import { ActivityRowItem } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getStreetSessionActivities: (
  id: string,
  extraData?: string,
  name?: string
) => Promise<ActivityRowItem[]> = (id, extraData, name) =>
  authorizedApi.get(`/v1/sessions/${id}/activities`, { extraData, name });

export const addSessionActivities: (sessionId: string, activityIds) => Promise<any> = (sessionId, activityIds) =>
  authorizedApi.post(`/v1/sessions/${sessionId}/activities`, { ids: activityIds });

export const removeActivityFromSession: (sessionId: string, activityId: string) => Promise<any> = (
  sessionId,
  activityId
) => authorizedApi.delete(`/v1/sessions/${sessionId}/activities/${activityId}`);

export const addParticipantsToActivity: (
  sessionId: string,
  activityId: string,
  participantIds: string[]
) => Promise<any> = (sessionId, activityId, participantIds) =>
  authorizedApi.put(`/v1/sessions/${sessionId}/participants/activities`, { activityIds: [activityId], participantIds });
