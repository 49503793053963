import React from 'react';
import classnames from 'classnames';

import Icon, { Type } from '@/components/Icon';
import BoxedIcon, { Props as BoxedIconProps } from '@/components/BoxedIcon';
import Typography, { Props as TypographyProps } from '@/components/Typography';

import style from './TextWithIcon.sass';

export interface Props<T> extends TypographyProps<T> {
  icon: Type;
  iconColor?: 'orange' | 'red' | 'gray' | 'blue' | 'white' | 'green' | 'orangeHollow';
  iconSize?: BoxedIconProps['size'];
  boxed?: boolean;
}

export default class TextWithIcon<T> extends React.PureComponent<Props<T>> {
  render() {
    const { is, type, color, weight, icon, iconColor, boxed, className, children, iconSize, ...rest } = this.props;
    const iconColoring = iconColor ? iconColor : color;
    return (
      <div className={classnames(style.root, className)} {...rest}>
        <div className={style.container}>
          {!boxed ? (
            <Icon type={icon} appearance={iconColoring} />
          ) : (
            // @ts-ignore
            <BoxedIcon type={icon} appearance={iconColoring} size={iconSize} />
          )}

          <Typography is={is} type={type} color={color} weight={weight} className={style.content}>
            {children}
          </Typography>
        </div>
      </div>
    );
  }
}
