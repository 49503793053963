import React, { PureComponent } from 'react';

import { RouteComponentProps, withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { urls } from '@/constants';

import If from '@/components/If';
import Spacer from '@/components/Spacer';
import { AppConsumer } from '@/components/Context/App';
import EntityPreview from '@/components/EntityPreview';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import Detail from './Detail';

import style from './SelfEvaluation.sass';

interface Params {
  id: string;
}

export interface Props {
  initialValues: any;
}

class Survey extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <div className={style.root}>
        <Spacer xs={2} />
        <AppConsumer>{({ team }) => !!team && <EntityPreview logo={team.imageUrl} name={team.name} />}</AppConsumer>

        <Spacer xs={4} />

        <Switch>
          <Route exact path={urls.digitalCoach.selfEvaluation.detail} component={Detail} />

          <ConsumeRedirectRoute render={() => <Redirect to={urls.digitalCoach.coach} />} />
        </Switch>
      </div>
    );
  }
}

export default injectIntl(withRouter(Survey));
