import jwtDecode from 'jwt-decode';

enum TokenFields {
  OrganizationId = 'impact_organization_id'
}

interface Token {
  organizationId: string | null;
}

export const decode: (token: string) => Token | null = (token) => {
  let decodedToken: any;

  try {
    decodedToken = jwtDecode(token);
  } catch (error) {
    return null;
  }

  return { organizationId: decodedToken[TokenFields.OrganizationId] || null };
};
