import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';
import { SurveyStatus } from '@/domains';

import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import TimeInput from '@/components/TimeInput';
import DatePickerInput from '@/components/DatePickerInput';
import { withValidation } from '@/components/hoc/withValidation';

import style from './SurveyDateSection.sass';

export interface Props {
  surveyStatus: SurveyStatus;
  submitting: boolean;
  onFormChange: (name: string, value: any) => void;
}

const TimeInputWithValidation = withValidation(TimeInput);
const DatePickerInputWithValidation = withValidation(DatePickerInput);

const SurveyDateSection: React.FC<Props> = ({ submitting, surveyStatus, onFormChange }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <div className={classnames(style.row, style.topAlign)}>
        <Form.Field
          is={DatePickerInputWithValidation}
          change={onFormChange}
          id={`start-date`}
          name="startDate"
          type="text"
          readOnly={submitting}
          disabled={surveyStatus === SurveyStatus.Closed}
          className={style.halfField}
        />
        <div className={style.margin}>
          <Form.Field
            is={TimeInputWithValidation}
            id={`start-time`}
            name="startTime"
            type="text"
            label={intl.formatMessage({ id: translations.inputs.startTime.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.startTime.placeholder })}
            disabled={surveyStatus === SurveyStatus.Closed}
            readOnly={submitting}
            className={style.halfField}
          />
        </div>
      </div>

      <Spacer xs={2} />

      <div className={style.row}>
        <Form.Field
          is={DatePickerInputWithValidation}
          change={onFormChange}
          id={`end-date`}
          name="endDate"
          type="text"
          disabled={surveyStatus === SurveyStatus.Closed}
          readOnly={submitting}
          className={style.halfField}
        />

        <div className={style.margin}>
          <Form.Field
            is={TimeInputWithValidation}
            id={`end-time`}
            name="endTime"
            type="text"
            disabled={surveyStatus === SurveyStatus.Closed}
            label={intl.formatMessage({ id: translations.inputs.endTime.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.endTime.placeholder })}
            readOnly={submitting}
            className={style.halfField}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SurveyDateSection;
