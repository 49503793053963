import { CreateEmergencyContact, EmergencyContact, Page } from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getEmergencyContacts: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<EmergencyContact>> = (teamId, pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/emergency-contacts`, { pageNumber, pageSize, teamIds: [teamId], ...options });

export const createEmergencyContact: (data: CreateEmergencyContact) => Promise<any> = (data) =>
  authorizedApi.post<any>(`/v1/emergency-contacts`, data);

export const updateEmergencyContact: (data: EmergencyContact) => Promise<any> = ({ id, ...rest }) =>
  authorizedApi.put(`/v1/emergency-contacts/${id}`, { ...rest });

export const deleteEmergencyContact: (id: string) => Promise<any> = (id) =>
  authorizedApi.delete(`/v1/emergency-contacts/${id}`);
