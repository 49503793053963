import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';

import Toggle, { Props as ToggleProps } from '@/components/Toggle';
import Typography from '@/components/Typography';

import style from './ToggleSwitch.sass';

export interface Props extends Omit<ToggleProps, 'renderToggle'> {
  label?: boolean;
  labelOnRight?: boolean;
}

const ToggleSwitch: React.FC<Props> = ({ label = true, labelOnRight, ...rest }) => (
  <Toggle
    {...rest}
    renderToggle={(toggled, disabled) => (
      <div className={classnames(style.root, { [style.rightLabel]: labelOnRight })}>
        {label && (
          <Typography is="span" type="halcyon" color={disabled ? 'gray' : 'blue'}>
            <FormattedMessage id={translations.stateSwitch[toggled ? 'active' : 'inactive']} />
          </Typography>
        )}

        <div className={classnames(style.toggle, { [style.active]: toggled, [style.disabled]: disabled })}>
          <div className={style.dot} />
        </div>
      </div>
    )}
  />
);

export default ToggleSwitch;
