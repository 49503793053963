import React, { PureComponent } from 'react';

import { crash } from '@/services/crash';
import Button from '@/components/Button';

import style from './ErrorBoundary.sass';

export interface Props {}

export interface State {
  error: boolean;
}

export default class ErrorBoundary extends PureComponent<Props, State> {
  state = {
    error: false
  };

  componentDidCatch(error: Error) {
    this.setState({ error: true });
    return crash.report(error);
  }

  reset = () => this.setState({ error: false });

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) return children;

    return (
      <div className={style.root}>
        <div className={style.content}>
          <div className={style.message}>
            We&apos;re sorry but something has gone wrong. Our team has been notified about it.
          </div>

          <Button onClick={this.reset} appearance="orange">
            Try again
          </Button>
        </div>
      </div>
    );
  }
}
