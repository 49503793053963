import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { hasRole } from '@/permission';
import { translations } from '@/locale';
import { urls, rules, Role } from '@/constants';

import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import HasRole from '@/components/HasRole';
import AppLayout from '@/components/AppLayout';
import { AppProvider } from '@/components/Context/App';
import { SessionContext } from '@/components/Context/Session';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import SuperAdminReporting from '@/page/Admin/Reporting';
import Organisations from '@/page/Admin/Organisations';
import SuperAdminDataCustomisation from '@/page/Admin/DataCustomisation';

import Dashboard from '@/page/Dashboard';
import Reporting from '@/page/Reporting';

import GeneralInformation from '@/page/Organization/GeneralInformation';
import Members from '@/page/Organization/Members';
import DataCustomisation from '@/page/Organization/DataCustomisation';
import Licenses from '@/page/Organization/Licenses';

import Teams from '@/page/Team/Teams';
import LocationReports from '@/page/Team/Teams/Locations/Reports';
import Teammates from '@/page/Team/Teammates';
import Participants from '@/page/Team/Participants';
import StreetSessions from '@/page/Team/StreetSessions';
import DigitalCoach from '@/page/Team/DigitalCoach';
import Survey from '@/page/Team/DigitalCoach/Survey';
import Spacer from '@/components/Spacer';
import SelfEvaluation from '@/page/Team/DigitalCoach/SelfEvaluation';
import CookieBar from '@/components/CookiesBar';
import Download from '@/page//Download';

export interface Props {}

const HomePage: React.FC<Props & WrappedComponentProps> = ({ intl }) => {
  const { me } = React.useContext(SessionContext);

  return (
    <AppProvider team={me.team}>
      <AppLayout>
        <AppLayout.Header>
          <Header />
        </AppLayout.Header>

        <AppLayout.Page>
          <AppLayout.Sidebar>
            <Sidebar>
              <Sidebar.Group title={intl.formatMessage({ id: translations.navigationGroup.main })}>
                <HasRole to={rules.APPLICATION_REPORTING}>
                  <Sidebar.Item icon="sessions" url={urls.admin.reports}>
                    <FormattedMessage id={translations.navigation.reports} />
                  </Sidebar.Item>
                </HasRole>

                <HasRole to={rules.APPLICATION_ORGANIZATIONS}>
                  <Sidebar.Item icon="sessions" url={urls.admin.organizations.list}>
                    <FormattedMessage id={translations.navigation.organizations} />
                  </Sidebar.Item>
                </HasRole>

                <HasRole to={rules.APPLICATION_DATA_CUSTOMIZATION}>
                  <Sidebar.Item icon="data" url={urls.admin.dataCustomisation.base}>
                    <FormattedMessage id={translations.navigation.dataCustomisation} />
                  </Sidebar.Item>
                </HasRole>

                <HasRole to={rules.MAIN_DASHBOARD_VIEW}>
                  <Sidebar.Item icon="dashboard" url={urls.dashboard}>
                    <FormattedMessage id={translations.navigation.dashboard} />
                  </Sidebar.Item>
                </HasRole>

                <HasRole to={rules.MAIN_REPORTING_VIEW}>
                  <Sidebar.Item icon="reports" url={urls.reporting}>
                    <FormattedMessage id={translations.navigation.reporting} />
                  </Sidebar.Item>
                </HasRole>

                <HasRole to={rules.ORGANIZATION_DOWNLOAD_INTERVENTIONS}>
                  <Sidebar.Item icon="download" url={urls.download}>
                    <FormattedMessage id={translations.miscellaneous.download} />
                  </Sidebar.Item>
                </HasRole>
              </Sidebar.Group>

              <HasRole to={rules.ORGANIZATION_MENU}>
                <Sidebar.Group title={intl.formatMessage({ id: translations.navigationGroup.organization })}>
                  <HasRole to={rules.ORGANIZATION_INFORMATION_VIEW}>
                    <Sidebar.Item icon="organisation" url={urls.generalInformation}>
                      <FormattedMessage id={translations.navigation.organizationInformation} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.ORGANIZATION_MEMBERS_VIEW}>
                    <Sidebar.Item icon="organisationMember" url={urls.members.list}>
                      <FormattedMessage id={translations.navigation.members} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.ORGANIZATION_DATA_CUSTOMISATION_VIEW}>
                    <Sidebar.Item icon="data" url={urls.dataCustomisation.base}>
                      <FormattedMessage id={translations.navigation.dataCustomisation} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.ORGANIZATION_LICENSES_VIEW}>
                    <Sidebar.Item icon="license" url={urls.licenses}>
                      <FormattedMessage id={translations.navigation.licenses} />
                    </Sidebar.Item>
                  </HasRole>
                </Sidebar.Group>
              </HasRole>

              <HasRole to={rules.TEAMS_MENU}>
                <Sidebar.Group title={intl.formatMessage({ id: translations.navigationGroup.teams })}>
                  <HasRole to={rules.TEAMS_TEAM_INFORMATION_VIEW}>
                    <Sidebar.Item icon="team" url={urls.team.detail}>
                      <FormattedMessage id={translations.navigation.teamInformation} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.TEAMS_TEAMMATES_VIEW}>
                    <Sidebar.Item icon="teamMember" url={urls.teammates.list}>
                      <FormattedMessage id={translations.navigation.teammates} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.TEAMS_PARTICIPANTS_VIEW}>
                    <Sidebar.Item icon="participants" url={urls.participants.list}>
                      <FormattedMessage id={translations.navigation.participants} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.TEAMS_SESSIONS_VIEW}>
                    <Sidebar.Item icon="sessions" url={urls.sessions.list}>
                      <FormattedMessage id={translations.navigation.sessions} />
                    </Sidebar.Item>
                  </HasRole>

                  <HasRole to={rules.TEAMS_DIGITAL_COACH_VIEW}>
                    <Sidebar.Item icon="eye" url={urls.digitalCoach.coach}>
                      <FormattedMessage id={translations.navigation.digitalCoach} />
                    </Sidebar.Item>
                  </HasRole>
                </Sidebar.Group>
              </HasRole>

              <Sidebar.Item icon="smile" url={urls.FAQ} external>
                <FormattedMessage id={translations.navigation.faq} />
              </Sidebar.Item>

              <Spacer xs={8} />
            </Sidebar>
          </AppLayout.Sidebar>

          <AppLayout.Content>
            <Switch>
              {hasRole(me.role, rules.APPLICATION_REPORTING) && (
                <Route path={urls.admin.reports} component={SuperAdminReporting} />
              )}
              {hasRole(me.role, rules.APPLICATION_ORGANIZATIONS) && (
                <Route path={urls.admin.organizations.list} component={Organisations} />
              )}

              {hasRole(me.role, rules.APPLICATION_DATA_CUSTOMIZATION) && (
                <Route exact path={urls.admin.dataCustomisation.base} component={SuperAdminDataCustomisation} />
              )}

              {hasRole(me.role, rules.MAIN_DASHBOARD_VIEW) && <Route path={urls.dashboard} component={Dashboard} />}

              {hasRole(me.role, rules.MAIN_REPORTING_VIEW) && <Route path={urls.reporting} component={Reporting} />}

              {hasRole(me.role, rules.ORGANIZATION_DOWNLOAD_INTERVENTIONS) && (
                <Route path={urls.download} component={Download} />
              )}

              {hasRole(me.role, rules.ORGANIZATION_INFORMATION_VIEW) && (
                <Route path={urls.generalInformation} component={GeneralInformation} />
              )}
              {hasRole(me.role, rules.ORGANIZATION_MEMBERS_VIEW) && (
                <Route path={urls.members.list} component={Members} />
              )}
              {hasRole(me.role, rules.ORGANIZATION_DATA_CUSTOMISATION_VIEW) && (
                <Route path={urls.dataCustomisation.base} component={DataCustomisation} />
              )}

              {hasRole(me.role, rules.ORGANIZATION_LICENSES_VIEW) && (
                <Route path={urls.licenses} component={Licenses} />
              )}

              {hasRole(me.role, rules.LOCATION_REPORTING) && (
                <Route path={urls.team.locations.reports.detail} component={LocationReports} />
              )}
              {hasRole(me.role, rules.TEAMS_TEAM_INFORMATION_VIEW) && (
                <Route path={urls.team.detail} component={Teams} />
              )}
              {hasRole(me.role, rules.TEAMS_TEAMMATES_VIEW) && (
                <Route path={urls.teammates.list} component={Teammates} />
              )}
              {hasRole(me.role, rules.TEAMS_PARTICIPANTS_VIEW) && (
                <Route path={urls.participants.list} component={Participants} />
              )}

              {hasRole(me.role, rules.TEAMS_SESSIONS_VIEW) && (
                <Route path={urls.sessions.list} component={StreetSessions} />
              )}

              <Route path={urls.digitalCoach.survey.base} component={Survey} />

              <Route path={urls.digitalCoach.selfEvaluation.base} component={SelfEvaluation} />

              {hasRole(me.role, rules.TEAMS_DIGITAL_COACH_VIEW) && (
                <Route path={urls.digitalCoach.coach} component={DigitalCoach} />
              )}

              <ConsumeRedirectRoute
                render={() => <Redirect to={me.role === Role.SuperAdmin ? urls.admin.reports : urls.dashboard} />}
              />
            </Switch>
          </AppLayout.Content>
        </AppLayout.Page>
        <CookieBar />
      </AppLayout>
    </AppProvider>
  );
};

export default injectIntl(HomePage);
