import React from 'react';
import * as yup from 'yup';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { ParticipantRowModel, ActivityRowItem } from '@/domains';
import { getSessionParticipants } from '@/services/api/street-session-participants';
import { addParticipantsToActivity } from '@/services/api/street-session-activities';

import { InstanceProps } from '@/components/Modal';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

const schema = yup.object({
  selectedItems: yup
    .array()
    .of(yup.string())
    .min(1)
    .required()
});

interface Props extends InstanceProps {
  activity: ActivityRowItem;
  participantId: string;
  sessionId: string;
  onSubmit: () => void;
}

class AddParticipantsToActivityModal extends React.PureComponent<Props & WrappedComponentProps> {
  extractParticipantData = (participant: ParticipantRowModel): SelectableItemData => {
    const { intl } = this.props;
    return {
      id: participant.id,
      topText: participant.nickname,
      bottomText: participant.birthDate
        ? intl.formatMessage(
            { id: translations.pages.sessions.details.tabs.participants.content.table.age },
            { age: getAgeFromBirthdate(participant.birthDate) }
          )
        : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge }),
      photoUrl: participant.imageUrl
    };
  };

  render() {
    const { activity, sessionId, intl, onSubmit } = this.props;

    return (
      <SearchableModal close={this.props.close}>
        <SearchableModal.Header
          title={intl.formatMessage(
            { id: translations.modals.searchableModal.participantsToActivity.title },
            { activityName: activity.name }
          )}
          subtitle={intl.formatMessage({ id: translations.modals.searchableModal.participantsToActivity.subtitle })}
        />

        <SearchableModal.Content
          close={this.props.close}
          schema={schema}
          initialValues={{ selectedItems: activity.participants.map((participant) => participant.id) }}
          searchLabel={intl.formatMessage({ id: translations.inputs.participantsSearch.label })}
          searchPlaceholder={intl.formatMessage({ id: translations.inputs.participantsSearch.placeholder })}
          dataSource={(page, number, search) => getSessionParticipants(page, number, search, sessionId)}
          submitDestination={(values) =>
            addParticipantsToActivity(sessionId, activity.id, values.selectedItems.map((id) => id)).then(onSubmit)
          }
          dataExtractionFunction={this.extractParticipantData}
        />
      </SearchableModal>
    );
  }
}
export default injectIntl(AddParticipantsToActivityModal);
