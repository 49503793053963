import { PaymentStatus } from '@/domains';
import { Appearance } from '@/components/BoxedContent';

export const paymentStatusColor: Record<PaymentStatus, Appearance> = {
  [PaymentStatus.Paid]: 'green',
  [PaymentStatus.Pending]: 'orange',
  [PaymentStatus.Overdue]: 'red',
  [PaymentStatus.Canceled]: 'red',
  [PaymentStatus.NotAvailable]: 'gray'
};
