import React, { PureComponent } from 'react';
import { formatISO, startOfMonth, endOfMonth } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { urls } from '@/constants';
import { getISOString } from '@/util';
import { translations } from '@/locale';
import { getSurveys } from '@/services/api/survey';
import { By, ParticipantListItem, Sort, SurveyTypeFilter } from '@/domains';

import If from '@/components/If';
import Link from '@/components/Link';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { dateFormat } from '@/components/DateInput';
import FilterManager from '@/components/FilterManager';
import { AppConsumer } from '@/components/Context/App';
import DatePickerInput from '@/components/DatePickerInput';
import SurveyTypeSelect from '@/components/Selects/SurveyTypeSelect';

import SurveyRow from './SurveyRow';
import SurveyHeader from './SurveyHeader';

import style from './Surveys.sass';

interface Params {
  id: string;
}

export interface Props {}

class Surveys extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <AppConsumer>
        {({ team, loading }) => (
          <If
            condition={!loading}
            then={() => (
              <div className={style.root}>
                <FilterManager
                  initialCriteria={{
                    start: formatISO(startOfMonth(new Date()), { representation: 'date' }),
                    end: formatISO(endOfMonth(new Date()), { representation: 'date' }),
                    type: SurveyTypeFilter.All
                  }}
                >
                  {({ criteria, debouncedUpdate, updateCriteria }) => (
                    <React.Fragment>
                      <div className={style.row}>
                        <TextInput
                          type="text"
                          id="search"
                          onChange={(event) => debouncedUpdate('search', event.target.value)}
                          label={intl.formatMessage({ id: translations.inputs.surveySearch.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.surveySearch.placeholder })}
                          autoComplete="off"
                          className={style.search}
                          leadingIcon="search"
                        />
                        <Link to={urls.digitalCoach.survey.new}>
                          <Button is="button" fat appearance="orange">
                            <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.newSurvey} />
                          </Button>
                        </Link>
                      </div>

                      <Spacer xs={3} />

                      <div className={style.filterRow}>
                        <div className={style.dateFilter}>
                          <label htmlFor="start" className={style.label}>
                            <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.table.filters.from} />
                          </label>

                          <DatePickerInput
                            id="start"
                            value={criteria.start}
                            change={(_, value) => updateCriteria('start', value)}
                            inputClassName={style.datePicker}
                          />
                        </div>

                        <div className={style.dateFilter}>
                          <label htmlFor="end" className={style.label}>
                            <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.table.filters.until} />
                          </label>

                          <DatePickerInput
                            id="end"
                            value={criteria.end}
                            change={(_, value) => updateCriteria('end', value)}
                            inputClassName={style.datePicker}
                          />
                        </div>

                        <div className={style.dateFilter}>
                          <label htmlFor="survey-type-filter" className={style.label}>
                            <FormattedMessage
                              id={translations.pages.digitalCoach.tabs.surveys.table.filters.surveyType}
                            />
                          </label>

                          <SurveyTypeSelect
                            id="survey-type-filter"
                            value={criteria.type}
                            onChange={(value) => updateCriteria('type', value)}
                            className={style.typePicker}
                          />
                        </div>
                      </div>

                      <Spacer xs={3} />

                      <Table
                        id={`${JSON.stringify({ criteria })}`}
                        source={(page, number) =>
                          getSurveys(team.id, page, number, {
                            keyword: criteria.search,
                            type: criteria.type === SurveyTypeFilter.All ? null : criteria.type,
                            start: getISOString(`${criteria.start} `, `${dateFormat}`),
                            end: getISOString(`${criteria.end} `, `${dateFormat}`),
                            [Sort.By]: [By.EndDate]
                          })
                        }
                        empty={() => (
                          <Placeholder image="questions" size="md" className={style.tablePlaceholder}>
                            <Typography is="span" type="swan" weight="bold">
                              <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.table.empty} />
                            </Typography>
                          </Placeholder>
                        )}
                        renderHeader={() => <SurveyHeader />}
                        renderRow={(data: ParticipantListItem) => (
                          <React.Fragment key={data.id}>
                            <SurveyRow data={data} />
                          </React.Fragment>
                        )}
                      />
                    </React.Fragment>
                  )}
                </FilterManager>
              </div>
            )}
          />
        )}
      </AppConsumer>
    );
  }
}

export default injectIntl(withRouter(Surveys));
