import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { OccurenceStatus } from '@/domains';
import { translations } from '@/locale';

import Card from '@/components/Card';

import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';
import style from './DaytimeOccupationRow.sass';

export interface Props {
  data: any;
  language: string;
}

const DaytimeOccupationRow: React.FC<Props> = ({ data, language, ...rest }) => (
  <Card.Row is="li" className={classnames(style.item, cellStyle.container)} {...rest}>
    <Typography is="div" type="hummingbird" className={cellStyle.daytimeoOcupation}>
      {data.translations[language.toLowerCase()]}
    </Typography>

    <Typography is="div" type="hummingbird" className={cellStyle.atStart}>
      {data.values.atStart ? <FormattedMessage id={translations.occurenceStatus[data.values.atStart]} /> : '-'}
    </Typography>

    <Typography is="div" type="hummingbird" className={cellStyle.atEnd}>
      {data.values.atEnd ? <FormattedMessage id={translations.occurenceStatus[data.values.atEnd]} /> : '-'}
    </Typography>
  </Card.Row>
);

export default DaytimeOccupationRow;
