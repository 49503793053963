import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '@/locale';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import Tab from '@/components/TabbedContainer/Tab';
import { AppContext } from '@/components/Context/App';
import EntityPreview from '@/components/EntityPreview';
import TabbedContainer from '@/components/TabbedContainer';

import Surveys from './Surveys';
import Messages from './Messages';
import Participants from './Participants';
import EmergencyInfo from './EmergencyInfo';
import SelfEvaluations from './SelfEvaluations';

import style from './DigitalCoach.sass';

interface Params {
  id: string;
}

export interface Props {}

const DigitalCoach: React.FC<Props & RouteComponentProps<Params>> = () => {
  const { team } = React.useContext(AppContext);

  const title = (
    <Typography is="span" type="swan" weight="bold">
      <FormattedMessage id={translations.pages.digitalCoach.title} />
    </Typography>
  );

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!team && <EntityPreview logo={team.imageUrl} name={team.name} />}

      <Spacer xs={4} />

      <Card className={style.card}>
        <TabbedContainer className={style.tabs}>
          <Tab
            contentBeforeTitle={title}
            title={<FormattedMessage id={translations.pages.digitalCoach.tabs.participants.title} />}
          >
            <Participants />
          </Tab>

          <Tab
            contentBeforeTitle={title}
            title={<FormattedMessage id={translations.pages.digitalCoach.tabs.selfEvaluations.title} />}
          >
            <SelfEvaluations />
          </Tab>

          <Tab
            contentBeforeTitle={title}
            title={<FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.title} />}
          >
            <Surveys />
          </Tab>

          <Tab
            contentBeforeTitle={title}
            title={<FormattedMessage id={translations.pages.digitalCoach.tabs.messages.title} />}
          >
            <Messages />
          </Tab>

          <Tab
            contentBeforeTitle={title}
            title={<FormattedMessage id={translations.pages.digitalCoach.tabs.emergencyInfo.title} />}
          >
            <EmergencyInfo />
          </Tab>
        </TabbedContainer>
      </Card>
    </div>
  );
};

export default withRouter(DigitalCoach);
