import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Picture, { Props as PictureProps } from '@/components/Picture';
import FileUpload, { Props as FileUploadProps } from '@/components/FileUpload';
import Dropdown from '@/components/Dropdown';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

import style from './DropdownPicture.sass';

type BaseProps = Pick<PictureProps, 'size' | 'type'> & Omit<FileUploadProps, 'value' | 'onChange' | 'size'>;

export interface Props extends BaseProps {
  id: string;
  name: string;
  invalid: boolean;
  value: File | string | null;
  onFocus: () => any;
  onBlur: () => any;
  onChange: (file: File | null) => any;
  uploadable?: boolean;
  downloadable?: boolean;
  removable?: boolean;
  deletable?: boolean;
  disabled?: boolean;
  onPictureDelete?: () => any;
}

interface State {
  preview: string | null;
}

class DropdownPicture extends React.PureComponent<Props, State> {
  state: State = {
    preview: null
  };

  componentDidMount() {
    this.update(this.props.value);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) this.update(this.props.value);
  }

  update = (value: Props['value']) => {
    if (!value) return this.setState({ preview: null });
    if (typeof value === 'string') return this.setState({ preview: value });

    const reader = new FileReader();

    reader.onload = (event) => this.setState({ preview: event.target.result.toString() });

    reader.readAsDataURL(value);
  };

  change = (files: FileList) => {
    return this.props.onChange(files[0] || null);
  };

  clear = () => this.props.onChange(null);

  render() {
    const {
      invalid,
      title,
      size,
      uploadable,
      downloadable,
      removable,
      deletable,
      onPictureDelete,
      onChange,
      type,
      ...rest
    } = this.props;
    return (
      <Dropdown
        renderMenu={({ close }) => (
          <Card className={style.menu}>
            {uploadable && (
              <Typography
                //@ts-ignore
                is={FileUpload}
                {...rest}
                multiple={false}
                onChange={(event) => this.change(event) || close()}
                className={style.menuItem}
                type="halcyon"
              >
                <FormattedMessage id={translations.inputs.picture.upload} />
              </Typography>
            )}

            {uploadable && removable && (
              <Typography
                is={(props) => <Button {...props} type="button" />}
                onClick={() => this.clear() || close()}
                type="halcyon"
                className={style.menuItem}
              >
                <FormattedMessage id={translations.inputs.picture.remove} />
              </Typography>
            )}

            {!uploadable && downloadable && (
              <Typography
                is="a"
                href={this.state.preview}
                target="_blank"
                type="halcyon"
                onClick={close}
                className={classnames(style.menuItem, style.downloadLink)}
              >
                <FormattedMessage id={translations.inputs.picture.download} />
              </Typography>
            )}

            {!uploadable && deletable && (
              <Typography
                is={(props) => <Button {...props} type="button" />}
                onClick={() => (onPictureDelete ? onPictureDelete() || close() : close())}
                type="halcyon"
                color="red"
                className={style.menuItem}
              >
                <FormattedMessage id={translations.inputs.picture.delete} />
              </Typography>
            )}
          </Card>
        )}
        dropdownClassName={style.dropdown}
      >
        {({ isOpen, open, close }) => (
          <Picture
            size={size}
            type={type}
            url={this.state.preview ? this.state.preview : null}
            onClick={rest.disabled ? null : isOpen ? close : open}
            className={classnames(style.picture, { [style.invalid]: invalid, [style.disabled]: rest.disabled })}
          >
            <Icon type="more" appearance={isOpen ? 'orange' : 'white'} className={style.icon} />
          </Picture>
        )}
      </Dropdown>
    );
  }
}

export default DropdownPicture;
