import React, { PureComponent } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '@/locale';
import { QuestionType } from '@/domains';
import { qualitativePalette } from '@/constants';
import { getColorFromPalleteByIndex, multiplyPalleteUpToLength } from '@/util';
import { getSurveyResponses } from '@/services/api/survey';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import { BarChart, PieChart } from '@/components/Charts';

import style from './Responses.sass';

interface Params {
  id: string;
}

export interface Props {}

class Participants extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl, match } = this.props;
    return (
      <LoadData id={match.params.id} load={getSurveyResponses}>
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => null}
            else={() => (
              <Card.Row padded className={style.column}>
                {value.answersReport.map((report) => (
                  <div key={report.id} className={style.column}>
                    <Typography is="span" type="halcyon" weight="bold">
                      {`${report.title} - ${intl.formatMessage({ id: translations.questionType[report.type] })}`}
                    </Typography>

                    <Spacer xs={2} />

                    {report.type === QuestionType.TextInput &&
                      report.answersReport.map((answer, index) => (
                        <Typography key={index} is="span" type="halcyon" className={style.textAnswer}>
                          {answer}
                        </Typography>
                      ))}

                    {report.type === QuestionType.SingleSelect && (
                      <PieChart
                        name={`${report.title}`}
                        data={{
                          labels: report.answersReport.labels.map((label) => label.name),
                          dataset: report.answersReport.data
                        }}
                        className={style.chart}
                      />
                    )}

                    {report.type === QuestionType.MultiSelect && (
                      <BarChart
                        name="barChart"
                        data={{
                          labels: [report.title],
                          datasets: report.answersReport.data.map((answer, index) => ({
                            label: answer.name,
                            data: [answer.numberOfRegistrations],
                            backgroundColor:
                              report.answersReport.data.length < qualitativePalette.length
                                ? getColorFromPalleteByIndex(qualitativePalette, index)
                                : getColorFromPalleteByIndex(
                                    multiplyPalleteUpToLength(report.answersReport.data.length, qualitativePalette),
                                    index
                                  )
                          }))
                        }}
                        className={style.chart}
                        options={{
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  min: 0,
                                  stepSize: 1
                                }
                              }
                            ]
                          }
                        }}
                      />
                    )}

                    <Spacer xs={4} />
                  </div>
                ))}
              </Card.Row>
            )}
          />
        )}
      </LoadData>
    );
  }
}

export default injectIntl(withRouter(Participants));
