import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { LocationState } from '@/domains';
import { translations } from '@/locale';

import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import TimeInput from '@/components/TimeInput';
import { AppContext } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import DatePickerInput from '@/components/DatePickerInput';
import InputsContainer from '@/components/InputsContainer';
import DataOptionSelect from '@/components/Selects/DataOptionSelect';
import LocationSelect from '@/components/Selects/LocationSelect';
import { withValidation } from '@/components/hoc/withValidation';

import style from './StreetSessionDetailFields.sass';

interface Props {
  readonly: boolean;
  formId: string;
  onFormChange: (name: string, value: any) => void;
}

const TimeInputWithValidation = withValidation(TimeInput);
const TextInputWithValidation = withValidation(TextInput);
const LocationSelectWithValidation = withValidation(LocationSelect);
const SessionTypeSelectWithValidation = withValidation(DataOptionSelect.SessionTypeSelect);

const StreetSessionDetailFields: React.FC<Props & WrappedComponentProps> = ({
  intl,
  readonly,
  formId,
  onFormChange
}) => {
  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  return (
    <React.Fragment>
      <Spacer xs={1} />

      <Form.Field
        is={SessionTypeSelectWithValidation}
        teamId={team.id}
        id={`${formId}-data-option-id`}
        name="dataOptionId"
        type="text"
        label={intl.formatMessage({ id: translations.inputs.sessionType.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.sessionType.placeholder })}
        readOnly={readonly}
        language={language}
      />

      <Spacer xs={1} />

      <Form.Field
        is={LocationSelectWithValidation}
        teamId={team.id}
        locationState={LocationState.Enabled}
        id={`${formId}-location-id`}
        name="locationId"
        type="text"
        label={intl.formatMessage({ id: translations.inputs.location.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.location.placeholder })}
        readOnly={readonly}
      />

      <Spacer xs={1} />

      <Form.Field
        is={DatePickerInput}
        change={onFormChange}
        id={`${formId}-start-date`}
        name="startDate"
        type="text"
        readOnly={readonly}
      />

      <Spacer xs={1} />

      <InputsContainer className={style.times}>
        <Form.Field
          is={TimeInputWithValidation}
          id={`${formId}-start-time`}
          name="startTime"
          type="text"
          label={intl.formatMessage({ id: translations.inputs.startTime.label })}
          placeholder={intl.formatMessage({ id: translations.inputs.startTime.placeholder })}
          readOnly={readonly}
        />
        <Form.Field
          is={TimeInputWithValidation}
          id={`${formId}-end-time`}
          name="endTime"
          type="text"
          label={intl.formatMessage({ id: translations.inputs.endTime.label })}
          placeholder={intl.formatMessage({ id: translations.inputs.endTime.placeholder })}
          readOnly={readonly}
        />
      </InputsContainer>

      <Spacer xs={1} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-attachment`}
        name="attachment"
        type="text"
        label={intl.formatMessage({ id: translations.inputs.sessionAttachment.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.sessionAttachment.placeholder })}
        readOnly={readonly}
      />
    </React.Fragment>
  );
};

export default injectIntl(StreetSessionDetailFields);
