import React, { PureComponent } from 'react';
import classnames from 'classnames';

import { WithSize, Magnitude } from '@/constants';
import { getStylesWithSize } from '@/util';

import style from './Spacer.sass';

export interface ISpacerProps extends WithSize<Magnitude> {
  className?: string;
}

export default class Spacer extends PureComponent<ISpacerProps> {
  render() {
    return (
      <div
        className={classnames(
          getStylesWithSize(
            (size) => (style as any)[`${size}${this.props[size] || 0}`],
            (size) => this.props[size] != null
          ),
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
