import React from 'react';
import classnames from 'classnames';

import { Language } from '@/constants';
import Typography from '@/components/Typography';
import Picture from '@/components/Picture';

import enFlag from './gb.svg';
import frFlag from './fr.svg';
import esFlag from './es.svg';
import nlFlag from './nl.svg';
import alFlag from './al.svg';
import grFlag from './gr.svg';
import roFlag from './ro.svg';
import plFlag from './pl.svg';

import style from './LanguageItem.sass';

export interface Props {
  is: React.ReactNode;
  language: Language;
  className?: string;
}

const flagMap: Record<Language, string> = {
  [Language.English]: enFlag,
  [Language.French]: frFlag,
  [Language.Spanish]: esFlag,
  [Language.Dutch]: nlFlag,
  [Language.Albanian]: alFlag,
  [Language.Greek]: grFlag,
  [Language.Romanian]: roFlag,
  [Language.Polish]: plFlag
};

const LanguageItem: React.FC<Props> = ({ is: Component, language, children, className, ...rest }) => (
  //@ts-ignore
  <Component className={classnames(style.root, className)} {...rest}>
    <Picture size="xs" url={flagMap[language]} className={style.flag} />

    <Typography is="span" type="halcyon">
      {language}
    </Typography>

    {children}
  </Component>
);

export default LanguageItem;
