import { authorizedApi } from '@/services/network/authorized-api';

export const updateParticipantActivities: (
  participantId: string,
  sessionId: string,
  activitiesIds: string[]
) => Promise<any> = (participantId, sessionId, activitiesIds) =>
  authorizedApi.post(`/v1/sessions/${sessionId}/participants/${participantId}/activities`, {
    ids: activitiesIds.map((id) => ({
      id
    }))
  });
