import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import {
  getEvaluationModules,
  getSocialMapEvaluationInterval,
  updateSocialMapEvaluationInterval
} from '@/services/api/organization';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import InputsContainer from '@/components/InputsContainer';
import SocialMapEvaluationIntervalSelect from '@/components/SocialMapEvaluationIntervalSelect';

import EvaluationModuleHeader from './EvaluationModuleHeader';
import EvaluationModuleRow from './EvaluationModuleRow';

import style from './General.sass';

const General: React.FC = () => {
  return (
    <Card.Row className={style.root}>
      <Card.Column>
        <LoadData load={getSocialMapEvaluationInterval}>
          {({ value, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center overlay>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <If
                  condition={!value}
                  then={() => 'Not found'}
                  else={() => (
                    <div className={style.container}>
                      <Typography is="h6" type="sparrow" weight="bold">
                        <FormattedMessage id={translations.dataCustomisation.general.evaluationIntervalTitle} />
                      </Typography>

                      <Spacer xs={1} />

                      <Typography is="h6" type="hummingbird" weight="lighter" color="gray">
                        <FormattedMessage id={translations.dataCustomisation.general.evaluationIntervalSubtitle} />
                      </Typography>

                      <Spacer xs={2} />

                      <InputsContainer>
                        <LiveSubmit
                          loading={loading}
                          value={{ interval: `${value}` }}
                          onChange={({ interval }) => updateSocialMapEvaluationInterval(interval)}
                        >
                          {({ value: { interval }, set }) => (
                            <SocialMapEvaluationIntervalSelect
                              value={interval}
                              onChange={(value) => set('interval', value)}
                            />
                          )}
                        </LiveSubmit>
                      </InputsContainer>
                    </div>
                  )}
                />
              )}
            />
          )}
        </LoadData>

        <Spacer xs={6} />

        <Typography is="h6" type="sparrow" weight="bold">
          <FormattedMessage id={translations.dataCustomisation.general.configureEvaluationModules} />
        </Typography>

        <Spacer xs={2} />

        <LoadData load={getEvaluationModules}>
          {({ value, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center overlay>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <Card className={style.evaluationModules}>
                  <EvaluationModuleHeader />
                  {value.map((item) => (
                    <EvaluationModuleRow key={item.type} evaluationModule={item} className={style.item} />
                  ))}
                </Card>
              )}
            />
          )}
        </LoadData>
      </Card.Column>
    </Card.Row>
  );
};

export default General;
