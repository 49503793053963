import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { hasRole } from '@/permission';
import { translations } from '@/locale';
import { urls, rules } from '@/constants';
import { getParticipantDetails } from '@/services/api/participant';

import If from '@/components/If';
import Card from '@/components/Card';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import Breadcrumb from '@/components/Breadcrumb';
import { AppContext } from '@/components/Context/App';
import { SessionContext } from '@/components/Context/Session';
import RoutedTabbedContainer from '@/components/RoutedTabbedContainer';
import RoutableTab from '@/components/RoutedTabbedContainer/RoutableTab';

import Goals from './Goals';
import Notes from './Notes';
import Images from './Images';
import Reports from './Reports';
import Contacts from './Contacts';
import Evaluations from './Evaluations';
import Information from './Information';
import DigitalCoach from './DigitalCoach';

interface Params {
  id: string;
}

const text = translations.pages.teams.participants;

const tabUrls = urls.participants.detailTabs;

const Details: React.FC<RouteComponentProps<Params> & WrappedComponentProps> = ({ intl, history, location, match }) => {
  const { team, loading: teamLoading } = React.useContext(AppContext);

  const { me } = React.useContext(SessionContext);

  return (
    !teamLoading && (
      <LoadData load={() => getParticipantDetails(team.id, match.params.id)}>
        {({ value: participant, loading, reload }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center overlay>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <React.Fragment>
                <Card.Row>
                  <Card.Column sm={12}>
                    <Breadcrumb
                      shape={{
                        [intl.formatMessage({ id: text.list.title })]: urls.participants.list,
                        [participant.nickname]: null
                      }}
                    />
                  </Card.Column>
                </Card.Row>

                <RoutedTabbedContainer baseUrl={tabUrls.withTab} history={history} location={location} match={match}>
                  <RoutableTab
                    url={tabUrls.get(match.params.id, tabUrls.information)}
                    title={intl.formatMessage({ id: text.details.information.tab })}
                  >
                    <Information participant={participant} onChange={reload} />
                  </RoutableTab>

                  <RoutableTab
                    url={tabUrls.get(match.params.id, tabUrls.contacts)}
                    title={intl.formatMessage({ id: text.details.contacts.tab })}
                  >
                    <Contacts />
                  </RoutableTab>

                  <RoutableTab
                    url={tabUrls.get(match.params.id, tabUrls.notes)}
                    title={intl.formatMessage({ id: text.details.notes.tab })}
                  >
                    <Notes />
                  </RoutableTab>

                  <RoutableTab
                    url={tabUrls.get(match.params.id, tabUrls.images)}
                    title={intl.formatMessage({ id: text.details.images.tab })}
                  >
                    <Images />
                  </RoutableTab>

                  <RoutableTab
                    url={tabUrls.get(match.params.id, tabUrls.evaluations)}
                    title={intl.formatMessage({ id: text.details.evaluations.tab })}
                  >
                    <Evaluations />
                  </RoutableTab>

                  <RoutableTab
                    url={tabUrls.get(match.params.id, tabUrls.goals)}
                    title={intl.formatMessage({ id: translations.evaluationCategories.GOAL.name })}
                  >
                    <Goals />
                  </RoutableTab>

                  {hasRole(me.role, rules.PARTICIPANT_REPORTING) && (
                    <RoutableTab
                      url={tabUrls.get(match.params.id, tabUrls.reports)}
                      title={intl.formatMessage({ id: text.details.reports.tab })}
                    >
                      <Reports />
                    </RoutableTab>
                  )}

                  {!!participant.participantAccount.id && (
                    <RoutableTab
                      url={tabUrls.get(match.params.id, tabUrls.digitalCoach)}
                      title={intl.formatMessage({ id: text.details.digitalCoach.tab })}
                    >
                      <DigitalCoach />
                    </RoutableTab>
                  )}
                </RoutedTabbedContainer>
              </React.Fragment>
            )}
          />
        )}
      </LoadData>
    )
  );
};
export default injectIntl(withRouter(Details));
