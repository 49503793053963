import React, { useState } from 'react';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { SubmitError } from '@/components/Error';

import style from './ConfirmationModal.sass';

interface Props extends InstanceProps {
  title: string;
  message?: string;
  confirmMessage: string;
  cancelMessage: string;
  onConfirm?: (event: React.MouseEvent) => Promise<any>;
  onCancel?: (event: React.MouseEvent) => Promise<any>;
}

const ConfirmationModal: React.FC<Props> = ({
  title,
  message,
  confirmMessage,
  cancelMessage,
  onConfirm,
  onCancel,
  close
}) => {
  // TODO Use useState once instead of multiple times
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [error, setError] = useState();

  const loading = cancelLoading || confirmLoading;

  const handleConfirm = (event: React.MouseEvent) => {
    if (onConfirm) {
      setConfirmLoading(true);
      onConfirm(event)
        .then(() => {
          setConfirmLoading(false);
          close();
        })
        .catch((error) => {
          setConfirmLoading(false);
          setError(error);
        });
    } else {
      close();
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    if (onCancel) {
      setCancelLoading(true);
      onCancel(event)
        .then(() => {
          setCancelLoading(false);
          close();
        })
        .catch((error) => {
          setCancelLoading(false);
          setError(error);
        });
    } else {
      close();
    }
  };

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12} className={style.content}>
          <Button className={style.closeButton} onClick={close}>
            <BoxedIcon type="close" appearance="red" />
          </Button>

          <Typography is="span" type="swan" weight="bold">
            {title}
          </Typography>

          <Spacer xs={2} />

          {message && (
            <Typography is="div" type="hummingbird">
              {message}
            </Typography>
          )}

          {error && <SubmitError error={error} />}
        </Card.Column>
      </Card.Row>
      <Card.Row padded className={style.buttonsContainer}>
        <Button
          appearance="ghost"
          className={style.button}
          onClick={handleCancel}
          loading={cancelLoading}
          disabled={loading}
        >
          {cancelMessage}
        </Button>
        <Button
          appearance="orange"
          className={style.button}
          onClick={handleConfirm}
          loading={confirmLoading}
          disabled={loading}
        >
          {confirmMessage}
        </Button>
      </Card.Row>
    </Card>
  );
};

export default ConfirmationModal;
