import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface Props extends RouteComponentProps {}

class ScrollToTop extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
