import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { sequentialPalette, qualitativePalette, skillProgressPalette, skillConfidencePalette } from '@/constants';
import { OrganizationReport, ReportFilters } from '@/domains';
import {
  getColorFromPalleteByIndex,
  getColorsForSequentialData,
  getLabelsFromPeriodAndTimeFrame,
  multiplyPalleteUpToLength
} from '@/util';
import { getOrganizationReports } from '@/services/api/organization-reports';

import If from '@/components/If';
import Spacer from '@/components/Spacer';
import Reports from '@/components/Reports';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import { LanguageContext } from '@/components/Language';
import { BarChart, StackedBarChart } from '@/components/Charts';

import style from '../Reporting.sass';

export enum LearningStatuses {
  veryNegative = 'VERY_NEGATIVE',
  negative = 'NEGATIVE',
  neutral = 'NEUTRAL',
  positive = 'POSITIVE',
  veryPositive = 'VERY_POSITIVE'
}

export enum ConfidenceStatuses {
  negative = 'NEGATIVE',
  neutral = 'NEUTRAL',
  positive = 'POSITIVE'
}

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          stepSize: 1
        }
      }
    ]
  }
};

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const SkillSection: React.FC<Props> = ({ intl, criteria }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.main.reporting.skills.title
      })}
    >
      <LoadData
        load={() => getOrganizationReports<OrganizationReport.Skill>(criteria, OrganizationReport.ReportType.Skill)}
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <React.Fragment>
                <Reports.ItemsContainer>
                  <Reports.Item
                    name={intl.formatMessage({
                      id: translations.pages.main.reporting.skills.participants
                    })}
                    value={value.numberOfSkillEvaluations}
                    className={style.reportItem}
                  />
                </Reports.ItemsContainer>

                <Spacer xs={3} />

                <Reports.Charts>
                  <BarChart
                    title={intl.formatMessage({
                      id: translations.pages.main.reporting.skills.numberOfevaluations.title
                    })}
                    name="number-of-skill-evaluations"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                      datasets: [
                        {
                          label: intl.formatMessage({
                            id: translations.pages.main.reporting.skills.numberOfevaluations.label
                          }),
                          data: value.skillEvaluationsInPeriods.data,
                          backgroundColor: getColorsForSequentialData(
                            value.skillEvaluationsInPeriods.data,
                            sequentialPalette
                          )
                        }
                      ]
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              min: 0,
                              stepSize: 1
                            }
                          }
                        ]
                      }
                    }}
                  />

                  <BarChart
                    title={intl.formatMessage({
                      id: translations.pages.main.reporting.skills.evaluationsPerSkill
                    })}
                    name="number-of-evaluations-per-skill"
                    data={{
                      labels: [
                        intl.formatMessage({
                          id: translations.pages.main.reporting.skills.evaluationsPerSkill
                        })
                      ],
                      datasets: value.evaluationsPerSkill.labels.map((label, index) => ({
                        label:
                          label.translations && label.translations[language.toLowerCase()]
                            ? label.translations[language.toLowerCase()]
                            : label.name,
                        data: [value.evaluationsPerSkill.data[index]],
                        backgroundColor: multiplyPalleteUpToLength(
                          value.evaluationsPerSkill.labels.length,
                          qualitativePalette
                        )[index]
                      }))
                    }}
                    className={style.wide}
                    options={options}
                    height={400}
                  />

                  <StackedBarChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.skills.skillProgress
                    })}
                    name="skill-progress"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                      datasets: Object.keys(LearningStatuses).map((key, index) => ({
                        label: intl.formatMessage({ id: translations.evaluationStatuses[LearningStatuses[key]] }),
                        data: value.confidenceAndLearnStatusReport.learningStatus.entries[0].data.map(
                          (entry) => entry[key]
                        ),
                        stack: `stack-0`,
                        backgroundColor: getColorFromPalleteByIndex(skillProgressPalette, index)
                      }))
                    }}
                    options={options}
                    className={style.wide}
                    height={279}
                  />

                  <StackedBarChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.skills.skillConfidence
                    })}
                    name="skill-confidence"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                      datasets: Object.keys(ConfidenceStatuses).map((key, index) => ({
                        label: intl.formatMessage({ id: translations.evaluationStatuses[ConfidenceStatuses[key]] }),
                        data: value.confidenceAndLearnStatusReport.confidenceStatus.entries[0].data.map(
                          (entry) => entry[key]
                        ),
                        stack: `stack-0`,
                        backgroundColor: getColorFromPalleteByIndex(skillConfidencePalette, index)
                      }))
                    }}
                    options={options}
                    className={style.wide}
                    height={279}
                  />
                </Reports.Charts>
              </React.Fragment>
            )}
          />
        )}
      </LoadData>
    </Reports>
  );
};
export default SkillSection;
