import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Goal } from '@/domains';
import { translations } from '@/locale';
import { getGoals } from '@/services/api/goal';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppContext } from '@/components/Context/App';

import GoalRow from './GoalRow';
import GoalHeader from './GoalHeader';

interface Params {
  id: string;
}

interface Props {}

const Goals: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const { id } = match.params;
  const { team } = React.useContext(AppContext);

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <Spacer xs={2} />

        <Table
          id={`participant-goals`}
          renderHeader={() => <GoalHeader />}
          empty={() => (
            <Placeholder image="questions" size="sm">
              <Typography is="span" type="halcyon" weight="bold">
                <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.none} />
              </Typography>
            </Placeholder>
          )}
          source={(page, number) => getGoals(id, team.id, page, number)}
          renderRow={(goal: Goal) => <GoalRow goal={goal} />}
        />
      </Card.Column>
    </Card.Row>
  );
};

export default withRouter(Goals);
