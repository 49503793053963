import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Message } from '@/domains';
import { translations } from '@/locale';
import { getMessages } from '@/services/api/messages';

import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppContext } from '@/components/Context/App';
import FilterManager from '@/components/FilterManager';
import MessageModal from '@/components/Modals/MessageModal';

import MessageRow from './MessageRow';
import MessagesHeader from './MessagesHeader';

import style from './Messages.sass';

interface Params {
  id: string;
}

export interface Props {}

const Messages: React.FC<Props> = () => {
  const intl = useIntl();

  const { team, loading } = React.useContext(AppContext);

  const [key, setKey] = React.useState(new Date().getTime());

  const updateKey = React.useCallback(() => setKey(new Date().getTime()), []);

  return (
    !loading && (
      <div className={style.root}>
        <FilterManager initialCriteria={{}}>
          {({ criteria, debouncedUpdate }) => (
            <React.Fragment>
              <div className={style.searchAndButton}>
                <TextInput
                  type="text"
                  id="search"
                  onChange={(event) => debouncedUpdate('search', event.target.value)}
                  label={intl.formatMessage({ id: translations.inputs.messagesSearch.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.messagesSearch.placeholder })}
                  autoComplete="off"
                  className={style.search}
                  leadingIcon="search"
                />

                <Modal modal={MessageModal} onClose={updateKey}>
                  {({ open }) => (
                    <Button fat appearance="orange" onClick={open}>
                      <FormattedMessage id={translations.pages.digitalCoach.tabs.messages.newMessage} />
                    </Button>
                  )}
                </Modal>
              </div>

              <Spacer xs={3} />

              <Table
                key={key}
                id={`${JSON.stringify({ criteria })}`}
                source={(page, number) => getMessages(team.id, page, number, { keyword: criteria.search })}
                empty={() => (
                  <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                    <Typography is="span" type="swan" weight="bold">
                      <FormattedMessage id={translations.pages.digitalCoach.tabs.messages.table.empty} />
                    </Typography>
                  </Placeholder>
                )}
                renderHeader={() => <MessagesHeader />}
                renderRow={(data: Message) => <MessageRow key={data.id} data={data} refresh={updateKey} />}
              />
            </React.Fragment>
          )}
        </FilterManager>
      </div>
    )
  );
};

export default Messages;
