import React from 'react';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { DataOption, State as DataOptionState } from '@/domains';
import { deleteDataOption, updateDataOption } from '@/services/api/data-option';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import ToggleSwitch from '@/components/ToggleSwitch';
import TextWithIcon from '@/components/TextWithIcon';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import cellStyle from '../cellStyle.sass';
import style from './DataOptionRow.sass';

export interface Props {
  dataOption: DataOption;
  className?: string;
  language?: string;
  withDelete?: boolean;
  onDelete?: () => any;
}

const DataOptionRow: React.FC<Props & WrappedComponentProps> = ({
  dataOption,
  className,
  language,
  withDelete,
  onDelete,
  intl,
  ...rest
}) => (
  <Card.Row is="li" className={classnames(cellStyle.container, className)} {...rest}>
    <div className={cellStyle.name}>
      <Typography is="div" type="sparrow" weight="bold">
        {dataOption.translations && dataOption.translations[language.toLowerCase()]
          ? dataOption.translations[language.toLowerCase()]
          : dataOption.name}
      </Typography>
    </div>

    <div className={cellStyle.icon}>
      {dataOption.icon ? <Picture url={dataOption.icon.url} size="xs" /> : <div className={style.empty} />}
    </div>

    <div className={cellStyle.category}>
      {dataOption.category ? (
        intl.formatMessage({ id: translations.skillCategories[dataOption.category] })
      ) : (
        <div className={style.empty} />
      )}
    </div>

    <div className={cellStyle.enabled}>
      <LiveSubmit
        loading={false}
        value={{ dataOptionState: dataOption.state }}
        onChange={({ dataOptionState }) => updateDataOption(dataOption.id, dataOptionState)}
      >
        {({ value: { dataOptionState }, set }) => (
          <ToggleSwitch
            checked={dataOptionState === DataOptionState.Enabled}
            label={false}
            onClick={() =>
              set(
                'dataOptionState',
                dataOptionState === DataOptionState.Enabled ? DataOptionState.Disabled : DataOptionState.Enabled
              )
            }
            onChange={() => null}
          />
        )}
      </LiveSubmit>
    </div>

    {withDelete && (
      <Modal
        modal={CrudConfirmationModal}
        message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
        onConfirm={() => deleteDataOption(dataOption.id).then(onDelete)}
      >
        {({ open }) => (
          <Button onClick={open} className={cellStyle.remove}>
            <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
              <FormattedMessage id={translations.inputs.remove} />
            </TextWithIcon>
          </Button>
        )}
      </Modal>
    )}
  </Card.Row>
);

export default injectIntl(DataOptionRow);
