import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as yup from 'yup';

import { rules } from '@/constants';
import { translations } from '@/locale';
import { DataOptionType } from '@/domains';
import { createDataOption, createDataOptionWithPicture } from '@/services/api/data-option';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import HasRole from '@/components/HasRole';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import PictureUpload from '@/components/PictureUpload';
import { withValidation } from '@/components/hoc/withValidation';
import SocialSkillCategorySelect from '@/components/Selects/SocialSkillCategorySelect';

import style from './NewDataOptionModal.sass';

const dataOptionCreationSchema = yup.object({
  name: yup.string().required()
});

const skillCreationSchema = yup.object({
  name: yup.string().required(),
  subtype: yup.string().required()
});

const dataOptionTypesWithImage = [
  DataOptionType.Skill,
  DataOptionType.ActivityType,
  DataOptionType.GoalType,
  DataOptionType.ContactType
];

interface Props extends InstanceProps {
  dataOptionType: DataOptionType;
}

const SkillCategorySelectWithValidation = withValidation(SocialSkillCategorySelect);

class NewDataOptionModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { close, dataOptionType, intl } = this.props;

    return (
      <Card className={style.root}>
        <Button className={style.closeButton} onClick={close}>
          <BoxedIcon type="close" appearance="red" />
        </Button>

        <div className={style.padded}>
          <Typography is="span" type="swan" weight="bold">
            <FormattedMessage
              id={translations.modals.addDataOption.title}
              values={{ dataOptionType: intl.formatMessage({ id: translations.dataOptions[dataOptionType] }) }}
            />
          </Typography>

          <Spacer xs={5} />

          <Form
            id="create-data-option"
            subscription={{ errors: true, submitting: true, valid: true }}
            schema={dataOptionType === DataOptionType.Skill ? skillCreationSchema : dataOptionCreationSchema}
            onSubmit={(values: any) =>
              values.iconId
                ? createDataOptionWithPicture(values.iconId, {
                    name: values.name,
                    subtype: values.subtype,
                    type: dataOptionType
                  }).then(close)
                : createDataOption({
                    name: values.name,
                    subtype: values.subtype,
                    type: dataOptionType
                  }).then(close)
            }
          >
            {({ submitting, valid }, id) => (
              <React.Fragment>
                <HasRole to={rules.APPLICATION_DATA_CUSTOMIZATION}>
                  <If
                    condition={dataOptionTypesWithImage.includes(dataOptionType)}
                    then={() => (
                      <React.Fragment>
                        <Form.Field
                          is={PictureUpload}
                          id="icon-id"
                          name="iconId"
                          type="participant"
                          title={this.props.intl.formatMessage(
                            { id: translations.inputs.dataOptionPhoto },
                            { optionName: intl.formatMessage({ id: translations.dataOptions[dataOptionType] }) }
                          )}
                          readOnly={submitting}
                        />

                        <Spacer xs={3} />
                      </React.Fragment>
                    )}
                  />
                </HasRole>

                <If
                  condition={dataOptionType === DataOptionType.Skill}
                  then={() => (
                    <React.Fragment>
                      <Form.Field
                        is={SkillCategorySelectWithValidation}
                        type="text"
                        id="subtype"
                        name="subtype"
                        label={intl.formatMessage({ id: translations.inputs.skillCategorySelect.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.skillCategorySelect.placeholder })}
                        readOnly={submitting}
                      />

                      <Spacer xs={3} />
                    </React.Fragment>
                  )}
                />

                <Form.Field
                  is={TextInput}
                  id={`${id}-name`}
                  name="name"
                  type="text"
                  label={this.props.intl.formatMessage({ id: translations.inputs.dataOptionName.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.dataOptionName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={3} />

                <Button
                  loading={submitting}
                  type="submit"
                  appearance="orange"
                  className={style.nextButton}
                  disabled={!valid}
                >
                  <FormattedMessage id={translations.buttons.add} />
                </Button>
              </React.Fragment>
            )}
          </Form>
        </div>
      </Card>
    );
  }
}
export default injectIntl(NewDataOptionModal);
