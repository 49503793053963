import React from 'react';

import { storage } from '@/services';

import Button from '@/components/Button';
import Typography from '@/components/Typography';

import style from './CookieBar.sass';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locale';
import Link from '../Link';
import { urls } from '@/constants';

interface State {
  isVisible: boolean;
}

export default class CookieBar extends React.PureComponent<{}, State> {
  state: State = {
    isVisible: false
  };

  checkCookiesAcceptance = () => (storage.getItem('acceptedCookies') ? false : true);

  acceptCookies = () => {
    this.setState(
      {
        isVisible: false
      },
      () => {
        storage.setItem('acceptedCookies', true);
      }
    );
  };

  componentDidMount() {
    this.setState({ isVisible: storage.getItem('acceptedCookies') ? false : true });
  }

  render() {
    return (
      this.state.isVisible && (
        <div className={style.container}>
          <Typography is="span" type="halcyon" weight="bold">
            <FormattedMessage
              id={translations.cookieBar.text}
              values={{
                a: (children) => (
                  <Link external to={urls.cookiePolicy} appearance="blue">
                    {children}
                  </Link>
                )
              }}
            />
          </Typography>

          <Button fat onClick={this.acceptCookies} appearance="orange">
            <FormattedMessage id={translations.cookieBar.button} />
          </Button>
        </div>
      )
    );
  }
}
