import {
  BarChartReportData,
  Data,
  Period,
  PieChartReportData,
  MoodReportLabel,
  Periodical,
  DifferentiateTimeFrameComparisonReport,
  Label
} from '@/domains';

export enum ReportType {
  Session = 'sessions',
  SessionParticipants = 'participation',
  ParticipantProfile = 'participants',
  ParticipantContacts = 'participant-connections',
  Activity = 'activities',
  Mood = 'moods',
  Skill = 'skills',
  Goal = 'goals',
  SocialMap = 'participants/contacts',
  DaytimeOccupation = 'daytime-occupation',
  AgeGroups = 'age-groups'
}

export interface Session extends Periodical {
  sessions: BarChartReportData;
}

export interface SessionParticipants {
  numberOfContactsWithOrganizationTeams: BarChartReportData;
  numberOfParticipantsAttendingAtLeastOneSession: Data;
  periodKeys: Period[];
}

export interface Participant {
  ageReport: PieChartReportData<string>;
  genderReport: PieChartReportData<string>;
  homeStatusReport?: PieChartReportData;
  languageReport?: PieChartReportData<string>;
  legalDocumentsReport?: BarChartReportData;
  legalStatusReport?: PieChartReportData;
  nationalityReport?: PieChartReportData<string>;
}

export interface ParticipantContacts extends Participant {}

export interface Activity {
  activities: PieChartReportData;
  participantsInActivities: PieChartReportData;
}

export interface Mood {
  evaluationsInQuadrants: PieChartReportData<MoodReportLabel>;
}

export interface Skill extends Periodical {
  confidenceAndLearnStatusReport: {
    confidenceStatus: BarChartReportData;
    learningStatus: BarChartReportData;
  };
  evaluationsPerSkill: PieChartReportData;
  numberOfSkillEvaluations: number;
  skillEvaluationsInPeriods: Data;
}

export interface Goal extends Periodical {
  averageDurationInWeeks: Data;
  goalsCompletedByCompletionStatus: BarChartReportData;
  goalsStarted: Data;
  totalNumberOfGoalsStarted: number;
  totalNumberOfParticipantsWhoStartedAGoal: number;
}

export interface SocialMap extends Periodical {
  averageNumberOfRelations: DifferentiateTimeFrameComparisonReport;
  percentageOfNegativity: DifferentiateTimeFrameComparisonReport;
  sizeOfCoreNetwork: DifferentiateTimeFrameComparisonReport;
  participantsWithSocialMapEvaluations: Data;
}

interface DaytimeOccupationReport {
  daytimeOccupation: PieChartReportData<string>;
  labelIdentifierDto: Label;
}

export interface DaytimeOccupation {
  atStart: { daytimeOccupations: DaytimeOccupationReport[] };
  atEnd: { daytimeOccupations: DaytimeOccupationReport[] };
}
