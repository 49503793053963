import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { EmergencyContact } from '@/domains';
import { phoneNumberSchema } from '@/validation';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { createEmergencyContact, updateEmergencyContact } from '@/services/api/emergency-contacts';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { withValidation } from '@/components/hoc/withValidation';

import style from './EmergencyContactModal.sass';

const schema = yup.object({
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.name.label)
    .required(),
  phoneNumber: phoneNumberSchema.required()
});

interface Props extends InstanceProps {
  data?: EmergencyContact;
  onSubmit?: () => any;
}

const TextInputWithValidation = withValidation(TextInput);

class EmergencyContactModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { intl, data, onSubmit, close } = this.props;
    const title = translations.modals.emergencyContactModal;

    return (
      <Card className={style.root}>
        <Button className={style.closeButton} onClick={() => close()}>
          <BoxedIcon type="close" appearance="red" />
        </Button>

        <Form
          id="new-emergency-contact"
          schema={schema}
          initialValues={data ? data : null}
          subscription={{ errors: true, submitting: true, submitError: true, values: true, valid: true }}
          onSubmit={(values) =>
            (data
              ? updateEmergencyContact({ id: data.id, name: values.name, phoneNumber: values.phoneNumber })
              : createEmergencyContact(values)
            )
              .then(onSubmit)
              .then(close)
          }
          className={style.form}
        >
          {({ submitError, submitting, valid }, formId) => (
            <React.Fragment>
              <div>
                <Typography is="span" type="swan" weight="bold">
                  <FormattedMessage id={data ? title.editTitle : title.creationTitle} />
                </Typography>

                <Spacer xs={3} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-name`}
                  name="name"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.emergencyContactName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.emergencyContactName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={2} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-phone-number`}
                  name="phoneNumber"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.phoneNumber.placeholder })}
                  readOnly={submitting}
                />

                {!!submitError && <SubmitError error={submitError} />}
              </div>

              <Spacer xs={4} />

              <Button
                form={formId}
                loading={submitting}
                type="submit"
                appearance="orange"
                className={style.addButton}
                disabled={!valid}
              >
                <FormattedMessage id={data ? translations.buttons.save : translations.buttons.add} />
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Card>
    );
  }
}
export default injectIntl(EmergencyContactModal);
