import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import EntityPreview from '@/components/EntityPreview';
import { AppConsumer } from '@/components/Context/App';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import List from './List';
import ParticipantDetails from './Details';
import EvaluationDetails from './Details/Evaluations/Details';

import style from './Participants.sass';

const Participants: React.FC = () => (
  <div className={style.root}>
    <Spacer xs={2} />

    <AppConsumer>{({ team }) => !!team && <EntityPreview logo={team.imageUrl} name={team.name} />}</AppConsumer>

    <Spacer xs={4} />

    <Card className={style.card}>
      <Switch>
        <Route exact path={urls.participants.list} component={List} />

        <Route exact path={urls.participants.evaluation.detail} component={EvaluationDetails} />

        <Route path={urls.participants.detail} component={ParticipantDetails} />

        <ConsumeRedirectRoute render={() => <Redirect to={urls.participants.list} />} />
      </Switch>
    </Card>
  </div>
);

export default Participants;
