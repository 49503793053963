import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';

import Dropdown from '@/components/Dropdown';

import AgeRangeFilterDropdown from './AgeRangeFilterDropdown';

import style from './AgeRangeFilter.sass';

export interface AgeRange {
  min: number;
  max: number;
}

export interface Props {
  value: AgeRange;
  placeholder?: string;
  className?: string;
  onChange: (value: AgeRange) => void;
}

const AgeRangeFilter: React.FC<Props & WrappedComponentProps> = ({ intl, value, placeholder, className, onChange }) => {
  const { min, max } = value;

  return (
    <Dropdown
      className={className}
      dropdownClassName={style.dropdown}
      renderMenu={({ close }) => <AgeRangeFilterDropdown range={value} onChange={onChange} close={close} />}
    >
      {({ isOpen, open }) => (
        <div
          className={classnames(style.input, { [style.open]: isOpen, [style.placeholder]: !min && !max })}
          onClick={open}
        >
          {min || max
            ? `${min || intl.formatMessage({ id: translations.inputs.ageRange.notDefined })} - ${max ||
                intl.formatMessage({ id: translations.inputs.ageRange.notDefined })}`
            : placeholder}
        </div>
      )}
    </Dropdown>
  );
};

export default injectIntl(AgeRangeFilter);
