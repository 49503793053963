import React from 'react';

import { withValidation, Props as WithValidationProps } from '@/components/hoc/withValidation';
import Label from '@/components/Label';

export interface ComponentProps {
  id: string;
  className?: string;
}

export interface Props extends ComponentProps {
  label: React.ReactNode;
  labelClassName?: string;
  inputClassName?: string;
}

export const withLabel = <P extends WithValidationProps>(
  Component: React.ComponentType<P & WithValidationProps>,
  staticProps?: Partial<Props>
) =>
  withValidation(
    class WithLabel extends React.PureComponent<any> {
      render() {
        const { invalid, error, label, labelClassName, ...rest } = this.props;

        return (
          <div className={labelClassName}>
            <Label htmlFor={this.props.id}>{label}</Label>

            {/*
            // @ts-ignore */}
            <Component invalid={invalid} {...staticProps} {...rest} />
          </div>
        );
      }
    }
  );
