import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Select, { Item, Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<string>, 'items'>, WrappedComponentProps {
  items: Item<string>[];
}

class CustomListTypeSelect extends React.PureComponent<Props> {
  render() {
    const { intl, items, ...rest } = this.props;

    return <Select {...rest} getItemValue={(value) => value} items={items} multiple={false} />;
  }
}

export default injectIntl(CustomListTypeSelect);
