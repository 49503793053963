import { withPagingParams } from '@/util';
import { Page, Contact, CreateContact, ContactDetails, UpdateContact } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getParticipantContacts = (
  participantId: string,
  page: number,
  size: number,
  search: string
): Promise<Page<Contact[]>> =>
  authorizedApi
    .get<{ contacts: Page<Contact[]> }>(
      `/v1/participants/${participantId}/contacts?${withPagingParams(page, size, { name: search })}`
    )
    .then(({ contacts }) => contacts);

export const getParticipantContacDetails = (contactId: string): Promise<ContactDetails> =>
  authorizedApi.get<ContactDetails>(`/v1/participants/contacts/${contactId}`, {
    extraData: ['LAST_UPDATED_AT', 'LAST_EVALUATION']
  });

export const createParticipantContact = (participantId: string, contact: CreateContact): Promise<any> =>
  authorizedApi.post<any>(`/v1/participants/${participantId}/contacts`, contact);

export const updateParticipantContact = (contactId: string, contact: UpdateContact): Promise<any> =>
  authorizedApi.put<any>(`/v1/participants/contacts/${contactId}`, contact);

export const deleteParticipantContact = (contactId: string): Promise<any> =>
  authorizedApi.delete<any>(`/v1/participants/contacts/${contactId}`);
