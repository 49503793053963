import url from 'url';
import { parse, stringify } from 'query-string';
import pickBy from 'lodash/pickBy';

export const replaceQueryParams = (location: string, search: Object) => {
  const parsedUrl = url.parse(location);

  const params = {
    ...parse(parsedUrl.search || ''),
    ...search
  };

  return url.format({ ...parsedUrl, search: stringify(pickBy(params)) });
};

export const urlsHaveSameDomains = (one: string, two: string) => {
  const parsedOneUrl = url.parse(one);
  const parsedTwoUrl = url.parse(two);
  return parsedOneUrl.host === parsedTwoUrl.host;
};

export const generateRedirectUrl = (currentUrl: string, redirectUrl: string) => {
  return urlsHaveSameDomains(currentUrl, redirectUrl)
    ? replaceQueryParams(redirectUrl, { redirect: currentUrl })
    : replaceQueryParams(currentUrl, { redirect: null });
};

export const getRedirectUrlFromParams = (path: string, search: string) => {
  const params = parse(search || '');

  const redirect = params.redirect
    ? typeof params.redirect === 'string'
      ? params.redirect
      : params.redirect[0]
    : null;

  if (redirect && urlsHaveSameDomains(path, redirect)) return redirect;

  return null;
};
