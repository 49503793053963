import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Filter } from '@/domains';
import { translations } from '@/locale';
import { getTeamMembersForSelect } from '@/services/api/team';

import LoadableSelect, { Props as LoadableSelectProps } from '../LoadableSelect';
import { AppContext } from '@/components/Context/App';

interface Props extends Omit<LoadableSelectProps, 'source'> {}

const TeamMemberSelect: React.FC<Props & WrappedComponentProps> = ({ intl, ...props }) => {
  const { team } = React.useContext(AppContext);
  return (
    team && (
      <LoadableSelect
        source={() =>
          getTeamMembersForSelect(team.id).then((page) => [
            { id: Filter.All as string, name: intl.formatMessage({ id: translations.miscellaneous.all }) },
            ...page.content.map((teamMember) => ({
              id: teamMember.id,
              name: `${teamMember.firstName ? teamMember.firstName : ''} ${
                teamMember.lastName ? teamMember.lastName : ''
              }`
            }))
          ])
        }
        {...props}
      />
    )
  );
};

export default injectIntl(TeamMemberSelect);
