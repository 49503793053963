import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { State } from '@/domains';
import { hasRole } from '@/permission';
import { translations } from '@/locale';
import { rules, urls } from '@/constants';
import { archiveTeam, deleteTeam } from '@/services/api/team';

import If from '@/components/If';
import Form from '@/components/Form';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import HasRole from '@/components/HasRole';
import Refresh from '@/components/Refresh';
import LiveSubmit from '@/components/LiveSubmit';
import Typography from '@/components/Typography';
import Tab from '@/components/TabbedContainer/Tab';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import ToggleSwitch from '@/components/ToggleSwitch';
import { AppContext } from '@/components/Context/App';
import EntityPreview from '@/components/EntityPreview';
import TabbedContainer from '@/components/TabbedContainer';
import { SessionContext } from '@/components/Context/Session';
import LocationModal from '@/components/Modals/LocationModal';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import TeamInfo from './TeamInfo';
import Locations from './Locations';
import DataOptions from './DataOptions';
import ProfileFields from './ProfileFields';

import style from './Teams.sass';

const Teams: React.FC<RouteComponentProps> = ({ history }) => {
  const intl = useIntl();

  const { me } = React.useContext(SessionContext);

  const { team, selectTeam, unselectTeam } = React.useContext(AppContext);

  const renderDeleteTeamButton = (teamId, unselectTeam) => {
    return (
      <Form id="delete-team" subscription={{ submitting: true }} onSubmit={null}>
        {({ submitting }, formId) => (
          <Modal
            modal={CrudConfirmationModal}
            message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
            onConfirm={() =>
              deleteTeam(teamId)
                .then(unselectTeam)
                .then(() => history.push(urls.dashboard))
            }
          >
            {({ open }) => (
              <Button
                id={`${formId}-delete`}
                loading={submitting}
                type="button"
                className={style.deleteButton}
                onClick={open}
              >
                <TextWithIcon is="span" type="halcyon" icon="bin" boxed iconColor="red" color="red">
                  <FormattedMessage id={translations.inputs.remove} />
                </TextWithIcon>
              </Button>
            )}
          </Modal>
        )}
      </Form>
    );
  };

  const renderArchiveTeamButton = (teamId, teamState) => {
    return (
      <LiveSubmit
        loading={false}
        value={{ teamState: teamState }}
        onChange={({ teamState }) => archiveTeam(teamId, teamState)}
      >
        {({ value: { teamState }, set }) => (
          <ToggleSwitch
            checked={teamState === State.Enabled}
            onClick={() => set('teamState', teamState === State.Enabled ? State.Disabled : State.Enabled)}
            onChange={() => null}
          />
        )}
      </LiveSubmit>
    );
  };

  return (
    <Refresh>
      {({ key, refresh }) => (
        <div className={style.root}>
          <Spacer xs={2} />

          {!!team && <EntityPreview logo={team.imageUrl} name={team.name} />}

          <Spacer xs={4} />

          <Card className={style.card}>
            <If
              condition={!team}
              then={() => (
                <Placeholder image="people" size="lg" className={style.noTeamPlaceholder}>
                  <Typography is="span" type="swan" weight="bold">
                    <FormattedMessage id={translations.pages.teams.noTeamSelected} />
                  </Typography>
                </Placeholder>
              )}
              else={() => (
                <TabbedContainer>
                  <Tab
                    title={<FormattedMessage id={translations.pages.teams.tabs.team.title} />}
                    contentBeforeTitle={
                      <div className={style.modalHeaderContainer}>
                        <Typography is="span" type="swan" weight="bold">
                          <FormattedMessage id={translations.pages.teams.cardTitles.team} />
                        </Typography>

                        <div className={style.row}>
                          <HasRole to={rules.TEAM_ARCHIVE}>{renderArchiveTeamButton(team.id, team.state)}</HasRole>

                          <HasRole to={rules.TEAM_DELETE}>{renderDeleteTeamButton(team.id, unselectTeam)}</HasRole>
                        </div>
                      </div>
                    }
                  >
                    <TeamInfo team={team} selectTeam={selectTeam} />
                  </Tab>

                  <Tab
                    title={<FormattedMessage id={translations.pages.teams.tabs.location.title} />}
                    contentBeforeTitle={
                      <div className={style.modalHeaderContainer}>
                        <Typography is="span" type="swan" weight="bold">
                          <FormattedMessage id={translations.pages.teams.cardTitles.locations} />
                        </Typography>

                        <HasRole to={rules.LOCATION_CREATE}>
                          <Modal modal={LocationModal} onSubmit={refresh}>
                            {({ open }) => (
                              <Button appearance="orange" onClick={open}>
                                <FormattedMessage id={translations.inputs.addLocations} />
                              </Button>
                            )}
                          </Modal>
                        </HasRole>
                      </div>
                    }
                  >
                    <Locations newKey={key} team={team} onChange={refresh} />
                  </Tab>

                  {hasRole(me.role, rules.TEAM_DATA_CUSTOMISATION) && (
                    <Tab
                      contentBeforeTitle={
                        <Card.Row className={style.modalHeaderContainer}>
                          <Typography is="span" type="swan" weight="bold">
                            <FormattedMessage id={translations.pages.organization.dataCustomisation.title} />
                          </Typography>
                        </Card.Row>
                      }
                      title={
                        <FormattedMessage
                          id={translations.pages.organization.dataCustomisation.tabs.dataOptions.title}
                        />
                      }
                    >
                      <DataOptions />
                    </Tab>
                  )}

                  {hasRole(me.role, rules.TEAM_DATA_CUSTOMISATION) && (
                    <Tab
                      contentBeforeTitle={
                        <Card.Row className={style.modalHeaderContainer}>
                          <Typography is="span" type="swan" weight="bold">
                            <FormattedMessage id={translations.pages.organization.dataCustomisation.title} />
                          </Typography>
                        </Card.Row>
                      }
                      title={
                        <FormattedMessage
                          id={translations.pages.organization.dataCustomisation.tabs.profileFields.title}
                        />
                      }
                    >
                      <ProfileFields />
                    </Tab>
                  )}
                </TabbedContainer>
              )}
            />
          </Card>
        </div>
      )}
    </Refresh>
  );
};

export default Teams;
