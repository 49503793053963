import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const ParticipantHeader: React.FC = () => {
  const { participant, role, responded } = translations.pages.digitalCoach.survey.tabs.participants.table.header;
  return (
    <Card.Row className={cellStyle.container}>
      <Typography is="span" type="halcyon" color="gray" className={cellStyle.participant}>
        <FormattedMessage id={participant} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.role}>
        <FormattedMessage id={role} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.responded}>
        <FormattedMessage id={responded} />
      </Typography>
    </Card.Row>
  );
};

export default ParticipantHeader;
