import React from 'react';
import classnames from 'classnames';

import { Page } from '@/domains';

import If from '@/components/If';
import Card from '@/components/Card';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import InfiniteScroll from '@/components/InfiniteScroll';

import style from './Table.sass';

export interface Props<T> {
  id?: string | number;
  source: (page?: number, size?: number) => Promise<Page<T>> | any;
  renderHeader?: () => any | null;
  renderRow: (rowData: T) => any;
  empty?: () => any;
  className?: string;
  listClassName?: string;
  withoutPagination?: boolean;
}

const Table: React.FC<Props<any>> = <T,>({
  id,
  source,
  renderHeader,
  renderRow,
  empty,
  listClassName,
  className,
  withoutPagination
}: Props<T>) => (
  <Card className={classnames(style.root, className)}>
    {!!renderHeader && renderHeader()}

    <Card.Row className={style.content}>
      <If
        condition={withoutPagination}
        then={() => (
          <LoadData key={id} load={source}>
            {({ value, loading }) => (
              <div className={classnames(style.list, listClassName)}>
                <If
                  condition={!loading && value && (value as any).length === 0}
                  then={empty}
                  else={() => (
                    <ul>
                      {/* //TODO this can be improved but it would mean changes in many places. Maybe if we have time at the end */}
                      {!loading && (value as any).map((rowData) => renderRow(rowData))}
                      <Loading visible={loading} center className={style.loadingIndicator}>
                        <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                      </Loading>
                    </ul>
                  )}
                />
              </div>
            )}
          </LoadData>
        )}
        else={() => (
          <InfiniteScroll id={id} source={source} className={classnames(style.list, listClassName)}>
            {({ data, loading }) => (
              <If
                condition={!loading && data.length === 0}
                then={empty}
                else={() => (
                  <ul>
                    {data.map((rowData: T) => renderRow(rowData))}

                    <Loading visible={loading} center className={style.loadingIndicator}>
                      <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                    </Loading>
                  </ul>
                )}
              />
            )}
          </InfiniteScroll>
        )}
      />
    </Card.Row>
  </Card>
);

export default Table;
