import React from 'react';

import Board from './Board';
import Item from './Item';

export default class KPI extends React.PureComponent {
  static Board = Board;
  static Item = Item;

  render() {
    return this.props.children;
  }
}
