import React from 'react';
import classnames from 'classnames';

import Row from './Row';
import Data from './Data';
import Header from './Header';

import style from './StatelessTable.sass';

export interface Props {
  className?: string;
}

export default class StatelessTable extends React.PureComponent<Props> {
  static Header = Header;
  static Row = Row;
  static Data = Data;

  render() {
    const { className, children } = this.props;
    return <div className={classnames(style.table, className)}>{children}</div>;
  }
}
