import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Visibility } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<Visibility>, 'items'>, WrappedComponentProps {}

class VisibilitySelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(Visibility).map((visibility) => ({
      value: visibility,
      name: intl.formatMessage({ id: translations.noteVisibilities[visibility] })
    }));

    return <Select {...rest} items={items} />;
  }
}

export default injectIntl(VisibilitySelect);
