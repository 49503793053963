import React from 'react';
import { WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { OrganizationReport, ReportFilters } from '@/domains';
import { getDaytimeOccupationReports } from '@/services/api/organization-reports';

import Loading from '@/components/Loading';
import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import { PieChart } from '@/components/Charts';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { LanguageContext } from '@/components/Language';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const DaytimeOccupationSection: React.FC<Props> = ({ intl, criteria }) => {
  const { language } = React.useContext(LanguageContext);
  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.main.reporting.daytimeOccupation.title
      })}
    >
      <LoadData
        load={() =>
          getDaytimeOccupationReports<OrganizationReport.DaytimeOccupation>(
            { ...criteria },
            OrganizationReport.ReportType.DaytimeOccupation
          )
        }
      >
        {({ value, loading }) =>
          loading ? (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          ) : value.atStart.daytimeOccupations.length === 0 ? (
            <Placeholder size="md" image="questions">
              <Typography is="span" type="halcyon" weight="bold">
                <FormattedMessage id={translations.pages.main.reporting.daytimeOccupation.empty} />
              </Typography>
            </Placeholder>
          ) : (
            <Reports.Charts>
              {value.atStart.daytimeOccupations.map((report, index) => (
                <React.Fragment key={index}>
                  {report.daytimeOccupation.data.length !== 0 ? (
                    <PieChart
                      title={intl.formatMessage(
                        {
                          id: translations.pages.main.reporting.daytimeOccupation.atStart
                        },
                        {
                          occupation:
                            report.labelIdentifierDto.translations &&
                            report.labelIdentifierDto.translations[language.toLowerCase()]
                              ? report.labelIdentifierDto.translations[language.toLowerCase()]
                              : report.labelIdentifierDto.name
                        }
                      )}
                      name={`${report.labelIdentifierDto.name.replace(/ /g, '-').toLowerCase()}-start`}
                      data={{
                        labels: report.daytimeOccupation.labels.map((label) =>
                          intl.formatMessage({
                            id: translations.occurenceStatus[label]
                          })
                        ),
                        dataset: report.daytimeOccupation.data
                      }}
                    />
                  ) : (
                    <Placeholder size="md" image="questions" className={style.placeholder}>
                      <Typography is="span" type="halcyon" weight="bold">
                        <FormattedMessage id={translations.pages.teams.participants.details.reports.socialMap.noData} />
                      </Typography>
                    </Placeholder>
                  )}

                  {value.atEnd.daytimeOccupations[index].daytimeOccupation.data.length !== 0 ? (
                    <PieChart
                      title={intl.formatMessage(
                        {
                          id: translations.pages.main.reporting.daytimeOccupation.atEnd
                        },
                        {
                          occupation:
                            value.atEnd.daytimeOccupations[index].labelIdentifierDto.translations &&
                            value.atEnd.daytimeOccupations[index].labelIdentifierDto.translations[
                              language.toLowerCase()
                            ]
                              ? value.atEnd.daytimeOccupations[index].labelIdentifierDto.translations[
                                  language.toLowerCase()
                                ]
                              : value.atEnd.daytimeOccupations[index].labelIdentifierDto.name
                        }
                      )}
                      name={`${value.atEnd.daytimeOccupations[index].labelIdentifierDto.name
                        .replace(/ /g, '-')
                        .toLowerCase()}-end`}
                      data={{
                        labels: value.atEnd.daytimeOccupations[index].daytimeOccupation.labels.map((label) =>
                          intl.formatMessage({
                            id: translations.occurenceStatus[label]
                          })
                        ),
                        dataset: value.atEnd.daytimeOccupations[index].daytimeOccupation.data
                      }}
                    />
                  ) : (
                    <Placeholder size="md" image="questions">
                      <Typography is="span" type="halcyon" weight="bold">
                        <FormattedMessage id={translations.pages.teams.participants.details.reports.socialMap.noData} />
                      </Typography>
                    </Placeholder>
                  )}
                </React.Fragment>
              ))}
            </Reports.Charts>
          )
        }
      </LoadData>
    </Reports>
  );
};

export default DaytimeOccupationSection;
