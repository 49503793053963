import { AgeGroup, NewAgeGroup } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getAgeGroups: () => Promise<AgeGroup[]> = () => authorizedApi.get<AgeGroup[]>(`/v1/age-groups`);

export const updateAgeGroup: (id: String, data: NewAgeGroup) => Promise<any> = (id, data) => {
  return authorizedApi.put(`/v1/age-groups/${id}`, data);
};

export const createAgeGroup: (data: NewAgeGroup) => Promise<any> = (data) => authorizedApi.post('/v1/age-groups', data);

export const deleteAgeGroup: (id: string) => Promise<any> = (id) => authorizedApi.delete(`/v1/age-groups/${id}`);
