import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { State, OrganizationUpdate } from '@/domains';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { emailSchema, phoneNumberSchema, taxIdSchema } from '@/validation';
import { updateOrganizationState } from '@/services/api/super-admin';
import { getPrepaidLicencesInformationForOrganization } from '@/services/api/licences';
import { getOrganizationPaymentProviderRedirectUrl } from '@/services/api/billing-info';
import { getOrganizationDetails, updateOrganizationDetails } from '@/services/api/organization';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Link from '@/components/Link';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import Refresh from '@/components/Refresh';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import LiveSubmit from '@/components/LiveSubmit';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import ToggleSwitch from '@/components/ToggleSwitch';
import PictureUpload from '@/components/PictureUpload';
import InputsContainer from '@/components/InputsContainer';
import InviteUsersModal from '@/components/Modals/InviteUsersModal';
import CountrySelect from '@/components/Selects/CountrySelect';
import { withValidation } from '@/components/hoc/withValidation';
import LanguageSelect from '@/components/Selects/LanguageSelect';
import PrepaidLicenceModal from '@/components/Modals/PrepaidLicenceModal';

import style from './Details.sass';

const TextInputWithValidation = withValidation(TextInput);
const LanguageSelectWithValidation = withValidation(LanguageSelect);
const CountrySelectWithValidation = withValidation(CountrySelect);

interface Params {
  id: string;
}

const organizationUpdateSchema: yup.ObjectSchema<OrganizationUpdate> = yup.object({
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.organizationName.label)
    .required(),
  notificationsLanguageCode: yup
    .string()
    .label(translations.inputs.emailLanguage.label)
    .required(),
  email: emailSchema.label(translations.inputs.email.label).required(),
  phoneNumber: phoneNumberSchema
    .label(translations.inputs.phoneNumber.label)
    .nullable()
    .notRequired(),
  officialCountry: yup
    .string()
    .label(translations.inputs.officialCountry.label)
    .nullable()
    .notRequired(),
  officialAddress: yup
    .string()
    .label(translations.inputs.officialAddress.label)
    .required(),
  taxId: taxIdSchema.label(translations.inputs.taxId.label).required(),
  websiteUrl: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.website.label)
    .nullable()
    .notRequired(),
  logoUrl: yup
    .mixed()
    .label(translations.inputs.picture.organization)
    .nullable()
    .notRequired(),
  ngoUrl: yup
    .mixed()
    .label(translations.inputs.picture.organization)
    .nullable()
    .notRequired(),
  contactPersonName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.fullName.label)
    .required(),
  contactPersonEmail: emailSchema.label(translations.inputs.email.label).required(),
  contactPersonPhoneNumber: phoneNumberSchema.label(translations.inputs.phoneNumber.label).required()
});

const Details: React.FC<WrappedComponentProps & RouteComponentProps<Params>> = ({ match, intl }) => {
  const organizationId = match.params.id;

  return (
    <LoadData load={() => getOrganizationDetails(organizationId)}>
      {({ value, loading, reload }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <Form
              id="organization-information"
              initialValues={value}
              schema={organizationUpdateSchema}
              subscription={{
                dirty: true,
                submitError: true,
                submitting: true,
                valid: true
              }}
              onSubmit={(values) => updateOrganizationDetails(value.id, values).then(reload)}
            >
              {({ dirty, valid, submitError, submitting }, id) => (
                <React.Fragment>
                  <Card.Row padded className={style.topRow}>
                    <div className={style.leftColumn}>
                      <Form.Field
                        is={PictureUpload}
                        id={`${id}-logo`}
                        name="logoUrl"
                        title={value.name}
                        accept="image/x-png,image/jpeg"
                        readOnly={submitting}
                      />
                    </div>

                    <div className={style.rightColumn}>
                      <LiveSubmit
                        loading={false}
                        value={{ fieldState: value.state }}
                        onChange={({ fieldState }) => updateOrganizationState(value.id, fieldState).then(reload)}
                      >
                        {({ value: { fieldState }, set }) => (
                          <ToggleSwitch
                            checked={fieldState === State.Enabled}
                            label={true}
                            onClick={() =>
                              set('fieldState', fieldState === State.Enabled ? State.Disabled : State.Enabled)
                            }
                            onChange={() => null}
                          />
                        )}
                      </LiveSubmit>

                      <LoadData id={organizationId} load={getOrganizationPaymentProviderRedirectUrl}>
                        {({ value: url, loading }) => (
                          <If
                            condition={!loading}
                            then={() =>
                              url && (
                                <Button is={Link} appearance="orange" external to={url} className={style.profileButton}>
                                  <FormattedMessage
                                    id={translations.pages.organizations.detail.tabs.details.customerProfile}
                                  />
                                </Button>
                              )
                            }
                          />
                        )}
                      </LoadData>
                    </div>
                  </Card.Row>

                  <Card.Column sm={12}>
                    <Typography is="h6" type="halcyon" color="gray">
                      <FormattedMessage id={translations.pages.organization.generalInformation.organizationInfo} />
                    </Typography>

                    <Spacer xs={1} />

                    <InputsContainer>
                      <div className={style.organizationName}>
                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-name`}
                          name="name"
                          label={intl.formatMessage({ id: translations.inputs.name.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.name.placeholder })}
                          readOnly={submitting}
                        />
                      </div>

                      <Form.Field
                        is={LanguageSelectWithValidation}
                        type="text"
                        id={`${id}-notifications-language-code`}
                        name="notificationsLanguageCode"
                        label={intl.formatMessage({ id: translations.inputs.emailLanguage.label })}
                        placeholder={intl.formatMessage({
                          id: translations.inputs.emailLanguage.placeholder
                        })}
                        readOnly={submitting}
                        searchable
                      />
                    </InputsContainer>

                    <Spacer xs={2} />

                    <div className={style.ngoRow}>
                      <Form.Field
                        is={PictureUpload}
                        id={`${id}-ngo`}
                        name="ngoUrl"
                        title={intl.formatMessage({ id: translations.inputs.picture.ngo })}
                        accept="image/x-png,image/jpeg"
                        readOnly={submitting}
                      />

                      <Button
                        is="a"
                        type="button"
                        appearance="ghost"
                        href={value.ngoUrl}
                        target="_blank"
                        className={style.downloadButton}
                      >
                        <Typography is="a" type="halcyon">
                          <FormattedMessage id={translations.inputs.picture.download} />
                        </Typography>
                      </Button>
                    </div>

                    <Spacer xs={3} />

                    <Typography is="h6" type="halcyon" color="gray">
                      <FormattedMessage id={translations.pages.organization.generalInformation.contact} />
                    </Typography>

                    <Spacer xs={1} />

                    <InputsContainer>
                      <Form.Field
                        is={TextInputWithValidation}
                        type="text"
                        id={`${id}-email`}
                        name="email"
                        label={intl.formatMessage({ id: translations.inputs.email.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                        readOnly={submitting}
                      />

                      <Form.Field
                        is={TextInputWithValidation}
                        type="text"
                        id={`${id}-phone-number`}
                        name="phoneNumber"
                        label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                        placeholder={intl.formatMessage({
                          id: translations.inputs.phoneNumber.placeholder
                        })}
                        readOnly={submitting}
                      />

                      <Form.Field
                        is={TextInputWithValidation}
                        type="text"
                        id={`${id}-website-url`}
                        name="websiteUrl"
                        label={intl.formatMessage({ id: translations.inputs.website.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.website.placeholder })}
                        readOnly={submitting}
                      />

                      <Form.Field
                        is={CountrySelectWithValidation}
                        id={`${id}-official-country`}
                        name="officialCountry"
                        label={intl.formatMessage({ id: translations.inputs.officialCountry.label })}
                        placeholder={intl.formatMessage({
                          id: translations.inputs.officialCountry.placeholder
                        })}
                      />

                      <Form.Field
                        is={TextInputWithValidation}
                        type="text"
                        id={`${id}-official-address`}
                        name="officialAddress"
                        label={intl.formatMessage({ id: translations.inputs.officialAddress.label })}
                        placeholder={intl.formatMessage({
                          id: translations.inputs.officialAddress.placeholder
                        })}
                        readOnly={submitting}
                      />

                      <Form.Field
                        is={TextInputWithValidation}
                        type="number"
                        id={`${id}-tax-id`}
                        name="taxId"
                        label={intl.formatMessage({ id: translations.inputs.taxId.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.taxId.placeholder })}
                        readOnly={submitting}
                      />
                    </InputsContainer>
                  </Card.Column>

                  <Card.Row>
                    <Card.Column sm={12}>
                      <Typography is="h6" type="halcyon" weight="bold">
                        <FormattedMessage id={translations.pages.organization.generalInformation.contactPerson} />
                      </Typography>

                      <Spacer xs={1} />

                      <InputsContainer>
                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-contact-person-name`}
                          name="contactPersonName"
                          label={intl.formatMessage({ id: translations.inputs.fullName.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.fullName.placeholder })}
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-contact-person-email`}
                          name="contactPersonEmail"
                          label={intl.formatMessage({ id: translations.inputs.email.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-contact-person-phone-number`}
                          name="contactPersonPhoneNumber"
                          label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                          placeholder={intl.formatMessage({
                            id: translations.inputs.phoneNumber.placeholder
                          })}
                          readOnly={submitting}
                        />
                      </InputsContainer>

                      <div className={style.buttons}>
                        <Modal modal={InviteUsersModal} organizationId={match.params.id}>
                          {({ open }) => (
                            <Button
                              type="button"
                              appearance="orange"
                              className={style.inviteAdminButton}
                              onClick={open}
                            >
                              <FormattedMessage id={translations.pages.organizations.detail.tabs.details.inviteAdmin} />
                            </Button>
                          )}
                        </Modal>

                        {!!submitError && (
                          <div className={style.submitError}>
                            <SubmitError error={submitError} />
                          </div>
                        )}

                        <Button
                          form={id}
                          type="submit"
                          appearance="orange"
                          className={style.saveButton}
                          block
                          loading={submitting}
                          disabled={!dirty || !valid}
                        >
                          <FormattedMessage id={translations.pages.organization.generalInformation.save} />
                        </Button>
                      </div>
                    </Card.Column>
                  </Card.Row>

                  <Card.Row padded className={style.licences}>
                    <Typography is="h6" type="swan" weight="bold">
                      <FormattedMessage
                        id={translations.pages.organization.licenses.tabs.billingInfo.prepaidLicensesTitle}
                      />
                    </Typography>

                    <Spacer xs={1} />

                    <Refresh>
                      {({ key, refresh }) => (
                        <LoadData key={key} load={() => getPrepaidLicencesInformationForOrganization(organizationId)}>
                          {({ value, loading }) => (
                            <If
                              condition={loading}
                              then={() => (
                                <Loading visible={loading} center overlay>
                                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                                </Loading>
                              )}
                              else={() => (
                                <If
                                  condition={value}
                                  then={() => (
                                    <React.Fragment>
                                      <Typography is="h6" type="sparrow" color="gray">
                                        <If
                                          condition={value.monthlyNumber === 0}
                                          then={() => (
                                            <FormattedMessage
                                              id={
                                                translations.pages.organization.licenses.tabs.billingInfo
                                                  .prepaidLicensesNone
                                              }
                                            />
                                          )}
                                          else={() => (
                                            <React.Fragment>
                                              <Typography
                                                is="span"
                                                type="swan"
                                                weight="bold"
                                                color="blue"
                                                className={style.centerVertical}
                                              >
                                                {`${value.monthlyNumber} `}
                                              </Typography>

                                              <span className={style.centerVertical}>
                                                <FormattedMessage
                                                  id={
                                                    translations.pages.organization.licenses.tabs.billingInfo
                                                      .prepaidLicensesUntil
                                                  }
                                                  values={{
                                                    licenseCount: value.monthlyNumber,
                                                    date: intl.formatDate(new Date(value.endDate), {
                                                      day: '2-digit',
                                                      month: 'short',
                                                      year: 'numeric'
                                                    })
                                                  }}
                                                />
                                              </span>
                                            </React.Fragment>
                                          )}
                                        />

                                        <If
                                          condition={value.monthlyNumber !== 0}
                                          then={() => (
                                            <Modal
                                              modal={PrepaidLicenceModal}
                                              organizationId={organizationId}
                                              onSubmit={refresh}
                                              initialValues={value}
                                            >
                                              {({ open }) => (
                                                <Typography
                                                  is={(props) => <Button {...props} type="button" />}
                                                  onClick={open}
                                                  type="sparrow"
                                                  color="orange"
                                                  weight="bold"
                                                  className={style.editLicenses}
                                                >
                                                  <FormattedMessage id={translations.buttons.edit} />
                                                </Typography>
                                              )}
                                            </Modal>
                                          )}
                                        />
                                      </Typography>

                                      <Spacer xs={2} />

                                      <If
                                        condition={value.monthlyNumber === 0}
                                        then={() => (
                                          <Modal
                                            modal={PrepaidLicenceModal}
                                            organizationId={organizationId}
                                            onSubmit={refresh}
                                          >
                                            {({ open }) => (
                                              <Button
                                                type="button"
                                                appearance="ghost"
                                                className={style.addNewButton}
                                                onClick={open}
                                              >
                                                <FormattedMessage id={translations.inputs.addNew} />
                                              </Button>
                                            )}
                                          </Modal>
                                        )}
                                      />
                                    </React.Fragment>
                                  )}
                                />
                              )}
                            />
                          )}
                        </LoadData>
                      )}
                    </Refresh>
                  </Card.Row>
                </React.Fragment>
              )}
            </Form>
          )}
        />
      )}
    </LoadData>
  );
};

export default withRouter(injectIntl(Details));
