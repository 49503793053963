import { Page, Note, CreateNote, UpdateNote } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getParticipantNotes: (
  participantId: string,
  page: number,
  size: number,
  filters: any
) => Promise<Page<Note>> = (participantId, pageNumber, pageSize, filters) =>
  authorizedApi.get<Page<Note>>(`/v1/notes`, { pageNumber, pageSize, participantIds: [participantId], ...filters });

export const createParticipantNote: (participantId: string, note: CreateNote) => Promise<any> = (participantId, note) =>
  authorizedApi.post(`/v1/notes`, { participantId, ...note });

export const updateParticipantNote: (noteId: string, note: UpdateNote) => Promise<any> = (noteId, noteDto) =>
  authorizedApi.put(`/v1/notes/${noteId}`, noteDto);

export const deleteParticipantNote: (noteId: string) => Promise<any> = (noteId) =>
  authorizedApi.delete(`/v1/notes/${noteId}`);
