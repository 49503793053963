import React from 'react';
import classnames from 'classnames';

import { getStylesWithSize } from '@/util';
import { WithSize } from '@/constants';

import style from './Data.sass';

type Magnitude = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18;

export interface Props extends WithSize<Magnitude> {
  breakWords?: boolean;
  className?: string;
}

export default class Data extends React.PureComponent<Props> {
  render() {
    const { breakWords, className, children } = this.props;
    return (
      <div
        className={classnames(
          style.root,
          getStylesWithSize(
            (size) => (style as any)[`${size}${this.props[size] || 0}`],
            (size) => this.props[size] != null
          ),
          { [style.breakWords]: breakWords },
          className
        )}
      >
        {children}
      </div>
    );
  }
}
