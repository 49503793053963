import React from 'react';
import classnames from 'classnames';

import style from './Typography.sass';

type Type =
  | 'ostrich'
  | 'flamingo'
  | 'albatross'
  | 'swan'
  | 'hawk'
  | 'corvus'
  | 'owl'
  | 'sparrow'
  | 'halcyon'
  | 'hummingbird';
export type Color = 'orange' | 'blue' | 'red' | 'gray' | 'white' | 'green';
type WeightType = 'lighter' | 'regular' | 'bold' | 'bolder';

export interface Props<T> {
  is: React.ElementType<T>;
  type?: Type;
  color?: Color;
  weight?: WeightType;
  className?: string;
  [x: string]: any;
}

export default class Typography<T extends any> extends React.PureComponent<Props<T>> {
  render() {
    const { is: Component, type = 'halcyon', color, weight, className, ...rest } = this.props;

    return (
      // @ts-ignore
      <Component
        {...rest}
        className={classnames(style[type!], !!color && style[color], !!weight && style[weight], className)}
      />
    );
  }
}
