import {
  BarChartReportData,
  PieChartReportData,
  Period,
  EvaluationStatus,
  MoodReportLabel,
  ReportEntry,
  DifferentiateTimeFrameComparisonReport,
  Label,
  SocialMapContact
} from '@/domains';

export enum ReportType {
  Session = 'sessions',
  Activity = 'activities',
  Mood = 'moods',
  Skill = 'skills',
  SocialMap = 'contacts',
  Goal = 'goals'
}

export interface Session {
  participationAtLocation: BarChartReportData;
  participationInSessionType: BarChartReportData;
  periods: Period[];
}

export interface Activity {
  attendedActivities: PieChartReportData;
  offeredActivitiesVsAttendedActivities: BarChartReportData;
  periods: Period[];
}

export interface Mood {
  averagePositivenessAndActivenessOnTimeFrame: BarChartReportData;
  evaluationsInQuadrants: PieChartReportData<MoodReportLabel>;
  periods: Period[];
  positivityAndActiveness: {
    activeness: number;
    positivity: number;
  };
}

export interface Skill {
  confidenceAndLearnStatusReport: {
    confidenceStatus: BarChartReportData;
    learningStatus: BarChartReportData;
  };
  evaluationsPerSkill: PieChartReportData;
  periods: Period[];
  skillEvaluationsInPeriods: Pick<ReportEntry<any>, 'data'>;
}

export interface SocialMap {
  networkDensity: DifferentiateTimeFrameComparisonReport;
  networkHelpfulness: DifferentiateTimeFrameComparisonReport;
  networkWarmth: DifferentiateTimeFrameComparisonReport;
  percentageOfNegativity: DifferentiateTimeFrameComparisonReport;
  sizeOfCoreNetwork: DifferentiateTimeFrameComparisonReport;
  socialMap: {
    participantContactsWithLatestEvaluation: BarChartReportData<Label, SocialMapContact>;
    participantContactsWithoutEvaluations: SocialMapContact[];
  };
}

export interface Goal {
  averageDurationInWeeks: number;
  goalEvaluationStatusesProportion: PieChartReportData<EvaluationStatus>;
  goalTypesUsed: PieChartReportData;
  goalsCompletedByCompletionStatus: BarChartReportData;
  goalsStarted: Pick<ReportEntry<any>, 'data'>;
  periods: Period[];
}
