import { Me, UpdateMe, UpdatePassword, MediaFileType, Team } from '@/domains';
import { uploadImage } from '@/services/api/media';
import { authorizedApi } from '@/services/network/authorized-api';

export const getMe = (): Promise<Me> =>
  authorizedApi
    .get<{ user: Me; openReminders: number; teams: Team[] }>(`/v1/users/me`)
    .then(({ user, openReminders, ...rest }) => ({
      ...user,
      openReminders,
      team: rest.teams ? rest.teams[0] || null : null
    }));

export const updateProfile: (data: UpdateMe) => Promise<Me> = ({ imageUrl, ...data }) =>
  uploadImage(imageUrl, MediaFileType.ProfilePicture).then((url) =>
    authorizedApi.put(`/v1/users/me`, { imageUrl: url, ...data })
  );

export const updatePassword = (data: UpdatePassword): Promise<any> => authorizedApi.put(`/v1/users/me/password`, data);
