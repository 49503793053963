declare var CONFIG: {
  API: string;
  KEYCLOAK_API: string;
  STRIPE_PUBLIC_KEY: string;
  ENVIRONMENT: string;
  SENTRY_DSN?: string;
  RELEASE?: string;
};

export const { API, KEYCLOAK_API, STRIPE_PUBLIC_KEY, ENVIRONMENT, SENTRY_DSN, RELEASE } = CONFIG;
