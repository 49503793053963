import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Image } from '@/domains';
import { translations } from '@/locale';

import Typography from '@/components/Typography';
import FileUpload from '@/components/FileUpload';
import TextWithIcon from '@/components/TextWithIcon';
import If from '@/components/If';
import Loading from '@/components/Loading';
import DropdownPicture, { Props as DropdownPictureProps } from '@/components/DropdownPicture';

import style from './PhotoGallery.sass';

export interface Props extends Pick<DropdownPictureProps, 'size'> {
  images: Image[];
  empty: () => any;
  onPhotosUpload: (photos: File[]) => void;
  onPhotoDelete: (id: string) => void;
  title?: string;
  loading?: boolean;
  className?: string;
}

export default class PhotoGallery extends React.PureComponent<Props> {
  render() {
    const { size, images, empty, onPhotosUpload, onPhotoDelete, title, loading, className } = this.props;

    return (
      <div className={classnames(style.root, className)}>
        <div className={style.header}>
          {title && (
            <Typography is="span" type="halcyon" weight="bold">
              {title}
            </Typography>
          )}

          <FileUpload
            accept="image/x-png,image/jpeg"
            onChange={(files: FileList) => onPhotosUpload(Object.values(files))}
            className={style.upload}
            multiple
          >
            <TextWithIcon is="span" type="halcyon" icon="upload" color="orange" boxed className={style.text}>
              <FormattedMessage id={translations.inputs.photoGallery.upload} />
            </TextWithIcon>
          </FileUpload>
        </div>

        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <If
              condition={images.length === 0}
              then={empty}
              else={() => (
                <ul className={style.photosContainer}>
                  {images.map((image, index) => (
                    <li key={index} className={style.photo}>
                      {
                        //@ts-ignore
                        <DropdownPicture
                          size={size}
                          value={image.imageUrl}
                          downloadable
                          deletable
                          onPictureDelete={() => onPhotoDelete(image.id)}
                        />
                      }
                    </li>
                  ))}
                </ul>
              )}
            />
          )}
        />
      </div>
    );
  }
}
