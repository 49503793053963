import { PieChartReportData, MoodReportLabel } from '@/domains';

export enum ReportType {
  Mood = 'moods',
  Participant = 'participants',
  Skill = 'skills'
}

export interface Participant {
  ageReport: PieChartReportData<string>;
  genderReport: PieChartReportData<string>;
}

export interface Mood {
  evaluationsInQuadrants: PieChartReportData<MoodReportLabel>;
}

export interface Skill {
  confidenceReport: any;
  learnReport: any;
  numberOfSkillEvaluations: number;
}
