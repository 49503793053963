import React from 'react';
import { useIntl } from 'react-intl';
import { formatISO, startOfMonth, endOfMonth } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '@/locale';
import { checkIfWeShouldDeselectAll } from '@/util';
import {
  TimeFrame,
  ReportFilters,
  Filter,
  EvaluationModuleState,
  EvaluationModuleType,
  DataOptionType
} from '@/domains';
import { getEvaluationModules } from '@/services/api/organization';

import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import LazyRender from '@/components/LazyRender';
import { AppContext } from '@/components/Context/App';
import ErrorBoundary from '@/components/ErrorBoundary';
import DatePickerInput from '@/components/DatePickerInput';
import AgeGroupSelect from '@/components/Selects/AgeGroupSelect';
import TimeFrameSelect from '@/components/Selects/TimeFrameSelect';
import SessionTypeSelect from '@/components/Selects/SessionTypeSelect';
import SessionLabelSelect from '@/components/Selects/SessionLabelSelect';
import LocationSimpleSelect from '@/components/Selects/LocationSimpleSelect';
import ToggleShowOnlyActive from '@/components/ToggleSwitch/ToggleShowOnlyActive';
import FilterManagerWithPersistence from '@/components/FilterManagerWithPersistence';

import SessionSection from './SessionSection';
import ActivitySection from './ActivitySection';
import MoodSection from './MoodSection';
import SkillSection from './SkillSection';
import SocialMapSection from './SocialMapSection';
import GoalSection from './GoalSection';

import style from './Reports.sass';
import { useAsync } from 'react-use';
import { getDataOptions } from '@/services/api/data-option';
import { getAllLocations } from '@/services/api/location';
import { isActive } from '@/util/isActive';
import { getIds } from '@/util/getIds';

interface Params {
  id: string;
}

const Reports: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const [showOnlyActive, setShowOnlyActive] = React.useState<boolean>(false);

  const { id } = match.params;

  const { team } = React.useContext(AppContext);

  const intl = useIntl();

  const { value: data, loading: dataLoading } = useAsync(
    () =>
      Promise.all([getDataOptions(DataOptionType.SessionType), getAllLocations()]).then(([sessionTypes, locations]) => {
        return {
          sessionTypes: sessionTypes
            .filter((sessionType) => (showOnlyActive === true ? isActive(sessionType) : true))
            .map((sessionType) => ({ id: sessionType.id, name: sessionType.name })),

          locations: locations.content
            .filter((location) => (showOnlyActive === true ? isActive(location) : true))
            .map(({ id, name }) => ({ id, name }))
        };
      }),
    [showOnlyActive]
  );

  const criteriaWithActive = (criteria: ReportFilters): ReportFilters => {
    if (showOnlyActive === false) return criteria;

    return {
      ...criteria,
      sessionTypeIds:
        criteria.sessionTypeIds.includes(Filter.All) || criteria.sessionTypeIds.length === 0
          ? getIds(data.sessionTypes)
          : criteria.sessionTypeIds,
      locationIds:
        criteria.locationIds.includes(Filter.All) || criteria.locationIds.length === 0
          ? getIds(data.locations)
          : criteria.locationIds
    };
  };

  return (
    <LoadData load={() => getEvaluationModules()}>
      {({ value, loading }) => (
        <If
          condition={loading && dataLoading}
          then={() => (
            <Loading visible={loading} center overlay>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => {
            const enabledModules = new Set(
              value.filter((item) => item.state === EvaluationModuleState.Enabled).map((item) => item.type)
            );

            return (
              <Card.Row>
                <Card.Column sm={12}>
                  <ErrorBoundary>
                    <FilterManagerWithPersistence
                      storageItem="participant-reports"
                      initialCriteria={{
                        start: formatISO(startOfMonth(new Date()), { representation: 'date' }),
                        end: formatISO(endOfMonth(new Date()), { representation: 'date' }),
                        timeFrame: TimeFrame.Monthly,
                        locationIds: [Filter.All],
                        ageGroupIds: [Filter.All],
                        sessionTypeIds: [Filter.All],
                        labelIds: [Filter.All]
                      }}
                    >
                      {({ criteria, updateCriteria }) => (
                        <React.Fragment>
                          <div className={style.toggle}>
                            <ToggleShowOnlyActive toggleState={showOnlyActive} {...{ setShowOnlyActive }} />
                          </div>

                          <div className={style.filters}>
                            <div className={style.row}>
                              <div className={style.withLabel}>
                                <DatePickerInput
                                  id="start"
                                  value={criteria.start}
                                  label={intl.formatMessage({
                                    id: translations.pages.teams.participants.details.reports.filters.from
                                  })}
                                  change={(_, value) => updateCriteria('start', value)}
                                  inputClassName={style.datePicker}
                                />
                              </div>

                              <div className={style.withLabel}>
                                <DatePickerInput
                                  id="end"
                                  value={criteria.end}
                                  label={intl.formatMessage({
                                    id: translations.pages.teams.participants.details.reports.filters.until
                                  })}
                                  change={(_, value) => updateCriteria('end', value)}
                                  inputClassName={style.datePicker}
                                />
                              </div>
                            </div>

                            <div className={style.withLabel}>
                              <TimeFrameSelect
                                appearance="normal"
                                label={intl.formatMessage({
                                  id: translations.pages.teams.participants.details.reports.filters.show
                                })}
                                id="time-frame"
                                value={criteria.timeFrame}
                                onChange={(value) => updateCriteria('timeFrame', value)}
                              />
                            </div>

                            <div className={style.withLabel}>
                              <LocationSimpleSelect
                                id="location"
                                key={`${showOnlyActive}-location`}
                                appearance="normal"
                                label={intl.formatMessage({ id: translations.pages.main.reporting.filters.location })}
                                value={criteria.locationIds}
                                onChange={(value) =>
                                  updateCriteria('locationIds', checkIfWeShouldDeselectAll([...value]))
                                }
                                {...{ showOnlyActive }}
                                searchable
                                multiple
                                editable
                              />
                            </div>

                            <div className={style.withLabel}>
                              <AgeGroupSelect
                                id="age-group"
                                appearance="normal"
                                label={intl.formatMessage({ id: translations.pages.main.reporting.filters.ageGroups })}
                                value={criteria.ageGroupIds}
                                onChange={(value) =>
                                  updateCriteria('ageGroupIds', checkIfWeShouldDeselectAll([...value]))
                                }
                                searchable
                                multiple
                                editable
                              />
                            </div>

                            <div className={style.withLabel}>
                              <SessionTypeSelect
                                key={`${showOnlyActive}-activity`}
                                appearance="normal"
                                label={intl.formatMessage({
                                  id: translations.pages.main.reporting.filters.sessionType
                                })}
                                id="session-type-ids"
                                value={criteria.sessionTypeIds}
                                onChange={(value) => {
                                  updateCriteria('sessionTypeIds', checkIfWeShouldDeselectAll([...value]));
                                }}
                                {...{ showOnlyActive }}
                                searchable
                                multiple
                              />
                            </div>

                            <div className={style.withLabel}>
                              <SessionLabelSelect
                                id="session-labels"
                                appearance="normal"
                                label={intl.formatMessage({
                                  id: translations.pages.main.reporting.filters.sessionLabel
                                })}
                                value={criteria.labelIds}
                                onChange={(value) => updateCriteria('labelIds', checkIfWeShouldDeselectAll([...value]))}
                                searchable
                                multiple
                              />
                            </div>
                          </div>

                          <Spacer xs={6} />

                          <SessionSection
                            participantId={id}
                            teamId={team.id}
                            key={JSON.stringify({ ...criteria, type: 'session' })}
                            intl={intl}
                            criteria={criteriaWithActive(criteria as ReportFilters)}
                          />

                          <Spacer xs={6} />

                          <LazyRender height={337}>
                            <ActivitySection
                              participantId={id}
                              teamId={team.id}
                              key={JSON.stringify({ ...criteria, type: 'activity' })}
                              intl={intl}
                              criteria={criteriaWithActive(criteria as ReportFilters)}
                            />
                          </LazyRender>

                          {enabledModules.has(EvaluationModuleType.Mood) && (
                            <React.Fragment>
                              <Spacer xs={6} />

                              <LazyRender height={337}>
                                <MoodSection
                                  participantId={id}
                                  teamId={team.id}
                                  key={JSON.stringify({ ...criteria, type: 'mood' })}
                                  intl={intl}
                                  criteria={criteriaWithActive(criteria as ReportFilters)}
                                />
                              </LazyRender>
                            </React.Fragment>
                          )}

                          {enabledModules.has(EvaluationModuleType.Skill) && (
                            <React.Fragment>
                              <Spacer xs={6} />

                              <LazyRender height={903}>
                                <SkillSection
                                  participantId={id}
                                  teamId={team.id}
                                  key={JSON.stringify({ ...criteria, type: 'skill' })}
                                  intl={intl}
                                  criteria={criteriaWithActive(criteria as ReportFilters)}
                                />
                              </LazyRender>
                            </React.Fragment>
                          )}

                          {enabledModules.has(EvaluationModuleType.SocialMap) && (
                            <React.Fragment>
                              <Spacer xs={6} />

                              <LazyRender height={337}>
                                <SocialMapSection
                                  participantId={id}
                                  teamId={team.id}
                                  key={JSON.stringify({ ...criteria, type: 'social-map' })}
                                  intl={intl}
                                  criteria={criteriaWithActive(criteria as ReportFilters)}
                                />
                              </LazyRender>
                            </React.Fragment>
                          )}

                          {enabledModules.has(EvaluationModuleType.Goal) && (
                            <React.Fragment>
                              <Spacer xs={6} />

                              <LazyRender height={903}>
                                <GoalSection
                                  participantId={id}
                                  teamId={team.id}
                                  key={JSON.stringify({ ...criteria, type: 'goal' })}
                                  intl={intl}
                                  criteria={criteriaWithActive(criteria as ReportFilters)}
                                />
                              </LazyRender>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </FilterManagerWithPersistence>
                  </ErrorBoundary>
                </Card.Column>
              </Card.Row>
            );
          }}
        />
      )}
    </LoadData>
  );
};

export default withRouter(Reports);
