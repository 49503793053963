import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const OrganizationHeader: React.FC = () => (
  <Typography is={Card.Row} type="hummingbird" color="gray" weight="bold" className={cellStyle.header}>
    <div className={cellStyle.organisationName}>
      <FormattedMessage id={translations.pages.organizations.list.table.header.organizationName} />
    </div>

    <div className={cellStyle.address}>
      <FormattedMessage id={translations.pages.organizations.list.table.header.address} />
    </div>

    <div className={cellStyle.registered}>
      <FormattedMessage id={translations.pages.organizations.list.table.header.registered} />
    </div>

    <div className={cellStyle.paymentStatus}>
      <FormattedMessage id={translations.pages.organizations.list.table.header.paymentStatus} />
    </div>

    <div className={cellStyle.accountStatus}>
      <FormattedMessage id={translations.pages.organizations.list.table.header.account} />
    </div>
  </Typography>
);

export default OrganizationHeader;
