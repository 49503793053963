import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getSurvey } from '@/services/api/survey';
import { surveryStatusColor } from '@/constants/survery-status';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import Breadcrumb from '@/components/Breadcrumb';
import Tab from '@/components/TabbedContainer/Tab';
import BoxedContent from '@/components/BoxedContent';
import TabbedContainer from '@/components/TabbedContainer';
import TextWithIcon from '@/components/TextWithIcon';

import Responses from './Responses';
import Participants from './Participants';
import SurveyDetail from './SurveyDetail';

import style from './Detail.sass';

interface Params {
  id: string;
}

export interface Props {}

const Detail: React.FC<Props & RouteComponentProps<Params>> = ({ history, match }) => {
  const id = match.params.id;
  const intl = useIntl();

  const getTitle = (value) => (
    <Card.Row className={style.headline}>
      <div className={style.title}>
        <Breadcrumb
          shape={{
            [intl.formatMessage({ id: translations.pages.digitalCoach.title })]: urls.digitalCoach.coach,
            [intl.formatMessage({ id: translations.pages.digitalCoach.survey.title })]: null,
            [`${value.title}`]: null
          }}
        />

        <Typography
          is={BoxedContent}
          appearance={surveryStatusColor[value.status]}
          className={style.status}
          type="hummingbird"
        >
          {value.status && <FormattedMessage id={translations.surveyStatus[value.status]} />}
        </Typography>
      </div>

      <Button type="button" onClick={() => history.push(urls.digitalCoach.survey.new, { data: value })}>
        <TextWithIcon is="span" type="halcyon" icon="duplicate" boxed iconColor="orange" color="orange">
          <FormattedMessage id={translations.pages.digitalCoach.survey.duplicate} />
        </TextWithIcon>
      </Button>
    </Card.Row>
  );

  return (
    <Refresh>
      {({ key, refresh }) => (
        <LoadData key={key} id={id} load={getSurvey}>
          {({ value, loading }) =>
            !loading && (
              <Card className={style.card}>
                <TabbedContainer>
                  <Tab
                    contentBeforeTitle={getTitle(value)}
                    title={<FormattedMessage id={translations.pages.digitalCoach.survey.tabs.details.title} />}
                  >
                    <SurveyDetail data={value} refresh={refresh} />
                  </Tab>

                  <Tab
                    contentBeforeTitle={getTitle(value)}
                    title={<FormattedMessage id={translations.pages.digitalCoach.survey.tabs.participants.title} />}
                  >
                    <Participants />
                  </Tab>

                  <Tab
                    contentBeforeTitle={getTitle(value)}
                    title={<FormattedMessage id={translations.pages.digitalCoach.survey.tabs.responses.title} />}
                  >
                    <Responses />
                  </Tab>
                </TabbedContainer>
              </Card>
            )
          }
        </LoadData>
      )}
    </Refresh>
  );
};

export default withRouter(Detail);
