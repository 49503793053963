import React from 'react';
import classnames from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls, rules } from '@/constants';
import { translations } from '@/locale';
import { MemberBase } from '@/domains';
import { getOrganizationMembers } from '@/services/api/organization';
import { getExportUsersRedirectUrl } from '@/services/api/export-url';

import Card from '@/components/Card';
import Link from '@/components/Link';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Member from '@/components/Member';
import HasRole from '@/components/HasRole';
import Refresh from '@/components/Refresh';
import Loading from '@/components/Loading';
import TextInput from '@/components/TextInput';
import ExportData from '@/components/ExportData';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import EntityPreview from '@/components/EntityPreview';
import FilterManager from '@/components/FilterManager';
import InfiniteScroll from '@/components/InfiniteScroll';
import { SessionContext } from '@/components/Context/Session';
import InviteUsersModal from '@/components/Modals/InviteUsersModal';

import Details from './Details';

import style from './Members.sass';

const Members: React.FC = () => {
  const intl = useIntl();

  const { organization } = React.useContext(SessionContext);

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

      <Spacer xs={4} />

      <Refresh>
        {({ key, refresh }) => (
          <Card className={style.card}>
            <div className={style.leftColumn}>
              <div className={style.members}>
                <TextWithIcon is="h6" type="hawk" boxed iconColor="blue" weight="bold" icon="myTeam">
                  <FormattedMessage id={translations.pages.organization.members.title} />
                </TextWithIcon>

                <Modal modal={InviteUsersModal}>
                  {({ open }) => (
                    <Button appearance="orange" type="button" onClick={open}>
                      <FormattedMessage id={translations.pages.organization.members.addUser} />
                    </Button>
                  )}
                </Modal>
              </div>

              <HasRole to={rules.TEAM_EXPORT_MEMBERS}>
                <ExportData redirect={getExportUsersRedirectUrl}>
                  {({ download }) => (
                    <div className={style.downloadWrapper}>
                      <TextWithIcon
                        is="span"
                        type="halcyon"
                        icon="download"
                        color="orange"
                        boxed
                        onClick={download}
                        className={style.text}
                      >
                        <FormattedMessage id={translations.miscellaneous.download} />
                      </TextWithIcon>
                    </div>
                  )}
                </ExportData>
              </HasRole>

              <FilterManager initialCriteria={{}}>
                {({ criteria, debouncedUpdate }) => (
                  <React.Fragment>
                    <div className={style.searchBar}>
                      <TextInput
                        type="text"
                        id="search"
                        onChange={(event) => debouncedUpdate('search', event.target.value)}
                        label={intl.formatMessage({ id: translations.inputs.membersSearch.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.membersSearch.placeholder })}
                        autoComplete="off"
                        leadingIcon="search"
                      />
                    </div>

                    <Card.Row className={style.membersList}>
                      <InfiniteScroll
                        key={key}
                        id={criteria.search}
                        size={15}
                        source={(page, number) => getOrganizationMembers(page, number, criteria.search)}
                        className={style.list}
                      >
                        {({ data, loading }) => (
                          <ul className={style.inlineBlock}>
                            {!loading && data.length === 0 ? (
                              <Placeholder image="people" size="xs" className={style.placeholder}>
                                <Typography is="h6" type="halcyon" weight="bold">
                                  <FormattedMessage id={translations.pages.organization.members.noResults} />
                                </Typography>
                              </Placeholder>
                            ) : (
                              data.map((member: MemberBase) => (
                                <Card.Row
                                  is="li"
                                  key={member.id}
                                  className={classnames(style.inlineBlock, style.member)}
                                >
                                  <Member is={Link} to={urls.members.get(member.id)} member={member} hoverable block />
                                </Card.Row>
                              ))
                            )}

                            <Loading visible={loading} center className={style.loadingIndicator}>
                              <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                            </Loading>
                          </ul>
                        )}
                      </InfiniteScroll>
                    </Card.Row>
                  </React.Fragment>
                )}
              </FilterManager>
            </div>

            <div className={style.rightColumn}>
              <Switch>
                <Route
                  exact
                  path={urls.members.list}
                  render={() => (
                    <Placeholder image="questions" size="sm" className={style.placeholder}>
                      <Typography is="h6" type="hawk" weight="bold">
                        <FormattedMessage id={translations.pages.organization.members.noneSelected} />
                      </Typography>
                    </Placeholder>
                  )}
                />

                <Route exact path={urls.members.detail} render={() => <Details onMemberDetailsChanged={refresh} />} />
              </Switch>
            </div>
          </Card>
        )}
      </Refresh>
    </div>
  );
};

export default Members;
