import { withPagingParams } from '@/util';
import { Organization, OrganizationWithPaymentStatus, Page } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getOrganizations: (
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<Organization>> = (page, size, options) =>
  authorizedApi.get(`/v1/organizations?${withPagingParams(page, size, { ...options })}`);

export const updateOrganizationState: (id: string, state: string) => Promise<any> = (id, state) =>
  authorizedApi.put(`/v1/organizations/${id}/state`, { state });

export const inviteAdminToOrganization: (id: string, email: string) => Promise<any> = (id, email) =>
  authorizedApi.post<any>(`/v1/organizations/${id}/invite`, { email });

export const getOrganizationsWithPaymentStatus: (
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<OrganizationWithPaymentStatus>> = (page, size, options) =>
  authorizedApi.get(`/v1/organizations/payments?${withPagingParams(page, size, { ...options })}`);
