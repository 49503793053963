import React, { PureComponent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '@/locale';
import { EmergencyContact } from '@/domains';
import { getEmergencyContacts } from '@/services/api/emergency-contacts';

import If from '@/components/If';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import FilterManager from '@/components/FilterManager';
import { AppConsumer } from '@/components/Context/App';
import EmergencyContactModal from '@/components/Modals/EmergencyContactModal';

import EmergencyInfoRow from './EmergencyInfoRow';
import EmergencyInfoHeader from './EmergencyInfoHeader';

import style from './EmergencyInfo.sass';

interface Params {
  id: string;
}

export interface Props {}

class EmergencyInfo extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <AppConsumer>
        {({ team, loading }) => (
          <If
            condition={!loading}
            then={() => (
              <Refresh>
                {({ key, refresh }) => (
                  <div className={style.root}>
                    <FilterManager initialCriteria={{}}>
                      {({ criteria, debouncedUpdate }) => (
                        <React.Fragment>
                          <div className={style.searchAndButton}>
                            <TextInput
                              type="text"
                              id="search"
                              onChange={(event) => debouncedUpdate('search', event.target.value)}
                              label={intl.formatMessage({ id: translations.inputs.emergencyContactSearch.label })}
                              placeholder={intl.formatMessage({
                                id: translations.inputs.emergencyContactSearch.placeholder
                              })}
                              autoComplete="off"
                              className={style.search}
                              leadingIcon="search"
                            />
                            <Modal modal={EmergencyContactModal} onSubmit={refresh}>
                              {({ open }) => (
                                <Button fat appearance="orange" onClick={open}>
                                  <FormattedMessage
                                    id={translations.pages.digitalCoach.tabs.emergencyInfo.newContact}
                                  />
                                </Button>
                              )}
                            </Modal>
                          </div>

                          <Spacer xs={3} />

                          <Table
                            key={key}
                            id={`${JSON.stringify({ criteria })}`}
                            source={(page, number) =>
                              getEmergencyContacts(team.id, page, number, { keyword: criteria.search })
                            }
                            empty={() => (
                              <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                                <Typography is="span" type="swan" weight="bold">
                                  <FormattedMessage
                                    id={translations.pages.digitalCoach.tabs.emergencyInfo.table.empty}
                                  />
                                </Typography>
                              </Placeholder>
                            )}
                            renderHeader={() => <EmergencyInfoHeader />}
                            renderRow={(data: EmergencyContact) => (
                              <EmergencyInfoRow key={data.id} data={data} onDelete={refresh} onUpdate={refresh} />
                            )}
                          />
                        </React.Fragment>
                      )}
                    </FilterManager>
                  </div>
                )}
              </Refresh>
            )}
          />
        )}
      </AppConsumer>
    );
  }
}

export default injectIntl(withRouter(EmergencyInfo));
