import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { moodPallete, qualitativePalette } from '@/constants';
import { getParticipantReports } from '@/services/api/participant-reports';
import { getLabelsFromPeriodAndTimeFrame, roundToTwoDecimals, getMoodReportLabel } from '@/util';
import { ReportFilters, ParticipantReport, TopicFilter, MoodState } from '@/domains';

import If from '@/components/If';
import Spacer from '@/components/Spacer';
import Reports from '@/components/Reports';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import { LanguageContext } from '@/components/Language';
import { PieChart, BarChart } from '@/components/Charts';

import style from '../Reports.sass';

export interface Props extends WrappedComponentProps {
  participantId: string;
  teamId: string;
  criteria: ReportFilters;
}

const MoodSection: React.FC<Props> = ({ intl, participantId, teamId, criteria }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.teams.participants.details.reports.mood.title
      })}
    >
      <LoadData
        key={JSON.stringify(criteria)}
        load={() =>
          getParticipantReports<ParticipantReport.Mood>(
            participantId,
            teamId,
            criteria,
            ParticipantReport.ReportType.Mood
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <React.Fragment>
                <Reports.Charts>
                  <BarChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.mood.positivityAndActiveness
                    })}
                    name="positivity-and-activeness"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                      datasets: value.averagePositivenessAndActivenessOnTimeFrame.entries.map((entry, index) => ({
                        label: intl.formatMessage({
                          id:
                            entry.label.name === MoodState.Active
                              ? translations.moodState[entry.label.identifier]
                              : translations.moodType[entry.label.identifier]
                        }),
                        data: entry.data,
                        backgroundColor: qualitativePalette[index]
                      }))
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              min: -1,
                              max: 1
                            }
                          }
                        ]
                      }
                    }}
                  />

                  <PieChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.mood.moodsRegistered
                    })}
                    name="moods"
                    data={{
                      labels: value.evaluationsInQuadrants.labels.map((label) =>
                        intl.formatMessage({
                          id: translations.reportMood[getMoodReportLabel(label.type, label.state)]
                        })
                      ),
                      dataset: value.evaluationsInQuadrants.data
                    }}
                    pallete={moodPallete}
                  />
                </Reports.Charts>

                <Spacer xs={2} />

                <Reports.ItemsContainer>
                  <Reports.Item
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.mood.averagePositivity
                    })}
                    value={roundToTwoDecimals(value.positivityAndActiveness.positivity)}
                  />

                  <Reports.Item
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.mood.averageActiveness
                    })}
                    value={roundToTwoDecimals(value.positivityAndActiveness.activeness)}
                  />
                </Reports.ItemsContainer>
              </React.Fragment>
            )}
          />
        )}
      </LoadData>

      <Spacer xs={2} />

      <Reports.Notes criteria={criteria} participantId={participantId} topic={TopicFilter.Moods} />
    </Reports>
  );
};

export default MoodSection;
