import React from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { qualitativePalette, skillConfidencePalette, skillProgressPalette } from '@/constants';
import { ReportFilters, ParticipantReport, TopicFilter } from '@/domains';
import {
  getParticipantDaytimeOccupationsReport,
  getParticipantEvaluatedSkills,
  getParticipantReports
} from '@/services/api/participant-reports';
import { getColorFromPalleteByIndex, getLabelsFromPeriodAndTimeFrame, multiplyPalleteUpToLength } from '@/util';

import If from '@/components/If';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Reports from '@/components/Reports';
import Loading from '@/components/Loading';
import Picture from '@/components/Picture';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppConsumer } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import ChartModal from '@/components/Modals/ChartModal';
import { BarChart, StackedBarChart } from '@/components/Charts';

import DaytimeOccupationHeader from './DaytimeOccupationHeader';
import DaytimeOccupationRow from './DaytimeOccupationRow';

import style from '../Reports.sass';

export interface Props extends WrappedComponentProps {
  participantId: string;
  teamId: string;
  criteria: ReportFilters;
}

export enum LearningStatuses {
  veryNegative = 'VERY_NEGATIVE',
  negative = 'NEGATIVE',
  neutral = 'NEUTRAL',
  positive = 'POSITIVE',
  veryPositive = 'VERY_POSITIVE'
}

export enum ConfidenceStatuses {
  negative = 'NEGATIVE',
  neutral = 'NEUTRAL',
  positive = 'POSITIVE'
}

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          stepSize: 1
        }
      }
    ]
  }
};

const SkillSection: React.FC<Props> = ({ intl, participantId, teamId, criteria }) => {
  const { language } = React.useContext(LanguageContext);
  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.teams.participants.details.reports.skills.title
      })}
    >
      <LoadData
        key={JSON.stringify(criteria)}
        load={() =>
          getParticipantReports<ParticipantReport.Skill>(
            participantId,
            teamId,
            criteria,
            ParticipantReport.ReportType.Skill
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Reports.Charts>
                <BarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.skills.evaluationsPerSkill
                  })}
                  name="evaluations-per-skill"
                  data={{
                    labels: [
                      intl.formatMessage({
                        id: translations.pages.teams.participants.details.reports.skills.evaluationsPerSkill
                      })
                    ],
                    datasets: value.evaluationsPerSkill.labels.map((label, index) => ({
                      label:
                        label.translations && label.translations[language.toLowerCase()]
                          ? label.translations[language.toLowerCase()]
                          : label.name,
                      data: [value.evaluationsPerSkill.data[index]],
                      backgroundColor: multiplyPalleteUpToLength(
                        value.evaluationsPerSkill.labels.length,
                        qualitativePalette
                      )[index]
                    }))
                  }}
                  className={style.wide}
                  options={options}
                  height={400}
                />

                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.skills.skillProgress
                  })}
                  name="skill-progress"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: Object.keys(LearningStatuses).map((key, index) => ({
                      label: intl.formatMessage({ id: translations.evaluationStatuses[LearningStatuses[key]] }),
                      data: value.confidenceAndLearnStatusReport.learningStatus.entries[0].data.map(
                        (entry) => entry[key]
                      ),
                      stack: `stack-0`,
                      backgroundColor: getColorFromPalleteByIndex(skillProgressPalette, index)
                    }))
                  }}
                  options={options}
                  className={style.wide}
                  height={279}
                />

                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.skills.skillConfidence
                  })}
                  name="skill-confidence"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: Object.keys(ConfidenceStatuses).map((key, index) => ({
                      label: intl.formatMessage({ id: translations.evaluationStatuses[ConfidenceStatuses[key]] }),
                      data: value.confidenceAndLearnStatusReport.confidenceStatus.entries[0].data.map(
                        (entry) => entry[key]
                      ),
                      stack: `stack-0`,
                      backgroundColor: getColorFromPalleteByIndex(skillConfidencePalette, index)
                    }))
                  }}
                  options={options}
                  className={style.wide}
                  height={279}
                />

                <div className={style.container}>
                  <Typography type="halcyon" is="span" color="gray">
                    <FormattedMessage
                      id={translations.pages.teams.participants.details.reports.skills.individualSkillReports}
                    />
                  </Typography>

                  <Spacer xs={1} />

                  <LoadData
                    key={JSON.stringify(criteria)}
                    load={() => getParticipantEvaluatedSkills<any>(participantId, teamId, criteria)}
                  >
                    {({ value, loading }) => (
                      <If
                        condition={loading}
                        then={() => (
                          <Loading visible={loading} center className={style.loading}>
                            <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                          </Loading>
                        )}
                        else={() => (
                          <If
                            condition={value.length !== 0}
                            then={() => (
                              <div className={style.pillsContainer}>
                                <Spacer xs={2} />

                                {value.map((item) => (
                                  <Modal
                                    key={item.id}
                                    modal={ChartModal}
                                    title={
                                      Object.keys(item.translations).length !== 0
                                        ? item.translations[language.toLowerCase()]
                                        : item.name
                                    }
                                    teamId={teamId}
                                    participantId={participantId}
                                    criteria={criteria}
                                    skillId={item.id}
                                  >
                                    {({ open }) => (
                                      <Button
                                        appearance="ghostBlue"
                                        type="button"
                                        className={style.pill}
                                        onClick={open}
                                      >
                                        <Picture url={item.icon ? item.icon.url : null} size="xs" />
                                        <Typography type="halcyon" is="span" className={style.pillText}>
                                          {Object.keys(item.translations).length !== 0
                                            ? item.translations[language.toLowerCase()]
                                            : item.name}
                                        </Typography>
                                      </Button>
                                    )}
                                  </Modal>
                                ))}
                              </div>
                            )}
                            else={() => '-'}
                          />
                        )}
                      />
                    )}
                  </LoadData>

                  <Spacer xs={3} />

                  <Typography is="div" type="corvus" weight="bold">
                    <FormattedMessage
                      id={translations.pages.teams.participants.details.reports.skills.daytimeOccupationReports.title}
                    />
                  </Typography>

                  <Spacer xs={2} />

                  <AppConsumer>
                    {({ team }) => (
                      <Table
                        id={'daytime-occupations'}
                        empty={() => (
                          <Placeholder image="people" size="md">
                            <Typography is="span" type="swan" weight="bold">
                              <FormattedMessage id={translations.pages.teams.sessions.empty} />
                            </Typography>
                          </Placeholder>
                        )}
                        source={() =>
                          getParticipantDaytimeOccupationsReport<ParticipantReport.Skill>(
                            participantId,
                            teamId,
                            criteria
                          )
                        }
                        renderHeader={() => <DaytimeOccupationHeader />}
                        renderRow={(data) => (
                          <DaytimeOccupationRow language={language} key={JSON.stringify(data)} data={data} />
                        )}
                        withoutPagination
                      />
                    )}
                  </AppConsumer>

                  <Spacer xs={3} />
                </div>
              </Reports.Charts>
            )}
          />
        )}
      </LoadData>

      <Spacer xs={2} />

      <Reports.Notes criteria={criteria} participantId={participantId} topic={TopicFilter.Skills} />
    </Reports>
  );
};

export default SkillSection;
