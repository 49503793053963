import React from 'react';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CreateNote, Visibility } from '@/domains';
import { STRING_SHORT_MAX_LENGTH, NOTE_CONTENT_MAX_LENGTH } from '@/constants';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Checkbox from '@/components/Checkbox';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import TopicSelect from '@/components/Selects/TopicSelect';
import { withValidation } from '@/components/hoc/withValidation';
import VisibilitySelect from '@/components/Selects/VisibilitySelect';

import style from './CreateNoteModal.sass';

export type Type = 'session' | 'participant';

// TODO: Use yup validate context instead of function context var
const schema: (scope: Type) => yup.ObjectSchema<CreateNote> = (scope) =>
  yup.object({
    title: yup
      .string()
      .label(translations.inputs.title.label)
      .max(STRING_SHORT_MAX_LENGTH)
      .required(),
    topic: yup
      .mixed()
      .label(translations.inputs.topic.label)
      .notRequired()
      .when('title', {
        is: (_) => scope === 'participant',
        then: yup.mixed().required()
      }),
    description: yup
      .string()
      .max(NOTE_CONTENT_MAX_LENGTH)
      .label(translations.inputs.reminderDescription.label)
      .required(),
    lifeEvent: yup
      .boolean()
      .label(translations.inputs.lifeEvent.label)
      .nullable()
      .notRequired(),
    visibility: yup
      .mixed()
      .label(translations.inputs.visibility.label)
      .required()
  });

const TextInputWithValidation = withValidation(TextInput);
const TopicSelectWithValidation = withValidation(TopicSelect);
const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const VisibilitySelectWithValidation = withValidation(VisibilitySelect);
const CheckboxWithValidation = withValidation(Checkbox);

interface Props {
  submitFunction: (...params) => any;
  onNoteCreated: () => any;
  type: Type;
}

const CreateNoteModal: React.FC<Props & InstanceProps & WrappedComponentProps> = ({
  intl,
  close,
  submitFunction,
  onNoteCreated,
  type
}) => (
  <Card className={style.root}>
    <Card.Row>
      <Card.Column sm={12}>
        <div className={style.titleAndClose}>
          <Typography is="h6" type="swan" weight="bold">
            <FormattedMessage id={translations.modals.createNote.title} />
          </Typography>

          <Button onClick={close}>
            <BoxedIcon type="close" appearance="red" />
          </Button>
        </div>
      </Card.Column>
    </Card.Row>

    <Form
      id="create-note"
      schema={schema(type)}
      subscription={{
        dirty: true,
        pristine: true,
        submitError: true,
        submitting: true
      }}
      initialValues={{ visibility: Visibility.Private }}
      onSubmit={(values) => submitFunction(values).then(() => onNoteCreated() || close())}
    >
      {({ dirty, pristine, submitError, submitting }, id) => (
        <React.Fragment>
          <Card.Row>
            <Card.Column sm={12}>
              <div className={style.inputs}>
                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-title`}
                  name="title"
                  label={intl.formatMessage({ id: translations.inputs.title.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.title.placeholder })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={TopicSelectWithValidation}
                  type="text"
                  id={`${id}-topic`}
                  name="topic"
                  label={intl.formatMessage({ id: translations.inputs.topic.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.topic.placeholder })}
                  readOnly={submitting}
                />
              </div>

              <Spacer xs={1} />

              <Form.Field
                is={TextAreaWithValidation}
                id={`${id}-description`}
                name="description"
                label={intl.formatMessage({ id: translations.inputs.noteDescription.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.noteDescription.placeholder })}
                readOnly={submitting}
                inputClassName={style.description}
              />

              <Spacer xs={1} />

              <div className={style.visibilityAndLifeEvent}>
                <div className={style.visibility}>
                  <Form.Field
                    is={VisibilitySelectWithValidation}
                    id={`${id}-visibility`}
                    name="visibility"
                    label={intl.formatMessage({ id: translations.inputs.visibility.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.visibility.placeholder })}
                    readOnly={submitting}
                  />
                </div>

                <Form.Field
                  is={CheckboxWithValidation}
                  id={`${id}-life-event`}
                  name="lifeEvent"
                  appearance="square"
                  type="checkbox"
                  readOnly={submitting}
                >
                  <FormattedMessage id={translations.inputs.lifeEvent.label} />
                </Form.Field>
              </div>
            </Card.Column>
          </Card.Row>

          <Card.Row>
            <Card.Column sm={12}>
              {!!submitError && <SubmitError error={submitError} />}

              <Button
                form={id}
                type="submit"
                appearance="orange"
                loading={submitting}
                disabled={!dirty || pristine}
                className={style.createButton}
              >
                <FormattedMessage id={translations.modals.createReminder.create} />
              </Button>
            </Card.Column>
          </Card.Row>
        </React.Fragment>
      )}
    </Form>
  </Card>
);

export default injectIntl(CreateNoteModal);
