import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import { State, TeamBase } from '@/domains';
import { Role, rules, urls } from '@/constants';
import { getMyTeams } from '@/services/api/team';

import If from '@/components/If';
import Card from '@/components/Card';
import Team from '@/components/Team';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import HasRole from '@/components/HasRole';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppContext } from '@/components/Context/App';
import ErrorBoundary from '@/components/ErrorBoundary';
import InfiniteScroll from '@/components/InfiniteScroll';
import NewTeamModal from '@/components/Modals/NewTeamModal';
import { SessionContext } from '@/components/Context/Session';

import style from './TeamsDropdownCard.sass';

interface Props {
  close: () => void;
}

const TeamsDropdownCard: React.FC<Props & RouteComponentProps> = ({ close, history }) => {
  const intl = useIntl();

  const { team, selectTeam } = React.useContext(AppContext);

  const { me } = React.useContext(SessionContext);

  const getTeamName = (teamName: string, state) =>
    state !== State.Disabled
      ? teamName
      : intl.formatMessage({ id: translations.header.teamsDropdown.inactiveTeam }, { teamName: teamName });

  const filterTeamsForAdmins = (teams: TeamBase[]) => teams.filter((item) => (team ? item.id !== team.id : true));

  const filterTeamsForNonAdmins = (teams: TeamBase[]) =>
    filterTeamsForAdmins(teams).filter((item) => item.state !== State.Disabled);

  return (
    <Card className={style.root}>
      <ErrorBoundary>
        <InfiniteScroll source={getMyTeams} className={classnames(style.list, style.teams)}>
          {({ data, loading }) => (
            <React.Fragment>
              <Card.Row>
                <Card.Column sm={12}>
                  <Typography is="h6" type="halcyon" weight="bold">
                    <FormattedMessage id={translations.header.teamsDropdown.teams} />
                  </Typography>

                  <Spacer xs={1} />

                  <Typography is="span" type="halcyon" color="gray" weight="lighter">
                    <FormattedMessage id={translations.header.teamsDropdown.partOf} />
                  </Typography>
                </Card.Column>
              </Card.Row>

              {team && (
                <Card.Row>
                  <Card.Column sm={12}>
                    <Typography is="span" type="halcyon" color="gray" weight="lighter">
                      <FormattedMessage id={translations.header.teamsDropdown.managing} />
                    </Typography>

                    <Spacer xs={1} />

                    <Team logo={team.imageUrl} name={getTeamName(team.name, team.state)} block />
                  </Card.Column>
                </Card.Row>
              )}

              <Card.Row>
                <Card.Column sm={12} className={style.teamsColumn}>
                  <Typography is="span" type="halcyon" color="gray" weight="lighter">
                    <FormattedMessage id={translations.header.teamsDropdown.others} />
                  </Typography>

                  <Spacer xs={1} />

                  <div className={style.list}>
                    {(() => {
                      const teams = [Role.Admin, Role.AdminTeamLeader].includes(me.role)
                        ? filterTeamsForAdmins(data)
                        : filterTeamsForNonAdmins(data);

                      return (
                        <If
                          condition={!loading && teams.length === 0}
                          then={() => (
                            <Placeholder image="questions" size="xs">
                              <Typography is="span" type="halcyon" weight="bold" className={style.empty}>
                                <FormattedMessage id={translations.header.teamsDropdown.empty} />
                              </Typography>
                            </Placeholder>
                          )}
                          else={() =>
                            teams.map((team) => (
                              <Team
                                is={Button}
                                logo={team.imageUrl}
                                name={getTeamName(team.name, team.state)}
                                block
                                hoverable
                                state={team.state}
                                key={team.id}
                                onClick={() => {
                                  selectTeam(team.id), history.push(urls.dashboard), close();
                                }}
                              />
                            ))
                          }
                        />
                      );
                    })()}
                  </div>

                  <Loading visible={loading} center className={style.loadingIndicator}>
                    <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                  </Loading>
                </Card.Column>
              </Card.Row>
            </React.Fragment>
          )}
        </InfiniteScroll>

        <HasRole to={rules.TEAM_CREATION}>
          <Spacer xs={1} />

          <div className={style.createTeamButton}>
            <Modal modal={NewTeamModal}>
              {({ open }) => (
                <Button appearance="orange" block className={style.createTeamButton} type="button" onClick={open}>
                  <FormattedMessage id={translations.header.teamsDropdown.create} />
                </Button>
              )}
            </Modal>
          </div>
        </HasRole>
      </ErrorBoundary>
    </Card>
  );
};
export default withRouter(TeamsDropdownCard);
