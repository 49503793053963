import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Link from '@/components/Link';
import Typography from '@/components/Typography';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import SignUpEmailSent from './SignUpEmailSent';
import ForgotPasswordLinkSent from './ForgotPasswordLinkSent';
import ResetPasswordLinkExpired from './ResetPasswordLinkExpired';

import logo from '@/assets/logo.svg';

import style from './Unauthorized.sass';

const Unauthorized: React.FC = () => (
  <div className={style.container}>
    <Link to={urls.home}>
      <img className={style.logo} src={logo} />
    </Link>

    <Switch>
      <Route exact path={urls.signIn} component={SignIn} />
      <Route exact path={urls.forgotPassword.main} component={ForgotPassword} />
      <Route exact path={urls.forgotPassword.sent} component={ForgotPasswordLinkSent} />
      <Route exact path={urls.resetPassword.main} component={ResetPassword} />
      <Route exact path={urls.resetPassword.expired} component={ResetPasswordLinkExpired} />
      <Route exact path={urls.signUp.main} component={SignUp} />
      <Route exact path={urls.signUp.sent} component={SignUpEmailSent} />
      <ConsumeRedirectRoute render={() => <ConsumeRedirectRoute render={() => <Redirect to={urls.signIn} />} />} />
    </Switch>

    <div className={style.links}>
      <Link to={urls.contact} external>
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.navigation.contact} />
        </Typography>
      </Link>

      <Link to={urls.about} external>
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.navigation.about} />
        </Typography>
      </Link>

      <Link to={urls.terms} external>
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.navigation.terms} />
        </Typography>
      </Link>
    </div>
  </div>
);

export default Unauthorized;
