import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { GoalState } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<GoalState>, 'items'>, WrappedComponentProps {
  closed?: boolean;
}

class GoalStateSelect extends React.PureComponent<Props> {
  render() {
    const { intl, closed, ...rest } = this.props;

    const items = Object.values(GoalState)
      .filter((goal) => (closed ? goal !== GoalState.InProgress : true))
      .map((goal) => ({
        value: goal,
        name: intl.formatMessage({ id: translations.goalState[goal] })
      }));

    return <SimpleSelect {...rest} items={items} />;
  }
}

export default injectIntl(GoalStateSelect);
