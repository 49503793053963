import React from 'react';
import { parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { TopicFilter, VisibilityFilter, Sort, By, Note } from '@/domains';
import { getParticipantNotes } from '@/services/api/participant-notes';

import Table from '@/components/Table';
import Placeholder from '@/components/Placeholder';
import { Order } from '@/components/Ordering';
import Typography from '@/components/Typography';
import { LanguageContext } from '@/components/Language';

import NoteHeader from '@/components/tables/NotesTable/Header';
import NoteRow from '@/components/tables/NotesTable/Row';

import style from './Notes.sass';

export interface Props {
  participantId: string;
  criteria: any;
  topic: TopicFilter;
  className?: string;
}

const Notes: React.FC<Props> = ({ participantId, criteria, topic }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Table
      id={`${JSON.stringify(criteria)}`}
      renderHeader={() => <NoteHeader />}
      listClassName={style.notesTable}
      empty={() => (
        <Placeholder size="sm" image="questions" className={style.empty}>
          <Typography is="span" type="halcyon" weight="bold">
            <FormattedMessage id={translations.pages.teams.participants.details.notes.empty} />
          </Typography>
        </Placeholder>
      )}
      source={(page, number) =>
        getParticipantNotes(participantId, page, number, {
          before: parseISO(criteria.end).toISOString(),
          after: parseISO(criteria.start).toISOString(),
          topic: topic,
          visibility: VisibilityFilter.Public,
          lifeEvent: true,
          [Sort.By]: By.CreatedAt,
          [Sort.Order]: Order.Ascendent
        })
      }
      renderRow={(note: Note) => (
        <li key={note.id} className={style.note}>
          <NoteRow note={note} hoverable={false} language={language} />
        </li>
      )}
    />
  );
};

export default Notes;
