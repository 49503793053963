import React from 'react';
import classnames from 'classnames';

import InfiniteScroll, { Props as InfiniteScrollProps } from '@/components/InfiniteScroll';
import Table from '@/components/StatelessTable';
import If from '@/components/If';
import Loading from '@/components/Loading';

import style from './InfiniteScrollableTable.sass';

export interface Props<T> extends Pick<InfiniteScrollProps<T>, 'source'> {
  renderHeader: () => any | null;
  empty: () => any;
  children: (data: T[]) => React.ReactElement;
  className?: string;
  listClassName?: string;
}

export default class InfiniteScrollableTable<T> extends React.PureComponent<Props<T>> {
  render() {
    const { source, renderHeader, empty, children, className, listClassName } = this.props;
    return (
      <Table className={className}>
        {renderHeader()}

        <InfiniteScroll source={source} className={classnames(style.list, listClassName)}>
          {({ data, loading }) => (
            <If
              condition={!loading && data.length === 0}
              then={empty}
              else={() => (
                <ul>
                  {children(data)}

                  <Loading visible={loading} center className={style.loadingIndicator}>
                    <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                  </Loading>
                </ul>
              )}
            />
          )}
        </InfiniteScroll>
      </Table>
    );
  }
}
