import React from 'react';
import classnames from 'classnames';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';

import { LanguageConsumer } from '@/components/Language';
import Dropdown from '@/components/Dropdown';
import Card from '@/components/Card';
import HeaderItem from '@/components/Header/HeaderItem';
import LanguageItem from './LanguageItem';
import Icon from '@/components/Icon';

import style from './LanguageSelector.sass';

export default class LanguageSelector extends React.PureComponent {
  stateReducer = (_: DownshiftState<any>, changes: StateChangeOptions<any>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEscape:
        return { isOpen: false };
      default:
        return changes;
    }
  };

  render() {
    return (
      <LanguageConsumer>
        {({ language, languages, update }) => (
          <Downshift
            initialSelectedItem={language}
            stateReducer={this.stateReducer}
            onChange={(selection) => update(selection)}
            itemToString={(item) => (item ? item.value : '')}
          >
            {({
              isOpen,
              closeMenu,
              selectedItem,
              getToggleButtonProps,
              getMenuProps,
              highlightedIndex,
              getItemProps
            }) => (
              <div>
                <Dropdown
                  className={classnames(style.root)}
                  dropdownClassName={style.dropdown}
                  isOpen={isOpen}
                  onClose={closeMenu}
                  renderMenu={() => (
                    <Card className={style.content}>
                      <ul {...getMenuProps({}, { suppressRefError: true })}>
                        {languages.map((item, index) => (
                          <LanguageItem
                            {...getItemProps({ item })}
                            is="li"
                            key={item}
                            language={item}
                            className={{ [style.item]: highlightedIndex === index }}
                          />
                        ))}
                      </ul>
                    </Card>
                  )}
                >
                  {() => (
                    <HeaderItem {...getToggleButtonProps()}>
                      <LanguageItem is="span" language={selectedItem} className={style.button}>
                        <Icon type={isOpen ? 'chevronUp' : 'chevronDown'} appearance="orange" />
                      </LanguageItem>
                    </HeaderItem>
                  )}
                </Dropdown>
              </div>
            )}
          </Downshift>
        )}
      </LanguageConsumer>
    );
  }
}
