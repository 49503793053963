import React from 'react';

export interface Props<T> {
  value: T;
  loading?: boolean;
  onChange: (value: T) => Promise<any>;
  children: (props: RenderProps<T>) => React.ReactElement;
}

export interface State<T> {
  value: T;
  loading: boolean;
}

export interface RenderProps<T> extends Pick<State<T>, 'value'> {
  value: T;
  set: (key: keyof T, value: T[keyof T]) => void;
}

export default class LiveSubmit<T> extends React.PureComponent<Props<T>, State<T>> {
  static defaultProps: Partial<Props<any>> = {
    loading: false
  };

  state: State<T> = {
    loading: this.props.loading,
    value: this.props.value
  };

  set = (key: keyof T, value: T[keyof T]) => {
    if (this.state.value[key] !== value)
      this.setState(
        {
          loading: true
        },
        () =>
          this.props
            .onChange({
              ...this.state.value,
              [key]: value
            })
            .then(() =>
              this.setState((prevState) => ({
                value: { ...prevState.value, [key]: value },
                loading: false
              }))
            )
            .catch(() => this.setState({ loading: false }))
      );
  };

  render() {
    return this.props.children({
      value: this.state.value,
      set: this.set
    });
  }
}
