import React, { PureComponent } from 'react';
import classnames from 'classnames';

import Row from './Row';
import Column from './Column';

import style from './Card.sass';

interface Props {
  className?: string;
}
export default class Card extends PureComponent<Props> {
  static Row = Row;
  static Column = Column;

  render() {
    const { className, children } = this.props;
    return <div className={classnames(style.root, className)}>{children}</div>;
  }
}
