export enum MediaFileType {
  Participant = 'PARTICIPANT',
  Organization = 'ORGANIZATION',
  Team = 'TEAM',
  Session = 'SESSION',
  Location = 'LOCATION',
  ProfilePicture = 'PROFILE_PICTURE',
  NGO = 'NGO'
}

export interface Image {
  id?: string;
  imageUrl: string;
}
