import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Pie, ChartComponentProps } from 'react-chartjs-2';

import { translations } from '@/locale';
import { getData, multiplyPalleteUpToLength } from '@/util';
import { qualitativePalette } from '@/constants';

import DownloadableChart, { Props as DownloadableChartProps } from '@/components/DownloadableChart';

interface PieChartData {
  labels: string[];
  dataset: number[];
}

interface Props extends Pick<DownloadableChartProps, 'title'>, ChartComponentProps {
  name: string;
  data: PieChartData;
  className?: string;
  pallete?: string[];
}

export const PieChart: React.FC<Props> = ({ name, data, title, className, pallete, ...rest }) => (
  <DownloadableChart
    title={title}
    data={{
      labels: data.labels,
      datasets: [
        {
          data: data.dataset,
          backgroundColor: multiplyPalleteUpToLength(data.dataset.length, pallete || qualitativePalette)
        }
      ]
    }}
    chart={Pie}
    className={className}
    {...rest}
  >
    {({ getImage }) => (
      <React.Fragment>
        <DownloadableChart.DownloadButton onClick={() => getImage(`${name}-pie-chart`)}>
          <FormattedMessage id={translations.inputs.chart.jpg} />
        </DownloadableChart.DownloadButton>

        <DownloadableChart.DownloadButton onClick={() => getData(data, `${name}-data`)}>
          <FormattedMessage id={translations.inputs.chart.data} />
        </DownloadableChart.DownloadButton>
      </React.Fragment>
    )}
  </DownloadableChart>
);
