import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { AgeGroup } from '@/domains';
import { translations } from '@/locale';
import { createAgeGroup, updateAgeGroup } from '@/services/api/age-groups';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';

import style from './NewAgeGroupModal.sass';
import { SubmitError } from '@/components/Error';

const ageGroupSchema = yup.object({
  name: yup.string().required(),
  minAge: yup
    .number()
    .min(0)
    .required(),
  maxAge: yup
    .number()
    .min(yup.ref('minAge'))
    .max(99)
    .required()
});

interface Props extends InstanceProps {
  ageGroupData?: AgeGroup;
  onSubmit: () => any;
}

const NewAgeGroupModal: React.FC<Props> = ({ close, ageGroupData, onSubmit }) => {
  const intl = useIntl();

  return (
    <Card className={style.root}>
      <Button className={style.closeButton} onClick={close}>
        <BoxedIcon type="close" appearance="red" />
      </Button>

      <Form
        id="create-age-group"
        subscription={{ errors: true, submitting: true, submitError: true, valid: true, values: true, dirty: true }}
        initialValues={{ ...ageGroupData }}
        schema={ageGroupSchema}
        onSubmit={(values: any) =>
          (!!ageGroupData ? updateAgeGroup(values.id, values) : createAgeGroup(values)).then(() => {
            onSubmit();
            close();
          })
        }
      >
        {({ submitting, submitError, valid, dirty }, id) => (
          <React.Fragment>
            <Card.Row padded className={style.fields}>
              <Typography is="span" type="swan" weight="bold">
                <FormattedMessage
                  id={translations.modals.ageGroupModal.title[!!ageGroupData ? 'update' : 'creation']}
                />
              </Typography>

              <Spacer xs={5} />

              <Form.Field
                is={TextInput}
                id={`${id}-name`}
                name="name"
                type="text"
                label={intl.formatMessage({ id: translations.inputs.ageGroupName.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.ageGroupName.placeholder })}
                readOnly={submitting}
              />

              <Spacer xs={3} />

              <div className={style.ageRange}>
                <Form.Field
                  is={TextInput}
                  id={`${id}-minAge`}
                  name="minAge"
                  type="number"
                  label={intl.formatMessage({ id: translations.inputs.ageGroupMinAge.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.ageGroupMinAge.placeholder })}
                  readOnly={submitting}
                  className={style.ageField}
                />

                <Form.Field
                  is={TextInput}
                  id={`${id}-maxAge`}
                  name="maxAge"
                  type="number"
                  label={intl.formatMessage({ id: translations.inputs.ageGroupMaxAge.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.ageGroupMaxAge.placeholder })}
                  readOnly={submitting}
                  className={style.ageField}
                />
              </div>
            </Card.Row>

            <Card.Row padded className={style.buttonContainer}>
              {!!submitError && (
                <React.Fragment>
                  <SubmitError error={submitError} />
                </React.Fragment>
              )}

              <Button
                loading={submitting}
                type="submit"
                appearance="orange"
                className={style.nextButton}
                disabled={!valid || !dirty}
              >
                <FormattedMessage id={translations.buttons[!!ageGroupData ? 'save' : 'add']} />
              </Button>
            </Card.Row>
          </React.Fragment>
        )}
      </Form>
    </Card>
  );
};
export default NewAgeGroupModal;
