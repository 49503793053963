import React from 'react';
import classnames from 'classnames';

import Picture from '@/components/Picture';
import Typography from '@/components/Typography';

import style from './Contact.sass';

export interface Props {
  is?: React.ElementType;
  picture: string;
  name: string;
  title: string;
  block?: boolean;
  hoverable?: boolean;
  className?: string;
  [x: string]: any;
}

const Contact: React.FC<Props> = ({
  is: Component = 'div',
  picture,
  name,
  title,
  block,
  hoverable,
  className,
  children,
  ...rest
}) => (
  <Component
    className={classnames(style.root, { [style.block]: block, [style.hoverable]: hoverable }, className)}
    {...rest}
  >
    <Picture className={style.picture} size="md" url={picture} type="contact" />

    <div className={style.info}>
      <Typography className={style.name} is="span" type="halcyon" weight="bold" color="blue">
        {name}
      </Typography>

      <Typography className={style.name} is="span" type="halcyon" weight="lighter" color="gray">
        {title}
      </Typography>
    </div>

    {children}
  </Component>
);

export default Contact;
