import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { AgeGroup } from '@/domains';
import { deleteAgeGroup } from '@/services/api/age-groups';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import NewAgeGroupModal from '@/components/Modals/NewAgeGroupModal';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import cellStyle from '../cellStyle.sass';

import style from './AgeGroupRow.sass';

export interface Props {
  ageGroup: AgeGroup;
  onModalAction?: () => any;
}

const DataOptionRow: React.FC<Props> = ({ ageGroup, onModalAction, ...rest }) => {
  const intl = useIntl();

  return (
    <Card.Row is="li" className={classnames(cellStyle.container, style.root)} {...rest}>
      <Modal modal={NewAgeGroupModal} ageGroupData={ageGroup} onSubmit={onModalAction}>
        {({ open }) => (
          <div className={style.container} onClick={open}>
            <Typography is="div" className={cellStyle.name} type="halcyon" weight="bold">
              {ageGroup.name}
            </Typography>

            <Typography is="div" className={cellStyle.min} type="halcyon" weight="bold">
              {ageGroup.minAge}
            </Typography>

            <Typography is="div" className={cellStyle.max} type="halcyon" weight="bold">
              {ageGroup.maxAge}
            </Typography>
          </div>
        )}
      </Modal>

      <Modal
        modal={CrudConfirmationModal}
        message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
        onConfirm={() => deleteAgeGroup(ageGroup.id).then(onModalAction)}
      >
        {({ open }) => (
          <Button onClick={open} className={cellStyle.remove}>
            <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
              <FormattedMessage id={translations.inputs.remove} />
            </TextWithIcon>
          </Button>
        )}
      </Modal>
    </Card.Row>
  );
};
export default DataOptionRow;
