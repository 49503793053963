import React from 'react';

export interface Props {
  title: React.ReactNode;
  url: string;
}

export default class RoutableTab extends React.PureComponent<Props> {
  render() {
    return this.props.children;
  }
}
