import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { translations } from '@/locale';
import { taxIdSchema } from '@/validation';
import { OrganizationSignUp } from '@/domains';
import { signUpOrganization } from '@/services/api/organization';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Modal, { InstanceProps } from '@/components/Modal';
import TextInput from '@/components/TextInput';
import { SubmitError } from '@/components/Error';
import { withValidation } from '@/components/hoc/withValidation';

import style from './CreateOrganizationModal.sass';

const TextInputWithValidation = withValidation(TextInput);

const schema: yup.ObjectSchema<OrganizationSignUp> = yup.object({
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.organizationName.label)
    .required(),
  officialAddress: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.address.label)
    .required(),
  taxId: taxIdSchema.label(translations.inputs.taxId.label).required(),
  contactPersonFirstName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.contactFirstName.label)
    .required(),
  contactPersonLastName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.contactLastName.label)
    .required(),
  contactPersonEmail: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.contactEmail.label)
    .required()
});

export interface Props extends InstanceProps {
  onOrganizationCreated: () => any;
}

class CreateOrganizationModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { close, onOrganizationCreated } = this.props;

    return (
      <React.Fragment>
        <Card className={style.card}>
          <Modal.Header close={close}>
            <FormattedMessage id={translations.pages.organizations.list.create} />
          </Modal.Header>

          <Spacer xs={3} />

          <Form
            id="create-organization"
            schema={schema}
            subscription={{ submitError: true, submitting: true }}
            onSubmit={(values) => signUpOrganization(values).then(() => close() || onOrganizationCreated())}
          >
            {({ submitError, submitting }, formId) => (
              <div className={style.form}>
                <div className={style.inputFields}>
                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-name`}
                    name="name"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.organizationName.label })}
                    placeholder={this.props.intl.formatMessage({
                      id: translations.inputs.organizationName.placeholder
                    })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-official-address`}
                    name="officialAddress"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.address.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.address.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-tax-id`}
                    name="taxId"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.taxId.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.taxId.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-contact-person-first-name`}
                    name="contactPersonFirstName"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.contactFirstName.label })}
                    placeholder={this.props.intl.formatMessage({
                      id: translations.inputs.contactFirstName.placeholder
                    })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-contact-person-last-name`}
                    name="contactPersonLastName"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.contactLastName.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.contactLastName.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${formId}-contact-person-email`}
                    name="contactPersonEmail"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.contactEmail.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.contactEmail.placeholder })}
                    readOnly={submitting}
                  />
                </div>

                {!!submitError && (
                  <React.Fragment>
                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}

                <Spacer xs={3} />

                <Button type="submit" loading={submitting} appearance="orange" form={formId}>
                  <FormattedMessage id={translations.inputs.create} />
                </Button>
              </div>
            )}
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

export default injectIntl(CreateOrganizationModal);
