export enum EvaluationModuleState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export enum EvaluationModuleType {
  Mood = 'MOOD',
  Skill = 'SKILL',
  Goal = 'GOAL',
  SocialMap = 'SOCIAL_MAP',
  DaytimeOccupation = 'DAYTIME_OCCUPATIOn'
}

export interface EvaluationModuleListItem {
  state: EvaluationModuleState;
  type: EvaluationModuleType;
}
