import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { ParticipantCreation } from '@/domains';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { createParticipant } from '@/services/api/participant';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import DateInput from '@/components/DateInput';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { AppConsumer } from '@/components/Context/App';
import PictureUpload from '@/components/PictureUpload';
import GenderSelect from '@/components/Selects/GenderSelect';
import { withValidation } from '@/components/hoc/withValidation';

import style from './NewParticipantModal.sass';

const TextInputWithValidation = withValidation(TextInput);
const GenderSelectWithValidation = withValidation(GenderSelect);
const DateInputWithValidation = withValidation(DateInput);

const schema: yup.ObjectSchema<ParticipantCreation> = yup.object({
  nickname: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.nickname.label)
    .required(),
  gender: yup
    .mixed()
    .label(translations.inputs.gender.label)
    .required()
});

interface Props extends InstanceProps {
  onProfileCreated?: () => any;
}

class NewParticipantModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { onProfileCreated, close } = this.props;

    return (
      <AppConsumer>
        {({ team }) => (
          <Card className={style.root}>
            <Button className={style.closeButton} onClick={() => close()}>
              <BoxedIcon type="close" appearance="red" />
            </Button>

            <div className={style.padded}>
              <Typography is="span" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.createParticipant.title} />
              </Typography>
              <Spacer xs={5} />
              <Form
                id="new-participant"
                schema={schema}
                subscription={{ errors: true, submitting: true, dirty: true, pristine: true, submitError: true }}
                onSubmit={(values) => createParticipant(team.id, values).then(() => onProfileCreated() || close())}
                className={style.form}
              >
                {({ submitError, submitting, dirty, pristine }, formId) => (
                  <React.Fragment>
                    <div>
                      <Form.Field
                        is={PictureUpload}
                        id={`${formId}-profile-picture`}
                        name="imageUrl"
                        title={this.props.intl.formatMessage({ id: translations.inputs.picture.profile })}
                        accept="image/x-png,image/jpeg"
                        readOnly={submitting}
                      />

                      <Spacer xs={5} />

                      <Typography is="span" type="halcyon" color="gray">
                        <FormattedMessage id={translations.modals.createParticipant.personalInformation} />
                      </Typography>

                      <Spacer xs={1} />

                      <Form.Field
                        is={TextInputWithValidation}
                        id={`${formId}-nickname`}
                        name="nickname"
                        type="text"
                        label={this.props.intl.formatMessage({ id: translations.inputs.nickname.label })}
                        placeholder={this.props.intl.formatMessage({ id: translations.inputs.nickname.placeholder })}
                        readOnly={submitting}
                      />
                      <Spacer xs={1} />

                      <div className={style.row}>
                        <Form.Field
                          is={GenderSelectWithValidation}
                          id={`${formId}-gender`}
                          name="gender"
                          type="text"
                          label={this.props.intl.formatMessage({ id: translations.inputs.gender.label })}
                          placeholder={this.props.intl.formatMessage({ id: translations.inputs.gender.placeholder })}
                          readOnly={submitting}
                          className={style.halfWidth}
                        />

                        <Form.Field
                          is={DateInputWithValidation}
                          id={`${formId}-birthdate`}
                          name="birthDate"
                          type="text"
                          label={this.props.intl.formatMessage({ id: translations.inputs.birthDate.label })}
                          placeholder={this.props.intl.formatMessage({ id: translations.inputs.birthDate.placeholder })}
                          readOnly={submitting}
                          className={style.halfWidth}
                        />
                      </div>

                      {!!submitError && <SubmitError error={submitError} />}
                    </div>

                    <Button
                      loading={submitting}
                      form={formId}
                      type="submit"
                      appearance="orange"
                      className={style.createButton}
                      disabled={!dirty || pristine}
                    >
                      <FormattedMessage id={translations.inputs.create} />
                    </Button>
                  </React.Fragment>
                )}
              </Form>
            </div>
          </Card>
        )}
      </AppConsumer>
    );
  }
}
export default injectIntl(NewParticipantModal);
