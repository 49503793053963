import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { formatAmount } from '@/util';
import { translations } from '@/locale';
import { BillingHistory } from '@/domains';
import { paymentStatusColor } from '@/constants';

import StatelessTable from '@/components/StatelessTable';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';
import Link from '@/components/Link';

import style from './BillingHistoryRow.sass';

export interface Props {
  billingHistory: BillingHistory;
  className?: string;
  detailed?: boolean;
}

const BillingHistoryRow: React.FC<Props & WrappedComponentProps> = ({
  billingHistory: { invoiceNumber, createdAt, numberOfUsers, amount, dueDate, status },
  detailed,
  intl
}) => (
  <Typography
    is={(props) => <StatelessTable.Row {...props} is="li" />}
    type="sparrow"
    weight="bold"
    className={style.row}
  >
    <StatelessTable.Data sm={3} className={style.number}>
      <div>{invoiceNumber}</div>

      <Typography is="div" type="hummingbird" color="gray">
        {intl.formatDate(new Date(createdAt), {
          year: 'numeric',
          month: 'short',
          day: '2-digit'
        })}
      </Typography>
    </StatelessTable.Data>

    <StatelessTable.Data sm={2}>{numberOfUsers}</StatelessTable.Data>

    <StatelessTable.Data sm={2}>{formatAmount(amount)}</StatelessTable.Data>

    <StatelessTable.Data sm={2}>
      {dueDate
        ? intl.formatDate(new Date(dueDate), {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
          })
        : intl.formatMessage({ id: translations.miscellaneous.unknown })}
    </StatelessTable.Data>

    <StatelessTable.Data sm={detailed ? 2 : 3}>
      <BoxedContent appearance={paymentStatusColor[status]} className={style.status}>
        <Typography is="span" type="hummingbird" weight="bold">
          <FormattedMessage id={translations.paymentStatuses[status]} />
        </Typography>
      </BoxedContent>
    </StatelessTable.Data>

    {detailed && (
      <StatelessTable.Data sm={detailed ? 1 : 0}>
        <Typography is="span" type="hummingbird" weight="bold" className={style.details}>
          <Link to="#" external appearance="orange">
            <FormattedMessage id={translations.billingHistoryTable.row.seeDetails} />
          </Link>
        </Typography>
      </StatelessTable.Data>
    )}
  </Typography>
);

export default injectIntl(BillingHistoryRow);
