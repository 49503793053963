import React from 'react';
import classnames from 'classnames';

import style from './InputsContainer.sass';

interface Props {
  className?: string;
}

const InputsContainer: React.FC<Props> = ({ className, ...rest }) => (
  <div className={classnames(style.container, className)} {...rest}></div>
);

export default InputsContainer;
