import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<boolean>, 'items'>, WrappedComponentProps {}

class BooleanSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = [
      {
        value: true,
        name: intl.formatMessage({ id: translations.boolean.true })
      },
      {
        value: false,
        name: intl.formatMessage({ id: translations.boolean.false })
      },
      {
        value: null,
        name: intl.formatMessage({ id: translations.miscellaneous.unknown })
      }
    ];

    return <Select {...rest} getItemValue={(value) => value} items={items} multiple={false} />;
  }
}

export default injectIntl(BooleanSelect);
