import { FetchedQuestion, Question } from './question';
import { StreetSession } from './street-session';
import { User } from './user';

export enum SurveyStatus {
  Scheduled = 'SCHEDULED',
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

export enum SurveyType {
  Regular = 'REGULAR',
  SessionFeedback = 'SESSION_FEEDBACK'
}

export enum SurveyTypeFilter {
  All = 'ALL',
  Regular = 'REGULAR',
  SessionFeedback = 'SESSION_FEEDBACK'
}

export interface CreateSurvey {
  completionTime?: number;
  description?: string;
  start: string;
  end: string;
  sessionId?: string;
  teamId: string;
  title: string;
  type: SurveyType;
  questions: Question[];
}

export interface SurveyRow {
  end: string;
  start: string;
  id: string;
  participants: number;
  status: SurveyStatus;
  type: SurveyType;
  title: string;
}

export interface SurveyDetails {
  createdBy: User;
  start: string;
  end: string;
  id: string;
  participants: number;
  questions: FetchedQuestion[];
  session?: StreetSession;
  title: string;
  type: SurveyType;
  status: SurveyStatus;
}

export interface UpdateSurvey {
  description?: string;
  questions: FetchedQuestion[];
  title: string;
  start: string;
  end: string;
}

export interface SurveyTiming {
  start: string;
  end: string;
}
