import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const NoteHeader: React.FC = () => (
  <Typography is={Card.Row} type="halcyon" color="gray" weight="bold" className={cellStyle.header}>
    <div className={cellStyle.sessionDetail}>
      <FormattedMessage id={translations.pages.teams.participants.details.notes.header.sessionDetail} />
    </div>

    <div className={cellStyle.noteTitle}>
      <FormattedMessage id={translations.pages.teams.participants.details.notes.header.noteTitle} />
    </div>
  </Typography>
);

export default NoteHeader;
