import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { ParticipantAccountOverview, InvitationToAppStatus } from '@/domains';
import { getParticipantAppParticipants, inviteParticipantsToSession } from '@/services/api/participant-digital-coach';

import { InstanceProps } from '@/components/Modal';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

interface Props extends InstanceProps {
  teamId: string;
  sessionId: string;
  onSubmit: () => void;
}

class InviteParticipantsToSessionModal extends React.PureComponent<Props & WrappedComponentProps> {
  extractParticipantData = (data: ParticipantAccountOverview): SelectableItemData => {
    const { intl } = this.props;
    return {
      id: data.id,
      topText: data.participant.nickname,
      bottomText: data.participant.birthDate
        ? intl.formatMessage(
            { id: translations.pages.sessions.details.tabs.participants.content.table.age },
            { age: getAgeFromBirthdate(data.participant.birthDate) }
          )
        : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge }),
      photoUrl: data.participant.imageUrl
    };
  };

  render() {
    const { teamId, sessionId, onSubmit, intl } = this.props;

    return (
      <SearchableModal close={this.props.close}>
        <SearchableModal.Header
          title={intl.formatMessage({ id: translations.modals.searchableModal.inviteParticipantsToSession.title })}
          subtitle={intl.formatMessage({
            id: translations.modals.searchableModal.inviteParticipantsToSession.subtitle
          })}
        />

        <SearchableModal.Content
          close={this.props.close}
          searchLabel={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
          searchPlaceholder={intl.formatMessage({ id: translations.inputs.participantSearch.placeholder })}
          dataSource={(page, number, criteria) =>
            getParticipantAppParticipants(teamId, page, number, {
              keyword: criteria,
              status: InvitationToAppStatus.Accepted
            })
          }
          submitDestination={(values) =>
            inviteParticipantsToSession(sessionId, teamId, values.selectedItems).then(onSubmit)
          }
          dataExtractionFunction={this.extractParticipantData}
          buttonLabel={intl.formatMessage({ id: translations.inputs.invite })}
        />
      </SearchableModal>
    );
  }
}
export default injectIntl(InviteParticipantsToSessionModal);
