import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { formatISO, endOfMonth, startOfMonth } from 'date-fns';

import { translations } from '@/locale';
import { getEvaluationModules } from '@/services/api/organization';
import { getDataOptions } from '@/services/api/data-option';
import { getMyTeams } from '@/services/api/team';
import { getAllLocations } from '@/services/api/location';
import { checkIfWeShouldDeselectAll } from '@/util';
import { isActive } from '@/util/isActive';
import { criteriaWithActiveToggleOn } from '@/util/criteria-with-active-toggle-on';

import {
  TimeFrame,
  ReportFilters,
  Filter,
  EvaluationModuleType,
  EvaluationModuleState,
  DataOptionType
} from '@/domains';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import LazyRender from '@/components/LazyRender';
import EntityPreview from '@/components/EntityPreview';
import ErrorBoundary from '@/components/ErrorBoundary';
import DatePickerInput from '@/components/DatePickerInput';
import { SessionContext } from '@/components/Context/Session';
import TeamSelect from '@/components/Selects/TeamSimpleSelect';
import AgeGroupSelect from '@/components/Selects/AgeGroupSelect';
import TimeFrameSelect from '@/components/Selects/TimeFrameSelect';
import TeamMemberSelect from '@/components/Selects/TeamMemberSelect';
import SessionTypeSelect from '@/components/Selects/SessionTypeSelect';
import SessionLabelSelect from '@/components/Selects/SessionLabelSelect';
import LocationSimpleSelect from '@/components/Selects/LocationSimpleSelect';
import ToggleShowOnlyActive from '@/components/ToggleSwitch/ToggleShowOnlyActive';
import FilterManagerWithPersistence from '@/components/FilterManagerWithPersistence';

import SessionSection from './SessionSection';
import ParticipantSection from './ParticipantSection';
import ActivitySection from './ActivitySection';
import MoodSection from './MoodSection';
import SkillSection from './SkillSection';
import GoalSection from './GoalSection';
import SocialMapSection from './SocialMapSection';
import DaytimeOccupationSection from './DaytimeOccupationSection';

import style from './Reporting.sass';

const Reporting: React.FC = () => {
  const intl = useIntl();

  const { organization } = React.useContext(SessionContext);

  const [showOnlyActive, setShowOnlyActive] = React.useState<boolean>(false);

  const { value: data, loading: dataLoading } = useAsync(
    () =>
      Promise.all([getDataOptions(DataOptionType.SessionType), getMyTeams(1, 500), getAllLocations()]).then(
        ([sessionTypes, teams, locations]) => {
          return {
            sessionTypes: sessionTypes
              .filter((sessionType) => (showOnlyActive === true ? isActive(sessionType) : true))
              .map((sessionType) => ({ id: sessionType.id, name: sessionType.name })),

            teams: teams.content
              .filter((team) => (showOnlyActive === true ? isActive(team) : true))
              .map(({ id, name }) => ({ id, name })),

            locations: locations.content
              .filter((location) => (showOnlyActive === true ? isActive(location) : true))
              .map(({ id, name }) => ({ id, name }))
          };
        }
      ),
    [showOnlyActive]
  );

  return (
    <LoadData load={() => getEvaluationModules()}>
      {({ value, loading }) => (
        <If
          condition={loading && dataLoading}
          then={() => (
            <Loading visible={loading} center overlay>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => {
            const enabledModules = new Set(
              value.filter((item) => item.state === EvaluationModuleState.Enabled).map((item) => item.type)
            );

            return (
              <div className={style.root}>
                <Spacer xs={2} />

                {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

                <Spacer xs={4} />

                <Card className={style.card}>
                  <FilterManagerWithPersistence
                    storageItem="organization-reporting"
                    initialCriteria={{
                      start: formatISO(startOfMonth(new Date()), { representation: 'date' }),
                      end: formatISO(endOfMonth(new Date()), { representation: 'date' }),
                      teamIds: [Filter.All],
                      locationIds: [Filter.All],
                      timeFrame: TimeFrame.Monthly,
                      sessionMemberIds: [Filter.All],
                      ageGroupIds: [Filter.All],
                      sessionTypeIds: [Filter.All],
                      labelIds: [Filter.All]
                    }}
                  >
                    {({ criteria, updateCriteria }) => {
                      return (
                        <ErrorBoundary>
                          <Card.Row>
                            <Card.Column sm={12}>
                              <div className={style.reportsHeader}>
                                <Typography is="div" type="corvus" weight="bold">
                                  <FormattedMessage id={translations.pages.main.reporting.title} />
                                </Typography>

                                <div className={style.toggle}>
                                  <ToggleShowOnlyActive toggleState={showOnlyActive} {...{ setShowOnlyActive }} />
                                </div>
                              </div>

                              <Spacer xs={2} />

                              <div className={style.filters}>
                                <DatePickerInput
                                  id="start"
                                  label={intl.formatMessage({
                                    id: translations.pages.teams.participants.details.reports.filters.from
                                  })}
                                  value={criteria.start}
                                  change={(_, value) => updateCriteria('start', value)}
                                  inputClassName={style.filter}
                                />

                                <DatePickerInput
                                  id="end"
                                  label={intl.formatMessage({
                                    id: translations.pages.teams.participants.details.reports.filters.until
                                  })}
                                  value={criteria.end}
                                  change={(_, value) => updateCriteria('end', value)}
                                  inputClassName={style.filter}
                                />

                                <TimeFrameSelect
                                  appearance="normal"
                                  label={intl.formatMessage({
                                    id: translations.pages.teams.participants.details.reports.filters.show
                                  })}
                                  id="time-frame"
                                  value={criteria.timeFrame}
                                  onChange={(value) => updateCriteria('timeFrame', value)}
                                  inputClassName={style.filter}
                                />

                                <TeamSelect
                                  id="team"
                                  key={`${showOnlyActive}-team`}
                                  value={criteria.teamIds}
                                  appearance="normal"
                                  label={intl.formatMessage({ id: translations.pages.main.reporting.filters.teams })}
                                  onChange={(value) =>
                                    updateCriteria('teamIds', checkIfWeShouldDeselectAll([...value]))
                                  }
                                  inputClassName={style.filter}
                                  {...{ showOnlyActive }}
                                  searchable
                                  editable
                                  multiple
                                />

                                <TeamMemberSelect
                                  id="team-members"
                                  value={criteria.sessionMemberIds}
                                  appearance="normal"
                                  label={intl.formatMessage({
                                    id: translations.pages.main.reporting.filters.teamMembers
                                  })}
                                  onChange={(value) =>
                                    updateCriteria('sessionMemberIds', checkIfWeShouldDeselectAll([...value]))
                                  }
                                  inputClassName={style.filter}
                                  searchable
                                  multiple
                                  editable
                                />

                                <LocationSimpleSelect
                                  id="location"
                                  key={`${showOnlyActive}-location`}
                                  value={criteria.locationIds}
                                  appearance="normal"
                                  label={intl.formatMessage({ id: translations.pages.main.reporting.filters.location })}
                                  onChange={(value) =>
                                    updateCriteria('locationIds', checkIfWeShouldDeselectAll([...value]))
                                  }
                                  inputClassName={style.filter}
                                  {...{ showOnlyActive }}
                                  searchable
                                  multiple
                                  editable
                                />

                                <AgeGroupSelect
                                  id="age-group"
                                  value={criteria.ageGroupIds}
                                  appearance="normal"
                                  label={intl.formatMessage({
                                    id: translations.pages.main.reporting.filters.ageGroups
                                  })}
                                  onChange={(value) =>
                                    updateCriteria('ageGroupIds', checkIfWeShouldDeselectAll([...value]))
                                  }
                                  inputClassName={style.filter}
                                  searchable
                                  multiple
                                  editable
                                />

                                <SessionTypeSelect
                                  key={`${showOnlyActive}-activity`}
                                  id="session-type-ids"
                                  value={criteria.sessionTypeIds}
                                  appearance="normal"
                                  label={intl.formatMessage({
                                    id: translations.pages.main.reporting.filters.sessionType
                                  })}
                                  onChange={(value) => {
                                    updateCriteria('sessionTypeIds', checkIfWeShouldDeselectAll([...value]));
                                  }}
                                  inputClassName={style.filter}
                                  {...{ showOnlyActive }}
                                  searchable
                                  multiple
                                />

                                <SessionLabelSelect
                                  id="session-labels"
                                  value={criteria.labelIds}
                                  appearance="normal"
                                  label={intl.formatMessage({
                                    id: translations.pages.main.reporting.filters.sessionLabel
                                  })}
                                  onChange={(value) =>
                                    updateCriteria('labelIds', checkIfWeShouldDeselectAll([...value]))
                                  }
                                  inputClassName={style.filter}
                                  searchable
                                  multiple
                                />
                              </div>
                            </Card.Column>
                          </Card.Row>

                          <Card.Row>
                            <Card.Column sm={12}>
                              <SessionSection
                                key={JSON.stringify({
                                  ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                  type: 'session'
                                })}
                                intl={intl}
                                criteria={criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data)}
                              />

                              <Spacer xs={4} />

                              <LazyRender height={707}>
                                <ParticipantSection
                                  key={JSON.stringify({
                                    ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                    type: 'participant'
                                  })}
                                  intl={intl}
                                  criteria={criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data)}
                                />
                              </LazyRender>

                              <Spacer xs={4} />

                              <LazyRender height={790}>
                                <ActivitySection
                                  key={JSON.stringify({
                                    ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                    type: 'activity'
                                  })}
                                  intl={intl}
                                  criteria={criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data)}
                                />
                              </LazyRender>

                              <Spacer xs={4} />

                              {enabledModules.has(EvaluationModuleType.Mood) && (
                                <React.Fragment>
                                  <LazyRender height={412}>
                                    <MoodSection
                                      key={JSON.stringify({
                                        ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                        type: 'mood'
                                      })}
                                      intl={intl}
                                      criteria={criteriaWithActiveToggleOn(
                                        criteria as ReportFilters,
                                        showOnlyActive,
                                        data
                                      )}
                                    />
                                  </LazyRender>

                                  <Spacer xs={4} />
                                </React.Fragment>
                              )}

                              {enabledModules.has(EvaluationModuleType.Skill) && (
                                <React.Fragment>
                                  <LazyRender height={843}>
                                    <SkillSection
                                      key={JSON.stringify({
                                        ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                        type: 'skill'
                                      })}
                                      intl={intl}
                                      criteria={criteriaWithActiveToggleOn(
                                        criteria as ReportFilters,
                                        showOnlyActive,
                                        data
                                      )}
                                    />
                                  </LazyRender>

                                  <Spacer xs={4} />
                                </React.Fragment>
                              )}

                              {enabledModules.has(EvaluationModuleType.Goal) && (
                                <React.Fragment>
                                  <LazyRender height={540}>
                                    <GoalSection
                                      key={JSON.stringify({
                                        ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                        type: 'goal'
                                      })}
                                      intl={intl}
                                      criteria={criteriaWithActiveToggleOn(
                                        criteria as ReportFilters,
                                        showOnlyActive,
                                        data
                                      )}
                                    />
                                  </LazyRender>

                                  <Spacer xs={4} />
                                </React.Fragment>
                              )}

                              {enabledModules.has(EvaluationModuleType.SocialMap) && (
                                <React.Fragment>
                                  <LazyRender height={848}>
                                    <SocialMapSection
                                      key={JSON.stringify({
                                        ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                        type: 'social-map'
                                      })}
                                      intl={intl}
                                      criteria={criteriaWithActiveToggleOn(
                                        criteria as ReportFilters,
                                        showOnlyActive,
                                        data
                                      )}
                                    />
                                  </LazyRender>

                                  <Spacer xs={4} />
                                </React.Fragment>
                              )}

                              {enabledModules.has(EvaluationModuleType.DaytimeOccupation) && (
                                <React.Fragment>
                                  <LazyRender height={337}>
                                    <DaytimeOccupationSection
                                      key={JSON.stringify({
                                        ...criteriaWithActiveToggleOn(criteria as ReportFilters, showOnlyActive, data),
                                        type: 'daytime-occupation'
                                      })}
                                      intl={intl}
                                      criteria={criteriaWithActiveToggleOn(
                                        criteria as ReportFilters,
                                        showOnlyActive,
                                        data
                                      )}
                                    />
                                  </LazyRender>
                                </React.Fragment>
                              )}
                            </Card.Column>
                          </Card.Row>
                        </ErrorBoundary>
                      );
                    }}
                  </FilterManagerWithPersistence>
                </Card>
              </div>
            );
          }}
        />
      )}
    </LoadData>
  );
};

export default Reporting;
