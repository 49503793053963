import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { Team, TeamUpdate } from '@/domains';
import { hasRole } from '@/permission';
import { translations } from '@/locale';
import { rules, STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { getTeamDetails, updateTeamDetails } from '@/services/api/team';

import Form from '@/components/Form';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import HasRole from '@/components/HasRole';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import PictureUpload from '@/components/PictureUpload';
import { SessionConsumer } from '@/components/Context/Session';
import { withValidation } from '@/components/hoc/withValidation';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import style from './TeamInfo.sass';

const PictureUploadWithValidation = withValidation(PictureUpload);
const TextInputWithValidation = withValidation(TextInput);
const TextAreaWithValidation = (props) => <TextInputWithValidation is="textarea" {...props} />;

const schema: yup.ObjectSchema<TeamUpdate> = yup.object({
  imageUrl: yup
    .mixed()
    .label(translations.inputs.teamPhoto)
    .nullable()
    .notRequired(),
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.teamName.label)
    .required(),
  description: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.moreInfo.label)
    .nullable()
    .notRequired()
});

export interface Props {
  team: Team;
  selectTeam: (id: string) => any;
}

const TeamInfo: React.FC<Props & WrappedComponentProps> = ({ selectTeam, team, intl }) => (
  <LoadData id={team ? team.id : null} load={getTeamDetails}>
    {({ value }) => (
      <Form
        initialValues={value}
        subscription={{ submitting: true, valid: true, values: true, dirty: true }}
        schema={schema}
        onSubmit={(team) => updateTeamDetails(value.id, team).then(() => selectTeam(value.id))}
      >
        {({ form, submitting, valid, dirty }) => (
          <SessionConsumer>
            {({ me }) => {
              const isUpdateRestricted = !hasRole(me.role, rules.TEAM_UPDATE);

              return (
                <React.Fragment>
                  <Card.Row padded>
                    <Spacer xs={3} />

                    <Form.Field
                      is={PictureUploadWithValidation}
                      id="image-url"
                      name="imageUrl"
                      title={intl.formatMessage({ id: translations.inputs.teamPhoto })}
                      accept="image/x-png,image/jpeg"
                      readOnly={submitting}
                      disabled={isUpdateRestricted}
                    />

                    <Spacer xs={3} />
                  </Card.Row>

                  <Card.Row padded className={style.fieldsContainer}>
                    <div className={style.fields}>
                      <Typography is="span" type="swan" weight="bold">
                        <FormattedMessage id={translations.pages.teams.tabs.team.teamInfo} />
                      </Typography>

                      <Spacer xs={3} />

                      <Form.Field
                        is={TextInputWithValidation}
                        id="name"
                        name="name"
                        type="text"
                        label={intl.formatMessage({ id: translations.inputs.teamName.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.teamName.placeholder })}
                        readOnly={submitting || isUpdateRestricted}
                      />

                      <Spacer xs={3} />

                      <Form.Field
                        is={TextAreaWithValidation}
                        id="description"
                        name="description"
                        type="text"
                        placeholder={intl.formatMessage({ id: translations.inputs.moreInfo.placeholder })}
                        label={intl.formatMessage({ id: translations.inputs.moreInfo.label })}
                        readOnly={submitting || isUpdateRestricted}
                        inputClassName={style.noResize}
                      />
                    </div>

                    <HasRole to={rules.TEAM_UPDATE}>
                      <Modal
                        modal={CrudConfirmationModal}
                        message={intl.formatMessage({ id: translations.modals.confirmationModals.edit })}
                        onConfirm={() => form.submit()}
                      >
                        {({ open }) => (
                          <Button
                            type="button"
                            loading={submitting}
                            onClick={open}
                            appearance="orange"
                            className={style.saveButton}
                            disabled={isUpdateRestricted || !dirty || !valid}
                          >
                            <FormattedMessage id={translations.inputs.save} />
                          </Button>
                        )}
                      </Modal>
                    </HasRole>
                  </Card.Row>
                </React.Fragment>
              );
            }}
          </SessionConsumer>
        )}
      </Form>
    )}
  </LoadData>
);

export default injectIntl(TeamInfo);
