import { DataOptionType, Page, SelfEvaluation } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { withPagingParams } from '@/util';
import { getDataOptions } from './data-option';

export const createSelfEvaluation = (start: string, end: string, teamId: string): Promise<any> =>
  getDataOptions(DataOptionType.SelfEvaluation).then((result) =>
    authorizedApi.post(`/v1/sessions/self-evaluation`, { dataOptionId: result[0].id, start, end, teamId })
  );

export const updateSelfEvaluation = (start: string, end: string, selfEvaluationId: string): Promise<any> =>
  authorizedApi.put(`/v1/sessions/self-evaluations/${selfEvaluationId}`, { start, end });

export const getSelfEvaluations: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<SelfEvaluation>> = (teamId, page, size, options) =>
  authorizedApi.get(`/v1/sessions/self-evaluations?${withPagingParams(page, size, { teamIds: [teamId], ...options })}`);

export const getSelfEvaluation: (id: string) => Promise<any> = (id) =>
  authorizedApi.get(`/v1/sessions/self-evaluations/${id}`);

export const getSelfEvaluationParticipants: (
  id: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<any>> = (id, page, size, options) =>
  authorizedApi.get(`/v1/sessions/self-evaluations/${id}/participants?${withPagingParams(page, size, { ...options })}`);

export const getParticipantSelfEvaluations: (
  id: string,
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<any>> = (id, teamId, page, size, options) =>
  authorizedApi.get(`/v1/participants/${id}/self-evaluations?${withPagingParams(page, size, { teamId, ...options })}`);

export const removeParticipantFromSelfEvaluation: (
  sessionId: string,
  participantId: string,
  teamId: string
) => Promise<any> = (sessionId, participantId, teamId) =>
  authorizedApi.delete<any>(
    `/v1/sessions/self-evaluations/${sessionId}/participants/${participantId}?teamId=${teamId}`
  );
