import React from 'react';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { Activity } from '@/domains';
import { translations } from '@/locale';
import { updateParticipantActivities } from '@/services/api/activity';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import { LanguageContext } from '@/components/Language';
import { withValidation } from '@/components/hoc/withValidation';
import SessionActivitySelect from '@/components/Selects/SessionActivitySelect';

import style from './AddActivityModal.sass';

const schema: yup.ObjectSchema<{ activity: string[] }> = yup.object({
  activity: yup
    .array()
    .of(yup.string().required())
    .label(translations.inputs.activityType.label)
    .required()
});

const ActivityTypeSelectWithValidation = withValidation(SessionActivitySelect);

export interface Props extends InstanceProps {
  sessionId: string;
  participantId: string;
  activities: Activity[];
  onActivityAdded: () => any;
}

const AddActivityModal: React.FC<Props & WrappedComponentProps> = ({
  intl,
  participantId,
  sessionId,
  activities,
  onActivityAdded,
  close
}) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12}>
          <div className={style.titleAndClose}>
            <Typography is="h6" type="swan" weight="bold">
              <FormattedMessage id={translations.modals.addActivity.title} />
            </Typography>

            <Button onClick={close}>
              <BoxedIcon type="close" appearance="red" />
            </Button>
          </div>

          <Spacer xs={2} />

          <Form
            id="add-activity"
            schema={schema}
            initialValues={{ activity: activities.map(({ id }) => id) }}
            subscription={{ dirty: true, pristine: true, submitting: true, submitError: true }}
            onSubmit={({ activity }) =>
              updateParticipantActivities(participantId, sessionId, activity).then(() => onActivityAdded() || close())
            }
          >
            {({ dirty, pristine, submitting, submitError }, id) => (
              <React.Fragment>
                <Spacer xs={2} />

                <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                  <FormattedMessage id={translations.modals.addActivity.description} />
                </Typography>

                <Spacer xs={2} />

                <Form.Field
                  is={ActivityTypeSelectWithValidation}
                  id={`${id}-activity`}
                  name="activity"
                  label={intl.formatMessage({ id: translations.inputs.activityType.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.activityType.placeholder })}
                  appearance="normal"
                  sessionId={sessionId}
                  multiple
                  language={language}
                />

                {!!submitError && <SubmitError error={submitError} />}

                <Spacer xs={10} />

                <Button className={style.button} loading={submitting} disabled={!dirty || pristine} appearance="orange">
                  <FormattedMessage id={translations.modals.addActivity.button} />
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Card.Column>
      </Card.Row>
    </Card>
  );
};
export default injectIntl(AddActivityModal);
