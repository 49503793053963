import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { ParticipantListItem } from '@/domains';
import { getParticipants, mergeParticipants } from '@/services/api/participant';

import { InstanceProps } from '@/components/Modal';
import { SelectableItemData, FieldType } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';
import { AppConsumer } from '@/components/Context/App';

interface Props extends InstanceProps {
  close: () => void;
  sourceParticipantId: string;
}

class MergeParticipantsModal extends React.PureComponent<Props & WrappedComponentProps & RouteComponentProps> {
  extractParticipantData = ({ id, nickname, birthDate, imageUrl }: ParticipantListItem): SelectableItemData => {
    return {
      id,
      topText: nickname,
      bottomText: birthDate
        ? this.props.intl.formatMessage(
            { id: translations.pages.teams.participants.list.age },
            { years: getAgeFromBirthdate(birthDate) }
          )
        : null,
      photoUrl: imageUrl
    };
  };

  render() {
    const { intl, sourceParticipantId } = this.props;

    return (
      <AppConsumer>
        {({ team }) => (
          <SearchableModal close={this.props.close}>
            <SearchableModal.Header
              title={intl.formatMessage({ id: translations.modals.searchableModal.mergeParticipants.title })}
              subtitle={intl.formatMessage({ id: translations.modals.searchableModal.mergeParticipants.subtitle })}
            />

            <SearchableModal.Content
              close={this.props.close}
              searchLabel={intl.formatMessage({ id: translations.inputs.participantsSearch.label })}
              searchPlaceholder={intl.formatMessage({ id: translations.inputs.participantsSearch.placeholder })}
              buttonLabel={intl.formatMessage({ id: translations.buttons.merge })}
              dataSource={(page, number, criteria) => getParticipants(team.id, page, number, { keyword: criteria })}
              submitDestination={(values) =>
                mergeParticipants(sourceParticipantId, values.selectedItems).then(() =>
                  this.props.history.push(urls.participants.list)
                )
              }
              dataExtractionFunction={this.extractParticipantData}
              fieldType={FieldType.Radio}
            />
          </SearchableModal>
        )}
      </AppConsumer>
    );
  }
}
export default withRouter(injectIntl(MergeParticipantsModal));
