import React from 'react';

import { urls, rules } from '@/constants';

import Link from '@/components/Link';
import HasRole from '@/components/HasRole';
import { SidebarConsumer } from '@/components/Context/Sidebar';

import MenuButton from './MenuButton';
import TeamsDropdown from './TeamsDropdown';
import ProfileDropdown from './ProfileDropdown';
import LanguageSelector from './LanguageSelector';
import NotficationsDropdown from './NotificationsDropdown';

import logo from '@/assets/logo.svg';

import style from './Header.sass';

const Header: React.FC = () => (
  <header className={style.root}>
    <div className={style.content}>
      <div className={style.sideContainer}>
        <SidebarConsumer>{({ open }) => <MenuButton onClick={open} className={style.menuButton} />}</SidebarConsumer>
        <Link to={urls.dashboard}>
          <img src={logo} className={style.logo} />
        </Link>
      </div>

      <div className={style.user}>
        <HasRole to={rules.HEADER_TEAMS_DROPDOWN}>
          <TeamsDropdown />
        </HasRole>

        <ProfileDropdown />

        <HasRole to={rules.HEADER_NOTIFICATIONS_DROPDOWN}>
          <NotficationsDropdown />
        </HasRole>

        <LanguageSelector />
      </div>
    </div>
  </header>
);

export default Header;
