import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { Contact as ParticipantContact } from '@/domains';
import { getParticipantContacts, deleteParticipantContact } from '@/services/api/participant-contacts';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Refresh from '@/components/Refresh';
import Contact from '@/components/Contact';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import FilterManager from '@/components/FilterManager';
import { LanguageContext } from '@/components/Language';
import AddContactModal from '@/components/Modals/AddContactModal';
import EditContactModal from '@/components/Modals/EditContactModal';

import style from './Contacts.sass';

interface Params {
  id: string;
}

const Contacts: React.FC<WrappedComponentProps & RouteComponentProps<Params>> = ({ intl, match }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <FilterManager initialCriteria={{}}>
          {({ criteria, debouncedUpdate }) => (
            <Refresh>
              {({ key, refresh }) => (
                <React.Fragment>
                  <div className={style.searchAndAddContact}>
                    <TextInput
                      type="text"
                      id="search"
                      onChange={(event) => debouncedUpdate('search', event.target.value)}
                      label={intl.formatMessage({ id: translations.inputs.contactSearch.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.contactSearch.placeholder })}
                      autoComplete="off"
                      className={style.searchBar}
                      leadingIcon="search"
                    />

                    <Modal modal={AddContactModal} participantId={match.params.id} onContactSaved={refresh}>
                      {({ open }) => (
                        <Button appearance="orange" type="button" onClick={open}>
                          <FormattedMessage id={translations.pages.teams.participants.details.contacts.add} />
                        </Button>
                      )}
                    </Modal>
                  </div>

                  <Spacer xs={2} />

                  <Table
                    id={criteria.search}
                    key={key}
                    source={(page, number) => getParticipantContacts(match.params.id, page, number, criteria.search)}
                    empty={() => (
                      <Placeholder image="people" size="lg" className={style.empty}>
                        <Typography is="span" type="halcyon" weight="bold">
                          <FormattedMessage id={translations.pages.teams.participants.details.contacts.empty} />
                        </Typography>
                      </Placeholder>
                    )}
                    renderRow={(data: ParticipantContact) => (
                      <li key={data.id} className={style.contact}>
                        <Modal modal={EditContactModal} contactId={data.id} onContactSaved={refresh}>
                          {({ open }) => (
                            <Contact
                              is="div"
                              name={data.fullName}
                              picture={!!data.type.icon ? data.type.icon.url : null}
                              title={
                                data.type.translations && data.type.translations[language.toLowerCase()]
                                  ? data.type.translations[language.toLowerCase()]
                                  : data.type.name
                              }
                              block
                              hoverable
                              onClick={open}
                            >
                              <TextWithIcon
                                color="red"
                                icon="bin"
                                boxed
                                is="span"
                                type="hummingbird"
                                className={style.deleteButton}
                                onClick={(event) => {
                                  deleteParticipantContact(data.id).then(refresh);
                                  event.stopPropagation();
                                }}
                              >
                                <FormattedMessage id={translations.pages.teams.participants.details.contacts.delete} />
                              </TextWithIcon>
                            </Contact>
                          )}
                        </Modal>
                      </li>
                    )}
                  />
                </React.Fragment>
              )}
            </Refresh>
          )}
        </FilterManager>
      </Card.Column>
    </Card.Row>
  );
};

export default withRouter(injectIntl(Contacts));
