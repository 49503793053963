import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Contact as ParticipantContact } from '@/domains';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';
import If from '@/components/If';

import ChooseContact from './ChooseContact';
import EvaluateContact from './EvaluateContact';

import style from './EvaluateContactModal.sass';

export interface Props extends InstanceProps {
  participantId: string;
  sessionId: string;
  onContactEvaluated: () => any;
}

interface State {
  step: number;
  contact?: ParticipantContact;
}

class EvaluateContactModal extends React.PureComponent<Props, State> {
  state: State = {
    step: 0
  };

  render() {
    const { participantId, sessionId, onContactEvaluated, close } = this.props;
    const { step } = this.state;

    return (
      <Card className={style.root}>
        <Card.Row>
          <Card.Column sm={12}>
            <div className={style.titleAndClose}>
              <Typography is="h6" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.evaluateContact.title} />
              </Typography>

              <Button onClick={close}>
                <BoxedIcon type="close" appearance="red" />
              </Button>
            </div>

            <If
              condition={step === 0}
              then={() => (
                <ChooseContact
                  participantId={participantId}
                  onContactChosen={(contact) => this.setState({ step: 1, contact })}
                />
              )}
              else={() => (
                <If
                  condition={step === 1}
                  then={() => (
                    <EvaluateContact
                      contact={this.state.contact}
                      sessionId={sessionId}
                      onContactEvaluated={() => onContactEvaluated() || close()}
                    />
                  )}
                />
              )}
            />
          </Card.Column>
        </Card.Row>
      </Card>
    );
  }
}
export default EvaluateContactModal;
