import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import { DataOptionType, DataOption } from '@/domains';
import { getTeamLeadDataOptions } from '@/services/api/data-option';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import FilterManager from '@/components/FilterManager';
import { AppContext } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import DataOptionTypeSelect from '@/components/Selects/DataOptionTypeSelect';

import DataOptionHeader from './DataOptionHeader';
import DataOptionRow from './DataOptionRow';

import style from './DataOptions.sass';

interface Props {
  dataOptionTypes?: DataOptionType[];
}

const DataOptions: React.FC<Props & RouteComponentProps & WrappedComponentProps> = ({ dataOptionTypes }) => {
  const { team } = React.useContext(AppContext);
  const { language } = React.useContext(LanguageContext);

  const getTranslatedText = (item: DataOption) =>
    item.translations && item.translations[language.toLowerCase()]
      ? item.translations[language.toLowerCase()]
      : item.name;

  return (
    <Card.Row className={style.root}>
      <FilterManager initialCriteria={{ dataType: DataOptionType.ActivityType }}>
        {({ criteria, updateCriteria }) => (
          <React.Fragment>
            <div className={style.filterAndButton}>
              <Typography is="div" type="halcyon" className={style.label}>
                <FormattedMessage id={translations.dataCustomisation.dataOptions.dataType} />
              </Typography>

              <DataOptionTypeSelect
                value={criteria.dataType}
                data={dataOptionTypes}
                onChange={(value) => {
                  return updateCriteria('dataType', value);
                }}
              />
            </div>

            <Table
              id={`${JSON.stringify(criteria)}`}
              source={() =>
                getTeamLeadDataOptions(criteria.dataType, team.id).then((result) =>
                  result.sort((a, b) => getTranslatedText(a).localeCompare(getTranslatedText(b)))
                )
              }
              renderHeader={() => <DataOptionHeader />}
              renderRow={(data: DataOption) => (
                <DataOptionRow
                  language={language}
                  key={data.id}
                  teamId={team.id}
                  dataOption={data}
                  className={style.item}
                />
              )}
              withoutPagination
            />
          </React.Fragment>
        )}
      </FilterManager>
    </Card.Row>
  );
};

export default injectIntl(withRouter(DataOptions));
