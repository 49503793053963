import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { hasRole } from '@/permission';
import { translations } from '@/locale';
import { LocationCreation, LocationState, TeamActive } from '@/domains';
import { rules, STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { updateLocation, createLocation, getLocation, updateLocationTeams } from '@/services/api/location';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import HasRole from '@/components/HasRole';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { AppConsumer } from '@/components/Context/App';
import PictureUpload from '@/components/PictureUpload';
import Modal, { InstanceProps } from '@/components/Modal';
import { SessionConsumer } from '@/components/Context/Session';
import { withValidation } from '@/components/hoc/withValidation';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';
import EditTeamAccessModal from '@/components/Modals/EditTeamAccessModal';
import Team from '@/components/Team';

import LocationStateHandler from './LocationStateHandler';

import style from './LocationModal.sass';

const schema: yup.ObjectSchema<LocationCreation> = yup.object({
  pictureUrl: yup
    .mixed()
    .nullable()
    .notRequired(),
  name: yup
    .string()
    .label(translations.inputs.name.label)
    .max(STRING_SHORT_MAX_LENGTH)
    .required(),
  description: yup
    .string()
    .label(translations.inputs.locationDescription.label)
    .max(STRING_LONG_MAX_LENGTH)
    .notRequired(),
  address: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.address.label)
    .required()
});

interface Props extends InstanceProps {
  locationId?: string;
  onSubmit: () => void;
  locationState?: LocationState;
  setLocationState?: (state: LocationState) => any;
}

interface State {
  teams?: TeamActive[];
}

const TextInputWithValidation = withValidation(TextInput);

const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);

class LocationModal extends React.PureComponent<Props & WrappedComponentProps> {
  state = {
    teams: []
  };

  render() {
    const { locationId, onSubmit, intl } = this.props;

    return (
      <SessionConsumer>
        {({ me }) => {
          const isUpdateRestricted = !hasRole(me.role, rules.TEAM_UPDATE);

          return (
            <LoadData
              load={() =>
                getLocation(locationId).then((location) => {
                  this.setState({ teams: location.teams });
                  return location;
                })
              }
            >
              {({ value: location, loading }) => (
                <If
                  condition={loading}
                  then={() => (
                    <Loading visible={loading} center overlay>
                      <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                    </Loading>
                  )}
                  else={() => (
                    <Card className={style.root}>
                      <Button className={style.closeButton} onClick={this.props.close}>
                        <BoxedIcon type="close" appearance="red" />
                      </Button>
                      <AppConsumer>
                        {({ team }) => (
                          <Form
                            subscription={{
                              submitError: true,
                              submitting: true,
                              valid: true,
                              dirty: true,
                              values: true
                            }}
                            schema={schema}
                            onSubmit={(values) =>
                              (locationId ? updateLocation(locationId, values) : createLocation(team.id, values))
                                .then(this.props.close)
                                .then(onSubmit)
                            }
                            className={style.form}
                            initialValues={location}
                          >
                            {({ form, submitError, submitting, valid, dirty, values }) => (
                              <React.Fragment>
                                <div>
                                  <Typography is="span" type="swan" weight="bold">
                                    <If
                                      condition={!locationId}
                                      then={() => <FormattedMessage id={translations.modals.location.creationTitle} />}
                                      else={() => (
                                        <HasRole
                                          to={rules.LOCATION_UPDATE}
                                          fail={() => <FormattedMessage id={translations.modals.location.viewTitle} />}
                                        >
                                          <FormattedMessage id={translations.modals.location.updateTitle} />
                                        </HasRole>
                                      )}
                                    />
                                  </Typography>

                                  <Spacer xs={5} />

                                  <Form.Field
                                    is={PictureUpload}
                                    id="picture-url"
                                    name="pictureUrl"
                                    type="location"
                                    title={this.props.intl.formatMessage({ id: translations.inputs.locationPhoto })}
                                    readOnly={submitting}
                                    disabled={isUpdateRestricted}
                                  />

                                  <Spacer xs={5} />

                                  <Typography is="span" type="halcyon" color="gray">
                                    <FormattedMessage id={translations.modals.location.place} />
                                  </Typography>

                                  <Spacer xs={1} />

                                  <Form.Field
                                    is={TextInputWithValidation}
                                    id="name"
                                    name="name"
                                    type="text"
                                    label={this.props.intl.formatMessage({
                                      id: translations.inputs.locationName.label
                                    })}
                                    placeholder={this.props.intl.formatMessage({
                                      id: translations.inputs.locationName.placeholder
                                    })}
                                    readOnly={submitting || isUpdateRestricted}
                                  />

                                  <Spacer xs={1} />

                                  <Form.Field
                                    is={TextInputWithValidation}
                                    id="address"
                                    name="address"
                                    type="text"
                                    label={this.props.intl.formatMessage({
                                      id: translations.inputs.locationAddress.label
                                    })}
                                    placeholder={this.props.intl.formatMessage({
                                      id: translations.inputs.locationAddress.placeholder
                                    })}
                                    readOnly={submitting || isUpdateRestricted}
                                  />

                                  <Spacer xs={3} />

                                  <Typography is="span" type="halcyon" color="gray">
                                    <FormattedMessage id={translations.modals.location.description} />
                                  </Typography>

                                  <Spacer xs={1} />

                                  <Form.Field
                                    is={TextAreaWithValidation}
                                    id="description"
                                    name="description"
                                    type="text"
                                    label={this.props.intl.formatMessage({
                                      id: translations.inputs.locationDescription.label
                                    })}
                                    placeholder={this.props.intl.formatMessage({
                                      id: translations.inputs.locationDescription.placeholder
                                    })}
                                    readOnly={submitting || isUpdateRestricted}
                                    inputClassName={style.noResize}
                                  />
                                  {!!submitError && (
                                    <React.Fragment>
                                      <SubmitError error={submitError} />

                                      <Spacer xs={2} />
                                    </React.Fragment>
                                  )}
                                </div>

                                {!!location && (
                                  <div>
                                    <Spacer xs={2} />
                                    <Typography is="span" type="sparrow" weight="bold">
                                      <FormattedMessage id={translations.modals.location.teamsUsage} />
                                    </Typography>
                                    &nbsp;
                                    <HasRole to={rules.LOCATION_SHARE}>
                                      <Modal
                                        modal={EditTeamAccessModal}
                                        initialTeams={this.state.teams}
                                        submitDestination={({ selectedItems }) =>
                                          updateLocationTeams(location.id, selectedItems.map((id) => ({ id }))).then(
                                            () => {
                                              getLocation(location.id).then((location) =>
                                                this.setState({ teams: location.teams })
                                              );
                                              return selectedItems;
                                            }
                                          )
                                        }
                                      >
                                        {({ open }) => (
                                          <Typography
                                            is="span"
                                            type="sparrow"
                                            weight="bold"
                                            color={
                                              this.props.locationState === LocationState.Enabled ? 'orange' : 'gray'
                                            }
                                            onClick={() => this.props.locationState === LocationState.Enabled && open()}
                                            className={
                                              this.props.locationState === LocationState.Enabled
                                                ? style.editAccess
                                                : style.disabledEditAccess
                                            }
                                          >
                                            <FormattedMessage id={translations.modals.location.editAccess} />
                                          </Typography>
                                        )}
                                      </Modal>
                                    </HasRole>
                                    <Spacer xs={2} />
                                    <div className={style.teams}>
                                      {this.state.teams.map((team) => (
                                        <Team
                                          key={team.id}
                                          logo={team.imageUrl}
                                          name={team.name}
                                          border
                                          className={style.team}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                )}

                                <HasRole to={rules.LOCATION_UPDATE}>
                                  <Spacer xs={5} />
                                  <div className={style.footer}>
                                    <div>
                                      {!!location && (
                                        <LocationStateHandler
                                          id={location.id}
                                          initialState={location.state}
                                          disabled={this.state.teams.length > 1}
                                          changeLocationState={this.props.setLocationState}
                                        />
                                      )}
                                    </div>
                                    <Modal
                                      modal={CrudConfirmationModal}
                                      message={intl.formatMessage({ id: translations.modals.confirmationModals.edit })}
                                      onConfirm={() => form.submit()}
                                    >
                                      {({ open }) => (
                                        <Button
                                          loading={submitting}
                                          onClick={open}
                                          type="button"
                                          appearance="orange"
                                          className={style.saveButton}
                                          disabled={!!location ? !valid || !dirty : !valid}
                                        >
                                          <FormattedMessage id={translations.inputs.save} />
                                        </Button>
                                      )}
                                    </Modal>
                                  </div>
                                </HasRole>
                              </React.Fragment>
                            )}
                          </Form>
                        )}
                      </AppConsumer>
                    </Card>
                  )}
                />
              )}
            </LoadData>
          );
        }}
      </SessionConsumer>
    );
  }
}
export default injectIntl(LocationModal);
