import { api } from '@/services/network/api';
import { keycloakApi } from '@/services/network/keycloak-api';

import { Session, ResetPassword, TokenValidity } from '@/domains/session';

const CLIENT_ID = `streetsmart-impact-web-client`;

const mapSession: (session: any) => Session = (session) => {
  return {
    accessToken: session['access_token'],
    refreshToken: session['refresh_token'],
    idToken: session['id_token']
  };
};

export const signInWithEmail: (email: string, password: string) => Promise<Session> = (email, password) => {
  const authData = new URLSearchParams();

  authData.append('client_id', CLIENT_ID);
  authData.append('grant_type', 'password');
  authData.append('scope', 'openid');
  authData.append('username', email);
  authData.append('password', password);

  return keycloakApi
    .post<any>(`/protocol/openid-connect/token`, authData, {
      sentry: { status: [401] }
    })
    .then(mapSession);
};

export const renewToken: (refreshToken: string) => Promise<Session> = (refreshToken) => {
  const authData = new URLSearchParams();

  authData.append('client_id', CLIENT_ID);
  authData.append('grant_type', 'refresh_token');
  authData.append('scope', 'openid');
  authData.append('refresh_token', refreshToken);

  return keycloakApi
    .post<any>(`/protocol/openid-connect/token`, authData, {
      sentry: { status: [401] }
    })
    .then(mapSession);
};

export const signOut = (session: Session) => {
  const authData = new URLSearchParams();
  authData.append('client_id', CLIENT_ID);
  authData.append('refresh_token', session.refreshToken);

  return keycloakApi.post<null>(`/protocol/openid-connect/logout`, authData, {
    headers: {
      authorization: `Bearer ${session.accessToken}`
    },
    sentry: { status: [401] }
  });
};

export const sendResetPasswordLink: (email: string) => Promise<any> = (email) =>
  api.post<any>(`/v1/users/password-token`, { email });

export const checkResetPasswordTokenValidity: (token: string) => Promise<TokenValidity> = (token) =>
  api.post<TokenValidity>(`/v1/users/password-token-validation`, { token });

export const resetPassword: (password: ResetPassword) => Promise<any> = (password) =>
  api.put<any>(`/v1/users/password`, password);
