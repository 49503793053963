import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { Message, State } from '@/domains';
import { translations } from '@/locale';
import { archiveMessage, createMessage, updateMessage } from '@/services/api/messages';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import ToggleSwitch from '@/components/ToggleSwitch';
import { AppContext } from '@/components/Context/App';
import PictureUpload from '@/components/PictureUpload';
import Modal, { InstanceProps } from '@/components/Modal';
import { withValidation } from '@/components/hoc/withValidation';
import ShareMessageWithParticipants from '@/components/SearchableModals/ShareMessageWithParticipants';

import style from './MessageModal.sass';

const schema = yup.object({
  imageUrl: yup.mixed().notRequired(),
  messageText: yup
    .string()
    .max(4000)
    .required(),
  attachment: yup.string().notRequired()
});

interface Props extends InstanceProps {
  data?: Message;
}

const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const TextInputWithValidation = withValidation(TextInput);

const MessageModal: React.FC<Props> = ({ data, close }) => {
  const intl = useIntl();

  const { team } = React.useContext(AppContext);

  const [messageId, setMessageId] = React.useState('');

  const title = translations.modals.messageModal;

  return (
    <Modal modal={ShareMessageWithParticipants} teamId={team.id} messageId={messageId} onSubmit={close}>
      {({ open }) => (
        <Card className={style.root}>
          <Button className={style.closeButton} onClick={close}>
            <BoxedIcon type="close" appearance="red" />
          </Button>

          <Form
            id="new-participant"
            schema={!data && schema}
            subscription={{ errors: true, submitting: true, submitError: true, values: true, valid: true }}
            initialValues={
              data && {
                imageUrl: data.imageUrl,
                messageText: data.description,
                attachment: data.attachment
              }
            }
            onSubmit={(values) =>
              data
                ? updateMessage({ id: data.id, ...values }).then(close)
                : createMessage({ ...values, teamId: team.id })
                    .then((response) => setMessageId(response.id))
                    .then(open)
            }
          >
            {({ submitError, submitting, values, valid }, formId) => (
              <React.Fragment>
                <Card.Row className={style.form}>
                  <Typography is="span" type="swan" weight="bold">
                    <FormattedMessage id={data ? title.editTitle : title.creationTitle} />
                  </Typography>

                  <Spacer xs={4} />
                  <Form.Field
                    is={PictureUpload}
                    id={`${formId}-profile-picture`}
                    name="imageUrl"
                    title={intl.formatMessage({ id: translations.inputs.picture.message })}
                    accept="image/x-png,image/jpeg"
                    readOnly={submitting}
                    disabled={data && data.state === State.Disabled}
                  />

                  <Spacer xs={2} />

                  <Typography is="span" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.messageModal.message} />
                  </Typography>

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextAreaWithValidation}
                    id="message-text"
                    name="messageText"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.messageText.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.messageText.placeholder })}
                    readOnly={submitting}
                    inputClassName={style.noResize}
                    disabled={data && data.state === State.Disabled}
                  />

                  <Spacer xs={2} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="attachment"
                    name="attachment"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.messageAttachment.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.messageAttachment.placeholder })}
                    readOnly={submitting}
                    disabled={data && data.state === State.Disabled}
                  />

                  {!!submitError && <SubmitError error={submitError} />}
                </Card.Row>

                <Card.Row padded className={style.bottomButtons}>
                  {data && (
                    <LiveSubmit
                      loading={false}
                      value={{ messageState: data.state }}
                      onChange={({ messageState }) => archiveMessage(data.id, messageState).then(close)}
                    >
                      {({ value: { messageState }, set }) => (
                        <ToggleSwitch
                          checked={messageState === State.Enabled}
                          onClick={() =>
                            set('messageState', messageState === State.Enabled ? State.Disabled : State.Enabled)
                          }
                          onChange={() => null}
                        />
                      )}
                    </LiveSubmit>
                  )}

                  <Button
                    form={formId}
                    loading={submitting}
                    type="submit"
                    appearance="orange"
                    className={style.nextButton}
                    disabled={!valid || (data && data.state === State.Disabled)}
                  >
                    <FormattedMessage id={data ? translations.buttons.save : translations.navigation.next} />
                  </Button>
                </Card.Row>
              </React.Fragment>
            )}
          </Form>
        </Card>
      )}
    </Modal>
  );
};
export default MessageModal;
