import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const ActivitiesHeader: React.FC<{}> = () => {
  const { participants, activity } = translations.pages.sessions.details.tabs.participants.header;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={cellStyle.activity}>
        <FormattedMessage id={activity} />
      </div>
      <div className={cellStyle.participants}>
        <FormattedMessage id={participants} />
      </div>
    </Card.Row>
  );
};

export default ActivitiesHeader;
