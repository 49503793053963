import React from 'react';
import classnames from 'classnames';
import { parse, format, addDays, subDays, isValid } from 'date-fns';

import { Props as TextInputProps } from '@/components/TextInput';
import DateInput, { dateFormat } from '@/components/DateInput';
import Datepicker from '@/components/DatePicker';
import Icon from '@/components/Icon';
import Typography from '@/components/Typography';

import style from './DatePickerInput.sass';

const dateDisplayFormat = 'dd/MM/yyyy';

interface Props extends Omit<TextInputProps, 'value'> {
  value: string;
  change: (name: string, value: any) => void;
}

interface State {
  isOpen: boolean;
}

class DatePickerInput extends React.PureComponent<Props, State> {
  state: State = {
    isOpen: false
  };

  timeoutID = null;

  onFocus = (event) => {
    clearTimeout(this.timeoutID);
    if (!this.state.isOpen) {
      this.setState({ isOpen: true });
    }
    this.props.onFocus(event);
  };

  onBlur = (event) => {
    this.timeoutID = setTimeout(() => {
      if (this.state.isOpen) {
        this.setState({ isOpen: false });
      }
    }, 0);
    this.props.onBlur(event);
  };

  goToNextDay = (event) => {
    const newDate = addDays(parse(this.props.value, dateFormat, new Date()), 1);
    this.props.change(this.props.name, format(newDate, dateFormat));
  };

  goToPreviousDay = (event) => {
    const newDate = subDays(parse(this.props.value, dateFormat, new Date()), 1);
    this.props.change(this.props.name, format(newDate, dateFormat));
  };

  render() {
    const { change, inputClassName, ...rest } = this.props;

    return (
      <div className={style.root}>
        <div
          className={classnames(style.content, { [style.opened]: this.state.isOpen })}
          onFocus={this.props.disabled ? null : this.onFocus}
          onBlur={this.onBlur}
          tabIndex={-1}
        >
          <DateInput
            {...rest}
            inputClassName={classnames(style.input, inputClassName)}
            onBlur={({ target: { value: newValue } }) => {
              const newDate = parse(newValue, dateDisplayFormat, new Date());
              if (isValid(newDate)) {
                change(this.props.name, format(newDate, dateFormat));
              }
            }}
            onChange={() => null}
          />

          <Icon className={style.calendar} type="calendar" />

          {this.state.isOpen && (
            <Datepicker
              className={style.datePicker}
              date={parse(this.props.value, dateFormat, new Date())}
              selectDate={(date: Date) => change(this.props.name, format(date, dateFormat))}
            />
          )}
        </div>

        <Typography
          is="span"
          type="sparrow"
          color="gray"
          className={style.chevronLeft}
          onClick={!this.props.disabled && this.goToPreviousDay}
        >
          <Icon type="chevronLeft" />
        </Typography>

        <Typography
          is="span"
          type="sparrow"
          color="gray"
          className={style.chevronRight}
          onClick={!this.props.disabled && this.goToNextDay}
        >
          <Icon type="chevronRight" />
        </Typography>
      </div>
    );
  }
}

export default DatePickerInput;
