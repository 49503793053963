import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

interface Props {
  withDelete?: boolean;
}

const DataOptionHeader: React.FC<Props> = ({ withDelete }) => {
  const { name, icon, category, enabled } = translations.dataCustomisation.dataOptions.header;
  return (
    <Card.Row className={cellStyle.header}>
      <Typography is="div" type="halcyon" color="gray" className={cellStyle.name}>
        <FormattedMessage id={name} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.icon}>
        <FormattedMessage id={icon} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.category}>
        <FormattedMessage id={category} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.enabled}>
        <FormattedMessage id={enabled} />
      </Typography>

      {withDelete && <div className={cellStyle.remove}></div>}
    </Card.Row>
  );
};

export default DataOptionHeader;
