import { State } from '@/domains';

export enum CustomPropertyAccessibility {
  Personal = 'PERSONAL',
  General = 'GENERAL'
}

export enum CustomPropertyType {
  String = 'STRING',
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
  List = 'LIST'
}

export interface CustomProperty {
  id: string;
  name: string;
  type: CustomPropertyType;
  availableOptions?: CustomPropertyOption[];
  state: State;
  translations?;
}

export interface CustomPropertyOnProfile {
  id: string;
  name: string;
  type: CustomPropertyType;
  options?: CustomPropertyOption[];
  state: State;
  translations?;
}

export interface CustomPropertyOption {
  id: string;
  option: string;
  state: State;
}

export interface DefaultField<T> {
  value: T;
}

export interface CustomField extends Pick<CustomProperty, 'name' | 'type'> {
  customPropertyId: string;
  value: string | boolean;
}

export interface UpdateCustomField extends Pick<CustomField, 'value'> {
  id: string;
}

export interface LegalDocument {
  isPresent: boolean;
  legalDocument: Omit<CustomProperty, 'type'>;
}

export interface UpdateLegalDocument extends Pick<CustomProperty, 'id'> {
  present: boolean;
}

export interface NewCustomProperty extends Pick<CustomProperty, 'name' | 'type'> {
  accessibility: CustomPropertyAccessibility;
  availableOptions: string[];
}

export interface UpdateCustomProperty extends Pick<CustomProperty, 'id' | 'name'> {
  type?: CustomPropertyType;
  availableOptions?: CustomPropertyOption[];
}
