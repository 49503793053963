import React from 'react';
import { differenceInWeeks } from 'date-fns';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { GoalState, Goal } from '@/domains';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';
import { LanguageContext } from '@/components/Language';
import GoalStateSelect from '@/components/Selects/GoalStateSelect';

import style from '../cellStyle.sass';

export interface Props {
  goal: Goal;
}

const GoalRow: React.FC<Props> = ({ goal }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Card.Row className={style.container}>
      <Typography is="span" type="halcyon" weight="bold" className={style.goalName}>
        {goal.description}
      </Typography>

      <Typography is="span" type="halcyon" weight="bold" className={style.creationTime}>
        <FormattedDate value={goal.startsAt} day="2-digit" month="long" year="numeric" />
      </Typography>

      <Typography is={BoxedContent} appearange="blue" className={style.topic} type="halcyon" weight="bold" color="blue">
        {goal.type.translations && goal.type.translations[language.toLowerCase()]
          ? goal.type.translations[language.toLowerCase()]
          : goal.type.name}
      </Typography>

      <Typography is="span" type="halcyon" weight="bold" className={style.duration}>
        <FormattedMessage
          id={translations.pages.teams.participants.details.evaluations.details.goals.table.weeks}
          values={{
            weeks: differenceInWeeks(new Date(goal.endsAt), new Date(goal.startsAt))
          }}
        />
      </Typography>

      <div className={style.select}>
        <GoalStateSelect
          value={goal.completionStatus}
          onChange={null}
          closed={[GoalState.Completed, GoalState.Failed].includes(goal.completionStatus)}
          disabled
        />
      </div>
    </Card.Row>
  );
};

export default GoalRow;
