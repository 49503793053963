import React from 'react';

export interface Props {
  title: React.ReactNode;
  contentBeforeTitle?: React.ReactNode;
}

export default class Tab extends React.PureComponent<Props> {
  render() {
    return this.props.children;
  }
}
