import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Reminder, ReminderToNote } from '@/domains';
import { NOTE_CONTENT_MAX_LENGTH } from '@/constants';
import { deleteReminder, convertToNote } from '@/services/api/reminder';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Picture from '@/components/Picture';
import Checkbox from '@/components/Checkbox';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import Participant from '@/components/Participant';
import TextWithIcon from '@/components/TextWithIcon';
import TopicSelect from '@/components/Selects/TopicSelect';
import { withValidation } from '@/components/hoc/withValidation';
import VisibilitySelect from '@/components/Selects/VisibilitySelect';
import { NotificationsConsumer } from '@/components/Context/Notifications';

import style from './ReminderDetailModal.sass';

const schema: yup.ObjectSchema<ReminderToNote> = yup.object({
  description: yup
    .string()
    .max(NOTE_CONTENT_MAX_LENGTH)
    .label(translations.inputs.reminderDescription.label)
    .required(),
  lifeEvent: yup
    .mixed()
    .label(translations.inputs.lifeEvent.label)
    .nullable()
    .notRequired(),
  visibility: yup
    .mixed()
    .label(translations.inputs.visibility.label)
    .required()
});

const mapReminder: (reminder: Reminder) => Partial<ReminderToNote> = ({ topic, title }) => {
  return {
    topic,
    title
  };
};

const TextInputWithValidation = withValidation(TextInput);
const TopicSelectWithValidation = withValidation(TopicSelect);
const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const CheckboxWithValidation = withValidation(Checkbox);

interface Props {
  reminder: Reminder;
  onReminderUpdated: () => any;
}

const ReminderDetailModal: React.FC<Props & InstanceProps & WrappedComponentProps> = ({
  intl,
  close,
  reminder,
  onReminderUpdated
}) => {
  const { participant, session } = reminder;

  return (
    <NotificationsConsumer>
      {({ decrement }) => (
        <Card className={style.root}>
          <Card.Row>
            <Card.Column
              sm={12}
              className={classnames(style.participantAndSessionColumn, { [style.border]: !!participant || !!session })}
            >
              <div className={style.titleAndClose}>
                <Typography is="h6" type="swan" weight="bold">
                  <FormattedMessage id={translations.modals.reminderDetails.title} />
                </Typography>

                <Button onClick={close}>
                  <BoxedIcon type="close" appearance="red" />
                </Button>
              </div>

              {(!!participant || !!session) && (
                <div className={style.participantAndSession}>
                  {!!participant && (
                    <Participant
                      birthDate={participant.birthDate}
                      name={participant.nickname}
                      picture={participant.imageUrl}
                      className={style.participant}
                    />
                  )}

                  {!!session && (
                    <div className={style.row}>
                      <Picture size="md" url={session.location.pictureUrl} className={style.sessionPicture} />

                      <div>
                        <Typography is="span" type="sparrow" color="blue" weight="lighter" className={style.title}>
                          <FormattedMessage
                            id={translations.header.notificationsDropdown.reminder.sessionAndTeam}
                            values={{
                              span: (children) => (
                                <Typography is="span" type="sparrow" color="blue" weight="bold">
                                  {children}
                                </Typography>
                              ),
                              session: session.type.name,
                              team: session.team.name
                            }}
                          />
                        </Typography>

                        <div className={style.row}>
                          <TextWithIcon
                            is="span"
                            type="sparrow"
                            icon="locationPin"
                            iconColor="blue"
                            className={style.location}
                          >
                            {session.location.name}
                          </TextWithIcon>

                          <TextWithIcon is="span" type="sparrow" icon="clock" iconColor="blue">
                            <FormattedMessage
                              id={translations.header.notificationsDropdown.reminder.interval}
                              values={{
                                start: intl.formatDate(new Date(session.start), {
                                  month: 'long',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit'
                                }),
                                end: intl.formatTime(new Date(session.end))
                              }}
                            />
                          </TextWithIcon>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Card.Column>
          </Card.Row>

          <Form
            id="reminder-details"
            schema={schema}
            initialValues={mapReminder(reminder)}
            subscription={{
              submitError: true,
              submitting: true
            }}
            onSubmit={({ description, lifeEvent, visibility }) =>
              convertToNote(reminder.id, { description, lifeEvent, visibility }).then(
                () => decrement() || onReminderUpdated() || close()
              )
            }
          >
            {({ submitError, submitting }, id) => (
              <React.Fragment>
                <Card.Row>
                  <Card.Column sm={12}>
                    <Typography is="p" type="halcyon" color="gray">
                      <FormattedMessage id={translations.modals.createReminder.details} />
                    </Typography>

                    <Spacer xs={2} />

                    <div className={style.inputs}>
                      <Form.Field
                        is={TextInputWithValidation}
                        id={`${id}-title`}
                        name="title"
                        label={intl.formatMessage({ id: translations.inputs.title.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.title.placeholder })}
                        readOnly={submitting}
                        disabled
                      />

                      <Form.Field
                        is={TopicSelectWithValidation}
                        type="text"
                        id={`${id}-topic`}
                        name="topic"
                        label={intl.formatMessage({ id: translations.inputs.topic.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.topic.placeholder })}
                        readOnly={submitting}
                        disabled
                      />
                    </div>

                    <Spacer xs={1} />

                    <Form.Field
                      is={TextAreaWithValidation}
                      id={`${id}-description`}
                      name="description"
                      label={intl.formatMessage({ id: translations.inputs.reminderDescription.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.reminderDescription.placeholder })}
                      readOnly={submitting}
                      inputClassName={style.description}
                    />

                    <Spacer xs={1} />

                    <div className={style.visibilityAndLifeEvent}>
                      <div className={style.visibility}>
                        <Form.Field
                          is={VisibilitySelect}
                          id={`${id}-visibility`}
                          name="visibility"
                          label={intl.formatMessage({ id: translations.inputs.visibility.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.visibility.placeholder })}
                          readOnly={submitting}
                        />
                      </div>

                      <Form.Field
                        is={CheckboxWithValidation}
                        id={`${id}-life-event`}
                        name="lifeEvent"
                        appearance="square"
                        type="checkbox"
                        readOnly={submitting}
                      >
                        <FormattedMessage id={translations.inputs.lifeEvent.label} />
                      </Form.Field>
                    </div>
                  </Card.Column>
                </Card.Row>

                <Card.Row>
                  <Card.Column sm={12}>
                    {!!submitError && <SubmitError error={submitError} />}

                    <div className={style.buttons}>
                      <Button
                        type="button"
                        appearance="ghost"
                        className={style.doneButton}
                        onClick={() =>
                          deleteReminder(reminder.id).then(() => decrement() || onReminderUpdated() || close())
                        }
                      >
                        <FormattedMessage id={translations.modals.reminderDetails.done} />
                      </Button>

                      {!!participant && !!session && (
                        <Button
                          form={id}
                          type="submit"
                          appearance="orange"
                          loading={submitting}
                          className={style.createButton}
                        >
                          <FormattedMessage id={translations.modals.reminderDetails.convert} />
                        </Button>
                      )}
                    </div>
                  </Card.Column>
                </Card.Row>
              </React.Fragment>
            )}
          </Form>
        </Card>
      )}
    </NotificationsConsumer>
  );
};

export default injectIntl(ReminderDetailModal);
