import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { EvaluationCategory } from '@/domains';

import { Type } from '@/components/Icon';
import If from '@/components/If';
import Typography from '@/components/Typography';
import BoxedIcon from '@/components/BoxedIcon';

import style from './EvaluationCategories.sass';

interface Props {
  categories: EvaluationCategory[];
}

const evaluationCategoryToIconMap: Record<EvaluationCategory, Type> = {
  [EvaluationCategory.Mood]: 'smile',
  [EvaluationCategory.Skill]: 'bulb',
  [EvaluationCategory.Goal]: 'flag',
  [EvaluationCategory.Occupation]: 'map',
  [EvaluationCategory.Contact]: 'participants'
};

const EvaluationCategories: React.FC<Props & WrappedComponentProps> = ({ intl, categories }) => {
  return (
    <React.Fragment>
      <If
        condition={categories.length === 0}
        then={() => (
          <Typography is="span" type="hummingbird" color="gray">
            <FormattedMessage
              id={translations.pages.sessions.details.tabs.participants.content.table.evaluation.none}
            />
          </Typography>
        )}
        else={() => (
          <React.Fragment>
            <div className={style.rowFlex}>
              {Object.values(EvaluationCategory)
                .filter((category) => categories.includes(category))
                .map((category) => (
                  <BoxedIcon
                    key={category}
                    size="small"
                    type={evaluationCategoryToIconMap[category]}
                    appearance="blue"
                    className={style.icon}
                  />
                ))}
            </div>

            <Typography is="span" type="hummingbird" color="gray">
              {categories.length === Object.values(EvaluationCategory).length ? (
                <FormattedMessage
                  id={translations.pages.sessions.details.tabs.participants.content.table.evaluation.all}
                />
              ) : (
                Object.values(EvaluationCategory)
                  .filter((category) => categories.includes(category))
                  .map((category) => intl.formatMessage({ id: translations.evaluationCategories[category].name }))
                  .join(', ')
              )}
            </Typography>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default injectIntl(EvaluationCategories);
