import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { CustomPropertyType } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<CustomPropertyType>, 'items'>, WrappedComponentProps {}

class CustomPropertyTypeSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(CustomPropertyType).map((type) => ({
      value: type,
      name: intl.formatMessage({ id: translations.customPropertyTypes[type] })
    }));

    return <Select {...rest} items={items} />;
  }
}

export default injectIntl(CustomPropertyTypeSelect);
