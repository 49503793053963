import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { UpdateContact, ContactDetails } from '@/domains';
import { STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { getParticipantContacDetails, updateParticipantContact } from '@/services/api/participant-contacts';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import { AppContext } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import MoodSelect from '@/components/Selects/MoodSelect';
import { withValidation } from '@/components/hoc/withValidation';
import FrequencySelect from '@/components/Selects/FrequencySelect';
import DataOptionSelect from '@/components/Selects/DataOptionSelect';
import EvaluationStatusSelect from '@/components/Selects/EvaluationStatusSelect';

import style from './EditContactModal.sass';

const schema: yup.ObjectSchema<UpdateContact> = yup.object({
  fullName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.name.label)
    .required(),
  additionalInformation: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.additionalInfo.label)
    .notRequired(),
  typeId: yup
    .mixed()
    .label(translations.inputs.contactType.label)
    .required()
});

const mapContact = ({ fullName, type, additionalInformation }: ContactDetails): UpdateContact => ({
  fullName,
  typeId: type.id,
  additionalInformation
});

const TextInputWithValidation = withValidation(TextInput);
const ContactTypeSelectWithValidation = withValidation(DataOptionSelect.ContactTypeSelect);
const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);

const InputsContainer = (props) => (
  <div className={classnames(style.inputsContainer, props.className)}>{props.children}</div>
);

export interface Props extends InstanceProps {
  contactId: string;
  onContactSaved: () => any;
}

const EditContactModal: React.FC<Props & WrappedComponentProps> = ({ intl, contactId, onContactSaved, close }) => {
  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  return (
    <Card className={style.root}>
      <LoadData id={contactId} load={getParticipantContacDetails}>
        {({ value, loading }) => (
          <React.Fragment>
            <div className={style.titleAndClose}>
              <Typography is="h6" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.editContact.title} />
              </Typography>

              <Button onClick={close}>
                <BoxedIcon type="close" appearance="red" />
              </Button>
            </div>

            <div>
              <If
                condition={loading}
                then={() => (
                  <Loading visible={loading} center className={style.loadingIndicator}>
                    <Loading.Indicator size={40} borderWidth={2} fullCircle color="#BCBDC3" />
                  </Loading>
                )}
                else={() => (
                  <Card.Row>
                    <Card.Column sm={12}>
                      <Form
                        id="edit-contact"
                        schema={schema}
                        initialValues={mapContact(value)}
                        subscription={{ dirty: true, pristine: true, submitting: true, submitError: true }}
                        onSubmit={(data) =>
                          updateParticipantContact(contactId, data).then(() => onContactSaved() || close())
                        }
                      >
                        {({ dirty, pristine, submitting, submitError }, id) => (
                          <React.Fragment>
                            <InputsContainer>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-full-name`}
                                name="fullName"
                                label={intl.formatMessage({ id: translations.inputs.fullName.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.fullName.placeholder })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={ContactTypeSelectWithValidation}
                                id={`${id}-type-id`}
                                teamId={team.id}
                                name="typeId"
                                label={intl.formatMessage({ id: translations.inputs.role.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.role.placeholder })}
                                readOnly={submitting}
                                language={language}
                              />
                            </InputsContainer>

                            <Spacer xs={2} />

                            <Form.Field
                              is={TextAreaWithValidation}
                              id={`${id}-additional-information`}
                              name="additionalInformation"
                              type="text"
                              label={intl.formatMessage({ id: translations.inputs.additionalInfo.label })}
                              placeholder={intl.formatMessage({
                                id: translations.inputs.additionalInfo.placeholder
                              })}
                              readOnly={submitting}
                              inputClassName={style.additionalInfo}
                            />

                            <If
                              condition={!!value.latestEvaluation}
                              then={() => (
                                <React.Fragment>
                                  <Spacer xs={2} />

                                  <Typography is="h4" type="sparrow" color="gray" weight="lighter">
                                    <FormattedMessage id={translations.modals.editContact.evaluation} />
                                  </Typography>

                                  <Spacer xs={1} />

                                  <InputsContainer>
                                    <FrequencySelect
                                      label={intl.formatMessage({ id: translations.inputs.frequency.label })}
                                      value={value.latestEvaluation.frequency}
                                      onChange={null}
                                      appearance="normal"
                                      disabled
                                    />

                                    <MoodSelect
                                      label={intl.formatMessage({ id: translations.inputs.mood.label })}
                                      value={value.latestEvaluation.interactionMood}
                                      onChange={null}
                                      appearance="normal"
                                      disabled
                                    />
                                  </InputsContainer>

                                  <Spacer xs={2} />

                                  <Typography is="h4" type="sparrow" weight="bold">
                                    <FormattedMessage id={translations.modals.editContact.helpfulness} />
                                  </Typography>

                                  <Spacer xs={1} />

                                  <InputsContainer>
                                    {value.latestEvaluation.socialIndicatorEvaluations.map((indicator) => (
                                      <EvaluationStatusSelect
                                        key={indicator.socialIndicator.id}
                                        label={indicator.socialIndicator.name}
                                        value={indicator.evaluationStatus}
                                        onChange={null}
                                        appearance="normal"
                                        disabled
                                      />
                                    ))}
                                  </InputsContainer>

                                  <Spacer xs={2} />

                                  <Typography is="h4" type="halcyon" color="gray" weight="lighter">
                                    <FormattedMessage
                                      id={translations.modals.editContact.lastEvaluated}
                                      values={{
                                        date: intl.formatDate(value.lastEvaluatedAt, {
                                          year: 'numeric',
                                          month: 'short',
                                          day: '2-digit'
                                        })
                                      }}
                                    />
                                  </Typography>
                                </React.Fragment>
                              )}
                            />

                            <Spacer xs={2} />

                            {!!submitError && <SubmitError error={submitError} />}

                            <Spacer xs={2} />

                            <Button
                              form={id}
                              className={style.addContact}
                              loading={submitting}
                              disabled={!dirty || pristine}
                              appearance="orange"
                            >
                              <FormattedMessage id={translations.modals.editContact.save} />
                            </Button>
                          </React.Fragment>
                        )}
                      </Form>
                    </Card.Column>
                  </Card.Row>
                )}
              />
            </div>
          </React.Fragment>
        )}
      </LoadData>
    </Card>
  );
};
export default injectIntl(EditContactModal);
