import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';

import Picture, { Props as PictureProps } from '@/components/Picture';
import Typography from '@/components/Typography';

import style from './Participant.sass';

export interface Props extends Partial<Pick<PictureProps, 'size'>> {
  is?: React.ElementType;
  picture: string;
  name: string;
  birthDate: string;
  block?: boolean;
  hoverable?: boolean;
  className?: string;
  [x: string]: any;
}

const Participant: React.FC<Props> = ({
  is: Component = 'div',
  picture,
  name,
  birthDate,
  size = 'md',
  block,
  hoverable,
  className,
  ...rest
}) => {
  return (
    <Component
      className={classnames(style.root, { [style.block]: block, [style.hoverable]: hoverable }, className)}
      {...rest}
    >
      <Picture className={style.picture} size={size} url={picture} />

      <div className={style.info}>
        <Typography className={style.name} is="span" type="halcyon" weight="bold" color="blue">
          {name}
        </Typography>

        {!!birthDate && (
          <Typography className={style.name} is="span" type="halcyon" weight="lighter" color="gray">
            <FormattedMessage
              id={translations.pages.teams.participants.list.age}
              values={{ years: getAgeFromBirthdate(birthDate) }}
            />
          </Typography>
        )}
      </div>
    </Component>
  );
};

export default Participant;
