import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { sequentialPalette } from '@/constants';
import { OrganizationReport, ReportFilters } from '@/domains';
import { roundToTwoDecimals, getColorsForSequentialData, getLabelsFromPeriodAndTimeFrame } from '@/util';
import { getOrganizationReports } from '@/services/api/organization-reports';

import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { BarChart } from '@/components/Charts';
import Spacer from '@/components/Spacer';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const SocialMapSection: React.FC<Props> = ({ intl, criteria }) => (
  <Reports
    title={intl.formatMessage({
      id: translations.pages.main.reporting.socialMap.title
    })}
  >
    <LoadData
      load={() =>
        getOrganizationReports<OrganizationReport.SocialMap>(criteria, OrganizationReport.ReportType.SocialMap)
      }
    >
      {({ value, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <React.Fragment>
              <Reports.Charts>
                <BarChart
                  title={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.participantsWithEvaluations.title
                  })}
                  name="participants-with-evaluations"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: [
                      {
                        label: intl.formatMessage({
                          id: translations.pages.main.reporting.socialMap.participantsWithEvaluations.label
                        }),
                        data: value.participantsWithSocialMapEvaluations.data,
                        backgroundColor: getColorsForSequentialData(
                          value.participantsWithSocialMapEvaluations.data,
                          sequentialPalette
                        )
                      }
                    ]
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                  className={style.wide}
                  height={279}
                />
              </Reports.Charts>

              <Spacer xs={3} />

              <Reports.ItemsContainer>
                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.averageNumberOfRelations.atStart
                  })}
                  value={roundToTwoDecimals(value.averageNumberOfRelations.atStart)}
                  className={style.fixedSizeReportItem}
                />

                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.averageNumberOfRelations.atEnd
                  })}
                  value={roundToTwoDecimals(value.averageNumberOfRelations.atEnd)}
                  className={style.fixedSizeReportItem}
                />
              </Reports.ItemsContainer>

              <Spacer xs={3} />

              <Reports.ItemsContainer>
                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.percentageOfNegativity.atStart
                  })}
                  value={intl.formatMessage(
                    { id: translations.pages.main.reporting.socialMap.percentageOfNegativity.result },
                    { result: Math.round((value.percentageOfNegativity.atStart + Number.EPSILON) * 100) }
                  )}
                  className={style.fixedSizeReportItem}
                />

                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.percentageOfNegativity.atEnd
                  })}
                  value={intl.formatMessage(
                    { id: translations.pages.main.reporting.socialMap.percentageOfNegativity.result },
                    { result: Math.round((value.percentageOfNegativity.atEnd + Number.EPSILON) * 100) }
                  )}
                  className={style.fixedSizeReportItem}
                />
              </Reports.ItemsContainer>

              <Spacer xs={3} />

              <Reports.ItemsContainer>
                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.sizeOfCoreNetwork.atStart
                  })}
                  value={intl.formatMessage(
                    { id: translations.pages.main.reporting.socialMap.sizeOfCoreNetwork.result },
                    { result: roundToTwoDecimals(value.sizeOfCoreNetwork.atStart) }
                  )}
                  className={style.fixedSizeReportItem}
                />

                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.socialMap.sizeOfCoreNetwork.atEnd
                  })}
                  value={intl.formatMessage(
                    { id: translations.pages.main.reporting.socialMap.sizeOfCoreNetwork.result },
                    { result: roundToTwoDecimals(value.sizeOfCoreNetwork.atEnd) }
                  )}
                  className={style.fixedSizeReportItem}
                />
              </Reports.ItemsContainer>
            </React.Fragment>
          )}
        />
      )}
    </LoadData>
  </Reports>
);

export default SocialMapSection;
