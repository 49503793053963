import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';

import If from '@/components/If';
import Form from '@/components/Form';
import Card from '@/components/Card';
import Search from '@/components/Search';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import Checkbox from '@/components/Checkbox';
import LoadData from '@/components/LoadData';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import InfiniteScroll from '@/components/InfiniteScroll';

import SelectableItem from '../SelectableItem';

import style from './Content.sass';

export enum FieldType {
  Checkbox = 'checkbox',
  Radio = 'radio'
}

export interface Props<T> extends Omit<InstanceProps, 'className'> {
  searchPlaceholder: string;
  searchLabel: string;
  dataSource: (...params) => any;
  submitDestination: (...params) => Promise<T>;
  dataExtractionFunction: (element) => SelectableItemData;
  schema?: yup.ObjectSchema<{ selectedItems: string[] }>;
  initialValues?: { selectedItems: string[] };
  footerContent?: React.ReactNode;
  fieldType?: FieldType;
  buttonLabel?: string;
  withoutPagination?: boolean;
}

export interface SelectableItemData {
  id: string;
  topText?: string;
  bottomText?: string;
  photoUrl: string;
}

class Content<T> extends React.PureComponent<Props<T> & WrappedComponentProps> {
  getSelectableItem = (data: SelectableItemData, submitting) => {
    return (
      <li key={data.id} className={style.selectableItem}>
        <Form.Field
          is={Checkbox}
          id={`selected-items-${data.id}`}
          name="selectedItems"
          type={this.props.fieldType || FieldType.Checkbox}
          align="right"
          block
          value={data.id}
          readOnly={submitting}
          appearance="circle"
        >
          <SelectableItem title={data.topText} subtitle={data.bottomText} photoUrl={data.photoUrl} />
        </Form.Field>
      </li>
    );
  };

  render() {
    const {
      schema,
      initialValues,
      searchLabel,
      searchPlaceholder,
      dataSource,
      submitDestination,
      dataExtractionFunction,
      footerContent,
      buttonLabel,
      withoutPagination
    } = this.props;

    return (
      <Form
        subscription={{
          submitting: true,
          valid: true,
          dirty: true,
          pristine: true,
          submitError: true,
          values: true
        }}
        schema={schema}
        initialValues={initialValues}
        onSubmit={(values) => submitDestination(values).then(this.props.close)}
        className={style.form}
      >
        {({ submitError, submitting, pristine, valid, dirty }) => (
          <React.Fragment>
            <Search label={searchLabel} placeholder={searchPlaceholder} searchBarClassName={style.searchBar}>
              {({ criteria }) => (
                <React.Fragment>
                  <Card.Row className={style.searchResults}>
                    <If
                      condition={withoutPagination}
                      then={() => (
                        <LoadData id={criteria} load={dataSource}>
                          {({ value, loading }) => (
                            <div className={style.resultContainer}>
                              <If
                                condition={loading}
                                then={() => (
                                  <Loading visible={loading} overlay center>
                                    <Loading.Indicator size={100} borderWidth={5} fullCircle color="#BCBDC3" />
                                  </Loading>
                                )}
                                else={() => (
                                  <React.Fragment>
                                    <ul>
                                      {value &&
                                        (value as any).map((element) =>
                                          this.getSelectableItem(dataExtractionFunction(element), submitting)
                                        )}
                                    </ul>
                                  </React.Fragment>
                                )}
                              />
                            </div>
                          )}
                        </LoadData>
                      )}
                      else={() => (
                        <InfiniteScroll
                          id={criteria}
                          source={(page, number) => dataSource(page, number, criteria)}
                          className={style.resultContainer}
                        >
                          {({ data, loading }) => (
                            <ul>
                              <Loading visible={loading} overlay center>
                                <Loading.Indicator size={100} borderWidth={5} fullCircle color="#BCBDC3" />
                              </Loading>

                              {data.map((element) =>
                                this.getSelectableItem(dataExtractionFunction(element), submitting)
                              )}
                            </ul>
                          )}
                        </InfiniteScroll>
                      )}
                    />
                  </Card.Row>
                </React.Fragment>
              )}
            </Search>

            <Card.Row className={style.footer}>
              <div>{footerContent}</div>

              {!!submitError && (
                <div>
                  <SubmitError error={submitError} />
                </div>
              )}

              <Button
                type="submit"
                appearance="orange"
                className={style.saveButton}
                disabled={(!dirty && pristine) || !valid}
                loading={submitting}
              >
                {buttonLabel || <FormattedMessage id={translations.buttons.add} />}
              </Button>
            </Card.Row>
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export default injectIntl(Content);
