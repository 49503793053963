import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { moodPallete, qualitativePalette, sequentialPalette, skillProgressPalette } from '@/constants';
import { StreetSessionReport } from '@/domains';
import { getColorFromPalleteByIndex, getColorsForSequentialData } from '@/util';
import { getSessionReports } from '@/services/api/street-session-reports';

import LoadData from '@/components/LoadData';
import { PieChart, BarChart, StackedBarChart } from '@/components/Charts';
import LazyRender from '@/components/LazyRender';
import Spacer from '@/components/Spacer';
import If from '@/components/If';
import Loading from '@/components/Loading';
import ReportsSection from '@/components/Reports';
import Card from '@/components/Card';

import style from './Reports.sass';

interface Props {
  sessionId: string;
}

export enum LearningStatuses {
  veryNegative = 'VERY_NEGATIVE',
  negative = 'NEGATIVE',
  neutral = 'NEUTRAL',
  positive = 'POSITIVE',
  veryPositive = 'VERY_POSITIVE'
}

const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          stepSize: 1
        }
      }
    ]
  }
};

const Reports: React.FC<WrappedComponentProps & Props> = ({ intl, sessionId }) => (
  <React.Fragment>
    <Card.Row>
      <Card.Column sm={12}>
        <ReportsSection
          title={intl.formatMessage({
            id: translations.pages.sessions.details.tabs.report.participants.title
          })}
        >
          <LoadData
            load={() =>
              getSessionReports<StreetSessionReport.Participant>(sessionId, StreetSessionReport.ReportType.Participant)
            }
          >
            {({ value, loading }) => (
              <If
                condition={loading}
                then={() => (
                  <Loading visible={loading} center className={style.loading}>
                    <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                  </Loading>
                )}
                else={() => (
                  <ReportsSection.Charts>
                    <BarChart
                      title={intl.formatMessage({
                        id: translations.pages.sessions.details.tabs.report.participants.age
                      })}
                      name="participant-age"
                      data={{
                        labels: value.ageReport.labels.map(
                          (label) => label || intl.formatMessage({ id: translations.miscellaneous.unknown })
                        ),
                        datasets: [
                          {
                            label: intl.formatMessage({
                              id: translations.pages.sessions.details.tabs.report.participants.label
                            }),
                            data: value.ageReport.data,
                            backgroundColor: getColorsForSequentialData(value.ageReport.data, sequentialPalette)
                          }
                        ]
                      }}
                      options={{
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                min: 0,
                                stepSize: 1
                              }
                            }
                          ]
                        }
                      }}
                    />

                    <PieChart
                      title={intl.formatMessage({
                        id: translations.pages.sessions.details.tabs.report.participants.gender
                      })}
                      name="participant-genders"
                      data={{
                        labels: value.genderReport.labels.map((label) =>
                          label
                            ? intl.formatMessage({ id: translations.genders[label] })
                            : intl.formatMessage({ id: translations.miscellaneous.notSet })
                        ),
                        dataset: value.genderReport.data
                      }}
                    />
                  </ReportsSection.Charts>
                )}
              />
            )}
          </LoadData>
        </ReportsSection>

        <Spacer xs={6} />

        <LazyRender height={337}>
          <ReportsSection
            title={intl.formatMessage({
              id: translations.pages.sessions.details.tabs.report.moods.title
            })}
          >
            <LoadData
              load={() => getSessionReports<StreetSessionReport.Mood>(sessionId, StreetSessionReport.ReportType.Mood)}
            >
              {({ value, loading }) => (
                <If
                  condition={loading}
                  then={() => (
                    <Loading visible={loading} center className={style.loading}>
                      <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                    </Loading>
                  )}
                  else={() => (
                    <ReportsSection.Charts>
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.sessions.details.tabs.report.moods.registered
                        })}
                        name="registered-moods"
                        data={{
                          labels: value.evaluationsInQuadrants.labels.map(
                            (label) =>
                              `${intl.formatMessage({
                                id: translations.moodState[label.state]
                              })}-${intl.formatMessage({ id: translations.moodType[label.type] })}`
                          ),
                          dataset: value.evaluationsInQuadrants.data
                        }}
                        pallete={moodPallete}
                      />
                    </ReportsSection.Charts>
                  )}
                />
              )}
            </LoadData>
          </ReportsSection>
        </LazyRender>

        <Spacer xs={6} />

        <LazyRender height={184}>
          <ReportsSection
            title={intl.formatMessage({
              id: translations.pages.sessions.details.tabs.report.skills.title
            })}
          >
            <LoadData
              load={() => getSessionReports<StreetSessionReport.Skill>(sessionId, StreetSessionReport.ReportType.Skill)}
            >
              {({ value, loading }) => (
                <If
                  condition={loading}
                  then={() => (
                    <Loading visible={loading} center className={style.skillLoading}>
                      <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                    </Loading>
                  )}
                  else={() => (
                    <StackedBarChart
                      title={intl.formatMessage({
                        id: translations.pages.teams.participants.details.reports.skills.skillProgress
                      })}
                      name="skill-progress"
                      data={{
                        labels: [
                          intl.formatMessage({ id: translations.confidenceAndLearningStatus.CONFIDENCE }),
                          intl.formatMessage({ id: translations.confidenceAndLearningStatus.LEARNING })
                        ],
                        datasets: [
                          ...Object.keys(LearningStatuses).map((key, index) => ({
                            label: intl.formatMessage({
                              id: translations.evaluationStatuses[LearningStatuses[key]]
                            }),
                            data: [value.confidenceReport[key], value.learnReport[key]],
                            stack: `stack-0`,
                            backgroundColor: getColorFromPalleteByIndex(skillProgressPalette, index)
                          }))
                        ]
                      }}
                      options={options}
                      className={style.wide}
                      height={279}
                    />
                  )}
                />
              )}
            </LoadData>
          </ReportsSection>
        </LazyRender>
      </Card.Column>
    </Card.Row>
  </React.Fragment>
);

export default injectIntl(Reports);
