import { MediaFileType } from '@/domains';
import { uploadImage } from '@/services/api/media';
import { authorizedApi } from '@/services/network/authorized-api';

export const uploadSessionPhotos: (sessionId: string, images: File[]) => Promise<any> = (sessionId, images) => {
  const promises: Promise<string>[] = [];

  images.forEach((image) => promises.push(uploadImage(image, MediaFileType.Session)));

  return Promise.all(promises).then((urls) =>
    authorizedApi.post<any>(`/v1/sessions/${sessionId}/pictures`, {
      images: urls.map((url) => ({ imageUrl: url }))
    })
  );
};

export const deleteSessionImage: (imageId: string) => Promise<any> = (imageId) =>
  authorizedApi.delete<any>(`/v1/sessions/pictures/${imageId}`);
