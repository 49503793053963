import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { Role, rules } from '@/constants';
import { ParticipantRowModel } from '@/domains';
import { getExportParticipantsRedirectUrl } from '@/services/api/export-url';
import { getSessionParticipants } from '@/services/api/street-session-participants';
import { updateStreetSessionUnregisteredParticipants, getStreetSessionDetails } from '@/services/api/street-session';

import If from '@/components/If';
import Form from '@/components/Form';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Search from '@/components/Search';
import Refresh from '@/components/Refresh';
import HasRole from '@/components/HasRole';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import ExportData from '@/components/ExportData';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import { AppConsumer } from '@/components/Context/App';
import { SessionContext } from '@/components/Context/Session';
import AddParticipantsToSessionModal from '@/components/SearchableModals/AddParticipantsToSessionModal';
import ExportDataForStreetWorker from '@/components/ExportDataForStreetWorker/ExportDataForStreetWorker';

import ParticipantRow from './ParticipantRow';
import ParticipantHeader from './ParticipantHeader';

import style from './Participants.sass';

export interface Props {}

interface Params {
  id: string;
}

const schema: yup.ObjectSchema<{ unregisteredParticipants: any }> = yup.object({
  unregisteredParticipants: yup
    .number()
    .label(translations.inputs.unregisteredParticipants.label)
    .min(0)
    .max(256)
    .required()
});

const Participants: React.FC<Props & WrappedComponentProps & RouteComponentProps<Params>> = ({ match, intl }) => {
  const sessionId = match.params.id;

  const { me } = React.useContext(SessionContext);

  return (
    <AppConsumer>
      {({ team, loading }) =>
        !loading && (
          <Refresh>
            {({ key, refresh }) => (
              <LoadData id={sessionId} load={getStreetSessionDetails}>
                {({ value: session, loading, reload }) => (
                  <div className={style.root}>
                    <If
                      condition={loading}
                      then={() => <div className={style.placeholder} />}
                      else={() => (
                        <div className={style.topRow}>
                          <Form
                            id="unregistered-participants-form"
                            subscription={{
                              errors: true,
                              submitting: true,
                              valid: true,
                              dirty: true,
                              submitSucceeded: true
                            }}
                            schema={schema}
                            onSubmit={({ unregisteredParticipants }) =>
                              updateStreetSessionUnregisteredParticipants(sessionId, unregisteredParticipants)
                            }
                            initialValues={{
                              unregisteredParticipants: session.unregisteredParticipants.toString() || '0'
                            }}
                            className={style.participantsForm}
                          >
                            {({ submitError, submitting, valid, dirty, submitSucceeded }, formId) => (
                              <React.Fragment>
                                <Form.Field
                                  is={TextInput}
                                  type="number"
                                  id="unregistered-participants"
                                  name="unregisteredParticipants"
                                  label={intl.formatMessage({
                                    id: translations.inputs.unregisteredParticipants.label
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.unregisteredParticipants.placeholder
                                  })}
                                  leadingIcon="unregisteredParticipant"
                                  readOnly={submitting}
                                  className={style.unregisteredParticipants}
                                />

                                {dirty && valid && !submitSucceeded && (
                                  <Button
                                    form={formId}
                                    type="submit"
                                    appearance="orange"
                                    disabled={!dirty || !valid}
                                    className={style.saveButton}
                                    fat
                                  >
                                    <FormattedMessage id={translations.inputs.save} />
                                  </Button>
                                )}

                                <div className={style.registeredParticipants}>
                                  <BoxedIcon type="participants" appearance="blue" />

                                  <div className={style.columnFlex}>
                                    <span>
                                      <Typography is="span" type="hummingbird" color="gray">
                                        {`${intl.formatMessage({
                                          id:
                                            translations.pages.sessions.details.tabs.participants.content
                                              .registeredParticipants
                                        })}: `}
                                      </Typography>
                                      <Typography is="span" type="halcyon" weight="bold">
                                        {session.registeredParticipants}
                                      </Typography>
                                    </span>

                                    <span>
                                      <Typography is="span" type="hummingbird" color="gray">
                                        {`${intl.formatMessage({
                                          id:
                                            translations.pages.sessions.details.tabs.participants.content
                                              .deletedParticipants
                                        })}: `}
                                      </Typography>
                                      <Typography is="span" type="halcyon" weight="bold">
                                        {session.removedParticipants}
                                      </Typography>
                                    </span>
                                  </div>
                                </div>

                                {submitError && submitError}
                              </React.Fragment>
                            )}
                          </Form>

                          <Modal
                            modal={AddParticipantsToSessionModal}
                            teamId={team.id}
                            sessionId={sessionId}
                            onSubmit={() => refresh() || reload()}
                          >
                            {({ open }) => (
                              <Button type="button" fat appearance="orange" onClick={open}>
                                <FormattedMessage id={translations.inputs.addParticipant} />
                              </Button>
                            )}
                          </Modal>
                        </div>
                      )}
                    />

                    <div className={style.bottomRow}>
                      <Search
                        label={intl.formatMessage({ id: translations.inputs.participantsSearch.label })}
                        placeholder={intl.formatMessage({
                          id: translations.inputs.participantsSearch.placeholder
                        })}
                        searchBarClassName={style.searchBar}
                      >
                        {({ criteria }) => (
                          <React.Fragment>
                            <Table
                              key={key}
                              id={criteria}
                              source={(page, number) =>
                                getSessionParticipants(page, number, criteria, sessionId, 'EVALUATION')
                              }
                              renderHeader={() => <ParticipantHeader />}
                              renderRow={(data: ParticipantRowModel) => (
                                <ParticipantRow
                                  key={data.id}
                                  sessionId={sessionId}
                                  participantRow={data}
                                  onRemove={refresh}
                                />
                              )}
                              className={style.table}
                              listClassName={style.results}
                            />
                          </React.Fragment>
                        )}
                      </Search>

                      <HasRole to={rules.SESSION_PARTICIPANTS_EXPORT}>
                        {/* This is a temporary fix but it creates a security risk. Should be revisited in future versions */}
                        {me.role === Role.StreetWorker ? (
                          <ExportDataForStreetWorker
                            redirect={(token) => getExportParticipantsRedirectUrl(team.id, token, sessionId)}
                          >
                            {({ download }) => (
                              <Button type="button" onClick={download} className={style.exportParticipants}>
                                <TextWithIcon is="span" type="halcyon" icon="download" color="orange" boxed>
                                  <FormattedMessage id={translations.inputs.downloadParticipants} />
                                </TextWithIcon>
                              </Button>
                            )}
                          </ExportDataForStreetWorker>
                        ) : (
                          <ExportData redirect={(token) => getExportParticipantsRedirectUrl(team.id, token, sessionId)}>
                            {({ download }) => (
                              <Button type="button" onClick={download} className={style.exportParticipants}>
                                <TextWithIcon is="span" type="halcyon" icon="download" color="orange" boxed>
                                  <FormattedMessage id={translations.inputs.downloadParticipants} />
                                </TextWithIcon>
                              </Button>
                            )}
                          </ExportData>
                        )}
                      </HasRole>
                    </div>
                  </div>
                )}
              </LoadData>
            )}
          </Refresh>
        )
      }
    </AppConsumer>
  );
};
export default withRouter(injectIntl(Participants));
