import React, { useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { translations } from '@/locale';
import { getClientSecret } from '@/services/api/billing-info';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import TextInput from '@/components/TextInput';
import { InstanceProps } from '@/components/Modal';
import { SubmitError } from '@/components/Error';

import PaymentFieldsValidation from './PaymentFieldsValidation';

import StripeElement from './StripeElement';

import style from './PaymentMethodModal.sass';

interface Props extends InstanceProps {
  onSuccess: () => any;
}

const PaymentMethodModal: React.FC<Props & WrappedComponentProps> = ({ intl, close, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<any>();

  const handleError = (error) => {
    setSubmitError(error);
    setLoading(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const cardHolder = event.target.cardHolder.value;

    if (!stripe || !elements) {
      setSubmitError(true);
      return;
    }

    setLoading(true);

    return getClientSecret()
      .then(({ clientSecret }) =>
        stripe
          .confirmCardSetup(
            clientSecret,
            /* eslint-disable @typescript-eslint/camelcase */
            {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                ...(cardHolder && {
                  billing_details: {
                    name: cardHolder
                  }
                })
              }
            }
            /* eslint-enable @typescript-eslint/camelcase */
          )
          .then((result) => {
            if (result.error) {
              return handleError(result.error);
            }

            setLoading(false);
            close();
            onSuccess();
          })
      )
      .catch(handleError);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PaymentFieldsValidation>
        {({ isComplete, handleFieldChange }) => (
          <Card className={style.root}>
            <Card.Row>
              <Card.Column sm={12}>
                <Button type="button" className={style.closeButton} onClick={close}>
                  <BoxedIcon type="close" appearance="red" />
                </Button>

                <Typography is="span" type="swan" weight="bold">
                  <FormattedMessage id={translations.modals.addPaymentMethod.title} />
                </Typography>

                <Spacer xs={4} />

                <Typography is="span" type="halcyon" color="gray">
                  <FormattedMessage id={translations.modals.addPaymentMethod.cardDetails} />
                </Typography>

                <Spacer xs={1} />

                <StripeElement
                  is={CardNumberElement}
                  options={{ showIcon: true }}
                  label={intl.formatMessage({ id: translations.inputs.cardNumber.label })}
                  onChange={handleFieldChange}
                />

                <Spacer xs={2} />

                <div className={style.fieldsContainer}>
                  <StripeElement
                    is={CardExpiryElement}
                    label={intl.formatMessage({ id: translations.inputs.validUntil.label })}
                    className={style.expiryField}
                    onChange={handleFieldChange}
                  />
                  <StripeElement
                    is={CardCvcElement}
                    label={intl.formatMessage({ id: translations.inputs.cvc.label })}
                    className={style.cvcField}
                    onChange={handleFieldChange}
                  />
                </div>

                <Spacer xs={2} />

                <TextInput
                  id="card-holder"
                  name="cardHolder"
                  label={intl.formatMessage({ id: translations.inputs.cardholderName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.cardholderName.placeholder })}
                />

                {!!submitError && <SubmitError error={submitError} />}
              </Card.Column>
            </Card.Row>

            <Card.Row padded className={style.buttonRow}>
              <Button
                type="submit"
                appearance="orange"
                className={style.addButton}
                loading={loading}
                disabled={!isComplete || !stripe || !elements}
              >
                <FormattedMessage id={translations.buttons.add} />
              </Button>
            </Card.Row>
          </Card>
        )}
      </PaymentFieldsValidation>
    </form>
  );
};

export default injectIntl(PaymentMethodModal);
