import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Me } from '@/domains';
import { urls } from '@/constants';
import { translations } from '@/locale';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Picture from '@/components/Picture';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';
import EditProfileModal from '@/components/Modals/EditProfileModal';

import style from './ProfileDropdownCard.sass';

export interface Props {
  profile: Me;
  signOut: () => any;
}

const DropdownCard: React.FC<Props & RouteComponentProps> = ({ profile, history, signOut }) => (
  <Card className={style.root}>
    <Card.Row>
      <Card.Column sm={12} className={style.column}>
        <div className={style.banner}>
          <Typography is="div" type="corvus" weight="bold" color="white" className={style.welcome}>
            <FormattedMessage
              id={translations.header.profileDropdown.welcome}
              values={{ span: (children) => <div className={style.name}>{children}</div>, name: profile.firstName }}
            />
          </Typography>
        </div>

        <div className={style.pictureContainer}>
          <Picture url={profile.imageUrl} size="xl" className={style.picture} type="teammate" />
        </div>

        <Typography is="div" type="corvus" weight="bold" className={style.name}>
          {`${profile.firstName} ${profile.lastName ? profile.lastName : ''}`}
        </Typography>

        <Typography is="div" type="hummingbird" weight="lighter" color="gray" className={style.name}>
          {profile.email}
        </Typography>

        <Spacer xs={2} />

        <Typography appearance="gray" is={BoxedContent} type="sparrow" weight="bold">
          <FormattedMessage id={translations.roles[profile.role]} />
        </Typography>

        <Spacer xs={2} />

        <Modal modal={EditProfileModal}>
          {({ open }) => (
            <Button appearance="ghost" className={style.button} onClick={open}>
              <FormattedMessage id={translations.header.profileDropdown.settings} />
            </Button>
          )}
        </Modal>
      </Card.Column>
    </Card.Row>

    <Card.Row padded>
      <div onClick={() => signOut().then(() => history.push(urls.signIn))} className={style.logout}>
        <Typography is="span" type="halcyon" weight="bold">
          <FormattedMessage id={translations.header.profileDropdown.logout} />
        </Typography>

        <BoxedIcon appearance="orange" type="logout" />
      </div>
    </Card.Row>
  </Card>
);

export default withRouter(DropdownCard);
