import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { qualitativePalette } from '@/constants';
import { ReportFilters, ParticipantReport } from '@/domains';
import { getLabelsFromPeriodAndTimeFrame, getColorFromPalleteByIndex } from '@/util';
import { getParticipantReports } from '@/services/api/participant-reports';

import If from '@/components/If';
import Reports from '@/components/Reports';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import { LanguageContext } from '@/components/Language';
import { StackedBarChart } from '@/components/Charts';

import style from '../Reports.sass';

export interface Props extends WrappedComponentProps {
  participantId: string;
  teamId: string;
  criteria: ReportFilters;
}

const SessionSection: React.FC<Props> = ({ intl, participantId, teamId, criteria }) => {
  const { language } = React.useContext(LanguageContext);
  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.teams.participants.details.reports.sessions.title
      })}
    >
      <LoadData
        key={JSON.stringify(criteria)}
        load={() =>
          getParticipantReports<ParticipantReport.Session>(
            participantId,
            teamId,
            criteria,
            ParticipantReport.ReportType.Session
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Reports.Charts>
                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.sessions.participantsPerSessionType
                  })}
                  name="number-of-participation-per-session-type"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: value.participationInSessionType.entries.map((entry, index) => ({
                      label:
                        entry.label.translations && entry.label.translations[language.toLowerCase()]
                          ? entry.label.translations[language.toLowerCase()]
                          : entry.label.name,
                      data: entry.data,
                      backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                    }))
                  }}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                />

                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.sessions.participantsPerLocation
                  })}
                  name="number-of-participation-per-location"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: value.participationAtLocation.entries.map((entry, index) => ({
                      label: entry.label.name,
                      data: entry.data,
                      backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                    }))
                  }}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                />
              </Reports.Charts>
            )}
          />
        )}
      </LoadData>
    </Reports>
  );
};

export default SessionSection;
