import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Typography from '@/components/Typography';

import questionMan from '@/assets/questionMan.png';

import style from './SignUpEmailSent.sass';

export interface Props extends RouteComponentProps {}

class SignUpEmailSent extends React.PureComponent<Props & RouteComponentProps> {
  render() {
    return (
      <Card className={style.card}>
        <div className={style.topSection}>
          <img src={questionMan} className={style.image} />

          <Typography is="span" type="swan" weight="bold">
            <FormattedMessage id={translations.pages.signUpEmailSent.title} />
          </Typography>

          <Spacer xs={1} />

          <Typography is="span" type="halcyon" color="gray">
            <FormattedMessage id={translations.pages.signUpEmailSent.subtitle} />
          </Typography>
        </div>

        <Spacer xs={5} />

        <Button type="submit" appearance="orange" onClick={() => this.props.history.push(urls.signIn)}>
          <FormattedMessage id={translations.pages.signUpEmailSent.back} />
        </Button>

        <Spacer xs={1} />
      </Card>
    );
  }
}

export default withRouter(SignUpEmailSent);
