import React from 'react';
import classnames from 'classnames';

import contact from './contact.png';
import location from './location.png';
import participant from './participant.png';
import teammate from './teammate.png';

import style from './Picture.sass';

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'wide';
export type Type = 'contact' | 'location' | 'participant' | 'teammate';

const typeToImage: Record<string, string> = {
  contact: contact,
  location: location,
  participant: participant,
  teammate: teammate
};

export interface Props {
  size: Size;
  type?: Type;
  url?: string;
  className?: string;
  [x: string]: any;
}

const Picture: React.FC<Props> = ({ url, size, className, children, type = 'participant', ...rest }) => (
  <div
    style={{ backgroundImage: `url(${url ? url : typeToImage[type]})` }}
    className={classnames(style.picture, style[size!], { [style.missing]: !url, [style[type!]]: !url }, className)}
    {...rest}
  >
    {children}
  </div>
);

export default Picture;
