import { authorizedApi } from '@/services/network/authorized-api';

interface ExportToken {
  expiryTime: Date;
  id: string;
  token: string;
}

export const getExportDataToken = (): Promise<ExportToken> => authorizedApi.post<ExportToken>(`/v1/export-tokens`);

export const getExportDataTokenForStreetWorker = (): Promise<ExportToken> =>
  authorizedApi.post<ExportToken>(`/v1/export-tokens?grantAccess=STREET_WORKER`);
