import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { EvaluationCategory, SelfEvaluationCategory } from '@/domains';

import If from '@/components/If';
import { Type } from '@/components/Icon';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';

import style from './SelfEvaluationCategories.sass';

interface Props {
  categories: SelfEvaluationCategory[];
}

const evaluationCategoryToIconMap: Record<SelfEvaluationCategory, Type> = {
  [SelfEvaluationCategory.Mood]: 'smile',
  [SelfEvaluationCategory.Skills]: 'bulb',
  [SelfEvaluationCategory.Goals]: 'flag',
  [SelfEvaluationCategory.SocialMap]: 'map'
};

const lucianCompensatorMap: Record<SelfEvaluationCategory, EvaluationCategory> = {
  [SelfEvaluationCategory.Mood]: EvaluationCategory.Mood,
  [SelfEvaluationCategory.Skills]: EvaluationCategory.Skill,
  [SelfEvaluationCategory.Goals]: EvaluationCategory.Goal,
  [SelfEvaluationCategory.SocialMap]: EvaluationCategory.Contact
};

const SelfEvaluationCategories: React.FC<Props & WrappedComponentProps> = ({ intl, categories }) => {
  return (
    <React.Fragment>
      <If
        condition={categories.length === 0}
        then={() => (
          <Typography is="span" type="hummingbird" color="gray">
            <FormattedMessage
              id={translations.pages.sessions.details.tabs.participants.content.table.evaluation.none}
            />
          </Typography>
        )}
        else={() => (
          <React.Fragment>
            <div className={style.rowFlex}>
              {Object.values(SelfEvaluationCategory)
                .filter((category) => categories.includes(category))
                .map((category) => (
                  <BoxedIcon
                    key={category}
                    size="small"
                    type={evaluationCategoryToIconMap[category]}
                    appearance="blue"
                    className={style.icon}
                  />
                ))}
            </div>

            <Typography is="span" type="hummingbird" color="gray">
              {categories.length === Object.values(SelfEvaluationCategory).length ? (
                <FormattedMessage
                  id={translations.pages.sessions.details.tabs.participants.content.table.evaluation.all}
                />
              ) : (
                Object.values(SelfEvaluationCategory)
                  .filter((category) => categories.includes(category))
                  .map((category) =>
                    intl.formatMessage({ id: translations.evaluationCategories[lucianCompensatorMap[category]].name })
                  )
                  .join(', ')
              )}
            </Typography>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default injectIntl(SelfEvaluationCategories);
