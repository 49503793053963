import React, { PureComponent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { hasRole } from '@/permission';
import { urls, rules } from '@/constants';
import { translations } from '@/locale';
import { getStreetSessionDetails } from '@/services/api/street-session';

import If from '@/components/If';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import Breadcrumb from '@/components/Breadcrumb';
import Placeholder from '@/components/Placeholder';
import Tab from '@/components/TabbedContainer/Tab';
import TabbedContainer from '@/components/TabbedContainer';
import { SessionConsumer } from '@/components/Context/Session';

import Teammates from './Teammates';
import Activities from './Activities';
import Participants from './Participants';
import BaseDetails from './BaseDetails';
import Reports from './Reports';

import style from './Details.sass';
import Invitations from './Invitations';

interface Params {
  id: string;
}

export interface Props {}

class Details extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    const sessionId = this.props.match.params.id;
    const tabs = translations.pages.sessions.details.tabs;

    return (
      <LoadData load={() => getStreetSessionDetails(sessionId)}>
        {({ value: session, loading }) => (
          <If
            condition={!loading}
            then={() => (
              <If
                condition={!session}
                then={() => (
                  <Placeholder image="questions" size="lg" className={style.placeholder}>
                    <Typography is="span" type="swan" weight="bold">
                      <FormattedMessage id={translations.pages.teams.sessions.notFound} />
                    </Typography>
                  </Placeholder>
                )}
                else={() => (
                  <React.Fragment>
                    <div className={style.breadcrumbContainer}>
                      <Breadcrumb
                        shape={{
                          [intl.formatMessage({ id: translations.pages.sessions.details.title })]: urls.sessions.list,
                          [`${session.sessionType.name}, ${session.location.name}, ${intl.formatDate(
                            new Date(session.start),
                            {
                              month: 'long',
                              day: '2-digit',
                              year: 'numeric'
                            }
                          )}`]: null
                        }}
                      />
                    </div>

                    <SessionConsumer>
                      {({ me }) => (
                        <TabbedContainer className={style.root}>
                          <Tab title={<FormattedMessage id={tabs.details.title} />}>
                            <BaseDetails session={session} />
                          </Tab>

                          <Tab title={<FormattedMessage id={tabs.teammates.title} />}>
                            <Teammates />
                          </Tab>

                          <Tab title={<FormattedMessage id={tabs.invitations.title} />}>
                            <Invitations />
                          </Tab>

                          <Tab title={<FormattedMessage id={tabs.participants.title} />}>
                            <Participants />
                          </Tab>

                          <Tab title={<FormattedMessage id={tabs.activities.title} />}>
                            <Activities sessionId={sessionId} />
                          </Tab>

                          {hasRole(me.role, rules.SESSION_REPORTING) && (
                            <Tab title={<FormattedMessage id={tabs.report.title} />}>
                              <Reports sessionId={sessionId} />
                            </Tab>
                          )}
                        </TabbedContainer>
                      )}
                    </SessionConsumer>
                  </React.Fragment>
                )}
              />
            )}
            else={() => (
              <Loading visible={true} center className={style.loadingIndicator}>
                <Loading.Indicator size={100} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
          />
        )}
      </LoadData>
    );
  }
}

export default injectIntl(withRouter(Details));
