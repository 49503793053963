import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls, STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { translations } from '@/locale';
import { SignIn as SignInDomain } from '@/domains';

import Form from '@/components/Form';
import Link from '@/components/Link';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import { AuthError } from '@/components/Error';
import Typography from '@/components/Typography';
import { SessionConsumer } from '@/components/Context/Session';
import { withValidation } from '@/components/hoc/withValidation';

import questionMan from '@/assets/questionMan.png';

import style from './SignIn.sass';

const TextInputWithValidation = withValidation(TextInput);

const schema: yup.ObjectSchema<SignInDomain> = yup.object({
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required(),
  password: yup
    .string()
    .label(translations.inputs.password.label)
    .required()
});

export interface Props extends RouteComponentProps {}

class SignIn extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    return (
      <SessionConsumer>
        {({ signInWithEmail }) => (
          <div>
            <Card className={style.card}>
              <div className={style.topSection}>
                <img src={questionMan} className={style.image} />

                <Typography is="span" type="swan" weight="bold">
                  <FormattedMessage id={translations.pages.signIn.title} />
                </Typography>

                <Spacer xs={1} />

                <Typography is="span" type="halcyon" color="gray">
                  <FormattedMessage id={translations.pages.signIn.subtitle} />
                </Typography>
              </div>

              <Spacer xs={3} />

              <Form
                schema={schema}
                subscription={{ submitError: true, submitting: true }}
                onSubmit={(values) => signInWithEmail(values.email, values.password)}
              >
                {({ submitError, submitting }) => (
                  <div className={style.form}>
                    <div className={style.inputFields}>
                      <Form.Field
                        is={TextInputWithValidation}
                        id="email"
                        name="email"
                        type="email"
                        label={this.props.intl.formatMessage({ id: translations.inputs.email.label })}
                        placeholder={this.props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                        readOnly={submitting}
                        icon="message"
                      />

                      <Spacer xs={1} />

                      <Form.Field
                        is={TextInputWithValidation}
                        id="password"
                        name="password"
                        type="password"
                        label={this.props.intl.formatMessage({ id: translations.inputs.password.label })}
                        placeholder={this.props.intl.formatMessage({ id: translations.inputs.password.placeholder })}
                        readOnly={submitting}
                        icon="lock"
                      />
                    </div>

                    {!!submitError && (
                      <React.Fragment>
                        <AuthError error={submitError} />

                        <Spacer xs={1} />
                      </React.Fragment>
                    )}

                    <Button type="submit" loading={submitting} appearance="orange">
                      <FormattedMessage id={translations.pages.signIn.signInButton} />
                    </Button>

                    <Spacer xs={2} />

                    <Link to={urls.forgotPassword.main} exact underlined>
                      <Typography is="span" type="halcyon">
                        <FormattedMessage id={translations.pages.signIn.forgotPassword} />
                      </Typography>
                    </Link>
                  </div>
                )}
              </Form>
            </Card>

            <div className={style.redirect}>
              <Typography is="span" type="halcyon">
                <FormattedMessage
                  id={translations.pages.signIn.noAccountSignUp}
                  values={{
                    a: (children) => (
                      <Link to={urls.signUp.main} appearance="orange">
                        {children}
                      </Link>
                    )
                  }}
                />
              </Typography>
            </div>
          </div>
        )}
      </SessionConsumer>
    );
  }
}

export default injectIntl(SignIn);
