import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { urls, ParticipantRole } from '@/constants';
import { getAgeFromBirthdate } from '@/util';

import Card from '@/components/Card';
import Link from '@/components/Link';
import Typography from '@/components/Typography';
import PictureWithData from '@/components/PictureWithData';

import cellStyle from '../cellStyle.sass';

import style from './ParticipantRow.sass';

export interface Props {
  data: any;
}

const roleToDescriptionMap = {
  [ParticipantRole.Starter]: translations.participantRoles.NO_ACCESS.description,
  [ParticipantRole.Advanced]: translations.participantRoles.WRITE_ACCESS.description,
  [ParticipantRole.Pro]: translations.participantRoles.FULL_ACCESS.description
};

const ParticipantRow: React.FC<Props & WrappedComponentProps> = ({ data, intl, ...rest }) => (
  <Card.Row is="li" className={style.root} {...rest}>
    <Link to={urls.participants.get(data.id)} className={style.row}>
      <PictureWithData
        size="md"
        url={data.imageUrl ? data.imageUrl : null}
        blueText={data.nickname}
        grayText={
          data.birthDate
            ? intl.formatMessage(
                { id: translations.pages.sessions.details.tabs.participants.content.table.age },
                { age: getAgeFromBirthdate(data.birthDate) }
              )
            : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge })
        }
        className={cellStyle.participant}
      />

      <div className={classnames(cellStyle.role, style.roleContainer)}>
        <Typography is="span" type="halcyon" color="blue">
          <FormattedMessage id={translations.participantRoles[data.roleLevel].name} />
        </Typography>

        <Typography is="span" type="hummingbird" color="gray">
          <FormattedMessage id={roleToDescriptionMap[data.roleLevel]} values={{ br: <br /> }} />
        </Typography>
      </div>

      <Typography is="span" type="halcyon" className={cellStyle.responded}>
        <FormattedMessage
          id={data.completion === 'NOT_ANSWERED' ? translations.boolean.false : translations.boolean.true}
        />
      </Typography>
    </Link>
  </Card.Row>
);

export default injectIntl(ParticipantRow);
