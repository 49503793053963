import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { AppConsumer } from '@/components/Context/App';
import Dropdown from '@/components/Dropdown';
import HeaderItem from '@/components/Header/HeaderItem';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import If from '@/components/If';
import Icon from '@/components/Icon';

import TeamsDropdownCard from './TeamsDropdownCard';

import style from './TeamsDropdown.sass';

const TeamsDropdown: React.FC = () => (
  <AppConsumer>
    {({ team, loading }) => {
      return (
        <Dropdown renderMenu={({ close }) => <TeamsDropdownCard close={close} />} dropdownClassName={style.dropdown}>
          {({ isOpen, open, close }) => (
            <HeaderItem onClick={isOpen ? close : open}>
              {team && <Picture size="xs" url={team.imageUrl} />}

              <Typography is="span" type="halcyon" className={style.text}>
                <If
                  condition={!loading}
                  then={() => (team ? team.name : <FormattedMessage id={translations.header.teamsDropdown.myTeams} />)}
                />
              </Typography>

              <Icon type={isOpen ? 'chevronUp' : 'chevronDown'} appearance="orange" />
            </HeaderItem>
          )}
        </Dropdown>
      );
    }}
  </AppConsumer>
);

export default TeamsDropdown;
