import React from 'react';
import classnames from 'classnames';

import style from './HiddenInput.sass';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const HiddenInput: React.FC<Props> = (props) => {
  return <input {...props} className={classnames(style.root, props.className)} />;
};

export default HiddenInput;
