import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { taxIdSchema } from '@/validation';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { getBillingInfo, updateBillingInfo } from '@/services/api/billing-info';

import If from '@/components/If';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import InputsContainer from '@/components/InputsContainer';
import CountrySelect from '@/components/Selects/CountrySelect';
import { withValidation } from '@/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);
const CountrySelectWithValidation = withValidation(CountrySelect);

const schema = yup.object({
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.name.label)
    .required(),
  country: yup
    .string()
    .label(translations.inputs.country.label)
    .required(),
  region: yup
    .string()
    .label(translations.inputs.region.label)
    .required(),
  city: yup
    .string()
    .label(translations.inputs.city.label)
    .required(),
  address: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.address.label)
    .required(),
  zipCode: yup
    .string()
    .label(translations.inputs.zipCode.label)
    .required(),
  taxId: taxIdSchema.label(translations.inputs.taxId.label).required()
});

const BillingInfoDetails: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <LoadData load={() => getBillingInfo()}>
      {({ value, loading, reload }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center overlay>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <If
              condition={!value}
              then={() => 'Not found'}
              else={() => (
                <React.Fragment>
                  <Typography is="h6" type="sparrow" weight="bold">
                    <FormattedMessage id={translations.pages.organization.licenses.tabs.billingInfo.billingInfo} />
                  </Typography>

                  <Spacer xs={1} />

                  <Form
                    id="billing-info"
                    schema={schema}
                    initialValues={value}
                    subscription={{
                      dirty: true,
                      pristine: true,
                      submitError: true,
                      submitting: true
                    }}
                    onSubmit={(values) => updateBillingInfo(values).then(reload)}
                  >
                    {({ dirty, pristine, submitError, submitting }, id) => (
                      <React.Fragment>
                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-name`}
                          name="name"
                          label={intl.formatMessage({ id: translations.inputs.name.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.name.placeholder })}
                          readOnly={submitting}
                        />

                        <Spacer xs={2} />

                        <InputsContainer>
                          <Form.Field
                            is={CountrySelectWithValidation}
                            id={`${id}-country`}
                            name="country"
                            label={intl.formatMessage({ id: translations.inputs.country.label })}
                            placeholder={intl.formatMessage({
                              id: translations.inputs.country.placeholder
                            })}
                            searchable
                            readOnly={submitting}
                          />

                          <Form.Field
                            is={TextInputWithValidation}
                            type="text"
                            id={`${id}-region`}
                            name="region"
                            label={intl.formatMessage({ id: translations.inputs.region.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.region.placeholder })}
                            readOnly={submitting}
                          />

                          <Form.Field
                            is={TextInputWithValidation}
                            type="text"
                            id={`${id}-city`}
                            name="city"
                            label={intl.formatMessage({ id: translations.inputs.city.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.city.placeholder })}
                            readOnly={submitting}
                          />

                          <Form.Field
                            is={TextInputWithValidation}
                            type="text"
                            id={`${id}-address`}
                            name="address"
                            label={intl.formatMessage({ id: translations.inputs.address.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.address.placeholder })}
                            readOnly={submitting}
                          />

                          <Form.Field
                            is={TextInputWithValidation}
                            type="text"
                            id={`${id}-zipCode`}
                            name="zipCode"
                            label={intl.formatMessage({ id: translations.inputs.zipCode.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.zipCode.placeholder })}
                            readOnly={submitting}
                          />

                          <Form.Field
                            is={TextInputWithValidation}
                            type="text"
                            id={`${id}-taxId`}
                            name="taxId"
                            label={intl.formatMessage({ id: translations.inputs.taxId.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.taxId.placeholder })}
                            readOnly={submitting}
                          />
                        </InputsContainer>

                        <Spacer xs={2} />

                        {!!submitError && <SubmitError error={submitError} />}

                        <Spacer xs={2} />

                        <Button
                          form={id}
                          type="submit"
                          appearance="orange"
                          loading={submitting}
                          disabled={!dirty || pristine}
                        >
                          <FormattedMessage id={translations.buttons.save} />
                        </Button>
                      </React.Fragment>
                    )}
                  </Form>
                </React.Fragment>
              )}
            />
          )}
        />
      )}
    </LoadData>
  );
};

export default injectIntl(BillingInfoDetails);
