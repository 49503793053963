import React from 'react';
import { Bubble } from 'react-chartjs-2';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { SocialMapContact, Frequency, Warmth, BarChartReportData, Label, EvaluationStatus } from '@/domains';

import { LanguageContext } from '@/components/Language';
import DownloadableChart, { Props as DownloadableChartProps } from '@/components/DownloadableChart';

import { getOptions } from './options';
import { getData } from '@/util';

const mapFrequencyToRadius: Record<Frequency, number> = {
  [Frequency.Never]: 5,
  [Frequency.Seldom]: 4,
  [Frequency.Sometimes]: 3,
  [Frequency.Often]: 2,
  [Frequency.VeryOften]: 1
};

// const mapWarmthToColor: Record<Warmth, string> = {
//   [Warmth.Cold]: divergingPalette[0],
//   [Warmth.Cool]: divergingPalette[2],
//   [Warmth.Warm]: divergingPalette[4],
//   [Warmth.Hot]: divergingPalette[6]
// };

const mapQuadtrantToSinSign: Record<number, number> = {
  1: 1,
  2: -1,
  3: -1,
  4: 1
};

const mapQuadtrantToCosSign: Record<number, number> = {
  1: 1,
  2: 1,
  3: -1,
  4: -1
};

const mapWarmthToQuadrant: Record<Warmth, number> = {
  [Warmth.Hot]: 1,
  [Warmth.Warm]: 2,
  [Warmth.Cool]: 3,
  [Warmth.Cold]: 4
};

const getCoordinatesFromRadiusAndQuadrant = (r: number, quadrant) => {
  const theta = Math.floor(Math.random() * 90);
  const x = r * Math.abs(Math.sin(theta)) * mapQuadtrantToSinSign[quadrant];
  const y = r * Math.abs(Math.cos(theta)) * mapQuadtrantToCosSign[quadrant];

  return { x, y };
};

// const getCoordinatesFromRadius = (r: number) => {
//   const theta = Math.floor(Math.random() * 360);
//   const x = r * Math.abs(Math.sin(theta));
//   const y = r * Math.abs(Math.cos(theta));
//   return { x, y };
// };

interface Props extends Pick<DownloadableChartProps, 'title'> {
  data: {
    participantContactsWithLatestEvaluation: BarChartReportData<Label, SocialMapContact>;
    participantContactsWithoutEvaluations: SocialMapContact[];
  };
  className?: string;
}

const SocialMap: React.FC<Props & WrappedComponentProps> = ({ intl, data, title, className }) => {
  const contactRadius = 10;
  const { language } = React.useContext(LanguageContext);

  return (
    <DownloadableChart
      title={title}
      chart={Bubble}
      data={{
        datasets: [
          ...data.participantContactsWithLatestEvaluation.entries.map((entry) => ({
            label: 'negative',
            backgroundColor: '#FFE2DA',
            borderColor: '#DE2736',
            borderWidth: 1,
            data: [
              ...(!entry.data
                ? []
                : entry.data
                    .filter((data) =>
                      data.evaluation.socialIndicatorEvaluations
                        .map((socialEvaluation) => socialEvaluation.evaluationStatus)
                        .includes(EvaluationStatus.Negative)
                    )
                    .map((data) => ({
                      r: contactRadius,
                      ...getCoordinatesFromRadiusAndQuadrant(
                        mapFrequencyToRadius[data.evaluation.frequency],
                        mapWarmthToQuadrant[entry.label.identifier]
                      ),
                      contact: data,
                      pointBackgroundColor: ['#000000']
                    })))
            ]
          })),
          ...data.participantContactsWithLatestEvaluation.entries.map((entry) => ({
            label: 'positive',
            backgroundColor: '#CBEDDE',
            borderColor: '#54C394',
            borderWidth: 1,
            data: [
              ...(!entry.data
                ? []
                : entry.data
                    .filter(
                      (data) =>
                        data.evaluation.socialIndicatorEvaluations
                          .map((socialEvaluation) => socialEvaluation.evaluationStatus)
                          .includes(EvaluationStatus.Negative) === false
                    )
                    .map((data) => ({
                      r: contactRadius,
                      ...getCoordinatesFromRadiusAndQuadrant(
                        mapFrequencyToRadius[data.evaluation.frequency],
                        mapWarmthToQuadrant[entry.label.identifier]
                      ),
                      contact: data,
                      pointBackgroundColor: ['#000000']
                    })))
            ]
          })),
          // {
          //   label: 'participants-without-evals',
          //   borderColor: 'gray',
          //   borderWidth: 1,
          //   data: [
          //     ...(!data.participantContactsWithoutEvaluations
          //       ? []
          //       : data.participantContactsWithoutEvaluations.map((data) => ({
          //           r: contactRadius,
          //           ...getCoordinatesFromRadius(5),
          //           contact: data
          //         })))
          //   ]
          // },
          {
            label: 'this-participant',
            backgroundColor: '#3F4EB1',
            borderColor: '#3F4EB1',
            borderWidth: 1,
            data: [
              {
                x: 0,
                y: 0,
                r: contactRadius
              }
            ]
          }
        ]
      }}
      options={{
        layout: {
          padding: {
            left: 40,
            right: 40,
            top: 40,
            bottom: 40
          }
        },
        ...getOptions(intl, language)
      }}
      className={className}
      height={700}
      width={700}
    >
      {({ getImage }) => (
        <React.Fragment>
          <DownloadableChart.DownloadButton onClick={() => getImage(`social-map`)}>
            <FormattedMessage id={translations.inputs.chart.jpg} />
          </DownloadableChart.DownloadButton>

          <DownloadableChart.DownloadButton onClick={() => getData(data, `social-map`)}>
            <FormattedMessage id={translations.inputs.chart.data} />
          </DownloadableChart.DownloadButton>
        </React.Fragment>
      )}
    </DownloadableChart>
  );
};

export default injectIntl(SocialMap);
