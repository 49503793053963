import { DataOption, EvaluationStatus } from '@/domains';

export enum SkillEvaluationLearningStatus {
  VeryNegative = 'VERY_NEGATIVE',
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
  VeryPositive = 'VERY_POSITIVE'
}

export enum SkillCategory {
  SocialEmotional = 'SOCIAL_EMOTIONAL',
  Cognitive = 'COGNITIVE',
  Physical = 'PHYSICAL',
  Custom = 'CUSTOM'
}

export interface Skill extends DataOption {
  category: SkillCategory;
}

export interface SkillEvaluation {
  id: string;
  learningStatus: SkillEvaluationLearningStatus;
  confidenceStatus: EvaluationStatus;
  skill: Skill;
}

export interface EvaluateSkill extends Pick<SkillEvaluation, 'learningStatus' | 'confidenceStatus'> {
  skillOptionId: string;
}
