import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { translations } from '@/locale';
import { urls } from '@/constants';

import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import EntityPreview from '@/components/EntityPreview';
import General from '@/components/Customisation/General';
import AgeGroups from '@/components/Customisation/AgeGroups';
import { SessionContext } from '@/components/Context/Session';
import DataOptions from '@/components/Customisation/DataOptions';
import ProfileFields from '@/components/Customisation/ProfileFields';
import RoutedTabbedContainer from '@/components/RoutedTabbedContainer';
import RoutableTab from '@/components/RoutedTabbedContainer/RoutableTab';

import style from './DataCustomisation.sass';

interface Params {
  tab: string;
}

const DataCustomisation: React.FC<RouteComponentProps<Params>> = ({ history, location, match }) => {
  const { organization } = React.useContext(SessionContext);

  const tabUrls = urls.dataCustomisation.tabs;

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

      <Spacer xs={4} />

      <Card className={style.card}>
        <Card.Row>
          <Card.Column sm={12}>
            <Typography is="span" type="swan" weight="bold">
              <FormattedMessage id={translations.pages.organization.dataCustomisation.title} />
            </Typography>
          </Card.Column>
        </Card.Row>

        <RoutedTabbedContainer baseUrl={tabUrls.withTab} history={history} location={location} match={match}>
          <RoutableTab
            url={tabUrls.get(tabUrls.dataOptions)}
            title={<FormattedMessage id={translations.pages.organization.dataCustomisation.tabs.dataOptions.title} />}
          >
            <DataOptions />
          </RoutableTab>

          <RoutableTab
            url={tabUrls.get(tabUrls.profileFields)}
            title={<FormattedMessage id={translations.pages.organization.dataCustomisation.tabs.profileFields.title} />}
          >
            <ProfileFields />
          </RoutableTab>

          <RoutableTab
            url={tabUrls.get(tabUrls.ageGroups)}
            title={<FormattedMessage id={translations.pages.organization.dataCustomisation.tabs.ageGroups.title} />}
          >
            <AgeGroups />
          </RoutableTab>

          <RoutableTab
            url={tabUrls.get(tabUrls.other)}
            title={<FormattedMessage id={translations.pages.organization.dataCustomisation.tabs.general.title} />}
          >
            <General />
          </RoutableTab>
        </RoutedTabbedContainer>
      </Card>
    </div>
  );
};

export default withRouter(DataCustomisation);
