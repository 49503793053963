import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import List from './List';
import Detail from './Detail';

const Organisations: React.FC = () => (
  <Switch>
    <Route exact path={urls.admin.organizations.list} component={List} />

    <Route exact path={urls.admin.organizations.detail} component={Detail} />

    <ConsumeRedirectRoute render={() => <Redirect to={urls.admin.organizations.list} />} />
  </Switch>
);

export default Organisations;
