import { MediaFileType, Image } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

/**
 * Uploads an image. If 'file' is an object it means the user has just selected an image and the function returns the url
 * that the api returns. If it's a string (url of an existing image) the call it's not made and the url is returned. If file
 * is null means the user deleted the image and returns null
 * @param file File object if user selected an image or string if he didn't
 * @param type Image type
 */
export const uploadImage: (file: File | string, type: MediaFileType) => Promise<string | null> = (file, type) => {
  if (!file) return Promise.resolve(null);
  if (typeof file === 'string') return Promise.resolve(file);

  const data = new FormData();

  data.append('file', file);
  data.append('type', type);

  return authorizedApi.post<Image>(`/v1/media`, data).then(({ imageUrl }) => imageUrl);
};

export const uploadIcon: (file: File | string) => Promise<string | null> = (file) => {
  const data = new FormData();

  data.append('file', file);

  return authorizedApi.post<Image>(`/v1/icons`, data).then(({ id }) => id);
};
