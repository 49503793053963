import React from 'react';
import { IntlProvider } from 'react-intl';

import { Language, normalizeLanguage } from '@/constants';
import { getTranslationMessages } from '@/locale';

interface LanguageState {
  readonly language: Language;
  readonly languages: Language[];
}

interface LanguageActions {
  readonly update: (language: Language) => any;
}

interface Props {
  language: Language;
  onUpdate: (language: Language) => any;
}

interface State extends LanguageState, LanguageActions {}

export const LanguageContext = React.createContext<State>({
  language: Language.English,
  languages: [],
  update: () => null
});

export const LanguageConsumer = LanguageContext.Consumer;

export class LanguageProvider extends React.PureComponent<Props, State> implements LanguageActions {
  state: State = {
    language: this.props.language,
    languages: [
      Language.English,
      Language.French,
      Language.Spanish,
      Language.Dutch,
      Language.Albanian,
      Language.Greek,
      Language.Romanian,
      Language.Polish
    ],
    update: this.update.bind(this)
  };

  update(language: Language) {
    const normalizedLanguage = normalizeLanguage(language);

    return this.setState({ language: normalizedLanguage }, () => this.props.onUpdate(normalizedLanguage));
  }

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        <IntlProvider locale={this.state.language.toLowerCase()} messages={getTranslationMessages(this.state.language)}>
          {this.props.children}
        </IntlProvider>
      </LanguageContext.Provider>
    );
  }
}

export default {
  LanguageProvider,
  LanguageConsumer,
  LanguageContext
};
