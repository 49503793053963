import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { removeParticipantFromSelfEvaluation } from '@/services/api/self-evaluations';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import TextWithIcon from '@/components/TextWithIcon';
import PictureWithData from '@/components/PictureWithData';
import EvaluationCategories from '@/components/EvaluationCategories';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import cellStyle from '../cellStyle.sass';

import style from './ParticipantRow.sass';

export interface Props {
  sessionId: string;
  participantRow: any;
  teamId: string;
  onRemove: () => void;
}

const ParticipantRow: React.FC<Props & WrappedComponentProps> = ({
  teamId,
  sessionId,
  participantRow: { id, nickname, imageUrl, evaluatedModules, birthDate },
  onRemove,
  intl,
  ...rest
}) => (
  <Card.Row is="li" className={style.container} {...rest}>
    <Link to={urls.participants.detailTabs.get(id, urls.participants.detailTabs.digitalCoach)} className={style.root}>
      <div className={classnames(cellStyle.participant, style.rowFlex)}>
        <PictureWithData
          size="md"
          url={imageUrl ? imageUrl : null}
          blueText={nickname}
          grayText={
            birthDate
              ? intl.formatMessage(
                  { id: translations.pages.sessions.details.tabs.participants.content.table.age },
                  { age: getAgeFromBirthdate(birthDate) }
                )
              : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge })
          }
        />
      </div>

      <div className={classnames(cellStyle.evaluated, style.centeredDoubleLine)}>
        <EvaluationCategories categories={evaluatedModules} />
      </div>
    </Link>

    <Modal
      modal={CrudConfirmationModal}
      message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
      onConfirm={() => removeParticipantFromSelfEvaluation(sessionId, id, teamId).then(onRemove)}
    >
      {({ open }) => (
        <Button onClick={open}>
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird" className={style.deleteLocation}>
            <FormattedMessage id={translations.inputs.remove} />
          </TextWithIcon>
        </Button>
      )}
    </Modal>
  </Card.Row>
);

export default injectIntl(ParticipantRow);
