import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getStreetSessionActivities } from '@/services/api/street-session-activities';

import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Search from '@/components/Search';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import AddActivitiesToSessionModal from '@/components/SearchableModals/AddActivitiesToSessionModal';

import ActivityRow from './ActivityRow';
import ActivitiesHeader from './ActivitiesHeader';

import style from './Activities.sass';

export interface Props {
  sessionId: string;
}

const Activities: React.FC<Props & WrappedComponentProps> = ({ intl, sessionId }) => {
  const [activities, setActivities] = React.useState([]);

  return (
    <Refresh>
      {({ key, refresh }) => (
        <div className={style.root}>
          <Search
            label={intl.formatMessage({ id: translations.inputs.activitiesSearch.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.activitiesSearch.placeholder })}
            searchBarClassName={style.searchBar}
          >
            {({ criteria }) => (
              <React.Fragment>
                <Table
                  key={key}
                  id={criteria}
                  source={() =>
                    getStreetSessionActivities(sessionId, 'PARTICIPANT', criteria).then((activities) => {
                      setActivities(activities.map((activity) => activity.id));
                      return activities;
                    })
                  }
                  renderHeader={() => <ActivitiesHeader />}
                  renderRow={(data) => (
                    <ActivityRow key={data.id} sessionId={sessionId} data={data} onDelete={refresh} />
                  )}
                  withoutPagination
                />
              </React.Fragment>
            )}
          </Search>

          <Modal
            modal={AddActivitiesToSessionModal}
            initialValues={activities}
            onSubmit={refresh}
            sessionId={sessionId}
          >
            {({ open }) => (
              <Button fat type="button" appearance="orange" className={style.addButton} onClick={open}>
                <FormattedMessage id={translations.inputs.addActivity} />
              </Button>
            )}
          </Modal>
        </div>
      )}
    </Refresh>
  );
};

export default injectIntl(Activities);
