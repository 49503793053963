import React from 'react';
import { useIntl } from 'react-intl';

import { Filter } from '@/domains';
import { translations } from '@/locale';
import { getLabels } from '@/services/api/label';

import LoadableSelect, { Props as LoadableSelectProps } from '../LoadableSelect';

interface Props extends Omit<LoadableSelectProps, 'source'> {}

const SessionLabelSelect: React.FC<Props> = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <LoadableSelect
      source={() =>
        // TODO use infinite scroll
        getLabels(1, 1000).then((page) =>
          [{ id: Filter.All as string, name: formatMessage({ id: translations.miscellaneous.all }) }].concat(
            page.content.map(({ id, name }) => ({ id, name }))
          )
        )
      }
      {...props}
    />
  );
};

export default SessionLabelSelect;
