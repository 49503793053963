import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { QuestionType } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';

interface Props extends Omit<SelectProps<QuestionType>, 'items'>, WrappedComponentProps {}

class QuestionTypeSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(QuestionType).map((type) => ({
      value: type,
      name: intl.formatMessage({ id: translations.questionType[type] })
    }));

    return <Select {...rest} items={items} />;
  }
}

export default injectIntl(QuestionTypeSelect);
