import React from 'react';
import Downshift, {
  GetItemPropsOptions,
  DownshiftState,
  StateChangeOptions,
  ControllerStateAndHelpers
} from 'downshift';
import classnames from 'classnames';
import { compareTwoStrings } from 'string-similarity';
import isEqual from 'lodash/isEqual';

import TextInput from '@/components/TextInput';
import Dropdown from '@/components/Dropdown';
import Icon, { Props as IconProps } from '@/components/Icon';

import SelectItem from './SelectItem';

import style from './Select.sass';

export interface Item<I> {
  value: I;
  name: string;
  disabled?: boolean;
}

export interface RenderItemProps<I> {
  items: Item<I>[];
  inputValue?: string;
  children: React.ReactNode;
}

export interface Props<T, I = T>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onSelect' | 'value'> {
  value: T | T[] | null;
  items: Item<I>[];
  loading?: boolean;
  searchable?: boolean;
  editable?: boolean;
  multiple?: boolean;
  inputClassName?: string;
  iconAppearance?: IconProps['appearance'];
  getValue: (item: Item<I> | null) => I;
  getItemValue: (value: T) => I;
  getSelectedDisplayName?: (props: { inputValue: string; selectedItems: Item<I>[] }) => string;
  renderItems: (props: RenderItemProps<I>) => React.ReactNode;
  onInputValueChange: (inputValue: string, stateAndHelpers: ControllerStateAndHelpers<any>) => any;
  onSelect(value: I[] | I | null): any;
  onChange(value: I[] | I | null): any;
}

export default class Select<T, I> extends React.PureComponent<Props<T, I>> {
  static Item = SelectItem;

  static defaultProps: Pick<
    Props<string, string>,
    'getValue' | 'getItemValue' | 'getSelectedDisplayName' | 'renderItems' | 'iconAppearance'
  > = {
    iconAppearance: 'blue',
    getValue: (item) => (item ? item.value : null),
    getItemValue: (value) => value || null,
    getSelectedDisplayName: ({ selectedItems }) => selectedItems.map((item) => item.name).join(', ') || '',
    renderItems: ({ children }) => children
  };

  filter = (items: Item<I>[], input: string) => {
    const normalizeName = (value: string) => value.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const normalizedInput = input && input.length > 0 ? normalizeName(input) : null;

    if (!normalizedInput || !this.props.searchable) return items;

    const normalizeIndex = (index: number) => (index < 0 ? Infinity : index);

    return items
      .map((item) => {
        const normalizedName = normalizeName(item.name);
        const similarity = compareTwoStrings(normalizedInput, normalizedName);

        return { item, normalizedName, similarity, index: normalizedName.indexOf(normalizedInput) };
      })
      .filter((item) => item.index >= 0 || item.similarity > 0.8)
      .sort((one, two) => {
        const indexDifference = normalizeIndex(one.index) - normalizeIndex(two.index);

        // prioritize exact searches first
        if (indexDifference !== 0) return indexDifference;

        return two.similarity - one.similarity;
      })
      .map(({ item }) => item);
  };

  handleChange = (selectedItem: Item<I> | null) => {
    const { multiple, getValue, getItemValue } = this.props;

    if (!selectedItem) return;

    const selectedValue = getValue(selectedItem);

    if (!multiple) return this.updateValues(selectedValue);

    if (this.getSelectedValues().some((value) => isEqual(value, selectedValue))) {
      return this.removeValue(selectedValue);
    }

    return this.addValue(selectedValue);
  };

  getSelectedValues = () => {
    const { value, getItemValue } = this.props;

    if (typeof value !== 'boolean' && !value) return [];
    if (Array.isArray(value)) return value.map(getItemValue);

    return [getItemValue(value)];
  };

  removeValue(selectedValue: I) {
    this.props.onChange(this.getSelectedValues().filter((value) => value !== selectedValue));
  }

  addValue(selectedValue: I) {
    this.props.onChange([...this.getSelectedValues(), selectedValue]);
  }

  updateValues = (selectedValues: I | I[] | null) => {
    this.props.onChange(selectedValues);
  };

  stateReducer = (state: DownshiftState<any>, changes: StateChangeOptions<any>) => {
    if (!this.props.multiple) return changes;

    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: this.props.multiple,
          inputValue: ''
        };
    }

    return changes;
  };

  renderItems(
    inputValue: string | null,
    items: Item<I>[],
    selectedItems: Item<I>[],
    highlightedIndex: number | null,
    getItemProps: (options: GetItemPropsOptions<any>) => any
  ) {
    const filteredItems = this.filter(items, inputValue);

    const children = filteredItems.map((item, index) => (
      <SelectItem
        key={item.value}
        active={highlightedIndex === index}
        selected={
          item &&
          selectedItems.some((selectedItem) => isEqual(this.props.getValue(selectedItem), this.props.getValue(item)))
        }
        disabled={item.disabled}
        {...getItemProps({ item, index })}
      >
        {item.name}
      </SelectItem>
    ));

    return this.props.renderItems({ items: filteredItems, inputValue, children });
  }

  render() {
    const {
      id,
      value,
      items,
      placeholder,
      searchable,
      editable,
      multiple,
      onBlur,
      onInputValueChange,
      getValue,
      getItemValue,
      getSelectedDisplayName,
      renderItems,
      className,
      iconAppearance,
      inputClassName,
      ...rest
    } = this.props;

    const selectedValues = this.getSelectedValues();

    const selectedItems = selectedValues
      .map((value) => items.find((item) => isEqual(getValue(item), value)))
      .filter((item) => item);

    return (
      <Downshift
        id={id}
        itemToString={(item) => (item ? item.name : null)}
        defaultHighlightedIndex={0}
        stateReducer={this.stateReducer}
        onChange={this.handleChange}
        onInputValueChange={onInputValueChange}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
          closeMenu,
          setState
        }) => (
          <div className={style.root}>
            <Dropdown
              isOpen={isOpen}
              renderMenu={() => (
                <Dropdown.Content>
                  <ul {...getMenuProps({ className: style.scrollable }, { suppressRefError: true })}>
                    {this.renderItems(inputValue, items, selectedItems, highlightedIndex, getItemProps)}
                  </ul>
                </Dropdown.Content>
              )}
              dropdownClassName={style.menu}
            >
              {() => {
                const inputProps = getInputProps({
                  placeholder
                });

                return (
                  <React.Fragment>
                    {/*
                    // @ts-ignore */}
                    <TextInput
                      {...rest}
                      {...inputProps}
                      value={
                        isOpen
                          ? inputValue || ''
                          : getSelectedDisplayName({
                              selectedItems,
                              inputValue: inputValue || ''
                            })
                      }
                      onClick={() => {
                        setState({ inputValue: '' });
                        openMenu();
                      }}
                      onBlur={(event) => {
                        closeMenu();
                        if (inputProps.onBlur) inputProps.onBlur(event);
                        if (onBlur) onBlur(event);
                      }}
                      readOnly={rest.readOnly || !(editable || searchable)}
                      className={classnames({ [style.open]: isOpen }, className)}
                      inputClassName={classnames(style.input, inputClassName)}
                    />

                    <Icon
                      className={style.icon}
                      appearance={rest.disabled ? 'gray' : iconAppearance}
                      type={isOpen ? 'chevronUp' : 'chevronDown'}
                    />
                  </React.Fragment>
                );
              }}
            </Dropdown>
          </div>
        )}
      </Downshift>
    );
  }
}
