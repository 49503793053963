import React from 'react';

interface RenderProps {
  key: number;
  refresh: () => any;
}

interface Props {
  children: (props: RenderProps) => any;
}

interface State {
  key: number;
}

export default class Refresh extends React.PureComponent<Props, State> {
  state: State = {
    key: new Date().getTime()
  };

  refresh = () => this.setState({ key: new Date().getTime() });

  render() {
    return this.props.children({ key: this.state.key, refresh: this.refresh });
  }
}
