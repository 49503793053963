import React from 'react';
import classnames from 'classnames';

import { getStylesWithSize } from '@/util';
import { WithSize, Magnitude } from '@/constants';

import style from './Column.sass';

export interface Props extends WithSize<Magnitude> {
  padded?: boolean;
  right?: boolean;
  className?: string;
}

export default class Column extends React.PureComponent<Props> {
  static defaultProps: Pick<Props, 'padded'> = {
    padded: true
  };

  render() {
    return (
      <div
        className={classnames(
          style.root,
          getStylesWithSize(
            (size) => (style as any)[`${size}${this.props[size] || 0}`],
            (size) => this.props[size] != null
          ),
          {
            [style.padded]: this.props.padded,
            [style.right]: this.props.right
          },
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
