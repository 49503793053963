import { State } from '@/domains';

export enum ParticipantProfileFieldType {
  VitalStatus = 'PARTICIPANT_VITAL_STATUS_FIELD',
  State = 'PARTICIPANT_STATE_FIELD',
  Nationality = 'PARTICIPANT_NATIONALITY_FIELD',
  Address = 'PARTICIPANT_ADDRESS_FIELD',
  Language = 'PARTICIPANT_LANGUAGE_FIELD',
  FirstName = 'PARTICIPANT_FIRST_NAME_FIELD',
  LastName = 'PARTICIPANT_LAST_NAME_FIELD',
  PhoneNumber = 'PARTICIPANT_PHONE_NUMBER_FIELD'
}

export interface ParticipantProfileField {
  participantField: ParticipantProfileFieldType;
  state: State;
}
