import React, { PureComponent } from 'react';
import classnames from 'classnames';

import SidebarWrapper, { Props as SidebarWrapperProps } from './SidebarWrapper';
import { SidebarProvider } from '@/components/Context/Sidebar';
import { SessionConsumer } from '@/components/Context/Session';
import { NotificationsProvider } from '@/components/Context/Notifications';

import style from './AppLayout.sass';

export interface Props {
  className?: string;
}

export default class AppLayout extends PureComponent<Props> {
  static Header = ({ ...props }) => <div className={style.header} {...props} />;
  static Page = ({ ...props }) => <div className={style.page} {...props} />;
  static Sidebar = ({ ...props }: SidebarWrapperProps) => <SidebarWrapper className={style.sidebar} {...props} />;
  static Content = ({ ...props }) => <div className={style.content} {...props} />;

  render() {
    return (
      <SidebarProvider isOpen={false}>
        <SessionConsumer>
          {({ me }) => (
            <NotificationsProvider isOpen={false} initialCount={me.openReminders}>
              <div className={classnames(style.root, this.props.className)}>{this.props.children}</div>
            </NotificationsProvider>
          )}
        </SessionConsumer>
      </SidebarProvider>
    );
  }
}
