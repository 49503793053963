import React from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Note, CreateNote } from '@/domains';
import { createSessionNote, updateSessionNote } from '@/services/api/street-session-notes';

import If from '@/components/If';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import SessionNote from '@/components/SessionNote';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import NoteDetailModal from '@/components/Modals/NoteDetailModal';
import CreateNoteModal from '@/components/Modals/CreateNoteModal';

import style from './NoteContainer.sass';

export interface Props {
  sessionId: string;
  notes: Note[];
  onChange: () => any;
}

const NoteContainer: React.FC<Props & WrappedComponentProps> = ({ sessionId, notes, intl, onChange, ...rest }) => (
  <React.Fragment>
    <div className={style.notes}>
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage id={translations.pages.sessions.details.tabs.details.notes.title} />
      </Typography>
      <Modal
        modal={CreateNoteModal}
        submitFunction={(values: CreateNote) => createSessionNote(sessionId, values).then(onChange)}
        onNoteCreated={onChange}
        type="session"
      >
        {({ open }) => (
          <TextWithIcon is="span" type="halcyon" icon="upload" boxed color="orange" onClick={open}>
            <FormattedMessage id={translations.pages.sessions.details.tabs.details.notes.createNote} />
          </TextWithIcon>
        )}
      </Modal>
    </div>
    <If
      condition={!!notes}
      then={() =>
        notes.map((note, index) => (
          <React.Fragment key={`note-${index}`}>
            {index > 0 && <Spacer xs={1} />}
            <Modal
              key={note.id}
              note={note}
              modal={NoteDetailModal}
              submitFunction={(values) => updateSessionNote(note.id, values)}
              onNoteUpdated={onChange}
            >
              {({ open }) => (
                <div className={style.note} onClick={open}>
                  <SessionNote data={note} />
                </div>
              )}
            </Modal>
          </React.Fragment>
        ))
      }
      else={() => (
        <Placeholder image="cards" size="sm">
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.pages.sessions.details.tabs.details.notes.noNotes} />
          </Typography>
        </Placeholder>
      )}
    />
  </React.Fragment>
);

export default injectIntl(NoteContainer);
