import React from 'react';

import Dropdown from '@/components/Dropdown';
import Card from '@/components/Card';
import BoxedIcon from '@/components/BoxedIcon';

import style from './DownloadDropdown.sass';

export interface Props {
  className?: string;
}

const DownloadDropdown: React.FC<Props> = ({ children, className }) => {
  return (
    <Dropdown
      className={className}
      dropdownClassName={style.dropdownOptionsContainer}
      renderMenu={() => <Card className={style.card}>{children}</Card>}
    >
      {({ open }) => (
        <div onClick={open}>
          <BoxedIcon type="download" appearance="orange" />
        </div>
      )}
    </Dropdown>
  );
};

export default DownloadDropdown;
