import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import EmergencyContactModal from '@/components/Modals/EmergencyContactModal';

import cellStyle from '../cellStyle.sass';

import style from './EmergencyInfoRow.sass';
import { deleteEmergencyContact } from '@/services/api/emergency-contacts';

export interface Props {
  data: any;
  onDelete: () => any;
  onUpdate: () => any;
}

const EmergencyInfoRow: React.FC<Props & WrappedComponentProps> = ({ data, onDelete, onUpdate, intl, ...rest }) => (
  <Modal modal={EmergencyContactModal} data={data} onSubmit={onUpdate}>
    {({ open }) => (
      <Card.Row is="li" className={style.root} {...rest}>
        <div className={style.row} onClick={open}>
          <Typography is="span" type="halcyon" className={cellStyle.name}>
            {data.name && data.name}
          </Typography>

          <Typography is="span" type="halcyon" className={cellStyle.phoneNumber}>
            {data.phoneNumber && data.phoneNumber}
          </Typography>
        </div>

        <Button onClick={() => deleteEmergencyContact(data.id).then(onDelete)} className={style.remove}>
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
            <FormattedMessage id={translations.inputs.remove} />
          </TextWithIcon>
        </Button>
      </Card.Row>
    )}
  </Modal>
);

export default injectIntl(EmergencyInfoRow);
