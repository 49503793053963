import { DataOption, EvaluationStatus } from '@/domains';

export enum GoalState {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED'
}

// TODO!: Talk to backend to add createdOn
export interface Goal {
  id: string;
  startsAt: Date;
  endsAt: Date;
  description: string;
  type: DataOption;
  completionStatus?: GoalState;
}

export interface CreateGoal extends Pick<Goal, 'description' | 'type'> {
  duration: number;
}

export interface GoalEvaluation {
  goal: Goal;
  id: string;
  lastEvaluatedAt: Date;
  currentEvaluationStatus: EvaluationStatus;
}
