import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

interface Props {
  showFieldType?: boolean;
}

const ProfileFieldsHeader: React.FC<Props> = ({ showFieldType }) => {
  const { name, fieldType, enabled } = translations.dataCustomisation.profileFields.profileFieldsHeader;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={cellStyle.headerContainer}>
        <Typography
          is="div"
          type="halcyon"
          color="gray"
          className={showFieldType ? cellStyle.customPropertyName : cellStyle.profileFieldName}
        >
          <FormattedMessage id={name} />
        </Typography>

        {showFieldType && (
          <Typography is="div" type="halcyon" color="gray" className={cellStyle.customPropertyFieldType}>
            <FormattedMessage id={fieldType} />
          </Typography>
        )}

        <Typography is="div" type="halcyon" color="gray" className={cellStyle.enabled}>
          <FormattedMessage id={enabled} />
        </Typography>
      </div>
      {showFieldType && <div className={cellStyle.remove}></div>}
    </Card.Row>
  );
};

export default ProfileFieldsHeader;
