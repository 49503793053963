import React from 'react';

import { DataOptionType, DataOption, State as DataOptionState } from '@/domains';

import Select, { Item, Props as SelectProps } from '@/components/Select';
import { getDataOptionsForTeam } from '@/services/api/data-option';

export interface Props extends Omit<SelectProps<string, string>, 'items'> {
  dataOptionType: DataOptionType;
  teamId: string;
  language: string;
}

interface State {
  loading: boolean;
  items: Item<string>[];
}

class DataOptionSelect extends React.PureComponent<Props, State> {
  static SessionTypeSelect = (props) => <DataOptionSelect dataOptionType={DataOptionType.SessionType} {...props} />;
  static ContactTypeSelect = (props) => <DataOptionSelect dataOptionType={DataOptionType.ContactType} {...props} />;
  static LegalStatusSelect = (props) => <DataOptionSelect dataOptionType={DataOptionType.LegalStatus} {...props} />;
  static HomeStatusSelect = (props) => <DataOptionSelect dataOptionType={DataOptionType.LegalLivingPlace} {...props} />;
  static SkillSelect = (props) => <DataOptionSelect dataOptionType={DataOptionType.Skill} {...props} />;
  static GoalTypeSelect = (props) => <DataOptionSelect dataOptionType={DataOptionType.GoalType} {...props} />;

  state: State = { loading: false, items: [] };

  componentDidMount() {
    this.loadByType();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) this.loadByType();
  }

  loadByType = () => {
    const { dataOptionType, teamId } = this.props;
    return this.load(() => getDataOptionsForTeam(dataOptionType, teamId, DataOptionState.Enabled));
  };

  load = (callback: () => Promise<DataOption[]>) => {
    this.setState({ loading: true });
    return callback()
      .then((items) => {
        this.setState({
          loading: false,
          items: items.map((item) => ({
            value: item.id,
            name:
              item.translations && item.translations[this.props.language.toLowerCase()]
                ? item.translations[this.props.language.toLowerCase()]
                : item.name
          }))
        });
      })
      .catch(() => this.setState({ loading: false, items: [] }));
  };

  render() {
    const { dataOptionType, teamId, ...rest } = this.props;
    const { items } = this.state;

    return <Select {...rest} items={items} searchable={false} />;
  }
}

export default DataOptionSelect;
