import React from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import { roundToTwoDecimals } from '@/util';
import { ReportFilters, ParticipantReport } from '@/domains';
import { getParticipantReports } from '@/services/api/participant-reports';

import If from '@/components/If';
import Spacer from '@/components/Spacer';
import Reports from '@/components/Reports';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import SocialMap from '@/components/SocialMap';
import Typography from '@/components/Typography';

import style from '../Reports.sass';

export interface Props extends WrappedComponentProps {
  participantId: string;
  teamId: string;
  criteria: ReportFilters;
}

const SocialMapSection: React.FC<Props> = ({ intl, participantId, teamId, criteria }) => {
  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.teams.participants.details.reports.socialMap.title
      })}
    >
      <LoadData
        key={JSON.stringify(criteria)}
        load={() =>
          getParticipantReports<ParticipantReport.SocialMap>(
            participantId,
            teamId,
            criteria,
            ParticipantReport.ReportType.SocialMap
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <React.Fragment>
                <div className={style.socialMapLegend}>
                  <div className={style.row}>
                    <div className={style.socialMapGreen} />
                    <Typography is="span" type="halcyon">
                      <FormattedMessage
                        id={translations.pages.teams.participants.details.reports.socialMap.socialMapLegend.positive}
                      />
                    </Typography>
                  </div>

                  <div className={style.row}>
                    <div className={style.socialMapRed} />
                    <Typography is="span" type="halcyon">
                      <FormattedMessage
                        id={translations.pages.teams.participants.details.reports.socialMap.socialMapLegend.negative}
                      />
                    </Typography>
                  </div>
                </div>

                <Spacer xs={2} />

                <SocialMap title="socialMap" data={value.socialMap} className={style.socialMap} />

                <Spacer xs={4} />

                <Reports.ItemsContainer>
                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.percentageOfNegativity.atStart
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.main.reporting.socialMap.percentageOfNegativity.result },
                      { result: Math.round((value.percentageOfNegativity.atStart + Number.EPSILON) * 100) }
                    )}
                  />

                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.percentageOfNegativity.atEnd
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.main.reporting.socialMap.percentageOfNegativity.result },
                      { result: Math.round((value.percentageOfNegativity.atEnd + Number.EPSILON) * 100) }
                    )}
                  />
                </Reports.ItemsContainer>

                <Reports.ItemsContainer>
                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.sizeOfCoreNetwork.atStart
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.main.reporting.socialMap.sizeOfCoreNetwork.result },
                      { result: roundToTwoDecimals(value.sizeOfCoreNetwork.atStart) }
                    )}
                  />

                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.sizeOfCoreNetwork.atEnd
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.main.reporting.socialMap.sizeOfCoreNetwork.result },
                      { result: roundToTwoDecimals(value.sizeOfCoreNetwork.atEnd) }
                    )}
                  />
                </Reports.ItemsContainer>

                <Reports.ItemsContainer>
                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.networkWarmth.atStart
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.teams.participants.details.reports.socialMap.networkWarmth.result },
                      { result: roundToTwoDecimals(value.networkWarmth.atStart) }
                    )}
                  />

                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.networkWarmth.atEnd
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.teams.participants.details.reports.socialMap.networkWarmth.result },
                      { result: roundToTwoDecimals(value.networkWarmth.atEnd) }
                    )}
                  />
                </Reports.ItemsContainer>

                <Reports.ItemsContainer>
                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.networkHelpfulness.atStart
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.teams.participants.details.reports.socialMap.networkHelpfulness.result },
                      { result: roundToTwoDecimals(value.networkHelpfulness.atStart) }
                    )}
                  />

                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.networkHelpfulness.atEnd
                    })}
                    value={intl.formatMessage(
                      { id: translations.pages.teams.participants.details.reports.socialMap.networkHelpfulness.result },
                      { result: roundToTwoDecimals(value.networkHelpfulness.atEnd) }
                    )}
                  />
                </Reports.ItemsContainer>

                <Reports.ItemsContainer>
                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.networkDensity.atStart
                    })}
                    value={roundToTwoDecimals(value.networkDensity.atStart)}
                  />

                  <Reports.Item
                    className={style.reportSectionItem}
                    name={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.socialMap.networkDensity.atEnd
                    })}
                    value={roundToTwoDecimals(value.networkDensity.atEnd)}
                  />
                </Reports.ItemsContainer>
              </React.Fragment>
            )}
          />
        )}
      </LoadData>
    </Reports>
  );
};

export default SocialMapSection;
