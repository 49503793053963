import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';

import { urls } from '@/constants';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';

import signs from '@/assets/signs.png';

import style from './ForgotPasswordLinkSent.sass';

export interface Props extends RouteComponentProps {}

export default class ForgotPasswordLinkSent extends React.PureComponent<Props> {
  render() {
    return (
      <Card className={style.card}>
        <div className={style.topSection}>
          <img src={signs} className={style.image} />

          <Typography is="span" type="swan" weight="bold">
            <FormattedMessage id={translations.pages.resetLinkSent.title} />
          </Typography>

          <Spacer xs={1} />

          <Typography is="span" type="halcyon" color="gray">
            <FormattedMessage id={translations.pages.resetLinkSent.subtitle} />
          </Typography>
        </div>

        <Spacer xs={2} />

        <div className={style.redirect}>
          <Typography is="span" type="halcyon">
            <FormattedMessage
              id={translations.pages.resetLinkSent.redirect}
              values={{
                a: (children) => (
                  <Link to={urls.signIn} appearance="orange">
                    {children}
                  </Link>
                )
              }}
            />
          </Typography>
        </div>
      </Card>
    );
  }
}
