import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import classnames from 'classnames';

import { urls, paymentStatusColor } from '@/constants';
import { translations } from '@/locale';
import { OrganizationWithPaymentStatus, State, PaymentStatus } from '@/domains';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';

import cellStyle from '../cellStyle.sass';

import style from './OrganizationRowItem.sass';

export interface Props {
  organization: OrganizationWithPaymentStatus;
}

const renderPaymentStatusBox = (paymentStatus: PaymentStatus) => {
  return paymentStatus === PaymentStatus.NotAvailable ? (
    '-'
  ) : (
    <BoxedContent appearance={paymentStatusColor[paymentStatus]} className={style.paymentStatus}>
      <FormattedMessage id={translations.paymentStatuses[paymentStatus]} />
    </BoxedContent>
  );
};

const renderAccountStatus = (accountStatus: State) => {
  return (
    <BoxedContent appearance={accountStatus === 'ENABLED' ? 'blue' : 'gray'} className={style.paymentStatus}>
      <FormattedMessage id={translations.accountStatuses[accountStatus]} />
    </BoxedContent>
  );
};

const OrganizationRowItem: React.FC<Props> = ({ organization }) => (
  <Link
    to={{
      pathname: urls.admin.organizations.get(organization.id),
      state: {
        name: organization.name
      }
    }}
    className={classnames(cellStyle.container, style.root)}
  >
    <div className={classnames(cellStyle.organisationName, style.nameContainer)}>
      <Picture size="md" url={organization.logoUrl} className={style.picture} />

      <Typography is="div" type="halcyon" weight="bold" className={style.orgName}>
        {organization.name}
      </Typography>
    </div>

    <div className={classnames(cellStyle.address)}>{organization.address}</div>

    <Typography is="span" className={cellStyle.registered} type="halcyon">
      <FormattedDate value={organization.createdAt} month="long" day="2-digit" year="numeric" />
    </Typography>

    <div className={classnames(cellStyle.paymentStatus)}>
      {renderPaymentStatusBox(organization.status ? organization.status : PaymentStatus.NotAvailable)}
    </div>

    <div className={classnames(cellStyle.accountStatus)}>{renderAccountStatus(organization.state)}</div>
  </Link>
);

export default OrganizationRowItem;
