import React from 'react';

interface NotificationsState {
  readonly isOpen: boolean;
}

interface NotificationsActions {
  readonly open: () => any;
  readonly close: () => any;
  readonly increment: () => any;
  readonly decrement: () => any;
  readonly setCount: (count: number) => any;
}

interface Props {
  isOpen: boolean;
  initialCount: number;
}

interface State extends NotificationsState, NotificationsActions {
  count: number;
}

export const NotificationsContext = React.createContext<State>({
  isOpen: false,
  count: 0,
  open: () => null,
  close: () => null,
  increment: () => null,
  decrement: () => null,
  setCount: () => null
});

export const NotificationsConsumer = NotificationsContext.Consumer;

export class NotificationsProvider extends React.PureComponent<Props, State> implements NotificationsActions {
  setCount = (count: number) => this.setState({ count });

  open = () => this.setState({ isOpen: true });

  close = () => this.setState({ isOpen: false });

  increment = () => this.setState(({ count }) => ({ count: count + 1 }));

  decrement = () => this.setState(({ count }) => ({ count: count - 1 }));

  state: State = {
    count: this.props.initialCount,
    isOpen: this.props.isOpen,
    open: this.open,
    close: this.close,
    increment: this.increment,
    decrement: this.decrement,
    setCount: this.setCount
  };

  render() {
    return <NotificationsContext.Provider value={this.state}>{this.props.children}</NotificationsContext.Provider>;
  }
}

export default {
  NotificationsProvider,
  NotificationsConsumer,
  NotificationsContext
};
