import React from 'react';
import classnames from 'classnames';

import style from './ItemsContainer.sass';

interface Props {
  className?: string;
}

const ItemsContainer: React.FC<Props> = ({ className, children }) => (
  <div className={classnames(style.root, className)}>{children}</div>
);

export default ItemsContainer;
