import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { LocationReport } from '@/domains';
import { getAgeFromBirthdate } from '@/util';
import { getRecommendedParticipants } from '@/services/api/location-reports';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Table from '@/components/StatelessTable';
import TextWithIcon from '@/components/TextWithIcon';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';
import PictureWithData from '@/components/PictureWithData';
import BoxedIcon from '@/components/BoxedIcon';

import style from './RecommendedParticipantsTable.sass';

interface Props {
  locationId: string;
}

const RecommendedParticipantsTable: React.FC<Props & WrappedComponentProps> = ({ intl, locationId }) => {
  const renderTickOrCross = (condition: boolean) =>
    condition ? <BoxedIcon type="checkmark" appearance="green" /> : <BoxedIcon type="close" appearance="red" />;

  return (
    <InfiniteScrollableTable
      source={(page, number) => getRecommendedParticipants(locationId, page, number)}
      renderHeader={() => (
        <Table.Header>
          <Table.Data sm={6}>
            <FormattedMessage
              id={translations.pages.teams.tabs.location.reports.recommendedParticipants.table.header.participants}
            />
          </Table.Data>

          <Table.Data sm={6}>
            <TextWithIcon is="span" icon="flag" iconColor="blue">
              <FormattedMessage
                id={translations.pages.teams.tabs.location.reports.recommendedParticipants.table.header.goals}
              />
            </TextWithIcon>
          </Table.Data>

          <Table.Data sm={6}>
            <TextWithIcon is="span" icon="allTeams" iconColor="blue">
              <FormattedMessage
                id={translations.pages.teams.tabs.location.reports.recommendedParticipants.table.header.evaluations}
              />
            </TextWithIcon>
          </Table.Data>
        </Table.Header>
      )}
      empty={() => (
        <Placeholder image="questions" size="md" className={style.empty}>
          <Typography is="span" type="halcyon" weight="bold">
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.recommendedParticipants.table.none} />
          </Typography>
        </Placeholder>
      )}
      listClassName={style.table}
    >
      {(data) => (
        <React.Fragment>
          {data.map((participant: LocationReport.RecommendedParticipant, index) => (
            <Table.Row key={index} is="li">
              <Table.Data sm={6}>
                <PictureWithData
                  size="md"
                  url={participant.imageUrl}
                  blueText={participant.nickname}
                  grayText={
                    participant.birthDate
                      ? intl.formatMessage(
                          { id: translations.pages.teams.participants.list.age },
                          { years: getAgeFromBirthdate(participant.birthDate) }
                        )
                      : null
                  }
                />
              </Table.Data>

              <Table.Data sm={6}>{renderTickOrCross(participant.hasOpenGoals)}</Table.Data>

              <Table.Data sm={6}>{renderTickOrCross(participant.hasSocialMapEvaluations)}</Table.Data>
            </Table.Row>
          ))}
        </React.Fragment>
      )}
    </InfiniteScrollableTable>
  );
};

export default injectIntl(RecommendedParticipantsTable);
