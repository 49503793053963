import { State } from '@/domains';
import { SkillCategory } from './skill';

export enum DataOptionType {
  ContactType = 'CONTACT_TYPE',
  GoalType = 'GOAL_TYPE',
  Skill = 'SKILL',
  ActivityType = 'ACTIVITY_TYPE',
  SessionType = 'SESSION_TYPE',
  LegalDocument = 'LEGAL_DOCUMENT',
  LegalStatus = 'LEGAL_STATUS',
  LegalLivingPlace = 'LEGAL_LIVING_PLACE',
  DaytimeOccupation = 'DAYTIME_OCCUPATION',
  ContactHelpfulnessIndicator = 'CONTACT_HELPFULNESS_INDICATOR',
  SelfEvaluation = 'SELF_EVALUATION'
}

export interface DataOption {
  id: string;
  name: string;
  state: State;
  category?: string;
  icon?: {
    id: string;
    url: string;
  };
  translations;
}

export interface NewDataOption extends Pick<DataOption, 'name'> {
  type: DataOptionType;
  subtype?: SkillCategory;
  iconId?: string;
}
