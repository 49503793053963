import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { lowerCase, map } from 'lodash';

import { StreetSession as StreetSessionModel } from '@/domains';
import { translations } from '@/locale';
import { getTime, getDateFormat } from '@/util';
import { urls } from '@/constants';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';
import DataWithIcon from '@/components/DataWithIcon';

import cellStyle from '../cellStyle.sass';
import style from './StreetSessionRow.sass';

export interface Props {
  streetSession: StreetSessionModel;
  className?: string;
  language: string;
}

const StreetSessionRow: React.FC<Props> = ({
  streetSession: { id, location, sessionType, start, end, registeredParticipants, unregisteredParticipants, labels },
  language,
  className,
  ...rest
}) => (
  <Card.Row is="li" className={classnames(style.item)} {...rest}>
    <Link to={urls.sessions.get(id.toString())} className={classnames(cellStyle.container, style.wide, className)}>
      <div className={classnames(cellStyle.location, style.location)}>
        <Picture size="md" url={location.pictureUrl} type="location" className={style.picture} />
        <DataWithIcon iconType="locationPin" title={location.name} subtitle={location.address} />
      </div>

      <div className={classnames(cellStyle.sessionType, style.sessionType)}>
        <BoxedContent appearance="blue">
          <Typography is="div" type="hummingbird" weight="bold" color="blue">
            {sessionType.translations && sessionType.translations[lowerCase(language)]
              ? sessionType.translations[lowerCase(language)]
              : sessionType.name}
          </Typography>
        </BoxedContent>
      </div>

      <DataWithIcon
        className={cellStyle.timeDate}
        iconType="clock"
        title={`${getTime(start)} - ${getTime(end)}`}
        subtitle={getDateFormat(start, 'MMM d, yyyy')}
      />

      <div className={cellStyle.labels}>
        {labels &&
          labels.map((labelItem, index) => (
            <Typography key={index} is="span" type="hummingbird" color="orange" className={style.sessionLabel}>
              {labelItem.name}
            </Typography>
          ))}
      </div>

      <div className={cellStyle.participants}>
        {registeredParticipants || unregisteredParticipants ? (
          <DataWithIcon iconType="participants" title={`${registeredParticipants + unregisteredParticipants}`} />
        ) : (
          <Typography is="div" type="hummingbird" color="gray">
            <FormattedMessage id={translations.pages.teams.sessions.row.noParticipants} />
          </Typography>
        )}
      </div>
    </Link>
  </Card.Row>
);

export default StreetSessionRow;
