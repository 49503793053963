import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { formatAmount } from '@/util';
import { translations } from '@/locale';
import { getNextPayment } from '@/services/api/billing-info';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import { SessionConsumer } from '@/components/Context/Session';
import BillingHistoryTable from '@/components/BillingHistoryTable';

import style from './BillingHistory.sass';

const BillingHistory: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <Card.Row className={style.root}>
      <SessionConsumer>
        {({ organization }) => (
          <Card.Column sm={12}>
            <Spacer xs={1} />

            <LoadData id={organization.id} load={getNextPayment}>
              {({ loading, value }) => (
                <If
                  condition={loading || (!loading && value.amount.amount === 0)}
                  then={() => <div className={style.skeleton} />}
                  else={() => (
                    <Typography is="span" type="halcyon">
                      <FormattedMessage
                        id={translations.pages.organization.licenses.tabs.billingHistory.nextPayment}
                        values={{
                          date: intl.formatDate(value.date, { day: '2-digit', month: 'short', year: 'numeric' }),
                          amount: formatAmount(value.amount),
                          numberOfUsers: value.users,
                          span: (children) => (
                            <Typography is="span" type="halcyon" weight="bold">
                              {children}
                            </Typography>
                          )
                        }}
                      />
                    </Typography>
                  )}
                />
              )}
            </LoadData>

            <Spacer xs={2} />

            <BillingHistoryTable id="licenses-billing-history" organizationId={organization.id} />
          </Card.Column>
        )}
      </SessionConsumer>
    </Card.Row>
  );
};

export default injectIntl(BillingHistory);
