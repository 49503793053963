import { translations } from '@/locale';
import { SocialMapContact } from '@/domains';

const getContact = (tooltipItem, data): SocialMapContact =>
  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].contact || null;

const boundary = 6;

export const getOptions = (intl, language) => ({
  legend: false,
  scales: {
    xAxes: [
      {
        ticks: {
          min: -boundary,
          max: boundary,
          stepSize: 1,
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: `${intl.formatMessage({ id: translations.moodType.NEGATIVE })}`
        }
      },
      {
        ticks: {
          min: -boundary,
          max: boundary,
          stepSize: 1,
          display: false
        },
        position: 'top',
        scaleLabel: {
          display: true,
          labelString: `${intl.formatMessage({ id: translations.moodType.POSITIVE })}`
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          min: -boundary,
          max: boundary,
          stepSize: 1,
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: `${intl.formatMessage({ id: translations.moodState.INACTIVE })}`
        }
      },
      {
        ticks: {
          min: -boundary,
          max: boundary,
          stepSize: 1,
          display: false
        },
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: `${intl.formatMessage({ id: translations.moodState.ACTIVE })}`
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const contact = getContact(tooltipItem, data);

        if (!contact)
          return intl.formatMessage({ id: translations.pages.teams.participants.details.reports.socialMap.this });

        return `${contact.contactDto.fullName}, ${
          contact.contactDto.type.translations && contact.contactDto.type.translations[language.toLowerCase()]
            ? contact.contactDto.type.translations[language.toLowerCase()]
            : contact.contactDto.type.name
        }`;
      },
      afterLabel: (tooltipItem, data) => {
        const contact = getContact(tooltipItem, data);

        if (!contact) return;

        if (!contact.evaluation)
          return intl.formatMessage({ id: translations.pages.teams.participants.details.reports.socialMap.noData });

        return `${intl.formatMessage(
          { id: translations.pages.teams.participants.details.reports.socialMap.frequency },
          {
            frequency: intl.formatMessage({
              id: translations.frequency[contact.evaluation.frequency]
            })
          }
        )}\n${intl.formatMessage(
          { id: translations.pages.teams.participants.details.reports.socialMap.mood },
          {
            mood: intl.formatMessage({
              id: translations.moods[contact.evaluation.interactionMood]
            })
          }
        )}\n${intl.formatMessage(
          { id: translations.pages.teams.participants.details.reports.socialMap.indicators },
          {
            indicators: contact.evaluation.socialIndicatorEvaluations
              .map(
                (indicator) =>
                  `\n\t${indicator.socialIndicator.name}: ${intl.formatMessage({
                    id: translations.evaluationStatuses[indicator.evaluationStatus]
                  })}`
              )
              .join(',')
          }
        )}`;
      }
    }
  }
});
