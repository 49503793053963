import React from 'react';
import classnames from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { urls, rules } from '@/constants';
import { translations } from '@/locale';
import { MemberBase } from '@/domains';
import { getTeamMembers } from '@/services/api/team';

import If from '@/components/If';
import Card from '@/components/Card';
import Link from '@/components/Link';
import Modal from '@/components/Modal';
import Member from '@/components/Member';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import HasRole from '@/components/HasRole';
import Loading from '@/components/Loading';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import FilterManager from '@/components/FilterManager';
import EntityPreview from '@/components/EntityPreview';
import { AppConsumer } from '@/components/Context/App';
import InfiniteScroll from '@/components/InfiniteScroll';
import AddOrganizationMembersToTeamModal from '@/components/SearchableModals/AddOrganizationMembersToTeamModal';

import Details from './Details';

import style from './Teammates.sass';

const Teammates: React.FC<WrappedComponentProps> = ({ intl }) => {
  const [key, setKey] = React.useState(new Date().getTime());

  const updateKey = React.useCallback(() => setKey(new Date().getTime()), []);

  return (
    <AppConsumer>
      {({ team, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center overlay>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <div className={style.root}>
              <Spacer xs={2} />

              {!!team && <EntityPreview logo={team.imageUrl} name={team.name} />}

              <Spacer xs={4} />

              <Card className={style.card}>
                <If
                  condition={!team}
                  then={() => (
                    <Placeholder image="people" size="lg" className={style.noTeamPlaceholder}>
                      <Typography is="span" type="swan" weight="bold">
                        <FormattedMessage id={translations.pages.teams.teammates.noTeamSelected} />
                      </Typography>
                    </Placeholder>
                  )}
                  else={() => (
                    <React.Fragment>
                      <div className={style.leftColumn}>
                        <div className={style.members}>
                          <TextWithIcon is="h6" type="hawk" boxed iconColor="blue" weight="bold" icon="myTeam">
                            <FormattedMessage id={translations.pages.teams.teammates.title} />
                          </TextWithIcon>

                          <HasRole to={rules.TEAM_ADD_MEMBERS}>
                            <Modal modal={AddOrganizationMembersToTeamModal} teamId={team.id} onClose={updateKey}>
                              {({ open }) => (
                                <Button appearance="orange" type="button" onClick={open} className={style.button}>
                                  <FormattedMessage id={translations.buttons.add} />
                                </Button>
                              )}
                            </Modal>
                          </HasRole>
                        </div>

                        <FilterManager initialCriteria={{}}>
                          {({ criteria, debouncedUpdate }) => (
                            <React.Fragment>
                              <div className={style.searchBar}>
                                <TextInput
                                  type="text"
                                  id="search"
                                  onChange={(event) => debouncedUpdate('search', event.target.value)}
                                  label={intl.formatMessage({ id: translations.inputs.membersSearch.label })}
                                  placeholder={intl.formatMessage({
                                    id: translations.inputs.membersSearch.placeholder
                                  })}
                                  autoComplete="off"
                                  leadingIcon="search"
                                />
                              </div>

                              <Card.Row className={style.membersList}>
                                <InfiniteScroll
                                  key={JSON.stringify({ key, team: team.id })}
                                  id={criteria.search}
                                  size={15}
                                  source={(page, number) => getTeamMembers(page, number, criteria.search, team.id)}
                                  className={style.list}
                                >
                                  {({ data, loading }) => (
                                    <ul className={style.inlineBlock}>
                                      <If
                                        condition={!loading && data.length === 0}
                                        then={() => (
                                          <Placeholder image="people" size="xs" className={style.placeholder}>
                                            <Typography is="h6" type="halcyon" weight="bold">
                                              <FormattedMessage
                                                id={translations.pages.organization.members.noResults}
                                              />
                                            </Typography>
                                          </Placeholder>
                                        )}
                                        else={() =>
                                          data.map((member: MemberBase) => (
                                            <Card.Row
                                              is="li"
                                              key={member.id}
                                              className={classnames(style.inlineBlock, style.item)}
                                            >
                                              <Member
                                                is={Link}
                                                to={urls.teammates.get(member.id)}
                                                member={member}
                                                hoverable
                                                block
                                              />
                                            </Card.Row>
                                          ))
                                        }
                                      />

                                      <Loading visible={loading} center className={style.loadingIndicator}>
                                        <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                                      </Loading>
                                    </ul>
                                  )}
                                </InfiniteScroll>
                              </Card.Row>
                            </React.Fragment>
                          )}
                        </FilterManager>
                      </div>

                      <div className={style.rightColumn}>
                        <Switch>
                          <Route
                            exact
                            path={urls.teammates.list}
                            render={() => (
                              <Placeholder image="questions" size="sm" className={style.placeholder}>
                                <Typography is="h6" type="hawk" weight="bold">
                                  <FormattedMessage id={translations.pages.teams.teammates.noneSelected} />
                                </Typography>
                              </Placeholder>
                            )}
                          />

                          <Route
                            exact
                            path={urls.teammates.detail}
                            render={() => <Details teamId={team.id} onMemberDetailsChanged={updateKey} />}
                          />
                        </Switch>
                      </div>
                    </React.Fragment>
                  )}
                />
              </Card>
            </div>
          )}
        />
      )}
    </AppConsumer>
  );
};

export default injectIntl(Teammates);
