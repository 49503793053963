import { ReportFilters, Filter } from '@/domains';
import { getIds } from './getIds';

export const criteriaWithActiveToggleOn = (
  criteria: ReportFilters,
  showOnlyActive: boolean,
  data: any
): ReportFilters => {
  if (showOnlyActive === false) return criteria;

  return {
    ...criteria,
    sessionTypeIds:
      criteria.sessionTypeIds.includes(Filter.All) || criteria.sessionTypeIds.length === 0
        ? getIds(data.sessionTypes)
        : criteria.sessionTypeIds,
    teamIds:
      criteria.teamIds.includes(Filter.All) || criteria.teamIds.length === 0 ? getIds(data.teams) : criteria.teamIds,
    locationIds:
      criteria.locationIds.includes(Filter.All) || criteria.locationIds.length === 0
        ? getIds(data.locations)
        : criteria.locationIds
  };
};
