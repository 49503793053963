import React from 'react';
import * as yup from 'yup';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls } from '@/constants';
import { NewStreetSession } from '@/domains';
import { translations } from '@/locale';
import { getISOString, getNow, getDateFormat } from '@/util';
import { createStreetSession } from '@/services/api/street-session';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import { dateFormat } from '@/components/DateInput';
import { AppConsumer } from '@/components/Context/App';
import DatePickerInput from '@/components/DatePickerInput';
import { SessionFrequency } from '@/domains/session-recurrence';
import { withValidation } from '@/components/hoc/withValidation';
import RecurrenceSelect from '@/components/Selects/RecurrenceSelect';

import StreetSessionDetailFields from '@/page/Team/StreetSessions/StreetSessionDetailFields';

import style from './NewStreetSessionModal.sass';

export const schema = yup.object({
  locationId: yup
    .string()
    .label(translations.inputs.location.label)
    .required(),
  dataOptionId: yup
    .string()
    .label(translations.inputs.sessionType.label)
    .required(),
  startDate: yup.string().required(),
  startTime: yup
    .string()
    .label(translations.inputs.startTime.label)
    .required(),
  endTime: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startTime: string = this.resolve(yup.ref('startTime'));

      if (!startTime) return true;

      return startTime.localeCompare(value) === -1;
    })
    .label(translations.inputs.endTime.label)
    .required(),
  attachment: yup
    .string()
    .nullable()
    .notRequired(),
  sessionRecurrence: yup
    .string()
    .label(translations.inputs.sessionRecurrence.label)
    .required(),
  recurrenceEndDate: yup.string().when('sessionRecurrence', {
    is: (value) => value === SessionFrequency.None,
    then: yup
      .string()
      .nullable()
      .notRequired(),
    otherwise: yup
      .string()
      .test('validValue', translations.validation.custom.sessionRecurrence, function(value) {
        const startDate: string = this.resolve(yup.ref('startDate'));

        if (!startDate) return true;

        const maxDate = new Date(startDate);
        maxDate.setFullYear(new Date(startDate).getFullYear() + 1);

        return (
          getDateFormat(maxDate.toISOString(), 'yyyy-MM-dd').localeCompare(value) === 1 &&
          startDate.localeCompare(value) === -1
        );
      })
      .required()
  })
});

const initialValues = {
  startDate: getNow(dateFormat),
  recurrenceEndDate: getNow(dateFormat),
  sessionRecurrence: SessionFrequency.None
};

const RecurrenceSelectWithValidation = withValidation(RecurrenceSelect);
interface Props extends InstanceProps {}

class NewStreetSessionModal extends React.PureComponent<Props & WrappedComponentProps & RouteComponentProps> {
  saveDetails = (values) => {
    const { startDate, startTime, endTime, sessionRecurrence, recurrenceEndDate, ...restValues } = values;
    const start = getISOString(`${startDate} ${startTime}`, `${dateFormat} HH:mm`);
    const end = getISOString(`${startDate} ${endTime}`, `${dateFormat} HH:mm`);
    const recurrenceEnd = getISOString(`${recurrenceEndDate} ${endTime}`, `${dateFormat} HH:mm`);

    const newStreetSession: NewStreetSession = {
      start,
      end,
      ...restValues
    };

    if (sessionRecurrence !== SessionFrequency.None)
      newStreetSession.recurrenceRule = {
        frequency: sessionRecurrence,
        until: recurrenceEnd
      };

    return createStreetSession(newStreetSession).then(({ id }) => {
      this.props.close();
      this.props.history.push(urls.sessions.get(id));
    });
  };

  render() {
    return (
      <Card className={style.root}>
        <Button className={style.closeButton} onClick={this.props.close}>
          <BoxedIcon type="close" appearance="red" />
        </Button>
        <div className={style.padded}>
          <AppConsumer>
            {({ team }) => (
              <Form
                id="create-session-details"
                initialValues={initialValues}
                subscription={{ errors: true, submitting: true, submitError: true, valid: true, values: true }}
                schema={schema}
                onSubmit={(values) => this.saveDetails({ ...values, teamId: team.id })}
                className={style.form}
              >
                {({ form, submitError, submitting, valid, values }, id) => (
                  <React.Fragment>
                    <div>
                      <Typography is="span" type="swan" weight="bold">
                        <FormattedMessage id={translations.modals.createSession.title} />
                      </Typography>
                      <Spacer xs={5} />

                      <Typography is="span" type="halcyon" color="gray">
                        <FormattedMessage id={translations.modals.createSession.details.title} />
                      </Typography>

                      <StreetSessionDetailFields formId={id} readonly={submitting} onFormChange={form.change} />

                      <Spacer xs={1} />

                      <div className={style.recurrence}>
                        <div className={style.until}>
                          <Spacer xs={3} />

                          <Form.Field
                            is={RecurrenceSelectWithValidation}
                            id={`${id}-session-recurrence`}
                            name="sessionRecurrence"
                            type="text"
                            label={this.props.intl.formatMessage({ id: translations.inputs.sessionRecurrence.label })}
                            placeholder={this.props.intl.formatMessage({
                              id: translations.inputs.sessionRecurrence.placeholder
                            })}
                            readOnly={submitting}
                          />
                        </div>

                        {values.sessionRecurrence !== SessionFrequency.None && (
                          <div className={style.until}>
                            <Typography is="span" type="halcyon">
                              <FormattedMessage
                                id={translations.inputs.sessionRecurrenceEndDate.label}
                                values={{
                                  frequency: this.props.intl
                                    .formatMessage({
                                      id: translations.sessionRecurrenceFrequency[values.sessionRecurrence]
                                    })
                                    .toLowerCase()
                                }}
                              />
                            </Typography>

                            <Form.Field
                              is={DatePickerInput}
                              change={form.change}
                              id={`${id}-recurrence-end-date`}
                              name="recurrenceEndDate"
                              type="text"
                              readOnly={submitting}
                            />
                          </div>
                        )}
                      </div>

                      {!!submitError && (
                        <React.Fragment>
                          <SubmitError error={submitError} />

                          <Spacer xs={2} />
                        </React.Fragment>
                      )}
                    </div>

                    <Button
                      form={id}
                      type="submit"
                      appearance="orange"
                      className={style.nextButton}
                      disabled={!valid}
                      loading={submitting}
                    >
                      <FormattedMessage id={translations.navigation.next} />
                    </Button>
                  </React.Fragment>
                )}
              </Form>
            )}
          </AppConsumer>
        </div>
      </Card>
    );
  }
}
export default injectIntl(withRouter(NewStreetSessionModal));
