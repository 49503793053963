import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { KPIItem, KPIDomain } from '@/domains';

import { Props as IconProps } from '@/components/Icon';
import TextWithIcon, { Props as TextWithIconProps } from '@/components/TextWithIcon';
import Typography from '@/components/Typography';

import style from './Item.sass';

enum Deviation {
  POSITIVE = 1,
  NEUTRAL = 0,
  NEGATIVE = -1
}

interface Icon {
  icon: IconProps['type'];
  color: TextWithIconProps<any>['iconColor'];
}

const mapDeviationToIcon: Record<Deviation, Icon> = {
  [Deviation.POSITIVE]: {
    icon: 'arrowPositive',
    color: 'green'
  },
  [Deviation.NEUTRAL]: {
    icon: 'arrowNeutral',
    color: 'gray'
  },
  [Deviation.NEGATIVE]: {
    icon: 'arrowNegative',
    color: 'red'
  }
};

interface Props {
  domain: KPIDomain;
  current: number;
  deviation: number;
}

const Item: React.FC<Props> = ({ domain, current, deviation }) => {
  const { icon, color } = mapDeviationToIcon[Math.sign(deviation)];

  return (
    <div className={style.root}>
      <Typography is="div" type="sparrow" color="blue" weight="lighter">
        <FormattedMessage id={translations.kpiDomains[domain]} />
      </Typography>

      <Typography is="div" type="flamingo" weight="bold" className={style.current}>
        {current}
      </Typography>

      <TextWithIcon
        is="div"
        type="sparrow"
        icon={icon}
        iconColor={color}
        boxed
        color={color}
        weight="bold"
        iconSize="small"
      >
        {Math.sign(deviation) === 1 && '+'}
        {deviation}
      </TextWithIcon>
    </div>
  );
};

export default Item;
