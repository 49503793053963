import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

interface Props {
  showFieldType?: boolean;
}

const ProfileFieldsHeader: React.FC<Props> = ({ showFieldType }) => {
  const { name, fieldType, enabled } = translations.dataCustomisation.profileFields.profileFieldsHeader;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={showFieldType ? cellStyle.customPropertyName : cellStyle.profileFieldName}>
        <FormattedMessage id={name} />
      </div>
      {showFieldType && (
        <div className={cellStyle.customPropertyFieldType}>
          <FormattedMessage id={fieldType} />
        </div>
      )}
      <div className={cellStyle.enabled}>
        <FormattedMessage id={enabled} />
      </div>
    </Card.Row>
  );
};

export default ProfileFieldsHeader;
