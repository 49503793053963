import { LocationReport, Page, Note, Sort, By, Filter } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

import { Order } from '@/components/Ordering';

export const getSessionNotes = (locationId: string, pageNumber: number, pageSize: number): Promise<Page<Note>> =>
  authorizedApi.get<Page<Note>>(`/v1/notes`, {
    locationId,
    pageNumber,
    pageSize,
    onlySessionNotes: true,
    [Sort.By]: By.CreatedAt,
    [Sort.Order]: Order.Descendent
  });

export const getLifeEventNotes = (locationId: string, pageNumber: number, pageSize: number): Promise<Page<Note>> =>
  authorizedApi.get<Page<Note>>(`/v1/notes`, {
    locationId,
    pageNumber,
    pageSize,
    lifeEvent: true,
    [Sort.By]: By.CreatedAt,
    [Sort.Order]: Order.Descendent
  });

export const getLocationReports = <T>(
  locationId: string,
  type: LocationReport.ReportType,
  { labelIds, ...rest }: { [x: string]: string }
): Promise<T> =>
  authorizedApi.get<T>(`/v1/locations/${locationId}/reports/${type}`, {
    ...rest,
    labelIds: labelIds.includes(Filter.All) ? null : labelIds
  });

export const getRecommendedParticipants = (
  locationId: string,
  pageNumber: number,
  pageSize: number
): Promise<Page<LocationReport.RecommendedParticipant>> =>
  authorizedApi.get<Page<LocationReport.RecommendedParticipant>>(
    `/v1/locations/${locationId}/reports/participants/recommended`,
    {
      pageNumber,
      pageSize
    }
  );
