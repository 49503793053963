import React from 'react';

import { hasRole } from '@/permission';

import { SessionConsumer } from '@/components/Context/Session';

export interface Props {
  to: string;
  fail?: () => any;
  children: any;
}

const HasRole: React.FC<Props> = ({ to, fail = () => null, children }: Props) => {
  return (
    <SessionConsumer>
      {({ me }) => {
        return me && hasRole(me.role, to) ? children : fail();
      }}
    </SessionConsumer>
  );
};

export default HasRole;
