import { ReportFilters, OrganizationReport, Filter } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getOrganizationReports = <T>(
  { locationIds, teamIds, labelIds, sessionMemberIds, ageGroupIds, sessionTypeIds, ...rest }: ReportFilters,
  type: OrganizationReport.ReportType
): Promise<T> =>
  authorizedApi.get<T>(`/v1/organizations/reports/${type}`, {
    ...rest,
    locationIds: locationIds.includes(Filter.All) ? null : locationIds,
    teamIds: teamIds.includes(Filter.All) ? null : teamIds,
    sessionMemberIds: sessionMemberIds.includes(Filter.All) ? null : sessionMemberIds,
    ageGroupIds: ageGroupIds.includes(Filter.All) ? null : ageGroupIds,
    sessionTypeIds: sessionTypeIds.includes(Filter.All) ? null : sessionTypeIds,
    labelIds: labelIds.includes(Filter.All) ? null : labelIds
  });

export const getDaytimeOccupationReports = <T>(
  { start, end, ...rest }: ReportFilters,
  type: OrganizationReport.ReportType
): Promise<T> => {
  const promises: Promise<OrganizationReport.DaytimeOccupation>[] = [];

  promises.push(
    getOrganizationReports<OrganizationReport.DaytimeOccupation>(
      { end: start, start: start, ...rest },
      OrganizationReport.ReportType.DaytimeOccupation
    )
  );
  promises.push(
    getOrganizationReports<OrganizationReport.DaytimeOccupation>(
      { end: end, start: end, ...rest },
      OrganizationReport.ReportType.DaytimeOccupation
    )
  );

  return Promise.all(promises).then((results) => ({ atStart: results[0], atEnd: results[1] })) as any;
};
