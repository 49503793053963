import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import StatelessTable from '@/components/StatelessTable';

const BillingHistoryHeader: React.FC = () => (
  <StatelessTable.Header>
    <StatelessTable.Data sm={3}>
      <FormattedMessage id={translations.billingHistoryTable.header.invoiceNumber} />
    </StatelessTable.Data>

    <StatelessTable.Data sm={2}>
      <FormattedMessage id={translations.billingHistoryTable.header.numberOfUsers} />
    </StatelessTable.Data>

    <StatelessTable.Data sm={2}>
      <FormattedMessage id={translations.billingHistoryTable.header.amount} />
    </StatelessTable.Data>

    <StatelessTable.Data sm={2}>
      <FormattedMessage id={translations.billingHistoryTable.header.dueDate} />
    </StatelessTable.Data>

    <StatelessTable.Data sm={3}>
      <FormattedMessage id={translations.billingHistoryTable.header.paymentStatus} />
    </StatelessTable.Data>
  </StatelessTable.Header>
);
export default BillingHistoryHeader;
