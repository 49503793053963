import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import If from '@/components/If';
import Link from '@/components/Link';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import BoxedContent from '@/components/BoxedContent';
import { surveyStatusColor } from '@/constants/survey-status';

import cellStyle from '../cellStyle.sass';

import style from './SurveyRow.sass';

export interface Props {
  data: any;
}

const getDateRow = (intl, dateValue: string) => {
  return `${intl.formatTime(dateValue)},  ${intl.formatDate(dateValue, {
    month: 'short',
    day: '2-digit'
  })}`;
};

const SurveyRow: React.FC<Props & WrappedComponentProps> = ({ data, intl, ...rest }) => (
  <Card.Row
    is={Link}
    // @ts-ignore
    to={urls.digitalCoach.survey.get(data.id)}
    className={classnames(cellStyle.container, style.root)}
    {...rest}
  >
    <Typography is="span" type="sparrow" className={cellStyle.title}>
      {data.title && data.title}
    </Typography>

    <div className={classnames(cellStyle.type, style.type)}>
      <BoxedContent appearance="blue">
        <Typography is="span" type="sparrow">
          {data.type && <FormattedMessage id={translations.surveyType[data.type]} />}
        </Typography>
      </BoxedContent>
    </div>

    <If
      condition={data.participants !== 0}
      then={() => (
        <TextWithIcon icon="participants" iconColor="blue" is="span" type="sparrow" className={cellStyle.participants}>
          {data.participants}
        </TextWithIcon>
      )}
      else={() => (
        <Typography is="span" type="hummingbird" color="gray" className={cellStyle.participants}>
          <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.table.item.noParticipants} />
        </Typography>
      )}
    />

    <Typography is="span" type="sparrow" className={cellStyle.timeAndDate}>
      <FormattedMessage
        id={translations.pages.digitalCoach.tabs.surveys.table.item.timeDate}
        values={{ start: `${getDateRow(intl, data.start)}`, br: <br />, end: getDateRow(intl, data.end) }}
      />
    </Typography>

    <div className={classnames(cellStyle.status, style.status)}>
      <BoxedContent appearance={surveyStatusColor[data.status]}>
        <Typography is="span" type="sparrow">
          {data.status && <FormattedMessage id={translations.surveyStatus[data.status]} />}
        </Typography>
      </BoxedContent>
    </div>
  </Card.Row>
);

export default injectIntl(SurveyRow);
