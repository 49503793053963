import React from 'react';
import { Bar, ChartComponentProps } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';

import { getData } from '@/util';
import { translations } from '@/locale';

import DownloadableChart, { Props as DownloadableChartProps } from '@/components/DownloadableChart';

interface Props extends Pick<DownloadableChartProps, 'title'> {
  name: string;
  className?: string;
}

const defaultOptions = {
  scales: {
    xAxes: [
      {
        stacked: true
      }
    ],
    yAxes: [
      {
        stacked: true
      }
    ]
  }
};

export const StackedBarChart: React.FC<Props & ChartComponentProps> = ({
  name,
  data,
  title,
  options,
  className,
  ...rest
}) => (
  <DownloadableChart
    title={title}
    chart={Bar}
    data={data}
    options={{ ...defaultOptions, ...options }}
    className={className}
    {...rest}
  >
    {({ getImage }) => (
      <React.Fragment>
        <DownloadableChart.DownloadButton onClick={() => getImage(`${name}-stacked-bar-chart`)}>
          <FormattedMessage id={translations.inputs.chart.jpg} />
        </DownloadableChart.DownloadButton>

        <DownloadableChart.DownloadButton onClick={() => getData(data, `${name}-data`)}>
          <FormattedMessage id={translations.inputs.chart.data} />
        </DownloadableChart.DownloadButton>
      </React.Fragment>
    )}
  </DownloadableChart>
);
