export const MAIN_DASHBOARD_VIEW = 'main:dashboard:view';
export const MAIN_REPORTING_VIEW = 'main:reporting:view';

export const ORGANIZATION_MENU = 'organization:menu';
export const ORGANIZATION_INFORMATION_VIEW = 'organization:information:view';
export const ORGANIZATION_MEMBERS_VIEW = 'organization:members:view';
export const ORGANIZATION_DATA_CUSTOMISATION_VIEW = 'organization:data-customisation:view';
export const ORGANIZATION_LICENSES_VIEW = 'organization:licenses:view';
export const ORGANIZATION_DOWNLOAD_INTERVENTIONS = 'organization:interventions:download';

export const TEAMS_MENU = 'teams:menu';
export const TEAMS_TEAM_INFORMATION_VIEW = 'teams:team-information:view';
export const TEAMS_TEAMMATES_VIEW = 'teams:teammates:view';
export const TEAMS_PARTICIPANTS_VIEW = 'teams:participants:view';
export const TEAMS_DIGITAL_COACH_VIEW = 'teams:digital-coach:view';
export const TEAMS_SESSIONS_VIEW = 'teams:sessions:view';
export const TEAMS_DOWNLOAD_NOTES = 'teams:notes:download';

export const TEAM_CREATION = 'team:creation';
export const TEAM_UPDATE = 'team:update';
export const TEAM_ARCHIVE = 'team:archive';
export const TEAM_DELETE = 'team:delete';
export const TEAM_ADD_MEMBERS = 'team:add-members';
export const TEAM_DATA_CUSTOMISATION = 'team:data-customisation';
export const TEAM_REMOVE_MEMBERS = 'team:remove-members';
export const TEAM_EXPORT_MEMBERS = 'team:export-members';
export const TEAM_CHANGE_EMAIL = 'team:change-email';

export const LOCATION_CREATE = 'location:create';
export const LOCATION_UPDATE = 'location:update';
export const LOCATION_ARCHIVE = 'location:archive';
export const LOCATION_REPORTING = 'location:reporting';
export const LOCATION_SHARE = 'location:share';

export const PARTICIPANT_DELETE = 'participant:delete';
export const PARTICIPANT_SHARE = 'participant:share';
export const PARTICIPANT_ACTIVATION = 'participant:activation';
export const PARTICIPANT_MERGE = 'participant:merge';
export const PARTICIPANT_REPORTING = 'participant:reporting';
export const PARTICIPANT_EXPORT = 'participant:export';

export const SESSION_REPORTING = 'session:reporting';
export const SESSION_EXPORT = 'session:export';
export const SESSION_PARTICIPANTS_EXPORT = 'session:participants:export';
export const SESSION_LABELS = 'session:labels';

export const APPLICATION_REPORTING = 'application:reporting';
export const APPLICATION_ORGANIZATIONS = 'application:organizations';
export const APPLICATION_DATA_CUSTOMIZATION = 'application:data-customizations';

export const HEADER_TEAMS_DROPDOWN = 'header:teams-dropdown';
export const HEADER_NOTIFICATIONS_DROPDOWN = 'header:notifications-dropdown';
