import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const ParticipantHeader: React.FC<{}> = () => {
  const { location, sessionType, timeDate, teamMembers, participants } = translations.pages.teams.sessions.header;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={cellStyle.participant}>Participant</div>
      <div className={cellStyle.evaluated}>Evaluated</div>
    </Card.Row>
  );
};

export default ParticipantHeader;
