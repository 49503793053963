import React from 'react';

import Typography from '@/components/Typography';
import Picture from '@/components/Picture';

import style from './SelectableItem.sass';

export interface Props {
  photoUrl: string;
  title: string;
  subtitle: string;
}

const SelectableItem: React.FC<Props> = ({ photoUrl, title, subtitle, ...rest }) => (
  <div className={style.root}>
    <Picture size="sm" url={photoUrl} className={style.picture} />

    <div className={style.textValues}>
      <Typography is="span" type="sparrow" weight="bold" color="blue">
        {title}
      </Typography>

      <Typography is="span" type="sparrow" weight="lighter" color="gray">
        {subtitle}
      </Typography>
    </div>
  </div>
);

export default SelectableItem;
