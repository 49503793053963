import React from 'react';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { format } from 'date-fns';
import { translations } from '@/locale';

import Dropdown from '@/components/Dropdown';

import DateRangeFilterDropdown from './DateRangeFilterDropdown';

import style from './DateRangeFilter.sass';

export interface DateRange {
  start: string;
  end: string;
}

export interface Props {
  value: DateRange;
  label?: string;
  placeholder?: string;
  className?: string;
  onChange: (value: DateRange) => void;
}

const DateRangeFilter: React.FC<Props & WrappedComponentProps> = ({
  intl,
  value,
  label,
  placeholder,
  className,
  onChange
}) => {
  const { start, end } = value;

  return (
    <Dropdown
      className={className}
      dropdownClassName={style.dropdown}
      renderMenu={({ close }) => <DateRangeFilterDropdown range={value} onChange={onChange} close={close} />}
    >
      {({ isOpen, open }) => (
        <div
          className={classnames(style.input, { [style.open]: isOpen, [style.placeholder]: !start && !end })}
          onClick={open}
        >
          {start || end
            ? `${
                start
                  ? format(new Date(start), 'dd/MM/yyyy')
                  : intl.formatMessage({ id: translations.inputs.ageRange.notDefined })
              } - ${
                end
                  ? format(new Date(end), 'dd/MM/yyyy')
                  : intl.formatMessage({ id: translations.inputs.ageRange.notDefined })
              }`
            : placeholder}
        </div>
      )}
    </Dropdown>
  );
};

export default injectIntl(DateRangeFilter);
