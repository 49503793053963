import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const { evaluationModule, enabled } = translations.dataCustomisation.general.header;

const EvaluationModuleHeader: React.FC = () => {
  return (
    <Card.Row className={cellStyle.header}>
      <Typography is="div" type="halcyon" color="gray" className={cellStyle.name}>
        <FormattedMessage id={evaluationModule} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.enabled}>
        <FormattedMessage id={enabled} />
      </Typography>
    </Card.Row>
  );
};

export default EvaluationModuleHeader;
