import React from 'react';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CreateReminder } from '@/domains';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { createReminder } from '@/services/api/reminder';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import TopicSelect from '@/components/Selects/TopicSelect';
import { withValidation } from '@/components/hoc/withValidation';
import { NotificationsConsumer } from '@/components/Context/Notifications';

import style from './CreateReminderModal.sass';

const schema: yup.ObjectSchema<CreateReminder> = yup.object({
  title: yup
    .string()
    .label(translations.inputs.title.label)
    .max(STRING_SHORT_MAX_LENGTH)
    .required(),
  topic: yup
    .mixed()
    .label(translations.inputs.topic.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);
const TopicSelectWithValidation = withValidation(TopicSelect);

interface Props {
  onReminderCreated: () => any;
}

const CreateReminderModal: React.FC<Props & InstanceProps & WrappedComponentProps> = ({
  intl,
  close,
  onReminderCreated
}) => (
  <NotificationsConsumer>
    {({ increment }) => (
      <Card className={style.root}>
        <Card.Row>
          <Card.Column sm={12}>
            <div className={style.titleAndClose}>
              <Typography is="h6" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.createReminder.title} />
              </Typography>

              <Button onClick={close}>
                <BoxedIcon type="close" appearance="red" />
              </Button>
            </div>

            <Typography is="p" type="halcyon" color="gray">
              <FormattedMessage id={translations.modals.createReminder.subtitle} />
            </Typography>
          </Card.Column>
        </Card.Row>

        <Form
          id="create-reminder"
          schema={schema}
          subscription={{
            dirty: true,
            pristine: true,
            submitError: true,
            submitting: true
          }}
          onSubmit={(values) => createReminder(values).then(() => increment() || onReminderCreated() || close())}
        >
          {({ dirty, pristine, submitError, submitting }, id) => (
            <React.Fragment>
              <Card.Row>
                <Card.Column sm={12}>
                  <Typography is="p" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.createReminder.details} />
                  </Typography>

                  <Spacer xs={2} />

                  <div className={style.inputs}>
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${id}-title`}
                      name="title"
                      label={intl.formatMessage({ id: translations.inputs.title.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.title.placeholder })}
                      readOnly={submitting}
                    />

                    <Form.Field
                      is={TopicSelectWithValidation}
                      type="text"
                      id={`${id}-topic`}
                      name="topic"
                      label={intl.formatMessage({ id: translations.inputs.topic.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.topic.placeholder })}
                      readOnly={submitting}
                    />
                  </div>
                </Card.Column>
              </Card.Row>

              <Card.Row>
                <Card.Column sm={12}>
                  {!!submitError && <SubmitError error={submitError} />}

                  <Button
                    form={id}
                    type="submit"
                    appearance="orange"
                    loading={submitting}
                    disabled={!dirty || pristine}
                    className={style.createButton}
                  >
                    <FormattedMessage id={translations.modals.createReminder.create} />
                  </Button>
                </Card.Column>
              </Card.Row>
            </React.Fragment>
          )}
        </Form>
      </Card>
    )}
  </NotificationsConsumer>
);

export default injectIntl(CreateReminderModal);
