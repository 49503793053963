import { Amount } from '@/domains';

const currencySign: Record<string, string> = {
  eur: '€'
};

export const getCurrencySign = (currency: string): string => currencySign[currency] || '€';

export const formatAmount = (
  amount: Amount,
  formatter = (currency: string, value: number): string => `${value !== 0 ? getCurrencySign(currency) : ''}${value}`
): string => formatter(amount.currency, amount.amount);
