import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { GenderFilter } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<string>, 'items'>, WrappedComponentProps {}

class GenderFilterSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(GenderFilter).map((gender) => ({
      value: gender,
      name: intl.formatMessage({ id: translations.genders[gender] })
    }));

    return (
      <SimpleSelect
        {...rest}
        items={items}
        getSelectedDisplayName={({ selectedItems }) =>
          selectedItems.map((item) => item.name).join(', ') ||
          intl.formatMessage({ id: translations.miscellaneous.all })
        }
      />
    );
  }
}

export default injectIntl(GenderFilterSelect);
