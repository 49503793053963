import React from 'react';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CreateContact } from '@/domains';
import { STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { createParticipantContact } from '@/services/api/participant-contacts';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import { AppContext } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import { withValidation } from '@/components/hoc/withValidation';
import DataOptionSelect from '@/components/Selects/DataOptionSelect';

import style from './AddContactModal.sass';

const schema: yup.ObjectSchema<CreateContact> = yup.object({
  fullName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.name.label)
    .required(),
  additionalInformation: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.additionalInfo.label)
    .notRequired(),
  typeId: yup
    .mixed()
    .label(translations.inputs.contactType.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);
const ContactTypeSelectWithValidation = withValidation(DataOptionSelect.ContactTypeSelect);
const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);

export interface Props extends InstanceProps {
  participantId: string;
  onContactSaved: () => any;
}

const AddContactModal: React.FC<Props & WrappedComponentProps> = ({ intl, participantId, onContactSaved, close }) => {
  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12}>
          <div className={style.titleAndClose}>
            <Typography is="h6" type="swan" weight="bold">
              <FormattedMessage id={translations.modals.addContact.title} />
            </Typography>

            <Button onClick={close}>
              <BoxedIcon type="close" appearance="red" />
            </Button>
          </div>

          <Spacer xs={4} />

          <Typography is="h6" type="halcyon" weight="lighter" color="gray">
            <FormattedMessage id={translations.modals.addContact.personalInformation} />
          </Typography>

          <Spacer xs={2} />

          <Form
            id="add-contact"
            schema={schema}
            subscription={{ dirty: true, pristine: true, submitting: true, submitError: true }}
            onSubmit={(data) => createParticipantContact(participantId, data).then(() => onContactSaved() || close())}
          >
            {({ dirty, pristine, submitting, submitError }, id) => (
              <React.Fragment>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-full-name`}
                  name="fullName"
                  label={intl.formatMessage({ id: translations.inputs.fullName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fullName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={1} />

                <Form.Field
                  is={ContactTypeSelectWithValidation}
                  teamId={team.id}
                  id={`${id}-type-id`}
                  name="typeId"
                  label={intl.formatMessage({ id: translations.inputs.role.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.role.placeholder })}
                  readOnly={submitting}
                  language={language}
                />

                <Spacer xs={3} />

                <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                  <FormattedMessage id={translations.modals.addContact.additionalInfo} />
                </Typography>

                <Spacer xs={2} />

                <Form.Field
                  is={TextAreaWithValidation}
                  id={`${id}-additional-information`}
                  name="additionalInformation"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.additionalInfo.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.additionalInfo.placeholder })}
                  readOnly={submitting}
                  inputClassName={style.additionalInfo}
                />

                <Spacer xs={2} />

                {!!submitError && <SubmitError error={submitError} />}

                <Spacer xs={2} />

                <Button
                  form={id}
                  className={style.addContact}
                  loading={submitting}
                  disabled={!dirty || pristine}
                  appearance="orange"
                >
                  <FormattedMessage id={translations.modals.addContact.add} />
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Card.Column>
      </Card.Row>
    </Card>
  );
};
export default injectIntl(AddContactModal);
