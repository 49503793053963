import React from 'react';

import HiddenInput, { Props as HiddenInputProps } from '@/components/HiddenInput';

export interface Props extends Omit<HiddenInputProps, 'value' | 'onChange' | 'multiple'> {
  // can be string because it might be an url already uploaded
  multiple?: boolean;
  value?: string | File | FileList | null;
  onChange: (files: FileList) => any;
}

const FileUpload: React.FC<Props> = ({ className, children, multiple = false, onChange, ...rest }) => {
  return (
    <label htmlFor={rest.id} className={className}>
      {children}

      <HiddenInput
        {...rest}
        type="file"
        multiple={multiple}
        onChange={(event) => onChange(event.target.files)}
        value=""
      />
    </label>
  );
};

export default FileUpload;
