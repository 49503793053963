import React from 'react';

interface SidebarState {
  readonly isOpen;
}

interface SidebarActions {
  readonly open: () => any;
  readonly close: () => any;
}

interface Props {
  isOpen: boolean;
}

interface State extends SidebarState, SidebarActions {}

export const SidebarContext = React.createContext<State>({
  isOpen: false,
  open: () => null,
  close: () => null
});

export const SidebarConsumer = SidebarContext.Consumer;

export class SidebarProvider extends React.PureComponent<Props, State> implements SidebarActions {
  state: State = {
    isOpen: this.props.isOpen,
    open: this.open.bind(this),
    close: this.close.bind(this)
  };

  open() {
    return this.setState({ isOpen: true });
  }

  close() {
    return this.setState({ isOpen: false });
  }

  render() {
    return <SidebarContext.Provider value={this.state}>{this.props.children}</SidebarContext.Provider>;
  }
}

export default {
  SidebarProvider,
  SidebarConsumer,
  SidebarContext
};
