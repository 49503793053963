import React, { PureComponent } from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getNow, getISOString, getDateFormat } from '@/util';
import { QuestionType, SurveyType } from '@/domains';
import { createSurvey } from '@/services/api/survey';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import TextInput from '@/components/TextInput';
import TimeInput from '@/components/TimeInput';
import Typography from '@/components/Typography';
import Breadcrumb from '@/components/Breadcrumb';
import { dateFormat } from '@/components/DateInput';
import TextWithIcon from '@/components/TextWithIcon';
import { AppConsumer } from '@/components/Context/App';
import DatePickerInput from '@/components/DatePickerInput';
import { withValidation } from '@/components/hoc/withValidation';
import QuestionTypeSelect from '@/components/Selects/QuestionTypeSelect';

import style from './Create.sass';
import BoxedIcon from '@/components/BoxedIcon';

interface Params {
  id: string;
}

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup
    .string()
    .max(250)
    .label(translations.inputs.surveyDescription.label)
    .required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  startTime: yup
    .string()
    .label(translations.inputs.startTime.label)
    .required(),
  endTime: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startTime: string = this.resolve(yup.ref('startTime'));
      const startDate: string = this.resolve(yup.ref('startDate'));
      const endDate: string = this.resolve(yup.ref('endDate'));

      if (!startTime) return true;

      return startDate === endDate ? startTime.localeCompare(value) === -1 : true;
    })
    .label(translations.inputs.endTime.label)
    .required(),
  questions: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        type: yup.string().required(),
        title: yup
          .string()
          .label(translations.pages.digitalCoach.survey.question.label)
          .required(),
        options: yup.array().when(['type'], (type) => {
          return type === QuestionType.TextInput
            ? yup.array().notRequired()
            : yup
                .array()
                .required()
                .min(2)
                .of(
                  yup.object().shape({
                    option: yup.string().required()
                  })
                );
        })
      })
    )
    .required()
});

const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const TextInputWithValidation = withValidation(TextInput);
const DatePickerInputWithValidation = withValidation(DatePickerInput);
const TimeInputWithValidation = withValidation(TimeInput);

class Create extends PureComponent<RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    const data = this.props.location.state ? this.props.location.state.data : null;
    const { start, end, type, ...rest } = data || {};
    return (
      <AppConsumer>
        {({ team }) => (
          <Card className={style.card}>
            <Card.Row className={style.padded}>
              <Breadcrumb
                shape={{
                  [intl.formatMessage({ id: translations.pages.digitalCoach.title })]: urls.digitalCoach.coach,
                  [intl.formatMessage({ id: translations.pages.digitalCoach.survey.createSurvey })]: null
                }}
              />
            </Card.Row>

            <Card.Row className={classnames(style.padded, style.column)}>
              <Form
                id="survey"
                schema={schema}
                initialValues={
                  data
                    ? {
                        type: intl.formatMessage({ id: translations.surveyType.REGULAR }),
                        startDate: getDateFormat(start, 'yyyy-MM-dd'),
                        startTime: getDateFormat(start, 'HH:mm'),
                        endDate: getDateFormat(end, 'yyyy-MM-dd'),
                        endTime: getDateFormat(end, 'HH:mm'),
                        ...rest
                      }
                    : {
                        type: intl.formatMessage({ id: translations.surveyType.REGULAR }),
                        startDate: getNow(dateFormat),
                        endDate: getNow(dateFormat)
                      }
                }
                subscription={{
                  values: true,
                  dirty: true,
                  pristine: true,
                  submitting: true,
                  submitError: true,
                  valid: true
                }}
                onSubmit={(data) =>
                  createSurvey({
                    start: getISOString(`${data.startDate} ${data.startTime}`, `${dateFormat} HH:mm`),
                    end: getISOString(`${data.endDate} ${data.endTime}`, `${dateFormat} HH:mm`),
                    description: data.description && data.description,
                    title: data.title,
                    questions: data.questions.map((question) => ({
                      ...question,
                      ...(question.options ? { options: question.options.map((op) => op.option) } : null)
                    })),
                    type: SurveyType.Regular,
                    teamId: team.id
                  }).then((response) => this.props.history.push(urls.digitalCoach.survey.get(response.id)))
                }
                mutators={{ ...arrayMutators }}
              >
                {(
                  {
                    form: {
                      mutators: { push }
                    },
                    form,
                    submitting,
                    submitError,
                    values,
                    valid,
                    dirty
                  },
                  id
                ) => (
                  <React.Fragment>
                    <Typography is="span" type="swan" weight="bold">
                      <FormattedMessage id={translations.pages.digitalCoach.survey.title} />
                    </Typography>

                    <Spacer xs={3} />

                    <Form.Field
                      is={TextInputWithValidation}
                      type="text"
                      id={`${id}-type`}
                      name="type"
                      label={intl.formatMessage({ id: translations.inputs.surveyType.label })}
                      disabled={true}
                      className={style.field}
                    />

                    <Spacer xs={2} />

                    <Form.Field
                      is={TextInputWithValidation}
                      type="text"
                      id={`${id}-title`}
                      name="title"
                      label={intl.formatMessage({ id: translations.inputs.surveyTitle.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.surveyTitle.placeholder })}
                      readOnly={submitting}
                      className={style.field}
                    />

                    <Spacer xs={3} />

                    <Typography is="span" type="hummingbird" color="gray">
                      <FormattedMessage id={translations.pages.digitalCoach.survey.description} />
                    </Typography>

                    <Spacer xs={1} />

                    <Form.Field
                      is={TextAreaWithValidation}
                      id={`${id}-description`}
                      name="description"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.surveyDescription.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.surveyDescription.placeholder })}
                      readOnly={submitting}
                      inputClassName={style.textArea}
                    />

                    <Spacer xs={5} />

                    <Typography is="span" type="hummingbird" color="gray">
                      <FormattedMessage id={translations.pages.digitalCoach.survey.schedule} />
                    </Typography>

                    <Spacer xs={1} />

                    <div className={classnames(style.row, style.topAlign)}>
                      <Form.Field
                        is={DatePickerInputWithValidation}
                        change={form.change}
                        id={`start-date`}
                        name="startDate"
                        type="text"
                        readOnly={submitting}
                        className={style.halfField}
                      />
                      <div className={style.margin}>
                        <Form.Field
                          is={TimeInputWithValidation}
                          id={`start-time`}
                          name="startTime"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.startTime.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.startTime.placeholder })}
                          readOnly={submitting}
                          className={style.halfField}
                        />
                      </div>
                    </div>

                    <Spacer xs={2} />

                    <div className={style.row}>
                      <Form.Field
                        is={DatePickerInputWithValidation}
                        change={form.change}
                        id={`end-date`}
                        name="endDate"
                        type="text"
                        readOnly={submitting}
                        className={style.halfField}
                      />

                      <div className={style.margin}>
                        <Form.Field
                          is={TimeInputWithValidation}
                          id={`end-time`}
                          name="endTime"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.endTime.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.endTime.placeholder })}
                          readOnly={submitting}
                          className={style.halfField}
                        />
                      </div>
                    </div>

                    <Spacer xs={7} />

                    <FieldArray name="questions">
                      {({ fields }) =>
                        fields.map((name, questionNumber) => (
                          <div className={style.column} key={questionNumber}>
                            <Typography is="span" type="halcyon" weight="bold">
                              <FormattedMessage
                                id={translations.pages.digitalCoach.survey.question.number}
                                values={{ number: questionNumber + 1 }}
                              />
                            </Typography>

                            <Spacer xs={3} />

                            <div className={classnames(style.row)}>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${questionNumber}-title`}
                                name={`${name}title`}
                                label={intl.formatMessage({
                                  id: translations.pages.digitalCoach.survey.question.label
                                })}
                                placeholder=""
                                readOnly={submitting}
                                className={style.field}
                              />

                              <div className={style.spacer} />

                              <Form.Field
                                is={QuestionTypeSelect}
                                type="text"
                                id={`${questionNumber}-type`}
                                name={`${name}type`}
                                label={intl.formatMessage({ id: translations.pages.digitalCoach.survey.question.type })}
                                readOnly={submitting}
                                className={style.questionTypeSelect}
                              />

                              <div className={style.spacer} />

                              <Button is="button" type="button" onClick={() => fields.remove(questionNumber)}>
                                <BoxedIcon type="bin" appearance="red" />
                              </Button>

                              <div className={style.spacer} />

                              <Button
                                is="button"
                                type="button"
                                onClick={() => push('questions', { ...fields.value[questionNumber] })}
                              >
                                <BoxedIcon type="duplicate" appearance="orange" />
                              </Button>
                            </div>

                            <If
                              condition={fields.value[questionNumber].type !== QuestionType.TextInput}
                              then={() => (
                                <React.Fragment>
                                  <Spacer xs={2} />

                                  <FieldArray name={`${name}options`}>
                                    {({ fields }) =>
                                      fields.map((name, index) => (
                                        <div className={style.column} key={index}>
                                          <div className={style.row}>
                                            <Form.Field
                                              is={TextInputWithValidation}
                                              type="text"
                                              id={`${questionNumber}${index}-option`}
                                              name={`${name}option`}
                                              label={intl.formatMessage(
                                                { id: translations.pages.digitalCoach.survey.question.answerOption },
                                                { number: index + 1 }
                                              )}
                                              placeholder=""
                                              readOnly={submitting}
                                              className={style.field}
                                            />

                                            <div className={style.spacer} />

                                            <Button is="button" type="button" onClick={() => fields.remove(index)}>
                                              <BoxedIcon type="bin" appearance="red" />
                                            </Button>
                                          </div>
                                          <Spacer xs={2} />
                                        </div>
                                      ))
                                    }
                                  </FieldArray>

                                  <Spacer xs={1} />

                                  <Button
                                    is="button"
                                    type="button"
                                    className={style.addOption}
                                    onClick={() => push(`${name}options`, '')}
                                  >
                                    <TextWithIcon
                                      icon="plus"
                                      boxed
                                      iconSize="small"
                                      iconColor="orangeHollow"
                                      color="orange"
                                      is="span"
                                      type="hummingbird"
                                    >
                                      <FormattedMessage id={translations.pages.digitalCoach.survey.addOption} />
                                    </TextWithIcon>
                                  </Button>
                                </React.Fragment>
                              )}
                              else={() => (fields.value[questionNumber].options = undefined)}
                            />

                            <Spacer xs={5} />
                          </div>
                        ))
                      }
                    </FieldArray>

                    <Button
                      is="button"
                      type="button"
                      appearance="ghost"
                      onClick={() => push('questions', { type: QuestionType.TextInput })}
                    >
                      <FormattedMessage id={translations.pages.digitalCoach.survey.addQuestion} />
                    </Button>

                    <Spacer xs={7} />

                    <div className={classnames(style.padded, style.row, style.buttons)}>
                      <Button fat appearance="ghost" type="button" className={style.button}>
                        <FormattedMessage id={translations.buttons.cancel} />
                      </Button>

                      <Button
                        fat
                        appearance="orange"
                        type="submit"
                        className={style.button}
                        disabled={
                          !dirty ||
                          !valid ||
                          //TODO this is a hacky fix for the form validation error. Change when a fix will be found
                          (values.questions &&
                            values.questions
                              .filter((question) => question.type !== QuestionType.TextInput)
                              .find((question) => question.options === undefined || question.options.length < 2) !=
                              undefined)
                        }
                      >
                        <FormattedMessage id={translations.buttons.save} />
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </Form>
            </Card.Row>
          </Card>
        )}
      </AppConsumer>
    );
  }
}

export default injectIntl(withRouter(Create));
