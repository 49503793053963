import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import TabbedContainer from '@/components/TabbedContainer';
import Tab from '@/components/TabbedContainer/Tab';
import { SessionConsumer } from '@/components/Context/Session';
import Spacer from '@/components/Spacer';
import If from '@/components/If';
import EntityPreview from '@/components/EntityPreview';

import BillingInfo from './BillingInfo';
import BillingHistory from './BillingHistory';
import AboutPricing from './AboutPricing';

import style from './Licenses.sass';

const Licenses: React.FC = () => (
  <SessionConsumer>
    {({ organization }) => (
      <div className={style.root}>
        <Spacer xs={2} />

        {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

        <Spacer xs={4} />

        <Card className={style.card}>
          <Card.Row>
            <Card.Column sm={12}>
              <Typography is="span" type="swan" weight="bold">
                <FormattedMessage id={translations.pages.organization.licenses.title} />
              </Typography>
            </Card.Column>
          </Card.Row>

          <TabbedContainer>
            <Tab title={<FormattedMessage id={translations.pages.organization.licenses.tabs.billingInfo.title} />}>
              <BillingInfo />
            </Tab>

            <Tab title={<FormattedMessage id={translations.pages.organization.licenses.tabs.billingHistory.title} />}>
              <BillingHistory />
            </Tab>

            <Tab title={<FormattedMessage id={translations.pages.organization.licenses.tabs.aboutPricing.title} />}>
              <AboutPricing />
            </Tab>
          </TabbedContainer>
        </Card>
      </div>
    )}
  </SessionConsumer>
);

export default Licenses;
