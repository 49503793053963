import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { ParticipantSessionInvitationRowItem } from '@/domains';
import { getParticipantInvitationStatusForSession } from '@/services/api/participant-digital-coach';

import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import FilterManager from '@/components/FilterManager';
import { AppConsumer } from '@/components/Context/App';
import InviteParticipantsToSessionModal from '@/components/SearchableModals/InviteParticipantsToSessionModal';

import style from './Invitations.sass';

import InvitationRow from './InvitationRow';
import InvitationHeader from './InvitationHeader';

export interface Props {}

interface Params {
  id: string;
}

const schema: yup.ObjectSchema<{ unregisteredParticipants: any }> = yup.object({
  unregisteredParticipants: yup
    .number()
    .label(translations.inputs.unregisteredParticipants.label)
    .min(0)
    .max(256)
    .required()
});

const Invitations: React.FC<Props & WrappedComponentProps & RouteComponentProps<Params>> = ({ match, intl }) => {
  const sessionId = match.params.id;

  return (
    <AppConsumer>
      {({ team, loading }) =>
        !loading && (
          <Refresh>
            {({ key, refresh }) => (
              <div className={style.root}>
                <FilterManager initialCriteria={{}}>
                  {({ criteria, debouncedUpdate }) => (
                    <React.Fragment>
                      <div className={style.searchAndButton}>
                        <TextInput
                          type="text"
                          id="search"
                          onChange={(event) => debouncedUpdate('search', event.target.value)}
                          label={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.participantSearch.placeholder })}
                          autoComplete="off"
                          className={style.search}
                          leadingIcon="search"
                        />
                        <Modal
                          modal={InviteParticipantsToSessionModal}
                          teamId={team.id}
                          sessionId={sessionId}
                          onSubmit={refresh}
                        >
                          {({ open }) => (
                            <Button fat appearance="orange" onClick={open}>
                              <FormattedMessage id={translations.inputs.inviteParticipant} />
                            </Button>
                          )}
                        </Modal>
                      </div>

                      <Table
                        key={key}
                        id={`${JSON.stringify({ criteria })}`}
                        source={(page, number) =>
                          getParticipantInvitationStatusForSession(sessionId, page, number, {
                            keyword: criteria.search
                          })
                        }
                        empty={() => (
                          <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                            <Typography is="span" type="swan" weight="bold">
                              <FormattedMessage id={translations.pages.digitalCoach.tabs.emergencyInfo.table.empty} />
                            </Typography>
                          </Placeholder>
                        )}
                        renderHeader={() => <InvitationHeader />}
                        renderRow={(data: ParticipantSessionInvitationRowItem) => (
                          <React.Fragment key={data.participantAccountOverviewDto.id}>
                            <InvitationRow data={data} />
                          </React.Fragment>
                        )}
                      />
                    </React.Fragment>
                  )}
                </FilterManager>
              </div>
            )}
          </Refresh>
        )
      }
    </AppConsumer>
  );
};
export default withRouter(injectIntl(Invitations));
