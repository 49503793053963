import React from 'react';
import { FormApi } from 'final-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { urls, rules, Role } from '@/constants';
import { translations } from '@/locale';
import { userUpdateSchema } from '@/validation';
import { removeTeamMembers } from '@/services/api/team';
import { Member, UpdateMember, State, Me } from '@/domains';
import { getMemberDetails, updateMember } from '@/services/api/member';

import If from '@/components/If';
import Form from '@/components/Form';
import Card from '@/components/Card';
import Team from '@/components/Team';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import HasRole from '@/components/HasRole';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import DateInput from '@/components/DateInput';
import TextInput from '@/components/TextInput';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import DropdownPicture from '@/components/DropdownPicture';
import GenderSelect from '@/components/Selects/GenderSelect';
import { SessionConsumer } from '@/components/Context/Session';
import { withValidation } from '@/components/hoc/withValidation';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import style from './Details.sass';

const mapMember: (member: Member) => UpdateMember = ({ id, state, ...data }) => data;
const mapStateToBoolean: (state: State) => boolean = (state) => state === State.Enabled;
const haveFieldsChanged: (fields: object, form: FormApi<any>) => boolean = (fields, form) =>
  Object.keys(fields).some((key) => form.getFieldState(key).dirty);

const DropdownPictureWithValidation = withValidation(DropdownPicture);
const TextInputWithValidation = withValidation(TextInput);
const DateInputWithValidation = withValidation(DateInput);
const GenderSelectWithValidation = withValidation(GenderSelect);

interface Props {
  teamId: string;
  onMemberDetailsChanged: () => any;
}

interface Params {
  id: string;
}

const Details: React.FC<Props & WrappedComponentProps & RouteComponentProps<Params>> = ({
  intl,
  match,
  history,
  teamId,
  onMemberDetailsChanged: refreshMembersList
}) => {
  const { id } = match.params;

  const isUserMe: (myId: string, userId: string) => boolean = (myId, userId) => myId === userId;
  const isEditDisabled = (me: Me): boolean => me.role === Role.StreetWorker || me.role === Role.Volunteer;

  return (
    <SessionConsumer>
      {({ me, reload: reloadMe }) => (
        <LoadData id={id} load={getMemberDetails}>
          {({ value: memberDetails, loading, reload: reloadMemberDetails }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center overlay>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <If
                  condition={!memberDetails}
                  then={() => (
                    <Placeholder image="questions" size="md" className={style.placeholder}>
                      <Typography is="h6" type="swan" weight="bold">
                        <FormattedMessage id={translations.pages.organization.members.details.notFound} />
                      </Typography>
                    </Placeholder>
                  )}
                  else={(userDisabled = !mapStateToBoolean(memberDetails.user.state)) => (
                    <Form
                      id="edit-member"
                      initialValues={mapMember(memberDetails.user)}
                      schema={userUpdateSchema}
                      subscription={{ dirty: true, valid: true, submitting: true, submitError: true }}
                      onSubmit={({ email, role, firstName, lastName, imageUrl, ...data }, form) =>
                        updateMember(id, { email, role, firstName, lastName, imageUrl, ...data }).then(() => {
                          if (haveFieldsChanged({ email, firstName, lastName, imageUrl }, form)) refreshMembersList();
                          if (isUserMe(me.id, id)) reloadMe();
                          reloadMemberDetails();
                        })
                      }
                      className={style.form}
                    >
                      {({ form, dirty, valid, submitting, submitError }, formId) => (
                        <React.Fragment>
                          <Card.Row padded className={style.nameSection}>
                            <div className={style.pictureAndName}>
                              <Form.Field
                                is={DropdownPictureWithValidation}
                                id={`${formId}-image`}
                                name="imageUrl"
                                type="teammate"
                                accept="image/x-png,image/jpeg"
                                size="lg"
                                uploadable
                                removable
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />

                              {!loading && (
                                <div className={style.nameAndTitle}>
                                  <Typography is="span" type="halcyon" weight="bold">
                                    {`${memberDetails.user.firstName ? memberDetails.user.firstName : ''} ${
                                      memberDetails.user.lastName ? memberDetails.user.lastName : ''
                                    }`}
                                  </Typography>

                                  <Typography is="span" type="halcyon" color="gray">
                                    <FormattedMessage id={translations.roles[memberDetails.user.role]} />
                                  </Typography>
                                </div>
                              )}
                            </div>

                            <HasRole to={rules.TEAM_REMOVE_MEMBERS}>
                              {!isUserMe(me.id, id) && !isEditDisabled(me) && (
                                <Modal
                                  modal={CrudConfirmationModal}
                                  message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
                                  onConfirm={() =>
                                    removeTeamMembers(teamId, [{ id }])
                                      .then(() => history.push(urls.teammates.list))
                                      .then(refreshMembersList)
                                  }
                                >
                                  {({ open }) => (
                                    <Button type="button" className={style.deleteButton} onClick={open}>
                                      <TextWithIcon boxed icon="bin" color="red" is="span" type="hummingbird">
                                        <FormattedMessage id={translations.pages.organization.members.details.remove} />
                                      </TextWithIcon>
                                    </Button>
                                  )}
                                </Modal>
                              )}
                            </HasRole>
                          </Card.Row>

                          <Card.Row className={style.fields}>
                            <Typography is="h6" type="halcyon" color="gray">
                              <FormattedMessage
                                id={translations.pages.organization.members.details.personalInformation}
                              />
                            </Typography>

                            <Spacer xs={1} />

                            <div className={style.fieldContainer}>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${formId}-first-name`}
                                name="firstName"
                                label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.firstName.placeholder
                                })}
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${formId}-last-name`}
                                name="lastName"
                                label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />

                              <Form.Field
                                is={DateInputWithValidation}
                                type="text"
                                id={`${formId}-birth-date`}
                                name="birthDate"
                                label={intl.formatMessage({ id: translations.inputs.birthDate.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.birthDate.placeholder
                                })}
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />

                              <Form.Field
                                is={GenderSelectWithValidation}
                                id={`${formId}-gender`}
                                name="gender"
                                label={intl.formatMessage({ id: translations.inputs.gender.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.gender.placeholder })}
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />
                            </div>

                            <Spacer xs={3} />

                            <Typography is="h6" type="halcyon" color="gray">
                              <FormattedMessage
                                id={translations.pages.organization.members.details.contactInformation}
                              />
                            </Typography>

                            <Spacer xs={1} />

                            <div className={style.fieldContainer}>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${formId}-email`}
                                name="email"
                                label={intl.formatMessage({ id: translations.inputs.email.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                                readOnly={submitting}
                                disabled={isEditDisabled(me)}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${formId}-phone-number`}
                                name="phoneNumber"
                                label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.phoneNumber.placeholder
                                })}
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />
                            </div>

                            <Spacer xs={3} />

                            <Typography is="h6" type="halcyon" color="gray">
                              <FormattedMessage id={translations.pages.organization.members.details.activity} />
                            </Typography>

                            <Spacer xs={1} />

                            <div className={style.fieldContainer}>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${formId}-job-title`}
                                name="jobTitle"
                                label={intl.formatMessage({ id: translations.inputs.jobTitle.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.jobTitle.placeholder })}
                                readOnly={submitting}
                                disabled={userDisabled || isEditDisabled(me)}
                              />
                            </div>
                          </Card.Row>

                          <Card.Row className={style.fields}>
                            <Typography is="h6" type="halcyon" weight="bold">
                              <FormattedMessage id={translations.pages.organization.members.details.memberIn} />
                            </Typography>

                            <Spacer xs={1} />

                            <If
                              condition={memberDetails.teams.length === 0}
                              then={() => (
                                <Typography is="span" type="halcyon" color="gray">
                                  <FormattedMessage id={translations.pages.organization.members.details.noTeams} />
                                </Typography>
                              )}
                              else={() => (
                                <div className={style.teams}>
                                  {memberDetails.teams.map((team) => (
                                    <Team key={team.id} logo={team.imageUrl} name={team.name} border />
                                  ))}
                                </div>
                              )}
                            />

                            <Spacer xs={4} />

                            {!!submitError && <SubmitError error={submitError} className={style.error} />}

                            <div>
                              <Modal
                                modal={CrudConfirmationModal}
                                message={intl.formatMessage({ id: translations.modals.confirmationModals.edit })}
                                onConfirm={() => form.submit()}
                              >
                                {({ open }) => (
                                  <Button
                                    form={formId}
                                    type="button"
                                    appearance="orange"
                                    loading={submitting}
                                    disabled={!dirty || !valid || isEditDisabled(me)}
                                    block
                                    className={style.saveButton}
                                    onClick={open}
                                  >
                                    <FormattedMessage id={translations.pages.organization.members.details.save} />
                                  </Button>
                                )}
                              </Modal>
                            </div>
                          </Card.Row>
                        </React.Fragment>
                      )}
                    </Form>
                  )}
                />
              )}
            />
          )}
        </LoadData>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(withRouter(Details));
