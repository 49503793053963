import React from 'react';

import { Activity } from '@/domains';
import { getStreetSessionActivities } from '@/services/api/street-session-activities';

import Select, { Item, Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<string, string>, 'items'> {
  sessionId: string;
  language: string;
}

interface State {
  loading: boolean;
  items: Item<string>[];
}

class SessionActivitySelect extends React.PureComponent<Props, State> {
  state: State = { loading: false, items: [] };

  componentDidMount() {
    this.loadByType();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) this.loadByType();
  }

  loadByType = () => {
    return this.load(() => getStreetSessionActivities(this.props.sessionId));
  };

  load = (callback: () => Promise<Activity[]>) => {
    this.setState({ loading: true });

    return callback()
      .then((items) => {
        this.setState({
          loading: false,
          items: items.map((item) => ({
            value: item.id,
            name:
              item.translations && item.translations[this.props.language.toLowerCase()]
                ? item.translations[this.props.language.toLowerCase()]
                : item.name
          }))
        });
      })
      .catch(() => this.setState({ loading: false, items: [] }));
  };

  render() {
    const { sessionId, ...rest } = this.props;
    const { items } = this.state;

    return <Select {...rest} items={items} searchable={false} />;
  }
}

export default SessionActivitySelect;
