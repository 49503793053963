import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Member } from '@/domains';
import { translations } from '@/locale';
import { getTeamMembers } from '@/services/api/team';
import { addTeamMembersToSession } from '@/services/api/street-session-teammates';

import { InstanceProps } from '@/components/Modal';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

interface Props extends InstanceProps {
  teamId: string;
  sessionId: string;
  onSubmit: () => void;
}

class AddTeammatesToSessionModal extends React.PureComponent<Props & WrappedComponentProps> {
  extractTeamMemberData = (teamMember: Member): SelectableItemData => {
    return {
      id: teamMember.id,
      topText: `${teamMember.firstName} ${teamMember.lastName}`,
      bottomText: teamMember.email,
      photoUrl: teamMember.imageUrl
    };
  };

  render() {
    const { teamId, sessionId, onSubmit, intl } = this.props;

    return (
      <SearchableModal close={this.props.close}>
        <SearchableModal.Header
          title={intl.formatMessage({ id: translations.modals.searchableModal.teammatesToSession.title })}
          subtitle={intl.formatMessage({ id: translations.modals.searchableModal.teammatesToSession.subtitle })}
        />
        <SearchableModal.Content
          close={this.props.close}
          searchLabel={intl.formatMessage({ id: translations.inputs.membersSearch.label })}
          searchPlaceholder={intl.formatMessage({ id: translations.inputs.membersSearch.placeholder })}
          dataSource={(page, number, criteria) => getTeamMembers(page, number, criteria, teamId)}
          submitDestination={(values) =>
            addTeamMembersToSession(
              sessionId,
              values.selectedItems.map((id) => {
                return { id };
              })
            ).then(onSubmit)
          }
          dataExtractionFunction={this.extractTeamMemberData}
        />
      </SearchableModal>
    );
  }
}
export default injectIntl(AddTeammatesToSessionModal);
