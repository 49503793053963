import flat from 'flat';
import * as yup from 'yup';

import { Language } from '@/constants/language';

import englishMessage from './en.json';
import frenchMessage from './fr.json';
import spanishMessage from './es.json';
import dutchMessage from './nl.json';
import albanianMessage from './sq.json';
import greekMessage from './el.json';
import romanianMessage from './ro.json';
import polishMessage from './pl.json';

export type LocaleTranslation = typeof englishMessage;

const messages: Record<Language, LocaleTranslation> = {
  [Language.English]: englishMessage,
  [Language.French]: frenchMessage,
  [Language.Spanish]: spanishMessage,
  [Language.Dutch]: dutchMessage,
  [Language.Albanian]: albanianMessage,
  [Language.Greek]: greekMessage,
  [Language.Romanian]: romanianMessage,
  [Language.Polish]: polishMessage
};

const traverse = (obj: any, path = ''): any => {
  if (obj === null) {
    return null;
  }

  const result: any = {};

  if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      result[key] = traverse(obj[key], path ? `${path}.${key}` : key);
    });
  } else {
    return path;
  }

  return result;
};

export const translations: LocaleTranslation = traverse(englishMessage);

yup.setLocale(translations.validation.basic);

export const getTranslationMessages = (language: Language): any => flat(messages[language]);
