import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { updateEvaluationModule } from '@/services/api/organization';
import { EvaluationModuleListItem, EvaluationModuleState as State } from '@/domains';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import ToggleSwitch from '@/components/ToggleSwitch';

import cellStyle from '../cellStyle.sass';

export interface Props {
  evaluationModule: EvaluationModuleListItem;
  className?: string;
}

const EvaluationModuleRow: React.FC<Props> = ({ evaluationModule, className, ...rest }) => {
  const intl = useIntl();
  return (
    <Card.Row is="li" className={classnames(cellStyle.container, className)} {...rest}>
      <Typography is="span" type="halcyon" className={cellStyle.name}>
        <FormattedMessage id={translations.evaluationModules[evaluationModule.type]} />
      </Typography>

      <div className={cellStyle.enabled}>
        <LiveSubmit
          key={evaluationModule.type}
          loading={false}
          value={{ state: evaluationModule.state }}
          onChange={({ state }) => updateEvaluationModule(evaluationModule.type, state)}
        >
          {({ value: { state }, set }) => (
            <ToggleSwitch
              checked={state === State.Enabled}
              label={false}
              onClick={() => set('state', state === State.Enabled ? State.Disabled : State.Enabled)}
              onChange={() => null}
            />
          )}
        </LiveSubmit>
      </div>
    </Card.Row>
  );
};

export default EvaluationModuleRow;
