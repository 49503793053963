import { Order } from '@/components/Ordering';
import { By, CreateGoal, Goal, GoalState, Page, Sort } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const createGoal: (participantId: string, teamId: string, goal: CreateGoal) => Promise<any> = (
  participantId,
  teamId,
  goal
) => authorizedApi.post<any>(`/v1/goals`, { participantId, teamId, ...goal });

export const getGoals: (
  participantId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
) => Promise<Page<Goal>> = (participantId, teamId, pageNumber, pageSize) =>
  authorizedApi.get<Page<Goal>>(`/v1/goals`, {
    participantId,
    pageNumber,
    pageSize,
    teamId,
    [Sort.By]: By.CreatedAt,
    [Sort.Order]: Order.Descendent
  });

export const updateGoalState: (sessionId: string, goalId: string, state: GoalState) => Promise<any> = (
  sessionId,
  goalId,
  state
) => authorizedApi.put<any>(`/v1/goals/complete/${goalId}/sessions/${sessionId}`, { completionStatus: state });

export const deleteGoal: (sessionId: string, goalId: string) => Promise<any> = (sessionId, goalId) =>
  updateGoalState(sessionId, goalId, GoalState.Canceled);
