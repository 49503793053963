import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { State, CustomProperty, CustomPropertyType } from '@/domains';
import { updateCustomPropertyState, deleteCustomProperty } from '@/services/api/custom-property';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import style from './CustomPropertyRow.sass';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import ToggleSwitch from '@/components/ToggleSwitch';
import TextWithIcon from '@/components/TextWithIcon';
import CustomPropertyModal from '@/components/Modals/CustomPropertyModal';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import cellStyle from '../cellStyle.sass';

export interface Props {
  data: CustomProperty;
  className?: string;
  refresh?: () => any;
}

const CustomPropertyRow: React.FC<Props & WrappedComponentProps> = ({
  data: { id, name, type, state, availableOptions },
  className,
  refresh,
  intl,
  ...rest
}) => (
  <Card.Row is="li" className={classnames(cellStyle.container, style.root, className)} {...rest}>
    <Modal
      modal={CustomPropertyModal}
      customProperty={{ id, name, type, availableOptions }}
      refresh={refresh}
      onClose={() => type === CustomPropertyType.List && refresh()}
    >
      {({ open }) => (
        <div className={style.container} onClick={open}>
          <Typography is="div" type="sparrow" weight="bold" className={cellStyle.customPropertyName}>
            {name}
          </Typography>

          <Typography is="div" type="sparrow" className={cellStyle.customPropertyFieldType}>
            {type && <FormattedMessage id={translations.customPropertyTypes[type]} />}
          </Typography>

          <div className={cellStyle.enabled}>
            <LiveSubmit
              loading={false}
              value={{ customPropertyState: state }}
              onChange={({ customPropertyState }) => updateCustomPropertyState(id, customPropertyState)}
            >
              {({ value: { customPropertyState }, set }) => (
                <ToggleSwitch
                  checked={customPropertyState === State.Enabled}
                  label={false}
                  onClick={() =>
                    set('customPropertyState', customPropertyState === State.Enabled ? State.Disabled : State.Enabled)
                  }
                  onChange={() => null}
                  onInputLabelClick={(event) => event.stopPropagation()}
                />
              )}
            </LiveSubmit>
          </div>
        </div>
      )}
    </Modal>

    <Modal
      modal={CrudConfirmationModal}
      message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
      onConfirm={() => deleteCustomProperty(id).then(refresh)}
    >
      {({ open }) => (
        <Button onClick={open} className={cellStyle.remove}>
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
            <FormattedMessage id={translations.inputs.remove} />
          </TextWithIcon>
        </Button>
      )}
    </Modal>
  </Card.Row>
);

export default injectIntl(CustomPropertyRow);
