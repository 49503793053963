import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Session, Me, OrganizationBase } from '@/domains';
import { urls } from '@/constants';
import { getMe } from '@/services/api/me';
import { storage } from '@/services/storage';
import { authorizedApi } from '@/services/network/authorized-api';
import { getOrganizationDetails } from '@/services/api/organization';
import { signInWithEmail, signOut, renewToken } from '@/services/api/session';
import { decode } from '@/util';

interface SessionActions {
  reload(): Promise<null>;
  reloadOrganization(): Promise<any>;
  signInWithEmail(email: string, password: string): Promise<null>;
  signOut(): Promise<null>;
}

interface SessionState {
  session: Session | null;
  me: Me | null;
  organization: OrganizationBase | null;
  loading: boolean;
  error: any;
}

export const SessionContext = React.createContext<SessionActions & SessionState>({} as any);

interface Props {}

interface State extends Readonly<SessionActions>, Readonly<SessionState> {}

export const SessionConsumer = SessionContext.Consumer;

class Provider extends React.PureComponent<Props & RouteComponentProps, State> {
  wrapWithSession(callback: (...values: any[]) => Promise<Session>) {
    return (...values: any[]) =>
      callback(...values)
        .then(this.updateSession)
        .then(this.loadOrganization)
        .then(this.loadMe)
        .then(() => null);
  }

  signOut = () => Promise.resolve().then(this.clearSession);

  state: State = {
    session: null,
    me: null,
    organization: null,
    loading: true,
    error: null,
    reload: () => this.loadMe(),
    reloadOrganization: () => this.loadOrganization(),
    signInWithEmail: this.wrapWithSession(signInWithEmail),
    signOut: this.signOut
  };

  componentDidMount() {
    authorizedApi.configure({
      onUnauthorized: () => Promise.resolve().then(this.clearSession),
      onAccessTokenExpired: this.renewToken
    });

    const session = storage.getItem<Session | null>('session');

    if (!session) return this.setState({ loading: false });

    this.updateSession(session)
      .then(this.loadOrganization)
      .then(this.loadMe)
      .catch(() => this.setState({ loading: false }));
  }

  updateSession = (session: Session) => {
    return new Promise((resolve) =>
      this.setState({ session }, () => {
        this.onSessionUpdated();
        resolve(null);
      })
    );
  };

  clearSession = () => {
    this.setState({ session: null, me: null }, this.onSessionUpdated);
    return null;
  };

  loadMe = () =>
    getMe()
      .then(this.updateMe)
      .catch(this.clearSession);

  updateMe = (me: Me) => {
    this.setState({ me, loading: false });
    return null;
  };

  loadOrganization = () => {
    let decodedToken = decode(this.state.session.idToken);

    if (!decodedToken || !decodedToken.organizationId) return null;

    const id = decodedToken.organizationId;

    return getOrganizationDetails(id).then(({ id, logoUrl, name }: OrganizationBase) =>
      this.setState({ organization: { id, logoUrl, name } })
    );
  };

  renewToken = () => {
    if (!this.state.session || !this.state.session.refreshToken)
      return Promise.reject(new Error(`No session found. Signing out...`));

    return renewToken(this.state.session.refreshToken)
      .then(this.updateSession)
      .catch(() => {
        this.clearSession();
        this.props.history.push(urls.home);
      });
  };

  onSessionUpdated = () => {
    storage.setItem('session', this.state.session);

    authorizedApi.token = this.state.session ? this.state.session.accessToken : null;
  };

  render() {
    return <SessionContext.Provider value={this.state}>{this.props.children}</SessionContext.Provider>;
  }
}

export const SessionProvider = withRouter(Provider);

export default {
  SessionProvider,
  SessionConsumer,
  SessionContext
};
