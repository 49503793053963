import React from 'react';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';

import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';

import style from './ProfilePictureList.sass';

export interface Props {
  items: string[];
  messageId: string;
  maxVisibleCount?: number;
  className?: string;
}

const ProfilePictureList: React.FC<Props> = ({ items, messageId, maxVisibleCount = 6, className }) => {
  const showRest = items.length > maxVisibleCount;
  const maxVisibleCountWithRest = maxVisibleCount - 1;
  const visiblePictures = showRest ? items.slice(0, maxVisibleCountWithRest) : items;
  const restCount = showRest ? items.length - maxVisibleCountWithRest : 0;

  return (
    <div className={className}>
      <div className={style.picturesContainer}>
        {map(visiblePictures, (picture, index) => (
          <Picture key={`${picture}-${index}`} size="xxs" url={picture} className={style.picture} />
        ))}

        {showRest && (
          <BoxedContent appearance="orange" className={style.rest}>
            <Typography is="div" type="hummingbird">{`+${restCount}`}</Typography>
          </BoxedContent>
        )}
      </div>

      <Typography is="div" type="hummingbird" color="gray">
        <FormattedMessage id={messageId} values={{ count: items.length }} />
      </Typography>
    </div>
  );
};

export default ProfilePictureList;
