import React from 'react';
import * as yup from 'yup';
import { formatISO, addMonths, differenceInMonths, parseISO, addDays } from 'date-fns';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { AddLicences } from '@/domains';
import { addPrepaidLicencesForOrganization, updatePrepaidLicence } from '@/services/api/licences';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import DatePickerInput from '@/components/DatePickerInput';
import { withValidation } from '@/components/hoc/withValidation';

import style from './PrepaidLicenceModal.sass';

interface Props extends InstanceProps {
  organizationId: string;
  onSubmit: () => any;
  initialValues?: any;
  editable?: boolean;
}

const TextInputWithValidation = withValidation(TextInput);
const DatePickerInputWithValidation = withValidation(DatePickerInput);

const schema: yup.ObjectSchema<AddLicences> = yup.object({
  monthlyNumber: yup
    .number()
    .moreThan(0)
    .label(translations.inputs.licences.label)
    .required(),
  endDate: yup
    .string()
    .test('minOneMonth', translations.validation.custom.minOneMonth, function(value) {
      const endDate: Date = parseISO(value);

      return differenceInMonths(endDate, new Date()) >= 1;
    })
    .label(translations.inputs.licenceExpirationDate.label)
    .required()
});

class PrepaidLicenceModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { organizationId, initialValues, onSubmit, close } = this.props;

    return (
      <Card className={style.root}>
        <Button className={style.closeButton} onClick={this.props.close}>
          <BoxedIcon type="close" appearance="red" />
        </Button>

        <Form
          id="prepaid-licenses"
          schema={schema}
          initialValues={
            {
              ...initialValues,
              endDate: initialValues
                ? initialValues.endDate
                : formatISO(addDays(addMonths(new Date(), 1), 1), { representation: 'date' })
            } as any
          }
          subscription={{ submitError: true, submitting: true, valid: true }}
          onSubmit={({ monthlyNumber, endDate }) =>
            (initialValues
              ? updatePrepaidLicence(initialValues.id, {
                  monthlyNumber,
                  endDate,
                  startDate: initialValues.startDate
                })
              : addPrepaidLicencesForOrganization(organizationId, {
                  monthlyNumber,
                  endDate,
                  startDate: formatISO(new Date(), { representation: 'date' })
                })
            ).then(() => onSubmit() || close())
          }
          className={style.form}
        >
          {({ form, submitError, submitting, valid }, id) => (
            <React.Fragment>
              <div>
                <Typography is="span" type="swan" weight="bold">
                  <FormattedMessage
                    id={
                      initialValues
                        ? translations.modals.prepaidLicenceModal.edit
                        : translations.modals.prepaidLicenceModal.title
                    }
                  />
                </Typography>

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-monthly-number`}
                  name="monthlyNumber"
                  type="text"
                  label={this.props.intl.formatMessage({ id: translations.inputs.licences.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.licences.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Typography is="span" type="halcyon" color="gray">
                  <FormattedMessage id={translations.modals.prepaidLicenceModal.validUntil} />
                </Typography>

                <Spacer xs={1} />

                <Form.Field
                  is={DatePickerInputWithValidation}
                  change={form.change}
                  id={`${id}-end-date`}
                  name="endDate"
                  type="text"
                  readOnly={submitting}
                />

                {!!submitError && (
                  <React.Fragment>
                    <Spacer xs={2} />

                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}
              </div>

              <Button
                form={id}
                loading={submitting}
                type="submit"
                appearance="orange"
                className={style.saveButton}
                disabled={!valid}
              >
                <FormattedMessage id={translations.inputs.addLicences} />
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Card>
    );
  }
}
export default injectIntl(PrepaidLicenceModal);
