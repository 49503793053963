import { LicencesInfo, AddLicences, UpdateLicences } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getPrepaidLicencesInformationForOrganization = (organizationId: string): Promise<LicencesInfo> =>
  authorizedApi.get(`/v1/licenses/${organizationId}`, {});

export const addPrepaidLicencesForOrganization = (organizationId: string, licences: AddLicences): Promise<any> =>
  authorizedApi.post(`/v1/licenses/${organizationId}`, { ...licences });

export const updatePrepaidLicence = (licenceId: string, licences: UpdateLicences): Promise<any> =>
  authorizedApi.put(`/v1/licenses/${licenceId}`, { ...licences });
