import React from 'react';

import Card from '@/components/Card';
import Spacer from '@/components/Spacer';

import PaymentDetails from './PaymentDetails';

import BillingInfoDetails from './BillingInfoDetails';
import PrepaidLicensesDetails from './PrepaidLicensesDetails';

import style from './BillingInfo.sass';

const BillingInfo: React.FC = () => {
  return (
    <React.Fragment>
      <Card.Row>
        <Card.Column>
          <div className={style.container}>
            <Spacer xs={4} />

            <PrepaidLicensesDetails />

            <Spacer xs={3} />

            <BillingInfoDetails />
          </div>
        </Card.Column>
      </Card.Row>

      <PaymentDetails />
    </React.Fragment>
  );
};

export default BillingInfo;
