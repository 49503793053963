import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatISO, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Note, TopicFilter, VisibilityFilter, Sort, By, UpdateNote } from '@/domains';
import { getParticipantNotes, updateParticipantNote, createParticipantNote } from '@/services/api/participant-notes';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import ToggleSwitch from '@/components/ToggleSwitch';
import FilterManager from '@/components/FilterManager';
import Ordering, { Order } from '@/components/Ordering';
import { LanguageContext } from '@/components/Language';
import NoteRow from '@/components/tables/NotesTable/Row';
import DatePickerInput from '@/components/DatePickerInput';
import CreateNoteModal from '@/components/Modals/CreateNoteModal';
import NoteDetailModal from '@/components/Modals/NoteDetailModal';
import TopicFilterSelect from '@/components/Selects/TopicFilterSelect';
import NoteHeader from '@/components/tables/NotesTable/Header';
import VisibilityFilterSelect from '@/components/Selects/VisibilityFilterSelect';

import style from './Notes.sass';

export interface Params {
  id: string;
}

const Notes: React.FC<WrappedComponentProps & RouteComponentProps<Params>> = ({ intl, match }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <FilterManager
          initialCriteria={{
            after: formatISO(startOfMonth(new Date()), { representation: 'date' }),
            before: formatISO(endOfMonth(new Date()), { representation: 'date' }),
            topic: TopicFilter.All,
            visibility: VisibilityFilter.All,
            lifeEvent: false,
            sort: Order.Ascendent
          }}
        >
          {({ criteria, updateCriteria, debouncedUpdate }) => (
            <Refresh>
              {({ key, refresh }) => (
                <React.Fragment>
                  <div className={style.searchAndButton}>
                    <TextInput
                      type="text"
                      id="search"
                      onChange={(event) => debouncedUpdate('search', event.target.value)}
                      label={intl.formatMessage({ id: translations.inputs.noteSearch.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.noteSearch.placeholder })}
                      autoComplete="off"
                      className={style.search}
                      leadingIcon="search"
                    />

                    <Modal
                      modal={CreateNoteModal}
                      submitFunction={(values) => createParticipantNote(match.params.id, values)}
                      onNoteCreated={refresh}
                      type="participant"
                    >
                      {({ open }) => (
                        <Button appearance="orange" onClick={open}>
                          <FormattedMessage id={translations.pages.teams.participants.details.notes.create} />
                        </Button>
                      )}
                    </Modal>
                  </div>

                  <Spacer xs={3} />

                  <div className={style.filters}>
                    <div className={style.withLabel}>
                      <label htmlFor="after" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.notes.filters.from} />
                      </label>

                      <DatePickerInput
                        id="after"
                        value={criteria.after}
                        change={(_, value) => updateCriteria('after', value)}
                        inputClassName={style.datePicker}
                      />
                    </div>

                    <div className={style.withLabel}>
                      <label htmlFor="before" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.notes.filters.until} />
                      </label>

                      <DatePickerInput
                        id="before"
                        value={criteria.before}
                        change={(_, value) => updateCriteria('before', value)}
                        inputClassName={style.datePicker}
                      />
                    </div>

                    <div className={style.withLabel}>
                      <label htmlFor="visibility-filter" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.notes.filters.visibility} />
                      </label>

                      <div className={style.select}>
                        <VisibilityFilterSelect
                          id="visibility-filter"
                          value={criteria.visibility}
                          onChange={(value) => updateCriteria('visibility', value)}
                        />
                      </div>
                    </div>

                    <div className={style.withLabel}>
                      <label htmlFor="topic-filter" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.notes.filters.topic} />
                      </label>

                      <div className={style.select}>
                        <TopicFilterSelect
                          id="topic-filter"
                          value={criteria.topic}
                          onChange={(value) => updateCriteria('topic', value)}
                        />
                      </div>
                    </div>

                    <div className={style.withLabel}>
                      <span className={style.lifeEventLabel}>
                        <FormattedMessage id={translations.pages.teams.participants.details.notes.filters.lifeEvent} />
                      </span>

                      <ToggleSwitch
                        id="life-event"
                        type="checkbox"
                        label={false}
                        checked={criteria.lifeEvent}
                        onChange={(event) => updateCriteria('lifeEvent', event.target.checked)}
                        className={style.lifeEvent}
                      />
                    </div>
                  </div>

                  <Spacer xs={2} />

                  <Ordering
                    message={translations.pages.teams.sessions.sortByDate}
                    value={criteria.sort}
                    onClick={() =>
                      updateCriteria('sort', criteria.sort === Order.Ascendent ? Order.Descendent : Order.Ascendent)
                    }
                  />

                  <Spacer xs={2} />

                  <Table
                    key={key}
                    id={`${JSON.stringify(criteria)}`}
                    renderHeader={() => <NoteHeader />}
                    empty={() => (
                      <Placeholder size="lg" image="questions" className={style.empty}>
                        <Typography is="span" type="halcyon" weight="bold">
                          <FormattedMessage id={translations.pages.teams.participants.details.notes.empty} />
                        </Typography>
                      </Placeholder>
                    )}
                    source={(page, number) =>
                      getParticipantNotes(match.params.id, page, number, {
                        before: parseISO(criteria.before).toISOString(),
                        after: parseISO(criteria.after).toISOString(),
                        keyword: criteria.search,
                        topic: criteria.topic === TopicFilter.All ? null : criteria.topic,
                        visibility: criteria.visibility,
                        lifeEvent: criteria.lifeEvent ? criteria.lifeEvent : null,
                        [Sort.By]: By.CreatedAt,
                        [Sort.Order]: criteria.sort
                      })
                    }
                    renderRow={(note: Note) => (
                      <Modal
                        key={note.id}
                        note={note}
                        modal={NoteDetailModal}
                        submitFunction={(data: UpdateNote) => updateParticipantNote(note.id, data)}
                        onNoteUpdated={refresh}
                      >
                        {({ open }) => (
                          <li className={style.note} onClick={open}>
                            <NoteRow note={note} language={language} />
                          </li>
                        )}
                      </Modal>
                    )}
                  />
                </React.Fragment>
              )}
            </Refresh>
          )}
        </FilterManager>
      </Card.Column>
    </Card.Row>
  );
};

export default withRouter(injectIntl(Notes));
