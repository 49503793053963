import React from 'react';
import queryString from 'query-string';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Role, urls } from '@/constants';
import { translations } from '@/locale';
import { DataOptionType, DataOption } from '@/domains';
import { getDataOptions } from '@/services/api/data-option';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import FilterManager from '@/components/FilterManager';
import { LanguageContext } from '@/components/Language';
import { SessionContext } from '@/components/Context/Session';
import NewDataOptionModal from '@/components/Modals/NewDataOptionModal';
import DataOptionTypeSelect from '@/components/Selects/DataOptionTypeSelect';

import DataOptionHeader from './DataOptionHeader';
import DataOptionRow from './DataOptionRow';

import style from './DataOptions.sass';

interface Props {
  dataOptionTypes?: DataOptionType[];
}

const DataOptions: React.FC<Props & RouteComponentProps & WrappedComponentProps> = ({
  dataOptionTypes,
  history,
  location
}) => {
  const [key, setKey] = React.useState(new Date().getTime());

  const updateKey = React.useCallback(() => setKey(new Date().getTime()), []);

  const { language } = React.useContext(LanguageContext);

  const { me } = React.useContext(SessionContext);

  const isValidDataTypeInUrl = Object.values(DataOptionType).includes(queryString.parse(location.search)
    .dataType as DataOptionType);

  isValidDataTypeInUrl
    ? null
    : history.replace(
        dataOptionTypes
          ? urls.admin.dataCustomisation.get(DataOptionType.ActivityType)
          : urls.dataCustomisation.tabs.get(urls.dataCustomisation.tabs.dataOptions, DataOptionType.ActivityType)
      );

  const getTranslatedText = (item: DataOption) =>
    item.translations && item.translations[language.toLowerCase()]
      ? item.translations[language.toLowerCase()]
      : item.name;

  return (
    <Card.Row className={style.root}>
      <Card.Column sm={12}>
        <FilterManager
          initialCriteria={{
            dataType: isValidDataTypeInUrl ? queryString.parse(location.search).dataType : DataOptionType.ActivityType
          }}
        >
          {({ criteria, updateCriteria }) => (
            <React.Fragment>
              <div className={style.filterAndButton}>
                <div className={style.filterContainer}>
                  <Typography is="div" type="halcyon" className={style.label}>
                    <FormattedMessage id={translations.dataCustomisation.dataOptions.dataType} />
                  </Typography>
                  <DataOptionTypeSelect
                    value={criteria.dataType}
                    data={dataOptionTypes}
                    onChange={(value) => {
                      history.replace(
                        dataOptionTypes
                          ? urls.admin.dataCustomisation.get(value.toString())
                          : urls.dataCustomisation.tabs.get(urls.dataCustomisation.tabs.dataOptions, value.toString())
                      );
                      return updateCriteria('dataType', value);
                    }}
                  />
                </div>

                <Modal modal={NewDataOptionModal} dataOptionType={criteria.dataType} onClose={updateKey}>
                  {({ open }) => (
                    <Button appearance="orange" onClick={open}>
                      <FormattedMessage id={translations.dataCustomisation.dataOptions.addNewButton} />
                    </Button>
                  )}
                </Modal>
              </div>

              <Table
                key={key}
                id={`${JSON.stringify(criteria)}`}
                source={() =>
                  getDataOptions(criteria.dataType).then((result) =>
                    result.sort((a, b) => getTranslatedText(a).localeCompare(getTranslatedText(b)))
                  )
                }
                renderHeader={() => <DataOptionHeader withDelete={me.role !== Role.SuperAdmin} />}
                renderRow={(data: DataOption) => (
                  <DataOptionRow
                    language={language}
                    key={data.id}
                    dataOption={data}
                    className={style.item}
                    withDelete={me.role !== Role.SuperAdmin}
                    onDelete={updateKey}
                  />
                )}
                withoutPagination
              />
            </React.Fragment>
          )}
        </FilterManager>
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(withRouter(DataOptions));
