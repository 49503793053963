import React from 'react';
import classnames from 'classnames';

import Picture from '@/components/Picture';
import Typography from '@/components/Typography';

import style from './Team.sass';

export interface Props {
  is?: React.ElementType;
  logo?: string;
  name?: string;
  border?: boolean;
  block?: boolean;
  hoverable?: boolean;
  className?: string;
  [x: string]: any;
}

const Team: React.FC<Props> = ({
  is: Component = 'div',
  logo,
  name,
  border,
  block,
  hoverable,
  className,
  children,
  ...rest
}) => (
  <Component
    className={classnames(
      style.root,
      { [style.border]: border, [style.block]: block, [style.hoverable]: hoverable },
      className
    )}
    {...rest}
  >
    <Picture className={style.picture} size="xs" url={logo} />

    <Typography className={style.name} is="span" type="halcyon" weight="bold">
      {name}
    </Typography>

    {children}
  </Component>
);

export default Team;
