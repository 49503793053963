import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { SkillCategory } from '@/domains';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<SkillCategory>, 'items'>, WrappedComponentProps {}

class SocialSkillCategorySelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(SkillCategory).map((category) => ({
      value: category,
      name: intl.formatMessage({ id: translations.skillCategories[category] })
    }));

    return <Select {...rest} items={items} />;
  }
}

export default injectIntl(SocialSkillCategorySelect);
