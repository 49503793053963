import React, { useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { rules, urls } from '@/constants';
import { Location, LocationState } from '@/domains';
import { translations } from '@/locale';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Picture from '@/components/Picture';
import HasRole from '@/components/HasRole';
import Typography from '@/components/Typography';
import LocationModal from '@/components/Modals/LocationModal';
import BoxedContent, { Appearance } from '@/components/BoxedContent';

import cellStyle from '../cellStyle.sass';

import style from './LocationRow.sass';

const locationStatusColor: Record<LocationState, Appearance> = {
  [LocationState.Disabled]: 'gray',
  [LocationState.Enabled]: 'green'
};

export interface Props {
  data: Location;
  refresh?: () => any;
}
const LocationRow: React.FC<Props & WrappedComponentProps> = ({ data, refresh, intl, ...rest }) => {
  const [locationState, setLocationState] = useState(data.state);

  return (
    <Card.Row className={style.root}>
      <Modal
        modal={LocationModal}
        locationId={data.id}
        onSubmit={refresh}
        locationState={locationState}
        setLocationState={setLocationState}
      >
        {({ open }) => (
          <div className={style.item} onClick={open}>
            <div className={classnames(cellStyle.place, style.place)}>
              <Picture url={data.pictureUrl} size="wide" type="location" />

              <Typography is="span" type="halcyon" weight="bold" className={style.name}>
                {data.name}
              </Typography>
            </div>
            <Typography is="span" type="hummingbird" className={cellStyle.address}>
              {data.address}
            </Typography>
            <div className={classnames(cellStyle.state, style.state)}>
              <BoxedContent appearance={locationStatusColor[locationState]}>
                <FormattedMessage id={translations.locationStatuses[locationState]} />
              </BoxedContent>
            </div>
          </div>
        )}
      </Modal>

      <HasRole to={rules.LOCATION_REPORTING}>
        <Link
          appearance="orange"
          to={{
            pathname: urls.team.locations.reports.get(data.id),
            state: {
              name: data.name
            }
          }}
          className={style.reports}
        >
          <FormattedMessage id={translations.inputs.locationReports} />
        </Link>
      </HasRole>
    </Card.Row>
  );
};

export default injectIntl(LocationRow);
