import React from 'react';
import classnames from 'classnames';

import Select, { Props as SelectProps } from '@/components/Select';

import style from './SimpleSelect.sass';

type AppearanceType = 'slim' | 'normal';

export interface Props<T>
  extends Omit<SelectProps<T>, 'onSelect' | 'onInputValueChange' | 'getValue' | 'getItemValue' | 'renderItems'> {
  appearance?: AppearanceType;
  label?: string;
  onInputValueChange?: (...params) => any;
}

export default class SimpleSelect<T> extends React.PureComponent<Props<T>> {
  static defaultProps: Pick<Props<any>, 'appearance' | 'onInputValueChange'> = {
    appearance: 'slim',
    onInputValueChange: () => null
  };

  render() {
    const { value, appearance, inputClassName, ...rest } = this.props;

    return (
      <Select
        value={this.props.value}
        onChange={this.props.onChange}
        onInputValueChange={this.props.onInputValueChange}
        onSelect={() => null}
        inputClassName={classnames(style[appearance!], inputClassName)}
        {...rest}
      />
    );
  }
}
