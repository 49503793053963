import {
  Location,
  MoodEvaluation,
  SkillEvaluation,
  GoalEvaluation,
  Activity,
  DataOption,
  StreetSession,
  ContactEvaluation
} from '@/domains';
import { DaytimeOccupationEvaluation } from '@/domains';

export enum EvaluationCategory {
  Mood = 'MOOD',
  Skill = 'SKILL',
  Goal = 'GOAL',
  Occupation = 'OCCUPATION',
  Contact = 'CONTACT'
}

export enum SelfEvaluationCategory {
  Mood = 'MOOD',
  Skills = 'SKILLS',
  Goals = 'GOALS',
  SocialMap = 'SOCIAL_MAP'
}

export enum EvaluationStatus {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export interface Evaluation extends Omit<StreetSession, 'teamMembers' | 'participants'> {
  id: string;
  location: Location;
  sessionType: DataOption;
  start: string;
  end: string;
  evaluations: EvaluationCategory[];
}

export interface EvaluationDetails {
  activities: Activity[];
  goals: GoalEvaluation[];
  moodEvaluation: MoodEvaluation;
  moodEvaluationOfParticipant?: MoodEvaluation;
  participantId: string;
  participantNickname: string;
  sessionId: string;
  sessionName: string;
  skillEvaluations: SkillEvaluation[];
  skillEvaluationsOfParticipant?: SkillEvaluation[];
  daytimeOccupationEvaluations: DaytimeOccupationEvaluation[];
  participantContactEvaluations: ContactEvaluation[];
}
