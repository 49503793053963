import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { DataOptionType } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<DataOptionType>, 'items'>, WrappedComponentProps {
  data?: DataOptionType[];
}

const nonCustomizableDataOptions = [DataOptionType.SelfEvaluation];

class DataOptionTypeSelect extends React.PureComponent<Props> {
  render() {
    const { data, intl, ...rest } = this.props;

    const values = data || Object.values(DataOptionType);

    const items = values
      .filter((value) => !nonCustomizableDataOptions.includes(value))
      .map((type) => ({
        value: type,
        name: intl.formatMessage({ id: translations.dataOptions[type] })
      }));

    return <SimpleSelect {...rest} items={items} />;
  }
}

export default injectIntl(DataOptionTypeSelect);
