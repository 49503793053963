import React from 'react';
import { RouteComponentProps, withRouter, Switch, Route, Redirect } from 'react-router-dom';

import { urls } from '@/constants';

import Spacer from '@/components/Spacer';
import { AppContext } from '@/components/Context/App';
import EntityPreview from '@/components/EntityPreview';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import Detail from './Detail';
import Create from './Create';

import style from './Survey.sass';

interface Params {
  id: string;
}

export interface Props {
  initialValues: any;
}

const Survey: React.FC<Props & RouteComponentProps<Params>> = () => {
  const { team } = React.useContext(AppContext);

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!team && <EntityPreview logo={team.imageUrl} name={team.name} />}

      <Spacer xs={4} />

      <Switch>
        <Route exact path={urls.digitalCoach.survey.new} component={Create} />

        <Route exact path={urls.digitalCoach.survey.detail} component={Detail} />

        <ConsumeRedirectRoute render={() => <Redirect to={urls.digitalCoach.coach} />} />
      </Switch>
    </div>
  );
};

export default withRouter(Survey);
