import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { ParticipantProfileField, State as FieldState } from '@/domains';
import { updateParticipantProfileField } from '@/services/api/organization';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import ToggleSwitch from '@/components/ToggleSwitch';
import LiveSubmit from '@/components/LiveSubmit';

import cellStyle from '../cellStyle.sass';

export interface Props {
  data: ParticipantProfileField;
  className?: string;
}

const ParticipantProfileFieldRow: React.FC<Props> = ({ data: { participantField, state }, className, ...rest }) => (
  <Card.Row is="li" className={classnames(cellStyle.container, className)} {...rest}>
    <Typography is="div" type="sparrow" weight="bold" className={cellStyle.profileFieldName}>
      <FormattedMessage id={translations.participantProfileFieldTypes[participantField]} />
    </Typography>

    <div className={cellStyle.enabled}>
      <LiveSubmit
        loading={false}
        value={{ fieldState: state }}
        onChange={({ fieldState }) => updateParticipantProfileField(participantField, fieldState)}
      >
        {({ value: { fieldState }, set }) => (
          <ToggleSwitch
            checked={fieldState === FieldState.Enabled}
            label={false}
            onClick={() =>
              set('fieldState', fieldState === FieldState.Enabled ? FieldState.Disabled : FieldState.Enabled)
            }
            onChange={() => null}
          />
        )}
      </LiveSubmit>
    </div>
  </Card.Row>
);

export default ParticipantProfileFieldRow;
