import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { Language } from '@/constants';
import Select, { Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<string>, 'items'>, WrappedComponentProps {}

class SystemLanguageSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.keys(translations.languages)
      .filter((language) => Object.values(Language).includes(language as Language))
      .map((id) => ({
        value: id.toLowerCase(),
        name: intl.formatMessage({ id: translations.languages[id] })
      }));

    return <Select {...rest} items={items} />;
  }
}

export default injectIntl(SystemLanguageSelect);
