import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { sequentialPalette } from '@/constants';
import { ReportFilters, SuperAdminReport } from '@/domains';
import { getLabelsFromPeriodAndTimeFrame, getColorsForSequentialData } from '@/util';
import { getSuperAdminReports } from '@/services/api/super-admin-reports';

import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { BarChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const UsersAndParticipationSection: React.FC<Props> = ({ intl, criteria }) => (
  <Reports>
    <LoadData load={() => getSuperAdminReports<SuperAdminReport.User>(criteria, SuperAdminReport.ReportType.User)}>
      {({ value: usersValue, loading: usersLoading }) => (
        <LoadData
          load={() =>
            getSuperAdminReports<SuperAdminReport.Participation>(criteria, SuperAdminReport.ReportType.Participation)
          }
        >
          {({ value: participationValue, loading: participationsLoading }) => (
            <If
              condition={usersLoading || participationsLoading}
              then={() => (
                <Loading visible={usersLoading || participationsLoading} center className={style.loading}>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <Reports.Charts>
                  <BarChart
                    title={intl.formatMessage({
                      id: translations.pages.superAdminReports.users.title
                    })}
                    name="number-of-users"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, usersValue.periods, criteria.timeFrame),
                      datasets: [
                        {
                          label: intl.formatMessage({
                            id: translations.pages.superAdminReports.users.label
                          }),
                          data: usersValue.users.data,
                          backgroundColor: getColorsForSequentialData(usersValue.users.data, sequentialPalette)
                        }
                      ]
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              min: 0,
                              stepSize: 1
                            }
                          }
                        ]
                      }
                    }}
                  />

                  <BarChart
                    title={intl.formatMessage({
                      id: translations.pages.superAdminReports.participation.title
                    })}
                    name="number-of-participants"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, participationValue.periodKeys, criteria.timeFrame),
                      datasets: [
                        {
                          label: intl.formatMessage({
                            id: translations.pages.superAdminReports.participation.label
                          }),
                          data: participationValue.numberOfParticipantsAttendingSessions.data,
                          backgroundColor: getColorsForSequentialData(
                            participationValue.numberOfParticipantsAttendingSessions.data,
                            sequentialPalette
                          )
                        }
                      ]
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              min: 0,
                              stepSize: 1
                            }
                          }
                        ]
                      }
                    }}
                  />
                </Reports.Charts>
              )}
            />
          )}
        </LoadData>
      )}
    </LoadData>
  </Reports>
);

export default UsersAndParticipationSection;
