import { Page, MemberBase } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const addTeamMembersToSession: (sessionId: string, memberIds) => Promise<any> = (sessionId, memberIds) =>
  authorizedApi.post(`/v1/sessions/${sessionId}/members`, { ids: memberIds });

export const removeTeamMembersFromSession: (sessionId: string, memberIds) => Promise<any> = (sessionId, memberIds) =>
  authorizedApi.delete<any>(`/v1/sessions/${sessionId}/members`, { data: { ids: memberIds } });

export const getTeamMembersForSession: (
  page: number,
  size: number,
  search: string,
  sessionId
) => Promise<Page<MemberBase>> = (pageNumber, pageSize, keyword, sessionIds) =>
  authorizedApi.get<Page<MemberBase>>(`/v1/users`, { pageNumber, pageSize, keyword, sessionIds });
