export enum Size {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl'
}

export type Magnitude = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface WithSize<T> {
  [Size.ExtraSmall]?: T;
  [Size.Small]?: T;
  [Size.Medium]?: T;
  [Size.Large]?: T;
  [Size.ExtraLarge]?: T;
}
