import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { ParticipantListItem } from '@/domains';
import { translations } from '@/locale';

import { urls } from '@/constants';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';
import style from './ParticipantRow.sass';

export interface Props {
  data: ParticipantListItem;
  language: string;
}

const ParticipantRow: React.FC<Props> = ({ data, language, ...rest }) => (
  <Card.Row is="li" className={classnames(style.item)} {...rest}>
    <Link
      to={{
        pathname: urls.participants.get(data.id),
        state: {
          name: data.nickname
        }
      }}
      className={classnames(cellStyle.container, style.wide)}
    >
      <div className={classnames(cellStyle.name, style.name)}>
        <Picture size="md" className={style.photo} url={data.imageUrl} />

        <Typography is="span" type="halcyon">
          {data.nickname ? data.nickname : '-'}
        </Typography>
      </div>

      <div className={cellStyle.gender}>
        <Typography is="span" type="halcyon">
          {data.gender ? <FormattedMessage id={translations.genders[data.gender]} /> : '-'}
        </Typography>
      </div>

      <div className={cellStyle.age}>
        <Typography is="span" type="halcyon">
          {data.age ? (
            <FormattedMessage id={translations.pages.teams.participants.list.age} values={{ years: data.age }} />
          ) : (
            '-'
          )}
        </Typography>
      </div>

      <div className={cellStyle.birthdate}>
        <Typography is="span" type="halcyon">
          {data.birthDate ? <FormattedDate value={data.birthDate} day="2-digit" month="short" year="numeric" /> : '-'}
        </Typography>
      </div>

      <div className={cellStyle.address}>
        <Typography is="span" type="halcyon">
          {data.address ? data.address : '-'}
        </Typography>
      </div>

      <div className={cellStyle.lastIntervention}>
        <Typography is="span" type="halcyon">
          {data.lastInterventionDate ? (
            <FormattedDate value={data.lastInterventionDate} day="2-digit" month="short" year="numeric" />
          ) : (
            '-'
          )}
        </Typography>
      </div>
    </Link>
  </Card.Row>
);

export default ParticipantRow;
