import {
  Page,
  InviteParticipantToTeam,
  ParticipantSessionInvitationRowItem,
  ParticipantAccountOverview
} from '@/domains';

import { authorizedApi } from '@/services/network/authorized-api';
import { Module, ParticipantRole } from '@/constants';

export const inviteParticipant: (data: InviteParticipantToTeam) => Promise<string> = (data) =>
  authorizedApi.post<any>(`/v1/participant-accounts/invitations`, data);

export const updateRoleAndModules: (
  teamId: string,
  participantId: string,
  data: { modulesAccess: Module[]; roleLevel: ParticipantRole }
) => Promise<any> = (teamId: string, participantId: string, data) =>
  authorizedApi.post<any>(`/v1/participant-accounts/roles/${participantId}/${teamId}`, data);

export const getParticipantInvitationStatusForSession: (
  sessionId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<ParticipantSessionInvitationRowItem>> = (sessionId, pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/participant-accounts/sessions/invitation-response`, {
    pageNumber,
    pageSize,
    sessionId,
    ...options
  });

export const getParticipantAppParticipants: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<ParticipantAccountOverview>> = (teamIds, pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/participant-accounts`, { teamIds, pageNumber, pageSize, ...options });

export const inviteParticipantsToSession: (sessionId: string, teamId: string, ids: string[]) => Promise<any> = (
  sessionId,
  teamId,
  ids
) =>
  authorizedApi.post(
    `/v1/sessions/${sessionId}/share`,
    {
      ids: ids.map((id) => ({
        id
      }))
    },
    { params: { teamId } }
  );

export const inviteParticipantsToSelfEvaluation: (sessionId: string, teamId: string, ids: string[]) => Promise<any> = (
  sessionId,
  teamId,
  ids
) =>
  authorizedApi.post(
    `/v1/sessions/${sessionId}/self-evaluation/share`,
    {
      ids: ids.map((id) => ({
        id
      }))
    },
    { params: { teamId } }
  );

export const deleteParticipantAppUserFromTeam: (participantAccountId: string, teamId: string) => Promise<any> = (
  participantAccountId,
  teamId
) => authorizedApi.delete<any>(`/v1/participant-accounts/remove`, { data: { participantAccountId, teamId } });

export const cancelParticipantInvitation: (invitationId: string) => Promise<any> = (invitationId) =>
  authorizedApi.delete<any>(`/v1/participant-accounts/invitations/${invitationId}`);
