import { MediaFileType, Page, State } from '@/domains';
import { CreateMessage, Message, ShareMessage, UpdateMessage } from '@/domains/message';

import { authorizedApi } from '@/services/network/authorized-api';
import { uploadImage } from './media';

export const getMessages: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<Message>> = (teamId, pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/messages`, { pageNumber, pageSize, teamIds: [teamId], ...options });

export const createMessage: (data: CreateMessage) => Promise<any> = ({ imageUrl, ...rest }) =>
  uploadImage(imageUrl, MediaFileType.ProfilePicture).then((result) =>
    authorizedApi.post<any>(`/v1/messages`, { ...rest, pictureUrl: result })
  );

export const updateMessage: (data: UpdateMessage) => Promise<any> = ({ imageUrl, ...rest }) =>
  uploadImage(imageUrl, MediaFileType.ProfilePicture).then((result) =>
    authorizedApi.put<any>(`/v1/messages/${rest.id}`, { ...rest, pictureUrl: result })
  );

export const archiveMessage: (id: string, state: State) => Promise<any> = (id, state) =>
  authorizedApi.put<any>(`/v1/messages/${id}/state`, { state: state });

export const shareMessage: (messageId: string, data: ShareMessage) => Promise<any> = (messageId, data) =>
  authorizedApi.post<any>(`/v1/messages/${messageId}/send`, data);
