import { ReportFilters, ParticipantReport, Filter } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getParticipantReports = <T>(
  participantId: string,
  teamId: string,
  { labelIds, locationIds, sessionTypeIds, ageGroupIds, ...filters }: ReportFilters,
  type: ParticipantReport.ReportType
): Promise<T> =>
  authorizedApi.get<T>(`/v1/participants/${participantId}/reports/${type}`, {
    ...filters,
    teamIds: [teamId],
    ageGroupIds: ageGroupIds.includes(Filter.All) ? null : ageGroupIds,
    labelIds: labelIds.includes(Filter.All) ? null : labelIds,
    locationIds: locationIds.includes(Filter.All) ? null : locationIds,
    sessionTypeIds: sessionTypeIds.includes(Filter.All) ? null : sessionTypeIds
  });

export const getParticipantEvaluatedSkills = <T>(
  participantId: string,
  teamId: string,
  { labelIds, locationIds, sessionTypeIds, ageGroupIds, ...filters }: ReportFilters
): Promise<T> =>
  authorizedApi.get<T>(`/v1/evaluations/participants/${participantId}/skills`, {
    ...filters,
    teamId: teamId,
    ageGroupIds: ageGroupIds.includes(Filter.All) ? null : ageGroupIds,
    labelIds: labelIds.includes(Filter.All) ? null : labelIds,
    locationIds: locationIds.includes(Filter.All) ? null : locationIds,
    sessionTypeIds: sessionTypeIds.includes(Filter.All) ? null : sessionTypeIds
  });

export const getParticipantSkillReport = <T>(
  participantId: string,
  skillId: string,
  teamId: string,
  { labelIds, locationIds, sessionTypeIds, ageGroupIds, ...filters }: ReportFilters
): Promise<T> =>
  authorizedApi.get<T>(`/v1/evaluations/participants/${participantId}/skills/${skillId}`, {
    ...filters,
    teamId,
    ageGroupIds: ageGroupIds.includes(Filter.All) ? null : ageGroupIds,
    labelIds: labelIds.includes(Filter.All) ? null : labelIds,
    locationIds: locationIds.includes(Filter.All) ? null : locationIds,
    sessionTypeIds: sessionTypeIds.includes(Filter.All) ? null : sessionTypeIds
  });

export const getParticipantDaytimeOccupationsReport = <T>(
  participantId: string,
  teamId: string,
  { labelIds, locationIds, sessionTypeIds, ageGroupIds, ...filters }: ReportFilters
): Promise<T> =>
  authorizedApi.get<T>(`/v1/participants/${participantId}/reports/daytime-occupation`, {
    teamIds: [teamId],
    ageGroupIds: ageGroupIds.includes(Filter.All) ? null : ageGroupIds,
    labelIds: labelIds.includes(Filter.All) ? null : labelIds,
    locationIds: locationIds.includes(Filter.All) ? null : locationIds,
    sessionTypeIds: sessionTypeIds.includes(Filter.All) ? null : sessionTypeIds,
    ...filters
  });
