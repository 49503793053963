import React from 'react';
import * as yup from 'yup';
import { parse } from 'query-string';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '@/constants';
import { translations } from '@/locale';
import { resetPassword, checkResetPasswordTokenValidity } from '@/services/api/session';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Checkbox from '@/components/Checkbox';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { withValidation } from '@/components/hoc/withValidation';

import questionMan from '@/assets/questionMan.png';

import style from './ResetPassword.sass';

const TextInputWithValidation = withValidation(TextInput);
const CheckboxWithValidation = withValidation(Checkbox);

const schema = yup.object({
  password: yup
    .string()
    .min(PASSWORD_MIN_LENGTH)
    .max(PASSWORD_MAX_LENGTH)
    .label(translations.inputs.password.label)
    .required(),
  confirmationPassword: yup
    .string()
    .label(translations.inputs.password.label)
    .required()
    .oneOf([yup.ref('password')], translations.validation.custom.passwordsDontMatch),
  privacyPolicy: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.privacyPolicy.label)
    .required(),
  acceptableUsePolicy: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.acceptableUsePolicy.label)
    .required()
});

export interface Props extends RouteComponentProps {}

class ResetPassword extends React.PureComponent<Props & WrappedComponentProps> {
  componentDidMount = () => {
    const token = parse(this.props.location.search).token as string;

    checkResetPasswordTokenValidity(token)
      .then((tokenValidity) => {
        if (tokenValidity.expired) this.props.history.push(urls.resetPassword.expired);
      })
      .catch(() => this.props.history.push(urls.forgotPassword.main));
  };

  render() {
    const token = parse(this.props.location.search).token as string;

    if (!token) return <Redirect to={urls.forgotPassword.main} />;

    return (
      <Card className={style.card}>
        <div className={style.topSection}>
          <img src={questionMan} className={style.image} />

          <Typography is="span" type="swan" weight="bold">
            <FormattedMessage id={translations.pages.resetPassword.title} />
          </Typography>
        </div>

        <Spacer xs={3} />

        <Form
          schema={schema}
          subscription={{ submitError: true, submitting: true }}
          onSubmit={({ password }) =>
            resetPassword({ password, resetPasswordToken: token }).then(() => this.props.history.push(urls.signIn))
          }
        >
          {({ submitError, submitting }) => (
            <div className={style.form}>
              <div className={style.inputFields}>
                <Form.Field
                  is={TextInputWithValidation}
                  id="password"
                  name="password"
                  type="password"
                  label={this.props.intl.formatMessage({ id: translations.inputs.newPassword.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.newPassword.placeholder })}
                  readOnly={submitting}
                  icon="lock"
                />

                <Spacer xs={1} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="confirmation-password"
                  name="confirmationPassword"
                  type="password"
                  label={this.props.intl.formatMessage({ id: translations.inputs.confirmedPassword.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.confirmedPassword.placeholder })}
                  readOnly={submitting}
                  icon="lock"
                />

                <Spacer xs={1} />

                <div className={style.agreements}>
                  <Form.Field
                    is={CheckboxWithValidation}
                    id="privacy-policy"
                    name="privacyPolicy"
                    appearance="square"
                    type="checkbox"
                    readOnly={submitting}
                  >
                    <Typography is="span" type="hummingbird" color="gray">
                      <FormattedMessage
                        id={translations.inputs.privacyPolicy.content}
                        values={{
                          privacy: (children) => (
                            <Link to={urls.privacyPolicy} external appearance="orange">
                              {children}
                            </Link>
                          )
                        }}
                      />
                    </Typography>
                  </Form.Field>

                  <Spacer xs={2} />

                  <Form.Field
                    is={CheckboxWithValidation}
                    id="acceptable-use-policy"
                    name="acceptableUsePolicy"
                    appearance="square"
                    type="checkbox"
                    readOnly={submitting}
                  >
                    <Typography is="span" type="hummingbird" color="gray">
                      <FormattedMessage
                        id={translations.inputs.acceptableUsePolicy.content}
                        values={{
                          policy: (children) => (
                            <Link to={urls.acceptableUsePolicy} external appearance="orange">
                              {children}
                            </Link>
                          )
                        }}
                      />
                    </Typography>
                  </Form.Field>
                </div>
              </div>

              {!!submitError && (
                <React.Fragment>
                  <SubmitError error={submitError} />

                  <Spacer xs={2} />
                </React.Fragment>
              )}

              <Button type="submit" loading={submitting} appearance="orange">
                <FormattedMessage id={translations.navigation.submit} />
              </Button>

              <Spacer xs={2} />
            </div>
          )}
        </Form>
      </Card>
    );
  }
}

export default injectIntl(ResetPassword);
