import React from 'react';
import classnames from 'classnames';

import style from './DropdownContent.sass';

export interface Props {
  className?: string;
}

export default class DropdownContent extends React.PureComponent<Props> {
  render() {
    return <div {...this.props} className={classnames(style.root, this.props.className)} />;
  }
}
