import React from 'react';
import classnames from 'classnames';

import bell from './bell.png';
import cards from './cards.png';
import images from './images.png';
import people from './people.png';
import questions from './questions.png';
import reminders from './reminders.png';

import style from './Placeholder.sass';

const imageMap: Record<ImageType, string> = {
  bell: bell,
  cards: cards,
  images: images,
  people: people,
  questions: questions,
  reminders: reminders
};

type ImageType = 'bell' | 'cards' | 'images' | 'people' | 'questions' | 'reminders';
type ImageSize = 'xs' | 'sm' | 'md' | 'lg';

export interface Props {
  image: ImageType;
  size: ImageSize;
  className?: string;
}

const Placeholder: React.FC<Props> = ({ image, size, className, children }) => (
  <div className={classnames(style.root, className)}>
    <img className={classnames(style[size!])} src={imageMap[image]} />

    {children}
  </div>
);

export default Placeholder;
