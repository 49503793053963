import React from 'react';
import classnames from 'classnames';
import { differenceInDays } from 'date-fns';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { Reminder as ReminderDomain, Topic } from '@/domains';

import { Type } from '@/components/Icon';
import Typography from '@/components/Typography';
import Participant from '@/components/Participant';
import BoxedContent from '@/components/BoxedContent';
import TextWithIcon from '@/components/TextWithIcon';

import style from './Reminder.sass';

const topicToIconMap: Record<Topic, Type> = {
  [Topic.Moods]: 'smile',
  [Topic.Skills]: 'bulb',
  [Topic.Goals]: 'flag',
  [Topic.SocialMap]: 'map',
  [Topic.PersonalInformation]: 'myTeam',
  [Topic.DaytimeOccupation]: 'occupation'
};

export interface Props {
  is?: React.ElementType;
  data: ReminderDomain;
  block?: boolean;
  hoverable?: boolean;
  className?: string;
  [x: string]: any;
}

const Reminder: React.FC<Props & WrappedComponentProps> = ({
  intl,
  is: Component = 'div',
  data,
  block,
  hoverable,
  className,
  ...rest
}) => {
  const { participant, session } = data;

  const getRelativeDate: (date: Date) => string = (date) => {
    switch (differenceInDays(new Date(), date)) {
      case 0:
        return intl.formatMessage({ id: translations.header.notificationsDropdown.reminder.createdAt.today });
      case 1:
        return intl.formatMessage({ id: translations.header.notificationsDropdown.reminder.createdAt.yesterday });
      default:
        return intl.formatDate(date, { month: 'long', day: '2-digit' });
    }
  };

  return (
    <Component
      className={classnames(style.root, { [style.block]: block, [style.hoverable]: hoverable }, className)}
      {...rest}
    >
      <Typography is="div" type="sparrow" color="gray" weight="lighter">
        <FormattedMessage
          id={translations.header.notificationsDropdown.reminder.createdAt.format}
          values={{
            day: getRelativeDate(new Date(data.createdAt)),
            time: intl.formatTime(new Date(data.createdAt))
          }}
        />
      </Typography>

      <Typography is="h4" type="halcyon" weight="bold" className={style.title}>
        {data.title}
      </Typography>

      {!!data.topic && (
        <BoxedContent appearance="blue" className={style.topicTag}>
          <TextWithIcon
            is="span"
            type="sparrow"
            color="blue"
            icon={topicToIconMap[data.topic]}
            iconColor="blue"
            iconSize="small"
          >
            <FormattedMessage id={translations.noteTopics[data.topic]} />
          </TextWithIcon>
        </BoxedContent>
      )}

      {!!participant && (
        <Participant
          picture={participant.imageUrl}
          size="sm"
          name={participant.nickname}
          birthDate={participant.birthDate}
          block
          className={style.participant}
        />
      )}

      {!!session && (
        <Typography is="div" type="sparrow" color="gray" weight="lighter" className={style.session}>
          <FormattedMessage
            id={translations.header.notificationsDropdown.reminder.sessionAndTeam}
            values={{
              span: (children) => (
                <Typography is="span" type="sparrow" color="gray" weight="bold">
                  {children}
                </Typography>
              ),
              session: session.type.name,
              team: session.team.name
            }}
          />
          <br />
          {session.location.name}
          ,&nbsp;
          <FormattedMessage
            id={translations.header.notificationsDropdown.reminder.interval}
            values={{
              start: intl.formatDate(new Date(session.start), {
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
              }),
              end: intl.formatTime(new Date(session.end))
            }}
          />
        </Typography>
      )}
    </Component>
  );
};

export default injectIntl(Reminder);
