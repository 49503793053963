import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { emailSchema } from '@/validation';
import { InviteUserToOrganization } from '@/domains';
import { inviteAdminToOrganization } from '@/services/api/super-admin';
import { inviteUserToOrganization } from '@/services/api/organization';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import RoleSelect from '@/components/Selects/RoleSelect';
import { InstanceProps } from '@/components/Modal';
import { withValidation } from '@/components/hoc/withValidation';

import style from './InviteUsersModal.sass';

const schema: yup.ObjectSchema<InviteUserToOrganization> = yup.object({
  email: emailSchema.label(translations.inputs.email.label).required(),
  role: yup
    .string()
    .label(translations.inputs.role.label)
    .required()
});

const superAdminSchema = yup.object({
  email: emailSchema.label(translations.inputs.email.label).required()
});

const TextInputWithValidation = withValidation(TextInput);
const RoleSelectWithValidation = withValidation(RoleSelect);

interface Props {
  organizationId?: string;
  onSubmit?: () => void;
}

class InviteUsersModal extends React.PureComponent<Props & InstanceProps & WrappedComponentProps> {
  render() {
    const { organizationId, onSubmit, intl, close } = this.props;

    return (
      <Card className={style.root}>
        <Card.Row>
          <Card.Column sm={12}>
            <div className={style.titleAndClose}>
              <Typography is="h6" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.inviteUsers.title} />
              </Typography>

              <Button onClick={this.props.close}>
                <BoxedIcon type="close" appearance="red" />
              </Button>
            </div>

            <Spacer xs={1} />

            <Typography className={style.subtitle} is="p" type="halcyon" color="gray">
              <FormattedMessage id={translations.modals.inviteUsers.subtitle} />
            </Typography>
          </Card.Column>
        </Card.Row>

        <Card.Row>
          <Card.Column sm={12} padded={false}>
            <Form
              id="invite-users"
              schema={organizationId ? superAdminSchema : schema}
              subscription={{ dirty: true, pristine: true, submitError: true, submitting: true }}
              onSubmit={(values) =>
                (organizationId
                  ? inviteAdminToOrganization(organizationId, values.email)
                  : inviteUserToOrganization(values as InviteUserToOrganization)
                )
                  .then(onSubmit)
                  .then(close)
              }
            >
              {({ dirty, pristine, submitError, submitting }, id) => (
                <React.Fragment>
                  <Card.Row>
                    <Card.Column sm={12}>
                      <div className={style.formRow}>
                        <Form.Field
                          className={classnames(style.email, !organizationId && style.withPadding)}
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-email`}
                          name="email"
                          label={intl.formatMessage({ id: translations.inputs.email.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                          readOnly={submitting}
                        />

                        {!organizationId && (
                          <Form.Field
                            is={RoleSelectWithValidation}
                            id={`${id}-role`}
                            name="role"
                            label={intl.formatMessage({ id: translations.inputs.role.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.role.placeholder })}
                            readOnly={submitting}
                          />
                        )}
                      </div>
                    </Card.Column>
                  </Card.Row>

                  <Card.Row>
                    {!!submitError && <SubmitError error={submitError} className={style.submitError} />}

                    <Card.Column sm={12}>
                      <Button
                        form={id}
                        type="submit"
                        className={style.sendButton}
                        loading={submitting}
                        disabled={!dirty || pristine}
                        appearance="orange"
                      >
                        <FormattedMessage id={translations.modals.inviteUsers.sendButton} />
                      </Button>
                    </Card.Column>
                  </Card.Row>
                </React.Fragment>
              )}
            </Form>
          </Card.Column>
        </Card.Row>
      </Card>
    );
  }
}
export default injectIntl(InviteUsersModal);
