import React from 'react';

import Typography from '@/components/Typography';
import Link from '@/components/Link';

import style from './Breadcrumb.sass';

interface Props {
  separator?: string;
  shape: Record<string, any>;
}

const Breadcrumb: React.FC<Props> = ({ separator = '/', shape }) => (
  <Typography is="div" type="corvus" weight="bold">
    {Object.entries(shape).map(([name, to], index) => (
      <React.Fragment key={index}>
        {index !== 0 && ` ${separator} `}

        {!!to ? (
          <Link to={to} className={style.clickableItem}>
            {name}
          </Link>
        ) : (
          <span className={style.item}>{name}</span>
        )}
      </React.Fragment>
    ))}
  </Typography>
);

export default Breadcrumb;
