import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { sequentialPalette } from '@/constants';
import { ReportFilters, SuperAdminReport } from '@/domains';
import { getLabelsFromPeriodAndTimeFrame, getColorsForSequentialData } from '@/util';
import { getSuperAdminReports } from '@/services/api/super-admin-reports';

import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { BarChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const RegistrationSection: React.FC<Props> = ({ intl, criteria }) => (
  <Reports title={intl.formatMessage({ id: translations.pages.superAdminReports.registration.title })}>
    <LoadData
      load={() =>
        getSuperAdminReports<SuperAdminReport.Registration>(criteria, SuperAdminReport.ReportType.Registration)
      }
    >
      {({ value, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <Reports.Charts>
              <BarChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.registration.moods.title
                })}
                name="number-of-evaluated-moods"
                data={{
                  labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                  datasets: [
                    {
                      label: intl.formatMessage({
                        id: translations.pages.superAdminReports.registration.moods.label
                      }),
                      data: value.moodEvaluationsInPeriods.data,
                      backgroundColor: getColorsForSequentialData(
                        value.moodEvaluationsInPeriods.data,
                        sequentialPalette
                      )
                    }
                  ]
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />

              <BarChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.registration.skills.title
                })}
                name="number-of-evaluated-skills"
                data={{
                  labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                  datasets: [
                    {
                      label: intl.formatMessage({
                        id: translations.pages.superAdminReports.registration.skills.label
                      }),
                      data: value.skillEvaluationsInPeriods.data,
                      backgroundColor: getColorsForSequentialData(
                        value.skillEvaluationsInPeriods.data,
                        sequentialPalette
                      )
                    }
                  ]
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />

              <BarChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.registration.goals.title
                })}
                name="number-of-evaluated-goals"
                data={{
                  labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                  datasets: [
                    {
                      label: intl.formatMessage({
                        id: translations.pages.superAdminReports.registration.goals.label
                      }),
                      data: value.goalEvaluationsInPeriods.data,
                      backgroundColor: getColorsForSequentialData(
                        value.goalEvaluationsInPeriods.data,
                        sequentialPalette
                      )
                    }
                  ]
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />

              <BarChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.registration.socialMap.title
                })}
                name="number-of-evaluated-contacts"
                data={{
                  labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                  datasets: [
                    {
                      label: intl.formatMessage({
                        id: translations.pages.superAdminReports.registration.socialMap.label
                      }),
                      data: value.socialMapEvaluationsInPeriods.data,
                      backgroundColor: getColorsForSequentialData(
                        value.socialMapEvaluationsInPeriods.data,
                        sequentialPalette
                      )
                    }
                  ]
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />

              <BarChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.registration.daytimeOccupation.title
                })}
                name="number-of-evaluated-daytime-occupations"
                data={{
                  labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                  datasets: [
                    {
                      label: intl.formatMessage({
                        id: translations.pages.superAdminReports.registration.daytimeOccupation.label
                      }),
                      data: value.daytimeOccupationEvaluationsInPeriods.data,
                      backgroundColor: getColorsForSequentialData(
                        value.daytimeOccupationEvaluationsInPeriods.data,
                        sequentialPalette
                      )
                    }
                  ]
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />

              <BarChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.registration.notes.title
                })}
                name="number-of-created-notes"
                data={{
                  labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                  datasets: [
                    {
                      label: intl.formatMessage({
                        id: translations.pages.superAdminReports.registration.notes.label
                      }),
                      data: value.createdNotesInPeriods.data,
                      backgroundColor: getColorsForSequentialData(value.createdNotesInPeriods.data, sequentialPalette)
                    }
                  ]
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          min: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />
            </Reports.Charts>
          )}
        />
      )}
    </LoadData>
  </Reports>
);

export default RegistrationSection;
