import React from 'react';
import classnames from 'classnames';

import Toggle, { Props as ToggleProps } from '@/components/Toggle';
import Icon from '@/components/Icon';

export type CheckboxAppearance = 'square' | 'circle';

import style from './Checkbox.sass';

interface Props extends Omit<ToggleProps, 'renderToggle'> {
  appearance: CheckboxAppearance;
}

const Checkbox: React.SFC<Props> = ({ className, ...props }) => (
  <Toggle
    {...props}
    className={classnames(style.root, { [style.disabled]: props.disabled }, className)}
    renderToggle={(toggled, disabled) => (
      <div
        className={classnames(style.mark, style[props.appearance], {
          [style.toggled]: toggled,
          [style.disabled]: disabled
        })}
      >
        <Icon type="checkmark" className={style.icon} />
      </div>
    )}
  />
);

export default Checkbox;
