import React, { useState } from 'react';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { SubmitError } from '@/components/Error';

import style from './CrudConfirmationModal.sass';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { translations } from '@/locale';

interface Props extends InstanceProps {
  message?: string;
  onConfirm?: (event: React.MouseEvent) => Promise<any>;
  onCancel?: (event: React.MouseEvent) => Promise<any>;
}

const CrudConfirmationModal: React.FC<Props> = ({ message, onConfirm, onCancel, close }) => {
  // TODO Use useState once instead of multiple times
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [error, setError] = useState();

  const loading = cancelLoading || confirmLoading;

  const handleConfirm = (event: React.MouseEvent) => {
    if (onConfirm) {
      setConfirmLoading(true);
      onConfirm(event)
        .then(() => {
          setConfirmLoading(false);
          close();
        })
        .catch((error) => {
          setConfirmLoading(false);
          setError(error);
        });
    } else {
      close();
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    if (onCancel) {
      setCancelLoading(true);
      onCancel(event)
        .then(() => {
          setCancelLoading(false);
          close();
        })
        .catch((error) => {
          setCancelLoading(false);
          setError(error);
        });
    } else {
      close();
    }
  };

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12}>
          <Button className={style.closeButton} onClick={close}>
            <BoxedIcon type="close" appearance="red" />
          </Button>

          <Spacer xs={1} />

          {message && (
            <Typography is="div" type="halcyon" className={style.message}>
              {message}
            </Typography>
          )}

          {error && <SubmitError error={error} />}
        </Card.Column>
      </Card.Row>

      <Card.Row padded className={style.buttonsContainer}>
        <Button
          appearance="ghost"
          className={style.button}
          onClick={handleCancel}
          loading={cancelLoading}
          disabled={loading}
        >
          <FormattedMessage id={translations.modals.confirmationModals.no} />
        </Button>

        <Button
          appearance="orange"
          className={style.button}
          onClick={handleConfirm}
          loading={confirmLoading}
          disabled={loading}
        >
          <FormattedMessage id={translations.modals.confirmationModals.yes} />
        </Button>
      </Card.Row>
    </Card>
  );
};

export default CrudConfirmationModal;
