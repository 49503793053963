import * as yup from 'yup';

import { translations } from '@/locale';
import { UpdateMember, Gender } from '@/domains';
import { STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';

import { emailSchema, phoneNumberSchema } from './fields';

export const userUpdateSchema: yup.ObjectSchema<UpdateMember> = yup.object({
  firstName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.firstName.label)
    .required(),
  email: emailSchema.required(),
  lastName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.lastName.label)
    .required(),
  gender: yup
    .mixed()
    .label(translations.inputs.gender.label)
    .default(Gender.Other)
    .notRequired(),
  birthDate: yup
    .string()
    .label(translations.inputs.birthDate.label)
    .nullable()
    .notRequired(),
  imageUrl: yup
    .mixed()
    .label(translations.inputs.picture.profile)
    .nullable()
    .notRequired(),
  phoneNumber: phoneNumberSchema
    .label(translations.inputs.phoneNumber.label)
    .nullable()
    .notRequired(),
  jobTitle: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.jobTitle.label)
    .notRequired(),
  role: yup.mixed().required()
});
