import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { evaluateDaytimeOccupation, deleteDaytimeOccupationEvaluation } from '@/services/api/participant-evaluations';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import { withValidation } from '@/components/hoc/withValidation';
import OccurenceStatusSelect from '@/components/Selects/OccurenceStatusSelect';

import style from './Occupations.sass';

const InputsContainer = (props) => (
  <div className={classnames(style.inputsContainer, props.className)}>{props.children}</div>
);

interface Params {
  participantId: string;
  evaluationId: string;
}

interface Props {
  language: string;
  daytimeOccupationEvaluations;
  reload;
}

const schema = yup.object().shape({
  daytimeEvaluation: yup
    .array()
    .of(
      yup.object().shape({
        daytimeOccupationOptionId: yup.string().required(),
        occurrenceStatus: yup.string().required()
      })
    )
    .required()
});

const OccurenceStatusSelectWithValidation = withValidation(OccurenceStatusSelect);

const Occupations: React.FC<Props & RouteComponentProps<Params> & WrappedComponentProps> = ({
  match,
  language,
  daytimeOccupationEvaluations,
  reload
}) => {
  const { participantId, evaluationId: sessionId } = match.params;

  return (
    <If
      condition={!!daytimeOccupationEvaluations && daytimeOccupationEvaluations.length !== 0}
      then={() => (
        <Card.Row>
          <Card.Column sm={12}>
            <div className={style.textAndDelete}>
              <Typography is="h6" type="swan" weight="bold">
                <FormattedMessage
                  id={translations.pages.teams.participants.details.evaluations.details.daytimeOccupation}
                />
              </Typography>

              <Button
                onClick={() => deleteDaytimeOccupationEvaluation(participantId, sessionId).then(reload)}
                className={style.deleteButtonWithText}
              >
                <TextWithIcon
                  is="span"
                  type="halcyon"
                  icon="bin"
                  color="red"
                  iconColor="red"
                  boxed
                  className={style.deleteText}
                >
                  <span>
                    <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.delete} />
                  </span>
                </TextWithIcon>
              </Button>
            </div>

            <Form
              id="daytimeEvaluation"
              schema={schema}
              initialValues={{
                daytimeEvaluation: daytimeOccupationEvaluations.map((item) => ({
                  daytimeOccupationOptionId: `${item.daytimeOccupation.id}`,
                  occurrenceStatus: item.occurrenceStatus
                }))
              }}
              subscription={{
                values: true,
                dirty: true,
                pristine: true,
                submitting: true,
                submitError: true,
                valid: true
              }}
              onSubmit={(data) => evaluateDaytimeOccupation(participantId, sessionId, data)}
              mutators={{ ...arrayMutators }}
            >
              {({
                form: {
                  mutators: { push }
                },
                submitting,
                valid,
                dirty
              }) => (
                <InputsContainer className={style.daytimeInputs}>
                  <FieldArray name="daytimeEvaluation">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <React.Fragment key={index}>
                          <Form.Field
                            is={OccurenceStatusSelectWithValidation}
                            id={daytimeOccupationEvaluations[index].daytimeOccupation.id}
                            name={`${name}occurrenceStatus`}
                            label={
                              daytimeOccupationEvaluations[index].daytimeOccupation.translations &&
                              daytimeOccupationEvaluations[index].daytimeOccupation.translations[language.toLowerCase()]
                                ? daytimeOccupationEvaluations[index].daytimeOccupation.translations[
                                    language.toLowerCase()
                                  ]
                                : daytimeOccupationEvaluations[index].daytimeOccupation.name
                            }
                            placeholder={
                              daytimeOccupationEvaluations[index].daytimeOccupation.translations &&
                              daytimeOccupationEvaluations[index].daytimeOccupation.translations[language.toLowerCase()]
                                ? daytimeOccupationEvaluations[index].daytimeOccupation.translations[
                                    language.toLowerCase()
                                  ]
                                : daytimeOccupationEvaluations[index].daytimeOccupation.name
                            }
                            appearance="normal"
                          />

                          {/* //TODO maybe this needs better hiding */}
                          <Form.Field
                            is={TextInput}
                            type="text"
                            id={`${index}-daytimeOccupationOptionId`}
                            name={`${name}daytimeOccupationOptionId`}
                            label={'asd'}
                            placeholder="asd"
                            value={daytimeOccupationEvaluations[index].daytimeOccupation.id}
                            readOnly={submitting}
                            className={style.hidden}
                          />
                        </React.Fragment>
                      ))
                    }
                  </FieldArray>

                  <Button
                    fat
                    appearance="orange"
                    type="submit"
                    disabled={!valid || !dirty}
                    className={style.saveButton}
                  >
                    <FormattedMessage id={translations.buttons.save} />
                  </Button>
                </InputsContainer>
              )}
            </Form>
          </Card.Column>
        </Card.Row>
      )}
    />
  );
};

export default injectIntl(withRouter(Occupations));
