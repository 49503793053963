import React from 'react';
import classnames from 'classnames';

import Icon, { Type } from '@/components/Icon';
import BoxedContent from '@/components/BoxedContent';

import style from './BoxedIcon.sass';

export type Appearance = 'orange' | 'blue' | 'navigation' | 'red' | 'green' | 'orangeHollow';

export interface Props {
  type: Type;
  appearance: Appearance;
  className?: string;
  size?: 'small' | 'large';
}

export default class BoxedIcon extends React.PureComponent<Props> {
  render() {
    const { className, type, appearance, size, children, ...rest } = this.props;

    return (
      <BoxedContent
        className={classnames(size === 'small' ? style.smallContainer : style.container, className)}
        appearance={appearance}
      >
        <Icon type={type} className={classnames(style.root, style[size])} />

        {children}
      </BoxedContent>
    );
  }
}
