import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { ReportFilters, SuperAdminReport } from '@/domains';
import { getSuperAdminReports } from '@/services/api/super-admin-reports';

import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { PieChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const ParticipantsSection: React.FC<Props> = ({ intl, criteria }) => (
  <Reports title={intl.formatMessage({ id: translations.pages.superAdminReports.participants.title })}>
    <LoadData
      load={() => getSuperAdminReports<SuperAdminReport.Participant>(criteria, SuperAdminReport.ReportType.Participant)}
    >
      {({ value, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <Reports.Charts>
              <PieChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.participants.age
                })}
                name="participants-age"
                data={{
                  labels: value.ageReport.labels.map((label) =>
                    label
                      ? label
                      : intl.formatMessage({
                          id: translations.miscellaneous.notAvailable
                        })
                  ),
                  dataset: value.ageReport.data
                }}
              />

              <PieChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.participants.gender
                })}
                name="participants-gender"
                data={{
                  labels: value.genderReport.labels.map((label) =>
                    label
                      ? intl.formatMessage({
                          id: translations.genders[label]
                        })
                      : intl.formatMessage({
                          id: translations.miscellaneous.notAvailable
                        })
                  ),
                  dataset: value.genderReport.data
                }}
              />

              <PieChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.participants.nationality
                })}
                name="participants-nationality"
                data={{
                  labels: value.nationalityReport.labels.map((label) =>
                    label
                      ? intl.formatMessage({
                          id: translations.countries[label]
                        })
                      : intl.formatMessage({
                          id: translations.miscellaneous.notAvailable
                        })
                  ),
                  dataset: value.nationalityReport.data
                }}
              />

              <PieChart
                title={intl.formatMessage({
                  id: translations.pages.superAdminReports.participants.motherTongue
                })}
                name="participants-language"
                data={{
                  labels: value.languageReport.labels.map((label) =>
                    label
                      ? intl.formatMessage({
                          id: translations.languages[label.toUpperCase()]
                        })
                      : intl.formatMessage({
                          id: translations.miscellaneous.notAvailable
                        })
                  ),
                  dataset: value.languageReport.data
                }}
              />
            </Reports.Charts>
          )}
        />
      )}
    </LoadData>
  </Reports>
);

export default ParticipantsSection;
