import React from 'react';
import classnames from 'classnames';

import Button, { Props as ButtonProps } from '@/components/Button';
import Typography from '@/components/Typography';

import style from './DownloadButton.sass';

export interface Props {
  className?: string;
}

const DownloadButton: React.FC<Props & ButtonProps> = ({ children, className, ...rest }) => {
  return (
    <Button {...rest} className={classnames(style.root, className)}>
      <Typography is="span" type="halcyon">
        {children}
      </Typography>
    </Button>
  );
};

export default DownloadButton;
