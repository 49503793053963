import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Filter, State } from '@/domains';
import { translations } from '@/locale';
import { getMyTeams } from '@/services/api/team';

import LoadableSelect, { Props as LoadableSelectProps } from '../LoadableSelect';

interface Props extends Omit<LoadableSelectProps, 'source'> {
  showOnlyActive: boolean;
}

const TeamSimpleSelect: React.FC<Props & WrappedComponentProps> = ({ intl, showOnlyActive, ...props }) => (
  <LoadableSelect
    source={() =>
      getMyTeams(1, 500).then((page) =>
        [{ id: Filter.All as string, name: intl.formatMessage({ id: translations.miscellaneous.all }) }].concat(
          page.content
            .filter((item) => {
              return showOnlyActive === true ? item.state === State.Enabled : true;
            })
            .map(({ id, name }) => ({ id, name }))
        )
      )
    }
    {...props}
  />
);

export default injectIntl(TeamSimpleSelect);
