import {
  Page,
  Evaluation,
  EvaluationDetails,
  EvaluateMood,
  EvaluateSkill,
  EvaluationStatus,
  EvaluateDaytimeOccupation,
  EvaluateContact,
  Sort,
  By
} from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

import { Order } from '@/components/Ordering';

export const getParticipantEvaluations: (
  page: number,
  size: number,
  teamId: string,
  participantId: string,
  search: string
) => Promise<Page<Evaluation>> = (pageNumber, pageSize, teamId, participantId, keyword) =>
  authorizedApi.get<Page<Evaluation>>(`/v1/sessions`, {
    pageNumber,
    pageSize,
    participantId,
    teamIds: [teamId],
    keyword,
    extraData: ['EVALUATION'],
    [Sort.By]: [By.EndDate],
    [Sort.Order]: [Order.Descendent]
  });

export const getParticipantEvaluationInSession: (
  sessionId: string,
  participantId: string
) => Promise<EvaluationDetails> = (sessionId, participantId) =>
  authorizedApi.get<EvaluationDetails>(`/v1/evaluations/sessions/${sessionId}/participants/${participantId}`);

export const evaluateMood: (participantId: string, sessionId: string, evaluation: EvaluateMood) => Promise<any> = (
  participantId,
  sessionId,
  evaluation
) => authorizedApi.put(`/v1/evaluations/moods`, { participantId, sessionId, ...evaluation });

export const evaluateSkill: (participantId: string, sessionId: string, evaluation: EvaluateSkill) => Promise<any> = (
  participantId,
  sessionId,
  evaluation
) => authorizedApi.put(`/v1/evaluations/skills`, { participantId, sessionId, ...evaluation });

export const deleteSkillEvaluation: (evaluationId: string) => Promise<any> = (evaluationId) =>
  authorizedApi.delete(`/v1/evaluations/skills/${evaluationId}`);

export const evaluateGoal: (sessionId: string, goalId: string, evaluationStatus: EvaluationStatus) => Promise<any> = (
  sessionId,
  goalId,
  evaluationStatus
) => authorizedApi.put(`/v1/evaluations/goals`, { sessionId, goalId, evaluationStatus });

export const deleteGoalEvaluation: (evaluationId: string) => Promise<any> = (evaluationId) =>
  authorizedApi.delete(`/v1/evaluations/goals/${evaluationId}`);

export const evaluateDaytimeOccupation: (
  participantId: string,
  sessionId: string,
  evaluation: EvaluateDaytimeOccupation
) => Promise<any> = (participantId, sessionId, evaluation) =>
  authorizedApi.put(`/v1/evaluations/daytime-occupation`, {
    participantId,
    sessionId,
    ...evaluation
  });

export const deleteDaytimeOccupationEvaluation: (participantId: string, sessionId: string) => Promise<any> = (
  participantId,
  sessionId
) => authorizedApi.delete(`/v1/evaluations/daytime-occupation/participant/${participantId}/session/${sessionId}`);

export const evaluateContact: (contactId: string, sessionId: string, evaluation: EvaluateContact) => Promise<any> = (
  participantContactId,
  sessionId,
  evaluation
) => authorizedApi.put(`/v1/evaluations/participant-contact`, { participantContactId, sessionId, ...evaluation });

export const deleteContactEvaluation: (evaluationId: string) => Promise<any> = (evaluationId) =>
  authorizedApi.delete(`/v1/evaluations/participant-contact/${evaluationId}`);
