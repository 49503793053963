import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { TeamBase, TeamActive } from '@/domains';
import { getMyTeamsWithSharingAccess } from '@/services/api/team';

import { InstanceProps } from '@/components/Modal';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

interface Props<T> extends InstanceProps {
  initialTeams: TeamActive[];
  submitDestination: (...params) => Promise<T>;
}

class EditTeamAccessModal extends React.PureComponent<Props<TeamActive[]> & WrappedComponentProps> {
  extractParticipantData = ({ id, name: topText, imageUrl: photoUrl }: TeamBase): SelectableItemData => ({
    id,
    topText,
    photoUrl
  });

  render() {
    const { initialTeams, submitDestination, intl } = this.props;

    return (
      <SearchableModal close={this.props.close}>
        <SearchableModal.Header
          title={intl.formatMessage({ id: translations.modals.searchableModal.editParticipantTeamAccess.title })}
          subtitle={intl.formatMessage({
            id: translations.modals.searchableModal.editParticipantTeamAccess.subtitle
          })}
        />

        <SearchableModal.Content
          close={this.props.close}
          searchLabel={intl.formatMessage({ id: translations.inputs.teamSearch.label })}
          searchPlaceholder={intl.formatMessage({ id: translations.inputs.teamSearch.label })}
          dataSource={getMyTeamsWithSharingAccess}
          initialValues={{ selectedItems: initialTeams.map(({ id }) => id) }}
          submitDestination={submitDestination}
          dataExtractionFunction={this.extractParticipantData}
        />
      </SearchableModal>
    );
  }
}
export default injectIntl(EditTeamAccessModal);
