import React from 'react';
import { WrappedComponentProps, injectIntl, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { inviteParticipantsToSurvey } from '@/services/api/survey';
import { InvitationToAppStatus, ParticipantAccountOverview } from '@/domains';
import { getParticipantAppParticipants } from '@/services/api/participant-digital-coach';

import { InstanceProps } from '@/components/Modal';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

interface Props extends InstanceProps {
  teamId: string;
  surveyId: string;
  onSubmit: () => void;
}

const AddParticipantsToSurveyModal: React.FC<Props> = ({ teamId, surveyId, onSubmit, close }) => {
  const intl = useIntl();

  const extractParticipantData = (data: ParticipantAccountOverview): SelectableItemData => ({
    id: data.id,
    topText: data.participant.nickname,
    bottomText: data.participant.birthDate
      ? intl.formatMessage(
          { id: translations.pages.sessions.details.tabs.participants.content.table.age },
          { age: getAgeFromBirthdate(data.participant.birthDate) }
        )
      : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge }),
    photoUrl: data.participant.imageUrl
  });

  return (
    <SearchableModal close={close}>
      <SearchableModal.Header
        title={intl.formatMessage({ id: translations.modals.searchableModal.participantsToSurvey.title })}
        subtitle={intl.formatMessage({ id: translations.modals.searchableModal.participantsToSurvey.subtitle })}
      />
      <SearchableModal.Content
        close={close}
        searchLabel={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
        searchPlaceholder={intl.formatMessage({ id: translations.inputs.participantSearch.placeholder })}
        dataSource={(page, number, criteria) =>
          getParticipantAppParticipants(teamId, page, number, {
            keyword: criteria,
            status: InvitationToAppStatus.Accepted
          })
        }
        submitDestination={(values) =>
          inviteParticipantsToSurvey(surveyId, teamId, values.selectedItems).then(onSubmit)
        }
        dataExtractionFunction={extractParticipantData}
      />
    </SearchableModal>
  );
};
export default AddParticipantsToSurveyModal;
