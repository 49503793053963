import React from 'react';
import { isEqual } from 'date-fns';

import { getWeekdayLabels, getDays } from '@/util';

import Typography from '@/components/Typography';

import Day from '../Day';

import style from './Month.sass';

const weekdayLabels = getWeekdayLabels();

export interface Props {
  year: number;
  month: number;
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
}

class Month extends React.PureComponent<Props> {
  render() {
    const days = getDays({ year: this.props.year, month: this.props.month });
    return (
      <React.Fragment>
        <div className={style.weekdayLabels}>
          {weekdayLabels.map((dayLabel) => (
            <Typography is="span" type="hummingbird" color="gray" key={dayLabel} className={style.dayLabel}>
              {dayLabel}
            </Typography>
          ))}
        </div>

        <div className={style.daysContainer}>
          {days.map((day, index) => (
            <div className={style.day} key={`day-${index}`}>
              {typeof day === 'object' ? (
                <Day
                  date={day.date}
                  dayNumber={day.dayNumber}
                  isSelected={isEqual(day.date, this.props.selectedDate)}
                  onSelectDate={this.props.onSelectDate}
                />
              ) : null}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default Month;
