import React from 'react';
import { WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { goalStatusPalette, qualitativePalette } from '@/constants';
import { ParticipantReport, TopicFilter, ReportFilters } from '@/domains';
import { getLabelsFromPeriodAndTimeFrame } from '@/util';
import { getParticipantReports } from '@/services/api/participant-reports';

import If from '@/components/If';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import { LanguageContext } from '@/components/Language';
import { StackedBarChart, PieChart } from '@/components/Charts';

import style from '../Reports.sass';

export interface Props extends WrappedComponentProps {
  participantId: string;
  teamId: string;
  criteria: ReportFilters;
}

const GoalSection: React.FC<Props> = ({ intl, participantId, teamId, criteria }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.teams.participants.details.reports.goals.title
      })}
    >
      <LoadData
        key={JSON.stringify(criteria)}
        load={() =>
          getParticipantReports<ParticipantReport.Goal>(
            participantId,
            teamId,
            criteria,
            ParticipantReport.ReportType.Goal
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <React.Fragment>
                <Reports.Charts>
                  <StackedBarChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.goals.startedAndEnded.title
                    })}
                    name="started-and-ended-goals"
                    data={{
                      labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                      datasets: [
                        ...value.goalsCompletedByCompletionStatus.entries.map((entry, index) => ({
                          label: intl.formatMessage({
                            id: translations.goalState[entry.label.identifier]
                          }),
                          data: entry.data,
                          stack: 'Stack 0',
                          backgroundColor: qualitativePalette[index]
                        })),
                        {
                          label: intl.formatMessage({
                            id: translations.pages.teams.participants.details.reports.goals.startedAndEnded.started
                          }),
                          stack: 'Stack 1',
                          data: value.goalsStarted.data,
                          backgroundColor: qualitativePalette[value.goalsCompletedByCompletionStatus.entries.length]
                        }
                      ]
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              min: 0,
                              stepSize: 1
                            }
                          }
                        ]
                      }
                    }}
                  />

                  <PieChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.goals.goalTypes
                    })}
                    name="goal-types"
                    data={{
                      labels: value.goalTypesUsed.labels.map((label) =>
                        label.translations && label.translations[language.toLowerCase()]
                          ? label.translations[language.toLowerCase()]
                          : label.name
                      ),
                      dataset: value.goalTypesUsed.data
                    }}
                  />

                  <PieChart
                    title={intl.formatMessage({
                      id: translations.pages.teams.participants.details.reports.goals.evaluationStatuses
                    })}
                    name="goal-evaluations-statuses"
                    data={{
                      labels: value.goalEvaluationStatusesProportion.labels.map((label) =>
                        intl.formatMessage({ id: translations.evaluationStatuses[label] })
                      ),
                      dataset: value.goalEvaluationStatusesProportion.data
                    }}
                    pallete={goalStatusPalette}
                  />
                </Reports.Charts>

                <Spacer xs={2} />

                <Typography is="h6" type="halcyon" color="gray">
                  <FormattedMessage
                    id={translations.pages.teams.participants.details.reports.goals.averageDuration}
                    values={{ number: value.averageDurationInWeeks }}
                  />
                </Typography>
              </React.Fragment>
            )}
          />
        )}
      </LoadData>

      <Spacer xs={2} />

      <Reports.Notes criteria={criteria} participantId={participantId} topic={TopicFilter.Goals} />
    </Reports>
  );
};

export default GoalSection;
