import { init, captureException, configureScope } from '@sentry/browser';

import { SENTRY_DSN, ENVIRONMENT, RELEASE } from '@/config';

class CrashService {
  private instance: Promise<void> | null = null;

  getInstance = () => {
    if (this.instance) return this.instance;

    if (!SENTRY_DSN) return Promise.reject(new Error(`Crash reporter is not configured.`));

    init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: RELEASE
    });

    this.instance = Promise.resolve();

    return this.instance;
  };

  private run(callback: () => any) {
    return this.getInstance()
      .then(callback)
      .catch(() => null);
  }

  initialize = () => this.run(() => null);

  report = (error: any, options?: Object) => {
    return this.run(() => {
      configureScope((scope) => {
        if (options) scope.setExtras(options);
        captureException(error);
      });
    });
  };

  throw = (error: Error, options?: Object) => this.report(error, options).then(() => Promise.reject(error));
}

export const crash = new CrashService();
