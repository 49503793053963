import React from 'react';
import * as yup from 'yup';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { rules, urls } from '@/constants';
import { translations } from '@/locale';
import { getISOString, getTime, getDateFormat } from '@/util';
import { UpdateStreetSession, StreetSession } from '@/domains';
import { uploadSessionPhotos, deleteSessionImage } from '@/services/api/street-session-images';
import { getStreetSessionDetails, updateStreetSession, deleteStreetSession } from '@/services/api/street-session';

import If from '@/components/If';
import Form from '@/components/Form';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import Placeholder from '@/components/Placeholder';
import { dateFormat } from '@/components/DateInput';
import TextWithIcon from '@/components/TextWithIcon';
import PhotoGallery from '@/components/PhotoGallery';
import { AppConsumer } from '@/components/Context/App';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';
import StreetSessionDetailFields from '@/page/Team/StreetSessions/StreetSessionDetailFields';
import StreetSessionTags from '@/page/Team/StreetSessions/StreetSessionTags';
import HasRole from '@/components/HasRole';

import NoteContainer from './NoteContainer';
import SurveyOverview from './SurveyOverview';

import style from './BaseDetails.sass';

export const schema = yup.object({
  locationId: yup
    .string()
    .label(translations.inputs.location.label)
    .required(),
  dataOptionId: yup
    .string()
    .label(translations.inputs.sessionType.label)
    .required(),
  startDate: yup.string().required(),
  startTime: yup
    .string()
    .label(translations.inputs.startTime.label)
    .required(),
  endTime: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startTime: string = this.resolve(yup.ref('startTime'));

      if (!startTime) return true;

      return startTime.localeCompare(value) === -1;
    })
    .label(translations.inputs.endTime.label)
    .required(),
  attachment: yup
    .string()
    .label(translations.inputs.sessionAttachment.label)
    .nullable()
    .notRequired()
});

export interface Props {
  session: StreetSession;
}

class BaseDetails extends React.PureComponent<Props & RouteComponentProps & WrappedComponentProps> {
  updateDetails = (id: string, values) => {
    const { startDate, startTime, endTime, ...restValues } = values;
    const start = getISOString(`${startDate} ${startTime}`, `${dateFormat} HH:mm`);
    const end = getISOString(`${startDate} ${endTime}`, `${dateFormat} HH:mm`);

    const streetSession: UpdateStreetSession = {
      start,
      end,
      ...restValues
    };

    return updateStreetSession(id, streetSession);
  };

  render() {
    const { session, history } = this.props;
    return (
      <div className={style.root}>
        <LoadData load={() => getStreetSessionDetails(session.id, 'IMAGES')} initialValues={session}>
          {({ value, loading, reload }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center overlay>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <If
                  condition={!value}
                  then={() => 'Not found'}
                  else={() => {
                    const initialValues = {
                      dataOptionId: value.sessionType.id,
                      locationId: value.location.id,
                      startDate: getDateFormat(value.start, dateFormat),
                      startTime: getTime(value.start),
                      endTime: getTime(value.end),
                      attachment: value.attachment
                    };
                    return (
                      <React.Fragment>
                        <AppConsumer>
                          {({ team }) => (
                            <Form
                              id="edit-street-session"
                              initialValues={initialValues}
                              subscription={{ errors: true, submitting: true, dirty: true, valid: true }}
                              schema={schema}
                              onSubmit={(values) =>
                                this.updateDetails(value.id, { ...values, teamId: team.id }).then(reload)
                              }
                              className={style.form}
                            >
                              {({ form, submitError, submitting, dirty }, id) => (
                                <React.Fragment>
                                  <div>
                                    <Spacer xs={1} />
                                    <Typography is="span" type="halcyon" weight="bold">
                                      <FormattedMessage
                                        id={translations.pages.sessions.details.tabs.details.sessionDetails}
                                      />
                                    </Typography>

                                    <StreetSessionDetailFields
                                      formId={id}
                                      readonly={submitting}
                                      onFormChange={form.change}
                                    />

                                    <Spacer xs={1} />

                                    <HasRole to={rules.SESSION_LABELS}>
                                      <StreetSessionTags initialLabels={value.labels} sessionId={value.id} />
                                    </HasRole>

                                    {!!submitError && (
                                      <React.Fragment>
                                        <SubmitError error={submitError} />

                                        <Spacer xs={2} />
                                      </React.Fragment>
                                    )}
                                  </div>

                                  <div className={style.buttonsContainer}>
                                    <Modal
                                      modal={CrudConfirmationModal}
                                      message={this.props.intl.formatMessage({
                                        id: translations.modals.confirmationModals.remove
                                      })}
                                      onConfirm={() =>
                                        deleteStreetSession(value.id).then(() =>
                                          this.props.history.push(urls.sessions.list)
                                        )
                                      }
                                    >
                                      {({ open }) => (
                                        <Button type="button" className={style.deleteSession} onClick={open}>
                                          <TextWithIcon is="span" type="hummingbird" icon="bin" boxed iconColor="red">
                                            <FormattedMessage id={translations.pages.sessions.details.delete} />
                                          </TextWithIcon>
                                        </Button>
                                      )}
                                    </Modal>

                                    <Button
                                      type="submit"
                                      appearance="orange"
                                      className={style.saveButton}
                                      disabled={!dirty}
                                      loading={submitting}
                                    >
                                      <FormattedMessage id={translations.inputs.save} />
                                    </Button>
                                  </div>

                                  <Spacer xs={8} />

                                  <Typography is="span" type="halcyon" weight="bold">
                                    <FormattedMessage
                                      id={translations.pages.sessions.details.tabs.details.sessionFeedback.title}
                                    />
                                  </Typography>

                                  <Spacer xs={3} />

                                  <If
                                    condition={session.surveyId === null}
                                    then={() => (
                                      <Button
                                        type="button"
                                        className={style.addSurveyButton}
                                        onClick={() => history.push(urls.sessions.feedback.getCreateUrl(session.id))}
                                      >
                                        <BoxedIcon type="plus" appearance="orange" className={style.plusIcon} />
                                        <Typography is="span" type="halcyon" color="orange">
                                          <FormattedMessage
                                            id={translations.pages.sessions.details.tabs.details.sessionFeedback.button}
                                          />
                                        </Typography>
                                      </Button>
                                    )}
                                    else={() => <SurveyOverview surveyId={session.surveyId} />}
                                  />
                                </React.Fragment>
                              )}
                            </Form>
                          )}
                        </AppConsumer>

                        <div className={style.rightColumn}>
                          <div className={style.container}>
                            <Spacer xs={1} />

                            <PhotoGallery
                              size="lg"
                              images={value.images}
                              loading={loading}
                              empty={() => (
                                <Placeholder image="questions" size="sm">
                                  <Typography is="span" type="halcyon">
                                    <FormattedMessage id={translations.pages.sessions.details.tabs.details.noImages} />
                                  </Typography>
                                </Placeholder>
                              )}
                              onPhotosUpload={(photos) => uploadSessionPhotos(value.id, photos).then(() => reload())}
                              onPhotoDelete={(id: string) => deleteSessionImage(id).then(reload)}
                            />

                            <NoteContainer sessionId={value.id} notes={value.notes} onChange={reload} />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }}
                />
              )}
            />
          )}
        </LoadData>
      </div>
    );
  }
}

export default injectIntl(withRouter(BaseDetails));
