import React from 'react';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { HttpError } from '@/services/network/http';
import { translations } from '@/locale';

import Typography from '@/components/Typography';

import style from './Error.sass';

interface Props {
  error: HttpError<any> | undefined | null;
  className?: string;
}

const Error: React.FC<Props & WrappedComponentProps> = ({ intl, error, children, className }) => {
  if (!error) return null;

  let validErrors = null;

  if (error.isAxiosError && error.response.status === 403)
    validErrors = [intl.formatMessage({ id: translations.errors.api.forbidden })];
  else {
    const errors =
      error.isAxiosError && error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors.map(({ code }) =>
            translations.errors.api[code] ? intl.formatMessage({ id: translations.errors.api[code] }) : null
          )
        : [error.message];

    validErrors = errors.filter((error) => !!error);
  }

  return (
    <React.Fragment>
      <Typography className={classnames(style.root, className)} is="div" type="hummingbird" color="red">
        {validErrors.length > 0
          ? validErrors.map((error, index) => <div key={index}>{error}</div>)
          : intl.formatMessage({ id: translations.errors.custom.unknown })}
      </Typography>

      {children}
    </React.Fragment>
  );
};

export const SubmitError = injectIntl(Error);
