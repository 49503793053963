import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedDate } from 'react-intl';

import { Note } from '@/domains';
import { translations } from '@/locale';
import { getDateFormat } from '@/util/date';

import Picture from '@/components/Picture';
import Typography from '@/components/Typography';

import style from './SessionNote.sass';

export interface Props {
  data: Note;
}

class SessionNote extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { intl, data } = this.props;
    return (
      <div className={style.root}>
        <div className={style.profileSection}>
          <Picture url={data.createdBy.imageUrl} size="xxs" className={style.profilePhoto} />
          <Typography is="span" type="hummingbird" color="gray">
            <FormattedMessage
              id={translations.pages.sessions.details.tabs.details.noteTitle}
              values={{
                username: `${data.createdBy.firstName} ${data.createdBy.lastName}`,
                title: `${intl.formatMessage({ id: translations.roles[data.createdBy.role] })}`
              }}
            />
          </Typography>
        </div>

        <div className={style.textSection}>
          {data.title && (
            <Typography is="span" type="hummingbird" weight="bold">
              {data.title}
            </Typography>
          )}
          {data.description && (
            <Typography is="span" type="hummingbird" className={style.content}>
              {data.description}
            </Typography>
          )}
        </div>
        {data.createdAt && (
          <Typography is="span" type="hummingbird" color="gray">
            <FormattedDate value={data.createdAt} day="2-digit" month="long" year="numeric" />
          </Typography>
        )}
      </div>
    );
  }
}

export default injectIntl(SessionNote);
