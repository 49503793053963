import React, { PureComponent } from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';

import { generateRedirectUrl } from '@/components/Navigation/redirect';

export interface Props extends RouteComponentProps {
  to: string;
}

class RedirectWithPersist extends PureComponent<Props> {
  render() {
    return (
      <Redirect
        {...this.props}
        to={generateRedirectUrl(`${this.props.location.pathname}${this.props.location.search}`, this.props.to)}
      />
    );
  }
}

export default withRouter(RedirectWithPersist);
