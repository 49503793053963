import React from 'react';

import { Team } from '@/domains';
import { storage } from '@/services';
import { getTeamDetails } from '@/services/api/team';

interface AppState {
  readonly team: Team;
  readonly loading: boolean;
}

interface AppActions {
  readonly selectTeam: (id: string) => void;
  readonly unselectTeam: () => void;
}

export const AppContext = React.createContext<AppState & AppActions>({} as any);

interface Props {
  team: Team;
}

interface State extends AppState, AppActions {}

export const AppConsumer = AppContext.Consumer;

export class AppProvider extends React.PureComponent<Props, State> implements AppActions {
  selectTeam = (id: string) => this.loadTeam(id);

  unselectTeam = () => this.setState({ team: null }, this.onTeamLoaded);

  state: State = {
    team: null,
    loading: true,
    selectTeam: this.selectTeam,
    unselectTeam: this.unselectTeam
  };

  componentDidMount = () => {
    const teamId: string = storage.getItem<string | null>('selected-team');

    if (!!teamId)
      getTeamDetails(teamId)
        .then((team) => this.setState({ team: team, loading: false }, this.onTeamLoaded))
        .catch(this.tryLoadingTeamFromProps);
    else this.tryLoadingTeamFromProps();
  };

  tryLoadingTeamFromProps = () => {
    if (this.props.team) this.setState({ team: this.props.team, loading: false }, this.onTeamLoaded);
    else this.setState({ loading: false });
  };

  loadTeam = (id: string) =>
    getTeamDetails(id)
      .then((team) => this.setState({ team: team, loading: false }, this.onTeamLoaded))
      .catch(() => this.setState({ loading: false }, this.onTeamLoaded));

  onTeamLoaded = () => storage.setItem('selected-team', this.state.team ? this.state.team.id : null);

  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}

export default {
  AppProvider,
  AppConsumer,
  AppContext
};
