import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { SkillEvaluationLearningStatus } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<SkillEvaluationLearningStatus>, 'items'>, WrappedComponentProps {}

class LearningStatusSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(SkillEvaluationLearningStatus).map((status) => ({
      value: status,
      name: intl.formatMessage({ id: translations.evaluationStatuses[status] })
    }));

    return (
      <SimpleSelect
        appearance="normal"
        label={intl.formatMessage({ id: translations.inputs.learningProgress.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.learningProgress.placeholder })}
        {...rest}
        items={items}
      />
    );
  }
}

export default injectIntl(LearningStatusSelect);
