import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

interface Props {}

const LegalDocumentHeader: React.FC<Props> = () => {
  const { name, dataOptions, enabled } = translations.dataCustomisation.profileFields.profileFieldsHeader;
  return (
    <Card.Row className={cellStyle.header}>
      <Typography is="div" type="halcyon" color="gray" className={cellStyle.legalInformationFieldName}>
        <FormattedMessage id={name} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.legalInformationFieldLink}>
        <FormattedMessage id={dataOptions} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.legalInformationFieldEnabled}>
        <FormattedMessage id={enabled} />
      </Typography>
    </Card.Row>
  );
};

export default LegalDocumentHeader;
