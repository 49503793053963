export enum KPIDomain {
  Participant = 'PARTICIPANT',
  Session = 'SESSION',
  Activity = 'ACTIVITY'
}

export enum KPIPeriod {
  Monthly = 'MONTH',
  Yearly = 'YEAR'
}

export interface KPIItem {
  domain: KPIDomain;
  current: number;
  deviation: number;
}

export interface KPI {
  current: number;
  previous: number;
}

export interface OrganizationKPIs {
  activities: KPI;
  participants: KPI;
  sessions: KPI;
}
