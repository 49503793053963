import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { sequentialPalette, qualitativePalette } from '@/constants';
import { OrganizationReport, ReportFilters } from '@/domains';
import { getColorsForSequentialData, getLabelsFromPeriodAndTimeFrame, getColorFromPalleteByIndex } from '@/util';
import { getOrganizationReports } from '@/services/api/organization-reports';

import If from '@/components/If';
import Reports from '@/components/Reports';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import { LanguageContext } from '@/components/Language';
import { BarChart, StackedBarChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const contactTypeTranslationsMap: Record<string, string> = {
  REGISTERED: translations.pages.sessions.details.tabs.participants.content.registeredParticipants,
  UNREGISTERED: translations.inputs.unregisteredParticipants.label
};

const SessionSection: React.FC<Props> = ({ intl, criteria }) => {
  const { language } = React.useContext(LanguageContext);
  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.main.reporting.session.title
      })}
    >
      <LoadData
        load={() => getOrganizationReports<OrganizationReport.Session>(criteria, OrganizationReport.ReportType.Session)}
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Reports.Charts>
                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.main.reporting.session.number
                  })}
                  name="number-of-sessions"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: value.sessions.entries.map((entry, index) => ({
                      stack: 'stack 0',
                      data: entry.data,
                      label:
                        entry.label.translations && entry.label.translations[language.toLowerCase()]
                          ? entry.label.translations[language.toLowerCase()]
                          : entry.label.name,
                      backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                    }))
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                  className={style.wide}
                  height={279}
                />
              </Reports.Charts>
            )}
          />
        )}
      </LoadData>

      <LoadData
        load={() =>
          getOrganizationReports<OrganizationReport.SessionParticipants>(
            criteria,
            OrganizationReport.ReportType.SessionParticipants
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Reports.Charts>
                <BarChart
                  title={intl.formatMessage({
                    id: translations.pages.main.reporting.session.participants.title
                  })}
                  name="number-of-participants-in-session"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periodKeys, criteria.timeFrame),
                    datasets: [
                      {
                        label: intl.formatMessage({
                          id: translations.pages.main.reporting.session.participants.label
                        }),
                        data: value.numberOfParticipantsAttendingAtLeastOneSession.data,
                        backgroundColor: getColorsForSequentialData(
                          value.numberOfParticipantsAttendingAtLeastOneSession.data,
                          sequentialPalette
                        )
                      }
                    ]
                  }}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                />

                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.main.reporting.session.contacts.title
                  })}
                  name="number-of-contacts-in-session"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periodKeys, criteria.timeFrame),
                    datasets: value.numberOfContactsWithOrganizationTeams.entries.map((entry, index) => ({
                      stack: 'stack 0',
                      data: entry.data,
                      label: intl.formatMessage({ id: contactTypeTranslationsMap[entry.label.name] }),
                      backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                    }))
                  }}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                />
              </Reports.Charts>
            )}
          />
        )}
      </LoadData>
    </Reports>
  );
};

export default SessionSection;
