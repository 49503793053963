import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { withValidation } from '@/components/hoc/withValidation';
import Picture, { Props as PictureProps } from '@/components/Picture';
import Typography from '@/components/Typography';
import FileUpload, { Props as FileUploadProps } from '@/components/FileUpload';
import Button from '@/components/Button';

import style from './PictureUpload.sass';

export interface Props extends Pick<PictureProps, 'type'>, Omit<FileUploadProps, 'type' | 'value' | 'onChange'> {
  id: string;
  name: string;
  title: string;
  invalid: boolean;
  value: File | string | null;
  onFocus: () => any;
  onBlur: () => any;
  onChange: (file: File | null) => any;
}

interface State {
  preview: string | null;
}

class PictureUpload extends React.PureComponent<Props, State> {
  state: State = {
    preview: null
  };

  componentDidMount() {
    this.update(this.props.value);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) this.update(this.props.value);
  }

  update = (value: Props['value']) => {
    if (!value) return this.setState({ preview: null });
    if (typeof value === 'string') return this.setState({ preview: value });

    const reader = new FileReader();

    reader.onload = (event) => this.setState({ preview: event.target.result.toString() });

    reader.readAsDataURL(value);
  };

  change = (files: FileList) => {
    return this.props.onChange(files[0] || null);
  };

  clear = () => this.props.onChange(null);

  render() {
    const { invalid, title, disabled, type, ...rest } = this.props;

    return (
      <div className={style.container}>
        <Picture
          size="xl"
          type={type}
          url={this.state.preview ? this.state.preview : null}
          className={classnames(style.picture, { [style.invalid]: invalid })}
        />

        {!disabled && (
          <div className={style.controls}>
            <Typography is="span" type="halcyon" weight="bold">
              {title}
            </Typography>

            <FileUpload {...rest} multiple={false} onChange={this.change} className={style.upload}>
              <Button is="div" appearance="ghost">
                <FormattedMessage id={translations.inputs.picture.upload} />
              </Button>
            </FileUpload>

            <Button onClick={this.clear} className={style.remove} type="button">
              <FormattedMessage id={translations.inputs.picture.remove} />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withValidation(PictureUpload);
