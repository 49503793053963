import { DataOption } from './data-option';

export enum OccurenceStatus {
  Sometimes = 'SOMETIMES',
  Often = 'OFTEN',
  Always = 'ALWAYS',
  Rarely = 'RARELY',
  Never = 'NEVER'
}

export interface DaytimeOccupation extends Pick<DataOption, 'id' | 'name' | 'state'> {}

export interface DaytimeOccupationEvaluation {
  daytimeOccupation: DaytimeOccupation;
  lastEvaluatedAt: string;
  occurrenceStatus: OccurenceStatus;
}

export interface EvaluateDaytimeOccupation {
  daytimeOccupationOptionId: string;
  occurrenceStatus: OccurenceStatus;
}
