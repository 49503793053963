import { PaymentMethod, UpdateBillingInfo, NextPayment } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getBillingInfo = (): Promise<any> => authorizedApi.get('/v1/billing-info');

export const updateBillingInfo = (billingInfo: UpdateBillingInfo): Promise<any> =>
  authorizedApi.put('/v1/billing-info', billingInfo);

export const getNextPayment = (organizationId: string): Promise<NextPayment> =>
  authorizedApi.get<NextPayment>(`/v1/billing-info/${organizationId}/cost`);

export const getPaymentMethod: () => Promise<PaymentMethod> = () =>
  authorizedApi.get('/v1/billing-info/payment-method');

export const removePaymentMethod: () => Promise<any> = () => authorizedApi.delete('/v1/billing-info/payment-method');

export const getClientSecret = () => authorizedApi.put('/v1/billing-info/client-secret');

export const getOrganizationPaymentProviderRedirectUrl = (organizationId: string): Promise<string> =>
  authorizedApi
    .get<{ redirectUrl: string }>(`/v1/billing-info/${organizationId}/redirect-url`)
    .then((response) => response.redirectUrl);
