import React from 'react';
import classnames from 'classnames';

import Picture from '@/components/Picture';
import Typography from '@/components/Typography';

import style from './EntityPreview.sass';

export interface Props {
  logo: string;
  name: string;
  className?: string;
}

const EntityPreview: React.FC<Props> = ({ logo, className, name }) => (
  <div className={classnames(style.root, className)}>
    <Picture url={logo} size="md" />

    <Typography className={style.name} is="span" type="flamingo" weight="bold">
      {name}
    </Typography>
  </div>
);

export default EntityPreview;
