import React from 'react';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { EvaluateSkill } from '@/domains';
import { evaluateSkill } from '@/services/api/participant-evaluations';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import { AppContext } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import { withValidation } from '@/components/hoc/withValidation';
import DataOptionSelect from '@/components/Selects/DataOptionSelect';
import LearningStatusSelect from '@/components/Selects/LearningStatusSelect';
import EvaluationStatusSelect from '@/components/Selects/EvaluationStatusSelect';

import style from './EvaluateSkillModal.sass';

const schema: yup.ObjectSchema<EvaluateSkill> = yup.object({
  skillOptionId: yup
    .string()
    .label(translations.inputs.skillSelect.label)
    .required(),
  confidenceStatus: yup
    .mixed()
    .label(translations.inputs.learningConfidence.label)
    .required(),
  learningStatus: yup
    .mixed()
    .label(translations.inputs.learningProgress.label)
    .required()
});

const SkillSelectWithValidation = withValidation(DataOptionSelect.SkillSelect);
const EvaluationStatusSelectWithValidation = withValidation(EvaluationStatusSelect);
const LearningStatusSelectWithValidation = withValidation(LearningStatusSelect);

export interface Props extends InstanceProps {
  sessionId: string;
  participantId: string;
  onSkillEvaluated: () => any;
}

const EvaluateSkillModal: React.FC<Props & WrappedComponentProps> = ({
  intl,
  sessionId,
  participantId,
  onSkillEvaluated,
  close
}) => {
  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12}>
          <div className={style.titleAndClose}>
            <Typography is="h6" type="swan" weight="bold">
              <FormattedMessage id={translations.modals.evaluateSkill.title} />
            </Typography>

            <Button onClick={close}>
              <BoxedIcon type="close" appearance="red" />
            </Button>
          </div>

          <Spacer xs={4} />

          <Typography is="h6" type="halcyon" weight="lighter" color="gray">
            <FormattedMessage id={translations.modals.addContact.personalInformation} />
          </Typography>

          <Spacer xs={2} />

          <Form
            id="evaluate-skill"
            schema={schema}
            subscription={{ dirty: true, pristine: true, submitting: true, submitError: true }}
            onSubmit={(data) => evaluateSkill(participantId, sessionId, data).then(() => onSkillEvaluated() || close())}
          >
            {({ dirty, pristine, submitting, submitError }, id) => (
              <React.Fragment>
                <Form.Field
                  is={SkillSelectWithValidation}
                  teamId={team.id}
                  id={`${id}-skill`}
                  name="skillOptionId"
                  label={intl.formatMessage({ id: translations.inputs.skillSelect.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.skillSelect.placeholder })}
                  readOnly={submitting}
                  language={language}
                />

                <Spacer xs={3} />

                <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                  <FormattedMessage id={translations.modals.evaluateSkill.evaluation} />
                </Typography>

                <Spacer xs={2} />

                <Form.Field
                  is={EvaluationStatusSelectWithValidation}
                  id={`${id}-confidence-status`}
                  name="confidenceStatus"
                  label={intl.formatMessage({ id: translations.inputs.learningConfidence.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.learningConfidence.placeholder })}
                  appearance="normal"
                />

                <Spacer xs={1} />

                <Form.Field
                  is={LearningStatusSelectWithValidation}
                  id={`${id}-learning-status`}
                  name="learningStatus"
                  label={intl.formatMessage({ id: translations.inputs.learningProgress.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.learningProgress.placeholder })}
                  appearance="normal"
                />

                <Spacer xs={10} />

                {!!submitError && <SubmitError error={submitError} />}

                <Spacer xs={2} />

                <Button className={style.button} loading={submitting} disabled={!dirty || pristine} appearance="orange">
                  <FormattedMessage id={translations.modals.evaluateSkill.button} />
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Card.Column>
      </Card.Row>
    </Card>
  );
};
export default injectIntl(EvaluateSkillModal);
