import { Role, rules } from '@/constants';

const volunteerRules = [
  rules.MAIN_DASHBOARD_VIEW,
  rules.TEAMS_MENU,
  rules.TEAMS_TEAM_INFORMATION_VIEW,
  rules.TEAMS_TEAMMATES_VIEW,
  rules.TEAMS_PARTICIPANTS_VIEW,
  rules.TEAMS_DIGITAL_COACH_VIEW,
  rules.TEAMS_SESSIONS_VIEW,
  rules.HEADER_TEAMS_DROPDOWN,
  rules.HEADER_NOTIFICATIONS_DROPDOWN
];

const streetWorkerRules = [
  ...volunteerRules,
  rules.LOCATION_REPORTING,
  rules.PARTICIPANT_REPORTING,
  rules.SESSION_REPORTING,
  rules.SESSION_PARTICIPANTS_EXPORT,
  rules.SESSION_LABELS,
  rules.MAIN_REPORTING_VIEW
];

const teamLeaderRules = [
  ...streetWorkerRules,
  rules.MAIN_REPORTING_VIEW,
  rules.TEAMS_DOWNLOAD_NOTES,
  rules.TEAM_UPDATE,
  rules.TEAM_ADD_MEMBERS,
  rules.TEAM_DATA_CUSTOMISATION,
  rules.TEAM_REMOVE_MEMBERS,
  rules.LOCATION_CREATE,
  rules.LOCATION_UPDATE,
  rules.LOCATION_ARCHIVE,
  rules.LOCATION_SHARE,
  rules.PARTICIPANT_DELETE,
  rules.PARTICIPANT_SHARE,
  rules.PARTICIPANT_MERGE,
  rules.PARTICIPANT_ACTIVATION,
  rules.PARTICIPANT_EXPORT,
  rules.SESSION_EXPORT
];

const adminRules = [
  rules.MAIN_DASHBOARD_VIEW,
  rules.MAIN_REPORTING_VIEW,
  rules.ORGANIZATION_MENU,
  rules.ORGANIZATION_INFORMATION_VIEW,
  rules.ORGANIZATION_MEMBERS_VIEW,
  rules.ORGANIZATION_DATA_CUSTOMISATION_VIEW,
  rules.ORGANIZATION_LICENSES_VIEW,
  rules.ORGANIZATION_DOWNLOAD_INTERVENTIONS,
  rules.TEAMS_MENU,
  rules.TEAMS_TEAM_INFORMATION_VIEW,
  rules.TEAMS_TEAMMATES_VIEW,
  rules.TEAM_CREATION,
  rules.TEAM_UPDATE,
  rules.TEAM_ARCHIVE,
  rules.TEAM_DELETE,
  rules.TEAM_ADD_MEMBERS,
  rules.TEAM_REMOVE_MEMBERS,
  rules.TEAM_CHANGE_EMAIL,
  rules.TEAM_EXPORT_MEMBERS,
  rules.LOCATION_CREATE,
  rules.LOCATION_UPDATE,
  rules.LOCATION_ARCHIVE,
  rules.HEADER_TEAMS_DROPDOWN,
  rules.HEADER_NOTIFICATIONS_DROPDOWN
];

const superAdminRules = [
  rules.APPLICATION_REPORTING,
  rules.APPLICATION_ORGANIZATIONS,
  rules.APPLICATION_DATA_CUSTOMIZATION
];

const permissionMap = {
  [Role.Admin]: adminRules,
  [Role.TeamLeader]: teamLeaderRules,
  [Role.AdminTeamLeader]: Array.from(new Set([...adminRules, ...teamLeaderRules])),
  [Role.StreetWorker]: streetWorkerRules,
  [Role.Volunteer]: volunteerRules,
  [Role.SuperAdmin]: superAdminRules
};

export const hasRole = (role: Role, rule: string) => {
  if (!role) {
    return false;
  }

  const permission = permissionMap[role];

  if (!permission) {
    return false;
  }

  if (permission.indexOf(rule) !== -1) {
    return true;
  }

  return false;
};
