import React from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { Contact as ParticipantContact } from '@/domains';
import { translations } from '@/locale';
import { getParticipantContacts } from '@/services/api/participant-contacts';

import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import InfiniteScroll from '@/components/InfiniteScroll';
import FilterManager from '@/components/FilterManager';
import TextInput from '@/components/TextInput';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Contact from '@/components/Contact';
import Placeholder from '@/components/Placeholder';

import style from './ChooseContact.sass';

interface Props {
  participantId: string;
  onContactChosen: (contact: ParticipantContact) => void;
}

const ChooseContact: React.FC<Props & WrappedComponentProps> = ({ intl, participantId, onContactChosen }) => (
  <React.Fragment>
    <Spacer xs={1} />

    <Typography is="span" type="halcyon" color="gray">
      <FormattedMessage id={translations.modals.evaluateContact.choose.subtitle} />
    </Typography>

    <FilterManager initialCriteria={{ search: null }}>
      {({ criteria, debouncedUpdate }) => (
        <React.Fragment>
          <Spacer xs={2} />

          <TextInput
            type="text"
            id="search"
            onChange={(event) => debouncedUpdate('search', event.target.value)}
            label={intl.formatMessage({ id: translations.inputs.contactSearch.label })}
            placeholder={intl.formatMessage({ id: translations.inputs.contactSearch.placeholder })}
            autoComplete="off"
            leadingIcon="search"
          />

          <Spacer xs={2} />

          <InfiniteScroll
            key={criteria.search}
            className={style.scroll}
            source={(page, size) => getParticipantContacts(participantId, page, size, criteria.search)}
          >
            {({ data, loading }) => (
              <If
                condition={!loading && data.length === 0}
                then={() => (
                  <Placeholder image="people" size="md">
                    <Typography is="span" type="halcyon" weight="bold">
                      <FormattedMessage id={translations.pages.teams.participants.details.contacts.empty} />
                    </Typography>
                  </Placeholder>
                )}
                else={() => (
                  <ul>
                    {data.map((contact: ParticipantContact) => (
                      <Contact
                        key={contact.id}
                        is="li"
                        name={contact.fullName}
                        picture={!!contact.type.icon ? contact.type.icon.url : null}
                        title={contact.type.name}
                        block
                        hoverable
                        onClick={() => onContactChosen(contact)}
                        className={style.contact}
                      />
                    ))}

                    <Loading visible={loading} center className={style.loadingIndicator}>
                      <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                    </Loading>
                  </ul>
                )}
              />
            )}
          </InfiniteScroll>
        </React.Fragment>
      )}
    </FilterManager>
  </React.Fragment>
);

export default injectIntl(ChooseContact);
