import { State, UpdateMember, MediaFileType, MemberDetails } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { uploadImage } from '@/services/api/media';

export const getMemberDetails: (id: string) => Promise<MemberDetails> = (id) =>
  authorizedApi.get<MemberDetails>(`/v1/users/${id}`);

export const updateMember: (id: string, member: UpdateMember) => Promise<any> = (id, { imageUrl, ...member }) =>
  uploadImage(imageUrl, MediaFileType.ProfilePicture).then((imageUrl) =>
    authorizedApi.put(`/v1/users/${id}`, { imageUrl, ...member })
  );

export const updateMemberState: (id: string, state: State) => Promise<any> = (id, state) =>
  authorizedApi.put(`/v1/users/${id}/state`, { state });
