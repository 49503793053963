import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '@/locale';
import { ParticipantListItem } from '@/domains';

import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppContext } from '@/components/Context/App';
import FilterManager from '@/components/FilterManager';
import { getSurveyParticipants } from '@/services/api/survey';
import AddParticipantsToSurveyModal from '@/components/SearchableModals/AddParticipantsToSurveyModal';

import ParticipantRow from './ParticipantRow';
import ParticipantHeader from './ParticipantHeader';

import style from './Participants.sass';

interface Params {
  id: string;
}

export interface Props {}

const Participants: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const { team } = React.useContext(AppContext);

  const intl = useIntl();

  return (
    team && (
      <Refresh>
        {({ key, refresh }) => (
          <div className={style.root}>
            <FilterManager initialCriteria={{ ageRange: { min: null, max: null } }}>
              {({ criteria, debouncedUpdate }) => (
                <React.Fragment>
                  <div className={style.searchAndButton}>
                    <TextInput
                      type="text"
                      id="search"
                      onChange={(event) => debouncedUpdate('search', event.target.value)}
                      label={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
                      placeholder={intl.formatMessage({
                        id: translations.inputs.participantSearch.placeholder
                      })}
                      autoComplete="off"
                      className={style.search}
                      leadingIcon="search"
                    />

                    <Modal
                      modal={AddParticipantsToSurveyModal}
                      teamId={team.id}
                      surveyId={match.params.id}
                      onSubmit={refresh}
                    >
                      {({ open }) => (
                        <Button fat appearance="orange" onClick={open} className={style.invite}>
                          <FormattedMessage id={translations.pages.digitalCoach.survey.tabs.participants.sendTo} />
                        </Button>
                      )}
                    </Modal>
                  </div>

                  <Spacer xs={2} />

                  <Table
                    key={key}
                    id={`${JSON.stringify({ criteria })}`}
                    source={(page, number) =>
                      getSurveyParticipants(match.params.id, page, number, {
                        keyword: criteria.search
                      })
                    }
                    empty={() => (
                      <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                        <Typography is="span" type="swan" weight="bold">
                          <FormattedMessage id={translations.pages.digitalCoach.tabs.participants.table.empty} />
                        </Typography>
                      </Placeholder>
                    )}
                    renderHeader={() => <ParticipantHeader />}
                    renderRow={(data: ParticipantListItem) => <ParticipantRow key={data.id} data={data} />}
                  />
                </React.Fragment>
              )}
            </FilterManager>
          </div>
        )}
      </Refresh>
    )
  );
};

export default withRouter(Participants);
