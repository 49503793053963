import React from 'react';
import classnames from 'classnames';

import style from './BoxedContent.sass';

export type Appearance = 'orange' | 'blue' | 'navigation' | 'red' | 'gray' | 'green' | 'orangeHollow';

export interface Props {
  appearance: Appearance;
  className?: string;
}

const BoxedContent: React.FC<Props> = ({ className, appearance, children, ...rest }) => (
  <div className={classnames(style.container, className, style[appearance!])}>{children}</div>
);

export default BoxedContent;
