import { Page, MemberBase } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getSessionParticipants: (
  page: number,
  size: number,
  search: string,
  sessionIds: string,
  extraData?: string
) => Promise<Page<MemberBase>> = (pageNumber, pageSize, keyword, sessionIds, extraData) =>
  authorizedApi.get<Page<MemberBase>>(`/v1/participants`, {
    pageNumber,
    pageSize,
    keyword,
    sessionIds,
    extraData: extraData
  });

export const addParticipantsToSession: (sessionId: string, participants) => Promise<any> = (sessionId, participants) =>
  authorizedApi.put(`/v1/sessions/${sessionId}/participants/add`, { ids: participants });

export const removeParticipantFromSession: (sessionId: string, participantId: string) => Promise<any> = (
  sessionId,
  participantId
) => authorizedApi.delete<any>(`/v1/sessions/${sessionId}/participants/${participantId}`);
