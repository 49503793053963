export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export enum GenderFilter {
  All = 'ALL',
  Other = 'OTHER',
  Female = 'FEMALE',
  Male = 'MALE'
}
