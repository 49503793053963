import React from 'react';
import classnames from 'classnames';

import style from './MenuButton.sass';

interface Props {
  className?: string;
  onClick: () => any;
}

const MenuButton = ({ className, onClick }: Props) => (
  <div className={classnames(style.root, className)} onClick={onClick}>
    <div className={style.line} />
    <div className={style.line} />
    <div className={style.line} />
  </div>
);

export default MenuButton;
