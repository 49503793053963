import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import classnames from 'classnames';

import { Evaluation } from '@/domains';
import { translations } from '@/locale';
import { getTime, getDateFormat } from '@/util';
import { urls } from '@/constants';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';
import DataWithIcon from '@/components/DataWithIcon';
import EvaluationCategories from '@/components/EvaluationCategories';

import cellStyle from '../cellStyle.sass';
import style from './Row.sass';

export interface Props {
  evaluationData: Evaluation;
  participantId: string;
  language: string;
  className?: string;
}

const EvaluationRow: React.FC<Props & WrappedComponentProps> = ({
  evaluationData: { id: sessionId, location, sessionType, start, end, evaluations },
  participantId,
  language,
  className,
  intl,
  ...rest
}) => (
  <Card.Row is="li" className={classnames(cellStyle.container, style.row, className)} {...rest}>
    <Link to={urls.participants.evaluation.get(participantId, sessionId)} className={style.sessionInfo}>
      <div className={classnames(style.location, style.location)}>
        <Picture size="md" url={location.pictureUrl} className={style.picture} />

        <DataWithIcon iconType="locationPin" title={location.name} subtitle={location.address} />
      </div>

      <div className={classnames(style.sessionType, style.sessionType)}>
        <BoxedContent appearance="blue">
          <Typography is="div" type="hummingbird" weight="bold" color="blue">
            {sessionType.translations && sessionType.translations[language.toLowerCase()]
              ? sessionType.translations[language.toLowerCase()]
              : sessionType.name}
          </Typography>
        </BoxedContent>
      </div>

      <DataWithIcon
        className={style.timeDate}
        iconType="clock"
        title={`${getTime(start)} - ${getTime(end)}`}
        subtitle={getDateFormat(start, 'MMM d, yyyy')}
      />

      <div className={style.evaluated}>
        <EvaluationCategories categories={evaluations} />
      </div>
    </Link>

    <Link to={urls.sessions.get(sessionId)} appearance="orange" className={style.link}>
      <FormattedMessage id={translations.pages.teams.participants.details.evaluations.list.sessionLink} />
    </Link>
  </Card.Row>
);

export default injectIntl(EvaluationRow);
