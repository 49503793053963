import React from 'react';
import classnames from 'classnames';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import TextInput from '@/components/TextInput';

import style from './Search.sass';

interface State {
  criteria: string;
}

interface RenderProps extends State {}

export interface Props {
  children: (props: RenderProps) => React.ReactNode;
  searchBarClassName?: string;
  label: string;
  placeholder: string;
}

export default class Search extends React.PureComponent<Props, State> {
  state: State = {
    criteria: null
  };

  setCriteria = AwesomeDebouncePromise((value) => this.setState({ criteria: value }), 300);

  handleChange = (event) => {
    this.setCriteria(event.target.value || null);
  };

  render() {
    const { label, placeholder, searchBarClassName, children } = this.props;
    return (
      <React.Fragment>
        <div className={classnames(style.searchContainer, searchBarClassName)}>
          <TextInput
            type="text"
            id={`${new Date().getTime().toString()}-search`}
            onChange={this.handleChange}
            label={label}
            placeholder={placeholder}
            autoComplete="off"
            className={searchBarClassName}
            leadingIcon="search"
          />
        </div>

        {children(this.state)}
      </React.Fragment>
    );
  }
}
