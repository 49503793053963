import React from 'react';
import { lowerCase } from 'lodash';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { DataOption, State as DataOptionState } from '@/domains';
import { updateTeamDataOption } from '@/services/api/data-option';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Picture from '@/components/Picture';
import ToggleSwitch from '@/components/ToggleSwitch';
import LiveSubmit from '@/components/LiveSubmit';

import cellStyle from '../cellStyle.sass';
import style from './DataOptionRow.sass';

export interface Props {
  dataOption: DataOption;
  teamId: string;
  className?: string;
  language?: string;
}

const DataOptionRow: React.FC<Props & WrappedComponentProps> = ({
  dataOption,
  teamId,
  className,
  language,
  intl,
  ...rest
}) => (
  <Card.Row is="li" className={classnames(cellStyle.container, className)} {...rest}>
    <div className={cellStyle.name}>
      <Typography is="div" type="sparrow" weight="bold">
        {dataOption.translations && dataOption.translations[language.toLowerCase()]
          ? dataOption.translations[language.toLowerCase()]
          : dataOption.name}
      </Typography>
    </div>

    <div className={cellStyle.icon}>
      {dataOption.icon ? <Picture url={dataOption.icon.url} size="xs" /> : <div className={style.empty} />}
    </div>

    <div className={cellStyle.category}>
      {dataOption.category ? (
        intl.formatMessage({ id: translations.skillCategories[dataOption.category] })
      ) : (
        <div className={style.empty} />
      )}
    </div>

    <div className={cellStyle.enabled}>
      <LiveSubmit
        loading={false}
        value={{ dataOptionState: dataOption.state }}
        onChange={({ dataOptionState }) => updateTeamDataOption(dataOption.id, teamId, dataOptionState)}
      >
        {({ value: { dataOptionState }, set }) => (
          <ToggleSwitch
            checked={dataOptionState === DataOptionState.Enabled}
            label={false}
            onClick={() =>
              set(
                'dataOptionState',
                dataOptionState === DataOptionState.Enabled ? DataOptionState.Disabled : DataOptionState.Enabled
              )
            }
            onChange={() => null}
          />
        )}
      </LiveSubmit>
    </div>
  </Card.Row>
);

export default injectIntl(DataOptionRow);
