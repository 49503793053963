import { State } from '@/domains';

export enum ParticipantLegalSectionType {
  LegalStatus = 'PARTICIPANT_LEGAL_STATUS',
  HomeStatus = 'PARTICIPANT_HOME_STATUS',
  LegalDocuments = 'PARTICIPANT_LEGAL_DOCUMENTS'
}

export interface ParticipantLegalSection {
  type: ParticipantLegalSectionType;
  state: State;
}
