import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { getAgeFromBirthdate } from '@/util';
import { translations } from '@/locale';
import { InvitationToAppStatus, ParticipantAccountOverview } from '@/domains';
import { getParticipantAppParticipants } from '@/services/api/participant-digital-coach';

import { InstanceProps } from '@/components/Modal';
import { shareMessage } from '@/services/api/messages';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';
import Button from '../Button';

interface Props extends InstanceProps {
  teamId: string;
  messageId: string;
  onSubmit: () => void;
}

class ShareMessageWithParticipants extends React.PureComponent<Props & WrappedComponentProps> {
  extractParticipantData = (data: ParticipantAccountOverview): SelectableItemData => {
    return {
      id: data.id,
      topText: `${data.participant.nickname} • ${this.props.intl.formatMessage({
        id: translations.participantRoles[data.roleLevel].name
      })}`,
      bottomText: data.participant.birthDate
        ? this.props.intl.formatMessage(
            { id: translations.pages.sessions.details.tabs.participants.content.table.age },
            { age: getAgeFromBirthdate(data.participant.birthDate) }
          )
        : this.props.intl.formatMessage({
            id: translations.pages.sessions.details.tabs.participants.content.table.noAge
          }),
      photoUrl: data.participant.imageUrl
    };
  };

  render() {
    const { teamId, messageId, onSubmit, intl } = this.props;

    return (
      <SearchableModal close={this.props.close}>
        <SearchableModal.Header
          title={intl.formatMessage({
            id: translations.modals.searchableModal.shareMessageWithParticipants.title
          })}
          subtitle={intl.formatMessage({
            id: translations.modals.searchableModal.shareMessageWithParticipants.subtitle
          })}
        />
        <SearchableModal.Content
          close={this.props.close}
          searchLabel={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
          searchPlaceholder={intl.formatMessage({ id: translations.inputs.participantSearch.placeholder })}
          dataSource={(page, number, criteria) =>
            getParticipantAppParticipants(teamId, page, number, {
              keyword: criteria,
              status: InvitationToAppStatus.Accepted
            })
          }
          submitDestination={(values) =>
            shareMessage(messageId, { teamId, participantAccountIds: values.selectedItems }).then(onSubmit)
          }
          dataExtractionFunction={this.extractParticipantData}
        />
      </SearchableModal>
    );
  }
}
export default injectIntl(ShareMessageWithParticipants);
