import React, { PureComponent } from 'react';
import classnames from 'classnames';

import { SidebarConsumer } from '@/components/Context/Sidebar';

import style from './SidebarWrapper.sass';

export interface Props {
  className?: string;
  children: any;
}

export default class SidebarWrapper extends PureComponent<Props> {
  render() {
    return (
      <SidebarConsumer>
        {({ isOpen, close }) => (
          <React.Fragment>
            <div className={classnames(style.overlay, isOpen && style.isOpen)} onClick={close} />
            <div className={classnames(style.sidebar, this.props.className, isOpen && style.isOpen)}>
              {this.props.children}
            </div>
          </React.Fragment>
        )}
      </SidebarConsumer>
    );
  }
}
