import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { Mood, Frequency, SocialIndicator, EvaluationStatus, UpdateSocialIndicator } from '@/domains';
import {
  evaluateMood,
  evaluateContact,
  deleteContactEvaluation,
  getParticipantEvaluationInSession
} from '@/services/api/participant-evaluations';

import If from '@/components/If';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import Contact from '@/components/Contact';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import Breadcrumb from '@/components/Breadcrumb';
import LiveSubmit from '@/components/LiveSubmit';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import { LanguageContext } from '@/components/Language';
import MoodSelect from '@/components/Selects/MoodSelect';
import FrequencySelect from '@/components/Selects/FrequencySelect';
import EvaluateContactModal from '@/components/Modals/EvaluateContactModal';
import EvaluationStatusSelect from '@/components/Selects/EvaluationStatusSelect';

import style from './Details.sass';
import Activities from './Activities';
import Skills from './Skills';
import Goals from './Goals';
import Occupations from './Occupations';

const mapSocialIndicators = (indicators: SocialIndicator[]): UpdateSocialIndicator[] =>
  indicators.map(({ evaluationStatus, socialIndicator }) => ({
    evaluationStatus,
    socialIndicatorId: socialIndicator.id
  }));

const InputsContainer = (props) => (
  <div className={classnames(style.inputsContainer, props.className)}>{props.children}</div>
);

interface Params {
  participantId: string;
  evaluationId: string;
}

const Details: React.FC<RouteComponentProps<Params> & WrappedComponentProps> = ({ intl, match }) => {
  const { participantId, evaluationId: sessionId } = match.params;
  const { language } = React.useContext(LanguageContext);

  return (
    <div className={style.page}>
      <LoadData load={() => getParticipantEvaluationInSession(sessionId, participantId)}>
        {({ value: evaluation, loading, reload }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.page}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => {
              const {
                moodEvaluation,
                moodEvaluationOfParticipant,
                activities,
                goals,
                daytimeOccupationEvaluations,
                participantContactEvaluations
              } = evaluation;

              return (
                <React.Fragment>
                  <Card.Row>
                    <Card.Column sm={12}>
                      <Breadcrumb
                        shape={{
                          [intl.formatMessage({ id: translations.pages.teams.participants.list.title })]: urls
                            .participants.list,
                          [evaluation.participantNickname]: {
                            pathname: urls.participants.get(participantId),
                            state: { name: evaluation.participantNickname }
                          },
                          [evaluation.sessionName]: null
                        }}
                      />

                      <Spacer xs={4} />

                      <Typography is="h6" type="swan" weight="bold">
                        <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.mood} />
                      </Typography>

                      <Spacer xs={2} />

                      <LiveSubmit
                        loading={loading}
                        value={moodEvaluation || { mood: null }}
                        onChange={(value) => evaluateMood(participantId, sessionId, value)}
                      >
                        {({ value, set }) => (
                          <InputsContainer>
                            <MoodSelect
                              id="mood"
                              name="mood"
                              label={intl.formatMessage({ id: translations.inputs.mood.label })}
                              placeholder={intl.formatMessage({ id: translations.inputs.mood.placeholder })}
                              value={value.mood}
                              onChange={(mood: Mood) => set('mood', mood)}
                              appearance="normal"
                            />
                          </InputsContainer>
                        )}
                      </LiveSubmit>

                      {moodEvaluationOfParticipant !== null && (
                        <React.Fragment>
                          <Spacer xs={3} />

                          <Typography type="halcyon" is="span" color="gray">
                            <FormattedMessage
                              id={translations.pages.teams.participants.details.evaluations.details.participantMood}
                            />
                          </Typography>

                          <Spacer xs={1} />
                          <InputsContainer>
                            <MoodSelect
                              id="mood"
                              name="mood"
                              label={intl.formatMessage({ id: translations.inputs.mood.label })}
                              placeholder={intl.formatMessage({ id: translations.inputs.mood.placeholder })}
                              value={moodEvaluationOfParticipant.mood}
                              appearance="normal"
                              onChange={() => null}
                              disabled
                            />
                          </InputsContainer>
                        </React.Fragment>
                      )}
                    </Card.Column>
                  </Card.Row>

                  <Activities activities={activities} reload={reload} />

                  <Skills evaluation={evaluation} loading={loading} reload={reload} />

                  <Goals goals={goals} loading={loading} reload={reload} />

                  <Occupations
                    language={language}
                    daytimeOccupationEvaluations={daytimeOccupationEvaluations}
                    reload={reload}
                  />

                  <Card.Row>
                    <Card.Column sm={12}>
                      <Typography is="h6" type="swan" weight="bold">
                        <FormattedMessage
                          id={translations.pages.teams.participants.details.evaluations.details.socialMap.title}
                        />
                      </Typography>

                      <Spacer xs={2} />

                      <If
                        condition={!!participantContactEvaluations && participantContactEvaluations.length == 0}
                        then={() => (
                          <Placeholder image="questions" size="sm">
                            <Typography is="span" type="halcyon" weight="bold">
                              <FormattedMessage
                                id={translations.pages.teams.participants.details.evaluations.details.socialMap.none}
                              />
                            </Typography>
                          </Placeholder>
                        )}
                        else={() => (
                          <div className={style.contactEvaluations}>
                            {participantContactEvaluations.map(({ contactDto, evaluation }) => (
                              <div key={contactDto.id} className={style.contactEvaluation}>
                                <div className={style.textAndDelete}>
                                  <Contact
                                    name={contactDto.fullName}
                                    picture={!!contactDto.type.icon ? contactDto.type.icon.url : null}
                                    title={
                                      contactDto.type.translations &&
                                      contactDto.type.translations[language.toLowerCase()]
                                        ? contactDto.type.translations[language.toLowerCase()]
                                        : contactDto.type.name
                                    }
                                    className={style.contact}
                                  />

                                  <Button
                                    onClick={() => deleteContactEvaluation(evaluation.id)}
                                    className={style.deleteButtonWithText}
                                  >
                                    <TextWithIcon
                                      is="span"
                                      type="halcyon"
                                      icon="bin"
                                      color="red"
                                      iconColor="red"
                                      boxed
                                      className={style.deleteText}
                                    >
                                      <FormattedMessage
                                        id={translations.pages.teams.participants.details.evaluations.details.delete}
                                      />
                                    </TextWithIcon>
                                  </Button>
                                </div>

                                <LiveSubmit
                                  key={contactDto.id}
                                  loading={loading}
                                  value={{
                                    frequency: evaluation.frequency,
                                    interactionMood: evaluation.interactionMood,
                                    socialIndicatorEvaluations: mapSocialIndicators(
                                      evaluation.socialIndicatorEvaluations
                                    )
                                  }}
                                  onChange={(evaluation) => evaluateContact(contactDto.id, sessionId, evaluation)}
                                >
                                  {({ value, set }) => (
                                    <React.Fragment>
                                      <InputsContainer>
                                        <FrequencySelect
                                          label={intl.formatMessage({ id: translations.inputs.frequency.label })}
                                          placeholder={intl.formatMessage({
                                            id: translations.inputs.frequency.placeholder
                                          })}
                                          value={value.frequency}
                                          onChange={(value) => set('frequency', value as Frequency)}
                                          appearance="normal"
                                        />

                                        <MoodSelect
                                          label={intl.formatMessage({ id: translations.inputs.mood.label })}
                                          placeholder={intl.formatMessage({ id: translations.inputs.mood.placeholder })}
                                          value={value.interactionMood}
                                          onChange={(value) => set('interactionMood', value as Mood)}
                                          appearance="normal"
                                        />
                                      </InputsContainer>

                                      <Spacer xs={3} />

                                      <Typography is="h4" type="sparrow" weight="bold">
                                        <FormattedMessage
                                          id={translations.modals.evaluateContact.evaluate.helpfulness}
                                        />
                                      </Typography>

                                      <Spacer xs={1} />

                                      <InputsContainer>
                                        {evaluation.socialIndicatorEvaluations.map(({ socialIndicator }, index) => (
                                          <EvaluationStatusSelect
                                            key={index}
                                            label={
                                              socialIndicator.translations &&
                                              socialIndicator.translations[language.toLowerCase()]
                                                ? socialIndicator.translations[language.toLowerCase()]
                                                : socialIndicator.name
                                            }
                                            value={value.socialIndicatorEvaluations[index].evaluationStatus}
                                            onChange={(indicator) =>
                                              set(
                                                `socialIndicatorEvaluations`,
                                                Object.assign([], value.socialIndicatorEvaluations, {
                                                  [index]: {
                                                    evaluationStatus: indicator as EvaluationStatus,
                                                    socialIndicatorId: socialIndicator.id
                                                  }
                                                })
                                              )
                                            }
                                            appearance="normal"
                                          />
                                        ))}
                                      </InputsContainer>
                                    </React.Fragment>
                                  )}
                                </LiveSubmit>
                              </div>
                            ))}
                          </div>
                        )}
                      />

                      <Spacer xs={2} />

                      <Modal
                        modal={EvaluateContactModal}
                        sessionId={sessionId}
                        participantId={participantId}
                        onContactEvaluated={reload}
                      >
                        {({ open }) => (
                          <Typography is="span" type="halcyon" color="orange" onClick={open} className={style.link}>
                            <FormattedMessage
                              id={translations.pages.teams.participants.details.evaluations.details.socialMap.evaluate}
                            />
                          </Typography>
                        )}
                      </Modal>

                      <Spacer xs={5} />
                    </Card.Column>
                  </Card.Row>
                </React.Fragment>
              );
            }}
          />
        )}
      </LoadData>
    </div>
  );
};

export default injectIntl(withRouter(Details));
