import React from 'react';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import Input from '@/components/Input';
import InfiniteScroll from '@/components/InfiniteScroll';
import Loading from '@/components/Loading';
import BoxedContent from '@/components/BoxedContent';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Typography from '@/components/Typography';

import style from './TagField.sass';

export interface Props {
  label: string;
  dropdownTitle: string;
  initialTags: any[];
  dataSource: (...params) => any;
  addNewTag: (newTagName: string) => Promise<any>;
  updateTagList: (tagList) => Promise<any>;
}

const TagField: React.FC<Props> = ({ label, dropdownTitle, initialTags, dataSource, addNewTag, updateTagList }) => {
  const [keyword, setKeyword] = React.useState('');
  const [inputItems, setInputItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState(initialTags);

  const onKeyDown = (event, setInputValue) => {
    if (event.key !== 'Enter') return;

    const currentTagName = event.target.value;
    if (!inputItems.find((item) => item.name === currentTagName)) {
      addNewTag(currentTagName).then((newTag) => {
        const newSelectedItem = { ...newTag, name: currentTagName };
        updateTagList([newSelectedItem]).then(() => {
          setSelectedItems((state) => [...state, newSelectedItem]);
          setInputValue('');
        });
      });
    }
  };

  const setValidatedUserInput = (downshiftInput: UseComboboxStateChange<any>) => {
    setKeyword(downshiftInput.inputValue);
    setInputItems([]);
  };

  const debouncedUserInput = React.useCallback(debounce(setValidatedUserInput, 300), []);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue
  } = useCombobox({
    items: inputItems,
    itemToString: (item) => item.name,
    onInputValueChange: debouncedUserInput,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItems.find((item) => selectedItem.id === item.id)) {
        updateTagList([selectedItem]).then(() => setSelectedItems((state) => [...state, selectedItem]));
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return { ...changes, inputValue: '' };
        default:
          return changes;
      }
    }
  });

  return (
    <div className={style.root}>
      <label className={style.label} {...getLabelProps()}>
        {label}
      </label>

      <div className={style.tagsContainer}>
        {selectedItems.map((item, index) => (
          <BoxedContent appearance="orange" key={`${item.name}${index}`} className={style.tag}>
            {item.name}
            <Button
              className={style.closeButton}
              type="button"
              onClick={() =>
                updateTagList([item]).then(() =>
                  setSelectedItems((state) => [...state.filter((selectedItem) => item.id !== selectedItem.id)])
                )
              }
            >
              <Icon type="close" appearance="gray" className={style.closeIcon} />
            </Button>
          </BoxedContent>
        ))}

        <div className={style.inputContainer} {...getComboboxProps()}>
          <Input
            className={style.input}
            {...getInputProps({ onKeyDown: (event) => highlightedIndex < 0 && onKeyDown(event, setInputValue) })}
          />
        </div>
      </div>

      <div className={classnames(style.listContainer, { [style.visible]: isOpen })} {...getMenuProps()}>
        <Typography is="div" type="hummingbird" color="gray" className={style.dropdownTitle}>
          {dropdownTitle}
        </Typography>
        <InfiniteScroll
          id={keyword}
          source={(page, number) => {
            return dataSource(page, number, keyword).then((result) => {
              setInputItems((state) => [...state, ...result.content]);
              return result;
            });
          }}
          className={style.list}
        >
          {({ data, loading }) => (
            <React.Fragment>
              <Loading visible={loading} className={style.loadingContainer}>
                <Loading.Indicator size={40} borderWidth={5} fullCircle color="#BCBDC3" />
              </Loading>
              <ul>
                {inputItems.map((item, index) => (
                  <li className={style.listItem} key={`${item}${index}`} {...getItemProps({ item, index })}>
                    <BoxedContent appearance={highlightedIndex === index ? 'orangeHollow' : 'orange'}>
                      {item.name}
                    </BoxedContent>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TagField;
