import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { differenceInHours } from 'date-fns/esm';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { urls } from '@/constants';
import { getDateFormat, getISOString } from '@/util';
import { translations } from '@/locale';
import { updateSurvey, deleteSurvey, updateSurveyTiming } from '@/services/api/survey';
import { getStreetSessionDetails } from '@/services/api/street-session';
import { QuestionType, SurveyDetails, SurveyStatus, SurveyType } from '@/domains';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { dateFormat } from '@/components/DateInput';
import TextWithIcon from '@/components/TextWithIcon';
import { LanguageContext } from '@/components/Language';
import HourSelect from '@/components/Selects/HourSelect';
import PictureWithData from '@/components/PictureWithData';
import { withValidation } from '@/components/hoc/withValidation';
import QuestionTypeSelect from '@/components/Selects/QuestionTypeSelect';
import StreetSessionRow from '@/page/Team/StreetSessions/List/StreetSessionRow';
import StreetSessionHeader from '@/page/Team/StreetSessions/List/StreetSessionHeader';

import SurveyDateSection from './SurveyDateSection';

import style from './SurveyDetail.sass';

interface Params {
  id: string;
}

export interface Props {
  data: SurveyDetails;
  refresh?: () => any;
}

const RegularSurveySchema = yup.object().shape({
  title: yup.string().required(),
  description: yup
    .string()
    .max(250)
    .label(translations.inputs.surveyDescription.label)
    .notRequired(),
  startDate: yup.string().required(),
  endDate: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startDate = this.resolve(yup.ref('startDate'));

      return startDate.localeCompare(value) <= 0;
    })
    .required(),
  startTime: yup
    .string()
    .label(translations.inputs.startTime.label)
    .required(),
  endTime: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startTime = this.resolve(yup.ref('startTime'));
      const startDate = this.resolve(yup.ref('startDate'));
      const endDate = this.resolve(yup.ref('endDate'));

      if (!startTime) return true;

      return startDate === endDate ? startTime.localeCompare(value) === -1 : true;
    })
    .label(translations.inputs.endTime.label)
    .required(),
  questions: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        type: yup.string().required(),
        title: yup
          .string()
          .label(translations.pages.digitalCoach.survey.question.label)
          .required(),
        options: yup.array().when(['type'], (type) => {
          return type === QuestionType.TextInput
            ? yup.array().notRequired()
            : yup
                .array()
                .required()
                .min(2)
                .of(
                  yup.object().shape({
                    id: yup
                      .string()
                      .nullable()
                      .notRequired(),
                    option: yup.string().required()
                  })
                );
        })
      })
    )
    .required()
});

const SessionFeedbackSchema = yup.object().shape({
  title: yup.string().required(),
  time: yup.number().required(),
  questions: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        type: yup.string().required(),
        title: yup
          .string()
          .label(translations.pages.digitalCoach.survey.question.label)
          .required(),
        options: yup.array().when(['type'], (type) => {
          return type === QuestionType.TextInput
            ? yup.array().notRequired()
            : yup
                .array()
                .min(2)
                .required()
                .of(
                  yup.object().shape({
                    id: yup
                      .string()
                      .nullable()
                      .notRequired(),
                    option: yup.string().required()
                  })
                );
        })
      })
    )
    .required()
});

const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const TextInputWithValidation = withValidation(TextInput);
const HourSelectWithValidation = withValidation(HourSelect);

const SurveyDetail: React.FC<Props & RouteComponentProps<Params>> = ({ data, history, refresh }) => {
  const { start, end, type, ...rest } = data;

  const intl = useIntl();

  const { language } = React.useContext(LanguageContext);

  const getInitialValues = (surveyType: SurveyType) =>
    surveyType === SurveyType.Regular
      ? {
          type: intl.formatMessage({ id: translations.surveyType[type] }),
          startDate: getDateFormat(start, 'yyyy-MM-dd'),
          startTime: getDateFormat(start, 'HH:mm'),
          endDate: getDateFormat(end, 'yyyy-MM-dd'),
          endTime: getDateFormat(end, 'HH:mm'),
          ...rest
        }
      : {
          type: intl.formatMessage({ id: translations.surveyType[type] }),
          time: differenceInHours(new Date(end), new Date(start)),
          ...rest
        };

  return (
    <Card.Row className={classnames(style.column, style.padded)}>
      <Form
        id="survey"
        schema={data.type === SurveyType.Regular ? RegularSurveySchema : SessionFeedbackSchema}
        initialValues={getInitialValues(data.type)}
        subscription={{
          values: true,
          dirty: true,
          pristine: true,
          submitting: true,
          valid: true
        }}
        onSubmit={(values) => {
          return (data.status === SurveyStatus.Active
            ? updateSurveyTiming(data.id, {
                start: getISOString(`${values.startDate} ${values.startTime}`, `${dateFormat} HH:mm`),
                end: getISOString(`${values.endDate} ${values.endTime}`, `${dateFormat} HH:mm`)
              })
            : updateSurvey(data.id, {
                description: values.description,
                questions: values.questions.map((question) =>
                  question.id
                    ? question
                    : question.type === QuestionType.TextInput
                    ? { title: question.title, type: question.type }
                    : question
                ),
                title: values.title,
                start: getISOString(`${values.startDate} ${values.startTime}`, `${dateFormat} HH:mm`),
                end: getISOString(`${values.endDate} ${values.endTime}`, `${dateFormat} HH:mm`)
              })
          ).then(refresh);
        }}
        mutators={{ ...arrayMutators }}
      >
        {(
          {
            form: {
              mutators: { push }
            },
            form,
            submitting,
            values,
            valid,
            dirty
          },
          id
        ) => (
          <React.Fragment>
            <Typography is="span" type="swan" weight="bold">
              <FormattedMessage id={translations.pages.digitalCoach.survey.title} />
            </Typography>

            <Spacer xs={3} />

            <Form.Field
              is={TextInputWithValidation}
              type="text"
              id={`${id}-type`}
              name="type"
              label={intl.formatMessage({ id: translations.inputs.surveyType.label })}
              disabled={true}
              className={style.field}
            />

            <Spacer xs={2} />

            <Form.Field
              is={TextInputWithValidation}
              type="text"
              id={`${id}-title`}
              name="title"
              label={intl.formatMessage({ id: translations.inputs.surveyTitle.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.surveyTitle.placeholder })}
              readOnly={submitting}
              className={style.field}
              disabled={data.status !== SurveyStatus.Scheduled}
            />

            <Spacer xs={4} />

            {data.session && (
              <React.Fragment>
                <Typography is="span" type="hummingbird" color="gray">
                  <FormattedMessage id={translations.pages.sessions.details.tabs.details.sessionDetails} />
                </Typography>

                <Spacer xs={2} />

                <div className={style.sessionDetail}>
                  <LoadData load={() => getStreetSessionDetails(data.session.id)}>
                    {({ value: session, loading }) => (
                      <React.Fragment>
                        <StreetSessionHeader />
                        {!loading && <StreetSessionRow language={language} streetSession={session} />}
                      </React.Fragment>
                    )}
                  </LoadData>
                </div>
              </React.Fragment>
            )}

            <Spacer xs={3} />
            <If
              condition={data.type === SurveyType.Regular}
              then={() => (
                <React.Fragment>
                  <Typography is="span" type="hummingbird" color="gray">
                    <FormattedMessage id={translations.pages.digitalCoach.survey.description} />
                  </Typography>

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextAreaWithValidation}
                    id={`${id}-description`}
                    name="description"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.surveyDescription.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.surveyDescription.placeholder })}
                    readOnly={submitting}
                    inputClassName={style.textArea}
                    disabled={data.status !== SurveyStatus.Scheduled}
                  />

                  <Spacer xs={5} />

                  <Typography is="span" type="hummingbird" color="gray">
                    <FormattedMessage id={translations.pages.digitalCoach.survey.schedule} />
                  </Typography>

                  <Spacer xs={1} />

                  <SurveyDateSection surveyStatus={data.status} submitting={submitting} onFormChange={form.change} />
                </React.Fragment>
              )}
              else={() => (
                <React.Fragment>
                  <Typography is="span" type="hummingbird" color="gray">
                    <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.sessionFeedback.time} />
                  </Typography>

                  <Spacer xs={2} />
                  <div className={style.hourSelect}>
                    <Form.Field
                      is={HourSelectWithValidation}
                      type="text"
                      id="time"
                      name="time"
                      label={intl.formatMessage({ id: translations.inputs.hourSelect.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.hourSelect.placeholder })}
                      readOnly={submitting}
                      disabled
                    />
                  </div>
                </React.Fragment>
              )}
            />

            <Spacer xs={7} />

            <FieldArray name="questions">
              {({ fields }) =>
                fields.map((name, questionNumber) => (
                  <div className={style.column} key={questionNumber}>
                    <Typography is="span" type="halcyon" weight="bold">
                      <FormattedMessage
                        id={translations.pages.digitalCoach.survey.question.number}
                        values={{ number: questionNumber + 1 }}
                      />
                    </Typography>

                    <Spacer xs={3} />

                    <div className={style.row}>
                      <Form.Field
                        is={TextInputWithValidation}
                        type="text"
                        id={`${questionNumber}-title`}
                        name={`${name}title`}
                        label={intl.formatMessage({ id: translations.pages.digitalCoach.survey.question.label })}
                        placeholder=""
                        readOnly={submitting}
                        className={style.field}
                        disabled={data.status !== SurveyStatus.Scheduled}
                      />

                      <div className={style.spacer} />

                      <Form.Field
                        is={QuestionTypeSelect}
                        type="text"
                        id={`${questionNumber}-type`}
                        name={`${name}type`}
                        label={intl.formatMessage({ id: translations.pages.digitalCoach.survey.question.type })}
                        readOnly={submitting}
                        disabled={fields.value[questionNumber].id}
                        className={style.questionTypeSelect}
                      />

                      <div className={style.spacer} />

                      <Button is="button" type="button" onClick={() => fields.remove(questionNumber)}>
                        <TextWithIcon icon="bin" boxed color="red" is="span" type="hummingbird">
                          <FormattedMessage id={translations.pages.digitalCoach.survey.removeQuestion} />
                        </TextWithIcon>
                      </Button>
                    </div>

                    {!!fields && fields.value[questionNumber].type !== QuestionType.TextInput ? (
                      <React.Fragment>
                        <Spacer xs={2} />

                        <FieldArray name={`${name}options`}>
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div className={style.column} key={index}>
                                <div className={style.row}>
                                  <Form.Field
                                    is={TextInputWithValidation}
                                    type="text"
                                    id={`${questionNumber}${index}-option`}
                                    name={`${name}option`}
                                    label={intl.formatMessage(
                                      { id: translations.pages.digitalCoach.survey.question.answerOption },
                                      { number: index + 1 }
                                    )}
                                    placeholder=""
                                    readOnly={submitting}
                                    className={style.field}
                                    disabled={data.status !== SurveyStatus.Scheduled}
                                  />

                                  <div className={style.spacer} />

                                  <Button is="button" type="button" onClick={() => fields.remove(index)}>
                                    <BoxedIcon type="bin" appearance="red" />
                                  </Button>
                                </div>

                                <Spacer xs={2} />
                              </div>
                            ))
                          }
                        </FieldArray>

                        <Spacer xs={1} />

                        <Button
                          is="button"
                          type="button"
                          className={style.addOption}
                          onClick={() => push(`${name}options`, {})}
                        >
                          <TextWithIcon
                            icon="plus"
                            boxed
                            iconSize="small"
                            iconColor="orangeHollow"
                            color="orange"
                            is="span"
                            type="hummingbird"
                          >
                            <FormattedMessage id={translations.pages.digitalCoach.survey.addOption} />
                          </TextWithIcon>
                        </Button>
                      </React.Fragment>
                    ) : (
                      () => (fields.value[questionNumber].options = [])
                    )}

                    <Spacer xs={5} />
                  </div>
                ))
              }
            </FieldArray>

            {(!data || data.status === SurveyStatus.Scheduled) && (
              <Button
                is="button"
                type="button"
                appearance="ghost"
                onClick={() => push('questions', { type: QuestionType.MultiSelect, options: [{}, {}] })}
              >
                <FormattedMessage id={translations.pages.digitalCoach.survey.addQuestion} />
              </Button>
            )}

            <Spacer xs={2} />

            {data.type === SurveyType.Regular && (
              <div className={style.column}>
                <Typography is="span" type="halcyon" color="gray">
                  <FormattedMessage id={translations.pages.digitalCoach.survey.tabs.details.createdBy} />
                </Typography>

                <Spacer xs={2} />

                <PictureWithData
                  size="md"
                  url={data.createdBy.imageUrl || null}
                  blueText={`${data.createdBy.firstName} ${data.createdBy.lastName} • ${intl.formatMessage({
                    id: translations.roles[data.createdBy.role]
                  })}`}
                  grayText={data.createdBy.email}
                />
              </div>
            )}

            {data.status !== SurveyStatus.Closed && (
              <React.Fragment>
                <Spacer xs={7} />

                <div className={classnames(style.padded, style.row, style.buttons)}>
                  <Button
                    is="button"
                    type="button"
                    onClick={() => deleteSurvey(data.id).then(() => history.push(urls.digitalCoach.survey.base))}
                    className={style.cancel}
                  >
                    <TextWithIcon icon="close" boxed color="red" is="span" type="hummingbird">
                      <FormattedMessage id={translations.pages.digitalCoach.survey.tabs.details.close} />
                    </TextWithIcon>
                  </Button>

                  <Button
                    fat
                    appearance="orange"
                    type="submit"
                    className={style.button}
                    disabled={
                      !dirty ||
                      !valid ||
                      //TODO this is a hacky fix for the form validation error. Change when a fix will be found
                      values.questions
                        .filter((question) => question.type !== QuestionType.TextInput)
                        .find((question) => question.options === undefined || question.options.length < 2) != undefined
                    }
                  >
                    <FormattedMessage id={translations.buttons.save} />
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Form>
    </Card.Row>
  );
};

export default withRouter(SurveyDetail);
