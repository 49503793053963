export enum Language {
  English = 'EN',
  French = 'FR',
  Spanish = 'ES',
  Dutch = 'NL',
  Albanian = 'SQ',
  Greek = 'EL',
  Romanian = 'RO',
  Polish = 'PL'
}

export const normalizeLanguage = (language: Language): Language => {
  return language && Object.values(Language).indexOf(language) >= 0 ? language : Language.English;
};
