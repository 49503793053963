import React from 'react';
import classnames from 'classnames';
import { parseISO, differenceInDays } from 'date-fns';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedDate } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { SelfEvaluation } from '@/domains';

import Link from '@/components/Link';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import BoxedContent from '@/components/BoxedContent';

import cellStyle from '../cellStyle.sass';

import style from './SelfEvaluationsRow.sass';

export interface Props {
  data: SelfEvaluation;
}

const SelfEvaluationsRow: React.FC<Props & WrappedComponentProps> = ({ data, intl, ...rest }) => (
  <Card.Row
    is={Link}
    // @ts-ignore
    to={urls.digitalCoach.selfEvaluation.get(data.id)}
    className={style.row}
    {...rest}
  >
    <div className={cellStyle.sessionType}>
      <BoxedContent appearance="blue" className={style.sessionType}>
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.pages.digitalCoach.tabs.selfEvaluations.table.row.selfEvaluation} />
        </Typography>
      </BoxedContent>
    </div>

    <TextWithIcon icon="participants" is="span" type="halcyon" iconColor="blue" className={cellStyle.participants}>
      {data.participants}
    </TextWithIcon>

    <div className={classnames(cellStyle.period, style.date)}>
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedDate value={data.start} timeZone="utc" month="short" day="2-digit" />
        {' - '}
        <FormattedDate value={data.end} timeZone="utc" month="short" day="2-digit" year="numeric" />
      </Typography>

      <Typography is="span" type="hummingbird" color="gray">
        <FormattedMessage
          id={translations.inputs.daysInterval.optionLabel}
          values={{ day: differenceInDays(parseISO(data.end), parseISO(data.start)) + 1 }}
        />
      </Typography>
    </div>
  </Card.Row>
);

export default injectIntl(SelfEvaluationsRow);
