import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { surveryStatusColor } from '@/constants/survery-status';
import {
  getSelfEvaluation,
  getSelfEvaluationParticipants,
  updateSelfEvaluation
} from '@/services/api/self-evaluations';
import { getDateFormat, getISOString, getTime } from '@/util';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import TimeInput from '@/components/TimeInput';
import Typography from '@/components/Typography';
import Breadcrumb from '@/components/Breadcrumb';
import Placeholder from '@/components/Placeholder';
import { dateFormat } from '@/components/DateInput';
import BoxedContent from '@/components/BoxedContent';
import { AppContext } from '@/components/Context/App';
import FilterManager from '@/components/FilterManager';
import DatePickerInput from '@/components/DatePickerInput';
import { withValidation } from '@/components/hoc/withValidation';
import InviteParticipantsToSelfEvaluationModal from '@/components/SearchableModals/InviteParticipantsToSelfEvaluationModal';

import ParticipantRow from './ParticipantRow';
import ParticipantHeader from './ParticipantHeader';

import style from './Detail.sass';

interface Params {
  id: string;
}

export interface Props {}

const schema = yup.object({
  type: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  startTime: yup
    .string()
    .label(translations.inputs.startTime.label)
    .required(),
  endTime: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startTime: string = this.resolve(yup.ref('startTime'));
      const startDate: string = this.resolve(yup.ref('startDate'));
      const endDate: string = this.resolve(yup.ref('endDate'));

      if (!startTime) return true;

      return startDate === endDate ? startTime.localeCompare(value) === -1 : true;
    })
    .label(translations.inputs.endTime.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);
const TimeInputWithValidation = withValidation(TimeInput);
const DatePickerInputWithValidation = withValidation(DatePickerInput);

const Detail: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const intl = useIntl();

  const { team, loading: teamLoading } = React.useContext(AppContext);

  const { id } = match.params;

  const translation = translations.pages.digitalCoach;

  return (
    <Card className={style.card}>
      <LoadData id={id} load={getSelfEvaluation}>
        {({ value, loading, reload }) =>
          !loading &&
          !teamLoading && (
            <React.Fragment>
              <Card.Row className={style.padded}>
                <Breadcrumb
                  shape={{
                    [intl.formatMessage({ id: translation.title })]: urls.digitalCoach.coach,
                    [intl.formatMessage({ id: translation.tabs.selfEvaluations.title })]: null,
                    [`${intl.formatDate(value.start, { month: 'long', day: '2-digit' })} - ${intl.formatDate(
                      value.end,
                      { month: 'long', day: '2-digit', year: 'numeric' }
                    )}`]: null
                  }}
                />

                {value.status && (
                  <Typography
                    is={BoxedContent}
                    appearance={surveryStatusColor[value.status]}
                    type="hummingbird"
                    className={style.status}
                  >
                    <FormattedMessage id={translations.surveyStatus[value.status]} />
                  </Typography>
                )}
              </Card.Row>

              <Card.Row className={style.padded}>
                <Form
                  id="self-evaluation"
                  subscription={{ errors: true, submitting: true, values: true, valid: true, dirty: true }}
                  onSubmit={(values) => {
                    const { startDate, startTime, endDate, endTime } = values;
                    const start = getISOString(`${startDate} ${startTime}`, `${dateFormat} HH:mm`);
                    const end = getISOString(`${endDate} ${endTime}`, `${dateFormat} HH:mm`);

                    return updateSelfEvaluation(start, end, id).then(reload);
                  }}
                  initialValues={{
                    type: intl.formatMessage({
                      id: translation.tabs.selfEvaluations.table.row.selfEvaluation
                    }),
                    startDate: getDateFormat(value.start, 'yyyy-MM-dd'),
                    endDate: getDateFormat(value.end, 'yyyy-MM-dd'),
                    startTime: getTime(value.start),
                    endTime: getTime(value.end)
                  }}
                  schema={schema}
                  className={style.form}
                >
                  {({ submitting, values, dirty, valid, form }, formId) => (
                    <div>
                      <Typography is="span" type="swan" weight="bold">
                        <FormattedMessage id={translation.selfEvaluation.detail.title} />
                      </Typography>
                      <Spacer xs={4} />

                      <Form.Field
                        is={TextInputWithValidation}
                        id="type"
                        name="type"
                        type="text"
                        label={intl.formatMessage({ id: translations.inputs.sessionType.label })}
                        readOnly={submitting}
                        disabled={true}
                      />

                      <Spacer xs={3} />

                      <Typography is="span" type="halcyon" color="gray">
                        <FormattedMessage id={translations.modals.createSelfEvaluationModal.dateSection} />
                      </Typography>

                      <Spacer xs={2} />

                      <div className={style.row}>
                        <Form.Field
                          is={DatePickerInputWithValidation}
                          id={`start`}
                          name="startDate"
                          type="text"
                          change={form.change}
                          readOnly={submitting}
                          className={style.startDate}
                        />

                        <Form.Field
                          is={TimeInputWithValidation}
                          id={`${formId}-start-time`}
                          name="startTime"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.startTime.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.startTime.placeholder })}
                          readOnly={submitting}
                        />
                      </div>

                      <Spacer xs={1} />

                      <div className={style.row}>
                        <Form.Field
                          is={DatePickerInputWithValidation}
                          id={`end`}
                          name="endDate"
                          type="text"
                          change={form.change}
                          readOnly={submitting}
                          className={style.startDate}
                        />

                        <Form.Field
                          is={TimeInputWithValidation}
                          id={`${formId}-end-time`}
                          name="endTime"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.endTime.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.endTime.placeholder })}
                          readOnly={submitting}
                        />
                      </div>

                      <Spacer xs={4} />

                      <Button
                        form={formId}
                        loading={submitting}
                        type="submit"
                        appearance="orange"
                        className={style.saveButton}
                        disabled={!valid || !dirty}
                      >
                        <FormattedMessage id={translations.buttons.save} />
                      </Button>
                    </div>
                  )}
                </Form>
              </Card.Row>

              <Spacer xs={1} />

              <Card.Row padded className={style.column}>
                <Typography is="span" type="swan" weight="bold">
                  <FormattedMessage id={translation.tabs.participants.title} />
                </Typography>
                <Spacer xs={2} />

                <Refresh>
                  {({ key, refresh }) => (
                    <FilterManager initialCriteria={{ ageRange: { min: null, max: null } }}>
                      {({ criteria, debouncedUpdate }) => (
                        <React.Fragment>
                          <div className={style.searchAndButton}>
                            <TextInput
                              type="text"
                              id="search"
                              onChange={(event) => debouncedUpdate('search', event.target.value)}
                              label={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
                              placeholder={intl.formatMessage({
                                id: translations.inputs.participantSearch.placeholder
                              })}
                              autoComplete="off"
                              className={style.search}
                              leadingIcon="search"
                            />

                            <Modal
                              modal={InviteParticipantsToSelfEvaluationModal}
                              teamId={team.id}
                              sessionId={id}
                              onSubmit={refresh}
                            >
                              {({ open }) => (
                                <Button fat appearance="orange" onClick={open}>
                                  <FormattedMessage id={translations.inputs.addParticipant} />
                                </Button>
                              )}
                            </Modal>
                          </div>

                          <Spacer xs={2} />

                          <Table
                            key={key}
                            id={`${JSON.stringify({ criteria })}`}
                            source={(page, number) =>
                              getSelfEvaluationParticipants(id, page, number, {
                                keyword: criteria.search
                              })
                            }
                            empty={() => (
                              <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                                <Typography is="span" type="swan" weight="bold">
                                  <FormattedMessage id={translation.tabs.participants.table.empty} />
                                </Typography>
                              </Placeholder>
                            )}
                            renderHeader={() => <ParticipantHeader />}
                            renderRow={(data) => (
                              <ParticipantRow
                                key={data.id}
                                sessionId={id}
                                participantRow={data}
                                teamId={team.id}
                                onRemove={refresh}
                              />
                            )}
                          />
                        </React.Fragment>
                      )}
                    </FilterManager>
                  )}
                </Refresh>
              </Card.Row>
            </React.Fragment>
          )
        }
      </LoadData>
    </Card>
  );
};

export default withRouter(Detail);
