import React from 'react';

import { SessionConsumer } from '@/components/Context/Session';
import Dropdown from '@/components/Dropdown';
import HeaderItem from '@/components/Header/HeaderItem';
import Picture from '@/components/Picture';
import Typography from '@/components/Typography';
import Icon from '@/components/Icon';

import ProfileDropdownCard from './ProfileDropdownCard';

import style from './ProfileDropdown.sass';

const ProfileDropdown: React.FC = () => (
  <SessionConsumer>
    {({ me, signOut }) => (
      <Dropdown
        renderMenu={() => <ProfileDropdownCard profile={me} signOut={signOut} />}
        dropdownClassName={style.dropdown}
      >
        {({ isOpen, open, close }) => (
          <HeaderItem onClick={isOpen ? close : open}>
            <Picture url={me.imageUrl} size="xs" type="teammate" />

            <Typography is="span" type="halcyon" className={style.text}>
              {`${me.firstName} ${me.lastName ? me.lastName : ''}`}
            </Typography>

            <Icon type={isOpen ? 'chevronUp' : 'chevronDown'} appearance="orange" />
          </HeaderItem>
        )}
      </Dropdown>
    )}
  </SessionConsumer>
);

export default ProfileDropdown;
