import * as yup from 'yup';

import { translations } from '@/locale';
import { STRING_LONG_MAX_LENGTH } from '@/constants';

export const emailSchema = yup
  .string()
  .email()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.email.label);

export const taxIdSchema = yup
  .string()
  .min(9)
  .max(10)
  .matches(/^[0-9]*$/, translations.validation.custom.taxId)
  .label(translations.inputs.taxId.label)
  .required();

export const phoneNumberSchema = yup
  .string()
  .matches(/^[0-9 +()-]+$/, translations.validation.custom.phoneNumber)
  .label(translations.inputs.phoneNumber.label);
