export enum TimeFrame {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export interface ReportFilters {
  end: string;
  start: string;
  teamIds?: string[];
  timeFrame?: TimeFrame;
  locationIds?: string[];
  labelIds?: string[];
  sessionMemberIds?: string[];
  ageGroupIds?: string[];
  sessionTypeIds?: string[];
}

export interface Period {
  periodOfYear: number;
  year: number;
  subPeriod: number;
}

export interface Label {
  identifier: string;
  name: string;
  translations?;
}

export interface ReportEntry<L, D = number> {
  data: D[];
  label: L;
}

export interface Periodical {
  periods: Period[];
}

export interface Data<D = number> extends Pick<ReportEntry<any, D>, 'data'> {}

export interface BarChartReportData<L = Label, D = number> {
  entries: ReportEntry<L, D>[];
}

export interface PieChartReportData<L = Label> extends Omit<ReportEntry<L>, 'label'> {
  labels: L[];
}

export interface DifferentiateTimeFrameComparisonReport {
  atStart: number;
  atEnd: number;
}
