import { withPagingParams } from '@/util';
import { authorizedApi } from '@/services/network/authorized-api';
import { Reminder, Page, CreateReminder, ReminderToNote, Sort, By } from '@/domains';

import { Order } from '@/components/Ordering';

export const getReminders: (page: number, size: number) => Promise<Page<Reminder>> = (page, size) =>
  authorizedApi.get<Page<Reminder>>(
    `/v1/reminders?${withPagingParams(page, size, {
      [Sort.By]: By.CreatedAt,
      [Sort.Order]: Order.Ascendent
    })}`
  );

export const createReminder: (reminder: CreateReminder) => Promise<any> = (reminder) =>
  authorizedApi.post<any>(`/v1/reminders`, reminder);

export const deleteReminder: (id: string) => Promise<any> = (id) => authorizedApi.delete<any>(`/v1/reminders/${id}`);

export const convertToNote: (id: string, noteDetails: ReminderToNote) => Promise<any> = (id, noteDetails) =>
  authorizedApi.post<any>(`/v1/notes/from-reminder`, { id, ...noteDetails });
