import { CreateNote, UpdateNote } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const createSessionNote: (sessionId: string, note: CreateNote) => Promise<any> = (sessionId, note) =>
  authorizedApi.post<any>(`/v1/notes`, { sessionId, ...note });

export const updateSessionNote: (noteId: string, note: UpdateNote) => Promise<any> = (noteId, note) =>
  authorizedApi.put<any>(`/v1/notes/${noteId}`, note);

export const deleteSessionNote: (noteId: string) => Promise<any> = (noteId) =>
  authorizedApi.delete<any>(`/v1/notes/${noteId}`);
