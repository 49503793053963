import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { SelfEvaluation } from '@/domains';
import { getParticipantSelfEvaluations } from '@/services/api/self-evaluations';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppConsumer } from '@/components/Context/App';

import SelfEvaluationsRow from './SelfEvaluationsRow';
import SelfEvaluationsHeader from './SelfEvaluationsHeader';

import style from './DigitalCoach.sass';

interface Params {
  id: string;
}

const DigitalCoach: React.FC<RouteComponentProps<Params> & WrappedComponentProps> = ({ intl, match }) => {
  const { id } = match.params;

  return (
    <AppConsumer>
      {({ team }) => (
        <Card.Row>
          <Card.Column sm={12}>
            <Spacer xs={2} />

            <Table
              id="self-evaluations"
              source={(page, number) => getParticipantSelfEvaluations(id, team.id, page, number, {})}
              empty={() => (
                <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                  <Typography is="span" type="swan" weight="bold">
                    <FormattedMessage id={translations.pages.digitalCoach.tabs.selfEvaluations.table.empty} />
                  </Typography>
                </Placeholder>
              )}
              renderHeader={() => <SelfEvaluationsHeader />}
              renderRow={(data: SelfEvaluation) => <SelfEvaluationsRow participantId={id} key={data.id} data={data} />}
            />
          </Card.Column>
        </Card.Row>
      )}
    </AppConsumer>
  );
};
export default injectIntl(withRouter(DigitalCoach));
