import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import {
  getParticipantImages,
  uploadParticipantImages,
  deleteParticipantImage
} from '@/services/api/participant-images';

import Card from '@/components/Card';
import LoadData from '@/components/LoadData';
import PhotoGallery from '@/components/PhotoGallery';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';

import style from './Images.sass';

interface Params {
  id: string;
}

const Images: React.FC<RouteComponentProps<Params>> = ({ match }) => (
  <Card.Row>
    <Card.Column sm={12} className={style.root}>
      <LoadData id={match.params.id} load={getParticipantImages}>
        {({ value, loading, reload }) => (
          <PhotoGallery
            size="xxl"
            images={value}
            loading={loading}
            empty={() => (
              <Placeholder image="questions" size="lg">
                <Typography is="span" type="halcyon" weight="bold">
                  <FormattedMessage id={translations.pages.teams.participants.details.images.empty} />
                </Typography>
              </Placeholder>
            )}
            onPhotosUpload={(photos) => uploadParticipantImages(match.params.id, photos).then(reload)}
            onPhotoDelete={(id: string) => deleteParticipantImage(id).then(reload)}
          />
        )}
      </LoadData>
    </Card.Column>
  </Card.Row>
);

export default withRouter(Images);
