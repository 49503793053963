import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { moodPallete } from '@/constants';
import { getMoodReportLabel } from '@/util';
import { OrganizationReport, ReportFilters } from '@/domains';
import { getOrganizationReports } from '@/services/api/organization-reports';

import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { PieChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const MoodSection: React.FC<Props> = ({ intl, criteria }) => (
  <Reports
    title={intl.formatMessage({
      id: translations.pages.main.reporting.moods.title
    })}
  >
    <LoadData
      load={() => getOrganizationReports<OrganizationReport.Mood>(criteria, OrganizationReport.ReportType.Mood)}
    >
      {({ value, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <Reports.Charts>
              <PieChart
                title={intl.formatMessage({
                  id: translations.pages.main.reporting.moods.registered
                })}
                name="moods"
                data={{
                  labels: value.evaluationsInQuadrants.labels.map((label) =>
                    intl.formatMessage({
                      id: translations.reportMood[getMoodReportLabel(label.type, label.state)]
                    })
                  ),
                  dataset: value.evaluationsInQuadrants.data
                }}
                options={{
                  maintainAspectRatio: false
                }}
                className={style.wide}
                height={279}
                pallete={moodPallete}
              />
            </Reports.Charts>
          )}
        />
      )}
    </LoadData>
  </Reports>
);

export default MoodSection;
