import React from 'react';
import classnames from 'classnames';
import { startOfDay, isEqual } from 'date-fns';

import Typography from '@/components/Typography';

import style from './Day.sass';

export interface Props {
  dayNumber: string;
  date: Date;
  isSelected: boolean;
  onSelectDate: (date: Date) => void;
}

class Day extends React.PureComponent<Props> {
  render() {
    const { dayNumber, date, isSelected } = this.props;
    const isToday = isEqual(startOfDay(new Date()), startOfDay(date));

    return (
      <div
        className={classnames(style.root, { [style.selected]: isSelected, [style.today]: isToday })}
        onClick={() => this.props.onSelectDate(date)}
      >
        <Typography is="span" type="hummingbird">
          {dayNumber}
        </Typography>
      </div>
    );
  }
}

export default Day;
