import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls, STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { translations } from '@/locale';
import { taxIdSchema } from '@/validation';
import { OrganizationSignUp } from '@/domains';
import { signUpOrganization } from '@/services/api/organization';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Link from '@/components/Link';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Checkbox from '@/components/Checkbox';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { withValidation } from '@/components/hoc/withValidation';

import questionMan from '@/assets/questionMan.png';

import style from './SignUp.sass';

const TextInputWithValidation = withValidation(TextInput);
const CheckboxWithValidation = withValidation(Checkbox);

const schema: yup.ObjectSchema<OrganizationSignUp & { agreement: boolean }> = yup.object({
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.organizationName.label)
    .required(),
  officialAddress: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.address.label)
    .required(),
  taxId: taxIdSchema
    .label(translations.inputs.taxId.label)
    .nullable()
    .notRequired(),
  contactPersonFirstName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.contactFirstName.label)
    .required(),
  contactPersonLastName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.contactLastName.label)
    .required(),
  contactPersonEmail: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.contactEmail.label)
    .required(),
  agreement: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.agreement.label)
    .required(),
  dataProcessingPolicy: yup
    .boolean()
    .oneOf([true], translations.validation.custom.acceptAgreement)
    .label(translations.inputs.dataProcessingPolicy.label)
    .required()
});

export interface Props extends RouteComponentProps {}

class SignUp extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    return (
      <React.Fragment>
        <Card className={style.card}>
          <div className={style.topSection}>
            <img src={questionMan} className={style.image} />

            <Typography is="span" type="swan" weight="bold">
              <FormattedMessage id={translations.pages.signUp.title} />
            </Typography>

            <Spacer xs={1} />

            <Typography is="span" type="halcyon" color="gray">
              <FormattedMessage id={translations.pages.signUp.subtitle} />
            </Typography>
          </div>

          <Spacer xs={3} />

          <Form
            schema={schema}
            subscription={{ submitError: true, submitting: true }}
            onSubmit={({ agreement, ...values }) =>
              signUpOrganization(values).then(() => this.props.history.push(urls.signUp.sent))
            }
          >
            {({ submitError, submitting }) => (
              <div className={style.form}>
                <div className={style.inputFields}>
                  <Form.Field
                    is={TextInputWithValidation}
                    id="name"
                    name="name"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.organizationName.label })}
                    placeholder={this.props.intl.formatMessage({
                      id: translations.inputs.organizationName.placeholder
                    })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="official-address"
                    name="officialAddress"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.address.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.address.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="tax-id"
                    name="taxId"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.taxId.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.taxId.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="contact-person-first-name"
                    name="contactPersonFirstName"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.contactFirstName.label })}
                    placeholder={this.props.intl.formatMessage({
                      id: translations.inputs.contactFirstName.placeholder
                    })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="contact-person-last-name"
                    name="contactPersonLastName"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.contactLastName.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.contactLastName.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="contact-person-email"
                    name="contactPersonEmail"
                    type="text"
                    label={this.props.intl.formatMessage({ id: translations.inputs.contactEmail.label })}
                    placeholder={this.props.intl.formatMessage({ id: translations.inputs.contactEmail.placeholder })}
                    readOnly={submitting}
                  />
                </div>

                {!!submitError && (
                  <React.Fragment>
                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}
                <div className={style.agreements}>
                  <Form.Field
                    is={CheckboxWithValidation}
                    id="agreement"
                    name="agreement"
                    appearance="square"
                    type="checkbox"
                    readOnly={submitting}
                  >
                    <Typography is="span" type="hummingbird" color="gray">
                      <FormattedMessage
                        id={translations.inputs.agreement.content}
                        values={{
                          terms: (children) => (
                            <Link to={urls.terms} external appearance="orange">
                              {children}
                            </Link>
                          ),
                          privacy: (children) => (
                            <Link to={urls.privacyPolicy} external appearance="orange">
                              {children}
                            </Link>
                          )
                        }}
                      />
                    </Typography>
                  </Form.Field>

                  <Spacer xs={2} />

                  <Form.Field
                    is={CheckboxWithValidation}
                    id="dataProcessingPolicy"
                    name="dataProcessingPolicy"
                    appearance="square"
                    type="checkbox"
                    readOnly={submitting}
                  >
                    <Typography is="span" type="hummingbird" color="gray">
                      <FormattedMessage
                        id={translations.inputs.dataProcessingPolicy.content}
                        values={{
                          policy: (children) => (
                            <Link to={urls.dataProcessingPolicy} external appearance="orange">
                              {children}
                            </Link>
                          )
                        }}
                      />
                    </Typography>
                  </Form.Field>
                </div>

                <Spacer xs={3} />

                <Button type="submit" loading={submitting} appearance="orange">
                  <FormattedMessage id={translations.pages.signUp.signUpButton} />
                </Button>
              </div>
            )}
          </Form>
        </Card>

        <div className={style.redirect}>
          <Typography is="span" type="halcyon">
            <FormattedMessage
              id={translations.pages.signUp.haveAccountSignIn}
              values={{
                a: (children) => (
                  <Link to={urls.signIn} appearance="orange">
                    {children}
                  </Link>
                )
              }}
            />
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SignUp);
