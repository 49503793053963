import React from 'react';

import Typography from '@/components/Typography';
import Spacer from '@/components/Spacer';
import ErrorBoundary from '@/components/ErrorBoundary';

import Charts from './Charts';
import Notes from './Notes';
import ItemsContainer from './ItemsContainer';
import Item from './Item';

export interface Props {
  title?: string;
  className?: string;
}

export default class Reports extends React.PureComponent<Props> {
  static Charts = Charts;
  static Notes = Notes;
  static ItemsContainer = ItemsContainer;
  static Item = Item;

  render() {
    const { title, className, children } = this.props;

    return (
      <ErrorBoundary>
        <div className={className}>
          <Typography is="h1" type="corvus" weight="bold">
            {title}
          </Typography>

          <Spacer xs={2} />

          {children}
        </div>
      </ErrorBoundary>
    );
  }
}
