import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage, FormattedDate } from 'react-intl';

import { Note } from '@/domains';
import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { getLifeEventNotes } from '@/services/api/location-reports';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Table from '@/components/StatelessTable';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';
import PictureWithData from '@/components/PictureWithData';
import BoxedContent from '@/components/BoxedContent';

import style from './LocationLifeEventNotesTable.sass';

interface Props {
  locationId: string;
}

const LocationLifeEventNotesTable: React.FC<Props & WrappedComponentProps> = ({ intl, locationId }) => {
  return (
    <InfiniteScrollableTable
      source={(page, number) => getLifeEventNotes(locationId, page, number)}
      renderHeader={() => (
        <Table.Header>
          <Table.Data sm={5}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.header.title} />
          </Table.Data>

          <Table.Data sm={4}>
            <FormattedMessage
              id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.header.participant}
            />
          </Table.Data>

          <Table.Data sm={3}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.header.session} />
          </Table.Data>

          <Table.Data sm={3}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.header.date} />
          </Table.Data>

          <Table.Data sm={3}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.header.topic} />
          </Table.Data>
        </Table.Header>
      )}
      empty={() => (
        <Placeholder image="questions" size="md" className={style.empty}>
          <Typography is="span" type="halcyon" weight="bold">
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.none} />
          </Typography>
        </Placeholder>
      )}
      listClassName={style.table}
    >
      {(data) => (
        <React.Fragment>
          {data.map((note: Note, index) => (
            <Typography key={index} is={(props) => <Table.Row is="li" {...props} />} type="halcyon" weight="bold">
              <Table.Data sm={5} breakWords>
                {note.title}
              </Table.Data>

              <Table.Data sm={4}>
                {note.participant && (
                  <PictureWithData
                    size="md"
                    url={note.participant.imageUrl}
                    blueText={note.participant.nickname}
                    grayText={
                      note.participant.birthDate
                        ? intl.formatMessage(
                            { id: translations.pages.teams.participants.list.age },
                            { years: getAgeFromBirthdate(note.participant.birthDate) }
                          )
                        : null
                    }
                  />
                )}
              </Table.Data>

              <Table.Data sm={3}>
                <div>
                  {note.session.type.name}

                  <Typography is="div" type="halcyon" color="gray" weight="lighter">
                    {note.session.location.name}, &nbsp;
                    <FormattedDate value={note.session.start} day="2-digit" month="long" />
                  </Typography>
                </div>
              </Table.Data>

              <Table.Data sm={3}>
                <div>
                  <FormattedDate value={note.createdAt} day="2-digit" month="long" year="numeric" />

                  <Typography is="div" type="halcyon" color="gray" weight="lighter">
                    <FormattedMessage
                      id={translations.pages.teams.participants.details.notes.row.creationDate.atTime}
                      values={{
                        time: intl.formatTime(note.createdAt)
                      }}
                    />
                  </Typography>
                </div>
              </Table.Data>

              <Table.Data sm={3}>
                <BoxedContent appearance="blue">
                  {intl.formatMessage({ id: translations.noteTopics[note.topic] })}
                </BoxedContent>
              </Table.Data>
            </Typography>
          ))}
        </React.Fragment>
      )}
    </InfiniteScrollableTable>
  );
};

export default injectIntl(LocationLifeEventNotesTable);
