import { Image, MediaFileType } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { uploadImage } from '@/services/api/media';

export const getParticipantImages: (participantId: string) => Promise<Image[]> = (participantId) =>
  authorizedApi.get<Image[]>(`/v1/participants/${participantId}/pictures`);

export const uploadParticipantImages: (participantId: string, images: File[]) => Promise<any> = (
  participantId,
  images
) => {
  const promises: Promise<string>[] = [];

  images.forEach((image) => promises.push(uploadImage(image, MediaFileType.Participant)));

  return Promise.all(promises).then((urls) =>
    authorizedApi.post<any>(`/v1/participants/${participantId}/pictures`, {
      images: urls.map((url) => ({ imageUrl: url }))
    })
  );
};

export const deleteParticipantImage: (imageId: string) => Promise<any> = (imageId) =>
  authorizedApi.delete<any>(`/v1/participants/pictures/${imageId}`);
