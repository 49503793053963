import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import TextWithIcon from '@/components/TextWithIcon';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const LocationHeader: React.FC<{}> = () => {
  const { place, address, status } = translations.pages.teams.tabs.location.resultsHeader;
  return (
    <Card.Row className={cellStyle.header}>
      <TextWithIcon color="blue" icon="home" is="span" type="hummingbird" className={cellStyle.place}>
        <FormattedMessage id={place} />
      </TextWithIcon>
      <TextWithIcon color="blue" icon="locationPin" is="span" type="hummingbird" className={cellStyle.address}>
        <FormattedMessage id={address} />
      </TextWithIcon>
      <Typography is="span" type="hummingbird" color="blue" className={cellStyle.state}>
        <FormattedMessage id={status} />
      </Typography>
    </Card.Row>
  );
};

export default LocationHeader;
