import React from 'react';
import classnames from 'classnames';

import MenuGroup from './MenuGroup';
import MenuItem from './MenuItem';

import style from './Sidebar.sass';

export interface Props {
  className?: string;
}

export default class Sidebar extends React.PureComponent<Props> {
  static Group = MenuGroup;
  static Item = MenuItem;

  render() {
    return <div className={classnames(style.root, this.props.className)}>{this.props.children}</div>;
  }
}
