import React from 'react';

import { NotificationsConsumer } from '@/components/Context/Notifications';
import Dropdown from '@/components/Dropdown';
import HeaderItem from '@/components/Header/HeaderItem';
import BoxedIcon from '@/components/BoxedIcon';

import NotificationsDropdownCard from './NotificationsDropdownCard';

import style from './NotificationsDropdown.sass';

const NotificationsDropdown: React.FC = () => (
  <NotificationsConsumer>
    {({ open, close, isOpen, count }) => (
      <Dropdown
        isOpen={isOpen}
        onOpen={open}
        onClose={close}
        renderMenu={() => <NotificationsDropdownCard />}
        dropdownClassName={style.dropdown}
      >
        {({ isOpen, close, open }) => (
          <HeaderItem onClick={isOpen ? close : open}>
            <BoxedIcon type="bell" appearance="blue">
              {count !== 0 && <span className={style.count}>{count}</span>}
            </BoxedIcon>
          </HeaderItem>
        )}
      </Dropdown>
    )}
  </NotificationsConsumer>
);

export default NotificationsDropdown;
