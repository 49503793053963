import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { Reminder as DomainReminder } from '@/domains';
import { getReminders } from '@/services/api/reminder';

import If from '@/components/If';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import Loading from '@/components/Loading';
import Reminder from '@/components/Reminder';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import ErrorBoundary from '@/components/ErrorBoundary';
import InfiniteScroll from '@/components/InfiniteScroll';
import ReminderDetailModal from '@/components/Modals/ReminderDetailModal';
import CreateReminderModal from '@/components/Modals/CreateReminderModal';
import { NotificationsConsumer } from '@/components/Context/Notifications';

import style from './NotificationsDropdownCard.sass';

const NotificationsDropdownCard: React.FC = () => (
  <NotificationsConsumer>
    {({ setCount }) => (
      <Card className={style.root}>
        <Refresh>
          {({ key, refresh }) => (
            <ErrorBoundary>
              <InfiniteScroll
                key={key}
                source={(page, size) =>
                  getReminders(page, size).then((response) => {
                    setCount(response.totalNumberOfElements);
                    return response;
                  })
                }
                className={classnames(style.list, style.reminders)}
              >
                {({ data, loading, total }) => (
                  <React.Fragment>
                    <Card.Row>
                      <Card.Column sm={12}>
                        <Typography is="h6" type="halcyon">
                          <FormattedMessage id={translations.header.notificationsDropdown.title} />
                        </Typography>

                        <Spacer xs={1} />

                        {!loading && (
                          <Typography is="span" type="halcyon" weight="bold">
                            <FormattedMessage
                              id={translations.header.notificationsDropdown.unresolved}
                              values={{
                                count: total,
                                span: (children) => (
                                  <Typography is="span" type="halcyon" color="blue">
                                    {children}
                                  </Typography>
                                )
                              }}
                            />
                          </Typography>
                        )}
                      </Card.Column>
                    </Card.Row>

                    <Card.Row>
                      <Card.Column sm={12} padded={false}>
                        <If
                          condition={!loading && data.length === 0}
                          then={() => (
                            <Placeholder image="questions" size="xs">
                              <Typography is="span" type="halcyon" weight="bold">
                                <FormattedMessage id={translations.header.notificationsDropdown.none} />
                              </Typography>
                            </Placeholder>
                          )}
                        />

                        <div className={style.list}>
                          {data.map((reminder: DomainReminder) => (
                            <Modal
                              key={reminder.id}
                              reminder={reminder}
                              modal={ReminderDetailModal}
                              onReminderUpdated={refresh}
                            >
                              {({ open }) => <Reminder data={reminder} onClick={open} block hoverable />}
                            </Modal>
                          ))}
                        </div>

                        <Loading visible={loading} center className={style.loadingIndicator}>
                          <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                        </Loading>
                      </Card.Column>
                    </Card.Row>
                  </React.Fragment>
                )}
              </InfiniteScroll>

              <Spacer xs={1} />

              <div className={style.createReminderButton}>
                <Modal modal={CreateReminderModal} onReminderCreated={refresh}>
                  {({ open }) => (
                    <Button
                      appearance="ghost"
                      block
                      className={style.createReminderButton}
                      type="button"
                      onClick={open}
                    >
                      <FormattedMessage id={translations.header.notificationsDropdown.create} />
                    </Button>
                  )}
                </Modal>
              </div>
            </ErrorBoundary>
          )}
        </Refresh>
      </Card>
    )}
  </NotificationsConsumer>
);

export default NotificationsDropdownCard;
