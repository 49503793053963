import React from 'react';
import classnames from 'classnames';

import style from './Icon.sass';
import icon from './style.css';

export type Type = keyof typeof icon;

export interface Props {
  className?: string;
  type: Type;
  appearance?: 'orange' | 'red' | 'gray' | 'blue' | 'white' | 'green' | 'orangeHollow';
}

export default class Icon extends React.PureComponent<Props> {
  render() {
    const { appearance, className, type, ...rest } = this.props;

    return <i className={classnames(style.root, icon[type], style[appearance], className)} {...rest} />;
  }
}
