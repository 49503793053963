import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { By, SelfEvaluation, Sort } from '@/domains';
import { getSelfEvaluations } from '@/services/api/self-evaluations';

import If from '@/components/If';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppConsumer } from '@/components/Context/App';
import CreateSelfEvaluationModal from '@/components/Modals/CreateSelfEvaluationModal';

import SelfEvaluationsRow from './SelfEvaluationsRow';
import SelfEvaluationsHeader from './SelfEvaluationsHeader';

import style from './SelfEvaluations.sass';
import { Order } from '@/components/Ordering';

interface Params {
  id: string;
}

export interface Props {}

class SelfEvaluations extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <AppConsumer>
        {({ team, loading }) => (
          <If
            condition={loading}
            then={() => null}
            else={() => (
              <Refresh>
                {({ key, refresh }) => (
                  <div className={style.root}>
                    <React.Fragment>
                      <div className={style.button}>
                        <Modal modal={CreateSelfEvaluationModal} teamId={team.id} onSubmit={refresh}>
                          {({ open }) => (
                            <Button fat appearance="orange" onClick={open}>
                              <FormattedMessage
                                id={translations.pages.digitalCoach.tabs.selfEvaluations.newSelfEvaluation}
                              />
                            </Button>
                          )}
                        </Modal>
                      </div>

                      <Spacer xs={3} />

                      <Table
                        key={key}
                        id="self-evaluations"
                        source={(page, number) =>
                          getSelfEvaluations(team.id, page, number, {
                            [Sort.By]: By.CreatedAt,
                            [Sort.Order]: Order.Descendent
                          })
                        }
                        empty={() => (
                          <Placeholder image="people" size="md" className={style.tablePlaceholder}>
                            <Typography is="span" type="swan" weight="bold">
                              <FormattedMessage id={translations.pages.digitalCoach.tabs.selfEvaluations.table.empty} />
                            </Typography>
                          </Placeholder>
                        )}
                        renderHeader={() => <SelfEvaluationsHeader />}
                        renderRow={(data: SelfEvaluation) => <SelfEvaluationsRow key={data.id} data={data} />}
                      />
                    </React.Fragment>
                  </div>
                )}
              </Refresh>
            )}
          />
        )}
      </AppConsumer>
    );
  }
}

export default injectIntl(withRouter(SelfEvaluations));
