import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Filter } from '@/domains';
import { translations } from '@/locale';

import { getParticipantsForSelect } from '@/services/api/participant';

import LoadableSelect, { Props as LoadableSelectProps } from '../LoadableSelect';
import { AppContext } from '@/components/Context/App';

interface Props extends Omit<LoadableSelectProps, 'source'> {}

const ParticipantSelect: React.FC<Props & WrappedComponentProps> = ({ intl, ...props }) => {
  const { team } = React.useContext(AppContext);
  return (
    team && (
      <LoadableSelect
        source={() =>
          getParticipantsForSelect(team.id).then((page) => [
            { id: Filter.All as string, name: intl.formatMessage({ id: translations.miscellaneous.all }) },
            ...page.content.map((participant) => ({
              id: participant.id,
              name: `${participant.nickname}`
            }))
          ])
        }
        {...props}
      />
    )
  );
};

export default injectIntl(ParticipantSelect);
