import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage, FormattedDate } from 'react-intl';

import { Note } from '@/domains';
import { translations } from '@/locale';
import { getSessionNotes } from '@/services/api/location-reports';

import InfiniteScrollableTable from '@/components/InfiniteScrollableTable';
import Table from '@/components/StatelessTable';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';

import style from './LocationSessionNotesTable.sass';

interface Props {
  locationId: string;
}

const LocationSessionNotesTable: React.FC<Props & WrappedComponentProps> = ({ intl, locationId }) => {
  return (
    <InfiniteScrollableTable
      source={(page, number) => getSessionNotes(locationId, page, number)}
      renderHeader={() => (
        <Table.Header>
          <Table.Data sm={7}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.sessionNotes.table.header.title} />
          </Table.Data>

          <Table.Data sm={7}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.sessionNotes.table.header.session} />
          </Table.Data>

          <Table.Data sm={4}>
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.sessionNotes.table.header.date} />
          </Table.Data>
        </Table.Header>
      )}
      empty={() => (
        <Placeholder image="questions" size="sm" className={style.empty}>
          <Typography is="span" type="halcyon" weight="bold">
            <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.table.none} />
          </Typography>
        </Placeholder>
      )}
      listClassName={style.table}
    >
      {(data) => (
        <React.Fragment>
          {data.map((note: Note, index) => (
            <Typography key={index} is={(props) => <Table.Row is="li" {...props} />} type="halcyon" weight="bold">
              <Table.Data sm={7} breakWords>
                {note.title}
              </Table.Data>

              <Table.Data sm={7}>
                <div>
                  {note.session.type.name}

                  <Typography is="div" type="halcyon" color="gray" weight="lighter">
                    {note.session.location.name}, &nbsp;
                    <FormattedDate value={note.session.start} day="2-digit" month="long" />
                  </Typography>
                </div>
              </Table.Data>

              <Table.Data sm={4}>
                <div>
                  <FormattedDate value={note.createdAt} day="2-digit" month="long" year="numeric" />

                  <Typography is="div" type="halcyon" color="gray" weight="lighter">
                    <FormattedMessage
                      id={translations.pages.teams.participants.details.notes.row.creationDate.atTime}
                      values={{
                        time: intl.formatTime(note.createdAt)
                      }}
                    />
                  </Typography>
                </div>
              </Table.Data>
            </Typography>
          ))}
        </React.Fragment>
      )}
    </InfiniteScrollableTable>
  );
};

export default injectIntl(LocationSessionNotesTable);
