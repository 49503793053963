export enum Sort {
  By = 'sortOptions[0].by',
  ByValue = 'sortOptions[0].by.value',
  Order = 'sortOptions[0].order'
}

export enum By {
  CreatedAt = 'CREATED_AT',
  EndDate = 'END_DATE'
}
