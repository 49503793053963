import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const DataOptionHeader: React.FC<{}> = () => {
  const { name, icon, category, enabled } = translations.dataCustomisation.dataOptions.header;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={cellStyle.name}>
        <FormattedMessage id={name} />
      </div>
      <div className={cellStyle.icon}>
        <FormattedMessage id={icon} />
      </div>
      <div className={cellStyle.category}>
        <FormattedMessage id={category} />
      </div>
      <div className={cellStyle.enabled}>
        <FormattedMessage id={enabled} />
      </div>
    </Card.Row>
  );
};

export default DataOptionHeader;
