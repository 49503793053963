import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import classnames from 'classnames';

import { Message } from '@/domains';
import { translations } from '@/locale';
import { getTime, getDateFormat } from '@/util';
import { messageStatusColor } from '@/constants/message-status';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import BoxedContent from '@/components/BoxedContent';
import TextWithIcon from '@/components/TextWithIcon';
import PictureWithData from '@/components/PictureWithData';
import MessageModal from '@/components/Modals/MessageModal';

import cellStyle from '../cellStyle.sass';

import style from './MessageRow.sass';

export interface Props {
  data: Message;
  refresh: () => any;
}

const MessageRow: React.FC<Props & WrappedComponentProps> = ({ data, refresh, intl, ...rest }) => (
  <Modal modal={MessageModal} data={data} onClose={refresh}>
    {({ open }) => (
      <Card.Row is="li" className={classnames(cellStyle.container, style.row)} {...rest} onClick={open}>
        <Typography is="div" type="halcyon" className={classnames(style.message, cellStyle.message)}>
          {data.description && data.description}
        </Typography>

        <PictureWithData
          size="md"
          url={data.imageUrl}
          blueText={
            data.createdBy.firstName && data.createdBy.lastName
              ? `${data.createdBy.firstName} ${data.createdBy.lastName}`
              : `${data.createdBy.email}`
          }
          grayText={intl.formatMessage({ id: translations.roles[data.createdBy.role] })}
          className={cellStyle.author}
        />

        <TextWithIcon icon="participants" is="span" type="halcyon" iconColor="blue" className={cellStyle.participants}>
          {data.noOfParticipants && data.noOfParticipants}
        </TextWithIcon>

        <div className={classnames(cellStyle.creationDate, style.date)}>
          <Typography is="span" type="halcyon">
            {getDateFormat(data.createdAt, 'MMM d, yyyy')}
          </Typography>

          <Typography is="span" type="hummingbird" color="gray">
            <FormattedMessage
              id={translations.pages.digitalCoach.tabs.messages.table.item.createdAt}
              values={{ time: getTime(data.createdAt) }}
            />
          </Typography>
        </div>

        <Typography
          is={BoxedContent}
          appearance={messageStatusColor[data.state]}
          className={cellStyle.status}
          type="sparrow"
        >
          {data.state && <FormattedMessage id={translations.messageState[data.state]} />}
        </Typography>
      </Card.Row>
    )}
  </Modal>
);

export default injectIntl(MessageRow);
