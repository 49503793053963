import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import style from './LazyRender.sass';

interface Props {
  height: number;
}

interface State {
  isVisible: boolean;
}

export default class LazyRender extends React.PureComponent<Props, State> {
  state: State = {
    isVisible: null
  };

  render() {
    const { height, children } = this.props;
    const { isVisible } = this.state;

    return (
      // TODO!: Replace this with aspect ratio
      <div style={{ minHeight: height }}>
        <VisibilitySensor
          partialVisibility
          offset={{ top: 20 }}
          active={!this.state.isVisible}
          onChange={(isVisible) => this.setState({ isVisible: isVisible })}
        >
          <React.Fragment>
            <div className={classnames(style.placeholder, { [style.hidden]: isVisible })} />

            {isVisible && children}
          </React.Fragment>
        </VisibilitySensor>
      </div>
    );
  }
}
