import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import { translations } from '@/locale';
import { getPaymentMethod, removePaymentMethod } from '@/services/api/billing-info';
import { getStripePromise } from '@/services/stripe';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';
import Modal from '@/components/Modal';
import PaymentMethodModal from '@/components/Modals/PaymentMethodModal';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import TextWithIcon from '@/components/TextWithIcon';
import ConfirmationModal from '@/components/Modals/ConfirmationModal';

import style from './PaymentDetails.sass';

const PaymentDetails: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <Card.Row className={style.root}>
      <Card.Column>
        <LoadData load={() => getPaymentMethod()}>
          {({ value, error, loading, reload }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center overlay>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <React.Fragment>
                  <Typography is="h6" type="sparrow" weight="bold">
                    <FormattedMessage id={translations.pages.organization.licenses.tabs.billingInfo.paymentDetails} />
                  </Typography>

                  <Spacer xs={2} />
                  <If
                    condition={value && Object.keys(value).length !== 0}
                    then={() => (
                      <React.Fragment>
                        <TextWithIcon icon="creditCard" iconColor="blue" boxed is="span" type="sparrow" weight="bold">
                          {`${value.brand} •••• ${value.last4}`}
                        </TextWithIcon>

                        <Spacer xs={1} />

                        <Typography is="span" type="hummingbird" color="gray" className={style.detailSpacing}>
                          {`${intl.formatMessage({
                            id: translations.pages.organization.licenses.tabs.billingInfo.expires
                          })} ${intl.formatDate(new Date(value.expYear, value.expMonth - 1), {
                            year: '2-digit',
                            month: '2-digit'
                          })}`}
                        </Typography>

                        <Spacer xs={1} />

                        <Modal
                          modal={ConfirmationModal}
                          title={intl.formatMessage({ id: translations.modals.removeCardModal.title })}
                          message={intl.formatMessage({ id: translations.modals.removeCardModal.message })}
                          confirmMessage={intl.formatMessage({
                            id: translations.modals.removeCardModal.removeButtonMessage
                          })}
                          cancelMessage={intl.formatMessage({
                            id: translations.modals.removeCardModal.cancelButtonMessage
                          })}
                          onConfirm={() => removePaymentMethod().then(() => reload())}
                        >
                          {({ open }) => (
                            <Button className={style.detailSpacing} onClick={() => open()}>
                              <Typography is="span" type="hummingbird" color="orange" weight="bold">
                                <FormattedMessage
                                  id={translations.pages.organization.licenses.tabs.billingInfo.removeCard}
                                />
                              </Typography>
                            </Button>
                          )}
                        </Modal>
                      </React.Fragment>
                    )}
                    else={() => (
                      <If
                        condition={error}
                        then={() => (
                          <React.Fragment>
                            <TextWithIcon icon="alert" iconColor="red" boxed is="span" type="hummingbird">
                              <FormattedMessage
                                id={translations.pages.organization.licenses.tabs.billingInfo.loadingError}
                              />
                            </TextWithIcon>

                            <Spacer xs={1} />

                            <Button className={style.detailSpacing} onClick={() => reload()}>
                              <Typography is="span" type="hummingbird" color="orange" weight="bold">
                                <FormattedMessage
                                  id={translations.pages.organization.licenses.tabs.billingInfo.tryAgain}
                                />
                              </Typography>
                            </Button>
                          </React.Fragment>
                        )}
                        else={() => (
                          <Elements stripe={getStripePromise()}>
                            <Modal modal={PaymentMethodModal} onSuccess={reload}>
                              {({ open }) => (
                                <ElementsConsumer>
                                  {({ stripe }) => (
                                    <Button className={style.addPaymentMethodButton} onClick={() => stripe && open()}>
                                      <BoxedIcon type="plus" appearance="orange" size="small" className={style.icon} />
                                      <Typography is="span" type="sparrow" color="orange" weight="bold">
                                        <FormattedMessage
                                          id={
                                            translations.pages.organization.licenses.tabs.billingInfo.addPaymentMethod
                                          }
                                        />
                                      </Typography>
                                    </Button>
                                  )}
                                </ElementsConsumer>
                              )}
                            </Modal>
                          </Elements>
                        )}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            />
          )}
        </LoadData>
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(PaymentDetails);
