import React from 'react';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locale';

import {
  StreetSession as StreetSessionModel,
  Sort,
  By,
  KPI as KPIModel,
  KPIPeriod,
  KPIDomain,
  KPIItem,
  TeamBase,
  Me
} from '@/domains';
import { urls, Role } from '@/constants';
import { getOrganizationKpis } from '@/services/api/kpi';
import { getStreetSessions } from '@/services/api/street-session';

import { SessionConsumer } from '@/components/Context/Session';
import { NotificationsConsumer } from '@/components/Context/Notifications';
import Spacer from '@/components/Spacer';
import If from '@/components/If';
import EntityPreview from '@/components/EntityPreview';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import KPI from '@/components/KPI';
import Loading from '@/components/Loading';
import Button from '@/components/Button';
import Placeholder from '@/components/Placeholder';
import Link from '@/components/Link';
import { AppConsumer } from '@/components/Context/App';
import Table from '@/components/Table';
import { Order } from '@/components/Ordering';
import { LanguageContext } from '@/components/Language';

import StreetSessionHeader from '@/page/Team/StreetSessions/List/StreetSessionHeader';
import StreetSessionRow from '@/page/Team/StreetSessions/List/StreetSessionRow';

import style from './Dashboard.sass';

const mapKPIItem = (domain: KPIDomain, kpi: KPIModel): KPIItem => ({
  domain,
  current: kpi.current,
  deviation: kpi.current - kpi.previous
});

const DashboardPage: React.FC = () => {
  const { language } = React.useContext(LanguageContext);
  const canViewKpiData = (me: Me, team: TeamBase): boolean =>
    [Role.Volunteer, Role.StreetWorker, Role.TeamLeader].includes(me.role) ? !!team : true;

  return (
    <SessionConsumer>
      {({ me, organization }) => (
        <AppConsumer>
          {({ team, loading: appLoading }) => (
            <NotificationsConsumer>
              {({ open, close, isOpen, count }) => (
                <div className={style.root}>
                  <Spacer xs={2} />

                  {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

                  <Spacer xs={4} />

                  <div className={style.cards}>
                    <Card className={style.statistics}>
                      <Card.Row>
                        <Card.Column sm={12}>
                          <Typography is="h1" type="flamingo">
                            <FormattedMessage
                              id={translations.pages.main.dashboard.statistics.title}
                              values={{
                                name: me.firstName,
                                span: (children) => (
                                  <Typography is="span" type="flamingo" color="blue">
                                    {children}
                                  </Typography>
                                )
                              }}
                            />
                          </Typography>

                          <Spacer xs={1} />

                          <If
                            condition={!appLoading && canViewKpiData(me, team)}
                            then={() => (
                              <Typography is="span" type="halcyon">
                                <FormattedMessage id={translations.pages.main.dashboard.statistics.subtitle} />
                              </Typography>
                            )}
                          />

                          <Spacer xs={5} />

                          {!appLoading && (
                            <If
                              condition={!canViewKpiData(me, team)}
                              then={() => (
                                <Placeholder image="questions" size="md">
                                  <Typography is="span" type="halcyon" weight="bold">
                                    <FormattedMessage id={translations.pages.main.dashboard.statistics.noTeam} />
                                  </Typography>
                                </Placeholder>
                              )}
                              else={() => (
                                <KPI key={team ? team.id : null}>
                                  <Typography is="h6" type="sparrow" color="gray" weight="bold">
                                    <FormattedMessage id={translations.pages.main.dashboard.statistics.month} />
                                  </Typography>

                                  <Spacer xs={1} />

                                  <If
                                    condition={!appLoading}
                                    then={() => (
                                      <LoadData
                                        load={() => getOrganizationKpis(KPIPeriod.Monthly, [team ? team.id : null])}
                                      >
                                        {({ value, loading }) => (
                                          <KPI.Board>
                                            <If
                                              condition={loading}
                                              then={() => (
                                                <Loading visible={loading} center>
                                                  <Loading.Indicator
                                                    size={20}
                                                    borderWidth={2}
                                                    fullCircle
                                                    color="#BCBDC3"
                                                  />
                                                </Loading>
                                              )}
                                              else={() => (
                                                <React.Fragment>
                                                  <KPI.Item
                                                    {...mapKPIItem(KPIDomain.Participant, value.participants)}
                                                  />

                                                  <KPI.Item {...mapKPIItem(KPIDomain.Session, value.sessions)} />

                                                  <KPI.Item {...mapKPIItem(KPIDomain.Activity, value.activities)} />
                                                </React.Fragment>
                                              )}
                                            />
                                          </KPI.Board>
                                        )}
                                      </LoadData>
                                    )}
                                  />

                                  <Spacer xs={2} />

                                  <Typography is="h6" type="sparrow" color="gray" weight="bold">
                                    <FormattedMessage id={translations.pages.main.dashboard.statistics.year} />
                                  </Typography>

                                  <Spacer xs={1} />

                                  <If
                                    condition={!appLoading}
                                    then={() => (
                                      <LoadData
                                        load={() => getOrganizationKpis(KPIPeriod.Yearly, [team ? team.id : null])}
                                      >
                                        {({ value, loading }) => (
                                          <KPI.Board>
                                            <If
                                              condition={loading}
                                              then={() => (
                                                <Loading visible={loading} center>
                                                  <Loading.Indicator
                                                    size={20}
                                                    borderWidth={2}
                                                    fullCircle
                                                    color="#BCBDC3"
                                                  />
                                                </Loading>
                                              )}
                                              else={() => (
                                                <React.Fragment>
                                                  <KPI.Item
                                                    {...mapKPIItem(KPIDomain.Participant, value.participants)}
                                                  />

                                                  <KPI.Item {...mapKPIItem(KPIDomain.Session, value.sessions)} />

                                                  <KPI.Item {...mapKPIItem(KPIDomain.Activity, value.activities)} />
                                                </React.Fragment>
                                              )}
                                            />
                                          </KPI.Board>
                                        )}
                                      </LoadData>
                                    )}
                                  />
                                </KPI>
                              )}
                            />
                          )}
                        </Card.Column>
                      </Card.Row>
                    </Card>

                    <Card className={style.reminders}>
                      <Card.Row className={style.fullHeight}>
                        <Card.Column sm={12}>
                          <Placeholder
                            image={count === 0 ? 'bell' : 'reminders'}
                            size="lg"
                            className={style.fullHeight}
                          >
                            <Typography is="span" type="corvus" weight="bold">
                              <FormattedMessage
                                id={translations.header.notificationsDropdown.unresolved}
                                values={{
                                  count: count,
                                  span: (children) => (
                                    <Typography is="span" type="corvus" color="blue">
                                      {children}
                                    </Typography>
                                  )
                                }}
                              />
                            </Typography>

                            <Spacer xs={1} />

                            <Typography is="span" type="halcyon" className={style.reminderSubtitle}>
                              <FormattedMessage
                                id={translations.pages.main.dashboard.reminders.subtitle}
                                values={{ count, br: <br /> }}
                              />
                            </Typography>

                            <Spacer xs={2} />

                            <Button
                              appearance="orange"
                              onClick={!isOpen ? open : close}
                              className={style.seeRemindersButton}
                            >
                              <FormattedMessage id={translations.pages.main.dashboard.reminders.button} />
                            </Button>
                          </Placeholder>
                        </Card.Column>
                      </Card.Row>
                    </Card>
                  </div>

                  <AppConsumer>
                    {({ team }) => (
                      <If
                        condition={!!team && me.role !== Role.Admin}
                        then={() => (
                          <Card className={style.upcomingSessions}>
                            <Card.Row>
                              <Card.Column sm={12}>
                                <div className={style.titleAndSessionLink}>
                                  <Typography is="span" type="corvus" weight="bold">
                                    <FormattedMessage id={translations.pages.main.dashboard.sessions.title} />
                                  </Typography>

                                  <Link to={urls.sessions.list} appearance="orange" className={style.sessionLink}>
                                    <FormattedMessage id={translations.pages.main.dashboard.sessions.all} />
                                  </Link>
                                </div>

                                <Spacer xs={3} />

                                <Table
                                  source={() =>
                                    getStreetSessions(team.id, 1, 3, {
                                      after: new Date().toISOString(),
                                      [Sort.By]: By.CreatedAt,
                                      [Sort.Order]: Order.Ascendent
                                    })
                                  }
                                  empty={() => (
                                    <Placeholder image="cards" size="md" className={style.sessionsPlaceholder}>
                                      <Typography is="span" type="corvus" weight="bold">
                                        <FormattedMessage id={translations.pages.main.dashboard.sessions.empty} />
                                      </Typography>
                                    </Placeholder>
                                  )}
                                  renderHeader={() => <StreetSessionHeader />}
                                  renderRow={(data: StreetSessionModel) => (
                                    <StreetSessionRow language={language} key={data.id} streetSession={data} />
                                  )}
                                  listClassName={style.table}
                                />
                              </Card.Column>
                            </Card.Row>
                          </Card>
                        )}
                      />
                    )}
                  </AppConsumer>
                </div>
              )}
            </NotificationsConsumer>
          )}
        </AppConsumer>
      )}
    </SessionConsumer>
  );
};

export default DashboardPage;
