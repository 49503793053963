import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { rules } from '@/constants';
import { translations } from '@/locale';
import { checkIfWeShouldDeselectAll } from '@/util';
import { getParticipants } from '@/services/api/participant';
import { GenderFilter, ParticipantListItem, Sort } from '@/domains';
import { getExportParticipantsRedirectUrl, getExportEvaluationsRedirectUrl } from '@/services/api/export-url';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import Refresh from '@/components/Refresh';
import HasRole from '@/components/HasRole';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import ExportData from '@/components/ExportData';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import { AppContext } from '@/components/Context/App';
import AgeRangeFilter from '@/components/AgeRangeFilter';
import DateRangeFilter from '@/components/DateRangeFilter';
import NewParticipantModal from '@/components/Modals/NewParticipantModal';
import GenderFilterSelect from '@/components/Selects/GenderFilterSelect';
import FilterManagerWithPersistence from '@/components/FilterManagerWithPersistence';

import ParticipantRow from './ParticipantRow';
import ParticipantHeader from './ParticipantHeader';

import style from './List.sass';

const List: React.FC<WrappedComponentProps> = ({ intl }) => {
  const { team, loading } = React.useContext(AppContext);

  return (
    <Card.Row>
      <Card.Column sm={12} className={style.root}>
        {loading ? (
          <Loading visible={loading} center overlay>
            <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
          </Loading>
        ) : !team ? (
          <Placeholder image="questions" size="lg" className={style.placeholder}>
            <Typography is="span" type="swan" weight="bold">
              <FormattedMessage id={translations.pages.teams.participants.list.none} />
            </Typography>
          </Placeholder>
        ) : (
          <Refresh>
            {({ key, refresh }) => (
              <React.Fragment>
                <Typography is="div" type="swan" weight="bold" className={style.title}>
                  <FormattedMessage id={translations.pages.teams.participants.list.title} />
                </Typography>

                <Spacer xs={2} />

                <FilterManagerWithPersistence
                  storageItem="participants"
                  initialCriteria={{
                    genders: [GenderFilter.All],
                    ageRange: { min: null, max: null },
                    birthdateRange: { start: null, end: null },
                    lastInterventionRange: { start: null, end: null }
                  }}
                  exceptionKeys={['search']}
                >
                  {({ criteria, updateCriteria, debouncedUpdate }) => (
                    <React.Fragment>
                      <div className={style.searchAndButton}>
                        <div className={style.flexRow}>
                          <TextInput
                            type="text"
                            id="search"
                            onChange={(event) => debouncedUpdate('search', event.target.value)}
                            label={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
                            placeholder={intl.formatMessage({
                              id: translations.inputs.participantSearch.placeholder
                            })}
                            autoComplete="off"
                            className={style.search}
                            leadingIcon="search"
                          />

                          <div className={style.gender}>
                            <GenderFilterSelect
                              value={criteria.genders}
                              appearance="normal"
                              label={intl.formatMessage({ id: translations.inputs.gender.label })}
                              onChange={(value) => updateCriteria('genders', checkIfWeShouldDeselectAll([...value]))}
                              multiple
                            />
                          </div>
                        </div>

                        <Modal modal={NewParticipantModal} onProfileCreated={refresh}>
                          {({ open }) => (
                            <Button fat appearance="orange" onClick={open}>
                              <FormattedMessage id={translations.pages.teams.participants.list.create} />
                            </Button>
                          )}
                        </Modal>
                      </div>

                      <Spacer xs={2} />

                      <div className={style.flexRow}>
                        <Typography is="label" type="halcyon">
                          <FormattedMessage id={translations.inputs.ageRange.label} />
                        </Typography>

                        <AgeRangeFilter
                          value={criteria.ageRange}
                          placeholder={intl.formatMessage({ id: translations.inputs.ageRange.placeholder })}
                          onChange={(value) => updateCriteria('ageRange', value)}
                          className={style.range}
                        />

                        <Typography is="label" type="halcyon" className={style.label}>
                          <FormattedMessage id={translations.inputs.dateRangeFilter.lastInterventionDateRange.label} />
                        </Typography>

                        <DateRangeFilter
                          value={criteria.lastInterventionRange}
                          label={intl.formatMessage({
                            id: translations.inputs.dateRangeFilter.lastInterventionDateRange.label
                          })}
                          placeholder={intl.formatMessage({
                            id: translations.inputs.dateRangeFilter.lastInterventionDateRange.placeholder
                          })}
                          onChange={(value) => updateCriteria('lastInterventionRange', value)}
                          className={style.range}
                        />

                        <Typography is="label" type="halcyon" className={style.label}>
                          <FormattedMessage id={translations.inputs.dateRangeFilter.birthdateRange.label} />
                        </Typography>

                        <DateRangeFilter
                          value={criteria.birthdateRange}
                          label={intl.formatMessage({
                            id: translations.inputs.dateRangeFilter.birthdateRange.label
                          })}
                          placeholder={intl.formatMessage({
                            id: translations.inputs.dateRangeFilter.birthdateRange.placeholder
                          })}
                          onChange={(value) => updateCriteria('birthdateRange', value)}
                          className={style.range}
                        />
                      </div>

                      <Spacer xs={2} />

                      <HasRole to={rules.SESSION_EXPORT}>
                        <div className={style.download}>
                          <ExportData redirect={(token) => getExportParticipantsRedirectUrl(team.id, token)}>
                            {({ download }) => (
                              <TextWithIcon
                                is="span"
                                type="halcyon"
                                icon="download"
                                color="orange"
                                boxed
                                onClick={download}
                                className={style.text}
                              >
                                <FormattedMessage
                                  id={translations.pages.teams.participants.list.downloadParticipants}
                                />
                              </TextWithIcon>
                            )}
                          </ExportData>

                          <ExportData redirect={(token) => getExportEvaluationsRedirectUrl(team.id, token)}>
                            {({ download }) => (
                              <TextWithIcon
                                is="span"
                                type="halcyon"
                                icon="download"
                                color="orange"
                                boxed
                                onClick={download}
                                className={style.text}
                              >
                                <FormattedMessage id={translations.pages.teams.participants.list.downloadEvaluations} />
                              </TextWithIcon>
                            )}
                          </ExportData>
                        </div>
                      </HasRole>

                      <Table
                        id={`${JSON.stringify({ id: team.id, key, criteria })}`}
                        source={(page, number) =>
                          getParticipants(team.id, page, number, {
                            [Sort.By]: 'NICKNAME',
                            keyword: criteria.search,
                            genders: criteria.genders.includes(GenderFilter.All) ? null : [criteria.genders],
                            maxAge: criteria.ageRange.max,
                            minAge: criteria.ageRange.min,
                            birthDayStart: criteria.birthdateRange.start,
                            birthDayEnd: criteria.birthdateRange.end,
                            lastSessionStart: criteria.lastInterventionRange.start
                              ? new Date(criteria.lastInterventionRange.start).toISOString()
                              : null,
                            lastSessionEnd: criteria.lastInterventionRange.start
                              ? new Date(criteria.lastInterventionRange.end).toISOString()
                              : null
                          })
                        }
                        empty={() => (
                          <Placeholder image="people" size="md" className={style.participantsPlaceholder}>
                            <Typography is="span" type="swan" weight="bold">
                              <FormattedMessage id={translations.pages.teams.participants.list.empty} />
                            </Typography>
                          </Placeholder>
                        )}
                        renderHeader={() => <ParticipantHeader />}
                        renderRow={(data: ParticipantListItem) => (
                          <ParticipantRow key={data.id} data={data} language="en" />
                        )}
                      />
                    </React.Fragment>
                  )}
                </FilterManagerWithPersistence>
              </React.Fragment>
            )}
          </Refresh>
        )}
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(List);
