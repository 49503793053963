import {
  State,
  Page,
  MemberBase,
  Organization,
  MediaFileType,
  OrganizationUpdate,
  OrganizationSignUp,
  InviteUserToOrganization,
  ParticipantProfileField,
  ParticipantProfileFieldType,
  BillingHistory,
  ParticipantLegalSection,
  ParticipantLegalSectionType,
  Sort,
  InvoicesPage,
  EvaluationModuleListItem,
  EvaluationModuleType,
  EvaluationModuleState
} from '@/domains';
import { uploadImage } from '@/services/api/media';
import { api } from '@/services/network/api';
import { authorizedApi } from '@/services/network/authorized-api';

import { Order } from '@/components/Ordering';

export const signUpOrganization: (organization: OrganizationSignUp) => Promise<any> = (organization) =>
  api.post<OrganizationSignUp>(`/v1/organizations`, organization);

export const getOrganizationDetails: (id: string) => Promise<Organization> = (id) =>
  authorizedApi.get<Organization>(`/v1/organizations/${id}`);

export const updateOrganizationDetails: (id: string, organization: OrganizationUpdate) => Promise<any> = (
  id,
  { logoUrl, ngoUrl, ...rest }
) =>
  uploadImage(logoUrl, MediaFileType.Organization).then((logoUrl) =>
    uploadImage(ngoUrl, MediaFileType.NGO).then((ngoUrl) =>
      authorizedApi.put<any>(`/v1/organizations/${id}`, { ...rest, ngoUrl, logoUrl })
    )
  );

export const inviteUserToOrganization: (user: InviteUserToOrganization) => Promise<any> = (user) =>
  authorizedApi.post<any>(`/v1/organizations/invite`, user);

export const getEvaluationModules: () => Promise<EvaluationModuleListItem[]> = () =>
  authorizedApi.get('/v1/organizations/evaluation-modules');

export const updateEvaluationModule: (type: EvaluationModuleType, state: EvaluationModuleState) => Promise<string> = (
  type,
  state
) => authorizedApi.put('/v1/organizations/evaluation-modules', { state, type });

export const getSocialMapEvaluationInterval: () => Promise<string> = () =>
  authorizedApi.get('/v1/organizations/social-map-evaluation-interval');

export const updateSocialMapEvaluationInterval: (contactEvaluationIntervalDays: string) => Promise<any> = (
  contactEvaluationIntervalDays
) => authorizedApi.put('/v1/organizations/social-map-evaluation-interval', { contactEvaluationIntervalDays });

export const getOrganizationMembers: (page: number, size: number, search: string) => Promise<Page<MemberBase>> = (
  pageNumber,
  pageSize,
  keyword
) => authorizedApi.get<Page<MemberBase>>(`/v1/users`, { pageNumber, pageSize, keyword });

export const getParticipantProfileFields: () => Promise<ParticipantProfileField[]> = () =>
  authorizedApi.get('/v1/organizations/participant-fields');

export const updateParticipantProfileField: (
  participantField: ParticipantProfileFieldType,
  state: State
) => Promise<any> = (participantField, state) =>
  authorizedApi.put('/v1/organizations/participant-fields/state', { participantField, state });

export const getBillingHistory: (
  page: number,
  size: number,
  lastItemId: string,
  organizationId: string
) => Promise<InvoicesPage<BillingHistory>> = (page, size, lastItem, organizationId) =>
  authorizedApi.get(`/v1/billing-info/${organizationId}/history`, {
    pageNumber: page,
    pageSize: size,
    lastItem,
    [Sort.Order]: [Order.Descendent]
  });

export const getParticipantLegalSections: () => Promise<ParticipantLegalSection[]> = () =>
  authorizedApi.get('/v1/organizations/legal-sections');

export const updateParticipantLegalSection: (type: ParticipantLegalSectionType, state: State) => Promise<any> = (
  type,
  state
) => authorizedApi.put('/v1/organizations/legal-sections', { type, state });
