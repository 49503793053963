import { PieChartReportData, Period, Data, Periodical } from '@/domains/report';

export enum ReportType {
  Participant = 'participants',
  Participation = 'participation',
  Registration = 'registration',
  User = 'users'
}

export interface Participant {
  ageReport: PieChartReportData<string>;
  genderReport: PieChartReportData<string>;
  languageReport: PieChartReportData<string>;
  nationalityReport: PieChartReportData<string>;
}

export interface Participation {
  numberOfParticipantsAttendingSessions: Data;
  periodKeys: Period[];
}

export interface Registration extends Periodical {
  createdNotesInPeriods: Data;
  daytimeOccupationEvaluationsInPeriods: Data;
  goalEvaluationsInPeriods: Data;
  moodEvaluationsInPeriods: Data;
  skillEvaluationsInPeriods: Data;
  socialMapEvaluationsInPeriods: Data;
}

export interface User extends Periodical {
  users: Data;
}
