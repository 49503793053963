import React from 'react';

import { getExportDataTokenForStreetWorker } from '@/services/api/export';

export interface RenderProps {
  download: () => any;
}

export interface Props {
  redirect: (token: string) => string;
  children: (props: RenderProps) => React.ReactElement;
}

const ExportDataForStreetWorker: React.FC<Props> = ({ redirect, children }) => {
  const download = (): any =>
    getExportDataTokenForStreetWorker().then((response) => {
      const link = document.createElement('a');
      link.href = redirect(response.token);

      link.click();
    });

  return children({ download: download });
};

export default ExportDataForStreetWorker;
