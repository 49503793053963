import React from 'react';
import { differenceInHours } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getSurvey } from '@/services/api/survey';

import If from '@/components/If';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import HourSelect from '@/components/Selects/HourSelect';

import style from './SurveyOverview.sass';

export interface Props {
  surveyId: string;
}

const SurveyOverview: React.FC<Props & WrappedComponentProps & RouteComponentProps> = ({
  surveyId,
  intl,
  history,
  ...rest
}) => (
  <LoadData load={() => getSurvey(surveyId)}>
    {({ value: survey, loading }) => (
      <If
        condition={loading}
        then={() => (
          <Loading visible={loading} center overlay>
            <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
          </Loading>
        )}
        else={() => (
          <div className={style.root}>
            <div className={style.row}>
              <Typography is="span" type="halcyon" color="gray">
                <FormattedMessage id={translations.pages.digitalCoach.tabs.surveys.sessionFeedback.time} />
              </Typography>

              <Button
                type="button"
                className={style.editSurveyButton}
                onClick={() => history.push(urls.sessions.feedback.getFeedbackUrl(survey.id))}
              >
                <BoxedIcon type="edit" appearance="orange" className={style.plusIcon} />
                <Typography is="span" type="halcyon" color="orange">
                  <FormattedMessage id={translations.pages.sessions.details.tabs.details.sessionFeedback.editButton} />
                </Typography>
              </Button>
            </div>

            <Spacer xs={2} />

            <div className={style.hourSelect}>
              <HourSelect
                value={differenceInHours(new Date(survey.end), new Date(survey.start))}
                placeholder={intl.formatMessage({ id: translations.inputs.hourSelect.placeholder })}
                // @ts-ignore */
                label={intl.formatMessage({ id: translations.inputs.hourSelect.label })}
                disabled
              />
            </div>

            <Spacer xs={4} />

            {survey.questions.map((question) => (
              <React.Fragment key={question.id}>
                <Typography is="span" type="halcyon" weight="bold">
                  {question.title}
                </Typography>

                {question.options &&
                  question.options.map((option, index) => (
                    <React.Fragment key={option.id}>
                      <Spacer xs={1} />
                      <Typography is="span" type="halcyon">
                        {option.option}
                      </Typography>
                    </React.Fragment>
                  ))}
                <Spacer xs={3} />
              </React.Fragment>
            ))}
          </div>
        )}
      />
    )}
  </LoadData>
);

export default withRouter(injectIntl(SurveyOverview));
