import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { qualitativePalette } from '@/constants';
import { getColorFromPalleteByIndex } from '@/util';
import { OrganizationReport, ReportFilters } from '@/domains';
import { getOrganizationReports } from '@/services/api/organization-reports';

import If from '@/components/If';
import Loading from '@/components/Loading';
import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import { LanguageContext } from '@/components/Language';
import { PieChart, StackedBarChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

export enum documentAvailabilityValues {
  present = 'present',
  notPresent = 'notPresent',
  notAvailable = 'notAvailable'
}

const ParticipantSection: React.FC<Props> = ({ intl, criteria }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.main.reporting.participants.title
      })}
    >
      <LoadData
        load={() =>
          getOrganizationReports<OrganizationReport.Participant>(
            criteria,
            OrganizationReport.ReportType.ParticipantProfile
          )
        }
      >
        {({ value: participantsValue, loading: participantsLoading }) => (
          <LoadData
            load={() =>
              getOrganizationReports<OrganizationReport.ParticipantContacts>(
                criteria,
                OrganizationReport.ReportType.ParticipantContacts
              )
            }
          >
            {({ value: contactsValue, loading: contactsLoading }) => (
              <If
                condition={participantsLoading || contactsLoading}
                then={() => (
                  <Loading visible={participantsLoading} center className={style.loading}>
                    <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                  </Loading>
                )}
                else={() => (
                  <Reports.Charts>
                    <PieChart
                      title={intl.formatMessage({
                        id: translations.pages.main.reporting.participants.age
                      })}
                      name="participants-age"
                      data={{
                        labels: participantsValue.ageReport.labels.map((label) =>
                          label
                            ? label
                            : intl.formatMessage({
                                id: translations.miscellaneous.notAvailable
                              })
                        ),
                        dataset: participantsValue.ageReport.data
                      }}
                    />

                    <PieChart
                      title={intl.formatMessage({
                        id: translations.pages.main.reporting.participants.contacts.age
                      })}
                      name="participant-contact-age"
                      data={{
                        labels: contactsValue.ageReport.labels.map((label) =>
                          label
                            ? label
                            : intl.formatMessage({
                                id: translations.miscellaneous.notAvailable
                              })
                        ),
                        dataset: contactsValue.ageReport.data
                      }}
                    />

                    <LoadData
                      load={() => getOrganizationReports<any>(criteria, OrganizationReport.ReportType.AgeGroups)}
                    >
                      {({ value: ageGroupsValues, loading: ageGroupReportsLoading }) =>
                        !ageGroupReportsLoading && (
                          <React.Fragment>
                            <PieChart
                              title={intl.formatMessage({
                                id: translations.pages.main.reporting.participants.ageGroups
                              })}
                              name="participants-age"
                              data={{
                                labels: ageGroupsValues.participantsAgeGroupReport.labels.map((label) =>
                                  label
                                    ? label
                                    : intl.formatMessage({
                                        id: translations.miscellaneous.notAvailable
                                      })
                                ),
                                dataset: ageGroupsValues.participantsAgeGroupReport.data
                              }}
                            />

                            <PieChart
                              title={intl.formatMessage({
                                id: translations.pages.main.reporting.participants.contacts.ageGroups
                              })}
                              name="participant-contact-age"
                              data={{
                                labels: ageGroupsValues.contactsAgeGroupReport.labels.map((label) =>
                                  label
                                    ? label
                                    : intl.formatMessage({
                                        id: translations.miscellaneous.notAvailable
                                      })
                                ),
                                dataset: ageGroupsValues.contactsAgeGroupReport.data
                              }}
                            />
                          </React.Fragment>
                        )
                      }
                    </LoadData>

                    <PieChart
                      title={intl.formatMessage({
                        id: translations.pages.main.reporting.participants.gender
                      })}
                      name="participants-gender"
                      data={{
                        labels: participantsValue.genderReport.labels.map((label) =>
                          intl.formatMessage({
                            id: label ? translations.genders[label] : translations.miscellaneous.notAvailable
                          })
                        ),
                        dataset: participantsValue.genderReport.data
                      }}
                    />

                    <PieChart
                      title={intl.formatMessage({
                        id: translations.pages.main.reporting.participants.contacts.gender
                      })}
                      name="participant-contact-gender"
                      data={{
                        labels: contactsValue.genderReport.labels.map((label) =>
                          intl.formatMessage({
                            id: label ? translations.genders[label] : translations.miscellaneous.notAvailable
                          })
                        ),
                        dataset: contactsValue.genderReport.data
                      }}
                    />

                    {participantsValue.nationalityReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.nationality
                        })}
                        name="participants-nationality"
                        data={{
                          labels: participantsValue.nationalityReport.labels.map((label) =>
                            intl.formatMessage({
                              id: label
                                ? translations.countries[label.toUpperCase()]
                                : translations.miscellaneous.notAvailable
                            })
                          ),
                          dataset: participantsValue.nationalityReport.data
                        }}
                      />
                    )}

                    {contactsValue.nationalityReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.contacts.nationality
                        })}
                        name="participant-contact-nationality"
                        data={{
                          labels: contactsValue.nationalityReport.labels.map((label) =>
                            intl.formatMessage({
                              id: label
                                ? translations.countries[label.toUpperCase()]
                                : translations.miscellaneous.notAvailable
                            })
                          ),
                          dataset: contactsValue.nationalityReport.data
                        }}
                      />
                    )}

                    {participantsValue.languageReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.motherTongue
                        })}
                        name="participants-language"
                        data={{
                          labels: participantsValue.languageReport.labels.map((label) =>
                            intl.formatMessage({
                              id: label
                                ? translations.languages[label.toUpperCase()]
                                : translations.miscellaneous.notAvailable
                            })
                          ),
                          dataset: participantsValue.languageReport.data
                        }}
                      />
                    )}

                    {contactsValue.languageReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.contacts.motherTongue
                        })}
                        name="participant-contact-language"
                        data={{
                          labels: contactsValue.languageReport.labels.map((label) =>
                            intl.formatMessage({
                              id: label
                                ? translations.languages[label.toUpperCase()]
                                : translations.miscellaneous.notAvailable
                            })
                          ),
                          dataset: contactsValue.languageReport.data
                        }}
                      />
                    )}

                    {participantsValue.legalDocumentsReport && (
                      <StackedBarChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.legalDocuments
                        })}
                        name="participants-legal-documents"
                        data={{
                          labels: participantsValue.legalDocumentsReport.entries.map((entry) =>
                            entry.label.translations && entry.label.translations[language.toLowerCase()]
                              ? entry.label.translations[language.toLowerCase()]
                              : entry.label.name
                          ),
                          datasets: Object.values(documentAvailabilityValues).map((value, index) => ({
                            label: intl.formatMessage({ id: translations.legalDocumentAvailabilityCategories[value] }),
                            data: participantsValue.legalDocumentsReport.entries.map((entry) => entry.data[0][value]),
                            stack: `stack-0`,
                            backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                          }))
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  min: 0,
                                  stepSize: 1
                                }
                              }
                            ]
                          }
                        }}
                        className={style.wide}
                        height={279}
                      />
                    )}

                    {contactsValue.legalDocumentsReport && (
                      <StackedBarChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.contacts.legalDocuments
                        })}
                        name="participants-contacts-legal-documents"
                        data={{
                          labels: contactsValue.legalDocumentsReport.entries.map((entry) =>
                            entry.label.translations && entry.label.translations[language.toLowerCase()]
                              ? entry.label.translations[language.toLowerCase()]
                              : entry.label.name
                          ),
                          datasets: Object.values(documentAvailabilityValues).map((value, index) => ({
                            label: intl.formatMessage({ id: translations.legalDocumentAvailabilityCategories[value] }),
                            data: contactsValue.legalDocumentsReport.entries.map((entry) => entry.data[0][value]),
                            stack: `stack-0`,
                            backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                          }))
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  min: 0,
                                  stepSize: 1
                                }
                              }
                            ]
                          }
                        }}
                        className={style.wide}
                        height={279}
                      />
                    )}

                    {participantsValue.homeStatusReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.homeStatus
                        })}
                        name="participants-home-status"
                        data={{
                          labels: participantsValue.homeStatusReport.labels.map((label) =>
                            label.name
                              ? label.name
                              : intl.formatMessage({
                                  id: translations.miscellaneous.notAvailable
                                })
                          ),
                          dataset: participantsValue.homeStatusReport.data
                        }}
                      />
                    )}

                    {contactsValue.homeStatusReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.contacts.homeStatus
                        })}
                        name="participant-contact-home-status"
                        data={{
                          labels: contactsValue.homeStatusReport.labels.map((label) =>
                            label.name
                              ? label.name
                              : intl.formatMessage({
                                  id: translations.miscellaneous.notAvailable
                                })
                          ),
                          dataset: contactsValue.homeStatusReport.data
                        }}
                      />
                    )}

                    {participantsValue.legalStatusReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.legalStatus
                        })}
                        name="participants-legal-status"
                        data={{
                          labels: participantsValue.legalStatusReport.labels.map((label) =>
                            label.name
                              ? label.name
                              : intl.formatMessage({
                                  id: translations.miscellaneous.notAvailable
                                })
                          ),
                          dataset: participantsValue.legalStatusReport.data
                        }}
                      />
                    )}

                    {contactsValue.legalStatusReport && (
                      <PieChart
                        title={intl.formatMessage({
                          id: translations.pages.main.reporting.participants.contacts.legalStatus
                        })}
                        name="participant-contact-legal-status"
                        data={{
                          labels: contactsValue.legalStatusReport.labels.map((label) =>
                            label.name
                              ? label.name
                              : intl.formatMessage({
                                  id: translations.miscellaneous.notAvailable
                                })
                          ),
                          dataset: contactsValue.legalStatusReport.data
                        }}
                      />
                    )}
                  </Reports.Charts>
                )}
              />
            )}
          </LoadData>
        )}
      </LoadData>
    </Reports>
  );
};

export default ParticipantSection;
