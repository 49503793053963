import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Mood } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<Mood>, 'items'>, WrappedComponentProps {}

class MoodSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(Mood).map((mood) => ({
      value: mood,
      name: intl.formatMessage({ id: translations.moods[mood] })
    }));

    return <SimpleSelect {...rest} items={items} />;
  }
}

export default injectIntl(MoodSelect);
