import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { State, CustomProperty } from '@/domains';
import { updateCustomPropertyStateForTeam } from '@/services/api/custom-property';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import ToggleSwitch from '@/components/ToggleSwitch';

import style from './CustomPropertyRow.sass';

import cellStyle from '../cellStyle.sass';
import { AppContext } from '@/components/Context/App';

export interface Props {
  data: CustomProperty;
  teamId: string;
  className?: string;
  refresh?: () => any;
}

const CustomPropertyRow: React.FC<Props> = ({
  data: { id, name, type, state },
  teamId,
  className,
  refresh,
  ...rest
}) => (
  <Card.Row is="li" className={classnames(cellStyle.container, style.container, className)} {...rest}>
    <div className={cellStyle.customPropertyName}>
      <Typography is="div" type="sparrow" weight="bold">
        {name}
      </Typography>
    </div>

    <div className={cellStyle.customPropertyFieldType}>
      <Typography is="div" type="sparrow">
        {type && <FormattedMessage id={translations.customPropertyTypes[type]} />}
      </Typography>
    </div>

    <div className={cellStyle.enabled}>
      <LiveSubmit
        loading={false}
        value={{ customPropertyState: state }}
        onChange={({ customPropertyState }) => updateCustomPropertyStateForTeam(id, teamId, customPropertyState)}
      >
        {({ value: { customPropertyState }, set }) => (
          <ToggleSwitch
            checked={customPropertyState === State.Enabled}
            label={false}
            onClick={(event) =>
              set('customPropertyState', customPropertyState === State.Enabled ? State.Disabled : State.Enabled)
            }
            onChange={() => null}
            onInputLabelClick={(event) => event.stopPropagation()}
          />
        )}
      </LiveSubmit>
    </div>
  </Card.Row>
);

export default CustomPropertyRow;
