import { withPagingParams } from '@/util';
import { Location, LocationCreation, LocationState, Page, MediaFileType, UpdateTeamAccess } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { uploadImage } from '@/services/api/media';

export const getLocations: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<Location>> = (teamId, page, size, options) =>
  authorizedApi.get(`/v2/locations?${withPagingParams(page, size, { teamIds: [teamId], ...options })}`);

export const getAllLocations = (): Promise<Page<Location>> =>
  authorizedApi.get(`/v2/locations`, { pageNumber: 1, pageSize: 500 });

export const updateLocation: (locationId: string, location: LocationCreation) => Promise<any> = (
  locationId,
  { pictureUrl, ...rest }
) => {
  return uploadImage(pictureUrl, MediaFileType.Location).then((url) =>
    authorizedApi.put<any>(`/v1/locations/${locationId}`, { ...rest, id: locationId, pictureUrl: url })
  );
};

export const updateLocationState: (locationId: string, state: LocationState) => Promise<any> = (locationId, state) =>
  authorizedApi.put(`/v1/locations/${locationId}/state`, { state });

export const updateLocationTeams: (locationId: string, teams: UpdateTeamAccess[]) => Promise<any> = (locationId, ids) =>
  authorizedApi.put(`/v1/locations/${locationId}/teams`, { ids });

export const createLocation: (teamId: string, location: LocationCreation) => Promise<any> = (
  teamId,
  { pictureUrl, ...rest }
) => {
  return uploadImage(pictureUrl, MediaFileType.Location).then((url) =>
    authorizedApi.post<any>(`/v1/locations`, { ...rest, teamId: teamId, pictureUrl: url })
  );
};

export const getLocation: (locationId: string) => Promise<Location> = (locationId) =>
  authorizedApi.get(`/v1/locations/${locationId}`);
