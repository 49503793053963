import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const EmergencyInfoHeader: React.FC = () => {
  const { name, phoneNumber } = translations.pages.digitalCoach.tabs.emergencyInfo.table.header;
  return (
    <Card.Row className={cellStyle.container}>
      <Typography is="span" type="halcyon" color="gray" className={cellStyle.name}>
        <FormattedMessage id={name} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.phoneNumber}>
        <FormattedMessage id={phoneNumber} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.remove} />
    </Card.Row>
  );
};

export default EmergencyInfoHeader;
