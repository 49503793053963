import React from 'react';
import classnames from 'classnames';

import style from './Label.sass';

export interface Props {
  htmlFor: string;
  className?: string;
}

export default class Label extends React.PureComponent<Props> {
  render() {
    return <label {...this.props} className={classnames(style.root, this.props.className)} />;
  }
}
