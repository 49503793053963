import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { fullName } from '@/util';
import { translations } from '@/locale';
import { Note, UpdateNote } from '@/domains';
import { STRING_SHORT_MAX_LENGTH, NOTE_CONTENT_MAX_LENGTH } from '@/constants';
import { deleteSessionNote } from '@/services/api/street-session-notes';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Picture from '@/components/Picture';
import Checkbox from '@/components/Checkbox';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import TextWithIcon from '@/components/TextWithIcon';
import TopicSelect from '@/components/Selects/TopicSelect';
import { withValidation } from '@/components/hoc/withValidation';
import VisibilitySelect from '@/components/Selects/VisibilitySelect';

import style from './NoteDetailModal.sass';

const schema: yup.ObjectSchema<UpdateNote> = yup.object({
  title: yup
    .string()
    .label(translations.inputs.title.label)
    .max(STRING_SHORT_MAX_LENGTH)
    .required(),
  topic: yup
    .mixed()
    .label(translations.inputs.topic.label)
    .nullable()
    .notRequired(),
  description: yup
    .string()
    .max(NOTE_CONTENT_MAX_LENGTH)
    .label(translations.inputs.reminderDescription.label)
    .required(),
  lifeEvent: yup
    .boolean()
    .label(translations.inputs.lifeEvent.label)
    .nullable()
    .notRequired(),
  visibility: yup
    .mixed()
    .label(translations.inputs.visibility.label)
    .required()
});

const mapNote: (note: Note) => UpdateNote = ({ title, topic, description, visibility, lifeEvent }) => {
  return {
    title,
    topic,
    lifeEvent,
    description,
    visibility
  };
};

const TextInputWithValidation = withValidation(TextInput);
const TopicSelectWithValidation = withValidation(TopicSelect);
const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const VisibilitySelectWithValidation = withValidation(VisibilitySelect);
const CheckboxWithValidation = withValidation(Checkbox);

interface Props {
  note: Note;
  submitFunction: (...params) => any;
  onNoteUpdated: () => any;
}

const NoteDetailModal: React.FC<Props & InstanceProps & WrappedComponentProps> = ({
  intl,
  close,
  note,
  submitFunction,
  onNoteUpdated
}) => {
  const { session } = note;

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12} className={classnames(style.sessionColumn, { [style.border]: !!session })}>
          <div className={style.titleAndClose}>
            <Typography is="h6" type="swan" weight="bold">
              <FormattedMessage id={translations.modals.noteDetails.title} />
            </Typography>

            <Button onClick={close}>
              <BoxedIcon type="close" appearance="red" />
            </Button>
          </div>

          {!!session && (
            <div className={classnames(style.session, style.row)}>
              <Picture size="md" url={session.location.pictureUrl} className={style.sessionPicture} />

              <div>
                <Typography is="span" type="sparrow" color="blue" weight="lighter" className={style.title}>
                  <FormattedMessage
                    id={translations.header.notificationsDropdown.reminder.sessionAndTeam}
                    values={{
                      span: (children) => (
                        <Typography is="span" type="sparrow" color="blue" weight="bold">
                          {children}
                        </Typography>
                      ),
                      session: session.type.name,
                      team: session.team.name
                    }}
                  />
                </Typography>

                <div className={style.row}>
                  <TextWithIcon is="span" type="sparrow" icon="locationPin" iconColor="blue" className={style.location}>
                    {session.location.name}
                  </TextWithIcon>

                  <TextWithIcon is="span" type="sparrow" icon="clock" iconColor="blue">
                    <FormattedMessage
                      id={translations.header.notificationsDropdown.reminder.interval}
                      values={{
                        start: intl.formatDate(new Date(session.start), {
                          month: 'long',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit'
                        }),
                        end: intl.formatTime(new Date(session.end))
                      }}
                    />
                  </TextWithIcon>
                </div>
              </div>
            </div>
          )}
        </Card.Column>
      </Card.Row>

      <Form
        id="note-details"
        schema={schema}
        initialValues={mapNote(note)}
        subscription={{
          dirty: true,
          pristine: true,
          submitError: true,
          submitting: true
        }}
        onSubmit={(data) => submitFunction(data).then(() => onNoteUpdated() || close())}
      >
        {({ dirty, pristine, submitError, submitting }, id) => (
          <React.Fragment>
            <Card.Row>
              <Card.Column sm={12}>
                <Typography is="p" type="halcyon" color="gray">
                  <FormattedMessage id={translations.modals.createReminder.details} />
                </Typography>

                <Spacer xs={2} />

                <div className={style.inputs}>
                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${id}-title`}
                    name="title"
                    label={intl.formatMessage({ id: translations.inputs.title.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.title.placeholder })}
                    readOnly={submitting}
                  />

                  <Form.Field
                    is={TopicSelectWithValidation}
                    type="text"
                    id={`${id}-topic`}
                    name="topic"
                    label={intl.formatMessage({ id: translations.inputs.topic.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.topic.placeholder })}
                    readOnly={submitting}
                  />
                </div>

                <Spacer xs={1} />

                <Form.Field
                  is={TextAreaWithValidation}
                  id={`${id}-description`}
                  name="description"
                  label={intl.formatMessage({ id: translations.inputs.noteDescription.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.noteDescription.placeholder })}
                  readOnly={submitting}
                  inputClassName={style.description}
                />

                <Spacer xs={1} />

                <div className={style.visibilityAndLifeEvent}>
                  <div className={style.visibility}>
                    <Form.Field
                      is={VisibilitySelectWithValidation}
                      id={`${id}-visibility`}
                      name="visibility"
                      label={intl.formatMessage({ id: translations.inputs.visibility.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.visibility.placeholder })}
                      readOnly={submitting}
                    />
                  </div>

                  <Form.Field
                    is={CheckboxWithValidation}
                    id={`${id}-life-event`}
                    name="lifeEvent"
                    appearance="square"
                    type="checkbox"
                    readOnly={submitting}
                  >
                    <FormattedMessage id={translations.inputs.lifeEvent.label} />
                  </Form.Field>
                </div>

                <Typography is="div" type="halcyon" color="gray" className={style.createdBy}>
                  <Picture
                    size="xs"
                    url={note.createdBy.imageUrl ? note.createdBy.imageUrl : null}
                    className={style.createdByPicture}
                  />

                  <FormattedMessage
                    id={translations.modals.noteDetails.createdBy}
                    values={{
                      span: (children) => (
                        <Typography is="span" type="halcyon" weight="bold" className={style.createdByName}>
                          {children}
                        </Typography>
                      ),
                      name: fullName(note.createdBy.firstName, note.createdBy.lastName),
                      date: intl.formatDate(note.createdAt, { day: '2-digit', month: 'long' }),
                      time: intl.formatTime(note.createdAt)
                    }}
                  />
                </Typography>
              </Card.Column>
            </Card.Row>

            <Card.Row>
              <Card.Column sm={12}>
                {!!submitError && <SubmitError error={submitError} />}

                <div className={style.buttons}>
                  <TextWithIcon
                    is="span"
                    type="halcyon"
                    icon="bin"
                    color="red"
                    boxed
                    onClick={() => deleteSessionNote(note.id).then(() => onNoteUpdated() || close())}
                    className={style.deleteButton}
                  >
                    <FormattedMessage id={translations.modals.noteDetails.delete} />
                  </TextWithIcon>

                  <Button
                    form={id}
                    type="submit"
                    appearance="orange"
                    loading={submitting}
                    disabled={!dirty || pristine}
                    className={style.createButton}
                  >
                    <FormattedMessage id={translations.modals.noteDetails.save} />
                  </Button>
                </div>
              </Card.Column>
            </Card.Row>
          </React.Fragment>
        )}
      </Form>
    </Card>
  );
};

export default injectIntl(NoteDetailModal);
