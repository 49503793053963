import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { OrganizationReport, ReportFilters } from '@/domains';
import { qualitativePalette, sequentialPalette } from '@/constants';
import { getColorsForSequentialData, getLabelsFromPeriodAndTimeFrame } from '@/util';
import { getOrganizationReports } from '@/services/api/organization-reports';

import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { StackedBarChart, BarChart } from '@/components/Charts';
import Spacer from '@/components/Spacer';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const GoalSection: React.FC<Props> = ({ intl, criteria }) => (
  <Reports
    title={intl.formatMessage({
      id: translations.pages.main.reporting.goals.title
    })}
  >
    <LoadData
      load={() => getOrganizationReports<OrganizationReport.Goal>(criteria, OrganizationReport.ReportType.Goal)}
    >
      {({ value, loading }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={loading} center className={style.loading}>
              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
            </Loading>
          )}
          else={() => (
            <React.Fragment>
              <Reports.ItemsContainer>
                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.goals.totalStarted
                  })}
                  value={value.totalNumberOfGoalsStarted}
                  className={style.reportItem}
                />

                <Reports.Item
                  name={intl.formatMessage({
                    id: translations.pages.main.reporting.goals.participantNumber
                  })}
                  value={value.totalNumberOfParticipantsWhoStartedAGoal}
                  className={style.reportItem}
                />
              </Reports.ItemsContainer>

              <Spacer xs={3} />

              <Reports.Charts>
                <StackedBarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.goals.startedAndEnded.title
                  })}
                  name="started-and-ended-goals"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: [
                      ...value.goalsCompletedByCompletionStatus.entries.map((entry, index) => ({
                        label: intl.formatMessage({
                          id: translations.goalState[entry.label.identifier]
                        }),
                        data: entry.data,
                        stack: 'Stack 0',
                        backgroundColor: qualitativePalette[index]
                      })),
                      {
                        label: intl.formatMessage({
                          id: translations.pages.teams.participants.details.reports.goals.startedAndEnded.started
                        }),
                        stack: 'Stack 1',
                        data: value.goalsStarted.data,
                        backgroundColor: qualitativePalette[value.goalsCompletedByCompletionStatus.entries.length]
                      }
                    ]
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                  className={style.wide}
                  height={279}
                />

                <BarChart
                  title={intl.formatMessage({
                    id: translations.pages.main.reporting.goals.averageTimeFrame.title
                  })}
                  name="average-goal-time-frame"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: [
                      {
                        label: intl.formatMessage({
                          id: translations.pages.main.reporting.goals.averageTimeFrame.label
                        }),
                        data: value.averageDurationInWeeks.data,
                        backgroundColor: getColorsForSequentialData(
                          value.averageDurationInWeeks.data,
                          sequentialPalette
                        )
                      }
                    ]
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                  className={style.wide}
                  height={279}
                />
              </Reports.Charts>
            </React.Fragment>
          )}
        />
      )}
    </LoadData>
  </Reports>
);

export default GoalSection;
