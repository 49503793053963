import React from 'react';
import { debounce } from 'lodash';

import { Location, LocationState } from '@/domains';
import { getLocations } from '@/services/api/location';

import Select, { Item, Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<string, string>, 'items'> {
  teamId: string;
  locationState: LocationState;
}

interface State {
  loading: boolean;
  items: Item<string>[];
}

class LocationSelect extends React.PureComponent<Props, State> {
  state: State = { loading: false, items: [] };

  componentDidMount() {
    this.loadByName();
  }

  loadByName = (name?: string) => {
    return this.load(() =>
      getLocations(this.props.teamId, 1, 100, {
        keyword: name,
        ...(this.props.locationState ? { state: this.props.locationState } : null)
      }).then((response) => response.content)
    );
  };

  load = (callback: () => Promise<Location[]>) => {
    this.setState({ loading: true });

    return callback()
      .then((items) => {
        this.setState({
          loading: false,
          items: items.map((item) => ({
            value: item.id,
            name: item.name
          }))
        });
      })
      .catch(() => this.setState({ loading: false, items: [] }));
  };

  debouncedLoadByName = debounce(this.loadByName, 300);

  render() {
    const { teamId, locationState, ...rest } = this.props;
    const { items } = this.state;

    return <Select {...rest} items={items} searchable onInputValueChange={this.debouncedLoadByName} />;
  }
}

export default LocationSelect;
