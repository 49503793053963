import React from 'react';
import classnames from 'classnames';

import Button, { Props as ButtonProps } from '@/components/Button';

import style from './HeaderItem.sass';

export interface Props extends ButtonProps {
  classNames?: string;
}

const HeaderItem: React.FC<Props> = ({ className, children, ...rest }) => (
  <Button className={classnames(style.root, className)} {...rest}>
    {children}
  </Button>
);

export default HeaderItem;
