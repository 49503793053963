import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { ReportFilters } from '@/domains';
import { qualitativePalette } from '@/constants';
import { getParticipantSkillReport } from '@/services/api/participant-reports';

import If from '@/components/If';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import BoxedIcon from '@/components/BoxedIcon';
import { LineChart } from '@/components/Charts';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { InstanceProps } from '@/components/Modal';

import { LearningStatuses } from '@/page/Reporting/SkillSection';

import style from './ChartModal.sass';

export interface Props extends InstanceProps {
  title: string;
  teamId: string;
  participantId: string;
  skillId: string;
  criteria: ReportFilters;
}

const learningStatusesToValueMap: Record<LearningStatuses, number> = {
  [LearningStatuses.veryNegative]: 1,
  [LearningStatuses.negative]: 2,
  [LearningStatuses.neutral]: 3,
  [LearningStatuses.positive]: 4,
  [LearningStatuses.veryPositive]: 5
};

class ChartModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { intl, teamId, participantId, skillId, criteria, title, close } = this.props;

    const tickValueToLearningStatuseMap: Record<number, string> = {
      4: intl.formatMessage({ id: translations.evaluationStatuses.VERY_NEGATIVE }),
      3: intl.formatMessage({ id: translations.evaluationStatuses.NEGATIVE }),
      2: intl.formatMessage({ id: translations.evaluationStatuses.NEUTRAL }),
      1: intl.formatMessage({ id: translations.evaluationStatuses.POSITIVE }),
      0: intl.formatMessage({ id: translations.evaluationStatuses.VERY_POSITIVE })
    };

    const tickValueToConfidenceStatuseMap: Record<number, string> = {
      2: intl.formatMessage({ id: translations.evaluationStatuses.NEGATIVE }),
      1: intl.formatMessage({ id: translations.evaluationStatuses.NEUTRAL }),
      0: intl.formatMessage({ id: translations.evaluationStatuses.POSITIVE })
    };

    return (
      <Card className={style.root}>
        <div className={style.titleAndClose}>
          <Typography is="h6" type="swan" weight="bold">
            {title}
          </Typography>

          <Button onClick={this.props.close}>
            <BoxedIcon type="close" appearance="red" />
          </Button>
        </div>
        <LoadData load={() => getParticipantSkillReport<any>(participantId, skillId, teamId, criteria)}>
          {({ value, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <If
                  condition={value.length !== 0}
                  then={() => (
                    <React.Fragment>
                      <LineChart
                        title={intl.formatMessage({ id: translations.confidenceAndLearningStatus.CONFIDENCE })}
                        name={intl.formatMessage({ id: translations.confidenceAndLearningStatus.CONFIDENCE })}
                        data={{
                          labels: value.map((entry) =>
                            intl.formatDate(entry.evaluationDate, { year: 'numeric', month: 'short', day: '2-digit' })
                          ),
                          datasets: [
                            {
                              label: intl.formatMessage({ id: translations.confidenceAndLearningStatus.CONFIDENCE }),
                              data: value.map((entry) => learningStatusesToValueMap[entry.confidenceStatus]),
                              stack: 'Stack 0',
                              borderColor: qualitativePalette[0],
                              backgroundColor: qualitativePalette[0]
                            }
                          ]
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: { min: 2, max: 4, stepSize: 1 },
                                afterTickToLabelConversion: (chart) => {
                                  for (var tick in chart.ticks) {
                                    chart.ticks[tick] = tickValueToConfidenceStatuseMap[tick];
                                  }
                                }
                              }
                            ]
                          }
                        }}
                        height={279}
                        className={style.wide}
                      />

                      <Spacer xs={2} />

                      <LineChart
                        title={intl.formatMessage({ id: translations.confidenceAndLearningStatus.LEARNING })}
                        name={intl.formatMessage({ id: translations.confidenceAndLearningStatus.LEARNING })}
                        data={{
                          labels: value.map((entry) =>
                            intl.formatDate(entry.evaluationDate, { year: 'numeric', month: 'short', day: '2-digit' })
                          ),
                          datasets: [
                            {
                              label: intl.formatMessage({ id: translations.confidenceAndLearningStatus.LEARNING }),
                              data: value.map((entry) => learningStatusesToValueMap[entry.learningStatus]),
                              stack: 'Stack 0',
                              borderColor: qualitativePalette[3],
                              backgroundColor: qualitativePalette[3]
                            }
                          ]
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: { min: 1, max: 5, stepSize: 1 },
                                afterTickToLabelConversion: (chart) => {
                                  for (var tick in chart.ticks) {
                                    chart.ticks[tick] = tickValueToLearningStatuseMap[tick];
                                  }
                                }
                              }
                            ]
                          }
                        }}
                        height={279}
                        className={style.wide}
                      />
                    </React.Fragment>
                  )}
                  else={() => (
                    <Placeholder image="people" size="md">
                      <Typography is="span" type="halcyon" weight="bold">
                        <FormattedMessage id={translations.chartModal.empty} />
                      </Typography>
                    </Placeholder>
                  )}
                />
              )}
            />
          )}
        </LoadData>
      </Card>
    );
  }
}
export default injectIntl(ChartModal);
