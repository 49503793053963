import { DataOptionType, DataOption, State, NewDataOption } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { uploadIcon } from './media';

export const getDataOptions: (type: DataOptionType, state?: State) => Promise<DataOption[]> = (type, state) =>
  authorizedApi.get<DataOption[]>(`/v1/data-options`, { type, state });

export const getDataOptionsWithTranslations: (ids: string[]) => Promise<DataOption[]> = (ids) =>
  authorizedApi.get<DataOption[]>(`/v1/data-options?${ids.map((id) => `ids=${id}`).join('&')}`);

export const updateDataOption: (id: string, state: State) => Promise<any> = (id, state) =>
  authorizedApi.put(`/v1/data-options/${id}/state`, { state });

export const createDataOption: (dataOption: NewDataOption) => Promise<any> = (dataOption) =>
  authorizedApi.post('/v1/data-options', dataOption);

export const createDataOptionWithPicture: (iconUrl, dataOption: NewDataOption) => Promise<any> = (
  iconUrl,
  { ...rest }
) => {
  return uploadIcon(iconUrl).then((id) => {
    return authorizedApi.post<any>(`/v1/data-options`, { ...rest, iconId: id });
  });
};

///Team Related Functions

export const getTeamLeadDataOptions: (type: DataOptionType, teamId: string, state?: State) => Promise<DataOption[]> = (
  type,
  teamId,
  state
) => authorizedApi.get<DataOption[]>(`/v2/data-options/teams/team-lead`, { type, teamId, state });

export const getDataOptionsForTeam: (type: DataOptionType, teamId: string, state?: State) => Promise<DataOption[]> = (
  type,
  teamId,
  state
) => authorizedApi.get<DataOption[]>(`/v2/data-options/teams`, { type: type, state: state, teamId: teamId });

export const getDataOptionsWithTranslationsForTeam: (ids: string[], teamId: string) => Promise<DataOption[]> = (
  ids,
  teamId
) =>
  authorizedApi.get<DataOption[]>(`/v2/data-options/teams?${ids.map((id) => `ids=${id}`).join('&')}`, {
    teamId: teamId
  });

export const getDataOptionsForTeamWithFilter: (
  type: DataOptionType,
  teamId: string,
  name: string,
  state: State
) => Promise<DataOption[]> = (type, teamId, name, state) =>
  getDataOptionsForTeam(type, teamId, state).then((results) =>
    results.filter((result) => result.name.toLowerCase().includes(name ? name.toLowerCase() : ''))
  );

export const updateTeamDataOption: (id: string, teamId: string, state: State) => Promise<any> = (id, teamId, state) =>
  authorizedApi.put(`/v2/data-options/${id}/teams/${teamId}`, { state: state });

export const deleteDataOption: (id: string) => Promise<any> = (id) => authorizedApi.delete(`/v1/data-options/${id}`);
