import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const GoalHeader: React.FC<{}> = () => {
  return (
    <Card.Row className={cellStyle.container}>
      <Typography is="span" type="halcyon" color="gray" className={cellStyle.goalName}>
        <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.table.goal} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.creationTime}>
        <FormattedMessage
          id={translations.pages.teams.participants.details.evaluations.details.goals.table.creationTime}
        />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.topic}>
        <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.table.topic} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.duration}>
        <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.table.duration} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.select}>
        <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.table.status} />
      </Typography>
    </Card.Row>
  );
};

export default GoalHeader;
