import React from 'react';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CreateGoal } from '@/domains';
import { STRING_LONG_MAX_LENGTH } from '@/constants';
import { createGoal } from '@/services/api/goal';

import { withValidation } from '@/components/hoc/withValidation';
import DataOptionSelect from '@/components/Selects/DataOptionSelect';
import TextInput from '@/components/TextInput';
import { InstanceProps } from '@/components/Modal';
import { AppConsumer } from '@/components/Context/App';
import Placeholder from '@/components/Placeholder';
import If from '@/components/If';
import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';
import Spacer from '@/components/Spacer';
import Form from '@/components/Form';
import { LanguageContext } from '@/components/Language';
import { SubmitError } from '@/components/Error';

import style from './CreateGoalModal.sass';

const schema: yup.ObjectSchema<CreateGoal> = yup.object({
  description: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.goalDescription.label)
    .required(),
  type: yup
    .mixed()
    .label(translations.inputs.goalTypeSelect.label)
    .required(),
  duration: yup
    .number()
    .positive()
    .max(20)
    .label(translations.inputs.numberOfWeeks.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);
const GoalTypeSelectWithValidation = withValidation(DataOptionSelect.GoalTypeSelect);

export interface Props extends InstanceProps {
  participantId: string;
  onGoalCreated: () => any;
}

const CreateGoalModal: React.FC<Props & WrappedComponentProps> = ({ intl, participantId, onGoalCreated, close }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Card className={style.root}>
      <Card.Row>
        <Card.Column sm={12}>
          <div className={style.titleAndClose}>
            <Typography is="h6" type="swan" weight="bold">
              <FormattedMessage id={translations.modals.createGoal.title} />
            </Typography>

            <Button onClick={close}>
              <BoxedIcon type="close" appearance="red" />
            </Button>
          </div>

          <Spacer xs={2} />

          <AppConsumer>
            {({ team }) => (
              <If
                condition={!team}
                then={() => (
                  <Placeholder image="people" size="md" className={style.selectTeam}>
                    <Typography is="span" type="halcyon" weight="bold">
                      <FormattedMessage id={translations.modals.createGoal.noTeam} />
                    </Typography>
                  </Placeholder>
                )}
                else={() => (
                  <Form
                    id="create-goal"
                    schema={schema}
                    subscription={{ dirty: true, pristine: true, submitting: true, submitError: true }}
                    onSubmit={(data) => createGoal(participantId, team.id, data).then(() => onGoalCreated() || close())}
                  >
                    {({ dirty, pristine, submitting, submitError }, id) => (
                      <React.Fragment>
                        <Spacer xs={2} />

                        <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                          <FormattedMessage id={translations.modals.createGoal.details} />
                        </Typography>

                        <Spacer xs={2} />

                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-description`}
                          name="description"
                          label={intl.formatMessage({ id: translations.inputs.goalDescription.label })}
                          placeholder={intl.formatMessage({
                            id: translations.inputs.goalDescription.placeholder
                          })}
                          readOnly={submitting}
                        />

                        <Spacer xs={1} />

                        <Form.Field
                          is={GoalTypeSelectWithValidation}
                          teamId={team.id}
                          id={`${id}-goal-type`}
                          name="type"
                          label={intl.formatMessage({ id: translations.inputs.goalTypeSelect.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.goalTypeSelect.placeholder })}
                          appearance="normal"
                          language={language}
                        />

                        <Spacer xs={3} />

                        <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                          <FormattedMessage id={translations.modals.createGoal.duration} />
                        </Typography>

                        <Spacer xs={2} />

                        <Form.Field
                          is={TextInputWithValidation}
                          type="number"
                          id={`${id}-number-of-weeks`}
                          name="duration"
                          label={intl.formatMessage({ id: translations.inputs.numberOfWeeks.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.numberOfWeeks.placeholder })}
                        />

                        <Spacer xs={10} />

                        {!!submitError && <SubmitError error={submitError} />}

                        <Spacer xs={2} />

                        <Button
                          className={style.button}
                          loading={submitting}
                          disabled={!dirty || pristine}
                          appearance="orange"
                        >
                          <FormattedMessage id={translations.modals.createGoal.button} />
                        </Button>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              />
            )}
          </AppConsumer>
        </Card.Column>
      </Card.Row>
    </Card>
  );
};
export default injectIntl(CreateGoalModal);
