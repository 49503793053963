import React from 'react';
import classnames from 'classnames';
import { differenceInWeeks } from 'date-fns';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { GoalState, EvaluationStatus, GoalEvaluation } from '@/domains';
import { evaluateGoal, deleteGoalEvaluation } from '@/services/api/participant-evaluations';
import { updateGoalState, deleteGoal } from '@/services/api/goal';

import Card from '@/components/Card';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import BoxedContent from '@/components/BoxedContent';
import GoalStateSelect from '@/components/Selects/GoalStateSelect';
import EvaluationStatusSelect from '@/components/Selects/EvaluationStatusSelect';

import cellStyle from '../cellStyle.sass';

export interface Props {
  goalEvaluation: GoalEvaluation;
  language: string;
  loading: boolean;
  sessionId: string;
  reload?: () => any;
}
const GoalRow: React.FC<Props & WrappedComponentProps> = ({ goalEvaluation, reload, language, sessionId, loading }) => (
  <Card.Row className={cellStyle.container}>
    <Typography is="span" type="halcyon" weight="bold" className={cellStyle.goalName}>
      {goalEvaluation.goal.description}
    </Typography>

    <Typography is="span" type="halcyon" weight="bold" className={cellStyle.creationTime}>
      <FormattedDate value={goalEvaluation.goal.startsAt} day="2-digit" month="long" year="numeric" />
    </Typography>

    <BoxedContent appearance="blue" className={classnames(cellStyle.goalType, cellStyle.topic)}>
      <Typography is="span" type="halcyon" weight="bold" color="blue">
        {goalEvaluation.goal.type.translations && goalEvaluation.goal.type.translations[language.toLowerCase()]
          ? goalEvaluation.goal.type.translations[language.toLowerCase()]
          : goalEvaluation.goal.type.name}
      </Typography>
    </BoxedContent>

    <span className={cellStyle.duration}>
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage
          id={translations.pages.teams.participants.details.evaluations.details.goals.table.weeks}
          values={{
            weeks: differenceInWeeks(new Date(goalEvaluation.goal.endsAt), new Date(goalEvaluation.goal.startsAt))
          }}
        />
      </Typography>
    </span>

    <LiveSubmit
      loading={loading}
      value={{ evaluationStatus: goalEvaluation.currentEvaluationStatus || null }}
      onChange={(status) => evaluateGoal(sessionId, goalEvaluation.goal.id, status.evaluationStatus)}
    >
      {({ value, set }) => (
        <div className={cellStyle.select}>
          <EvaluationStatusSelect
            value={value.evaluationStatus}
            onChange={(status: EvaluationStatus) => set('evaluationStatus', status)}
            disabled={goalEvaluation.goal.completionStatus === GoalState.Canceled}
          />
        </div>
      )}
    </LiveSubmit>

    <LiveSubmit
      loading={loading}
      value={{ state: goalEvaluation.goal.completionStatus || null }}
      onChange={(value) => updateGoalState(sessionId, goalEvaluation.goal.id, value.state).then(reload)}
    >
      {({ value, set }) => (
        <div className={cellStyle.select}>
          <GoalStateSelect
            value={value.state}
            onChange={(state) => set('state', state as GoalState)}
            closed={[GoalState.Completed, GoalState.Failed].includes(goalEvaluation.goal.completionStatus)}
            disabled={value.state === GoalState.Canceled}
          />
        </div>
      )}
    </LiveSubmit>

    <Button
      onClick={() =>
        !!goalEvaluation.id
          ? deleteGoalEvaluation(goalEvaluation.id).then(reload)
          : deleteGoal(sessionId, goalEvaluation.goal.id).then(reload)
      }
      className={cellStyle.bin}
    >
      <BoxedIcon type="bin" appearance="red" />
    </Button>
  </Card.Row>
);

export default injectIntl(GoalRow);
