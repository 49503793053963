import { withPagingParams } from '@/util';
import { authorizedApi } from '@/services/network/authorized-api';
import { Page, CreateSurvey, SurveyRow, SurveyDetails, UpdateSurvey, SurveyTiming } from '@/domains';

export const getSurveyParticipants: (
  surveyId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<SurveyRow>> = (surveyId, page, size, options) =>
  authorizedApi.get(`/v1/surveys/${surveyId}/participants?${withPagingParams(page, size, { ...options })}`);

export const getSurveys: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<SurveyRow>> = (teamId, page, size, options) =>
  authorizedApi.get(`/v1/surveys?${withPagingParams(page, size, { teamIds: [teamId], ...options })}`);

export const getSurvey: (id: string) => Promise<SurveyDetails> = (id) => authorizedApi.get(`/v1/surveys/${id}`);

export const getSurveyResponses: (id: string) => Promise<any> = (id) =>
  authorizedApi.get(`/v1/surveys/${id}/responses`);

export const createSurvey: (data: CreateSurvey) => Promise<any> = (data) =>
  authorizedApi.post<any>(`/v1/surveys`, data);

export const updateSurvey: (surveyId: string, data: UpdateSurvey) => Promise<any> = (surveyId, data) =>
  authorizedApi.put<any>(`/v1/surveys/${surveyId}`, data);

export const updateSurveyTiming: (surveyId: string, data: SurveyTiming) => Promise<any> = (surveyId, data) =>
  authorizedApi.put<any>(`/v1/surveys/${surveyId}/time-interval`, data);

export const deleteSurvey: (id: string) => Promise<any> = (id) => authorizedApi.delete<any>(`/v1/surveys/${id}`);

export const inviteParticipantsToSurvey: (surveyId: string, teamId: string, ids: string[]) => Promise<any> = (
  surveyId,
  teamId,
  ids
) =>
  authorizedApi.post(
    `/v1/surveys/${surveyId}/share`,
    {
      ids: ids.map((id) => ({
        id
      }))
    },
    { params: { teamId: teamId } }
  );
