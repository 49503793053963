import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { InvitationStatus, ParticipantSessionInvitationRowItem } from '@/domains';

import Link from '@/components/Link';
import Card from '@/components/Card';
import PictureWithData from '@/components/PictureWithData';
import BoxedContent, { Appearance } from '@/components/BoxedContent';

import cellStyle from '../cellStyle.sass';

import style from './InvitationRow.sass';

export const invitationStatusColor: Record<InvitationStatus, Appearance> = {
  [InvitationStatus.Accepted]: 'green',
  [InvitationStatus.Declined]: 'red',
  [InvitationStatus.Pending]: 'orange'
};

export interface Props {
  data: ParticipantSessionInvitationRowItem;
}

const InvitationRow: React.FC<Props & WrappedComponentProps> = ({ data, intl, ...rest }) => (
  <Card.Row is="li" className={style.container} {...rest}>
    <div className={classnames(cellStyle.participant, style.rowFlex)}>
      <PictureWithData
        size="md"
        url={data.participantAccountOverviewDto.imageUrl ? data.participantAccountOverviewDto.imageUrl : null}
        blueText={data.participantAccountOverviewDto.name}
        grayText={
          data.participantAccountOverviewDto.birthDate
            ? intl.formatMessage(
                { id: translations.pages.sessions.details.tabs.participants.content.table.age },
                { age: getAgeFromBirthdate(data.participantAccountOverviewDto.birthDate) }
              )
            : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge })
        }
      />
    </div>

    <BoxedContent appearance={invitationStatusColor[data.status]} className={style.invitationStatus}>
      <FormattedMessage id={translations.invitationStatus[data.status]} />
    </BoxedContent>
  </Card.Row>
);

export default injectIntl(InvitationRow);
