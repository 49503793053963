import { uploadImage } from '@/services/api/media';
import { authorizedApi } from '@/services/network/authorized-api';

import { Team, TeamUpdate, Page, TeamBase, TeamCreation, MemberBase, MediaFileType, State } from '@/domains';

export const createTeam: (organization: TeamCreation) => Promise<any> = ({ imageUrl, ...rest }) => {
  return uploadImage(imageUrl, MediaFileType.Team).then((url) =>
    authorizedApi.post(`/v1/teams`, { ...rest, imageUrl: url })
  );
};

export const getTeamDetails: (id: string) => Promise<Team> = (id) => authorizedApi.get(`/v1/teams/${id}`);

export const getMyTeams: (page: number, size: number, name?: string) => Promise<Page<TeamBase>> = (
  pageNumber,
  pageSize,
  name
) => authorizedApi.get(`/v1/teams`, { pageNumber, pageSize, name });

export const getMyTeamsWithSharingAccess: (page: number, size: number, name?: string) => Promise<Page<TeamBase>> = (
  pageNumber,
  pageSize,
  name
) => authorizedApi.get(`/v1/teams/sharing-access-to-resources`, { pageNumber, pageSize, name });

export const updateTeamDetails: (id: string, teamData: TeamUpdate) => Promise<any> = (id, { imageUrl, ...rest }) => {
  return uploadImage(imageUrl, MediaFileType.Team).then((url) =>
    authorizedApi.put(`/v1/teams/${id}`, { ...rest, id, imageUrl: url })
  );
};

export const getTeamMembers: (
  page: number,
  size: number,
  search: string,
  teamId: string
) => Promise<Page<MemberBase>> = (pageNumber, pageSize, keyword, teamId) =>
  authorizedApi.get<Page<MemberBase>>(`/v1/users`, { pageNumber, pageSize, keyword, teamId });

export const getTeamMembersForSelect: (teamId?: string) => Promise<Page<MemberBase>> = (teamId) =>
  authorizedApi.get<Page<MemberBase>>(`/v1/users`, { pageNumber: 1, pageSize: 1000, teamId });

export const addTeamMembers: (teamId: string, data) => Promise<any> = (teamId, data) => {
  return authorizedApi.post(`/v1/teams/${teamId}/members`, data);
};

export const removeTeamMembers: (teamId: string, memberIds) => Promise<any> = (teamId, memberIds) => {
  return authorizedApi.delete(`/v1/teams/${teamId}/members`, { data: memberIds });
};

export const archiveTeam: (id: string, state: State) => Promise<any> = (id, state: State) =>
  authorizedApi.put(`/v1/teams/${id}/state`, { state });

export const deleteTeam: (id: string) => Promise<any> = (id) => authorizedApi.delete(`/v1/teams/${id}`);
