import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { updateParticipantLegalSection } from '@/services/api/organization';
import { DataOptionType, ParticipantLegalSection, ParticipantLegalSectionType, State as FieldState } from '@/domains';

import Link from '@/components/Link';
import Card from '@/components/Card';
import LiveSubmit from '@/components/LiveSubmit';
import Typography from '@/components/Typography';
import ToggleSwitch from '@/components/ToggleSwitch';
import TextWithIcon from '@/components/TextWithIcon';

import cellStyle from '../cellStyle.sass';

export interface Props {
  data: ParticipantLegalSection;
  className?: string;
}

const legalSectionTypeToDataTypeMap: Record<ParticipantLegalSectionType, DataOptionType> = {
  [ParticipantLegalSectionType.HomeStatus]: DataOptionType.LegalLivingPlace,
  [ParticipantLegalSectionType.LegalDocuments]: DataOptionType.LegalDocument,
  [ParticipantLegalSectionType.LegalStatus]: DataOptionType.LegalStatus
};

const ParticipantLegalSectionRow: React.FC<Props> = ({ data: { type, state }, className, ...rest }) => (
  <Card.Row is="li" className={classnames(cellStyle.container, className)} {...rest}>
    <Typography is="div" type="sparrow" weight="bold" className={cellStyle.legalInformationFieldName}>
      <FormattedMessage id={translations.participantLegalSectionTypes[type]} />
    </Typography>

    <Link
      to={urls.dataCustomisation.tabs.get(urls.dataCustomisation.tabs.dataOptions, legalSectionTypeToDataTypeMap[type])}
      className={cellStyle.legalInformationFieldLink}
    >
      <TextWithIcon icon="link" boxed is="span" type="hummingbird" color="orange">
        <FormattedMessage id={translations.dataCustomisation.profileFields.dataOptions} />
      </TextWithIcon>
    </Link>

    <div className={cellStyle.legalInformationFieldEnabled}>
      <LiveSubmit
        loading={false}
        value={{ fieldState: state }}
        onChange={({ fieldState }) => updateParticipantLegalSection(type, fieldState)}
      >
        {({ value: { fieldState }, set }) => (
          <ToggleSwitch
            checked={fieldState === FieldState.Enabled}
            label={false}
            onClick={() =>
              set('fieldState', fieldState === FieldState.Enabled ? FieldState.Disabled : FieldState.Enabled)
            }
            onChange={() => null}
          />
        )}
      </LiveSubmit>
    </div>
  </Card.Row>
);

export default ParticipantLegalSectionRow;
