import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Team } from '@/domains';
import { translations } from '@/locale';
import { Location } from '@/domains/location';
import { getLocations } from '@/services/api/location';

import Table from '@/components/Table';
import TextInput from '@/components/TextInput';
import FilterManager from '@/components/FilterManager';

import LocationHeader from './LocationHeader';
import LocationRow from './LocationRow';

import style from './Locations.sass';

export interface Props {
  team: Team;
  newKey: number;
  onChange: () => void;
}

class Locations extends React.Component<Props & WrappedComponentProps> {
  render() {
    const { intl, team, newKey, onChange } = this.props;
    return (
      <React.Fragment>
        <FilterManager initialCriteria={{}}>
          {({ criteria, debouncedUpdate }) => (
            <div className={style.locationsContainer}>
              <TextInput
                type="text"
                id="search"
                onChange={(event) => debouncedUpdate('search', event.target.value)}
                label={intl.formatMessage({ id: translations.inputs.contactSearch.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.contactSearch.placeholder })}
                autoComplete="off"
                className={style.searchBar}
                leadingIcon="search"
              />

              <Table
                key={newKey}
                id={criteria.search}
                source={(page, size) => getLocations(team.id, page, size, { keyword: criteria.search })}
                renderHeader={() => <LocationHeader />}
                renderRow={(data: Location) => <LocationRow key={data.id} data={data} refresh={onChange} />}
                className={style.table}
              />
            </div>
          )}
        </FilterManager>
      </React.Fragment>
    );
  }
}

export default injectIntl(Locations);
