import React from 'react';
import * as yup from 'yup';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { phoneNumberSchema } from '@/validation';
import { Me, UpdateMe, UpdatePassword, Gender } from '@/domains';
import { STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { updateProfile, updatePassword } from '@/services/api/me';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import DateInput from '@/components/DateInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import PictureUpload from '@/components/PictureUpload';
import GenderSelect from '@/components/Selects/GenderSelect';
import { SessionConsumer } from '@/components/Context/Session';
import { withValidation } from '@/components/hoc/withValidation';

import style from './EditProfileModal.sass';

const editProfileSchema: yup.ObjectSchema<UpdateMe> = yup.object({
  firstName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.firstName.label)
    .required(),
  lastName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.lastName.label)
    .required(),
  gender: yup
    .mixed()
    .label(translations.inputs.gender.label)
    .default(Gender.Other)
    .required(),
  birthDate: yup
    .string()
    .label(translations.inputs.birthDate.label)
    .required(),
  imageUrl: yup
    .mixed()
    .label(translations.inputs.picture.profile)
    .nullable()
    .notRequired(),
  phoneNumber: phoneNumberSchema
    .label(translations.inputs.phoneNumber.label)
    .nullable()
    .notRequired(),
  jobTitle: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.jobTitle.label)
    .notRequired()
});

const changePasswordSchema: yup.ObjectSchema<UpdatePassword> = yup.object({
  newPassword: yup
    .string()
    .min(8)
    .max(50)
    .label(translations.inputs.password.label),
  newPasswordConfirm: yup
    .string()
    .label(translations.inputs.password.label)
    .required()
    .oneOf([yup.ref('newPassword')], translations.validation.custom.passwordsDontMatch)
});

const mapMe: (me: Me) => UpdateMe = ({ id, email, role, state, ...rest }) => rest;

const PictureUploadWithValidation = withValidation(PictureUpload);
const TextInputWithValidation = withValidation(TextInput);
const GenderSelectWithValidation = withValidation(GenderSelect);
const DateInputWithValidation = withValidation(DateInput);

const EditProfileModal: React.FC<InstanceProps & WrappedComponentProps> = ({ intl, close }) => (
  <Card className={style.root}>
    <Card.Row>
      <Card.Column sm={12}>
        <div className={style.titleAndClose}>
          <Typography is="h6" type="swan" weight="bold">
            <FormattedMessage id={translations.modals.editProfile.title} />
          </Typography>

          <Button onClick={close}>
            <BoxedIcon type="close" appearance="red" />
          </Button>
        </div>

        <Spacer xs={3} />

        <SessionConsumer>
          {({ me, reload }) => (
            <Form
              id="edit-profile"
              schema={editProfileSchema}
              initialValues={mapMe(me)}
              subscription={{
                dirty: true,
                pristine: true,
                submitError: true,
                submitting: true
              }}
              onSubmit={(data) => updateProfile(data).then(() => reload() && close())}
            >
              {({ dirty, pristine, submitError, submitting }, id) => (
                <React.Fragment>
                  <Form.Field
                    is={PictureUploadWithValidation}
                    id={`${id}-image-url`}
                    name="imageUrl"
                    type="teammate"
                    title={intl.formatMessage({ id: translations.inputs.picture.profile })}
                    accept="image/x-png,image/jpeg"
                    readOnly={submitting}
                  />

                  <Spacer xs={2} />

                  <Typography is="h6" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.editProfile.personalInformation} />
                  </Typography>

                  <Spacer xs={1} />

                  <div className={style.container}>
                    <Form.Field
                      is={TextInputWithValidation}
                      type="text"
                      id={`${id}-first-name`}
                      name="firstName"
                      label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
                      readOnly={submitting}
                    />

                    <Form.Field
                      is={TextInputWithValidation}
                      type="text"
                      id={`${id}-last-name`}
                      name="lastName"
                      label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
                      readOnly={submitting}
                    />

                    <Form.Field
                      is={GenderSelectWithValidation}
                      type="text"
                      id={`${id}-gender`}
                      name="gender"
                      label={intl.formatMessage({ id: translations.inputs.gender.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.gender.placeholder })}
                      readOnly={submitting}
                    />

                    <Form.Field
                      is={DateInputWithValidation}
                      type="text"
                      id={`${id}-birth-date`}
                      name="birthDate"
                      label={intl.formatMessage({ id: translations.inputs.birthDate.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.birthDate.placeholder })}
                      readOnly={submitting}
                    />
                  </div>

                  <Spacer xs={2} />

                  <Typography is="h6" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.editProfile.whatIDo} />
                  </Typography>

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    type="text"
                    id={`${id}-job-title`}
                    name="jobTitle"
                    label={intl.formatMessage({ id: translations.inputs.jobTitle.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.jobTitle.placeholder })}
                    readOnly={submitting}
                  />

                  <Spacer xs={2} />

                  <Typography is="h6" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.editProfile.contact} />
                  </Typography>

                  <Spacer xs={1} />

                  <Form.Field
                    is={TextInputWithValidation}
                    type="text"
                    id={`${id}-phone-number`}
                    name="phoneNumber"
                    label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                    placeholder={intl.formatMessage({
                      id: translations.inputs.phoneNumber.placeholder
                    })}
                    readOnly={submitting}
                    className={style.phoneNumber}
                  />

                  {!!submitError && <SubmitError error={submitError} />}

                  <Button
                    form={id}
                    type="submit"
                    appearance="orange"
                    loading={submitting}
                    disabled={!dirty || pristine}
                    className={style.saveButton}
                  >
                    <FormattedMessage id={translations.modals.editProfile.saveButton} />
                  </Button>
                </React.Fragment>
              )}
            </Form>
          )}
        </SessionConsumer>
      </Card.Column>
    </Card.Row>

    <Card.Row>
      <Card.Column sm={12}>
        <Typography is="h6" type="halcyon" weight="bold">
          <FormattedMessage id={translations.modals.editProfile.changePassword} />
        </Typography>

        <Spacer xs={1} />

        <Form
          id="change-password"
          schema={changePasswordSchema}
          subscription={{
            dirty: true,
            pristine: true,
            submitError: true,
            submitting: true
          }}
          onSubmit={(values) => updatePassword(values)}
        >
          {({ dirty, pristine, submitError, submitting }, id) => (
            <React.Fragment>
              <div className={style.container}>
                <Form.Field
                  is={TextInputWithValidation}
                  type="password"
                  id={`${id}-new-password`}
                  name="newPassword"
                  label={intl.formatMessage({ id: translations.inputs.newPassword.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.newPassword.placeholder })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={TextInputWithValidation}
                  type="password"
                  id={`${id}-confirm-password`}
                  name="newPasswordConfirm"
                  label={intl.formatMessage({ id: translations.inputs.confirmedPassword.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.confirmedPassword.placeholder })}
                  readOnly={submitting}
                />
              </div>

              {!!submitError && <SubmitError error={submitError} />}

              <Button
                form={id}
                type="submit"
                appearance="ghost"
                loading={submitting}
                disabled={!dirty || pristine}
                className={style.saveButton}
              >
                <FormattedMessage id={translations.modals.editProfile.changePasswordButton} />
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Card.Column>
    </Card.Row>
  </Card>
);

export default injectIntl(EditProfileModal);
