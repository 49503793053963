import React from 'react';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps, FormattedDate } from 'react-intl';

import { Note } from '@/domains';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

import style from './Row.sass';

export interface Props {
  note: Note;
  hoverable?: boolean;
  language: string;
}

const NoteRow: React.FC<Props & WrappedComponentProps> = ({ note, hoverable = true, language, intl }) => (
  <Typography
    is={Card.Row}
    type="halcyon"
    weight="bold"
    className={classnames(style.container, { [style.hoverable]: hoverable })}
  >
    <div className={cellStyle.sessionDetail}>
      {!note.session ? (
        '-'
      ) : (
        <React.Fragment>
          {note.session.type.translations && note.session.type.translations[language.toLowerCase()]
            ? note.session.type.translations[language.toLowerCase()]
            : note.session.type.name}
          <Typography is="div" type="halcyon" color="gray" weight="lighter">
            {note.session.location.name}, &nbsp;
            <FormattedDate value={note.session.start} day="2-digit" month="long" />
          </Typography>
        </React.Fragment>
      )}
    </div>

    <div className={cellStyle.noteTitle}>
      <Typography is="div" type="halcyon">
        {note.title}
      </Typography>

      <Typography is="div" type="halcyon" color="gray" weight="lighter">
        {note.description}
      </Typography>
    </div>
  </Typography>
);

export default injectIntl(NoteRow);
