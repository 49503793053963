import React from 'react';

import { Item } from '@/components/Select';
import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<string>, 'items'> {
  source: () => Promise<{ id: string; name: string }[]>;
}

interface State {
  loading: boolean;
  items: Item<string>[];
}

class DataOptionSelect<T> extends React.PureComponent<Props, State> {
  state: State = { loading: false, items: [] };

  componentDidMount() {
    this.loadItems();
  }

  loadItems = () => {
    return this.load(this.props.source);
  };

  load = (callback: () => Promise<{ id: string; name: string }[]>) => {
    this.setState({ loading: true });

    return callback()
      .then((items) => {
        this.setState({
          loading: false,
          items: items.map((item) => ({
            value: item.id,
            name: item.name
          }))
        });
      })
      .catch(() => this.setState({ loading: false, items: [] }));
  };

  render() {
    const { source, ...rest } = this.props;
    const { items } = this.state;

    return <SimpleSelect {...rest} items={items} />;
  }
}

export default DataOptionSelect;
