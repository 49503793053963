export enum Mood {
  Proud = 'PROUD',
  Excited = 'EXCITED',
  Happy = 'HAPPY',
  Cooperative = 'COOPERATIVE',
  Relaxed = 'RELAXED',
  Humble = 'HUMBLE',
  Reserved = 'RESERVED',
  Quiet = 'QUIET',
  Angry = 'ANGRY',
  Frustrated = 'FRUSTRATED',
  Shamed = 'SHAMED',
  Fearful = 'FEARFUL',
  Sad = 'SAD',
  Lonely = 'LONELY',
  Bored = 'BORED',
  Discouraged = 'DISCOURAGED'
}

export enum MoodState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum MoodType {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE'
}

export interface MoodReportLabel {
  state: MoodState;
  type: MoodType;
}

export interface MoodEvaluation {
  mood: Mood;
}

export interface EvaluateMood extends MoodEvaluation {}
