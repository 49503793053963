import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { TeamCreation } from '@/domains';
import { urls, STRING_SHORT_MAX_LENGTH, STRING_LONG_MAX_LENGTH } from '@/constants';
import { createTeam } from '@/services/api/team';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import { AppConsumer } from '@/components/Context/App';
import PictureUpload from '@/components/PictureUpload';
import { withValidation } from '@/components/hoc/withValidation';

import style from './NewTeamModal.sass';

const PictureUploadWithValidation = withValidation(PictureUpload);
const TextInputWithValidation = withValidation(TextInput);
const TextAreaWithValidation = (props) => <TextInputWithValidation is="textarea" {...props} />;

const schema: yup.ObjectSchema<TeamCreation> = yup.object({
  imageUrl: yup
    .mixed()
    .label(translations.inputs.teamPhoto)
    .nullable()
    .notRequired(),
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.teamName.label)
    .required(),
  description: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.moreInfo.label)
    .nullable()
    .notRequired()
});

interface Props extends InstanceProps {
  close: () => void;
}

class NewTeamModal extends React.PureComponent<Props & WrappedComponentProps & RouteComponentProps> {
  render() {
    return (
      <AppConsumer>
        {({ selectTeam }) => (
          <Card className={style.root}>
            <Button className={style.closeButton} onClick={this.props.close}>
              <BoxedIcon type="close" appearance="red" />
            </Button>

            <div className={style.padded}>
              <Typography is="span" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.createTeam.basicInfo.title} />
              </Typography>

              <Spacer xs={5} />

              <Form
                subscription={{ errors: true, submitting: true, valid: true }}
                schema={schema}
                onSubmit={(values) =>
                  createTeam(values)
                    .then((response) => selectTeam(response.id))
                    .then(this.props.close)
                    .then(() => this.props.history.push(urls.team.detail))
                }
                className={style.teamDetailsForm}
              >
                {({ submitError, submitting, valid }) => (
                  <React.Fragment>
                    <div>
                      <Form.Field
                        is={PictureUploadWithValidation}
                        id="new-image-url"
                        name="imageUrl"
                        title={this.props.intl.formatMessage({ id: translations.inputs.teamPhoto })}
                        accept="image/x-png,image/jpeg"
                        readOnly={submitting}
                      />

                      <Spacer xs={5} />

                      <Typography is="span" type="halcyon" color="gray">
                        <FormattedMessage id={translations.modals.createTeam.basicInfo.teamName} />
                      </Typography>

                      <Spacer xs={1} />

                      <Form.Field
                        is={TextInputWithValidation}
                        id="new-team-name"
                        name="name"
                        type="text"
                        label={this.props.intl.formatMessage({ id: translations.inputs.teamName.label })}
                        placeholder={this.props.intl.formatMessage({ id: translations.inputs.teamName.placeholder })}
                        readOnly={submitting}
                      />

                      <Spacer xs={3} />

                      <Typography is="span" type="halcyon" color="gray">
                        <FormattedMessage id={translations.modals.createTeam.basicInfo.moreInfo} />
                      </Typography>

                      <Spacer xs={1} />

                      <Form.Field
                        is={TextAreaWithValidation}
                        id="new-team-description"
                        name="description"
                        type="text"
                        placeholder={this.props.intl.formatMessage({ id: translations.inputs.moreInfo.placeholder })}
                        readOnly={submitting}
                        inputClassName={style.noResize}
                      />

                      {submitError && submitError}
                    </div>

                    <Button
                      loading={submitting}
                      type="submit"
                      appearance="orange"
                      className={style.nextButton}
                      disabled={!valid}
                    >
                      <FormattedMessage id={translations.inputs.create} />
                    </Button>
                  </React.Fragment>
                )}
              </Form>
            </div>
          </Card>
        )}
      </AppConsumer>
    );
  }
}
export default withRouter(injectIntl(NewTeamModal));
