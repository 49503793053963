import React from 'react';
import { debounce } from 'lodash';

import { storage } from '@/services';

const filterExceptionKeys = (obj: { [x: string]: any } = {}, exceptionKeys: string[] = []) => {
  const newObj = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (exceptionKeys.includes(key)) {
      delete newObj[key];
    }
  });

  return newObj;
};

interface RenderProps {
  criteria: { [x: string]: any };
  updateCriteria: (name: string, value: any) => void;
  debouncedUpdate: (name: string, value: any) => void;
}

interface Props {
  storageItem: string;
  initialCriteria: { [x: string]: any };
  children: (props: RenderProps) => any;
  exceptionKeys?: string[];
}

interface State {
  criteria: { [x: string]: any };
}

export default class FilterManagerWithPersistence extends React.PureComponent<Props, State> {
  state: State = {
    //If a new filter is added in the future, it won't be overwritten by the items in local storage
    criteria: {
      ...this.props.initialCriteria,
      ...filterExceptionKeys(storage.getItem(`${this.props.storageItem}-filters`), this.props.exceptionKeys)
    }
  };

  updateCriteria = (name: string, value: any) => {
    this.setState(
      (state) => ({ criteria: { ...state.criteria, [name]: value } }),
      () => {
        if (!this.props.exceptionKeys || !this.props.exceptionKeys.includes(name)) {
          storage.setItem(`${this.props.storageItem}-filters`, { ...this.state.criteria, [name]: value });
        }
      }
    );
  };

  debouncedUpdate = debounce(this.updateCriteria, 300);

  render() {
    return this.props.children({
      criteria: this.state.criteria,
      updateCriteria: this.updateCriteria,
      debouncedUpdate: this.debouncedUpdate
    });
  }
}
