import React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import If from '@/components/If';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { LanguageContext } from '@/components/Language';
import CreateGoalModal from '@/components/Modals/CreateGoalModal';

import GoalHeader from './GoalHeader';
import GoalRow from './GoalRow';

import style from './Goals.sass';

interface Params {
  participantId: string;
  evaluationId: string;
}

interface Props {
  goals;
  loading: boolean;
  reload;
}

const Goals: React.FC<Props & RouteComponentProps<Params> & WrappedComponentProps> = ({
  match,
  goals,
  loading,
  reload
}) => {
  const { participantId, evaluationId: sessionId } = match.params;

  const { language } = React.useContext(LanguageContext);

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <Typography is="h6" type="swan" weight="bold">
          <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.title} />
        </Typography>

        <Spacer xs={2} />

        <If
          condition={!!goals && goals.length === 0}
          then={() => (
            <Placeholder image="questions" size="sm">
              <Typography is="span" type="halcyon" weight="bold">
                <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.none} />
              </Typography>
            </Placeholder>
          )}
          else={() => (
            <div className={style.goals}>
              <GoalHeader />

              {goals.map((goalEvaluation, index) => (
                <GoalRow
                  loading={loading}
                  sessionId={sessionId}
                  key={index}
                  goalEvaluation={goalEvaluation}
                  reload={reload}
                  language={language}
                />
              ))}
            </div>
          )}
        />

        <Spacer xs={2} />

        <Modal modal={CreateGoalModal} participantId={participantId} onGoalCreated={reload}>
          {({ open }) => (
            <Typography is="span" type="halcyon" color="orange" onClick={open} className={style.link}>
              <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.goals.create} />
            </Typography>
          )}
        </Modal>
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(withRouter(Goals));
