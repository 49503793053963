import React from 'react';
import { debounce } from 'lodash';
import classnames from 'classnames';

import { Page } from '@/domains';

import style from './InfiniteScroll.sass';

interface State<T> {
  page: number;
  more: boolean;
  total: number;
  data: T[];
  loading: boolean;
}

interface RenderProps<T> extends Pick<State<T>, 'data' | 'loading' | 'total'> {
  reload: () => void;
}

export interface Props<T> {
  id?: string | number;
  size: number;
  source: (page: number, size: number) => Promise<Page<T>>;
  children: (props: RenderProps<T>) => React.ReactNode;
  className?: string;
}

export default class InfiniteScroll<T> extends React.PureComponent<Props<T>, State<T>> {
  static defaultProps = {
    size: 10
  };

  state: State<T> = {
    page: 1,
    data: [],
    more: true,
    total: 0,
    loading: true
  };

  private ref = React.createRef<HTMLDivElement>();

  fetch = () =>
    this.props
      .source(this.state.page, this.props.size)
      .then((response: Page<T>) =>
        this.setState(({ data, page }) => ({
          data: [...data, ...response.content],
          more: (page - 1) * this.props.size + response.pageSize < response.totalNumberOfElements,
          total: response.totalNumberOfElements,
          page: page + 1,
          loading: false
        }))
      )
      .catch(() => this.setState({ loading: false }));

  load = () => {
    if (!this.state.more) return;

    this.setState({ loading: true }, this.fetch);
  };

  reload = () => this.setState({ page: 1, data: [], total: 0, loading: true, more: true }, this.fetch);

  componentDidMount = () => this.load();

  componentDidUpdate = (props: Props<T>) => {
    if (props.id !== this.props.id) {
      this.reload();
    }
  };

  handleScroll = debounce(() => {
    if (this.state.loading || !this.state.more) return;

    const list = this.ref.current;
    if (list.scrollHeight - (list.scrollTop + list.offsetHeight) < 10) {
      this.load();
    }
  }, 100);

  render() {
    const { className, children } = this.props;
    const { data, loading, total } = this.state;

    return (
      <div className={classnames(style.root, className)} ref={this.ref} onScroll={this.handleScroll}>
        {children({ data, loading, total, reload: this.reload })}
      </div>
    );
  }
}
