import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const DataOptionHeader: React.FC<{}> = () => {
  const { name, min, max } = translations.pages.organization.dataCustomisation.tabs.ageGroups.header;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={cellStyle.headerContainer}>
        <Typography is="div" className={cellStyle.name} type="halcyon" color="gray">
          <FormattedMessage id={name} />
        </Typography>

        <Typography is="div" className={cellStyle.min} type="halcyon" color="gray">
          <FormattedMessage id={min} />
        </Typography>

        <Typography is="div" className={cellStyle.max} type="halcyon" color="gray">
          <FormattedMessage id={max} />
        </Typography>
      </div>

      <div className={cellStyle.remove}></div>
    </Card.Row>
  );
};

export default DataOptionHeader;
