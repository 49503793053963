import React from 'react';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

interface Props {
  children: (state: { isComplete: boolean; handleFieldChange: (event: StripeElementChangeEvent) => any }) => any;
}

interface State {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

export default class PaymentFieldsValidation extends React.PureComponent<Props, State> {
  state: State = {
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  };

  handleFieldChange = (event: StripeElementChangeEvent) => {
    switch (event.elementType) {
      case 'cardNumber':
        this.setState({ cardNumber: event.complete });
        break;
      case 'cardExpiry':
        this.setState({ cardExpiry: event.complete });
        break;
      case 'cardCvc':
        this.setState({ cardCvc: event.complete });
        break;
    }
  };

  isComplete = () => {
    const { cardNumber, cardExpiry, cardCvc } = this.state;
    return cardNumber && cardExpiry && cardCvc;
  };

  render() {
    return this.props.children({ isComplete: this.isComplete(), handleFieldChange: this.handleFieldChange });
  }
}
