import React from 'react';

export interface Props<K, T> {
  id?: K;
  initialValues?: T;
  load: (id: K | undefined) => Promise<T>;
  children: (props: RenderProps<T>) => any;
}

export interface State<T> {
  value?: T;
  loading: boolean;
  error: string | null;
}

export interface RenderProps<T> extends State<T> {
  reload: () => Promise<void>;
}

export default class LoadData<T, K> extends React.PureComponent<Props<K, T>, State<T>> {
  state: State<T> = {
    value: undefined,
    loading: true,
    error: null
  };

  load(id?: K) {
    this.setState({ value: undefined, loading: true, error: null });

    return this.props
      .load(id)
      .then((value) => this.setState({ value, loading: false }))
      .catch((error) =>
        this.setState({
          error: error && error.message ? error.message : 'Unknown load error.',
          loading: false
        })
      );
  }

  reload = () => {
    return this.load(this.props.id);
  };

  componentDidMount() {
    return this.reload();
  }

  componentDidUpdate(props: Props<K, T>) {
    if (props.id !== this.props.id) return this.load(this.props.id);
  }

  render() {
    const { initialValues, children } = this.props;
    const { value, loading, error } = this.state;

    return children({
      value: value || initialValues,
      loading,
      error,
      reload: this.reload
    });
  }
}
