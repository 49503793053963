import React from 'react';
import { debounce } from 'lodash';

interface RenderProps {
  criteria: { [x: string]: any };
  updateCriteria: (name: string, value: any) => void;
  debouncedUpdate: (name: string, value: any) => void;
}

interface Props {
  initialCriteria: { [x: string]: any };
  children: (props: RenderProps) => any;
}

interface State {
  criteria: { [x: string]: any };
}

export default class FilterManager extends React.PureComponent<Props, State> {
  state: State = {
    criteria: this.props.initialCriteria
  };

  updateCriteria = (name: string, value: any) => {
    this.setState((state) => ({ criteria: { ...state.criteria, [name]: value } }));
  };

  debouncedUpdate = debounce(this.updateCriteria, 300);

  render() {
    return this.props.children({
      criteria: this.state.criteria,
      updateCriteria: this.updateCriteria,
      debouncedUpdate: this.debouncedUpdate
    });
  }
}
