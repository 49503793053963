import { stringify } from 'query-string';

import { API } from '@/config';

export const getExportParticipantsRedirectUrl = (teamId: string, token: string, sessionId?: string) =>
  `${API}/v1/participants/export?${stringify({ teamId, token, sessionId })}`;

export const getExportEvaluationsRedirectUrl = (teamId: string, token: string) =>
  `${API}/v1/evaluations/export?${stringify({ teamId, token })}`;

export const getExportSessionsRedirectUrl = (teamId?: string, token?: string) =>
  `${API}/v1/sessions/export?${stringify({ teamId, token })}`;

export const getExportAllSessionsRedirectUrl = (token?: string) => `${API}/v1/sessions/export?${stringify({ token })}`;

export const getExportAllTeamNotesRedirectUrl = (teamId: string, token?: string) =>
  `${API}/v1/notes/team/${teamId}/export?${stringify({ token })}`;

export const getExportOrgContactInfoRedirectUrl = (token?: string) =>
  `${API}/v1/organizations/export?${stringify({ token })}`;

export const getExportUsersRedirectUrl = (token: string) => `${API}/v1/users/export?${stringify({ token })}`;
