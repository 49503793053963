import { CreateStreetSessionLabel, StreetSessionLabel, Page } from '@/domains';
import { authorizedApi } from '../network/authorized-api';

export const getLabels: (
  page: number,
  size: number,
  options?: {
    [x: string]: any;
  }
) => Promise<Page<StreetSessionLabel>> = (pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/labels`, { pageNumber, pageSize, ...options });

export const createLabel: (label: CreateStreetSessionLabel) => Promise<unknown> = ({ name }) =>
  authorizedApi.post<any>(`/v1/labels`, { name });
