import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { DataOptionType, DataOption, State as DataOptionState } from '@/domains';
import { getDataOptionsForTeamWithFilter } from '@/services/api/data-option';
import { addSessionActivities } from '@/services/api/street-session-activities';

import { InstanceProps } from '@/components/Modal';
import { AppContext } from '@/components/Context/App';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

interface Props extends InstanceProps {
  sessionId: string;
  initialValues?: string[];
  onSubmit: () => void;
}

const AddActivitiesToSessionModal: React.FC<Props & WrappedComponentProps> = ({
  sessionId,
  initialValues,
  onSubmit,
  close,
  intl
}) => {
  const extractActivityData = (activity: DataOption): SelectableItemData => ({
    id: activity.id,
    topText: activity.name,
    photoUrl: activity.icon ? activity.icon.url : null
  });

  const { team } = React.useContext(AppContext);

  return (
    <SearchableModal close={close}>
      <SearchableModal.Header
        title={intl.formatMessage({ id: translations.modals.searchableModal.activitiesToSession.title })}
        subtitle={intl.formatMessage({ id: translations.modals.searchableModal.activitiesToSession.subtitle })}
      />

      <SearchableModal.Content
        close={close}
        initialValues={{ selectedItems: initialValues }}
        searchLabel={intl.formatMessage({ id: translations.inputs.activitiesSearch.label })}
        searchPlaceholder={intl.formatMessage({ id: translations.inputs.activitiesSearch.placeholder })}
        dataSource={(criteria) =>
          getDataOptionsForTeamWithFilter(DataOptionType.ActivityType, team.id, criteria, DataOptionState.Enabled)
        }
        submitDestination={(values) =>
          addSessionActivities(sessionId, values.selectedItems.map((id) => ({ id }))).then(onSubmit)
        }
        dataExtractionFunction={extractActivityData}
        withoutPagination
      />
    </SearchableModal>
  );
};
export default injectIntl(AddActivitiesToSessionModal);
