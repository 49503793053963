import React from 'react';
import classnames from 'classnames';

import Typography from '@/components/Typography';
import { Type } from '@/components/Icon';
import TextWithIcon from '@/components/TextWithIcon';

import style from './DataWithIcon.sass';

export interface Props {
  iconType: Type;
  title?: string;
  subtitle?: string;
  className?: string;
}

const DataWithIcon: React.FC<Props> = ({ iconType, title, subtitle, className, ...rest }) => (
  <div className={classnames(style.root, className)}>
    {title && (
      <TextWithIcon is="div" type="sparrow" weight="bold" iconColor="blue" icon={iconType}>
        {title}
      </TextWithIcon>
    )}

    {subtitle && (
      <Typography className={style.subtitle} is="div" type="hummingbird" color="gray">
        {subtitle}
      </Typography>
    )}
  </div>
);

export default DataWithIcon;
