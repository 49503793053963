import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { Module, ParticipantRole } from '@/constants';
import { translations } from '@/locale';
import { emailSchema } from '@/validation';
import { inviteParticipant } from '@/services/api/participant-digital-coach';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import { Type } from '@/components/Icon';
import Checkbox from '@/components/Checkbox';
import BoxedIcon from '@/components/BoxedIcon';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import TextWithIcon from '@/components/TextWithIcon';
import { AppConsumer } from '@/components/Context/App';
import PictureWithData from '@/components/PictureWithData';
import { withValidation } from '@/components/hoc/withValidation';
import ParticipantRoleSelect from '@/components/Selects/ParticipantRoleSelect';

import style from './InviteParticipantModal.sass';

const schema: yup.ObjectSchema = yup.object({
  email: emailSchema.label(translations.inputs.email.label).required(),
  roleLevel: yup
    .string()
    .label(translations.inputs.role.label)
    .required(),
  modulesAccess: yup
    .array()
    .of(yup.string())
    .min(1)
    .default([])
});

const icons: Record<Module, Type> = {
  [Module.Mood]: 'smile',
  [Module.Skills]: 'eye',
  [Module.Goals]: 'flag',
  [Module.SocialMap]: 'eye'
};

const TextInputWithValidation = withValidation(TextInput);
const ParticipantRoleSelectWithValidation = withValidation(ParticipantRoleSelect);

interface Props {
  participantId: string;
  imageUrl: string;
  name: string;
  age: number;
  onSubmit?: () => void;
}

class InviteParticipantModal extends React.PureComponent<Props & InstanceProps & WrappedComponentProps> {
  renderModules(submitting: boolean, id: string) {
    return Object.values(Module).map((module) => (
      <React.Fragment key={module}>
        <Form.Field
          is={Checkbox}
          id={`${id}-${module}`}
          name="modulesAccess"
          appearance="circle"
          type="checkbox"
          align="right"
          value={module}
          readOnly={submitting}
          className={style.module}
        >
          <TextWithIcon is="span" icon={icons[module]} iconColor="blue" boxed iconSize="small" type="halcyon">
            <FormattedMessage id={translations.modules[module]} />
          </TextWithIcon>
        </Form.Field>
        <Spacer xs={2} />
      </React.Fragment>
    ));
  }

  render() {
    const { participantId, imageUrl, name, age, onSubmit, intl, close } = this.props;
    const title = translations.modals.inviteParticipantModal.title;

    return (
      <AppConsumer>
        {({ team, loading }) =>
          !loading && (
            <Card className={style.root}>
              <Card.Row>
                <Card.Column sm={12}>
                  <div className={style.titleAndClose}>
                    <Typography is="span" type="swan" weight="bold">
                      <FormattedMessage id={title.withData} values={{ name: name || null }} />
                    </Typography>

                    <Button onClick={this.props.close}>
                      <BoxedIcon type="close" appearance="red" />
                    </Button>
                  </div>

                  <Spacer xs={2} />

                  <Typography className={style.subtitle} is="p" type="hummingbird" color="gray">
                    <FormattedMessage id={translations.modals.inviteParticipantModal.subtitle} />
                  </Typography>
                </Card.Column>
              </Card.Row>

              <Card.Row>
                <div className={style.paddedContainer}>
                  {imageUrl && name && age && (
                    <React.Fragment>
                      <Spacer xs={3} />

                      <PictureWithData url={imageUrl} size="md" blueText={name} grayText={`${age}`} />
                    </React.Fragment>
                  )}
                </div>
                <Form
                  id="invite-users"
                  schema={schema}
                  subscription={{
                    dirty: true,
                    pristine: true,
                    submitError: true,
                    valid: true,
                    submitting: true,
                    values: true
                  }}
                  onSubmit={(values: any) =>
                    inviteParticipant({
                      email: values.email,
                      roleLevel: values.roleLevel,
                      modulesAccess: values.modulesAccess,
                      participantId,
                      teamId: team.id
                    }).then(onSubmit)
                  }
                  className={style.form}
                  initialValues={{ roleLevel: ParticipantRole.Starter }}
                >
                  {({ submitError, submitting, valid }, id) => (
                    <React.Fragment>
                      <Spacer xs={2} />
                      <div className={classnames(style.row, style.paddedContainer)}>
                        <Form.Field
                          is={TextInputWithValidation}
                          type="text"
                          id={`${id}-email`}
                          name="email"
                          label={intl.formatMessage({ id: translations.inputs.email.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                          readOnly={submitting}
                          className={style.email}
                        />

                        <Form.Field
                          is={ParticipantRoleSelectWithValidation}
                          id={`${id}-role`}
                          name="roleLevel"
                          label={intl.formatMessage({ id: translations.inputs.participantRole.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.participantRole.placeholder })}
                          readOnly={submitting}
                          className={style.roleSelect}
                        />
                      </div>

                      <div className={classnames(style.column, style.paddedContainer)}>
                        <Spacer xs={3} />

                        <Typography is="span" type="hummingbird" color="gray">
                          <FormattedMessage id={translations.modals.inviteParticipantModal.modulesSelect} />
                        </Typography>

                        <Spacer xs={3} />

                        {this.renderModules(submitting, id)}

                        <Spacer xs={2} />
                      </div>

                      <Card.Row>
                        {!!submitError && <SubmitError error={submitError} className={style.submitError} />}

                        <Card.Column sm={12}>
                          <Button
                            form={id}
                            type="submit"
                            className={style.sendButton}
                            loading={submitting}
                            disabled={!valid}
                            appearance="orange"
                          >
                            <FormattedMessage id={translations.modals.inviteUsers.sendButton} />
                          </Button>
                        </Card.Column>
                      </Card.Row>
                    </React.Fragment>
                  )}
                </Form>
              </Card.Row>
            </Card>
          )
        }
      </AppConsumer>
    );
  }
}
export default injectIntl(InviteParticipantModal);
