import React from 'react';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { urls, qualitativePalette, moodPallete } from '@/constants';
import { translations } from '@/locale';
import { Filter, LocationReport, Period } from '@/domains';
import {
  getMoodReportLabel,
  getColorFromPalleteByIndex,
  multiplyPalleteUpToLength,
  checkIfWeShouldDeselectAll
} from '@/util';
import { getLocationReports } from '@/services/api/location-reports';
import { getDataOptionsWithTranslationsForTeam } from '@/services/api/data-option';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import Breadcrumb from '@/components/Breadcrumb';
import Typography from '@/components/Typography';
import LazyRender from '@/components/LazyRender';
import ReportsSection from '@/components/Reports';
import { AppContext } from '@/components/Context/App';
import FilterManager from '@/components/FilterManager';
import EntityPreview from '@/components/EntityPreview';
import ErrorBoundary from '@/components/ErrorBoundary';
import { LanguageContext } from '@/components/Language';
import { PieChart, BarChart } from '@/components/Charts';
import SessionLabelSelect from '@/components/Selects/SessionLabelSelect';
import LocationSessionNotesTable from '@/components/tables/LocationSessionNotesTable';
import LocationLifeEventNotesTable from '@/components/tables/LocationLifeEventNotesTable';
import RecommendedParticipantsTable from '@/components/tables/RecommendedParticipantsTable';

import style from './Reports.sass';

interface Params {
  locationId: string;
}

const Reports: React.FC<WrappedComponentProps & RouteComponentProps<Params>> = ({ intl, history, match, location }) => {
  if (!location.state) history.push(urls.team.detail);
  const { locationId } = match.params;

  const getLabelsFromPeriod = (period: Period[]): string[] => {
    if (!period) return;

    return period.map(({ year, periodOfYear: month, subPeriod: day }) =>
      intl.formatDate(new Date(year, month - 1, day), { month: 'short', day: '2-digit' })
    );
  };
  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!team && <EntityPreview logo={team.imageUrl} name={team.name} />}

      <Spacer xs={4} />

      <Card className={style.card}>
        <Card.Row>
          <Card.Column sm={12}>
            <FilterManager
              initialCriteria={{
                labelIds: [Filter.All]
              }}
            >
              {({ criteria, updateCriteria }) => (
                <ErrorBoundary>
                  <div className={classnames(style.title, style.headline)}>
                    <Breadcrumb
                      shape={{
                        [intl.formatMessage({ id: translations.pages.teams.tabs.location.title })]: urls.team.detail,
                        [intl.formatMessage(
                          { id: translations.pages.teams.tabs.location.reports.title },
                          { location: location.state.name }
                        )]: null
                      }}
                    />
                  </div>

                  <Spacer xs={5} />

                  <div className={style.headline}>
                    <div>
                      <Typography is="h1" type="corvus" weight="bold">
                        <FormattedMessage id={translations.pages.teams.tabs.location.reports.sessionNotes.title} />
                      </Typography>

                      <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                        <FormattedMessage
                          id={translations.pages.teams.tabs.location.reports.sessionNotes.description}
                        />
                      </Typography>
                    </div>

                    <div className={style.withLabel}>
                      <label htmlFor="session-labels" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.tabs.location.reports.filters.sessionLabel} />
                      </label>

                      <SessionLabelSelect
                        id="session-labels"
                        value={criteria.labelIds}
                        onChange={(value) => updateCriteria('labelIds', checkIfWeShouldDeselectAll([...value]))}
                        searchable
                        multiple
                      />
                    </div>
                  </div>

                  <Spacer xs={2} />

                  <LocationSessionNotesTable key={JSON.stringify({ ...criteria })} locationId={locationId} />

                  <Spacer xs={4} />

                  <Typography is="h1" type="corvus" weight="bold">
                    <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.title} />
                  </Typography>

                  <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                    <FormattedMessage id={translations.pages.teams.tabs.location.reports.lifeEventNotes.description} />
                  </Typography>

                  <Spacer xs={2} />

                  <LazyRender height={427}>
                    <LocationLifeEventNotesTable key={JSON.stringify({ ...criteria })} locationId={locationId} />
                  </LazyRender>

                  <Spacer xs={4} />

                  <LazyRender height={1300}>
                    <LoadData
                      key={JSON.stringify({ ...criteria })}
                      load={() =>
                        getLocationReports<LocationReport.Participant>(
                          locationId,
                          LocationReport.ReportType.Participant,
                          criteria
                        )
                      }
                    >
                      {({ value, loading }) => (
                        <If
                          condition={loading}
                          then={() => (
                            <Loading visible={loading} center className={style.loading}>
                              <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                            </Loading>
                          )}
                          else={() => (
                            <React.Fragment>
                              <ReportsSection
                                title={intl.formatMessage({
                                  id: translations.pages.teams.tabs.location.reports.participants.title
                                })}
                              >
                                <ReportsSection.Charts>
                                  <BarChart
                                    title={intl.formatMessage({
                                      id: translations.pages.teams.tabs.location.reports.participants.number
                                    })}
                                    name="participants-number"
                                    data={{
                                      labels: getLabelsFromPeriod(value.periods),
                                      datasets: [
                                        ...value.participantsInSessions.entries.map((entry, index) => ({
                                          label:
                                            entry.label.translations && entry.label.translations[language.toLowerCase()]
                                              ? entry.label.translations[language.toLowerCase()]
                                              : entry.label.name,
                                          data: entry.data,
                                          backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                                        }))
                                      ]
                                    }}
                                    options={{
                                      maintainAspectRatio: false,
                                      scales: {
                                        yAxes: [
                                          {
                                            ticks: {
                                              min: 0,
                                              stepSize: 1
                                            }
                                          }
                                        ]
                                      }
                                    }}
                                    className={style.wide}
                                    height={279}
                                  />

                                  <PieChart
                                    title={intl.formatMessage({
                                      id: translations.pages.teams.tabs.location.reports.participants.age
                                    })}
                                    name="participants-age"
                                    data={{
                                      labels: value.ageReport.labels.map((label) =>
                                        label ? label : intl.formatMessage({ id: translations.miscellaneous.notSet })
                                      ),
                                      dataset: value.ageReport.data
                                    }}
                                  />

                                  <PieChart
                                    title={intl.formatMessage({
                                      id: translations.pages.teams.tabs.location.reports.participants.gender
                                    })}
                                    name="participants-gender"
                                    data={{
                                      labels: value.genderReport.labels.map((label) =>
                                        intl.formatMessage({
                                          id: label ? translations.genders[label] : translations.miscellaneous.notSet
                                        })
                                      ),
                                      dataset: value.genderReport.data
                                    }}
                                  />

                                  <PieChart
                                    title={intl.formatMessage({
                                      id: translations.pages.teams.tabs.location.reports.participants.nativeLanguage
                                    })}
                                    name="participants-native-language"
                                    data={{
                                      labels: value.motherTongueReport.labels.map((label) =>
                                        intl.formatMessage({
                                          id: label
                                            ? translations.languages[label.toUpperCase()]
                                            : translations.miscellaneous.notSet
                                        })
                                      ),
                                      dataset: value.motherTongueReport.data
                                    }}
                                  />
                                </ReportsSection.Charts>
                              </ReportsSection>

                              <Spacer xs={4} />

                              <ReportsSection
                                title={intl.formatMessage({
                                  id: translations.pages.teams.tabs.location.reports.activities.title
                                })}
                              >
                                <ReportsSection.Charts>
                                  <LoadData
                                    load={() =>
                                      getDataOptionsWithTranslationsForTeam(
                                        [...value.activitiesReport.labels.map((label) => label.identifier)],
                                        team.id
                                      )
                                    }
                                  >
                                    {({ value: optionsWithTranslations, loading }) =>
                                      !loading && (
                                        <React.Fragment>
                                          <BarChart
                                            title={intl.formatMessage({
                                              id: translations.pages.teams.tabs.location.reports.activities.variety
                                            })}
                                            name="participant-activities"
                                            data={{
                                              labels: [
                                                intl.formatMessage({
                                                  id: translations.pages.teams.tabs.location.reports.activities.variety
                                                })
                                              ],
                                              datasets: value.activitiesReport.labels.map((label, index) => ({
                                                label: optionsWithTranslations.find(
                                                  (element) => element.id == label.identifier
                                                ).translations[language.toLowerCase()],
                                                data: [value.activitiesReport.data[index]],
                                                backgroundColor: multiplyPalleteUpToLength(
                                                  value.activitiesReport.labels.length,
                                                  qualitativePalette
                                                )[index]
                                              }))
                                            }}
                                            className={style.wide}
                                            options={{
                                              maintainAspectRatio: false,
                                              scales: {
                                                yAxes: [
                                                  {
                                                    ticks: {
                                                      min: 0,
                                                      stepSize: 1
                                                    }
                                                  }
                                                ]
                                              }
                                            }}
                                            height={400}
                                          />
                                        </React.Fragment>
                                      )
                                    }
                                  </LoadData>
                                </ReportsSection.Charts>
                              </ReportsSection>
                            </React.Fragment>
                          )}
                        />
                      )}
                    </LoadData>
                  </LazyRender>

                  <Spacer xs={4} />

                  <LazyRender height={337}>
                    <ReportsSection
                      title={intl.formatMessage({
                        id: translations.pages.teams.tabs.location.reports.moods.title
                      })}
                    >
                      <LoadData
                        load={() =>
                          getLocationReports<LocationReport.Mood>(locationId, LocationReport.ReportType.Mood, criteria)
                        }
                      >
                        {({ value, loading }) => (
                          <If
                            condition={loading}
                            then={() => (
                              <Loading visible={loading} center className={style.loading}>
                                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                              </Loading>
                            )}
                            else={() => (
                              <ReportsSection.Charts>
                                <PieChart
                                  title={intl.formatMessage({
                                    id: translations.pages.teams.tabs.location.reports.moods.variety
                                  })}
                                  name="registered-moods"
                                  data={{
                                    labels: value.evaluationsInQuadrants.labels.map((label) =>
                                      intl.formatMessage({
                                        id: translations.reportMood[getMoodReportLabel(label.type, label.state)]
                                      })
                                    ),
                                    dataset: value.evaluationsInQuadrants.data
                                  }}
                                  pallete={moodPallete}
                                />

                                <PieChart
                                  title={intl.formatMessage({
                                    id: translations.pages.teams.tabs.location.reports.moods.lastSessionVariety
                                  })}
                                  name="last-session-registered-moods"
                                  data={{
                                    labels: value.evaluationsInQuadrantsForLastSession.labels.map((label) =>
                                      intl.formatMessage({
                                        id: translations.reportMood[getMoodReportLabel(label.type, label.state)]
                                      })
                                    ),
                                    dataset: value.evaluationsInQuadrantsForLastSession.data
                                  }}
                                  pallete={moodPallete}
                                />
                              </ReportsSection.Charts>
                            )}
                          />
                        )}
                      </LoadData>
                    </ReportsSection>
                  </LazyRender>

                  <Spacer xs={4} />

                  <Typography is="h1" type="corvus" weight="bold">
                    <FormattedMessage
                      id={translations.pages.teams.tabs.location.reports.recommendedParticipants.title}
                    />
                  </Typography>

                  <Typography is="h6" type="halcyon" weight="lighter" color="gray">
                    <FormattedMessage
                      id={translations.pages.teams.tabs.location.reports.recommendedParticipants.description}
                    />
                  </Typography>

                  <Spacer xs={2} />

                  <LazyRender height={431}>
                    <RecommendedParticipantsTable locationId={locationId} />
                  </LazyRender>
                </ErrorBoundary>
              )}
            </FilterManager>
          </Card.Column>
        </Card.Row>
      </Card>
    </div>
  );
};

export default withRouter(injectIntl(Reports));
