import { withPagingParams } from '@/util';
import { Page, StreetSession, NewStreetSession, UpdateStreetSession, StreetSessionLabel } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const createStreetSession = (streetSession: NewStreetSession) =>
  authorizedApi.post('/v2/sessions', streetSession);

export const updateStreetSession = (id: string, streetSession: UpdateStreetSession) =>
  authorizedApi.put(`/v2/sessions/${id}`, streetSession);

export const updateStreetSessionUnregisteredParticipants = (
  sessionId: string,
  unregisteredParticipants: number
): Promise<any> =>
  authorizedApi.put(`/v1/sessions/${sessionId}/unregistered-participants`, { number: unregisteredParticipants });

export const updateStreetSessionLabels: (sessionId: string, labels: StreetSessionLabel[]) => Promise<any> = (
  sessionId,
  ids // add id if not exists, remove id if exists
) => authorizedApi.put(`/v1/sessions/${sessionId}/labels`, { ids: ids.map((label) => ({ id: label.id })) });

export const getStreetSessionDetails: (id: string, extraData?) => Promise<StreetSession> = (id, extraData) =>
  authorizedApi.get(`/v1/sessions/${id}`, { extraData });

export const deleteStreetSession: (sessionId: string) => Promise<any> = (sessionId) =>
  authorizedApi.delete<any>(`/v1/sessions/${sessionId}`);

export const getStreetSessions: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<StreetSession>> = (teamId, page, size, options) =>
  authorizedApi.get(`/v1/sessions?${withPagingParams(page, size, { teamIds: [teamId], ...options })}`);
