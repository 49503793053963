import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { Type } from '@/components/Icon';
import Typography from '@/components/Typography';
import BoxedIcon from '@/components/BoxedIcon';
import { SidebarConsumer } from '@/components/Context/Sidebar';

import style from './MenuItem.sass';
import Link from '@/components/Link';

export interface Props {
  icon: Type;
  url: string;
  className?: string;
  external?: boolean;
}

export default class MenuItem extends React.PureComponent<Props> {
  render() {
    const { icon, url, className, external, children } = this.props;
    return (
      <SidebarConsumer>
        {({ close }) => (
          <Link
            to={url}
            className={classnames(style.root, className)}
            activeClassName={style.activeLink}
            onClick={close}
            external={external}
          >
            <BoxedIcon type={icon} appearance="blue" />
            <Typography is="span" type="halcyon" color="white" className={style.content}>
              {children}
            </Typography>
          </Link>
        )}
      </SidebarConsumer>
    );
  }
}
