import React from 'react';
import { Bar, ChartComponentProps } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';

import { getData } from '@/util';
import { translations } from '@/locale';

import DownloadableChart, { Props as DownloadableChartProps } from '@/components/DownloadableChart';

interface Props extends Pick<DownloadableChartProps, 'title'>, ChartComponentProps {
  name: string;
  className?: string;
}

export const BarChart: React.FC<Props> = ({ name, data, options, title, className, ...rest }) => (
  <DownloadableChart title={title} chart={Bar} data={data} options={options} className={className} {...rest}>
    {({ getImage }) => (
      <React.Fragment>
        <DownloadableChart.DownloadButton onClick={() => getImage(`${name}-bar-chart`)}>
          <FormattedMessage id={translations.inputs.chart.jpg} />
        </DownloadableChart.DownloadButton>

        <DownloadableChart.DownloadButton onClick={() => getData(data, `${name}-data`)}>
          <FormattedMessage id={translations.inputs.chart.data} />
        </DownloadableChart.DownloadButton>
      </React.Fragment>
    )}
  </DownloadableChart>
);
