import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const MessagesHeader: React.FC = () => {
  const {
    message,
    author,
    participants,
    creationDate,
    status
  } = translations.pages.digitalCoach.tabs.messages.table.header;
  return (
    <Card.Row className={cellStyle.container}>
      <Typography is="div" type="halcyon" color="gray" className={cellStyle.message}>
        <FormattedMessage id={message} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.author}>
        <FormattedMessage id={author} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.participants}>
        <FormattedMessage id={participants} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.creationDate}>
        <FormattedMessage id={creationDate} />
      </Typography>

      <Typography is="div" type="halcyon" color="gray" className={cellStyle.status}>
        <FormattedMessage id={status} />
      </Typography>
    </Card.Row>
  );
};

export default MessagesHeader;
