import { ParticipantListItem, ReminderStreetSession, User } from '@/domains';

export enum Visibility {
  Private = 'PRIVATE',
  MyTeam = 'MY_TEAM',
  AllTeams = 'ALL_TEAMS'
}

export enum VisibilityFilter {
  All = 'ALL',
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export enum Topic {
  Moods = 'MOODS',
  Skills = 'SKILLS',
  Goals = 'GOALS',
  SocialMap = 'SOCIAL_MAP',
  PersonalInformation = 'PERSONAL_INFORMATION',
  DaytimeOccupation = 'DAYTIME_OCCUPATION'
}

export enum TopicFilter {
  All = 'ALL',
  Moods = 'MOODS',
  Skills = 'SKILLS',
  Goals = 'GOALS',
  SocialMap = 'SOCIAL_MAP',
  PersonalInformation = 'PERSONAL_INFORMATION',
  DaytimeOccupation = 'DAYTIME_OCCUPATION'
}

export interface Note {
  id: string;
  createdBy: User;
  createdAt: number;
  description: string;
  lifeEvent: boolean;
  participant: ParticipantListItem;
  session: ReminderStreetSession;
  title: string;
  topic: Topic;
  visibility: Visibility;
}

export interface CreateNote extends Pick<Note, 'title' | 'topic' | 'visibility' | 'lifeEvent' | 'description'> {}

export interface UpdateNote extends CreateNote {}
