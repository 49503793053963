import React from 'react';
import classnames from 'classnames';

import Typography from '@/components/Typography';

import Row from '../Row';

import style from './Header.sass';

export interface Props {
  className?: string;
}

export default class Header extends React.PureComponent<Props> {
  render() {
    const { className, children } = this.props;

    return (
      <Typography
        is="div"
        type="hummingbird"
        color="gray"
        weight="bold"
        className={classnames(style.root, style.header, className)}
      >
        {children}
      </Typography>
    );
  }
}
