import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import { CustomPropertyAccessibility, CustomProperty } from '@/domains';
import { getCustomPropertiesForTeamLead } from '@/services/api/custom-property';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import { AppContext } from '@/components/Context/App';
import FilterManager from '@/components/FilterManager';
import TeamProfileFieldSelect from '@/components/Selects/TeamProfileFieldSelect';

import ProfileFieldsHeader from './ProfileFieldsHeader';
import CustomPropertyRow from './CustomPropertyRow';

import style from './ProfileFields.sass';

const ProfileFields: React.FC<RouteComponentProps & WrappedComponentProps> = () => {
  const { team } = React.useContext(AppContext);

  const [key, setKey] = React.useState(new Date().getTime());

  const refresh = React.useCallback(() => setKey(new Date().getTime()), []);

  return (
    <Card.Row className={style.root}>
      <Card.Column sm={12}>
        <FilterManager initialCriteria={{ profileFieldType: CustomPropertyAccessibility.General }}>
          {({ criteria, updateCriteria }) => (
            <React.Fragment>
              <div className={style.filterAndButton}>
                <div className={style.filterContainer}>
                  <Typography is="div" type="halcyon" className={style.label}>
                    <FormattedMessage id={translations.dataCustomisation.profileFields.show} />
                  </Typography>

                  <TeamProfileFieldSelect
                    value={criteria.profileFieldType}
                    onChange={(value) => updateCriteria('profileFieldType', value)}
                  />
                </div>
              </div>

              <Table
                key={key}
                id={`${JSON.stringify(criteria)}`}
                className={style.table}
                empty={() => (
                  <Placeholder image="questions" size="lg" className={style.empty}>
                    <Typography is="span" type="halcyon" weight="bold">
                      <FormattedMessage id={translations.dataCustomisation.profileFields.empty} />
                    </Typography>
                  </Placeholder>
                )}
                withoutPagination
                source={() =>
                  getCustomPropertiesForTeamLead(criteria.profileFieldType, team.id).then((result) =>
                    result.sort((a, b) => a.name.localeCompare(b.name))
                  )
                }
                renderHeader={() => (
                  <ProfileFieldsHeader
                    showFieldType={Object.values(CustomPropertyAccessibility).includes(criteria.profileFieldType)}
                  />
                )}
                renderRow={(data: CustomProperty) => (
                  <CustomPropertyRow
                    key={data.id}
                    teamId={team.id}
                    data={data}
                    refresh={refresh}
                    className={style.customItem}
                  />
                )}
              />
            </React.Fragment>
          )}
        </FilterManager>
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(withRouter(ProfileFields));
