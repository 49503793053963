import { PieChartReportData, BarChartReportData, EvaluationCategory, MoodReportLabel, Period } from '@/domains';

export enum ReportType {
  Mood = 'moods',
  Participant = 'participants'
}

export interface Participant {
  activitiesReport?: any;
  ageReport: PieChartReportData<string>;
  genderReport: PieChartReportData<string>;
  motherTongueReport: PieChartReportData<string>;
  participantsInSessions: BarChartReportData;
  periods: Period[];
}

export interface Mood {
  evaluationsInQuadrants: PieChartReportData<MoodReportLabel>;
  evaluationsInQuadrantsForLastSession: PieChartReportData<MoodReportLabel>;
}

export interface RecommendedParticipant {
  birthDate: string;
  evaluations: EvaluationCategory[];
  firstName: string;
  hasOpenGoals: boolean;
  hasSocialMapEvaluations: boolean;
  id: string;
  imageUrl: string;
  lastName: string;
  nextEvaluationDate: Date;
  nickname: string;
}
