import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { MemberBase } from '@/domains';

import Picture from '@/components/Picture';
import Typography from '@/components/Typography';

import style from './Member.sass';

export interface Props {
  is?: React.ElementType;
  member: MemberBase;
  border?: boolean;
  block?: boolean;
  hoverable?: boolean;
  className?: string;
  [x: string]: any;
}

const Member: React.FC<Props> = ({
  is: Component = 'div',
  member,
  border,
  block,
  hoverable,
  className,
  intl,
  ...rest
}) => (
  <Component
    className={classnames(style.root, { [style.block]: block, [style.hoverable]: hoverable }, className)}
    {...rest}
  >
    <Picture size="sm" url={member.imageUrl} type="teammate" className={style.picture} />

    <div className={style.nameAndEmail}>
      <Typography is="span" type="sparrow" weight="bold" color="blue">{`${member.firstName ? member.firstName : ''} ${
        member.lastName ? member.lastName : ''
      } • ${intl.formatMessage({ id: translations.roles[member.role] })}`}</Typography>

      <Typography is="span" type="sparrow" weight="lighter" color="gray">
        {member.email}
      </Typography>
    </div>
  </Component>
);

export default injectIntl(Member);
