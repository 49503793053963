import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { updateParticipantActivities } from '@/services/api/activity';

import If from '@/components/If';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import AddActivityModal from '@/components/Modals/AddActivityModal';

import style from './Activities.sass';
import { LanguageContext } from '@/components/Language';

interface Params {
  participantId: string;
  evaluationId: string;
}

interface Props {
  activities;
  reload;
}

const Activities: React.FC<Props & RouteComponentProps<Params> & WrappedComponentProps> = ({
  match,
  activities,
  reload
}) => {
  const { participantId, evaluationId: sessionId } = match.params;

  const { language } = React.useContext(LanguageContext);

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <Typography is="h6" type="swan" weight="bold">
          <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.activities.title} />
        </Typography>

        <Spacer xs={2} />

        <If
          condition={!!activities && activities.length === 0}
          then={() => (
            <Placeholder image="questions" size="sm">
              <Typography is="span" type="halcyon" weight="bold">
                <FormattedMessage
                  id={translations.pages.teams.participants.details.evaluations.details.activities.none}
                />
              </Typography>
            </Placeholder>
          )}
          else={() => (
            <div className={style.activities}>
              {activities.map(({ id, name, translations }) => (
                <div key={id} className={style.activityAndDelete}>
                  <div className={style.activity}>
                    {translations && translations[language.toLowerCase()] ? translations[language.toLowerCase()] : name}
                  </div>

                  <Button
                    type="button"
                    onClick={() =>
                      updateParticipantActivities(
                        participantId,
                        sessionId,
                        activities.filter((activity) => activity.id !== id).map(({ id }) => id)
                      ).then(reload)
                    }
                  >
                    <BoxedIcon type="bin" appearance="red" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        />

        <Spacer xs={2} />

        <Modal
          modal={AddActivityModal}
          sessionId={sessionId}
          participantId={participantId}
          activities={activities}
          onActivityAdded={reload}
        >
          {({ open }) => (
            <Typography is="span" type="halcyon" color="orange" className={style.link} onClick={open}>
              <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.activities.add} />
            </Typography>
          )}
        </Modal>
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(withRouter(Activities));
