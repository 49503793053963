import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { DataOptionType, Filter, State } from '@/domains';
import { translations } from '@/locale';

import LoadableSelect, { Props as LoadableSelectProps } from '../LoadableSelect';
import { getDataOptions } from '@/services/api/data-option';

interface Props extends Omit<LoadableSelectProps, 'source'> {
  showOnlyActive: boolean;
}

const SessionTypeSelect: React.FC<Props & WrappedComponentProps> = ({ intl, showOnlyActive, ...props }) => {
  return (
    <LoadableSelect
      source={() =>
        getDataOptions(DataOptionType.SessionType).then((response) => [
          { id: Filter.All as string, name: intl.formatMessage({ id: translations.miscellaneous.all }) },
          ...response
            .filter((sessionTypeItem) => {
              return showOnlyActive === true ? sessionTypeItem.state === State.Enabled : true;
            })
            .map((sessionTypeItem) => ({
              id: sessionTypeItem.id,
              name: sessionTypeItem.name
            }))
        ])
      }
      {...props}
    />
  );
};

export default injectIntl(SessionTypeSelect);
