import React from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { ActivityRowItem } from '@/domains';
import { removeActivityFromSession } from '@/services/api/street-session-activities';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import TextWithIcon from '@/components/TextWithIcon';
import PictureWithData from '@/components/PictureWithData';
import ProfilePictureList from '@/components/ProfilePictureList';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';
import AddParticipantsToActivityModal from '@/components/SearchableModals/AddParticipantsToActivityModal';

import cellStyle from '../cellStyle.sass';

import style from './ActivityRow.sass';

export interface Props {
  sessionId: string;
  data: ActivityRowItem;
  onDelete: () => void;
}

const ActivityRow: React.FC<Props & WrappedComponentProps> = ({ sessionId, onDelete, data, intl, ...rest }) => (
  <Card.Row key={data.id} is="li" className={style.container} {...rest}>
    <Modal
      modal={AddParticipantsToActivityModal}
      onSubmit={onDelete}
      activity={data}
      sessionId={sessionId}
      participantId={data.id}
    >
      {({ open }) => (
        <div className={style.root} onClick={open}>
          <div className={classnames(cellStyle.activity, style.rowFlex)}>
            <PictureWithData size="md" url={data.iconDto ? data.iconDto.url : null} blueText={data.name} />
          </div>

          <ProfilePictureList
            className={cellStyle.participants}
            items={map(data.participants, ({ imageUrl }) => imageUrl)}
            messageId={translations.pages.sessions.details.tabs.participants.row}
          />
        </div>
      )}
    </Modal>

    <Modal
      modal={CrudConfirmationModal}
      message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
      onConfirm={() => removeActivityFromSession(sessionId, data.id).then(onDelete)}
    >
      {({ open }) => (
        <Button onClick={open}>
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird" className={style.deleteLocation}>
            <FormattedMessage id={translations.inputs.remove} />
          </TextWithIcon>
        </Button>
      )}
    </Modal>
  </Card.Row>
);

export default injectIntl(ActivityRow);
