import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';
import Typography from '@/components/Typography';

const DaytimeOccupationHeader: React.FC<{}> = () => {
  const {
    name,
    atStart,
    atEnd
  } = translations.pages.teams.participants.details.reports.skills.daytimeOccupationReports;
  return (
    <Card.Row className={cellStyle.container}>
      <Typography is="span" type="halcyon" color="gray" weight="bold" className={cellStyle.daytimeoOcupation}>
        <FormattedMessage id={name} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" weight="bold" className={cellStyle.atStart}>
        <FormattedMessage id={atStart} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" weight="bold" className={cellStyle.atEnd}>
        <FormattedMessage id={atEnd} />
      </Typography>
    </Card.Row>
  );
};

export default DaytimeOccupationHeader;
