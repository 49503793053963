import React, { PureComponent } from 'react';
import * as yup from 'yup';
import { addHours } from 'date-fns';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { QuestionType, SurveyType } from '@/domains';
import { createSurvey } from '@/services/api/survey';
import { getStreetSessionDetails } from '@/services/api/street-session';

import If from '@/components/If';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import Breadcrumb from '@/components/Breadcrumb';
import TextWithIcon from '@/components/TextWithIcon';
import { AppConsumer } from '@/components/Context/App';
import HourSelect from '@/components/Selects/HourSelect';
import { withValidation } from '@/components/hoc/withValidation';
import QuestionTypeSelect from '@/components/Selects/QuestionTypeSelect';

import style from './SessionFeedback.sass';

interface Params {
  id: string;
}

export interface Props {
  initialValues?: any;
}

const schema = yup.object().shape({
  time: yup.number().required(),
  title: yup.string().required(),
  questions: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        type: yup.string().required(),
        title: yup
          .string()
          .label(translations.pages.digitalCoach.survey.question.label)
          .required(),
        options: yup.array().when(['type'], (type) => {
          return type === QuestionType.TextInput
            ? yup.array().notRequired()
            : yup
                .array()
                .required()
                .min(2)
                .of(yup.string().required());
        })
      })
    )
    .required()
});

const TextInputWithValidation = withValidation(TextInput);
const HourSelectWithValidation = withValidation(HourSelect);

class SessionFeedback extends PureComponent<Props & RouteComponentProps<Params> & WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <AppConsumer>
        {({ team }) => (
          <LoadData load={() => getStreetSessionDetails(this.props.match.params.id)}>
            {({ value: session, loading }) =>
              !loading && (
                <Card className={style.card}>
                  <Card.Row className={style.padded}>
                    <Breadcrumb
                      shape={{
                        [intl.formatMessage({ id: translations.pages.sessions.details.title })]: urls.sessions.list,
                        [`${session.sessionType.name}, ${session.location.name}, ${intl.formatDate(
                          new Date(session.start),
                          {
                            month: 'long',
                            day: '2-digit',
                            year: 'numeric'
                          }
                        )}`]: urls.sessions.get(this.props.match.params.id),
                        [intl.formatMessage({
                          id: translations.pages.digitalCoach.tabs.surveys.sessionFeedback.title
                        })]: null
                      }}
                    />
                  </Card.Row>

                  <Card.Row className={style.column}>
                    <Form
                      id="survey"
                      schema={schema}
                      subscription={{
                        values: true,
                        dirty: true,
                        pristine: true,
                        submitting: true,
                        submitError: true,
                        valid: true
                      }}
                      onSubmit={(data) =>
                        createSurvey({
                          start: session.end,
                          end: addHours(new Date(session.end), data.time).toISOString(),
                          title: data.title,
                          questions: data.questions,
                          description: intl.formatMessage({ id: translations.surveyType.SESSION_FEEDBACK }),
                          type: SurveyType.SessionFeedback,
                          teamId: team.id,
                          sessionId: session.id
                        }).then((response) =>
                          this.props.history.push(urls.sessions.feedback.getFeedbackUrl(response.id))
                        )
                      }
                      mutators={{ ...arrayMutators }}
                    >
                      {(
                        {
                          form: {
                            mutators: { push }
                          },
                          form,
                          submitting,
                          submitError,
                          values,
                          valid,
                          dirty
                        },
                        id
                      ) => (
                        <React.Fragment>
                          <Card.Row padded className={style.column}>
                            <Typography is="span" type="hummingbird" color="gray">
                              <FormattedMessage
                                id={translations.pages.digitalCoach.tabs.surveys.sessionFeedback.time}
                              />
                            </Typography>

                            <Spacer xs={2} />

                            <div className={style.hourSelect}>
                              <Form.Field
                                is={HourSelectWithValidation}
                                type="text"
                                id="time"
                                name="time"
                                label={intl.formatMessage({ id: translations.inputs.hourSelect.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.hourSelect.placeholder })}
                                readOnly={submitting}
                              />
                            </div>

                            <Spacer xs={3} />

                            <Form.Field
                              is={TextInputWithValidation}
                              type="text"
                              id={`${id}-title`}
                              name="title"
                              label={intl.formatMessage({ id: translations.inputs.surveyTitle.label })}
                              placeholder={intl.formatMessage({ id: translations.inputs.surveyTitle.placeholder })}
                              readOnly={submitting}
                              className={style.field}
                            />
                          </Card.Row>

                          <div className={style.padded}>
                            <FieldArray name="questions">
                              {({ fields }) =>
                                fields.map((name, questionNumber) => (
                                  <div className={style.column} key={questionNumber}>
                                    <Typography is="span" type="halcyon" weight="bold">
                                      <FormattedMessage
                                        id={translations.pages.digitalCoach.survey.question.number}
                                        values={{ number: questionNumber + 1 }}
                                      />
                                    </Typography>

                                    <Spacer xs={3} />

                                    <div className={classnames(style.row)}>
                                      <Form.Field
                                        is={TextInputWithValidation}
                                        type="text"
                                        id={`${questionNumber}-title`}
                                        name={`${name}title`}
                                        label={intl.formatMessage({
                                          id: translations.pages.digitalCoach.survey.question.label
                                        })}
                                        placeholder=""
                                        readOnly={submitting}
                                        className={style.field}
                                      />

                                      <div className={style.spacer} />

                                      <Form.Field
                                        is={QuestionTypeSelect}
                                        type="text"
                                        id={`${questionNumber}-type`}
                                        name={`${name}type`}
                                        label={intl.formatMessage({
                                          id: translations.pages.digitalCoach.survey.question.type
                                        })}
                                        readOnly={submitting}
                                        className={style.questionTypeSelect}
                                      />

                                      <div className={style.spacer} />

                                      <Button is="button" type="button" onClick={() => fields.remove(questionNumber)}>
                                        <TextWithIcon icon="bin" boxed color="red" is="span" type="hummingbird">
                                          <FormattedMessage
                                            id={translations.pages.digitalCoach.survey.removeQuestion}
                                          />
                                        </TextWithIcon>
                                      </Button>
                                    </div>

                                    <If
                                      condition={fields.value[questionNumber].type !== QuestionType.TextInput}
                                      then={() => (
                                        <React.Fragment>
                                          <Spacer xs={2} />

                                          <FieldArray name={`${name}options`}>
                                            {({ fields }) =>
                                              fields.map((name, index) => (
                                                <div className={style.column} key={index}>
                                                  <Form.Field
                                                    is={TextInputWithValidation}
                                                    type="text"
                                                    id={`${questionNumber}${index}-option`}
                                                    name={`${name}`}
                                                    label={intl.formatMessage(
                                                      {
                                                        id: translations.pages.digitalCoach.survey.question.answerOption
                                                      },
                                                      { number: index + 1 }
                                                    )}
                                                    placeholder=""
                                                    readOnly={submitting}
                                                    className={style.field}
                                                  />
                                                  <Spacer xs={2} />
                                                </div>
                                              ))
                                            }
                                          </FieldArray>

                                          <Spacer xs={1} />

                                          <Button
                                            is="button"
                                            type="button"
                                            className={style.addOption}
                                            onClick={() => push(`${name}options`, '')}
                                          >
                                            <TextWithIcon
                                              icon="plus"
                                              boxed
                                              iconSize="small"
                                              iconColor="orangeHollow"
                                              color="orange"
                                              is="span"
                                              type="hummingbird"
                                            >
                                              <FormattedMessage id={translations.pages.digitalCoach.survey.addOption} />
                                            </TextWithIcon>
                                          </Button>
                                        </React.Fragment>
                                      )}
                                      else={() => (fields.value[questionNumber].options = undefined)}
                                    />
                                    <Spacer xs={5} />
                                  </div>
                                ))
                              }
                            </FieldArray>

                            <Button
                              is="button"
                              type="button"
                              appearance="ghost"
                              onClick={() => push('questions', { type: QuestionType.MultiSelect })}
                            >
                              <FormattedMessage id={translations.pages.digitalCoach.survey.addQuestion} />
                            </Button>

                            <Spacer xs={7} />

                            <Spacer xs={7} />

                            <div className={classnames(style.padded, style.row, style.buttons)}>
                              <Button
                                fat
                                appearance="ghost"
                                type="button"
                                className={style.button}
                                onClick={() => this.props.history.push(urls.sessions.get(this.props.match.params.id))}
                              >
                                <FormattedMessage id={translations.buttons.cancel} />
                              </Button>

                              <Button fat appearance="orange" type="submit" className={style.button} disabled={!valid}>
                                <FormattedMessage id={translations.buttons.save} />
                              </Button>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Form>
                  </Card.Row>
                </Card>
              )
            }
          </LoadData>
        )}
      </AppConsumer>
    );
  }
}

export default injectIntl(withRouter(SessionFeedback));
