import React, { useRef } from 'react';
import { ChartComponentProps } from 'react-chartjs-2';
import classnames from 'classnames';

import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';

import DownloadDropdown from './DownloadDropdown';
import DownloadButton from './DownloadButton';

import style from './DownloadableChart.sass';

export interface Props {
  chart: React.ComponentType<ChartComponentProps>;
  children(props: RenderProps): React.ReactNode;
  title?: string;
  className?: string;
  data: ChartComponentProps['data'];
}

export interface RenderProps {
  getImage: (fileName?: string) => void;
}

const DownloadableChart: React.FC<Props & ChartComponentProps> = ({
  chart: Component,
  children,
  title,
  className,
  data,
  ...rest
}) => {
  const canvas = useRef(null);

  const clonedData = React.useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  const getImage = (fileName = 'chart') => {
    const link = document.createElement('a');
    link.download = `${fileName}-${new Date().toISOString()}.png`;
    link.href = canvas.current.chartInstance.toBase64Image();
    link.click();
  };

  return (
    <div className={classnames(style.wrapper, className)}>
      {title && (
        <React.Fragment>
          <Typography is="h6" type="halcyon" color="gray">
            {title}
          </Typography>

          <Spacer xs={1} />
        </React.Fragment>
      )}

      <Card className={style.root}>
        {/*
      // @ts-ignore */}
        <Component data={clonedData} {...rest} ref={canvas} />
        <DownloadDropdown className={style.dropdown}>{children({ getImage })}</DownloadDropdown>
      </Card>
    </div>
  );
};

export default Object.assign(DownloadableChart, { DownloadButton });
