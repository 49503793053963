import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { Module, ParticipantRole } from '@/constants';
import { updateRoleAndModules } from '@/services/api/participant-digital-coach';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import { Type } from '@/components/Icon';
import Checkbox from '@/components/Checkbox';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { InstanceProps } from '@/components/Modal';
import TextWithIcon from '@/components/TextWithIcon';
import { withValidation } from '@/components/hoc/withValidation';
import ParticipantRoleSelect from '@/components/Selects/ParticipantRoleSelect';

import style from './ModuleSelectModal.sass';

const schema: yup.ObjectSchema = yup.object({
  roleLevel: yup
    .string()
    .label(translations.inputs.role.label)
    .required(),
  modulesAccess: yup
    .array()
    .of(yup.string())
    .min(1)
    .default([])
});

const icons: Record<Module, Type> = {
  [Module.Mood]: 'smile',
  [Module.Skills]: 'eye',
  [Module.Goals]: 'flag',
  [Module.SocialMap]: 'map'
};

const ParticipantRoleSelectWithValidation = withValidation(ParticipantRoleSelect);

interface Props {
  data: { modulesAccess: Module[]; roleLevel: ParticipantRole };
  teamId: string;
  participantId: string;
  onSubmit?: () => void;
}

class ModuleSelectModal extends React.PureComponent<Props & InstanceProps & WrappedComponentProps> {
  renderModules(submitting: boolean, id: string) {
    return Object.values(Module).map((module) => (
      <React.Fragment key={module}>
        <Form.Field
          is={Checkbox}
          id={`${id}-${module}`}
          name="modulesAccess"
          appearance="circle"
          type="checkbox"
          align="right"
          value={module}
          readOnly={submitting}
        >
          <TextWithIcon is="span" icon={icons[module]} iconColor="blue" boxed iconSize="small" type="halcyon">
            <FormattedMessage id={translations.modules[module]} />
          </TextWithIcon>
        </Form.Field>
        <Spacer xs={2} />
      </React.Fragment>
    ));
  }

  render() {
    const { data, teamId, participantId, onSubmit, intl, close } = this.props;

    return (
      <Card className={style.root}>
        <Card.Row>
          <Card.Column sm={12}>
            <div className={style.titleAndClose}>
              <Typography is="span" type="swan" weight="bold">
                <FormattedMessage id={translations.modals.moduleSelectModal.title} />
              </Typography>

              <Button onClick={this.props.close}>
                <BoxedIcon type="close" appearance="red" />
              </Button>
            </div>
          </Card.Column>
        </Card.Row>

        <Card.Row>
          <div className={style.paddedContainer}></div>
          <Form
            id="edit-role"
            schema={schema}
            initialValues={data}
            subscription={{
              dirty: true,
              pristine: true,
              submitError: true,
              valid: true,
              submitting: true,
              values: true
            }}
            onSubmit={(values: { modulesAccess: Module[]; roleLevel: ParticipantRole }) =>
              updateRoleAndModules(teamId, participantId, values)
                .then(onSubmit)
                .then(close)
            }
            className={style.form}
          >
            {({ submitError, submitting, valid }, id) => (
              <React.Fragment>
                <Spacer xs={3} />

                <div className={style.paddedContainer}>
                  <Form.Field
                    is={ParticipantRoleSelectWithValidation}
                    id={`${id}-role`}
                    name="roleLevel"
                    label={intl.formatMessage({ id: translations.inputs.participantRole.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.participantRole.placeholder })}
                    readOnly={submitting}
                    className={style.roleSelect}
                  />
                </div>

                <Spacer xs={3} />

                <div className={classnames(style.column, style.paddedContainer)}>
                  <Typography is="span" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.inviteParticipantModal.modulesSelect} />
                  </Typography>

                  <Spacer xs={3} />

                  {this.renderModules(submitting, id)}
                </div>

                <Spacer xs={2} />

                <Card.Row>
                  {!!submitError && <SubmitError error={submitError} className={style.submitError} />}

                  <Card.Column sm={12}>
                    <Button
                      type="submit"
                      className={style.sendButton}
                      loading={submitting}
                      appearance="orange"
                      disabled={!valid}
                    >
                      <FormattedMessage id={translations.buttons.save} />
                    </Button>
                  </Card.Column>
                </Card.Row>
              </React.Fragment>
            )}
          </Form>
        </Card.Row>
      </Card>
    );
  }
}
export default injectIntl(ModuleSelectModal);
