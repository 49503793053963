import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Role } from '@/constants';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';

export interface Props extends Omit<SelectProps<Role>, 'items'>, WrappedComponentProps {}

class RoleSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(Role)
      .filter((role) => role !== Role.SuperAdmin)
      .map((role) => ({
        value: role,
        name: intl.formatMessage({ id: translations.roles[role] })
      }));

    return <Select {...rest} items={items} />;
  }
}

export default injectIntl(RoleSelect);
