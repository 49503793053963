import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { Sort, By } from '@/domains';
import { getOrganizationsWithPaymentStatus } from '@/services/api/super-admin';
import { getExportOrgContactInfoRedirectUrl } from '@/services/api/export-url';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import ExportData from '@/components/ExportData';
import FilterManager from '@/components/FilterManager';
import AccountStatusSelect from '@/components/Selects/AccountStatusSelect';
import CreateOrganizationModal from '@/components/Modals/CreateOrganizationModal';

import OrganizationHeader from './OrganizationHeader';
import OrganizationRowItem from './OrganizationRowItem';

import style from './List.sass';

const List: React.FC<WrappedComponentProps> = ({ intl }) => (
  <Card className={style.root}>
    <div className={style.rowFlex}>
      <Typography is="span" type="swan" weight="bold">
        <FormattedMessage id={translations.pages.organizations.list.title} />
      </Typography>

      <ExportData redirect={(token) => getExportOrgContactInfoRedirectUrl(token)}>
        {({ download }) => (
          <Button type="button" appearance="orange" className={style.button} onClick={download}>
            <FormattedMessage id={translations.pages.download.downloadAllOrganizationsContactInfo} />
          </Button>
        )}
      </ExportData>
    </div>

    <FilterManager initialCriteria={{ paymentStatus: 'all', accountStatus: 'enabled' }}>
      {({ criteria, debouncedUpdate, updateCriteria }) => (
        <Refresh>
          {({ key, refresh }) => (
            <React.Fragment>
              <div className={style.filtersContainer}>
                <div className={style.rowFlex}>
                  <TextInput
                    type="text"
                    id="searchText"
                    onChange={(event) => debouncedUpdate('searchText', event.target.value)}
                    label={intl.formatMessage({ id: translations.inputs.participantSearch.label })}
                    placeholder={intl.formatMessage({
                      id: translations.inputs.participantSearch.placeholder
                    })}
                    autoComplete="off"
                    leadingIcon="search"
                    className={style.searchBar}
                  />

                  <Modal modal={CreateOrganizationModal} onOrganizationCreated={refresh}>
                    {({ open }) => (
                      <Button appearance="orange" fat onClick={open}>
                        <FormattedMessage id={translations.inputs.createNewOrg} />
                      </Button>
                    )}
                  </Modal>
                </div>

                <Spacer xs={3} />

                <div className={style.accountStatusFilter}>
                  <Typography is="div" type="halcyon" color="gray">
                    <FormattedMessage id={translations.pages.organizations.list.accountStatusLabel} />
                  </Typography>

                  <AccountStatusSelect
                    className={style.filter}
                    value={criteria.accountStatus}
                    onChange={(value) => updateCriteria('accountStatus', value)}
                  />
                </div>
              </div>

              <Table
                key={key}
                id={`${JSON.stringify(criteria)}-${criteria.searchText}`}
                source={(page, number) => {
                  const options = {
                    ...(criteria.searchText ? { name: criteria.searchText } : null),
                    ...(criteria.accountStatus === 'enabled' ? { state: 'ENABLED' } : { state: 'DISABLED' }),
                    [Sort.By]: By.CreatedAt,
                    [Sort.Order]: criteria.sort
                  };

                  return getOrganizationsWithPaymentStatus(page, number, options);
                }}
                renderHeader={() => <OrganizationHeader />}
                renderRow={(data) => <OrganizationRowItem key={data.id} organization={data} />}
              />
            </React.Fragment>
          )}
        </Refresh>
      )}
    </FilterManager>
  </Card>
);

export default injectIntl(List);
