import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const ParticipantHeader: React.FC<{}> = () => {
  const { name, gender, age, birthdate, address, lastIntervention } = translations.pages.teams.participants.list.header;
  return (
    <Card.Row className={cellStyle.container}>
      <div className={cellStyle.name}>
        <FormattedMessage id={name} />
      </div>

      <div className={cellStyle.gender}>
        <FormattedMessage id={gender} />
      </div>

      <div className={cellStyle.age}>
        <FormattedMessage id={age} />
      </div>

      <div className={cellStyle.birthdate}>
        <FormattedMessage id={birthdate} />
      </div>

      <div className={cellStyle.address}>
        <FormattedMessage id={address} />
      </div>

      <div className={cellStyle.lastIntervention}>
        <FormattedMessage id={lastIntervention} />
      </div>
    </Card.Row>
  );
};

export default ParticipantHeader;
