import React from 'react';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';

import style from './Ordering.sass';

export enum Order {
  Ascendent = 'ASC',
  Descendent = 'DESC'
}

export interface Props extends WrappedComponentProps {
  message: string;
  value: Order;
  className?: string;
  onClick: () => any;
}

class Ordering extends React.PureComponent<Props> {
  render() {
    const { intl, message, className } = this.props;

    return (
      <TextWithIcon
        is="div"
        type="hummingbird"
        color="orange"
        boxed
        icon={this.props.value === Order.Ascendent ? 'arrowUp' : 'arrowDown'}
        onClick={this.props.onClick}
        className={classnames(style.root, className)}
      >
        <FormattedMessage
          id={message}
          values={{
            ordering: intl.formatMessage({
              id: translations.ordering[this.props.value === Order.Ascendent ? 'ascendent' : 'descendent']
            }),
            span: (children) => (
              <Typography is="span" type="hummingbird" color="gray">
                {children}
              </Typography>
            )
          }}
        />
      </TextWithIcon>
    );
  }
}

export default injectIntl(Ordering);
