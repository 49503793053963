import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { LocationState } from '@/domains';
import { translations } from '@/locale';
import { updateLocationState } from '@/services/api/location';

import Modal from '@/components/Modal';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';
import ToggleSwitch from '@/components/ToggleSwitch';

interface Props {
  id: string;
  initialState: LocationState | null;
  disabled?: boolean;
  changeLocationState: (state: LocationState) => any;
}

const LocationStateHandler: React.FC<Props & WrappedComponentProps> = ({
  intl,
  id,
  initialState,
  disabled = false,
  changeLocationState
}) => {
  const [locationState, setLocationState] = useState(initialState);

  return (
    <Modal
      modal={CrudConfirmationModal}
      message={intl.formatMessage({
        id: translations.modals.confirmationModals[locationState === LocationState.Enabled ? 'inactivate' : 'activate']
      })}
      onConfirm={() => {
        const newLocationState =
          locationState === LocationState.Enabled ? LocationState.Disabled : LocationState.Enabled;
        return updateLocationState(id, newLocationState).then(() => {
          setLocationState(newLocationState);
          changeLocationState(newLocationState);
        });
      }}
    >
      {({ open }) => (
        <ToggleSwitch
          checked={locationState === LocationState.Enabled}
          labelOnRight
          onClick={() => open()}
          disabled={disabled}
        />
      )}
    </Modal>
  );
};

export default injectIntl(LocationStateHandler);
