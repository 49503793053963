import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { MemberBase } from '@/domains';
import { translations } from '@/locale';
import { removeTeamMembersFromSession } from '@/services/api/street-session-teammates';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import TextWithIcon from '@/components/TextWithIcon';
import PictureWithData from '@/components/PictureWithData';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import style from './TeammateRow.sass';

export interface Props {
  sessionId: string;
  data: MemberBase;
  onDelete: () => void;
}

const TeammateRow: React.FC<Props & WrappedComponentProps> = ({
  intl,
  sessionId,
  onDelete,
  data: { id, firstName, lastName, imageUrl, email }
}) => (
  <Card.Row className={style.teamMember}>
    <PictureWithData size="md" url={imageUrl} blueText={`${firstName} ${lastName}`} grayText={email} type="teammate" />
    <Modal
      modal={CrudConfirmationModal}
      message={intl.formatMessage({ id: translations.modals.confirmationModals.remove })}
      onConfirm={() => removeTeamMembersFromSession(sessionId, [{ id: id }]).then(onDelete)}
    >
      {({ open }) => (
        <Button type="button" className={style.remove} onClick={open}>
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
            <FormattedMessage id={translations.inputs.remove} />
          </TextWithIcon>
        </Button>
      )}
    </Modal>
  </Card.Row>
);

export default injectIntl(TeammateRow);
