import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const InvitationHeader: React.FC<{}> = () => {
  const { participant, status } = translations.pages.sessions.details.tabs.invitations.header;
  return (
    <Card.Row className={cellStyle.header}>
      <div className={cellStyle.participant}>
        <FormattedMessage id={participant} />
      </div>

      <div className={cellStyle.status}>
        <FormattedMessage id={status} />
      </div>
    </Card.Row>
  );
};

export default InvitationHeader;
