import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getBillingHistory } from '@/services/api/organization';

import If from '@/components/If';
import Loading from '@/components/Loading';
import StatelessTable from '@/components/StatelessTable';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import CursorBasedInfiniteScroll from '@/components/CursorBasedInfiniteScroll';

import BillingHistoryHeader from './BillingHistoryHeader';
import BillingHistoryRow from './BillingHistoryRow';

import style from './BillingHistoryTable.sass';

interface Props {
  id: string;
  organizationId: string;
  detailed?: boolean;
}

const BillingHistoryTable: React.FC<Props> = ({ detailed, organizationId }) => {
  return (
    <StatelessTable>
      <BillingHistoryHeader />

      <CursorBasedInfiniteScroll
        source={(page, number, lastItem) =>
          getBillingHistory(page, number, lastItem ? lastItem.invoiceId : null, organizationId)
        }
        className={style.list}
      >
        {({ data, loading }) => (
          <If
            condition={!loading && data.length === 0}
            then={() => (
              <Placeholder image="questions" size="lg" className={style.empty}>
                <Typography is="span" type="halcyon" weight="bold">
                  <FormattedMessage id={translations.billingHistoryTable.empty} />
                </Typography>
              </Placeholder>
            )}
            else={() => (
              <ul>
                {data.map((billingHistory) => (
                  <BillingHistoryRow
                    key={billingHistory.invoiceId}
                    billingHistory={billingHistory}
                    detailed={detailed}
                  />
                ))}

                <Loading visible={loading} center className={style.loadingIndicator}>
                  <Loading.Indicator size={20} borderWidth={2} fullCircle color="#BCBDC3" />
                </Loading>
              </ul>
            )}
          />
        )}
      </CursorBasedInfiniteScroll>
    </StatelessTable>
  );
};

export default BillingHistoryTable;
