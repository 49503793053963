import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Evaluation } from '@/domains';
import { translations } from '@/locale';
import { getParticipantEvaluations } from '@/services/api/participant-evaluations';

import { AppConsumer } from '@/components/Context/App';
import Placeholder from '@/components/Placeholder';
import Typography from '@/components/Typography';
import Card from '@/components/Card';
import { LanguageContext } from '@/components/Language';
import FilterManager from '@/components/FilterManager';
import TextInput from '@/components/TextInput';
import Spacer from '@/components/Spacer';
import Table from '@/components/Table';
import EvaluationHeader from '@/components/tables/EvaluationsTable/Header';
import EvaluationRow from '@/components/tables/EvaluationsTable/Row';

import style from './Evaluations.sass';

interface Params {
  id: string;
}

const Evaluations: React.FC<RouteComponentProps<Params> & WrappedComponentProps> = ({ intl, match }) => {
  const { id } = match.params;
  const { language } = React.useContext(LanguageContext);

  return (
    <AppConsumer>
      {({ team }) => (
        <Card.Row>
          <Card.Column sm={12}>
            <FilterManager initialCriteria={{}}>
              {({ criteria, debouncedUpdate }) => (
                <React.Fragment>
                  <TextInput
                    type="text"
                    id="search"
                    onChange={(event) => debouncedUpdate('search', event.target.value)}
                    label={intl.formatMessage({ id: translations.inputs.sessionSearch.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.sessionSearch.placeholder })}
                    autoComplete="off"
                    className={style.search}
                    leadingIcon="search"
                  />

                  <Spacer xs={2} />

                  <Table
                    id={criteria.search}
                    key={team.id}
                    source={(page, number) => getParticipantEvaluations(page, number, team.id, id, criteria.search)}
                    empty={() => (
                      <Placeholder image="questions" size="lg" className={style.none}>
                        <Typography is="span" type="halcyon" weight="bold">
                          <FormattedMessage id={translations.pages.teams.participants.details.evaluations.none} />
                        </Typography>
                      </Placeholder>
                    )}
                    renderHeader={() => <EvaluationHeader />}
                    renderRow={(data: Evaluation) => (
                      <EvaluationRow key={data.id} participantId={id} evaluationData={data} language={language} />
                    )}
                  />
                </React.Fragment>
              )}
            </FilterManager>
          </Card.Column>
        </Card.Row>
      )}
    </AppConsumer>
  );
};
export default injectIntl(withRouter(Evaluations));
