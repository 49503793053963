import React from 'react';

import Card from '@/components/Card';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';
import { InstanceProps } from '@/components/Modal';

import Header from './Header';
import Content from './Content';

import style from './SearchableModal.sass';

interface Props extends Omit<InstanceProps, 'className' | 'close'> {
  close: () => void;
}

class SearchableModal extends React.PureComponent<Props> {
  static Header = Header;
  static Content = Content;

  render() {
    const { children } = this.props;

    return (
      <Card className={style.root}>
        {children}
        <Button className={style.closeButton} type="button" onClick={this.props.close}>
          <BoxedIcon type="close" appearance="red" />
        </Button>
      </Card>
    );
  }
}
export default SearchableModal;
