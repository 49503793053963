import React from 'react';

import { InstanceProps } from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import BoxedIcon from '@/components/BoxedIcon';

import style from './ModalHeader.sass';

export interface Props extends Pick<InstanceProps, 'close'> {}

const ModalHeader: React.FC<Props> = ({ close, children }) => (
  <div className={style.root}>
    <Typography is="h6" type="swan" weight="bold">
      {children}
    </Typography>

    <Button onClick={close}>
      <BoxedIcon type="close" appearance="red" />
    </Button>
  </div>
);

export default ModalHeader;
