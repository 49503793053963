import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedDate } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { urls, ParticipantRole } from '@/constants';
import { InvitationToAppStatus, ParticipantAccountOverview } from '@/domains';
import {
  cancelParticipantInvitation,
  deleteParticipantAppUserFromTeam
} from '@/services/api/participant-digital-coach';

import If from '@/components/If';
import Card from '@/components/Card';
import Link from '@/components/Link';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import TextWithIcon from '@/components/TextWithIcon';
import PictureWithData from '@/components/PictureWithData';

import cellStyle from '../cellStyle.sass';
import style from './ParticipantRow.sass';

export interface Props {
  teamId: string;
  data: ParticipantAccountOverview;
  onDelete: () => any;
}

const roleToDescriptionMap = {
  [ParticipantRole.Starter]: translations.participantRoles.NO_ACCESS.description,
  [ParticipantRole.Advanced]: translations.participantRoles.WRITE_ACCESS.description,
  [ParticipantRole.Pro]: translations.participantRoles.FULL_ACCESS.description
};

const ParticipantRow: React.FC<Props & WrappedComponentProps> = ({ teamId, data, onDelete, intl, ...rest }) => (
  <Card.Row is="li" className={style.root} {...rest}>
    <Link to={urls.participants.get(data.participant.id)} className={style.row}>
      <PictureWithData
        size="md"
        url={data.participant.imageUrl ? data.participant.imageUrl : null}
        blueText={data.participant.nickname}
        grayText={
          data.participant.birthDate
            ? intl.formatMessage(
                { id: translations.pages.sessions.details.tabs.participants.content.table.age },
                { age: getAgeFromBirthdate(data.participant.birthDate) }
              )
            : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge })
        }
        className={cellStyle.participant}
      />

      <div className={classnames(cellStyle.role, style.roleContainer)}>
        <Typography is="span" type="halcyon" color="blue">
          <FormattedMessage id={translations.participantRoles[data.roleLevel].name} />
        </Typography>

        <Typography is="span" type="hummingbird" color="gray">
          <FormattedMessage id={roleToDescriptionMap[data.roleLevel]} values={{ br: <br /> }} />
        </Typography>
      </div>

      <Typography is="span" type="halcyon" className={cellStyle.invitationStatus}>
        {data.status === InvitationToAppStatus.Pending ? (
          'Pending'
        ) : (
          <FormattedDate value={data.createdAt} day="2-digit" month="short" year="numeric" />
        )}
      </Typography>
    </Link>

    <div className={cellStyle.remove}>
      {data.status === InvitationToAppStatus.Accepted && (
        <Button
          onClick={() => deleteParticipantAppUserFromTeam(data.participant.id, teamId).then(onDelete)}
          className={style.negativeAction}
        >
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
            <FormattedMessage id={translations.inputs.remove} />
          </TextWithIcon>
        </Button>
      )}

      {data.status === InvitationToAppStatus.Pending && (
        <Button
          onClick={() => cancelParticipantInvitation(data.invitation.id).then(onDelete)}
          className={style.negativeAction}
        >
          <TextWithIcon color="red" icon="bin" boxed is="span" type="hummingbird">
            {/* <FormattedMessage id={translations.inputs.remove} /> */}
            Cancel
          </TextWithIcon>
        </Button>
      )}
    </div>
  </Card.Row>
);

export default injectIntl(ParticipantRow);
