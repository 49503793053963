import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const StreetSessionHeader: React.FC<{}> = () => {
  const { location, sessionType, timeDate, labels, participants } = translations.pages.teams.sessions.header;
  return (
    <Card.Row className={cellStyle.container}>
      <div className={cellStyle.location}>
        <FormattedMessage id={location} />
      </div>
      <div className={cellStyle.sessionType}>
        <FormattedMessage id={sessionType} />
      </div>
      <div className={cellStyle.timeDate}>
        <FormattedMessage id={timeDate} />
      </div>
      <div className={cellStyle.labels}>
        <FormattedMessage id={labels} />
      </div>
      <div className={cellStyle.participants}>
        <FormattedMessage id={participants} />
      </div>
    </Card.Row>
  );
};

export default StreetSessionHeader;
