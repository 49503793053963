import React from 'react';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { urls, STRING_LONG_MAX_LENGTH } from '@/constants';
import { translations } from '@/locale';
import { sendResetPasswordLink } from '@/services/api/session';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import { SubmitError } from '@/components/Error';
import { withValidation } from '@/components/hoc/withValidation';

import signs from '@/assets/signs.png';

import style from './ResetPasswordLinkExpired.sass';

const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required()
});

export interface Props extends RouteComponentProps {}

class ResetPasswordLinkExpired extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    return (
      <Card className={style.card}>
        <div className={style.topSection}>
          <img src={signs} className={style.image} />

          <Typography is="span" type="swan" weight="bold">
            <FormattedMessage id={translations.pages.resetLinkExpired.title} />
          </Typography>

          <Spacer xs={1} />

          <Typography is="span" type="halcyon" color="gray">
            <FormattedMessage id={translations.pages.resetLinkExpired.subtitle} />
          </Typography>
        </div>

        <Spacer xs={3} />

        <Form
          schema={schema}
          subscription={{ submitError: true, submitting: true }}
          onSubmit={({ email }) =>
            sendResetPasswordLink(email).then(() => this.props.history.push(urls.forgotPassword.sent))
          }
        >
          {({ submitError, submitting }) => (
            <div className={style.form}>
              <div className={style.inputFields}>
                <Form.Field
                  is={TextInputWithValidation}
                  id="email"
                  name="email"
                  type="email"
                  label={this.props.intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={this.props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                  readOnly={submitting}
                  icon="message"
                />
              </div>

              {!!submitError && (
                <React.Fragment>
                  <SubmitError error={submitError} />

                  <Spacer xs={2} />
                </React.Fragment>
              )}

              <Button type="submit" loading={submitting} appearance="orange">
                <FormattedMessage id={translations.navigation.submit} />
              </Button>
            </div>
          )}
        </Form>
      </Card>
    );
  }
}

export default withRouter(injectIntl(ResetPasswordLinkExpired));
