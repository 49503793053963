import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import { AgeRange } from '@/components/AgeRangeFilter';
import Card from '@/components/Card';
import TextInput from '@/components/TextInput';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Form from '@/components/Form';

import style from './AgeRangeFilterDropdown.sass';

const schema: yup.ObjectSchema<AgeRange> = yup.object({
  min: yup
    .number()
    .integer()
    .min(0)
    .max(99)
    .nullable(),
  max: yup
    .number()
    .integer()
    .min(yup.ref('min'))
    .max(99)
    .nullable()
});

export interface Props {
  range: { min: number; max: number };
  close: () => any;
  onChange: (value: AgeRange) => void;
}

class AgeRangeFilterDropdown extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { close, onChange, range, intl } = this.props;

    return (
      <Card className={style.card}>
        <Form initialValues={range} schema={schema} onSubmit={(values) => (onChange(values) as any) || close()}>
          {({ form }) => (
            <React.Fragment>
              <div className={style.row}>
                <Form.Field
                  is={TextInput}
                  id="min"
                  name="min"
                  type="number"
                  label={intl.formatMessage({ id: translations.inputs.ageRange.minAge.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.ageRange.minAge.placeholder })}
                  className={classnames(style.input, style.min)}
                />

                <Form.Field
                  is={TextInput}
                  id="max"
                  name="max"
                  type="number"
                  label={intl.formatMessage({ id: translations.inputs.ageRange.maxAge.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.ageRange.maxAge.placeholder })}
                  className={style.input}
                />
              </div>

              <Spacer xs={2} />

              <div className={style.row}>
                <Button
                  type="button"
                  appearance="ghost"
                  onClick={() => {
                    form.reset();
                    onChange({ min: null, max: null });
                    close();
                  }}
                  className={style.button}
                >
                  <FormattedMessage id={translations.inputs.ageRange.clear} />
                </Button>

                <Button type="submit" appearance="orange" className={style.button}>
                  <FormattedMessage id={translations.inputs.ageRange.save} />
                </Button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </Card>
    );
  }
}

export default injectIntl(AgeRangeFilterDropdown);
