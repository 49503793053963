import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getISOString, getNow } from '@/util';
import { createSelfEvaluation } from '@/services/api/self-evaluations';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TimeInput from '@/components/TimeInput';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import { dateFormat } from '@/components/DateInput';
import { AppContext } from '@/components/Context/App';
import Modal, { InstanceProps } from '@/components/Modal';
import DatePickerInput from '@/components/DatePickerInput';
import { withValidation } from '@/components/hoc/withValidation';
import InviteParticipantsToSelfEvaluationModal from '@/components/SearchableModals/InviteParticipantsToSelfEvaluationModal';

import style from './CreateSelfEvaluationModal.sass';

const schema = yup.object({
  type: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup
    .string()
    .required()
    .test('minValue', translations.validation.custom.dateInterval, function(value) {
      const startDate: string = this.resolve(yup.ref('startDate'));
      const startTime: string = this.resolve(yup.ref('startTime'));
      const endTime: string = this.resolve(yup.ref('endTime'));
      const endDate: string = this.resolve(yup.ref('endDate'));

      if (!startDate) return true;
      if (!startTime) return true;
      if (!endTime) return true;

      return startDate === endDate ? true : startDate.localeCompare(value) !== 1;
    }),
  startTime: yup
    .string()
    .label(translations.inputs.startTime.label)
    .required(),
  endTime: yup
    .string()
    .test('minValue', translations.validation.custom.timeInterval, function(value) {
      const startTime: string = this.resolve(yup.ref('startTime'));
      const startDate: string = this.resolve(yup.ref('startDate'));
      const endDate: string = this.resolve(yup.ref('endDate'));

      if (!startTime) return true;

      return startDate === endDate ? startTime.localeCompare(value) === -1 : true;
    })
    .label(translations.inputs.endTime.label)
    .required()
});

interface Props extends InstanceProps {
  teamId: string;
  onSubmit?: () => any;
}

const TextInputWithValidation = withValidation(TextInput);
const TimeInputWithValidation = withValidation(TimeInput);
const DatePickerInputWithValidation = withValidation(DatePickerInput);

const CreateSelfEvaluationModal: React.FC<Props> = ({ teamId, onSubmit, close }) => {
  const [sessionId, setSessionId] = React.useState('');

  const intl = useIntl();

  const { team } = React.useContext(AppContext);

  const createNewSelfEvaluation = (values, open) => {
    const { startDate, startTime, endDate, endTime } = values;
    const start = getISOString(`${startDate} ${startTime}`, `${dateFormat} HH:mm`);
    const end = getISOString(`${endDate} ${endTime}`, `${dateFormat} HH:mm`);
    return createSelfEvaluation(start, end, teamId)
      .then((response) => {
        setSessionId(response.id);
      })
      .then(open);
  };

  return (
    <Modal
      modal={InviteParticipantsToSelfEvaluationModal}
      sessionId={sessionId}
      teamId={team.id}
      onSubmit={() => {
        onSubmit();
        close();
      }}
    >
      {({ open }) => (
        <Card className={style.root}>
          <Button className={style.closeButton} onClick={() => close()}>
            <BoxedIcon type="close" appearance="red" />
          </Button>

          <Form
            id="new-selfEvaluation"
            schema={schema}
            initialValues={{ type: 'Self-Evaluation', startDate: getNow('yyyy-MM-dd'), endDate: getNow('yyyy-MM-dd') }}
            subscription={{ errors: true, submitting: true, submitError: true, values: true, valid: true }}
            onSubmit={(values) => createNewSelfEvaluation(values, open)}
            className={style.form}
          >
            {({ submitError, submitting, values, valid, form }, formId) => (
              <React.Fragment>
                <div>
                  <Typography is="span" type="swan" weight="bold">
                    <FormattedMessage id={translations.modals.createSelfEvaluationModal.title} />
                  </Typography>

                  <Spacer xs={4} />

                  <Form.Field
                    is={TextInputWithValidation}
                    id="type"
                    name="type"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.sessionType.label })}
                    readOnly={submitting}
                    disabled={true}
                  />

                  <Spacer xs={3} />

                  <Typography is="span" type="halcyon" color="gray">
                    <FormattedMessage id={translations.modals.createSelfEvaluationModal.dateSection} />
                  </Typography>

                  <Spacer xs={2} />

                  <div className={style.row}>
                    <Form.Field
                      is={DatePickerInputWithValidation}
                      id={`start`}
                      name="startDate"
                      type="text"
                      change={form.change}
                      readOnly={submitting}
                      className={style.startDate}
                    />

                    <Form.Field
                      is={TimeInputWithValidation}
                      id={`${formId}-start-time`}
                      name="startTime"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.startTime.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.startTime.placeholder })}
                      readOnly={submitting}
                    />
                  </div>

                  <Spacer xs={1} />

                  <div className={style.row}>
                    <Form.Field
                      is={DatePickerInputWithValidation}
                      id={`end`}
                      name="endDate"
                      type="text"
                      change={form.change}
                      readOnly={submitting}
                      className={style.startDate}
                    />

                    <Form.Field
                      is={TimeInputWithValidation}
                      id={`${formId}-end-time`}
                      name="endTime"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.endTime.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.endTime.placeholder })}
                      readOnly={submitting}
                    />
                  </div>
                  <Spacer xs={1} />

                  {!!submitError && <SubmitError error={submitError} />}
                </div>

                <Button
                  form={formId}
                  loading={submitting}
                  type="submit"
                  appearance="orange"
                  className={style.nextButton}
                  disabled={!valid}
                >
                  <FormattedMessage id={translations.navigation.next} />
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Card>
      )}
    </Modal>
  );
};
export default CreateSelfEvaluationModal;
