import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import {
  DataOptionType,
  State,
  EvaluationStatus,
  DataOption,
  Frequency,
  Mood,
  Contact as ParticipantContact,
  UpdateSocialIndicator
} from '@/domains';
import { getDataOptionsForTeam } from '@/services/api/data-option';
import { evaluateContact } from '@/services/api/participant-evaluations';

import If from '@/components/If';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Contact from '@/components/Contact';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import { AppContext } from '@/components/Context/App';
import MoodSelect from '@/components/Selects/MoodSelect';
import FrequencySelect from '@/components/Selects/FrequencySelect';
import EvaluationStatusSelect from '@/components/Selects/EvaluationStatusSelect';

import style from './EvaluateContact.sass';

const InputsContainer = (props) => (
  <div className={classnames(style.inputsContainer, props.className)}>{props.children}</div>
);

const mapSocialIndicators = (indicators: DataOption[]): UpdateSocialIndicator[] =>
  indicators.map((indicator) => ({
    evaluationStatus: EvaluationStatus.Neutral,
    socialIndicatorId: indicator.id
  }));

interface Props {
  contact: ParticipantContact;
  sessionId: string;
  onContactEvaluated: () => any;
}

const EvaluateContact: React.FC<Props & WrappedComponentProps> = ({ intl, contact, sessionId, onContactEvaluated }) => {
  const { team } = React.useContext(AppContext);

  return (
    <div className={style.root}>
      <Contact
        name={contact.fullName}
        picture={!!contact.type.icon ? contact.type.icon.url : null}
        title={contact.type.name}
        className={style.contact}
      />

      <LoadData load={() => getDataOptionsForTeam(DataOptionType.ContactHelpfulnessIndicator, team.id, State.Enabled)}>
        {({ loading, value: indicators }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loadingIndicator}>
                <Loading.Indicator size={40} borderWidth={2} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Form
                id="evaluate-contact"
                initialValues={{
                  frequency: Frequency.Sometimes,
                  interactionMood: Mood.Cooperative,
                  socialIndicatorEvaluations: mapSocialIndicators(indicators)
                }}
                subscription={{ submitError: true }}
                onSubmit={(values) => evaluateContact(contact.id, sessionId, values).then(onContactEvaluated)}
              >
                {({ submitError }, id) => (
                  <React.Fragment>
                    <InputsContainer>
                      <Form.Field
                        is={FrequencySelect}
                        id={`${id}-frequency`}
                        name="frequency"
                        label={intl.formatMessage({ id: translations.inputs.frequency.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.frequency.placeholder })}
                        appearance="normal"
                      />

                      <Form.Field
                        is={MoodSelect}
                        id={`${id}-interaction-mood`}
                        name="interactionMood"
                        label={intl.formatMessage({ id: translations.inputs.mood.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.mood.placeholder })}
                        appearance="normal"
                      />
                    </InputsContainer>

                    <Spacer xs={3} />

                    <Typography is="h4" type="sparrow" weight="bold">
                      <FormattedMessage id={translations.modals.evaluateContact.evaluate.helpfulness} />
                    </Typography>

                    <Spacer xs={1} />

                    <InputsContainer>
                      {indicators.map((indicator, index) => (
                        <Form.Field
                          key={indicator.id}
                          is={EvaluationStatusSelect}
                          id={`${id}-${indicator.name.replace(/ /g, '-').toLowerCase()}`}
                          name={`socialIndicatorEvaluations[${index}].evaluationStatus`}
                          label={indicator.name}
                          placeholder={indicator.name}
                          appearance="normal"
                        />
                      ))}
                    </InputsContainer>

                    <Spacer xs={3} />

                    {submitError && (
                      <React.Fragment>
                        <SubmitError error={submitError} />

                        <Spacer xs={3} />
                      </React.Fragment>
                    )}

                    <Button form={id} appearance="orange" className={style.button}>
                      <FormattedMessage id={translations.modals.evaluateContact.evaluate.button} />
                    </Button>
                  </React.Fragment>
                )}
              </Form>
            )}
          />
        )}
      </LoadData>
    </div>
  );
};

export default injectIntl(EvaluateContact);
