import { CustomPropertyAccessibility, CustomProperty, State, NewCustomProperty, UpdateCustomProperty } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const updateCustomPropertyState: (id: string, state: State) => Promise<any> = (id, state) =>
  authorizedApi.put(`/v1/custom-properties/${id}/state`, { state });

export const updateCustomProperty: (customProperty: UpdateCustomProperty) => Promise<any> = ({ id, ...rest }) =>
  authorizedApi.put(`/v1/custom-properties/${id}`, { ...rest });

export const createCustomProperty: (customProperty: NewCustomProperty) => Promise<any> = (customProperty) =>
  authorizedApi.post('/v1/custom-properties', customProperty);

export const updateCustomPropertyOptionState: (id: string, optionId: string, state: State) => Promise<any> = (
  id,
  optionId,
  state
) => authorizedApi.put(`/v1/custom-properties/${id}/options/${optionId}/state`, { state });

export const deleteCustomProperty: (id: string) => Promise<any> = (id) =>
  authorizedApi.delete(`/v1/custom-properties/${id}`);

export const getCustomPropertiesForTeamLead: (
  accessibility: CustomPropertyAccessibility,
  teamId: string,
  state?: State
) => Promise<CustomProperty[]> = (accessibility, teamId, state) => {
  return authorizedApi.get<CustomProperty[]>('/v2/custom-properties/teams/team-lead', { accessibility, teamId, state });
};

export const getCustomPropertiesForTeam: (
  accessibility: CustomPropertyAccessibility,
  teamId: string,
  state?: State
) => Promise<CustomProperty[]> = (accessibility, teamId, state) => {
  return authorizedApi.get<CustomProperty[]>('/v3/custom-properties/teams', { accessibility, teamId });
};

export const updateCustomPropertyStateForTeam: (id: string, teamId: string, state: State) => Promise<any> = (
  id,
  teamId,
  state
) => authorizedApi.put(`/v2/custom-properties/${id}/teams/${teamId}`, { state });
