import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import { DataOptionType, AgeGroup } from '@/domains';
import { getAgeGroups } from '@/services/api/age-groups';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import NewAgeGroupModal from '@/components/Modals/NewAgeGroupModal';

import AgeGroupRow from './AgeGroupRow';
import DataOptionHeader from './AgeGroupHeader';

import style from './AgeGroups.sass';

interface Props {
  dataOptionTypes?: DataOptionType[];
}

const AgeGroups: React.FC<Props & RouteComponentProps> = () => {
  const [key, setKey] = React.useState(new Date().getTime());

  const updateKey = React.useCallback(() => setKey(new Date().getTime()), []);

  return (
    <Card.Row className={style.root}>
      <Card.Column sm={12}>
        <div className={style.buttonContainer}>
          <Modal modal={NewAgeGroupModal} onSubmit={updateKey}>
            {({ open }) => (
              <Button appearance="orange" onClick={open}>
                <FormattedMessage id={translations.pages.organization.dataCustomisation.tabs.ageGroups.addNewButton} />
              </Button>
            )}
          </Modal>
        </div>

        <Table
          key={key}
          source={() => getAgeGroups().then((result) => result.sort((a, b) => a.name.localeCompare(b.name)))}
          renderHeader={() => <DataOptionHeader />}
          renderRow={(data: AgeGroup) => <AgeGroupRow key={data.id} ageGroup={data} onModalAction={updateKey} />}
          withoutPagination
        />
      </Card.Column>
    </Card.Row>
  );
};

export default withRouter(AgeGroups);
