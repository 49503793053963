import React from 'react';
import { format, subMonths, addMonths, startOfDay } from 'date-fns';
import classnames from 'classnames';

import Card from '@/components/Card';
import Typography from '@/components/Typography';
import Icon from '@/components/Icon';

import Month from './Month';

import style from './DatePicker.sass';

export interface Props {
  date?: Date;
  selectDate: (date: Date) => any;
  className?: string;
}

export interface State {
  currentDate: Date;
}

class Datepicker extends React.PureComponent<Props, State> {
  initialDate = this.props.date || startOfDay(new Date());

  state: State = {
    currentDate: this.initialDate
  };

  goToPreviousMonth = () => {
    this.setState((state) => ({ ...state, currentDate: subMonths(state.currentDate, 1) }));
  };

  goToNextMonth = () => {
    this.setState((state) => ({ ...state, currentDate: addMonths(state.currentDate, 1) }));
  };

  render() {
    return (
      <Card className={classnames(style.root, this.props.className)}>
        <div className={style.content}>
          <div className={style.header}>
            <Typography is="span" type="sparrow" color="gray" onClick={this.goToPreviousMonth}>
              <Icon type="chevronLeft" />
            </Typography>
            <Typography is="span" type="sparrow" weight="bold">
              {format(this.state.currentDate, 'MMMM yyyy')}
            </Typography>
            <Typography is="span" type="sparrow" color="gray" onClick={this.goToNextMonth}>
              <Icon type="chevronRight" />
            </Typography>
          </div>
          <Month
            year={this.state.currentDate.getFullYear()}
            month={this.state.currentDate.getMonth()}
            selectedDate={this.props.date}
            onSelectDate={this.props.selectDate}
          />
        </div>
      </Card>
    );
  }
}

export default Datepicker;
