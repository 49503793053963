import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { Organization, OrganizationUpdate } from '@/domains';
import { taxIdSchema, phoneNumberSchema, emailSchema } from '@/validation';
import { updateOrganizationDetails, getOrganizationDetails } from '@/services/api/organization';

import If from '@/components/If';
import Form from '@/components/Form';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import TextInput from '@/components/TextInput';
import { SubmitError } from '@/components/Error';
import Typography from '@/components/Typography';
import EntityPreview from '@/components/EntityPreview';
import PictureUpload from '@/components/PictureUpload';
import CountrySelect from '@/components/Selects/CountrySelect';
import InputsContainer from '@/components/InputsContainer';
import { SessionConsumer } from '@/components/Context/Session';
import { withValidation } from '@/components/hoc/withValidation';
import SystemLanguageSelect from '@/components/Selects/SystemLanguageSelect';
import CrudConfirmationModal from '@/components/Modals/CrudConfirmationModal';

import style from './GeneralInformation.sass';

const TextInputWithValidation = withValidation(TextInput);
const SystemLanguageSelectWithValidation = withValidation(SystemLanguageSelect);
const CountrySelectWithValidation = withValidation(CountrySelect);

const organizationUpdateSchema: yup.ObjectSchema<OrganizationUpdate> = yup.object({
  name: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.organizationName.label),
  notificationsLanguageCode: yup
    .string()
    .label(translations.inputs.emailLanguage.label)
    .required(),
  email: emailSchema.label(translations.inputs.email.label).required(),
  phoneNumber: phoneNumberSchema
    .label(translations.inputs.phoneNumber.label)
    .nullable()
    .notRequired(),
  officialCountry: yup
    .string()
    .label(translations.inputs.officialCountry.label)
    .nullable()
    .notRequired(),
  officialAddress: yup
    .string()
    .label(translations.inputs.officialAddress.label)
    .required(),
  taxId: taxIdSchema
    .label(translations.inputs.taxId.label)
    .nullable()
    .notRequired(),
  websiteUrl: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.website.label)
    .nullable()
    .notRequired(),
  logoUrl: yup
    .mixed()
    .label(translations.inputs.picture.organization)
    .nullable()
    .notRequired(),
  ngoUrl: yup
    .mixed()
    .label(translations.inputs.picture.ngo)
    .nullable()
    .notRequired(),
  contactPersonName: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.fullName.label)
    .required(),
  contactPersonEmail: emailSchema.label(translations.inputs.email.label).required(),
  contactPersonPhoneNumber: phoneNumberSchema.label(translations.inputs.phoneNumber.label).required()
});

const mapOrganization: (organization: Organization) => OrganizationUpdate = ({ id, state, ...rest }) => rest;

const GeneralInformation: React.FC<WrappedComponentProps> = ({ intl }) => (
  <SessionConsumer>
    {({ organization, reloadOrganization }) => (
      <div className={style.root}>
        <Spacer xs={2} />

        {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

        <Spacer xs={4} />

        <Card className={style.card}>
          <LoadData id={organization.id} load={getOrganizationDetails}>
            {({ value, loading }) => (
              <If
                condition={loading}
                then={() => (
                  <Loading visible={loading} center className={style.loading}>
                    <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                  </Loading>
                )}
                else={() => (
                  <Form
                    id="general-information"
                    schema={organizationUpdateSchema}
                    initialValues={mapOrganization(value)}
                    subscription={{
                      dirty: true,
                      pristine: true,
                      submitError: true,
                      submitting: true,
                      valid: true
                    }}
                    onSubmit={(values) => updateOrganizationDetails(organization.id, values).then(reloadOrganization)}
                  >
                    {({ form, dirty, valid, pristine, submitError, submitting }, id) => (
                      <React.Fragment>
                        <Card.Row>
                          <Card.Column sm={12}>
                            <Typography is="h6" type="corvus" weight="bold">
                              <FormattedMessage
                                id={translations.pages.organization.generalInformation.editOrganization}
                              />
                            </Typography>

                            <Spacer xs={4} />

                            <Form.Field
                              is={PictureUpload}
                              id={`${id}-logo`}
                              name="logoUrl"
                              title={intl.formatMessage({ id: translations.inputs.picture.organization })}
                              accept="image/x-png,image/jpeg"
                              readOnly={submitting}
                            />
                          </Card.Column>
                        </Card.Row>

                        <Card.Row>
                          <Card.Column sm={12}>
                            <Typography is="h6" type="halcyon" color="gray">
                              <FormattedMessage
                                id={translations.pages.organization.generalInformation.organizationInfo}
                              />
                            </Typography>

                            <Spacer xs={1} />

                            <InputsContainer>
                              <div className={style.organizationName}>
                                <Form.Field
                                  is={TextInputWithValidation}
                                  type="text"
                                  id={`${id}-name`}
                                  name="name"
                                  label={intl.formatMessage({ id: translations.inputs.name.label })}
                                  placeholder={intl.formatMessage({ id: translations.inputs.name.placeholder })}
                                  readOnly={submitting}
                                />
                              </div>

                              <Form.Field
                                is={SystemLanguageSelectWithValidation}
                                type="text"
                                id={`${id}-notifications-language-code`}
                                name="notificationsLanguageCode"
                                label={intl.formatMessage({ id: translations.inputs.emailLanguage.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.emailLanguage.placeholder
                                })}
                                readOnly={submitting}
                                searchable
                              />
                            </InputsContainer>

                            <Spacer xs={2} />

                            <Form.Field
                              is={PictureUpload}
                              id={`${id}-ngo`}
                              name="ngoUrl"
                              title={intl.formatMessage({ id: translations.inputs.picture.ngo })}
                              accept="image/x-png,image/jpeg"
                              readOnly={submitting}
                            />

                            <Spacer xs={3} />

                            <Typography is="h6" type="halcyon" color="gray">
                              <FormattedMessage id={translations.pages.organization.generalInformation.contact} />
                            </Typography>

                            <Spacer xs={1} />

                            <InputsContainer>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-email`}
                                name="email"
                                label={intl.formatMessage({ id: translations.inputs.email.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-phone-number`}
                                name="phoneNumber"
                                label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.phoneNumber.placeholder
                                })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-website-url`}
                                name="websiteUrl"
                                label={intl.formatMessage({ id: translations.inputs.website.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.website.placeholder })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={CountrySelectWithValidation}
                                id={`${id}-official-country`}
                                name="officialCountry"
                                label={intl.formatMessage({ id: translations.inputs.officialCountry.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.officialCountry.placeholder
                                })}
                                searchable
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-official-address`}
                                name="officialAddress"
                                label={intl.formatMessage({ id: translations.inputs.officialAddress.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.officialAddress.placeholder
                                })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="number"
                                id={`${id}-tax-id`}
                                name="taxId"
                                label={intl.formatMessage({ id: translations.inputs.taxId.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.taxId.placeholder })}
                                readOnly={submitting}
                              />
                            </InputsContainer>
                          </Card.Column>
                        </Card.Row>

                        <Card.Row>
                          <Card.Column sm={12}>
                            <Typography is="h6" type="halcyon" weight="bold">
                              <FormattedMessage id={translations.pages.organization.generalInformation.contactPerson} />
                            </Typography>

                            <Spacer xs={1} />

                            <InputsContainer>
                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-contact-person-name`}
                                name="contactPersonName"
                                label={intl.formatMessage({ id: translations.inputs.fullName.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.fullName.placeholder })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-contact-person-email`}
                                name="contactPersonEmail"
                                label={intl.formatMessage({ id: translations.inputs.email.label })}
                                placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                                readOnly={submitting}
                              />

                              <Form.Field
                                is={TextInputWithValidation}
                                type="text"
                                id={`${id}-contact-person-phone-number`}
                                name="contactPersonPhoneNumber"
                                label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                                placeholder={intl.formatMessage({
                                  id: translations.inputs.phoneNumber.placeholder
                                })}
                                readOnly={submitting}
                              />
                            </InputsContainer>
                          </Card.Column>
                        </Card.Row>

                        {!!submitError && (
                          <div className={style.submitError}>
                            <SubmitError error={submitError} />
                          </div>
                        )}

                        <div className={style.buttons}>
                          <Modal
                            modal={CrudConfirmationModal}
                            message={intl.formatMessage({ id: translations.modals.confirmationModals.edit })}
                            onConfirm={() => form.submit()}
                          >
                            {({ open }) => (
                              <Button
                                form={id}
                                type="button"
                                onClick={open}
                                appearance="orange"
                                className={style.saveButton}
                                block
                                loading={submitting}
                                disabled={!dirty || !valid}
                              >
                                <FormattedMessage id={translations.pages.organization.generalInformation.save} />
                              </Button>
                            )}
                          </Modal>
                        </div>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              />
            )}
          </LoadData>
        </Card>
      </div>
    )}
  </SessionConsumer>
);

export default injectIntl(GeneralInformation);
