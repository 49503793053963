import React, { HTMLAttributes, DOMAttributes } from 'react';
import classnames from 'classnames';

import style from './Row.sass';

export interface Props extends DOMAttributes<any> {
  is?: any;
  padded?: boolean;
  className?: string;
}

const Row: React.FC<Props> = ({ is: Component = 'div', padded, className, ...rest }) => (
  <Component {...rest} className={classnames(style.root, { [style.padded]: padded }, className)} />
);

export default Row;
