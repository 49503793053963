import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';

import cellStyle from '../cellStyle.sass';

const EvaluationHeader: React.FC = () => {
  const {
    location,
    sessionType,
    timeDate,
    evaluated
  } = translations.pages.teams.participants.details.evaluations.list.header;
  return (
    <Card.Row className={cellStyle.container}>
      <div className={cellStyle.location}>
        <FormattedMessage id={location} />
      </div>

      <div className={cellStyle.sessionType}>
        <FormattedMessage id={sessionType} />
      </div>

      <div className={cellStyle.timeDate}>
        <FormattedMessage id={timeDate} />
      </div>

      <div className={cellStyle.evaluated}>
        <FormattedMessage id={evaluated} />
      </div>
    </Card.Row>
  );
};

export default EvaluationHeader;
