import { TeamActive } from './team';

export enum LocationState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export interface Location {
  id: string;
  pictureUrl: string;
  name: string;
  description: string;
  address: string;
  state: LocationState;
  teams?: TeamActive[];
}

export interface LocationCreation extends Omit<Location, 'id' | 'state'> {}
