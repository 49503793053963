import React from 'react';
import { formatISO, startOfMonth, endOfMonth } from 'date-fns';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { translations } from '@/locale';
import { TimeFrame, ReportFilters } from '@/domains';

import Card from '@/components/Card';
import FilterManager from '@/components/FilterManager';
import ErrorBoundary from '@/components/ErrorBoundary';
import Typography from '@/components/Typography';
import Spacer from '@/components/Spacer';
import DatePickerInput from '@/components/DatePickerInput';
import TimeFrameSelect from '@/components/Selects/TimeFrameSelect';
import LazyRender from '@/components/LazyRender';

import UsersSection from './UsersAndParticipationSection';
import RegistrationSection from './RegistrationSection';
import ParticipantsSection from './ParticipantsSection';

import style from './Reporting.sass';

const Reporting: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <div className={style.root}>
      <Card className={style.card}>
        <FilterManager
          initialCriteria={{
            start: formatISO(startOfMonth(new Date()), { representation: 'date' }),
            end: formatISO(endOfMonth(new Date()), { representation: 'date' }),
            teamIds: null,
            locationId: null,
            timeFrame: TimeFrame.Monthly
          }}
        >
          {({ criteria, updateCriteria }) => (
            <ErrorBoundary>
              <Card.Row>
                <Card.Column sm={12}>
                  <Typography is="div" type="corvus" weight="bold">
                    <FormattedMessage id={translations.pages.main.reporting.title} />
                  </Typography>

                  <Spacer xs={5} />

                  <div className={style.filters}>
                    <div className={style.withLabel}>
                      <label htmlFor="start" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.reports.filters.from} />
                      </label>

                      <DatePickerInput
                        id="start"
                        value={criteria.start}
                        change={(_, value) => updateCriteria('start', value)}
                        inputClassName={style.datePicker}
                      />
                    </div>

                    <div className={style.withLabel}>
                      <label htmlFor="end" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.reports.filters.until} />
                      </label>

                      <DatePickerInput
                        id="end"
                        value={criteria.end}
                        change={(_, value) => updateCriteria('end', value)}
                        inputClassName={style.datePicker}
                      />
                    </div>

                    <div className={style.withLabel}>
                      <label htmlFor="time-frame" className={style.label}>
                        <FormattedMessage id={translations.pages.teams.participants.details.reports.filters.show} />
                      </label>

                      <TimeFrameSelect
                        id="time-frame"
                        value={criteria.timeFrame}
                        onChange={(value) => updateCriteria('timeFrame', value)}
                      />
                    </div>
                  </div>
                </Card.Column>
              </Card.Row>

              <Card.Row>
                <Card.Column sm={12}>
                  <UsersSection
                    key={JSON.stringify({ ...criteria, type: 'users-and-participation' })}
                    intl={intl}
                    criteria={criteria as ReportFilters}
                  />

                  <Spacer xs={4} />

                  <LazyRender height={909}>
                    <RegistrationSection
                      key={JSON.stringify({ ...criteria, type: 'registration' })}
                      intl={intl}
                      criteria={criteria as ReportFilters}
                    />
                  </LazyRender>

                  <Spacer xs={4} />

                  <LazyRender height={606}>
                    <ParticipantsSection
                      key={JSON.stringify({ ...criteria, type: 'participants' })}
                      intl={intl}
                      criteria={criteria as ReportFilters}
                    />
                  </LazyRender>
                </Card.Column>
              </Card.Row>
            </ErrorBoundary>
          )}
        </FilterManager>
      </Card>
    </div>
  );
};

export default injectIntl(Reporting);
