import React from 'react';
import classnames from 'classnames';

import Typography from '@/components/Typography';
import Picture, { Props as PictureProps } from '@/components/Picture/Picture';

import style from './PictureWithData.sass';

export interface Props extends PictureProps {
  blueText?: string;
  grayText?: string;
  className?: string;
}

export default class PictureWithData extends React.PureComponent<Props> {
  render() {
    const { size, url, blueText, grayText, className, type, ...rest } = this.props;
    return (
      <div className={classnames(style.root, className)}>
        <Picture size={size} url={url} type={type} />
        <div className={style.textContainer}>
          {blueText && (
            <Typography is="span" type="halcyon" color="blue" weight="bold">
              {blueText}
            </Typography>
          )}
          {grayText && (
            <Typography is="span" type="hummingbird" color="gray">
              {grayText}
            </Typography>
          )}
        </div>
      </div>
    );
  }
}
