import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import DateInput from '@/components/DateInput';

import style from './DateRangeFilterDropdown.sass';

const schema = yup.object({
  start: yup.string().nullable(),
  end: yup.string().nullable()
});

export interface Props {
  range: { start: string; end: string };
  close: () => any;
  onChange: (value: { start: string; end: string }) => void;
}

class DateRangeFilterDropdown extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { close, onChange, range, intl } = this.props;

    return (
      <Card className={style.card}>
        <Form initialValues={range} schema={schema} onSubmit={(values) => (onChange(values) as any) || close()}>
          {({ form }) => (
            <React.Fragment>
              <div className={style.row}>
                <Form.Field
                  is={DateInput}
                  id="start"
                  name="start"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.dateRangeFilter.start.label })}
                  className={classnames(style.input, style.min)}
                />

                <Form.Field
                  is={DateInput}
                  id="end"
                  name="end"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.dateRangeFilter.end.label })}
                  className={style.input}
                />
              </div>

              <Spacer xs={2} />

              <div className={style.row}>
                <Button
                  type="button"
                  appearance="ghost"
                  onClick={() => {
                    form.reset();
                    onChange({ start: null, end: null });
                    close();
                  }}
                  className={style.button}
                >
                  <FormattedMessage id={translations.inputs.ageRange.clear} />
                </Button>

                <Button type="submit" appearance="orange" className={style.button}>
                  <FormattedMessage id={translations.inputs.ageRange.save} />
                </Button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </Card>
    );
  }
}

export default injectIntl(DateRangeFilterDropdown);
