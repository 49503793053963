import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Filter } from '@/domains';
import { translations } from '@/locale';

import LoadableSelect, { Props as LoadableSelectProps } from '../LoadableSelect';
import { AppContext } from '@/components/Context/App';
import { getAgeGroups } from '@/services/api/age-groups';

interface Props extends Omit<LoadableSelectProps, 'source'> {}

const AgeGroupSelect: React.FC<Props & WrappedComponentProps> = ({ intl, ...props }) => {
  const { team } = React.useContext(AppContext);
  return (
    <LoadableSelect
      source={() =>
        getAgeGroups().then((response) => [
          { id: Filter.All as string, name: intl.formatMessage({ id: translations.miscellaneous.all }) },
          ...response.map((ageGroupItem) => ({ id: ageGroupItem.id, name: ageGroupItem.name }))
        ])
      }
      {...props}
    />
  );
};

export default injectIntl(AgeGroupSelect);
