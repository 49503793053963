import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Card from '@/components/Card';
import If from '@/components/If';
import Breadcrumb from '@/components/Breadcrumb';
import Tab from '@/components/TabbedContainer/Tab';
import ErrorBoundary from '@/components/ErrorBoundary';
import TabbedContainer from '@/components/TabbedContainer';

import Details from './Details';
import BillingHistory from './BillingHistory';

import style from './Detail.sass';

const Detail: React.FC<RouteComponentProps> = ({ location }) => {
  const intl = useIntl();

  const tabHeader = (
    <Breadcrumb
      shape={{
        [intl.formatMessage({ id: translations.pages.organizations.detail.title })]: urls.admin.organizations.list,
        [location.state.name]: null
      }}
    />
  );

  return (
    <If
      condition={!location.state}
      then={() => <Redirect to={urls.admin.organizations.list} />}
      else={() => (
        <div className={style.root}>
          <Card className={style.card}>
            <ErrorBoundary>
              <TabbedContainer>
                <Tab
                  contentBeforeTitle={tabHeader}
                  title={<FormattedMessage id={translations.pages.organizations.detail.tabs.details.title} />}
                >
                  <Details />
                </Tab>

                <Tab
                  contentBeforeTitle={tabHeader}
                  title={<FormattedMessage id={translations.pages.organizations.detail.tabs.billingHistory.title} />}
                >
                  <BillingHistory />
                </Tab>
              </TabbedContainer>
            </ErrorBoundary>
          </Card>
        </div>
      )}
    />
  );
};

export default Detail;
