import React from 'react';

import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';

import style from './Header.sass';

export interface Props {
  title: string;
  subtitle: string;
}

class Header extends React.PureComponent<Props> {
  render() {
    const { title, subtitle } = this.props;
    return (
      <div className={style.root}>
        <Typography is="span" type="swan" weight="bold">
          {title}
        </Typography>

        <Spacer xs={1} />

        <Typography is="span" type="halcyon" color="gray">
          {subtitle}
        </Typography>
      </div>
    );
  }
}

export default Header;
