import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import {
  CustomPropertyAccessibility,
  ParticipantProfileField,
  CustomProperty,
  ParticipantLegalSection
} from '@/domains';
import { getCustomPropertiesForTeam } from '@/services/api/custom-property';
import { getParticipantProfileFields, getParticipantLegalSections } from '@/services/api/organization';

import Card from '@/components/Card';
import Table from '@/components/Table';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import Placeholder from '@/components/Placeholder';
import FilterManager from '@/components/FilterManager';
import ProfileFieldSelect from '@/components/Selects/ProfileFieldSelect';
import CustomPropertyModal from '@/components/Modals/CustomPropertyModal';
import { AppContext } from '@/components/Context/App';

import ProfileFieldsHeader from './ProfileFieldsHeader';
import ParticipantProfileFieldRow from './ParticipantProfileFieldRow';
import CustomPropertyRow from './CustomPropertyRow';
import LegalDocumentHeader from './LegalDocumentsHeader';
import ParticipantLegalSectionRow from './ParticipantLegalSectionRow';

import style from './ProfileFields.sass';

const ProfileFields: React.FC<RouteComponentProps & WrappedComponentProps> = () => {
  const intl = useIntl();
  const { team } = React.useContext(AppContext);

  const [key, setKey] = React.useState(new Date().getTime());

  const refresh = React.useCallback(() => setKey(new Date().getTime()), []);

  const compareProfileFields = (a, b) =>
    intl.formatMessage({ id: translations.participantProfileFieldTypes[a.participantField] }).localeCompare(
      intl.formatMessage({
        id: translations.participantProfileFieldTypes[b.participantField]
      })
    );

  const compareLegalFields = (a, b) =>
    intl
      .formatMessage({ id: translations.participantLegalSectionTypes[a.type] })
      .localeCompare(intl.formatMessage({ id: translations.participantLegalSectionTypes[b.type] }));

  return (
    <Card.Row className={style.root}>
      <Card.Column sm={12}>
        <FilterManager initialCriteria={{ profileFieldType: 'defaultProfileFields' }}>
          {({ criteria, updateCriteria }) => (
            <React.Fragment>
              <div className={style.filterAndButton}>
                <div className={style.filterContainer}>
                  <Typography is="div" type="halcyon" className={style.label}>
                    <FormattedMessage id={translations.dataCustomisation.profileFields.show} />
                  </Typography>

                  <ProfileFieldSelect
                    value={criteria.profileFieldType}
                    onChange={(value) => updateCriteria('profileFieldType', value)}
                  />
                </div>

                {Object.values(CustomPropertyAccessibility).includes(criteria.profileFieldType) && (
                  <Modal modal={CustomPropertyModal} accessibility={criteria.profileFieldType} refresh={refresh}>
                    {({ open }) => (
                      <Button appearance="orange" onClick={open}>
                        <FormattedMessage id={translations.dataCustomisation.profileFields.addNewButton} />
                      </Button>
                    )}
                  </Modal>
                )}
              </div>

              <Table
                key={key}
                id={`${JSON.stringify(criteria)}`}
                className={style.table}
                empty={() => (
                  <Placeholder image="questions" size="lg" className={style.empty}>
                    <Typography is="span" type="halcyon" weight="bold">
                      <FormattedMessage id={translations.dataCustomisation.profileFields.empty} />
                    </Typography>
                  </Placeholder>
                )}
                withoutPagination
                source={() =>
                  criteria.profileFieldType === 'defaultProfileFields'
                    ? getParticipantProfileFields().then((result) => result.sort((a, b) => compareProfileFields(a, b)))
                    : criteria.profileFieldType === 'legalInformation'
                    ? getParticipantLegalSections().then((result) => result.sort((a, b) => compareLegalFields(a, b)))
                    : getCustomPropertiesForTeam(criteria.profileFieldType, team.id).then((result) =>
                        result.sort((a, b) => a.name.localeCompare(b.name))
                      )
                }
                renderHeader={() =>
                  criteria.profileFieldType === 'legalInformation' ? (
                    <LegalDocumentHeader />
                  ) : (
                    <ProfileFieldsHeader
                      showFieldType={Object.values(CustomPropertyAccessibility).includes(criteria.profileFieldType)}
                    />
                  )
                }
                renderRow={(data: CustomProperty | ParticipantProfileField | ParticipantLegalSection) =>
                  Object.values(CustomPropertyAccessibility).includes(criteria.profileFieldType) ? (
                    <CustomPropertyRow
                      key={(data as CustomProperty).id}
                      data={data as CustomProperty}
                      refresh={refresh}
                      className={style.customItem}
                    />
                  ) : criteria.profileFieldType === 'defaultProfileFields' ? (
                    <ParticipantProfileFieldRow
                      key={(data as ParticipantProfileField).participantField}
                      data={data as ParticipantProfileField}
                      className={style.item}
                    />
                  ) : (
                    <ParticipantLegalSectionRow
                      key={(data as ParticipantLegalSection).type}
                      data={data as ParticipantLegalSection}
                      className={style.item}
                    />
                  )
                }
              />
            </React.Fragment>
          )}
        </FilterManager>
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(withRouter(ProfileFields));
