import React, { PureComponent } from 'react';
import { withRouter, Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { getRedirectUrlFromParams } from '@/components/Navigation/redirect';

export interface Props extends RouteComponentProps {
  render: (props: RouteComponentProps) => any;
}

class ConsumeRedirectRoute extends PureComponent<Props> {
  render() {
    const redirectFromParam = getRedirectUrlFromParams(this.props.location.pathname, this.props.location.search);

    if (redirectFromParam) {
      return <Redirect to={redirectFromParam} />;
    }

    return <Route {...this.props} />;
  }
}

export default withRouter(ConsumeRedirectRoute);
