import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { rules } from '@/constants';
import { translations } from '@/locale';
import { getExportAllSessionsRedirectUrl, getExportAllTeamNotesRedirectUrl } from '@/services/api/export-url';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import HasRole from '@/components/HasRole';
import Typography from '@/components/Typography';
import ExportData from '@/components/ExportData';
import TextWithIcon from '@/components/TextWithIcon';
import { AppContext } from '@/components/Context/App';
import EntityPreview from '@/components/EntityPreview';
import { SessionContext } from '@/components/Context/Session';

import style from './Download.sass';

const Download: React.FC = () => {
  const intl = useIntl();

  const { organization } = React.useContext(SessionContext);

  const { team } = React.useContext(AppContext);

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

      <Spacer xs={4} />

      <Card className={style.card}>
        <div className={style.content}>
          <Typography is="span" type="swan" weight="bold">
            <FormattedMessage id={translations.pages.download.title} />
          </Typography>

          <Spacer xs={3} />

          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.pages.download.subtitle} />
          </Typography>

          <Spacer xs={3} />

          <HasRole to={rules.ORGANIZATION_DOWNLOAD_INTERVENTIONS}>
            <ExportData redirect={(token) => getExportAllSessionsRedirectUrl(token)}>
              {({ download }) => (
                <Button type="button" appearance="orange" className={style.button} onClick={download}>
                  <FormattedMessage id={translations.pages.download.downloadAllInterventionsButton} />
                </Button>
              )}
            </ExportData>
          </HasRole>

          {team && (
            <HasRole to={rules.TEAMS_DOWNLOAD_NOTES}>
              <Spacer xs={5} />

              <ExportData redirect={(token) => getExportAllTeamNotesRedirectUrl(team.id, token)}>
                {({ download }) => (
                  <TextWithIcon
                    is="span"
                    type="halcyon"
                    icon="download"
                    color="orange"
                    className={style.link}
                    boxed
                    onClick={download}
                  >
                    <FormattedMessage id={translations.pages.download.downloadAllTeamNotes} />
                  </TextWithIcon>
                )}
              </ExportData>
            </HasRole>
          )}

          <Spacer xs={7} />
        </div>
      </Card>
    </div>
  );
};

export default Download;
