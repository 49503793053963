import React from 'react';
import classnames from 'classnames';

import style from './MenuGroup.sass';

export interface Props {
  title: string;
  className?: string;
}

export default class MenuGroup extends React.PureComponent<Props> {
  render() {
    const { title, className, children } = this.props;
    return (
      <div className={classnames(style.root, className)}>
        <div className={style.title}>{title}</div>
        {children}
      </div>
    );
  }
}
