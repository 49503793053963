import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { getPrepaidLicencesInformationForOrganization } from '@/services/api/licences';

import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Typography from '@/components/Typography';
import { SessionConsumer } from '@/components/Context/Session';

import style from './PrepaidLicensesDetails.sass';

const PrepaidLicensesDetails: React.FC<WrappedComponentProps> = ({ intl }) => {
  return (
    <SessionConsumer>
      {({ organization }) => (
        <LoadData load={() => getPrepaidLicencesInformationForOrganization(organization.id)}>
          {({ value, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center overlay>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              )}
              else={() => (
                <If
                  condition={value}
                  then={() => (
                    <React.Fragment>
                      <Typography is="h6" type="sparrow" weight="bold">
                        <FormattedMessage
                          id={translations.pages.organization.licenses.tabs.billingInfo.prepaidLicensesTitle}
                        />
                      </Typography>

                      <Typography is="h6" type="sparrow" color="gray">
                        <If
                          condition={value.monthlyNumber === 0}
                          then={() => (
                            <FormattedMessage
                              id={translations.pages.organization.licenses.tabs.billingInfo.prepaidLicensesNone}
                            />
                          )}
                          else={() => (
                            <React.Fragment>
                              <Typography
                                is="span"
                                type="swan"
                                weight="bold"
                                color="blue"
                                className={style.centerVertical}
                              >
                                {`${value.monthlyNumber} `}
                              </Typography>

                              <span className={style.centerVertical}>
                                <FormattedMessage
                                  id={translations.pages.organization.licenses.tabs.billingInfo.prepaidLicensesUntil}
                                  values={{
                                    licenseCount: value.monthlyNumber,
                                    date: intl.formatDate(new Date(value.endDate), {
                                      day: '2-digit',
                                      month: 'short',
                                      year: 'numeric'
                                    })
                                  }}
                                />
                              </span>
                            </React.Fragment>
                          )}
                        />
                      </Typography>
                    </React.Fragment>
                  )}
                />
              )}
            />
          )}
        </LoadData>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(PrepaidLicensesDetails);
