import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { SurveyTypeFilter } from '@/domains';
import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

export interface Props extends Omit<SimpleSelectProps<SurveyTypeFilter>, 'items'>, WrappedComponentProps {}

class SurveyTypeSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = Object.values(SurveyTypeFilter).map((type) => ({
      value: type,
      name: intl.formatMessage({ id: translations.surveyType[type] })
    }));

    return <SimpleSelect {...rest} items={items} />;
  }
}

export default injectIntl(SurveyTypeSelect);
