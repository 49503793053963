import React from 'react';

import { translations } from '@/locale';

import Card from '@/components/Card';

import style from './AboutPricing.sass';
import Typography from '@/components/Typography';
import { FormattedMessage } from 'react-intl';
import Spacer from '@/components/Spacer';
import { urls } from '@/constants';
import Link from '@/components/Link';

const AboutPricing: React.FC = () => {
  const text = translations.pages.organization.licenses.tabs.aboutPricing;

  return (
    <Card.Row className={style.root}>
      <Card.Column className={style.column}>
        <Typography is="div" type="corvus" weight="bold">
          <FormattedMessage id={text.firstPharagraph.title} />
        </Typography>

        <Spacer xs={2} />

        <Typography is="div" type="hummingbird">
          <FormattedMessage id={text.firstPharagraph.text} />
        </Typography>

        <Spacer xs={4} />

        <Typography is="div" type="corvus" weight="bold">
          <FormattedMessage id={text.secondPharagraph.title} />
        </Typography>

        <Spacer xs={2} />

        <Typography is="div" type="hummingbird">
          <FormattedMessage
            id={text.secondPharagraph.text}
            values={{
              a: (children) => (
                <Link external to={urls.FAQ} appearance="orange">
                  {children}
                </Link>
              )
            }}
          />
        </Typography>
      </Card.Column>
    </Card.Row>
  );
};

export default AboutPricing;
