import React from 'react';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { CustomPropertyAccessibility, CustomPropertyType, State, UpdateCustomProperty } from '@/domains';
import {
  createCustomProperty,
  updateCustomProperty,
  updateCustomPropertyOptionState
} from '@/services/api/custom-property';

import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import TextInput from '@/components/TextInput';
import BoxedIcon from '@/components/BoxedIcon';
import LiveSubmit from '@/components/LiveSubmit';
import Typography from '@/components/Typography';
import { InstanceProps } from '@/components/Modal';
import ToggleSwitch from '@/components/ToggleSwitch';
import { withValidation } from '@/components/hoc/withValidation';
import CustomPropertyTypeSelect from '@/components/Selects/CustomPropertyTypeSelect';

import style from './CustomPropertyModal.sass';

const customPropertyCreationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  availableOptions: yup
    .array()
    .of(
      yup
        .object()
        .shape({ option: yup.string() })
        .required()
    )
    .when('type', {
      is: (type) => type === CustomPropertyType.List,
      then: (schema) => schema.required()
    })
});

const customPropertyUpdateSchema = yup.object({
  name: yup.string().required()
});

const TextInputWithValidation = withValidation(TextInput);

interface Props extends InstanceProps {
  accessibility?: CustomPropertyAccessibility; // in case of Custom property creation
  customProperty?: UpdateCustomProperty; // in case of Custom property update
  refresh: () => any;
}

class CustomPropertyModal extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { close, intl, accessibility, customProperty, refresh } = this.props;

    return (
      <Card className={style.root}>
        <Button className={style.closeButton} onClick={close}>
          <BoxedIcon type="close" appearance="red" />
        </Button>

        <div className={style.padded}>
          <Typography is="span" type="swan" weight="bold">
            {customProperty ? (
              <FormattedMessage id={translations.modals.updateCustomProperty.title} />
            ) : (
              <FormattedMessage
                id={translations.modals.addCustomProperty.title}
                values={{
                  customPropertyAccessibility: intl.formatMessage({ id: translations.profileFields[accessibility] })
                }}
              />
            )}
          </Typography>

          <Spacer xs={5} />

          <Form
            id="create-custom-property"
            subscription={{ errors: true, submitting: true, valid: true, values: true }}
            schema={customProperty ? customPropertyUpdateSchema : customPropertyCreationSchema}
            initialValues={customProperty}
            onSubmit={({
              name,
              type,
              availableOptions
            }: {
              name: string;
              type: CustomPropertyType;
              availableOptions: { option: string }[];
            }) =>
              (customProperty
                ? updateCustomProperty({ id: customProperty.id, name })
                : createCustomProperty({
                    name,
                    type,
                    accessibility,
                    availableOptions:
                      type === CustomPropertyType.List ? availableOptions.map(({ option }) => option) : null
                  })
              ).then(() => {
                refresh();
                close();
              })
            }
            mutators={{ ...arrayMutators }}
          >
            {(
              {
                form: {
                  mutators: { push }
                },
                submitting,
                valid,
                values
              },
              id
            ) => (
              <React.Fragment>
                <Form.Field
                  is={TextInput}
                  id={`${id}-name`}
                  name="name"
                  type="text"
                  label={this.props.intl.formatMessage({ id: translations.inputs.customPropertyName.label })}
                  placeholder={this.props.intl.formatMessage({
                    id: translations.inputs.customPropertyName.placeholder
                  })}
                  readOnly={submitting}
                />

                {!customProperty && (
                  <React.Fragment>
                    <Spacer xs={2} />

                    <Form.Field
                      is={CustomPropertyTypeSelect}
                      id={`${id}-type`}
                      name="type"
                      label={intl.formatMessage({ id: translations.inputs.customPropertyType.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.customPropertyType.placeholder })}
                      readOnly={submitting}
                    />
                  </React.Fragment>
                )}

                {values.type === CustomPropertyType.List && (
                  <FieldArray name="availableOptions">
                    {({ fields }) =>
                      fields.map((name, optionNumber) => (
                        <div key={optionNumber} className={style.optionRow}>
                          <Form.Field
                            is={TextInputWithValidation}
                            type="text"
                            id={`${optionNumber}`}
                            name={`${name}option`}
                            readOnly={submitting}
                            label={intl.formatMessage({ id: translations.inputs.customPropertyListOption.label })}
                            placeholder={intl.formatMessage({
                              id: translations.inputs.customPropertyListOption.placeholder
                            })}
                            inputClassName={style.optionName}
                            disabled={customProperty}
                          />

                          {customProperty ? (
                            <LiveSubmit
                              loading={false}
                              value={{ state: values.availableOptions[optionNumber].state }}
                              onChange={({ state }) =>
                                updateCustomPropertyOptionState(
                                  customProperty.id,
                                  customProperty.availableOptions[optionNumber].id,
                                  state
                                )
                              }
                            >
                              {({ value: { state }, set }) => (
                                <ToggleSwitch
                                  checked={state === State.Enabled}
                                  label={false}
                                  onClick={() => set('state', state === State.Enabled ? State.Disabled : State.Enabled)}
                                  onChange={() => null}
                                  onInputLabelClick={(event) => event.stopPropagation()}
                                />
                              )}
                            </LiveSubmit>
                          ) : (
                            <Button is="button" type="button" onClick={() => fields.remove(optionNumber)}>
                              <BoxedIcon type="bin" appearance="red" />
                            </Button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                )}

                <Spacer xs={3} />

                <div className={style.buttonsRow}>
                  {values.type === CustomPropertyType.List && !customProperty && (
                    <Button is="button" type="button" appearance="ghost" onClick={() => push('availableOptions', '')}>
                      <FormattedMessage id={translations.modals.addCustomProperty.addOption} />
                    </Button>
                  )}

                  <Button
                    loading={submitting}
                    type="submit"
                    appearance="orange"
                    className={style.nextButton}
                    disabled={!valid}
                  >
                    <FormattedMessage id={translations.buttons[customProperty ? 'save' : 'add']} />
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Form>
        </div>
      </Card>
    );
  }
}
export default injectIntl(CustomPropertyModal);
