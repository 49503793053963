export const qualitativePalette = [
  '#3F46B1',
  '#FB8460',
  '#FFCD58',
  '#C3479B',
  '#FF5B90',
  '#11B9C8',
  '#ACF1E6',
  '#54C394',
  '#0C197E'
];

export const sequentialPalette = ['#3F46B1', '#5D5BBB', '#7770C5', '#8F87CF', '#A69ED9', '#BDB5E3', '#E9E6F6'];

export const divergingPalette = ['#3F46B1', '#7770C5', '#BDB5E3', '#E8E8E8', '#F5C7B9', '#FBA68C', '#FB8460'];

export const moodPallete = ['#72C096', '#D6595D', '#3F4EB1', '#F8CE6C'];

export const goalStatusPalette = ['#D6595D', '#F8CE6C', '#72C096'];

export const skillProgressPalette = ['#E64E5A', '#F9A639', '#FFCD58', '#9BD27C', '#54C394'];

export const skillConfidencePalette = ['#E64E5A', '#FFCD58', '#54C394'];
