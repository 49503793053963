import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { qualitativePalette } from '@/constants';
import { multiplyPalleteUpToLength } from '@/util';
import { OrganizationReport, ReportFilters } from '@/domains';
import { getOrganizationReports } from '@/services/api/organization-reports';

import If from '@/components/If';
import Loading from '@/components/Loading';
import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import { LanguageContext } from '@/components/Language';
import { BarChart } from '@/components/Charts';

import style from '../Reporting.sass';

export interface Props extends WrappedComponentProps {
  criteria: ReportFilters;
}

const ActivitySection: React.FC<Props> = ({ intl, criteria }) => {
  const { language } = React.useContext(LanguageContext);

  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.main.reporting.activities.title
      })}
    >
      <LoadData
        load={() =>
          getOrganizationReports<OrganizationReport.Activity>(criteria, OrganizationReport.ReportType.Activity)
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Reports.Charts>
                <BarChart
                  title={intl.formatMessage({ id: translations.pages.main.reporting.activities.number })}
                  name="activities"
                  data={{
                    labels: [intl.formatMessage({ id: translations.pages.main.reporting.activities.number })],
                    datasets: value.activities.labels.map((label, index) => ({
                      label:
                        label.translations && label.translations[language.toLowerCase()]
                          ? label.translations[language.toLowerCase()]
                          : label.name,
                      data: [value.activities.data[index]],
                      backgroundColor: multiplyPalleteUpToLength(value.activities.labels.length, qualitativePalette)[
                        index
                      ]
                    }))
                  }}
                  className={style.wide}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                  height={400}
                />

                <BarChart
                  title={intl.formatMessage({ id: translations.pages.main.reporting.activities.proportion })}
                  name="activities-proportions"
                  data={{
                    labels: [intl.formatMessage({ id: translations.pages.main.reporting.activities.proportion })],
                    datasets: value.participantsInActivities.labels.map((label, index) => ({
                      label: label.translations ? label.translations[language.toLowerCase()] : label.name,
                      data: [value.participantsInActivities.data[index]],
                      backgroundColor: multiplyPalleteUpToLength(
                        value.participantsInActivities.labels.length,
                        qualitativePalette
                      )[index]
                    }))
                  }}
                  className={style.wide}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                  height={400}
                />
              </Reports.Charts>
            )}
          />
        )}
      </LoadData>
    </Reports>
  );
};

export default ActivitySection;
