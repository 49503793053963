import React from 'react';
import classnames from 'classnames';

import Tab from './Tab';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';

import style from './TabbedContainer.sass';

type Alignment = 'left' | 'right' | 'center';

interface Props {
  buttonAlignment?: Alignment;
  className?: string;
}

interface State {
  activeTab: number;
}

export default class TabbedContainer extends React.PureComponent<Props, State> {
  state: State = {
    activeTab: 0
  };

  changeTab = (index: number) => {
    this.setState({
      activeTab: index
    });
  };

  render() {
    const { buttonAlignment: buttonAlignment, className } = this.props;
    return (
      <div className={classnames(style.tabbedContainer, className)}>
        {React.Children.map(
          this.props.children,
          (child: Tab, index) =>
            index === this.state.activeTab &&
            (child.props.contentBeforeTitle ? (
              <div className={style.leadContentContainer}>{child.props.contentBeforeTitle}</div>
            ) : (
              <Spacer xs={4} />
            ))
        )}

        <div className={classnames(style.tabSelector, style[buttonAlignment])}>
          {React.Children.map(
            this.props.children,
            (child: Tab, index) =>
              child && (
                <button
                  key={index}
                  className={classnames(style.tabButton, { [style.active]: index === this.state.activeTab })}
                  onClick={() => this.changeTab(index)}
                >
                  <Typography is="span" type="hummingbird" color="orange">
                    {child.props.title}
                  </Typography>
                </button>
              )
          )}
        </div>
        <div className={style.tab}>
          {React.Children.map(this.props.children, (child: Tab, index) => index === this.state.activeTab && child)}
        </div>
      </div>
    );
  }
}
