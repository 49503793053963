import React from 'react';

export type ExactBranch<T> = (condition: T) => any;
export type Branch = () => any;

export interface Props<T> {
  condition: T | null;
  then?: ExactBranch<Exclude<T, undefined>>;
  else?: Branch;
}

export default class If<T> extends React.PureComponent<Props<T>> {
  static defaultProps: Partial<Props<any>> = {
    then: null,
    else: null
  };

  render() {
    return (
      <React.Fragment>
        {!!this.props.condition && this.props.then && this.props.then(this.props.condition as any)}
        {!this.props.condition && this.props.else && this.props.else()}
      </React.Fragment>
    );
  }
}
