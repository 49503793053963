import React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getAgeFromBirthdate } from '@/util';
import { ParticipantRowModel, State as ParticipantState } from '@/domains';
import { getParticipants } from '@/services/api/participant';
import { addParticipantsToSession } from '@/services/api/street-session-participants';

import Refresh from '@/components/Refresh';
import Typography from '@/components/Typography';
import Modal, { InstanceProps } from '@/components/Modal';
import NewParticipantModal from '@/components/Modals/NewParticipantModal';
import { SelectableItemData } from '@/components/SearchableModal/Content';
import SearchableModal from '@/components/SearchableModal/SearchableModal';

interface Props extends InstanceProps {
  sessionId: string;
  teamId: string;
  onSubmit: () => any;
}

class AddParticipantsToSessionModal extends React.PureComponent<Props & WrappedComponentProps> {
  extractParticipantData = (participant: ParticipantRowModel): SelectableItemData => {
    const { intl } = this.props;
    return {
      id: participant.id,
      topText: participant.nickname,
      bottomText: participant.birthDate
        ? intl.formatMessage(
            { id: translations.pages.sessions.details.tabs.participants.content.table.age },
            { age: getAgeFromBirthdate(participant.birthDate) }
          )
        : intl.formatMessage({ id: translations.pages.sessions.details.tabs.participants.content.table.noAge }),
      photoUrl: participant.imageUrl
    };
  };

  render() {
    const { sessionId, teamId, onSubmit, intl } = this.props;

    return (
      <SearchableModal close={this.props.close}>
        <SearchableModal.Header
          title={intl.formatMessage({ id: translations.modals.searchableModal.participantsToSession.title })}
          subtitle={intl.formatMessage({ id: translations.modals.searchableModal.participantsToSession.subtitle })}
        />

        <Refresh>
          {({ key, refresh }) => (
            <SearchableModal.Content
              key={key}
              close={this.props.close}
              searchLabel={intl.formatMessage({ id: translations.inputs.participantsSearch.label })}
              searchPlaceholder={intl.formatMessage({ id: translations.inputs.participantsSearch.placeholder })}
              dataSource={(page, number, criteria) =>
                getParticipants(teamId, page, number, {
                  keyword: criteria,
                  state: ParticipantState.Enabled,
                  notInSessionIds: [sessionId]
                })
              }
              submitDestination={(values) =>
                addParticipantsToSession(sessionId, values.selectedItems.map((id) => ({ id }))).then(onSubmit)
              }
              dataExtractionFunction={this.extractParticipantData}
              footerContent={
                <React.Fragment>
                  <Typography is="span" type="halcyon" color="gray">
                    <FormattedMessage
                      id={translations.modals.searchableModal.participantsToSession.footer}
                      values={{
                        modal: (children) => (
                          <Modal modal={NewParticipantModal} onProfileCreated={refresh}>
                            {({ open }) => (
                              <Typography
                                is="span"
                                color="orange"
                                type="halcyon"
                                onClick={open}
                                style={{ cursor: 'pointer' }}
                              >
                                {children}
                              </Typography>
                            )}
                          </Modal>
                        )
                      }}
                    />
                  </Typography>
                </React.Fragment>
              }
            />
          )}
        </Refresh>
      </SearchableModal>
    );
  }
}
export default injectIntl(AddParticipantsToSessionModal);
