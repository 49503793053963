import {
  Page,
  State,
  Participant,
  MediaFileType,
  ParticipantListItem,
  ParticipantCreation,
  UpdateParticipant,
  UpdateTeamAccess
} from '@/domains';
import { uploadImage } from '@/services/api/media';
import { authorizedApi } from '@/services/network/authorized-api';

export const createParticipant: (teamId: string, data: ParticipantCreation) => Promise<any> = (
  teamId,
  { imageUrl, ...rest }
) =>
  uploadImage(imageUrl, MediaFileType.Team).then((imageUrl) =>
    authorizedApi.post<any>(`/v1/participants`, { ...rest, imageUrl, teamId })
  );

export const getParticipants: (
  teamId: string,
  page: number,
  size: number,
  options: {
    [x: string]: any;
  }
) => Promise<Page<ParticipantListItem>> = (teamId, pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/participants`, { pageNumber, pageSize, teamIds: [teamId], ...options });

export const getParticipantsForSelect: (teamId?: string) => Promise<Page<ParticipantListItem>> = (teamId) =>
  authorizedApi.get<Page<ParticipantListItem>>(`/v1/participants`, {
    pageNumber: 1,
    pageSize: 1000,
    teamIds: [teamId]
  });

export const getParticipantDetails: (teamId: string, participantId: string) => Promise<Participant> = (
  teamId,
  participantId
) =>
  authorizedApi.get<Participant>(`/v3/participants/${participantId}/teams/${teamId}`, {
    extraData: ['TEAMS', 'PARTICIPANT_ACCOUNT']
  });

export const updateParticipant: (participantId: string, data: UpdateParticipant, teamId: string) => Promise<any> = (
  participantId,
  { imageUrl, ...data },
  teamId
) =>
  uploadImage(imageUrl, MediaFileType.Team).then((imageUrl) =>
    authorizedApi.put(`/v3/participants/${participantId}/teams/${teamId}`, { imageUrl, ...data })
  );

export const updateParticipantState = (participantId: string, state: State) =>
  authorizedApi.put(`/v1/participants/${participantId}/state`, { state });

export const updateParticipantTeams: (participantId: string, teamIds: UpdateTeamAccess[]) => Promise<any> = (
  participantId,
  ids
) => authorizedApi.put(`/v1/participants/${participantId}/teams`, { ids });

export const deleteParticipant: (participantId: string) => Promise<any> = (participantId) =>
  authorizedApi.delete(`/v1/participants/${participantId}`);

export const mergeParticipants: (firstParticipantId: string, secondParticipantId: string) => Promise<any> = (
  firstParticipantId,
  id
) => authorizedApi.put(`/v1/participants/${firstParticipantId}/merge`, { id });
