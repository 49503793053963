import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Card from '@/components/Card';
import Typography from '@/components/Typography';

import cellStyle from '../cellStyle.sass';

const SurveyHeader: React.FC = () => {
  const { title, type, participants, timeDate, status } = translations.pages.digitalCoach.tabs.surveys.table.header;
  return (
    <Card.Row className={cellStyle.container}>
      <Typography is="span" type="halcyon" color="gray" className={cellStyle.title}>
        <FormattedMessage id={title} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.type}>
        <FormattedMessage id={type} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.participants}>
        <FormattedMessage id={participants} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.timeAndDate}>
        <FormattedMessage id={timeDate} />
      </Typography>

      <Typography is="span" type="halcyon" color="gray" className={cellStyle.status}>
        <FormattedMessage id={status} />
      </Typography>
    </Card.Row>
  );
};

export default SurveyHeader;
