import React from 'react';
import { endOfMonth, formatISO, startOfMonth, add } from 'date-fns';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { rules } from '@/constants';
import { translations } from '@/locale';
import { checkIfWeShouldDeselectAll } from '@/util';
import { getStreetSessions } from '@/services/api/street-session';
import { getExportSessionsRedirectUrl } from '@/services/api/export-url';
import { StreetSession as StreetSessionModel, Sort, By, Filter } from '@/domains';

import If from '@/components/If';
import Card from '@/components/Card';
import Table from '@/components/Table';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import HasRole from '@/components/HasRole';
import TextInput from '@/components/TextInput';
import Typography from '@/components/Typography';
import ExportData from '@/components/ExportData';
import Placeholder from '@/components/Placeholder';
import TextWithIcon from '@/components/TextWithIcon';
import { AppContext } from '@/components/Context/App';
import Ordering, { Order } from '@/components/Ordering';
import { LanguageContext } from '@/components/Language';
import DatePickerInput from '@/components/DatePickerInput';
import TeamMemberSelect from '@/components/Selects/TeamMemberSelect';
import ParticipantSelect from '@/components/Selects/ParticipantSelect';
import SessionLabelSelect from '@/components/Selects/SessionLabelSelect';
import FilterManagerWithPersistence from '@/components/FilterManagerWithPersistence';

import NewStreetSessionModal from '@/page/Team/StreetSessions/NewStreetSessionModal';

import StreetSessionHeader from './StreetSessionHeader';
import StreetSessionRow from './StreetSessionRow';

import style from './List.sass';

const List: React.FC<WrappedComponentProps> = ({ intl }) => {
  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <If
          condition={!team}
          then={() => (
            <Placeholder image="questions" size="lg" className={style.placeholder}>
              <Typography is="span" type="swan" weight="bold">
                <FormattedMessage id={translations.pages.teams.sessions.none} />
              </Typography>
            </Placeholder>
          )}
          else={() => (
            <React.Fragment>
              <div className={style.cardHeader}>
                <Typography is="span" type="swan" weight="bold">
                  <FormattedMessage id={translations.pages.teams.sessions.title} />
                </Typography>

                <Modal modal={NewStreetSessionModal}>
                  {({ open }) => (
                    <Button appearance="orange" onClick={open}>
                      <FormattedMessage id={translations.pages.teams.sessions.createSession} />
                    </Button>
                  )}
                </Modal>
              </div>

              <FilterManagerWithPersistence
                storageItem="sessions"
                initialCriteria={{
                  time: 'upcoming',
                  sort: Order.Ascendent,
                  labelIds: [Filter.All],
                  userIds: [Filter.All],
                  participantIds: [Filter.All],
                  start: formatISO(startOfMonth(new Date()), { representation: 'date' }),
                  end: formatISO(endOfMonth(add(new Date(), { months: 1 })), { representation: 'date' })
                }}
              >
                {({ criteria, updateCriteria, debouncedUpdate }) => (
                  <React.Fragment>
                    <Spacer xs={2} />

                    <div className={style.row}>
                      <TextInput
                        type="text"
                        id="search"
                        onChange={(event) => debouncedUpdate('searchText', event.target.value)}
                        label={intl.formatMessage({ id: translations.inputs.sessionSearch.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.sessionSearch.placeholder })}
                        className={style.search}
                        autoComplete="off"
                        leadingIcon="search"
                      />

                      <HasRole to={rules.SESSION_EXPORT}>
                        <ExportData redirect={(token) => getExportSessionsRedirectUrl(team.id, token)}>
                          {({ download }) => (
                            <TextWithIcon
                              is="span"
                              type="halcyon"
                              icon="download"
                              color="orange"
                              boxed
                              onClick={download}
                              className={style.text}
                            >
                              <FormattedMessage id={translations.miscellaneous.download} />
                            </TextWithIcon>
                          )}
                        </ExportData>
                      </HasRole>
                    </div>

                    <Spacer xs={4} />

                    <div className={style.filterContainer}>
                      <div className={style.row}>
                        <div className={style.halfPadding}>
                          <Typography is="span" type="halcyon">
                            <FormattedMessage id={translations.pages.teams.sessions.dateIntervalFilter} />
                          </Typography>
                        </div>

                        <div className={style.halfPadding}>
                          <DatePickerInput
                            id="start"
                            value={criteria.start}
                            change={(_, value) => updateCriteria('start', value)}
                            inputClassName={style.datePicker}
                          />
                        </div>

                        <div className={style.withPadding}>
                          <DatePickerInput
                            id="end"
                            value={criteria.end}
                            change={(_, value) => updateCriteria('end', value)}
                            inputClassName={style.datePicker}
                          />
                        </div>
                      </div>

                      <Ordering
                        message={translations.pages.teams.sessions.sortByDate}
                        value={criteria.sort}
                        className={style.noPadding}
                        onClick={() =>
                          updateCriteria('sort', criteria.sort === Order.Ascendent ? Order.Descendent : Order.Ascendent)
                        }
                      />
                    </div>

                    <Spacer xs={2} />

                    <div className={style.filterContainer}>
                      <div className={style.withPadding}>
                        <SessionLabelSelect
                          id="session-labels"
                          value={criteria.labelIds}
                          appearance="normal"
                          label={intl.formatMessage({
                            id: translations.pages.teams.sessions.sessionLabelFilter
                          })}
                          onChange={(value) => updateCriteria('labelIds', checkIfWeShouldDeselectAll([...value]))}
                          searchable
                          multiple
                        />
                      </div>

                      <div className={style.withPadding}>
                        <TeamMemberSelect
                          id="team-members"
                          value={criteria.userIds}
                          appearance="normal"
                          label={intl.formatMessage({
                            id: translations.pages.teams.sessions.teamMembersFilter
                          })}
                          onChange={(value) => updateCriteria('userIds', checkIfWeShouldDeselectAll([...value]))}
                          searchable
                          multiple
                          editable
                        />
                      </div>

                      <ParticipantSelect
                        id="participants"
                        value={criteria.participantIds}
                        appearance="normal"
                        label={intl.formatMessage({
                          id: translations.pages.teams.sessions.participantsFilter
                        })}
                        onChange={(value) => updateCriteria('participantIds', checkIfWeShouldDeselectAll([...value]))}
                        searchable
                        multiple
                        editable
                      />
                    </div>

                    <Spacer xs={3} />

                    <Table
                      id={JSON.stringify({ criteria, id: team.id })}
                      empty={() => (
                        <Placeholder image="people" size="md" className={style.empty}>
                          <Typography is="span" type="swan" weight="bold">
                            <FormattedMessage id={translations.pages.teams.sessions.empty} />
                          </Typography>
                        </Placeholder>
                      )}
                      source={(page, number) => {
                        const options = {
                          ...(criteria.searchText ? { keyword: criteria.searchText } : null),
                          before: new Date(criteria.end).toISOString(),
                          after: new Date(criteria.start).toISOString(),
                          labelIds: criteria.labelIds.includes(Filter.All) ? null : criteria.labelIds,
                          userIds: criteria.userIds.includes(Filter.All) ? null : criteria.userIds,
                          participantIds: criteria.participantIds.includes(Filter.All) ? null : criteria.participantIds,
                          [Sort.By]: [By.EndDate],
                          [Sort.Order]: criteria.sort,
                          extraData: ['LABELS']
                        };

                        return getStreetSessions(team.id, page, number, options);
                      }}
                      renderHeader={() => <StreetSessionHeader />}
                      renderRow={(data: StreetSessionModel) => (
                        <StreetSessionRow key={data.id} language={language} streetSession={data} />
                      )}
                    />
                  </React.Fragment>
                )}
              </FilterManagerWithPersistence>
            </React.Fragment>
          )}
        />
      </Card.Column>
    </Card.Row>
  );
};

export default injectIntl(List);
