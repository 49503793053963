import { DataOption, Mood, SocialIndicator, UpdateSocialIndicator, MoodState, MoodType } from '@/domains';

export enum Frequency {
  Never = 'NEVER',
  Seldom = 'SELDOM',
  Sometimes = 'SOMETIMES',
  Often = 'OFTEN',
  VeryOften = 'VERY_OFTEN'
}

export enum Warmth {
  Cold = 'NEGATIVE_INACTIVE',
  Cool = 'NEGATIVE_ACTIVE',
  Warm = 'POSITIVE_INACTIVE',
  Hot = 'POSITIVE_ACTIVE'
}

export interface Contact {
  id: string;
  fullName: string;
  lastEvaluatedAt: Date;
  type: DataOption;
}

export interface CreateContact extends Pick<Contact, 'fullName'> {
  additionalInformation: string;
  typeId: string;
}

export interface UpdateContact extends CreateContact {}

export interface SocialMapEvaluation {
  id: string;
  frequency: Frequency;
  interactionMood: Mood;
  socialIndicatorEvaluations: SocialIndicator[];
  updatedAt: Date;
}

export interface ContactDetails extends Contact, Pick<CreateContact, 'additionalInformation'> {
  latestEvaluation: SocialMapEvaluation;
}

export interface ContactEvaluation {
  contactDto: Contact;
  evaluation: SocialMapEvaluation;
}

export interface EvaluateContact {
  frequency: Frequency;
  interactionMood: Mood;
  socialIndicatorEvaluations: UpdateSocialIndicator[];
}

export interface SocialMapContact {
  contactDto: Contact;
  evaluation: SocialMapEvaluation;
  moodState: MoodState;
  moodType: MoodType;
}
