import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import Typography from '../Typography';
import ToggleSwitch from './ToggleSwitch';

interface Props {
  toggleState: boolean;
  setShowOnlyActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleShowOnlyActive: React.FC<Props> = ({ toggleState, setShowOnlyActive }) => {
  return (
    <>
      <span>
        <Typography is="span" type="halcyon" color="blue">
          <FormattedMessage id={translations.reportsFilters.showOnlyActive} />
        </Typography>
      </span>
      <ToggleSwitch
        label={false}
        checked={toggleState === true}
        onClick={() => {
          setShowOnlyActive(toggleState === true ? false : true);
        }}
        onChange={() => null}
      />
    </>
  );
};

export default ToggleShowOnlyActive;
