import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';

import SimpleSelect, { Props as SimpleSelectProps } from '@/components/SimpleSelect';

const intervalList = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];

export interface Props extends Omit<SimpleSelectProps<any>, 'items'>, WrappedComponentProps {}

class SocialMapEvaluationIntervalSelect extends React.PureComponent<Props> {
  render() {
    const { intl, ...rest } = this.props;

    const items = intervalList.map((value) => ({
      value: `${value}`,
      name: intl.formatMessage({ id: translations.inputs.daysInterval.optionLabel }, { day: value })
    }));

    return (
      <SimpleSelect
        {...rest}
        label={intl.formatMessage({ id: translations.inputs.daysInterval.label })}
        appearance="normal"
        items={items}
      />
    );
  }
}

export default injectIntl(SocialMapEvaluationIntervalSelect);
