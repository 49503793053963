import React, { PureComponent } from 'react';
import classnames from 'classnames';

import Input, { Props as InputProps } from '@/components/Input';
import Icon, { Type } from '@/components/Icon';

import style from './TextInput.sass';

export interface Props extends InputProps {
  invalid?: boolean;
  label?: string;
  leadingIcon?: Type;
  icon?: Type;
  inputClassName?: string;
}

export default class TextInput extends PureComponent<Props> {
  render() {
    const { invalid, className, inputClassName, label, icon, leadingIcon, ...rest } = this.props;

    return (
      <div className={classnames(style.container, className)}>
        {leadingIcon && (
          <Icon
            type={leadingIcon}
            className={classnames(style.icon, style.leadingIcon, { [style.invalid]: invalid })}
          />
        )}

        <Input
          id={this.props.id}
          className={classnames(
            style.root,
            { [style.invalid]: invalid, [style.withLead]: leadingIcon, [style.withTrail]: icon },
            inputClassName
          )}
          {...rest}
        />

        {label && (
          <label
            htmlFor={this.props.id}
            className={classnames(style.label, { [style.invalid]: invalid, [style.withLeadLabel]: leadingIcon })}
          >
            {label}
          </label>
        )}

        {icon && (
          <Icon type={icon} className={classnames(style.icon, style.trailingIcon, { [style.invalid]: invalid })} />
        )}
      </div>
    );
  }
}
