import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';
import { StreetSessionLabel } from '@/domains';
import { createLabel, getLabels } from '@/services/api/label';
import { updateStreetSessionLabels } from '@/services/api/street-session';

import TagField from '@/components/TagField';

export interface Props {
  sessionId: string;
  initialLabels: StreetSessionLabel[];
}

const StreetSessionTags: React.FC<Props> = ({ sessionId, initialLabels }) => {
  const { formatMessage } = useIntl();

  return (
    <TagField
      label={formatMessage({ id: translations.inputs.sessionLabel.label })}
      dropdownTitle={formatMessage({ id: translations.inputs.sessionLabel.dropdownTitle })}
      initialTags={initialLabels}
      dataSource={(page, number, criteria) =>
        getLabels(page, number, {
          keyword: criteria
        })
      }
      addNewTag={(newTagName) => createLabel({ name: newTagName })}
      updateTagList={(tagList) => updateStreetSessionLabels(sessionId, tagList)}
    />
  );
};

export default StreetSessionTags;
