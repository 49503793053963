import React, { useState } from 'react';
import { ElementProps } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import classnames from 'classnames';

import style from './StripeElement.sass';

const cardStyle = {
  base: {
    fontFamily: '"Open Sans", sans-serif',
    lineHeight: '24px',
    iconColor: 'transparent',
    '::placeholder': {
      color: 'transparent'
    },
    ':focus': {
      iconColor: '#90919C',
      '::placeholder': {
        color: '#90919C'
      }
    }
  },
  invalid: {
    color: '#DE2736',
    iconColor: '#DE2736'
  }
};

interface Props {
  is: any;
  label: any;
  options?: object;
  className?: string;
  onFocus?: () => any;
  onBlur?: () => any;
  onChange?: (event: StripeElementChangeEvent) => any;
}

const StripeElement: React.FC<Props & ElementProps> = ({
  is: Component,
  label,
  options,
  className,
  onFocus,
  onBlur,
  onChange,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [empty, setEmpty] = useState(true);

  const handleFocus = () => {
    setFocus(true);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    setFocus(false);
    onBlur && onBlur();
  };

  const handleChange = (event: StripeElementChangeEvent) => {
    setEmpty(event.empty);
    onChange && onChange(event);
  };

  return (
    <label
      className={classnames(
        style.stripeElementContainer,
        className,
        focus && style.focused,
        !empty && style.hasContent
      )}
    >
      <span className={style.stripeElementLabel}>{label}</span>
      <Component
        options={{ style: cardStyle, ...options }}
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </label>
  );
};

export default StripeElement;
