import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getTeamMembersForSession } from '@/services/api/street-session-teammates';

import Modal from '@/components/Modal';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Refresh from '@/components/Refresh';
import TextInput from '@/components/TextInput';
import { AppConsumer } from '@/components/Context/App';
import FilterManager from '@/components/FilterManager';
import AddTeammatesToSessionModal from '@/components/SearchableModals/AddTeammatesToSessionModal';

import TeammateRow from './TeammateRow';

import style from './Teammates.sass';

export interface Props {}

interface Params {
  id: string;
}

const Teammates: React.FC<Props & WrappedComponentProps & RouteComponentProps<Params>> = ({ intl, match }) => {
  const { id } = match.params;

  return (
    <AppConsumer>
      {({ team, loading }) => (
        <Refresh>
          {({ key, refresh }) => (
            <div className={style.root}>
              <FilterManager initialCriteria={{}}>
                {({ criteria, debouncedUpdate }) => (
                  <React.Fragment>
                    <TextInput
                      type="text"
                      id="search"
                      onChange={(event) => debouncedUpdate('search', event.target.value)}
                      label={intl.formatMessage({ id: translations.inputs.membersSearch.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.membersSearch.placeholder })}
                      autoComplete="off"
                      className={style.searchBar}
                      leadingIcon="search"
                    />

                    <Table
                      key={key}
                      id={criteria.search}
                      source={(page, number) => getTeamMembersForSession(page, number, criteria.search, id)}
                      renderRow={(member) => <TeammateRow sessionId={id} data={member} onDelete={refresh} />}
                      listClassName={style.searchResults}
                    />
                  </React.Fragment>
                )}
              </FilterManager>

              <Modal modal={AddTeammatesToSessionModal} sessionId={id} teamId={!loading && team.id} onSubmit={refresh}>
                {({ open }) => (
                  <Button type="button" fat appearance="orange" className={style.addButton} onClick={open}>
                    <FormattedMessage id={translations.inputs.addTeammates} />
                  </Button>
                )}
              </Modal>
            </div>
          )}
        </Refresh>
      )}
    </AppConsumer>
  );
};

export default withRouter(injectIntl(Teammates));
