export enum QuestionType {
  MultiSelect = 'MULTIPLE_CHOICE',
  SingleSelect = 'SINGLE_CHOICE',
  TextInput = 'TEXT_INPUT'
}

export interface Question {
  title: string;
  type: QuestionType;
  options?: string[];
}

export interface FetchedQuestion {
  id: string;
  title: string;
  type: QuestionType;
  options?: { id: string; option: string }[];
}
