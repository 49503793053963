import { generatePath } from 'react-router';

export const signIn = `/login`;
export const signUp = {
  main: `/signup`,
  sent: `/signup/sent`
};

export const forgotPassword = {
  main: `/forgot-password`,
  sent: `/forgot-password/sent`
};

export const resetPassword = {
  main: `/reset-password`,
  expired: `/reset-password/expired`
};

export const home = `/`;

export const dashboard = `/dashboard`;
export const reporting = `/reporting`;
export const generalInformation = `/general-information`;
export const download = `/download`;

export const members = {
  list: `/members`,
  create: `/members/new`,
  detail: `/members/:id`,
  get: (id: string) => generatePath(`/members/:id`, { id })
};

export const dataCustomisation = {
  base: `/data-customisation`,
  tabs: {
    withTab: `/data-customisation/:tab`,
    dataOptions: 'data-options',
    profileFields: 'profile-fields',
    ageGroups: 'age-groups',
    other: 'other',
    get: (tab: string, dataType?: string) =>
      generatePath(
        !!dataType ? `/data-customisation/:tab${dataType && `\\\?dataType=${dataType}`}` : `/data-customisation/:tab`,
        { tab }
      )
  }
};

export const team = {
  detail: `/team`,
  locations: {
    reports: {
      detail: `/team/locations/:locationId/reports`,
      get: (locationId: string) => generatePath(`/team/locations/:locationId/reports`, { locationId })
    }
  }
};

export const teammates = {
  list: `/teammates`,
  create: `/teammates/new`,
  detail: `/teammates/:id`,
  get: (id: string) => generatePath(`/teammates/:id`, { id })
};

export const participants = {
  list: `/participants`,
  detail: `/participants/:id`,
  detailTabs: {
    withTab: `/participants/:id/:tab`,
    information: 'details',
    contacts: 'contacts',
    notes: 'notes',
    images: 'images',
    evaluations: 'evaluations',
    goals: 'goals',
    reports: 'reports',
    digitalCoach: 'digital-coach',
    get: (id: string, tab: string) => generatePath(`/participants/:id/:tab`, { id, tab })
  },
  get: (id: string) => generatePath(`/participants/:id`, { id }),
  evaluation: {
    detail: `/participants/:participantId/evaluations/:evaluationId`,
    get: (participantId: string, evaluationId: string) =>
      generatePath(`/participants/:participantId/evaluations/:evaluationId`, { participantId, evaluationId })
  }
};

export const sessions = {
  list: `/sessions`,
  detail: '/sessions/:id',
  get: (id: string) => generatePath(`/sessions/:id`, { id }),
  feedback: {
    create: '/sessions/:id/create-feedback',
    getCreateUrl: (id: string) => generatePath(`/sessions/:id/create-feedback`, { id }),
    detail: '/sessions/:id/feedback',
    getFeedbackUrl: (id: string) => generatePath(`/sessions/:id/feedback`, { id })
  }
};

export const licenses = '/licenses';

export const contact = 'https://www.mobileschool.org/contact';
export const about = 'https://www.mobileschool.org/about-us';
export const terms = 'https://www.street-smart.be/terms-of-service-streetsmart-impact';
export const privacyPolicy = 'https://www.street-smart.be/privacy-policy-streetsmart';
export const dataProcessingPolicy = 'https://www.street-smart.be/data-processing-policy';
export const acceptableUsePolicy = 'https://www.street-smart.be/acceptable-use-policy';
export const cookiePolicy = 'https://www.street-smart.be/cookie-policy';
export const FAQ = 'https://www.street-smart.be/faq/impact';

export const admin = {
  reports: '/admin/reports',
  organizations: {
    list: `/admin/organisations`,
    detail: `/admin/organisations/:id`,
    get: (id: string) => generatePath(`/admin/organisations/:id`, { id })
  },
  dataCustomisation: {
    base: `/admin/data-customisation`,
    get: (dataType?: string) => generatePath(`/admin/data-customisation/\\\?dataType=${dataType}`)
  }
};

export const digitalCoach = {
  coach: '/digital-coach',
  survey: {
    base: '/digital-coach/survey',
    new: '/digital-coach/survey/create',
    detail: '/digital-coach/survey/:id',
    get: (id: string) => generatePath(`/digital-coach/survey/:id`, { id })
  },
  selfEvaluation: {
    base: '/digital-coach/self-evaluation',
    detail: '/digital-coach/self-evaluation/:id',
    get: (id: string) => generatePath(`/digital-coach/self-evaluation/:id`, { id })
  }
};
