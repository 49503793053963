import React from 'react';
import { WrappedComponentProps } from 'react-intl';

import { translations } from '@/locale';
import { qualitativePalette } from '@/constants';
import { ReportFilters, ParticipantReport } from '@/domains';
import { getLabelsFromPeriodAndTimeFrame, getColorFromPalleteByIndex } from '@/util';
import { getParticipantReports } from '@/services/api/participant-reports';

import If from '@/components/If';
import Loading from '@/components/Loading';
import Reports from '@/components/Reports';
import LoadData from '@/components/LoadData';
import { PieChart, BarChart } from '@/components/Charts';
import { LanguageContext } from '@/components/Language';

import style from '../Reports.sass';

export interface Props extends WrappedComponentProps {
  participantId: string;
  teamId: string;
  criteria: ReportFilters;
}

const ActivitySection: React.FC<Props> = ({ intl, participantId, teamId, criteria }) => {
  const { language } = React.useContext(LanguageContext);
  return (
    <Reports
      title={intl.formatMessage({
        id: translations.pages.teams.participants.details.reports.activities.title
      })}
    >
      <LoadData
        key={JSON.stringify(criteria)}
        load={() =>
          getParticipantReports<ParticipantReport.Activity>(
            participantId,
            teamId,
            criteria,
            ParticipantReport.ReportType.Activity
          )
        }
      >
        {({ value, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className={style.loading}>
                <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
              </Loading>
            )}
            else={() => (
              <Reports.Charts className={style.column}>
                <BarChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.activities.total
                  })}
                  name="number-of-activities-offered"
                  data={{
                    labels: getLabelsFromPeriodAndTimeFrame(intl, value.periods, criteria.timeFrame),
                    datasets: value.offeredActivitiesVsAttendedActivities.entries.map((entry, index) => ({
                      label: intl.formatMessage({ id: translations.activities[entry.label.name] }),
                      data: entry.data,
                      backgroundColor: getColorFromPalleteByIndex(qualitativePalette, index)
                    }))
                  }}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            stepSize: 1
                          }
                        }
                      ]
                    }
                  }}
                />

                <PieChart
                  title={intl.formatMessage({
                    id: translations.pages.teams.participants.details.reports.activities.participation
                  })}
                  name="activity-participation"
                  data={{
                    labels: value.attendedActivities.labels.map((label) =>
                      label.translations && label.translations[language.toLowerCase()]
                        ? label.translations[language.toLowerCase()]
                        : label.name
                    ),
                    dataset: value.attendedActivities.data
                  }}
                  options={{
                    maintainAspectRatio: false
                  }}
                  height={300}
                  className={style.participantActivityOverview}
                />
              </Reports.Charts>
            )}
          />
        )}
      </LoadData>
    </Reports>
  );
};

export default ActivitySection;
