import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import If from '@/components/If';
import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import { AppConsumer } from '@/components/Context/App';
import EntityPreview from '@/components/EntityPreview';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import List from './List';
import Details from './Details';
import SessionFeedback from './SessionFeedback';
import Detail from '../DigitalCoach/Survey/Detail';

import style from './StreetSessions.sass';

const StreetSessions: React.FC = () => (
  <div className={style.root}>
    <Spacer xs={2} />

    <AppConsumer>{({ team }) => !!team && <EntityPreview logo={team.imageUrl} name={team.name} />}</AppConsumer>

    <Spacer xs={4} />

    <Card className={style.card}>
      <Switch>
        <Route exact path={urls.sessions.list} component={List} />

        <Route exact path={urls.sessions.detail} component={Details} />

        <Route exact path={urls.sessions.feedback.create} component={SessionFeedback} />

        <Route exact path={urls.sessions.feedback.detail} component={Detail} />

        <ConsumeRedirectRoute render={() => <Redirect to={urls.sessions.list} />} />
      </Switch>
    </Card>
  </div>
);

export default StreetSessions;
