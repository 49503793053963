import { IntlShape } from 'react-intl';

import { translations } from '@/locale';
import { MoodType, MoodState, Period, TimeFrame } from '@/domains';

export const linearMap = (A: number, B: number, a: number, b: number, val: number): number =>
  ((val - A) * (b - a)) / (B - A) + a;

/**
 * Multiplies a pallete until it reaches specified length
 * @param length Length of the new pallete
 * @param pallete Pallete to be multiplied
 */
export const multiplyPalleteUpToLength = (length: number, pallete: string[]): string[] =>
  length > pallete.length ? new Array(Math.ceil(length / pallete.length)).fill(pallete).flat() : pallete;

/**
 * Get a color from pallete. If index is greater than pallete length repeat the pallete and get an appropiate color
 * @param pallete
 * @param index
 */
export const getColorFromPalleteByIndex = (pallete: string[], index: number): string =>
  index >= pallete.length ? pallete[index - pallete.length * Math.floor(index / pallete.length)] : pallete[index];

export const getColorFromPallete = (max: number, palette: string[], value: number) =>
  palette[Math.floor(linearMap(0, max, 0, palette.length, value))] || palette[0];

export const getColorsForSequentialData = (data: number[], palette: string[]) =>
  data.map((value) => getColorFromPallete(Math.max(...data), palette, value));

export const toDecimal = (hex: string) => parseInt(hex, 16);

export const parseHex = (hex: string): number[] => [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)].map(toDecimal);

export const withAlphaChannel = (rgb: number[], a: number): string => `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`;

export const getMoodReportLabel = (type: MoodType, state: MoodState): string => `${type}_${state}`;

export const getLabelsFromPeriodAndTimeFrame = (intl: IntlShape, period: Period[], timeFrame: TimeFrame): string[] => {
  if (!period) return;

  switch (timeFrame) {
    case TimeFrame.Weekly:
      return period.map(({ year, periodOfYear: week }) =>
        intl.formatMessage({ id: translations.miscellaneous.week }, { week, year })
      );
    case TimeFrame.Monthly:
      return period.map(({ year, periodOfYear: month }) =>
        intl.formatDate(new Date(year, month - 1, 1), { month: 'short', year: 'numeric' })
      );
    case TimeFrame.Yearly:
      return period.map(({ year }) => `${year}`);
  }
};
