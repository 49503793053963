import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '@/locale';
import { getDataOptionsForTeam } from '@/services/api/data-option';
import { DataOption, DataOptionType, EvaluationDetails } from '@/domains';
import { evaluateSkill, deleteSkillEvaluation } from '@/services/api/participant-evaluations';

import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import LoadData from '@/components/LoadData';
import BoxedIcon from '@/components/BoxedIcon';
import Typography from '@/components/Typography';
import LiveSubmit from '@/components/LiveSubmit';
import Placeholder from '@/components/Placeholder';
import SimpleSelect from '@/components/SimpleSelect';
import { AppContext } from '@/components/Context/App';
import { LanguageContext } from '@/components/Language';
import EvaluateSkillModal from '@/components/Modals/EvaluateSkillModal';
import LearningStatusSelect from '@/components/Selects/LearningStatusSelect';
import EvaluationStatusSelect from '@/components/Selects/EvaluationStatusSelect';

import style from './Skills.sass';

const InputsContainer = (props) => (
  <div className={classnames(style.inputsContainer, props.className)}>{props.children}</div>
);

const mapDataOptionsToSelectItems = (dataOptions: DataOption[], language: string) =>
  dataOptions.map((item) => ({
    value: item.id,
    name:
      item.translations && item.translations[language.toLowerCase()]
        ? item.translations[language.toLowerCase()]
        : item.name
  }));

interface Params {
  participantId: string;
  evaluationId: string;
}

interface Props {
  evaluation: EvaluationDetails;
  loading: boolean;
  reload: () => any;
}

const Skills: React.FC<Props & RouteComponentProps<Params>> = ({ match, evaluation, loading, reload }) => {
  const intl = useIntl();
  const { participantId, evaluationId: sessionId } = match.params;

  const { language } = React.useContext(LanguageContext);
  const { team } = React.useContext(AppContext);

  const translation = translations.inputs;

  return (
    <Card.Row>
      <Card.Column sm={12}>
        <Typography is="h6" type="swan" weight="bold">
          <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.skills.title} />
        </Typography>

        <Spacer xs={2} />

        {(!!evaluation.skillEvaluations || !!evaluation.skillEvaluationsOfParticipant) && (
          <LoadData load={() => getDataOptionsForTeam(DataOptionType.Skill, team.id)}>
            {({ value: skillItems, loading: skillItemsLoading }) =>
              skillItemsLoading ? (
                <Loading visible={skillItemsLoading} center className={style.dataOptionLoading}>
                  <Loading.Indicator size={60} borderWidth={4} fullCircle color="#BCBDC3" />
                </Loading>
              ) : (
                <React.Fragment>
                  {evaluation.skillEvaluations !== null && evaluation.skillEvaluations.length !== 0 && (
                    <div className={style.skills}>
                      {evaluation.skillEvaluations.map(({ skill, id, ...rest }, index) => (
                        <LiveSubmit
                          key={index}
                          loading={loading}
                          value={{ skillOptionId: skill.id, ...rest }}
                          onChange={(evaluation) => evaluateSkill(participantId, sessionId, evaluation)}
                        >
                          {({ value, set }) => (
                            <div className={style.skill}>
                              <InputsContainer className={style.skillInputs}>
                                <SimpleSelect
                                  id={`skill-${index}`}
                                  name="skill"
                                  label={intl.formatMessage({ id: translation.skillSelect.label })}
                                  placeholder={intl.formatMessage({ id: translation.skillSelect.placeholder })}
                                  value={value.skillOptionId}
                                  onChange={(id: string) => set('skillOptionId', id)}
                                  appearance="normal"
                                  items={mapDataOptionsToSelectItems(skillItems, language)}
                                />

                                <EvaluationStatusSelect
                                  id={`learning-confidence-${index}`}
                                  name="confidenceStatus"
                                  label={intl.formatMessage({ id: translation.learningConfidence.label })}
                                  placeholder={intl.formatMessage({ id: translation.learningConfidence.placeholder })}
                                  value={value.confidenceStatus}
                                  onChange={(status) => set('confidenceStatus', status as string)}
                                  appearance="normal"
                                />

                                <LearningStatusSelect
                                  id={`learning-progress-${index}`}
                                  name="learningStatus"
                                  value={value.learningStatus}
                                  onChange={(status) => set('learningStatus', status as string)}
                                />
                              </InputsContainer>

                              <Button
                                type="button"
                                className={style.icon}
                                onClick={() => deleteSkillEvaluation(id).then(reload)}
                              >
                                <BoxedIcon type="bin" appearance="red" />
                              </Button>
                            </div>
                          )}
                        </LiveSubmit>
                      ))}
                    </div>
                  )}

                  {evaluation.skillEvaluationsOfParticipant !== null &&
                    evaluation.skillEvaluationsOfParticipant.length !== 0 && (
                      <div className={style.skills}>
                        <Typography is="span" type="halcyon" color="gray">
                          <FormattedMessage id={translations.modals.evaluateSkill.participantEvaluation} />
                        </Typography>

                        {evaluation.skillEvaluationsOfParticipant.map(
                          ({ skill, id, confidenceStatus, learningStatus }, index) => (
                            <InputsContainer key={id} className={style.skillInputs}>
                              <SimpleSelect
                                id={`participant-skill-${index}`}
                                name="participantSkill"
                                label={intl.formatMessage({ id: translation.skillSelect.label })}
                                placeholder={intl.formatMessage({ id: translation.skillSelect.placeholder })}
                                value={skill.id}
                                onChange={() => null}
                                appearance="normal"
                                items={mapDataOptionsToSelectItems(skillItems, language)}
                                disabled
                              />

                              <EvaluationStatusSelect
                                id={`participant-learning-confidence-${index}`}
                                name="participantConfidenceStatus"
                                label={intl.formatMessage({ id: translation.learningConfidence.label })}
                                placeholder={intl.formatMessage({ id: translation.learningConfidence.placeholder })}
                                value={confidenceStatus}
                                onChange={() => null}
                                appearance="normal"
                                disabled
                              />

                              <LearningStatusSelect
                                id={`participant-learning-progress-${index}`}
                                name="participantLearningStatus"
                                value={learningStatus}
                                onChange={() => null}
                                disabled
                              />
                            </InputsContainer>
                          )
                        )}
                      </div>
                    )}
                </React.Fragment>
              )
            }
          </LoadData>
        )}

        {(evaluation.skillEvaluations === null || evaluation.skillEvaluations.length === 0) && (
          <Placeholder image="questions" size="sm">
            <Typography is="span" type="halcyon" weight="bold">
              <FormattedMessage id={translations.pages.teams.participants.details.evaluations.details.skills.none} />
            </Typography>
          </Placeholder>
        )}

        {(evaluation.skillEvaluationsOfParticipant === null ||
          evaluation.skillEvaluationsOfParticipant.length === 0) && (
          <React.Fragment>
            <Typography is="span" type="halcyon" color="gray">
              <FormattedMessage id={translations.modals.evaluateSkill.participantEvaluation} />
            </Typography>

            <Placeholder image="questions" size="sm">
              <Typography is="span" type="halcyon" weight="bold">
                <FormattedMessage
                  id={translations.pages.teams.participants.details.evaluations.details.skills.participantNone}
                />
              </Typography>
            </Placeholder>
          </React.Fragment>
        )}

        <Spacer xs={3} />

        <Modal modal={EvaluateSkillModal} sessionId={sessionId} participantId={participantId} onSkillEvaluated={reload}>
          {({ open }) => (
            <Typography is="span" type="halcyon" color="orange" onClick={open} className={style.link}>
              <FormattedMessage
                id={translations.pages.teams.participants.details.evaluations.details.skills.evaluate}
              />
            </Typography>
          )}
        </Modal>
      </Card.Column>
    </Card.Row>
  );
};

export default withRouter(Skills);
