import React from 'react';
import classnames from 'classnames';

import Typography from '@/components/Typography';

import style from './Item.sass';

interface Props {
  name: string;
  value: string | number;
  className?: string;
}

const Item: React.FC<Props> = ({ name, value, className }) => (
  <div className={classnames(style.root, className)}>
    <Typography is="h4" type="halcyon" color="blue" weight="lighter">
      {name}
    </Typography>

    <Typography is="span" type="flamingo" weight="bold">
      {value}
    </Typography>
  </div>
);

export default Item;
