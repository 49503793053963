import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { translations } from '@/locale';
import { DataOptionType } from '@/domains';

import Card from '@/components/Card';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import Tab from '@/components/TabbedContainer/Tab';
import EntityPreview from '@/components/EntityPreview';
import TabbedContainer from '@/components/TabbedContainer';
import { SessionContext } from '@/components/Context/Session';

import DataOptions from '@/components/Customisation/DataOptions';

import style from './DataCustomisation.sass';

const superAdminDataOptionTypes = [
  DataOptionType.SessionType,
  DataOptionType.Skill,
  DataOptionType.ActivityType,
  DataOptionType.GoalType,
  DataOptionType.ContactType
];

interface Params {
  tab: string;
}

const DataCustomisation: React.FC<RouteComponentProps<Params>> = ({ match, history, location }) => {
  const { organization } = React.useContext(SessionContext);

  return (
    <div className={style.root}>
      <Spacer xs={2} />

      {!!organization && <EntityPreview logo={organization.logoUrl} name={organization.name} />}

      <Spacer xs={4} />

      <Card className={style.card}>
        <Card.Row>
          <Card.Column sm={12}>
            <Typography is="span" type="swan" weight="bold">
              <FormattedMessage id={translations.pages.superAdminDataCustomisation.title} />
            </Typography>
          </Card.Column>
        </Card.Row>

        <TabbedContainer>
          <Tab title={<FormattedMessage id={translations.pages.superAdminDataCustomisation.tabs.dataOptions} />}>
            <DataOptions dataOptionTypes={superAdminDataOptionTypes} />
          </Tab>
        </TabbedContainer>
      </Card>
    </div>
  );
};

export default withRouter(DataCustomisation);
