import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import RoutableTab from './RoutableTab';
import Typography from '@/components/Typography';

import style from './RoutedTabbedContainer.sass';

type Alignment = 'left' | 'right' | 'center';

interface Props extends RouteComponentProps {
  buttonAlignment?: Alignment;
  className?: string;
  baseUrl: string;
}

export default class RoutedTabbedContainer extends React.PureComponent<Props> {
  changeTab = (url: string) => {
    this.props.history.push(url);
  };

  render() {
    const { buttonAlignment: buttonAlignment, className, history, location, children, baseUrl } = this.props;

    const childrenArray = React.Children.toArray(children).filter((child) => child);
    return (
      <div className={classnames(style.tabbedContainer, className)}>
        <div className={classnames(style.tabSelector, style[buttonAlignment])}>
          {childrenArray.map((child: RoutableTab) => (
            <button
              key={child.props.url}
              className={classnames(style.tabButton, {
                [style.active]: location.pathname === child.props.url
              })}
              onClick={() => history.replace(child.props.url)}
            >
              <Typography is="span" type="hummingbird" color="orange">
                {child.props.title}
              </Typography>
            </button>
          ))}
        </div>

        <div className={style.tab}>
          <Switch>
            <Route
              exact
              path={baseUrl}
              render={() => childrenArray.find((child: RoutableTab) => location.pathname === child.props.url)}
            />

            <Redirect to={children[0].props.url} />
          </Switch>
        </div>
      </div>
    );
  }
}
