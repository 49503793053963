import React from 'react';
import classnames from 'classnames';

import style from './Row.sass';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

export default class Row extends React.PureComponent<Props> {
  render() {
    const { is: Component = 'div', className, children, ...rest } = this.props;
    return (
      <Component className={classnames(style.root, style.row, className)} {...rest}>
        {children}
      </Component>
    );
  }
}
